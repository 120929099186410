import { ContractHourlyRateCategoryPairDto } from '../../interfaces/dtos/contract-hourly-rate-category-pair.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ContractHourlyRateCategoryPairDtoModel {
  readonly submitterHourlyRateCategoryLabel: string;

  readonly supplierHourlyRateCategoryLabel: string;

  disabled?: boolean;

  constructor(
    readonly contractHourlyRateCategoryPairId: number | null = null,
    readonly contractId: number | null = null,
    readonly submitterHourlyRateCategory: string | null = null,
    readonly submitterHourlyRateCategoryIsDefault: boolean = false,
    readonly submitterActivityTypeDescription: string | null = null,
    readonly supplierHourlyRateCategory: string | null = null,
    readonly supplierHourlyRateCategoryIsDefault: boolean = false,
    readonly supplierActivityTypeDescription: string | null = null,
  ) {
    this.submitterHourlyRateCategoryLabel = `${this.submitterHourlyRateCategory}${this.submitterHourlyRateCategoryIsDefault ? ' (Default)' : ''}`;
    this.supplierHourlyRateCategoryLabel = `${this.supplierHourlyRateCategory}${this.supplierHourlyRateCategoryIsDefault ? ' (Default)' : ''}`;
    this.disabled = false;
  }

  static fromJSON(json: ContractHourlyRateCategoryPairDto): ContractHourlyRateCategoryPairDtoModel {
    assertRequiredProperties(json, [
    ]);

    return new ContractHourlyRateCategoryPairDtoModel(
      json.contractHourlyRateCategoryPairId,
      json.contractId,
      json.submitterHourlyRateCategory,
      json.submitterHourlyRateCategoryIsDefault || false,
      json.submitterActivityTypeDescription,
      json.supplierHourlyRateCategory,
      json.supplierHourlyRateCategoryIsDefault || false,
      json.supplierActivityTypeDescription,
    );
  }

  clone(): ContractHourlyRateCategoryPairDtoModel {
    return new ContractHourlyRateCategoryPairDtoModel(
      this.contractHourlyRateCategoryPairId,
      this.contractId,
      this.submitterHourlyRateCategory,
      this.submitterHourlyRateCategoryIsDefault,
      this.submitterActivityTypeDescription,
      this.supplierHourlyRateCategory,
      this.supplierHourlyRateCategoryIsDefault,
      this.supplierActivityTypeDescription,
    );
  }

  toJSON(): ContractHourlyRateCategoryPairDto {
    return {
      contractHourlyRateCategoryPairId: this.contractHourlyRateCategoryPairId,
      contractId: this.contractId,
      submitterHourlyRateCategory: this.submitterHourlyRateCategory,
      submitterHourlyRateCategoryIsDefault: this.submitterHourlyRateCategoryIsDefault,
      submitterActivityTypeDescription: this.submitterActivityTypeDescription,
      supplierHourlyRateCategory: this.supplierHourlyRateCategory,
      supplierHourlyRateCategoryIsDefault: this.supplierHourlyRateCategoryIsDefault,
      supplierActivityTypeDescription: this.supplierActivityTypeDescription,
    };
  }
}

/**
 * Static model for the hourly rate default category 'Auto' at the end of the select dropdowns.
 */
export const autoHourlyRateCategory = new ContractHourlyRateCategoryPairDtoModel(
  -1,
  null,
  'Auto',
  false,
  '',
  'Auto',
  false,
  '',
);
