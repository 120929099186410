import {
  NgModule, ModuleWithProviders, Optional, SkipSelf, ɵstringify as stringify,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularApplicationInsightsConfig, ApplicationInsightsService } from './application-insights.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    ApplicationInsightsService,
  ],
})
export class ApplicationInsightsModule {
  constructor(
    applicationInsightsService: ApplicationInsightsService,
    @Optional() @SkipSelf() parentModule?: ApplicationInsightsModule,
  ) {
    if (parentModule) {
      throw new Error(`${stringify(ApplicationInsightsModule)} is already loaded. Import it in the AppModule only.`);
    }
    applicationInsightsService.init();
  }

  static forRoot(config: AngularApplicationInsightsConfig): ModuleWithProviders<ApplicationInsightsModule> {
    return {
      ngModule: ApplicationInsightsModule,
      providers: [
        { provide: AngularApplicationInsightsConfig, useValue: config },
        ApplicationInsightsService,
      ],
    };
  }
}
