import { UnitCalendarLinkDto } from '../../interfaces/dtos/unit-calendar-link.dto';

export class UnitCalendarLinkDtoModel {
  constructor(
    readonly unitName: string | null,
    readonly level: number,
    readonly calendarLink: string | null,
  ) {}

  static fromJSON(json: UnitCalendarLinkDto): UnitCalendarLinkDtoModel {
    return new UnitCalendarLinkDtoModel(
      json.unitName,
      json.level,
      json.calendarLink,
    );
  }

  toJSON(): UnitCalendarLinkDto {
    return {
      unitName: this.unitName,
      level: this.level,
      calendarLink: this.calendarLink,
    };
  }
}
