import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { ProjectPlanningRangeStateModel } from './project-planning-range-state.model';
import {
  InitProjectPlanningRangeSettings,
  ResetProjectPlanningRangeSettings,
  SaveProjectPlanningRangeSettings,
  SetProjectPlanningRangeSettings,
} from './project-planning-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultProjectPlanningRangeState: ProjectPlanningRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<ProjectPlanningRangeStateModel>({
  name: 'ProjectPlanningRange',
  defaults: defaultProjectPlanningRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class ProjectPlanningRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitProjectPlanningRangeSettings)
  initProjectPlanningRangeSettings(
    ctx: StateContext<ProjectPlanningRangeStateModel>,
    { settings }: InitProjectPlanningRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetProjectPlanningRangeSettings)
  setProjectPlanningRangeSettings$(
    ctx: StateContext<ProjectPlanningRangeStateModel>,
    { settings }: SetProjectPlanningRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveProjectPlanningRangeSettings(ctx.getState()));
  }

  @Action(ResetProjectPlanningRangeSettings)
  resetProjectPlanningRangeSettings(ctx: StateContext<ProjectPlanningRangeStateModel>): void {
    ctx.setState(defaultProjectPlanningRangeState);
  }

  @Action(SaveProjectPlanningRangeSettings)
  saveProjectPlanningRangeSettings$(
    ctx: StateContext<ProjectPlanningRangeStateModel>,
    { settings }: SaveProjectPlanningRangeSettings,
  ): Observable<void> {
    return this.userService.saveProjectPlanningRangeSettings$(settings);
  }
}
