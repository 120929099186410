import { MetadataModel } from '../metadata.model';
import { UserListResponseMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserListViewResponse } from '../../interfaces/responses/user-list-view.response';
import { UserListViewResponseItemModel } from '../dtos/user-list-view-response-item.dto.model';

export class UserListViewResponseModel {
  constructor(
    readonly items: readonly UserListViewResponseItemModel[],
    readonly metadata: MetadataModel<UserListResponseMetadata> = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: UserListViewResponse): UserListViewResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new UserListViewResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => UserListViewResponseItemModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): UserListViewResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
