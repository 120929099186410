import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserPlanningRangeStateModel } from './user-planning-range-state.model';
import {
  InitUserPlanningRangeSettings,
  ResetUserPlanningRangeSettings,
  SaveUserPlanningRangeSettings,
  SetUserPlanningRangeSettings,
} from './user-planning-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUserPlanningRangeState: UserPlanningRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UserPlanningRangeStateModel>({
  name: 'UserPlanningRange',
  defaults: defaultUserPlanningRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class UserPlanningRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitUserPlanningRangeSettings)
  initUserPlanningRangeSettings(
    ctx: StateContext<UserPlanningRangeStateModel>,
    { settings }: InitUserPlanningRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetUserPlanningRangeSettings)
  setUserPlanningRangeSettings$(
    ctx: StateContext<UserPlanningRangeStateModel>,
    { settings }: SetUserPlanningRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveUserPlanningRangeSettings(ctx.getState()));
  }

  @Action(ResetUserPlanningRangeSettings)
  resetUserPlanningRangeSettings(ctx: StateContext<UserPlanningRangeStateModel>): void {
    ctx.setState(defaultUserPlanningRangeState);
  }

  @Action(SaveUserPlanningRangeSettings)
  saveUserPlanningRangeSettings$(
    ctx: StateContext<UserPlanningRangeStateModel>,
    { settings }: SaveUserPlanningRangeSettings,
  ): Observable<void> {
    return this.userService.saveUserPlanningRangeSettings$(settings);
  }
}
