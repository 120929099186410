import { Selector } from '@ngxs/store';
import { TimesheetRangeStateModel } from './timesheet-range-state.model';
import { TimesheetRangeState } from './timesheet-range.state';

export class TimesheetRangeSelectors {
  @Selector([TimesheetRangeState])
  static range(state: TimesheetRangeStateModel): TimesheetRangeStateModel['range'] {
    return state.range;
  }
}
