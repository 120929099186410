/**
 * Makes a deep clone of the given object.
 *
 * @param {any} value
 */
export function deepCopy<T>(value: T): T {
  if (Array.isArray(value)) {
    return value.map((o: T) => deepCopy(o)) as any;
  }

  if (value && typeof value === 'object') {
    if ((value as any).toJSON) {
      return JSON.parse(((value as any).toJSON as () => string)());
    }

    const copy = new (Object.getPrototypeOf(value).constructor)();

    Object.getOwnPropertyNames(value).forEach((key) => {
      copy[key] = deepCopy((value as any)[key]);
    });

    return copy;
  }

  return value;
}
