import { UnitSelectorDto } from '../../interfaces/dtos/unit-selector.dto';
import { UnitLevel } from '../../../models/unit-level.enum';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { Unit } from '../../../models/unit.interface';

export class UnitSelectorDtoModel implements Unit {
  set disabled(flag: boolean) {
    this._disabled = flag;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  set group(name: string | undefined) {
    this._group = name;
  }

  get group(): string | undefined {
    return this._group;
  }

  /**
   * This property is not set from the backend.
   * It is purely used in the frontend, in order
   * to disable certain elements from the list.
   */
  private _disabled: boolean = false;

  /**
   * This property is not set from the backend.
   * The frontend uses it to distinguish sets of units in a list.
   */
  private _group: string | undefined = undefined;

  constructor(
    readonly unitId: number,
    readonly name: string,
    readonly level: UnitLevel = UnitLevel.UNKNOWN,
    readonly unitCoordinator: UserSlimDtoModel | null,
    readonly parentUnit: UnitSlimDtoModel | null,
  ) {
  }

  static fromJSON(json: UnitSelectorDto): UnitSelectorDtoModel {
    assertRequiredProperties(json, [
      'unitId',
      'name',
    ]);

    return new UnitSelectorDtoModel(
      json.unitId,
      json.name,
      json.level,
      (json.unitCoordinator ? UserSlimDtoModel.fromJSON(json.unitCoordinator) : null),
      (json.parentUnit ? UnitSlimDtoModel.fromJSON(json.parentUnit) : null),
    );
  }
}
