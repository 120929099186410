
@switch (accessErrorType) {
  @case (accessErrorTypes.NONE) {
    <h1>
      You are authenticated!
    </h1>
    <p>
      Use the navigation to start working with the application.
    </p>
    <br />
    <p>
      <ng-container *ngTemplateOutlet="logoutButtonTemplate"></ng-container>
    </p>
  }
  @case (accessErrorTypes.AUTHORIZATION_INVALID) {
    <h1>
      Access denied
    </h1>
    <p>
      Hello {{ basicUserInfo?.fullName }}.
    </p>
    <p>
      Sorry! You cannot log in to CollApp. You are currently not assigned to any unit.
    </p>
    <br />
    <p>
      If you're not {{ basicUserInfo?.fullName }}, please use the logout button to
      close the application and log in with a different user.
    </p>
    <p>
      <ng-container *ngTemplateOutlet="logoutButtonTemplate"></ng-container>
    </p>
  }
  @case (accessErrorTypes.AUTHORIZATION_EXPIRED) {
    <h1>
      Access expired
    </h1>
    <p>
      Hello {{ basicUserInfo?.fullName }}.
    </p>
    <p>
      Sorry! You cannot log in to CollApp. Your account expired on {{ basicUserInfo?.validUntil | momentLocal:'datetime' }} ({{ relativeAccessTime$ | async }}).
    </p>
    <br />
    <p>
      If you're not {{ basicUserInfo?.fullName }}, please use the logout button to
      close the application and log in with a different user.
    </p>
    <p>
      <ng-container *ngTemplateOutlet="logoutButtonTemplate"></ng-container>
    </p>
  }
  @case (accessErrorTypes.AUTHORIZATION_NOT_YET_VALID) {
    <h1>
      Awaiting activation
    </h1>
    <p>
      Hello {{ basicUserInfo?.fullName }}.
    </p>
    <p>
      Sorry! Your account is not yet active. You can log in to CollApp after {{ basicUserInfo?.validFrom | momentLocal:'datetime' }} ({{ relativeAccessTime$ | async }}).
    </p>
    <br />
    <p>
      If you're not {{ basicUserInfo?.fullName }}, please use the logout button to
      close the application and log in with a different user.
    </p>
    <p>
      <ng-container *ngTemplateOutlet="logoutButtonTemplate"></ng-container>
    </p>
  }
  @case (accessErrorTypes.UNAUTHORIZED) {
    <h1>
      There was a problem with your authorization!
    </h1>
    <p>
      You lack the basic privileges to access this application.
    </p>
    @if (error) {
      <p>
        <strong>{{error.name}}</strong><br />
        {{error.message}}<br />
        Code: {{error.code}}
      </p>
    }
    <mat-progress-bar mode="determinate" color="warn" [value]="100"></mat-progress-bar>
    <br />
    <p>
      <ng-container *ngTemplateOutlet="logoutButtonTemplate"></ng-container>
    </p>
  }
  @case (accessErrorTypes.AUTHENTICATION_ERROR) {
    @if (isRedirectingToAuthenticationProvider) {
      <h1>
        You are not authenticated.
      </h1>
      <p>
        Please wait while we redirect you to the login screen.
      </p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    } @else {
      <h1>
        There was a problem with your authentication!
      </h1>
      @if (error) {
        <p>
          <strong>{{error.name}}</strong><br />
          {{error.message}}<br />
          Code: {{error.code}}
        </p>
      }
      <mat-progress-bar mode="determinate" color="warn" [value]="100"></mat-progress-bar>
      <br />
      <p>
        <ng-container *ngTemplateOutlet="loginButtonTemplate"></ng-container>
      </p>
    }
  }
}

<ng-template #loginButtonTemplate>
  <button type="button" collapp-flat-button color="primary" (click)="login()">Login</button>
</ng-template>
<ng-template #logoutButtonTemplate>
  <button type="button" collapp-stroked-button (click)="logout()">Logout</button>
</ng-template>
<ng-template #overlay>
  <img src="https://i.imgur.com/JL1aW25.gif" />
</ng-template>
