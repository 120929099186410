/**
 * Error for missing date implementation.
 */
export function createMissingDateImplError(parent: string): Error {
  return Error(
    `${parent}: No provider found for CollappDateAdapter. You must import the following `
    + 'module at your application root: CollappMomentDateModule, or provide a '
    + 'custom implementation.',
  );
}
