import { ProjectPlanningRangeStateModel } from './project-planning-range-state.model';

/**
 * Action types for ProjectPlanningRangeState
 */
export enum ProjectPlanningRangeActionTypes {
  InitProjectPlanningRangeSettings = '[Project Planning API] Initialize Project Planning Range Settings',
  SetProjectPlanningRangeSettings = '[Project Planning API] Set Project Planning Range Settings',
  ResetProjectPlanningRangeSettings = '[Project Planning API] Reset Project Planning Range Settings',
  SaveProjectPlanningRangeSettings = '[Project Planning API] Save Project Planning Range Settings',
}

export class InitProjectPlanningRangeSettings {
  static readonly type: string = ProjectPlanningRangeActionTypes.InitProjectPlanningRangeSettings;

  constructor(public settings: ProjectPlanningRangeStateModel) {}
}

export class SetProjectPlanningRangeSettings {
  static readonly type: string = ProjectPlanningRangeActionTypes.SetProjectPlanningRangeSettings;

  constructor(public settings: ProjectPlanningRangeStateModel) {}
}

export class ResetProjectPlanningRangeSettings {
  static readonly type: string = ProjectPlanningRangeActionTypes.ResetProjectPlanningRangeSettings;
}

export class SaveProjectPlanningRangeSettings {
  static readonly type: string = ProjectPlanningRangeActionTypes.SaveProjectPlanningRangeSettings;

  constructor(public settings: ProjectPlanningRangeStateModel) {}
}
