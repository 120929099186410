import { UserNotificationViewResponse } from '../../interfaces/responses/user-notification-view.response';
import { UserNotificationRoleDtoModel } from '../dtos/user-notification-role.dto.model';
import { MetadataModel } from '../metadata.model';

export class UserNotificationViewResponseModel {
  constructor(
    readonly roles: UserNotificationRoleDtoModel[],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    if (roles) {
      this.roles = roles.map((role) => role.clone());
    }
  }

  static fromJSON(json: UserNotificationViewResponse): UserNotificationViewResponseModel {
    return new UserNotificationViewResponseModel(
      (Array.isArray(json.roles) ? json.roles : [])
        .map((item) => UserNotificationRoleDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): UserNotificationViewResponse {
    return {
      roles: this.roles.map((role) => role.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
