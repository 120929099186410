import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Unit } from '../../../models/unit.interface';
import { SkillFilterModel } from '../../../components/skill-filter/skill-filter.component';
import { PlanningCategorySelectItem } from '../../../components/planning-category-select';
import { UnitPlanningResourceDtoModel } from '../../../api/models/dtos/unit-planning-resource.dto.model';
import { UnitPlanningStateModel } from './unit-planning-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  AddUnitPlanningRecentUnit,
  InitUnitPlanningSettings,
  SetUnitPlanningFilters,
} from './unit-planning.actions';

const MAX_NUMBER_OF_RECENT_UNITS = 4;

const defaultUnitPlanningState: UnitPlanningStateModel = {
  recentUnits: [],
  unitSettings: {},
};

@State<UnitPlanningStateModel>({
  name: 'unitPlanning',
  defaults: defaultUnitPlanningState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitPlanningState {
  constructor(
    private userService: UserService,
  ) {
  }

  @Selector([UnitPlanningState])
  static recentPlanningUnits(state: UnitPlanningStateModel): readonly Unit[] {
    return state.recentUnits;
  }

  /**
   * Get the most recent / latest unit that was viewed.
   */
  @Selector([UnitPlanningState])
  static mostRecentPlanningUnit(state: UnitPlanningStateModel): Unit | null {
    return state.recentUnits.length > 0 ? state.recentUnits[0] : null;
  }

  @Selector([UnitPlanningState])
  static unitSettingsForUnit(state: UnitPlanningStateModel, unit: number): ({
    selectedSkills?: SkillFilterModel[];
    selectedResources?: UnitPlanningResourceDtoModel[];
    selectedCategories?: PlanningCategorySelectItem[];
  }) | undefined {
    return state.unitSettings?.[unit] || undefined;
  }

  @Action(InitUnitPlanningSettings)
  initUnitPlanningSettingsState(
    ctx: StateContext<UnitPlanningStateModel>,
    { recentUnits }: InitUnitPlanningSettings,
  ): void {
    ctx.patchState({
      recentUnits,
    });
  }

  @Action(AddUnitPlanningRecentUnit)
  addUnitPlanningRecentUnit(
    { patchState, getState }: StateContext<UnitPlanningStateModel>,
    { unit }: AddUnitPlanningRecentUnit,
  ): void {
    const { recentUnits } = getState();

    // Check if given unit already exists in queue
    const existingUnitIndex = recentUnits.findIndex((element) => (element.unitId === unit.unitId));

    // Create a new array of units with the most recent in front.
    const updatedRecentUnits = [unit, ...recentUnits];

    if (existingUnitIndex !== -1) {
      // Remove duplicates
      updatedRecentUnits.splice(existingUnitIndex + 1, 1);
    }

    // Limit queue to a given maximum number of entries.
    updatedRecentUnits.length = Math.min(updatedRecentUnits.length, MAX_NUMBER_OF_RECENT_UNITS);

    patchState({
      recentUnits: updatedRecentUnits,
    });
  }

  @Action(SetUnitPlanningFilters)
  setUnitPlanningFilters(
    { patchState, getState }: StateContext<UnitPlanningStateModel>,
    { unit, data }: SetUnitPlanningFilters,
  ): void {
    const { unitSettings } = getState();

    patchState({
      unitSettings: {
        ...unitSettings,
        [unit]: {
          selectedSkills: data.selectedSkills || unitSettings[unit]?.selectedSkills || [],
          selectedCategories: data.selectedCategories || unitSettings[unit]?.selectedCategories || [],
          selectedResources: data.selectedResources || unitSettings[unit]?.selectedResources || [],
        },
      },
    });
  }
}
