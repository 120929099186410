import moment from 'moment';
import { MetadataModel } from '../metadata.model';
import { UserListDtoMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserListViewResponseItem } from '../../interfaces/dtos/user-list-view-response-item.dto';

export class UserListViewResponseItemModel {
  readonly fullName: string;

  constructor(
    readonly userId: string,
    readonly activeDirectoryId: string,
    readonly email: string,
    readonly photoUrl: string | null,
    readonly hitachiId: string | null,
    readonly globalEmployeeId: string | null,
    readonly localEmployeeId: string | null,
    readonly firstName: string | null,
    readonly lastName: string | null,
    readonly abbreviation: string | null,
    readonly payrollCompany: string | null,
    readonly costCenter: string | null,
    readonly countryCode: string | null,
    readonly city: string | null,
    readonly divisionCode: string | null,
    readonly productGroupCode: string | null,
    readonly unitName: string | null,
    readonly unitCoordinatorFullName: string | null,
    readonly unitCoordinatorId: string | null,
    readonly unitCoordinatorPhotoUrl: string | null,
    readonly validFrom: moment.Moment | null,
    readonly validUntil: moment.Moment | null,
    readonly businessUnitCode: string | null,
    readonly hourlyRateCategory: string | null,
    readonly workingHoursPerWeek: number | null,
    readonly roleName: string | null,
    readonly metadata: UserListDtoMetadata,
  ) {
    if (this.firstName && this.lastName) {
      this.fullName = `${this.firstName} ${this.lastName}`.trim();
    } else {
      this.fullName = '';
    }

    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);
  }

  static fromJSON(json: UserListViewResponseItem): UserListViewResponseItemModel {
    assertRequiredProperties(json, [
      'userId',
      'activeDirectoryId',
      'email',
    ]);

    return new UserListViewResponseItemModel(
      json.userId,
      json.activeDirectoryId,
      json.email,
      json.photoUrl,
      json.hitachiId,
      json.globalEmployeeId,
      json.localEmployeeId,
      json.firstName,
      json.lastName,
      json.abbreviation,
      json.payrollCompany,
      json.costCenter,
      json.countryCode,
      json.city,
      json.divisionCode,
      json.productGroupCode,
      json.unitName,
      json.unitCoordinatorFullName,
      json.unitCoordinatorId,
      json.unitCoordinatorPhotoUrl,
      (json.validFrom
        ? moment(json.validFrom)
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
        : null
      ),
      json.businessUnitCode,
      json.hourlyRateCategory,
      json.workingHoursPerWeek,
      json.roleName,
      new MetadataModel(json.metadata || {}) as UserListDtoMetadata,
    );
  }

  toJSON(): UserListViewResponseItem {
    return {
      userId: this.userId,
      activeDirectoryId: this.activeDirectoryId,
      email: this.email,
      photoUrl: this.photoUrl,
      hitachiId: this.hitachiId,
      globalEmployeeId: this.globalEmployeeId,
      localEmployeeId: this.localEmployeeId,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      abbreviation: this.abbreviation,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      countryCode: this.countryCode,
      city: this.city,
      divisionCode: this.divisionCode,
      productGroupCode: this.productGroupCode,
      unitName: this.unitName,
      unitCoordinatorFullName: this.unitCoordinatorFullName,
      unitCoordinatorId: this.unitCoordinatorId,
      unitCoordinatorPhotoUrl: this.unitCoordinatorPhotoUrl,
      validFrom: this.validFrom ? this.validFrom : null,
      validUntil: this.validUntil ? this.validUntil : null,
      businessUnitCode: this.businessUnitCode,
      hourlyRateCategory: this.hourlyRateCategory,
      workingHoursPerWeek: this.workingHoursPerWeek,
      roleName: this.roleName,
      metadata: this.metadata,
    };
  }
}
