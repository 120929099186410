import {
  Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationInsightsService } from '../../../modules/application-insights';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AdditionalSuppliersAndChrcpService } from '../../../services/additional-suppliers-and-chrcp.service';
import { UntypedFormControl } from '@angular/forms';
import { UnitSkillDtoModel } from '../../../api/models/dtos/unit-skill.dto.model';
import { UnitSelectorDtoModel } from '../../../api/models/dtos/unit-selector.dto.model';
import { ContractDtoModel } from '../../../api/models/dtos/contract.dto.model';
import { CollappWpAddAdditionalSuppliersDialogComponent } from '../collapp-wp-add-additional-suppliers-dialog/collapp-wp-add-additional-suppliers-dialog.component';
import { AddAdditionalSuppliersDialogCloseData, AddAdditionalSuppliersDialogData } from '../collapp-wp-add-additional-suppliers-dialog/collapp-wp-add-additional-suppliers-dialog.types';

@Component({
  selector: 'add-additional-supplier-button',
  templateUrl: './add-additional-supplier-button.component.html',
  styleUrls: ['./add-additional-supplier-button.component.scss'],
})
export class AddAdditionalSupplierButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  projectId!: number;

  @Input()
  workPackageId!: number;

  @Input()
  contractSupplierUnitId!: number;

  @Input()
  canAdd: boolean = false;

  @Input()
  unitControl: UntypedFormControl = new UntypedFormControl();

  @Input()
  existingUnits: UnitSelectorDtoModel[] | UnitSkillDtoModel[] = [];

  forceDisabled: boolean = false;

  private existingUnitIds: number[] = [];

  private readonly destroyed$: Subject<void> = new Subject();

  private addDialogRef: MatDialogRef<
  CollappWpAddAdditionalSuppliersDialogComponent, AddAdditionalSuppliersDialogCloseData
  > | null = null;

  constructor(
    public dialog: MatDialog,
    private appInsightsService: ApplicationInsightsService,
    public additionalSuppliersAndChrcpService: AdditionalSuppliersAndChrcpService,
  ) {
  }

  ngOnInit(): void {
    this.additionalSuppliersAndChrcpService.canAdd = this.canAdd;
    this.initAdditionalSuppliers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existingUnits) {
      this.checkIfDifferentAdditionalUnitsOrDisable();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  addSupplierUnit(): void {
    this.openAdditionalSupplierUnitDialog();
  }

  /**
   * Check if all additional Units are already in existingUnits , if yes, disable the button
   */
  private checkIfDifferentAdditionalUnitsOrDisable(): void {
    // at least one of the additional units must be different from the existing Units
    // really different, not the same with other hrc

    if (!this.additionalSuppliersAndChrcpService.availableContracts
      || this.additionalSuppliersAndChrcpService.availableContracts.length === 0) {
      this.forceDisabled = true;
      return;
    }

    if (this.existingUnits && this.existingUnits.length) {
      // eslint-disable-next-line max-len
      const additionalUnitsIds = this.additionalSuppliersAndChrcpService.availableContracts.map((item) => item.supplierUnit?.unitId);
      this.existingUnitIds = (this.existingUnits as UnitSelectorDtoModel[]).map((existingUnit) => existingUnit.unitId);
      // if all additionalIds are in existingUnits, we should forceDisabled=true
      let areAllInExisting = true;
      for (let i = 0; i < additionalUnitsIds.length; i++) {
        // eslint-disable-next-line max-len
        const foundIndex = this.existingUnitIds.indexOf(additionalUnitsIds[i] || 0);
        if (foundIndex === -1) {
          areAllInExisting = false;
          break;
        }
      }
      if (areAllInExisting) {
        this.forceDisabled = true;
        return;
      }
    }

    this.forceDisabled = false;
  }

  /**
   * Remove the additionalUnits which are already in ExistingUnits
   * Ignore the HRC
   */
  private filterAdditionalUnitsComparedToExisting(): ContractDtoModel[] {
    // eslint-disable-next-line max-len, arrow-body-style
    return this.additionalSuppliersAndChrcpService.availableContracts.filter((availableContract) => {
      return (this.existingUnitIds.indexOf(availableContract.supplierUnit?.unitId || 0) === -1);
    });
  }

  private openAdditionalSupplierUnitDialog(): void {
    const hasAdditionalSuppliers = (this.additionalSuppliersAndChrcpService.availableContracts?.length || 0) > 0;
    if (this.addDialogRef || !this.canAdd || !hasAdditionalSuppliers) {
      return;
    }
    const { contractSupplierUnitId } = this;
    const contracts = this.filterAdditionalUnitsComparedToExisting();
    if (!contractSupplierUnitId || !contracts) {
      this.appInsightsService.trackException(
        new Error('Contract supplier unit id is not defined or no contracts are available'),
      );
      return;
    }
    const dialogData: AddAdditionalSuppliersDialogData = {
      contracts,
    };
    this.addDialogRef = this.dialog.open<
    CollappWpAddAdditionalSuppliersDialogComponent,
    AddAdditionalSuppliersDialogData
    >(CollappWpAddAdditionalSuppliersDialogComponent, {
      data: dialogData,
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--small'],
    });
    this.addDialogRef.afterClosed()
      .pipe(
        // do not use takeUntil because the dialog is closed automatically before the value is emitted
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-takeuntil
      .subscribe((data) => {
        if (!data) {
          return;
        }
        const { chrcps } = data;
        this.additionalSuppliersAndChrcpService.adjustSuppliersByCHRCP(chrcps);
        this.additionalSuppliersAndChrcpService.save$(
          this.projectId,
          this.workPackageId,
        ).subscribe({
          next: this.onDialogSuccess.bind(this),
          error: this.onDialogError.bind(this),
        });
      });
  }

  private onDialogSuccess(): void {
    if (!this.additionalSuppliersAndChrcpService.isModified) {
      return;
    }
    this.unitControl.setValue(
      this.additionalSuppliersAndChrcpService.lastAddedSupplierUnit,
    );
    this.addDialogRef = null;
    this.additionalSuppliersAndChrcpService.reset();
  }

  private onDialogError(error: Error): void {
    this.appInsightsService.trackException(error);
    this.addDialogRef = null;
  }

  private checkInputs(): void {
    if (
      this.canAdd && (
        !this.projectId
        || !this.workPackageId
        || !this.contractSupplierUnitId
      )
    ) {
      const errorMsg = 'Project id, work package id or contract supplier unit id is not defined';
      this.appInsightsService.trackException(
        new Error(errorMsg),
      );
      throw new Error(errorMsg);
    }
  }

  private initAdditionalSuppliers(): void {
    if (!this.canAdd) {
      return;
    }
    this.checkInputs();
    this.additionalSuppliersAndChrcpService.loadData$(
      this.projectId,
      this.workPackageId,
      this.contractSupplierUnitId,
    ).pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.checkIfDifferentAdditionalUnitsOrDisable();
    });
  }
}
