import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserService } from '../../../api/services/user.service';
import { WORK_PACKAGE_ACTIVITIES_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';
import { ActivitiesStateModel } from './activities-state.model';
import {
  InitActivitiesSettings,
  ResetActivitiesSettings,
  SaveActivitiesSettings,
  SetActivitiesSettings,
} from './activities.actions';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultActivitiesState: ActivitiesStateModel = {
  pageSize: WORK_PACKAGE_ACTIVITIES_DEFAULT_PAGE_SIZE,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<ActivitiesStateModel>({
  name: 'activities',
  defaults: defaultActivitiesState,
})
@Injectable({
  providedIn: 'root',
})
export class ActivitiesState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitActivitiesSettings)
  InitActivitiesSettings(ctx: StateContext<ActivitiesStateModel>, { settings }: InitActivitiesSettings): void {
    const { pageSize } = settings;

    ctx.patchState({
      pageSize: (pageSize > 0 ? pageSize : 10),
    });
  }

  @Action(SetActivitiesSettings)
  setActivitiesSettings$(
    ctx: StateContext<ActivitiesStateModel>,
    { settings }: SetActivitiesSettings,
  ): Observable<void> {
    const { pageSize } = settings;

    ctx.patchState({
      pageSize: (pageSize > 0 ? pageSize : 10),
    });

    return ctx.dispatch(new SaveActivitiesSettings(ctx.getState()));
  }

  @Action(ResetActivitiesSettings)
  resetActivitiesSettings(ctx: StateContext<ActivitiesStateModel>): void {
    ctx.setState(defaultActivitiesState);
  }

  @Action(SaveActivitiesSettings)
  saveActivitiesSettings$(
    ctx: StateContext<ActivitiesStateModel>,
    { settings }: SaveActivitiesSettings,
  ): Observable<void> {
    return this.userService.saveActivitiesSettings$(settings);
  }
}
