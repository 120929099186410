import { UnitPlanningTotalsStateModel } from './unit-planning-totals-state.model';

/**
 * Action types for UnitPlanningTotalsState
 */
export enum UnitPlanningTotalsActionTypes {
  InitUnitPlanningTotalsSettings = '[Unit Planning API] Initialize Unit Planning Totals Settings',
  SetUnitPlanningTotalsSettings = '[Unit Planning API] Set Unit Planning Totals Settings',
  ResetUnitPlanningTotalsSettings = '[Unit Planning API] Reset Unit Planning Totals Settings',
  SaveUnitPlanningTotalsSettings = '[Unit Planning API] Save Unit Planning Totals Settings',
}

export class InitUnitPlanningTotalsSettings {
  static readonly type: string = UnitPlanningTotalsActionTypes.InitUnitPlanningTotalsSettings;

  constructor(public settings: UnitPlanningTotalsStateModel) {}
}

export class SetUnitPlanningTotalsSettings {
  static readonly type: string = UnitPlanningTotalsActionTypes.SetUnitPlanningTotalsSettings;

  constructor(public settings: UnitPlanningTotalsStateModel) {}
}

export class ResetUnitPlanningTotalsSettings {
  static readonly type: string = UnitPlanningTotalsActionTypes.ResetUnitPlanningTotalsSettings;
}

export class SaveUnitPlanningTotalsSettings {
  static readonly type: string = UnitPlanningTotalsActionTypes.SaveUnitPlanningTotalsSettings;

  constructor(public settings: UnitPlanningTotalsStateModel) {}
}
