import { MetadataModel } from '../metadata.model';
import { WithPaginationInfo } from '../../interfaces/metadata';
import { WorkPackageViewListResponse } from '../../interfaces/responses/work-package-view-list.response';
import { WorkPackageViewListItemResponseModel } from './work-package-view-list-item.response.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageViewListResponseModel {
  constructor(
    readonly items: readonly WorkPackageViewListItemResponseModel[],
    readonly metadata: MetadataModel<WithPaginationInfo> = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  // TODO: Remove
  // public toJSON(): WorkPackageViewListResponse {
  //   return {
  //     items: this.items.map((item) => item.toJSON()),
  //     metadata: this.metadata.toJSON(),
  //   };
  // }

  static fromJSON(json: WorkPackageViewListResponse): WorkPackageViewListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new WorkPackageViewListResponseModel(
      (Array.isArray(json.items)
        ? json.items
          .map((item) => WorkPackageViewListItemResponseModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata || {}),
    );
  }
}
