import { ContractSaveRequest } from '../../interfaces/requests/contract-save.request';
import { ContractHourlyRateCategoryPairDtoModel } from '../dtos/contract-hourly-rate-category-pair.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ContractSaveRequestModel {
  constructor(
    readonly supplierUnitId: number,
    readonly submitterUnitId: number | null = null,
    readonly contractId: number | null = null,
    readonly model: string | null = null,
    readonly fteHoursWeek: number | null = null,
    readonly hoursPerWeek: number | null = null,
    readonly contractHourlyRateCategoryPairs: readonly ContractHourlyRateCategoryPairDtoModel[] = [],
    readonly removedContractHourlyRateCategoryPairIds: readonly number[] = [],
    readonly employeeNumber: string | null = null,
    readonly costCenter: string | null = null,
    readonly notificationSentTo: string | null = null,
    readonly costObject: string | null = null,
    readonly categoryActivityItem: string | null = null,
  ) {
    this.contractHourlyRateCategoryPairs = [...contractHourlyRateCategoryPairs];
    this.removedContractHourlyRateCategoryPairIds = [...removedContractHourlyRateCategoryPairIds];
  }

  static fromJSON(json: ContractSaveRequest): ContractSaveRequestModel {
    assertRequiredProperties(json, [
      'supplierUnitId',
    ]);

    return new ContractSaveRequestModel(
      json.supplierUnitId,
      json.submitterUnitId,
      json.contractId,
      json.model,
      json.fteHoursWeek,
      json.hoursPerWeek,
      (Array.isArray(json.contractHourlyRateCategoryPairs) ? json.contractHourlyRateCategoryPairs : [])
        .map((item) => ContractHourlyRateCategoryPairDtoModel.fromJSON(item)),
      (Array.isArray(json.removedContractHourlyRateCategoryPairIds)
        ? [...json.removedContractHourlyRateCategoryPairIds]
        : []),
      json.submitterEmployeeNumber,
      json.submitterCostCenter,
      json.notificationSentTo,
      json.costObject,
      json.categoryActivityItem,
    );
  }

  toJSON(): ContractSaveRequest {
    return {
      supplierUnitId: this.supplierUnitId,
      submitterUnitId: this.submitterUnitId,
      contractId: this.contractId,
      model: this.model,
      fteHoursWeek: this.fteHoursWeek,
      hoursPerWeek: this.hoursPerWeek,
      contractHourlyRateCategoryPairs: this.contractHourlyRateCategoryPairs.map((item) => item.toJSON()),
      removedContractHourlyRateCategoryPairIds: [...this.removedContractHourlyRateCategoryPairIds],
      submitterEmployeeNumber: this.employeeNumber,
      submitterCostCenter: this.costCenter,
      notificationSentTo: this.notificationSentTo,
      costObject: this.costObject,
      categoryActivityItem: this.categoryActivityItem,
    };
  }
}
