import { CountryDto } from '../../interfaces/dtos/country.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class CountryDtoModel {
  constructor(
    readonly countryCode: string,
    readonly name: string,
  ) {
  }

  static fromJSON(json: CountryDto): CountryDtoModel {
    assertRequiredProperties(json, [
      'countryCode',
      'name',
    ]);

    return new CountryDtoModel(
      json.countryCode,
      json.name,
    );
  }

  toJSON(): CountryDto {
    return {
      countryCode: this.countryCode,
      name: this.name,
    };
  }
}
