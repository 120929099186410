import { UsersViewResponse } from '../../interfaces/responses/users-view.response';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UsersViewResponseModel {
  readonly canCreateUsers: boolean;

  constructor(
    readonly metadata: MetadataModel,
  ) {
    this.canCreateUsers = (this.metadata.fields && this.metadata.fields.isAllowedToCreateUsers) || false;
  }

  static fromJSON(json: UsersViewResponse): UsersViewResponseModel {
    assertRequiredProperties(json, [
      'metadata',
    ]);

    return new UsersViewResponseModel(
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): UsersViewResponse {
    return {
      metadata: this.metadata.toJSON(),
    };
  }
}
