import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  forkJoin, isObservable, Observable, of,
} from 'rxjs';
import {
  catchError, map, switchMap, take,
} from 'rxjs/operators';
import { ComponentCanDeactivateDirective } from '../common-behaviors/can-deactivate';
import { LayoutService } from '../services/layout.service';
import { LoadingIndicatorService } from '../services/loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard  {
  constructor(
    private layoutService: LayoutService,
    private loadingService: LoadingIndicatorService,
  ) {}

  canDeactivate(
    component: ComponentCanDeactivateDirective,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    // Check main component as well as any other components (navbar, sidebar, aside).
    return forkJoin([
      of(component),
      this.layoutService.sidebarComponent$.pipe(take(1)) as Observable<ComponentCanDeactivateDirective>,
      this.layoutService.navbarComponent$.pipe(take(1)) as Observable<ComponentCanDeactivateDirective>,
      this.layoutService.asideComponent$.pipe(take(1)) as Observable<ComponentCanDeactivateDirective>,
    ])
      .pipe(
        catchError(() => of([] as ComponentCanDeactivateDirective[])),
        switchMap((values) => {
          const innerObservables: (Observable<boolean>)[] = [];

          let j = values.length;
          while (j > 0) {
            j -= 1;
            // Proceed only if component exists an provides the `canDeactivate` method.
            if (values[j] != null && typeof values[j].canDeactivate !== "undefined") {
              const value$ = values[j].canDeactivate(route, state, nextState);
              if (typeof value$ === 'boolean') {
                innerObservables.push(of(value$));
              } else if (isObservable(value$)) {
                innerObservables.push(value$);
              } else {
                throw new Error('Invalid response type given from \'canDeactivate()\'. Type has to resolve to boolean.');
              }
            }
          }

          // Prevent error when list of inner observables is empty.
          if (innerObservables.length === 0) {
            return of([] as boolean[]);
          }

          this.loadingService.addGuardsCheck();

          // Await completion of all the inner observables.
          return forkJoin(innerObservables);
        }),
        map((values) => {
          let canDeactivate = true;
          let j = values.length;
          while (j > 0 && canDeactivate) {
            j -= 1;
            canDeactivate = values[j];
          }

          this.loadingService.removeGuardsCheck();

          return canDeactivate;
        }),
      );
  }
}
