import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UnitPlanningSidepanelStateModel } from './unit-planning-sidepanel-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  CloseUnitPlanningSidepanel,
  InitUnitPlanningSidepanel,
  OpenUnitPlanningSidepanel,
  ToggleUnitPlanningSidepanel,
} from './unit-planning-sidepanel.actions';

const openState: UnitPlanningSidepanelStateModel = { isOpen: true };
const closedState: UnitPlanningSidepanelStateModel = { isOpen: false };
const defaultState: UnitPlanningSidepanelStateModel = openState;

@State<UnitPlanningSidepanelStateModel>({
  name: 'UnitPlanningSidePanel',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitPlanningSidepanelState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitUnitPlanningSidepanel)
  initUnitPlanningSidepanel(
    ctx: StateContext<UnitPlanningSidepanelStateModel>,
    { isOpen }: InitUnitPlanningSidepanel,
  ): void {
    ctx.setState(
      isOpen,
    );
  }

  @Action(OpenUnitPlanningSidepanel)
  openUnitPlanningSidepanel$(ctx: StateContext<UnitPlanningSidepanelStateModel>): Observable<void> {
    ctx.patchState(openState);

    return this.userService.saveUnitPlanningSidePanel$(openState);
  }

  @Action(CloseUnitPlanningSidepanel)
  closeUnitPlanningSidepanel$(ctx: StateContext<UnitPlanningSidepanelStateModel>): Observable<void> {
    ctx.patchState(closedState);

    return this.userService.saveUnitPlanningSidePanel$(closedState);
  }

  @Action(ToggleUnitPlanningSidepanel)
  toggleUnitPlanningSidepanel$(ctx: StateContext<UnitPlanningSidepanelStateModel>): Observable<void> {
    const action = ctx.getState().isOpen ? new CloseUnitPlanningSidepanel() : new OpenUnitPlanningSidepanel();

    return ctx.dispatch(action);
  }
}
