import moment from 'moment';
import { WorkPackageDashboardDto } from '../../interfaces/dtos/work-package-dashboard.dto';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageDashboardDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly displayId: string,
    readonly projectId: number,
    readonly title: string,
    readonly projectNumber: string = '',
    readonly projectTitle: string,
    readonly reportedHours: number,
    readonly numberOfOpenQuestions: number = 0,
    readonly status: WorkPackageStatus = WorkPackageStatus.Draft,
    readonly estimatedStartDate: moment.Moment,
    readonly latestEstimatedEndDate: moment.Moment,
    readonly latestEstimatedHours: number = 0,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly latestPlannedEndDate: moment.Moment | null = null,
    readonly plannedHours: number = 0,
    readonly costToComplete: number = 0,
    readonly latestConfirmedHours: number = 0,
    readonly latestConfirmedEndDate: moment.Moment | null = null,
    readonly numberOfNonHumanResourcesPlanned: number = 0,
    readonly numberOfResponsibleUsers: number = 0,
  ) {
    this.estimatedStartDate = (estimatedStartDate != null ? estimatedStartDate.clone() : estimatedStartDate);
    this.latestEstimatedEndDate = (latestEstimatedEndDate != null
      ? latestEstimatedEndDate.clone()
      : latestEstimatedEndDate);
    this.plannedStartDate = (plannedStartDate != null ? plannedStartDate.clone() : plannedStartDate);
    this.latestPlannedEndDate = (latestPlannedEndDate != null ? latestPlannedEndDate.clone() : latestPlannedEndDate);
    this.latestConfirmedEndDate = (latestConfirmedEndDate != null
      ? latestConfirmedEndDate.clone()
      : latestConfirmedEndDate);
  }

  // eslint-disable-next-line complexity
  static fromJSON(json: WorkPackageDashboardDto): WorkPackageDashboardDtoModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'displayId',
      'projectId',
      'title',
      // 'projectNumber',
      'projectTitle',
      'reportedHours',
      // 'numberOfOpenQuestions',
      'status',
      'estimatedStartDate',
      'latestEstimatedEndDate',
      'latestEstimatedHours',
      // 'plannedStartDate',
      // 'latestPlannedEndDate',
      // 'plannedHours',
      // 'costToComplete',
      // 'latestConfirmedHours',
      // 'latestConfirmedEndDate',
    ]);

    return new WorkPackageDashboardDtoModel(
      json.workPackageId,
      json.displayId,
      json.projectId,
      json.title,
      json.projectNumber ?? undefined,
      json.projectTitle,
      json.reportedHours,
      json.numberOfOpenQuestions || 0,
      json.status,
      moment(json.estimatedStartDate)
        .parseZone(),
      moment(json.latestEstimatedEndDate)
        .parseZone(),
      json.latestEstimatedHours || 0,
      (json.plannedStartDate != null
        ? moment(json.plannedStartDate)
          .parseZone()
        : null
      ),
      (json.latestPlannedEndDate != null
        ? moment(json.latestPlannedEndDate)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.costToComplete || 0,
      json.latestConfirmedHours || 0,
      (json.latestConfirmedEndDate != null
        ? moment(json.latestConfirmedEndDate)
          .parseZone()
        : null
      ),
      json.numberOfNonHumanResourcesPlanned || 0,
      json.numberOfResponsibleUsers || 0,
    );
  }

  toJSON(): WorkPackageDashboardDto {
    return {
      workPackageId: this.workPackageId,
      displayId: this.displayId,
      projectId: this.projectId,
      title: this.title,
      projectNumber: this.projectNumber,
      projectTitle: this.projectTitle,
      reportedHours: this.reportedHours,
      numberOfOpenQuestions: this.numberOfOpenQuestions,
      status: this.status,
      estimatedStartDate: this.estimatedStartDate.toJSON(),
      latestEstimatedEndDate: this.latestEstimatedEndDate.toJSON(),
      latestEstimatedHours: this.latestEstimatedHours,
      plannedStartDate: (this.plannedStartDate != null ? this.plannedStartDate.toJSON() : null),
      latestPlannedEndDate: (this.latestPlannedEndDate != null ? this.latestPlannedEndDate.toJSON() : null),
      plannedHours: this.plannedHours,
      costToComplete: this.costToComplete,
      latestConfirmedHours: this.latestConfirmedHours,
      latestConfirmedEndDate: (this.latestConfirmedEndDate != null ? this.latestConfirmedEndDate.toJSON() : null),
      numberOfNonHumanResourcesPlanned: this.numberOfNonHumanResourcesPlanned,
      numberOfResponsibleUsers: this.numberOfResponsibleUsers,
    };
  }
}
