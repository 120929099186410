import { EnvironmentConfig } from './environmentConfig';

/**
 * This is the environment configuration for the 'staging' context of the project.
 *
 * The following contexts or environments are available:
 *  - development (environment.ts): For local development.
 *  - integration (environment.integration.ts): First deploy after merges. Development built for production.
 *  - integration-local (environment.integration-local.ts): For testing the PWA build in local development.
 *  - staging (environment.staging.ts): Staging environment before production (acceptance tests).
 *  - production (environment.production.ts): Final stage for live application.
 *
 * @type {EnvironmentConfig}
 */
export const environment: EnvironmentConfig = {
  production: true,
  showBanner: true,
  title: 'Staging',
  apiEndpoint: '//api.stage.collapp.hitachienergy.com',
  msalApiEndpointResource: 'https://graph.windows.net',
  msalConfig: {
    apiEndpointId: '69963e93-264c-4246-95b7-12aed1ec6b55',
    clientId: '69963e93-264c-4246-95b7-12aed1ec6b55',
    instance: 'https://login.microsoftonline.com/7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195/',
    cacheLocation: 'localStorage',
    endpoints: {
      'https://api.stage.collapp.hitachienergy.com': 'https://graph.windows.net',
    },
  },
  msalLoggingEnabled: false,
  azureBlobServiceUrl: 'https://abbcollappeustageg.blob.core.windows.net/?sv=2018-03-28&ss=b&srt=sco&sp=wd&se=2222-02-27T22:43:17Z&st=2019-02-27T14:43:17Z&spr=https&sig=v6nMf1KXAvh1GcqdKl0BEPuI4XCRLehs%2Ftt9Ei2lkYg%3D',
  azureBlobTemporaryAttachmentsContainer: 'attachments-temp',
  instrumentationKey: '51ed924c-d9fb-4cdd-97c3-3389b8e20210',
  ngxsDevelopmentModeEnabled: false,
  ngxsReduxDevtoolsEnabled: false,
  ngxsLoggingEnabled: false,
};
