import moment from 'moment';
import { NonHumanResourcePlanningEntryDto, PlanningEntryDtoMetadata } from '../../interfaces/dtos/planning-entry.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { MetadataModel } from '../metadata.model';

export class NonHumanResourcePlanningEntryDtoModel {
  /**
   * Derived from the received Meta Data
   * for easier access
   */
  readonly canUpdate: boolean;

  constructor(
    readonly planningEntryId: number,
    readonly nonHumanResourceId: string,
    readonly weekStartDate: moment.Moment,
    readonly plannedHours: number | null = null,
    readonly unitBackgroundColorId: number | null = null,
    readonly workingDays: string | null,
    readonly note: string | null,
    readonly noteCreatedBy: string | null,
    readonly noteCreatedOn: moment.Moment | null,
    public holidayDescription: string | null = null,
    public holidayHours: number,
    readonly workPackageId?: number | null,
    readonly unitCategoryId?: number | null,
    readonly metadata: MetadataModel<PlanningEntryDtoMetadata> = new MetadataModel(),
  ) {
    this.weekStartDate = (weekStartDate != null ? weekStartDate.clone() : weekStartDate);
    this.canUpdate = (this.metadata.fields && this.metadata.fields.canUpdatePlannedEntry) || false;
    this.noteCreatedOn = (noteCreatedOn != null ? noteCreatedOn.clone() : noteCreatedOn);
  }

  static fromJSON(json: NonHumanResourcePlanningEntryDto): NonHumanResourcePlanningEntryDtoModel {
    assertRequiredProperties(json, [
      'planningEntryId',
      'nonHumanResourceId',
      'weekStartDate',
    ]);

    return new NonHumanResourcePlanningEntryDtoModel(
      json.planningEntryId,
      json.nonHumanResourceId,
      moment(json.weekStartDate)
        .parseZone(),
      json.plannedHours,
      json.unitBackgroundColorId,
      json.workingDays,
      json.note,
      json.noteCreatedBy,
      json.noteCreatedOn ? moment(json.noteCreatedOn).parseZone() : null,
      json.holidayDescription,
      json.holidayHours,
      json.workPackageId,
      json.unitCategoryId,
      new MetadataModel(json.metadata),
    );
  }

  clone(): NonHumanResourcePlanningEntryDtoModel {
    return new NonHumanResourcePlanningEntryDtoModel(
      this.planningEntryId,
      this.nonHumanResourceId,
      this.weekStartDate,
      this.plannedHours,
      this.unitBackgroundColorId,
      this.workingDays,
      this.note,
      this.noteCreatedBy,
      this.noteCreatedOn,
      this.holidayDescription,
      this.holidayHours,
      this.workPackageId,
      this.unitCategoryId,
      this.metadata.clone(),
    );
  }

  toJSON(): NonHumanResourcePlanningEntryDto {
    return {
      workPackageId: this.workPackageId,
      nonHumanResourceId: this.nonHumanResourceId,
      weekStartDate: this.weekStartDate.toJSON(),
      plannedHours: this.plannedHours,
      unitBackgroundColorId: this.unitBackgroundColorId,
      workingDays: this.workingDays,
      note: this.note,
      noteCreatedBy: this.noteCreatedBy,
      noteCreatedOn: this.noteCreatedOn ? this.noteCreatedOn.toJSON() : null,
      planningEntryId: this.planningEntryId,
      unitCategoryId: this.unitCategoryId,
      metadata: this.metadata.toJSON(),
      holidayDescription: this.holidayDescription,
      holidayHours: this.holidayHours,
    };
  }
}
