import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SettingsStateModel } from './settings-state.model';
import { SetTestString } from './settings.actions';
import { AttachmentListState } from './attachment-list';
import { DashboardState } from './dashboard';
import { ProjectListState } from './project-list';
import { TimecardsState } from './timecards';
import { TimesheetState } from './timesheet';
import { UnitListState } from './unit-list';
import { UserListState } from './user-list';
import { WorkPackageListState } from './work-package-list';
import { WorkPackageQuestionListState } from './work-package-question-list';
import { QuestionsListState } from './questions-list';
import { UserResourceListState } from './user-resource-list';
import { NonHumanResourceListState } from './non-human-resource-list';
import { WorkPackageState } from './work-package';
import { SkillsState } from './skills';
import { ActivitiesState } from './activities';
import { UserPlanningTotalsState } from './user-planning-totals/user-planning-totals.state';
import { UnitPlanningTotalsState } from './unit-planning-totals';
import { UnitPlanningRangeState } from './unit-planning-range/unit-planning-range.state';
import { UserPlanningRangeState } from './user-planning-range/user-planning-range.state';
import { TimesheetRangeState } from './timesheet-range/timesheet-range.state';
import { TimecardRangeState } from './timecard-range/timecard-range.state';
import { WorkPackagePlanningRangeState } from './work-package-planning-range/work-package-planning-range.state';
import { WorkPackagePlanningTotalsState } from './work-package-planning-totals/work-package-planning-totals.state';
import { TimesheetTotalsState } from './timesheet-totals/timesheet-totals.state';
import { WorkpackageSidepanelState } from './work-package-sidepanel/work-package-sidepanel.state';
import { UserPlanningSidepanelState } from './user-planning-sidepanel/user-planning-sidepanel.state';
import { ProjectPlanningTotalsState } from './project-planning-totals/project-planning-totals.state';
import { UnitPlanningState } from './unit-planning/unit-planning.state';

const defaultSettingState: SettingsStateModel = {
  test: null,
};

@State<SettingsStateModel>({
  name: 'settings',
  defaults: defaultSettingState,
  children: [
    AttachmentListState,
    DashboardState,
    ProjectListState,
    TimecardsState,
    TimesheetState,
    SkillsState,
    UnitPlanningState,
    UnitListState,
    NonHumanResourceListState,
    UserListState,
    WorkPackageListState,
    WorkPackageQuestionListState,
    WorkPackageState,
    QuestionsListState,
    UserResourceListState,
    ActivitiesState,
    UserPlanningTotalsState,
    UnitPlanningTotalsState,
    ProjectPlanningTotalsState,
    UserPlanningRangeState,
    UnitPlanningRangeState,
    TimesheetRangeState,
    TimecardRangeState,
    WorkPackagePlanningRangeState,
    WorkPackagePlanningTotalsState,
    TimesheetTotalsState,
    WorkpackageSidepanelState,
    UserPlanningSidepanelState,
  ],
})
@Injectable({
  providedIn: 'root',
})
export class SettingsState {
  @Action(SetTestString)
  setLoginUser({ patchState }: StateContext<SettingsStateModel>, { test }: SetTestString): void {
    patchState({
      test,
    });
  }
}
