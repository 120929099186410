import { SkillDto } from '../../interfaces/dtos/skill.dto';

export class SkillDtoModel {
  constructor(
    readonly categoryId: number,
    readonly isSelected: boolean,
    readonly id: number,
    readonly name: string | null,
  ) {}

  static fromJSON(json: SkillDto): SkillDtoModel {
    return new SkillDtoModel(
      json.categoryId,
      json.isSelected,
      json.id,
      json.name,
    );
  }

  clone(): SkillDtoModel {
    return new SkillDtoModel(
      this.categoryId,
      this.isSelected,
      this.id,
      this.name,
    );
  }

  toJSON(): SkillDto {
    return {
      categoryId: this.categoryId,
      isSelected: this.isSelected,
      id: this.id,
      name: this.name,
    };
  }
}
