import { Selector } from '@ngxs/store';
import { UnitPlanningRangeStateModel } from './unit-planning-range-state.model';
import { UnitPlanningRangeState } from './unit-planning-range.state';

export class UnitPlanningRangeSelectors {
  @Selector([UnitPlanningRangeState])
  static range(state: UnitPlanningRangeStateModel): UnitPlanningRangeStateModel['range'] {
    return state.range;
  }
}
