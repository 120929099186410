import { Selector } from '@ngxs/store';
import { QuestionsListState } from './questions-list.state';
import { QuestionsListOptions } from './questions-list.actions';
import { QuestionsListStateModel } from './questions-list-state.model';

export class QuestionsListSelectors {
  @Selector([QuestionsListState])
  static selectedColumns(state: QuestionsListStateModel): QuestionsListStateModel['selectedColumns'] {
    return state.selectedColumns;
  }

  @Selector([QuestionsListState])
  static options(state: QuestionsListStateModel): QuestionsListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: state.columnFilters,
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
