/**
 * The behavior of this method differs from that of the JavaScript Math.round() function.
 * In the following case for example, the method rounds down where Math.round() rounds up:
 *
 * <code><pre>
 *   roundArithmetic(-2.5)
 *   -3
 *   Math.round(-2.5)
 *   -2
 * </pre></code>
 *
 * @param value  -  The number to be rounded.
 */
export function roundArithmetic(value: number): number {
  return (value >= 0 ? 1 : -1) * Math.round(Math.abs(value));
}

/**
 * Checks whether the given value `a` is greater than value `b`, limited by a given number of fraction digits.
 * Numbers are rounded before comparison and not just "cut off" after the offset.
 *
 * <code><pre>
 *   greaterThan(1.235, 1.2348, 3)
 *   false
 *   greaterThan(1.2348, 1.2354, 3)
 *   false
 *   greaterThan(1.2356, 1.2354, 3)
 *   true
 * </pre></code>
 */
export function greaterThan(a: number, b: number, fractionDigits: number = 0): boolean {
  return Number.parseFloat(a.toFixed(fractionDigits)) > Number.parseFloat(b.toFixed(fractionDigits));
}

/**
 * Checks whether the given value `a` is greater than or equal to value `b`, limited by a given number
 * of fraction digits. Numbers are rounded before comparison and not just "cut off" after the offset.
 *
 * <code><pre>
 *   greaterThan(1.235, 1.2348, 3)
 *   true
 *   greaterThan(1.2348, 1.2354, 3)
 *   true
 *   greaterThan(1.2356, 1.2354, 3)
 *   true
 * </pre></code>
 */
export function greaterThanOrEqual(a: number, b: number, fractionDigits: number = 0): boolean {
  return Number.parseFloat(a.toFixed(fractionDigits)) >= Number.parseFloat(b.toFixed(fractionDigits));
}

/**
 * Checks whether the given value `a` is less than value `b`, limited by a given number of fraction digits.
 * Numbers are rounded before comparison and not just "cut off" after the offset.
 *
 * @see `greaterThan` with parameters a and be switched.
 */
export function lessThan(a: number, b: number, fractionDigits: number = 0): boolean {
  return greaterThan(b, a, fractionDigits);
}

/**
 * Checks whether the given value `a` is less than or equal to value `b`, limited by a given number of fraction digits.
 * Numbers are rounded before comparison and not just "cut off" after the offset.
 *
 * @see `greaterThanOrEqual` with parameters a and be switched.
 */
export function lessThanOrEqual(a: number, b: number, fractionDigits: number = 0): boolean {
  return greaterThanOrEqual(b, a, fractionDigits);
}

/**
 * Checks whether a given value is a number or can be interpreted as a number.
 *
 * WARNING: Can not yet detect ' 2' as not a number (ignores whitespace).
 */
export function isNumber(value?: any): boolean {
  return (typeof value === 'string' || typeof value === 'number') && value !== '' && !Number.isNaN(+value);
}

export function roundTo2Decimals(value: number): number {
  return Math.round(value * 100) / 100;
}
