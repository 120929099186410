import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomerMissingTranslationHandler implements MissingTranslationHandler {

  /**
   * This code is called when a translation is missing. Instead of using the fallback
   * language we're rendering the currently set language as prefix plus the actual
   * i18n key, so that it can easily be recognized.
   *
   * @param params
   * @returns {string}
   */
  handle(params: MissingTranslationHandlerParams): string {
    return params.key;
  }
}
