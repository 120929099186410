import { AttachmentCreateRequest } from '../../interfaces/requests/attachment-create.request';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AttachmentCreateRequestModel implements ApiModelNew {
  constructor(
    readonly blobUrl: string,
    readonly blobName: string,
    readonly fileName: string,
  ) {
  }

  static fromJSON(json: AttachmentCreateRequest): AttachmentCreateRequestModel {
    assertRequiredProperties(json, [
      'blobUrl',
      'blobName',
      'fileName',
    ]);

    return new AttachmentCreateRequestModel(
      json.blobUrl,
      json.blobName,
      json.fileName,
    );
  }

  clone(): AttachmentCreateRequestModel {
    return new AttachmentCreateRequestModel(
      this.blobUrl,
      this.blobName,
      this.fileName,
    );
  }

  toJSON(): AttachmentCreateRequest {
    return {
      blobUrl: this.blobUrl,
      blobName: this.blobName,
      fileName: this.fileName,
    };
  }
}
