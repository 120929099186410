import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { WorkPackageColumnFilters } from '../filter-columns';
import { ReadonlyCompleteMap } from '../../app.types';
import { WorkPackageViewListItemResponseModel } from '../models/responses/work-package-view-list-item.response.model';

export type WorkPackageViewListColumnKeys = ObjectPropertyNames<WorkPackageViewListItemResponseModel>;

// This defines the object that should implement all properties of 'WorkPackageListDto' exactly.
// It's used for linting mostly and discarded later.
const workPackageViewListColumnsInfoObj: ListColumnsInfo<WorkPackageViewListItemResponseModel> = {
  status: {
    label: 'Status',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  workPackageId: {
    label: 'WP-ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  displayId: {
    label: 'WP-ID',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  projectId: {
    label: 'Project ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  workPackageTitle: {
    label: 'Title',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  projectNumber: {
    label: 'Project Number',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  projectTitle: {
    label: 'Project',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  levelTitle: {
    label: 'Project Level',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  sequenceNumber: {
    label: 'Sequence Number',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  scope: {
    label: 'Scope',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  creatorFullName: {
    label: 'WP Creator',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  latestEstimatedStartDate: {
    label: 'Submitter Estimated Start Date',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  latestEstimatedEndDate: {
    label: 'Submitter Estimated End Date',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  latestEstimatedHours: {
    label: 'Submitter Estimated Hours',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  estimatedStartDate: {
    label: 'Submitter Estimated Start Date',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  estimatedEndDate: {
    label: 'Submitter Estimated End Date',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  estimatedHours: {
    label: 'Submitter Estimated Hours',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  confirmedStartDate: {
    label: 'Supplier Confirmed Start Date',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  openQuestionsCount: {
    label: 'Open Questions Count',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  confirmedEndDate: {
    label: 'Supplier Confirmed End Date',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  confirmedHours: {
    label: 'Supplier Confirmed Hours',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  latestConfirmedEndDate: {
    label: 'Supplier Confirmed End Date',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  latestConfirmedHours: {
    label: 'Supplier Confirmed Hours',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  plannedStartDate: {
    label: 'Supplier Planned Start Date',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  latestPlannedEndDate: {
    label: 'Supplier Planned End Date',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  plannedHours: {
    label: 'Supplier Planned Hours',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  reportedHours: {
    label: 'work-package.reported-hours',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  supplierUnit: {
    label: 'Supplier Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  supplierUnitCoordinatorFullName: {
    label: 'Supplier Unit Coordinator',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  submitterHourlyRateCategory: {
    label: 'work-package.submitter.hrc',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  supplierHourlyRateCategory: {
    label: 'work-package.supplier.hrc',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  isFlagged: {
    label: 'Flagged',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  submitterUnit: {
    label: 'Submitter Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  submitterUnitCoordinatorFullName: {
    label: 'Submitter Unit Coordinator',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  coordinator: {
    label: 'WP Coordinator',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  responsibles: {
    label: 'WP Responsibles',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  submitterProjectTypeCode: {
    label: 'work-package.submitter.project-type',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  supplierProjectTypeCode: {
    label: 'work-package.supplier.project-type',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  supplierProjectNumberANDActivityItemCategoryNumber: {
    label: 'work-package.supplier.project-number-activity-category-item',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  submitterProjectNumberANDActivityItemCategoryNumber: {
    label: 'work-package.submitter.project-number-activity-category-item',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  pmFullName: {
    label: 'common.project-manager',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  technicalPMFullName: {
    label: 'common.technical-project-manager',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  additionalSupplierProjectTypeCodes: {
    label: 'work-package.additional-supplier.project-type',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  additionalSupplierProjectNumberANDActivityItemCategoryNumbers: {
    label: 'work-package.additional-supplier.project-number-activity-category-item',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  additionalSupplierUnitNames: {
    label: 'work-package.additional-supplier.units',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  additionalSupplierHourlyRateCategory: {
    label: 'work-package.additional-supplier.hrcs',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  contractName: {
    label: 'common.contract',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const workPackageViewListColumnsInfo: ReadonlyCompleteMap<
WorkPackageViewListColumnKeys,
ListColumnInfo
> = new Map([
  ['workPackageId', workPackageViewListColumnsInfoObj.workPackageId],
  ['displayId', workPackageViewListColumnsInfoObj.displayId],
  ['projectId', workPackageViewListColumnsInfoObj.projectId],
  ['projectTitle', workPackageViewListColumnsInfoObj.projectTitle],
  ['levelTitle', workPackageViewListColumnsInfoObj.levelTitle],
  ['sequenceNumber', workPackageViewListColumnsInfoObj.sequenceNumber],
  ['workPackageTitle', workPackageViewListColumnsInfoObj.workPackageTitle],
  ['status', workPackageViewListColumnsInfoObj.status],
  ['scope', workPackageViewListColumnsInfoObj.scope],
  ['openQuestionsCount', workPackageViewListColumnsInfoObj.openQuestionsCount],

  ['latestEstimatedStartDate', workPackageViewListColumnsInfoObj.latestEstimatedStartDate],
  ['latestEstimatedEndDate', workPackageViewListColumnsInfoObj.latestEstimatedEndDate],
  ['latestEstimatedHours', workPackageViewListColumnsInfoObj.latestEstimatedHours],

  ['confirmedStartDate', workPackageViewListColumnsInfoObj.confirmedStartDate],
  ['latestConfirmedEndDate', workPackageViewListColumnsInfoObj.latestConfirmedEndDate],
  ['latestConfirmedHours', workPackageViewListColumnsInfoObj.latestConfirmedHours],

  ['plannedStartDate', workPackageViewListColumnsInfoObj.plannedStartDate],
  ['latestPlannedEndDate', workPackageViewListColumnsInfoObj.latestPlannedEndDate],
  ['plannedHours', workPackageViewListColumnsInfoObj.plannedHours],

  ['reportedHours', workPackageViewListColumnsInfoObj.reportedHours],
  ['contractName', workPackageViewListColumnsInfoObj.contractName],

  ['submitterUnit', workPackageViewListColumnsInfoObj.submitterUnit],
  ['submitterProjectTypeCode', workPackageViewListColumnsInfoObj.submitterProjectTypeCode],
  ['submitterProjectNumberANDActivityItemCategoryNumber', workPackageViewListColumnsInfoObj.submitterProjectNumberANDActivityItemCategoryNumber],
  ['submitterHourlyRateCategory', workPackageViewListColumnsInfoObj.submitterHourlyRateCategory],

  ['supplierUnit', workPackageViewListColumnsInfoObj.supplierUnit],
  ['supplierProjectTypeCode', workPackageViewListColumnsInfoObj.supplierProjectTypeCode],
  ['supplierProjectNumberANDActivityItemCategoryNumber', workPackageViewListColumnsInfoObj.supplierProjectNumberANDActivityItemCategoryNumber],
  ['supplierHourlyRateCategory', workPackageViewListColumnsInfoObj.supplierHourlyRateCategory],

  ['additionalSupplierUnitNames', workPackageViewListColumnsInfoObj.additionalSupplierUnitNames],
  ['additionalSupplierProjectTypeCodes', workPackageViewListColumnsInfoObj.additionalSupplierProjectTypeCodes],
  ['additionalSupplierProjectNumberANDActivityItemCategoryNumbers', workPackageViewListColumnsInfoObj.additionalSupplierProjectNumberANDActivityItemCategoryNumbers],
  ['additionalSupplierHourlyRateCategory', workPackageViewListColumnsInfoObj.additionalSupplierHourlyRateCategory],

  ['pmFullName', workPackageViewListColumnsInfoObj.pmFullName],
  ['technicalPMFullName', workPackageViewListColumnsInfoObj.technicalPMFullName],
  ['submitterUnitCoordinatorFullName', workPackageViewListColumnsInfoObj.submitterUnitCoordinatorFullName],
  ['creatorFullName', workPackageViewListColumnsInfoObj.creatorFullName],
  ['supplierUnitCoordinatorFullName', workPackageViewListColumnsInfoObj.supplierUnitCoordinatorFullName],
  ['coordinator', workPackageViewListColumnsInfoObj.coordinator],
  ['responsibles', workPackageViewListColumnsInfoObj.responsibles],

  ['isFlagged', workPackageViewListColumnsInfoObj.isFlagged]
]) as ReadonlyCompleteMap<WorkPackageViewListColumnKeys, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const workPackageViewListColumnsWithFilters: (keyof WorkPackageColumnFilters)[] = Array.from(
  workPackageViewListColumnsInfo.entries(),
)
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof WorkPackageColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
