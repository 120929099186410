import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService implements OnDestroy {
  isLoading$: Observable<boolean>;

  isGuardsCheckInProgress$: Observable<boolean>;

  get isLoading(): boolean { return this.loadingRequestsSubject$.value > 0; }

  get isGuardsCheckInProgress(): boolean { return this.guardChecksSubject$.value > 0; }

  private loadingRequestsSubject$: BehaviorSubject<number> = new BehaviorSubject(0);

  private guardChecksSubject$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {
    this.isLoading$ = this.loadingRequestsSubject$
      .asObservable()
      .pipe(
        map((count) => count > 0),
        distinctUntilChanged(),
      );
    this.isGuardsCheckInProgress$ = this.guardChecksSubject$
      .asObservable()
      .pipe(
        map((count) => count > 0),
        distinctUntilChanged(),
      );
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    this.loadingRequestsSubject$.complete();
    this.guardChecksSubject$.complete();
  }

  /**
   * Adds a new loading request to the stack.
   */
  addLoadingRequest(): void {
    this.loadingRequestsSubject$.next(this.loadingRequestsSubject$.value + 1);
  }

  /**
   * Removes a loading request from the stack.
   */
  removeLoadingRequest(): void {
    if (this.loadingRequestsSubject$.value > 0) {
      this.loadingRequestsSubject$.next(this.loadingRequestsSubject$.value - 1);
    }
  }

  /**
   * Adds a new guards check to the stack.
   */
  addGuardsCheck(): void {
    this.guardChecksSubject$.next(this.guardChecksSubject$.value + 1);
  }

  /**
   * Removes a guards check from the stack.
   */
  removeGuardsCheck(): void {
    if (this.guardChecksSubject$.value > 0) {
      this.guardChecksSubject$.next(this.guardChecksSubject$.value - 1);
    }
  }
}
