import { Selector } from '@ngxs/store';
import { TimesheetState } from './timesheet.state';
import { TimesheetStateModel } from './timesheet-state.model';
import { UnitSlimDtoModel } from '../../../api/models/dtos/unit-slim.dto.model';
import { UnitPlanningSlimDtoModel } from '../../../api/models/dtos/unit-planning-slim.dto.model';

export class TimesheetSelectors {
  @Selector([TimesheetState])
  static withCrossUtilizedUsers(state: TimesheetStateModel): boolean {
    return state.withCrossUtilization;
  }

  @Selector([TimesheetState])
  static recentTimesheetUnits(
    state: TimesheetStateModel,
  ): readonly (UnitSlimDtoModel | UnitPlanningSlimDtoModel)[] {
    return state.recentUnits;
  }

  /**
   * Get the most recent / latest unit that was viewed.
   */
  @Selector([TimesheetState])
  static mostRecentTimesheetUnit(
    state: TimesheetStateModel,
  ): UnitSlimDtoModel | UnitPlanningSlimDtoModel | undefined {
    return state.recentUnits.length > 0 ? state.recentUnits[0] : undefined;
  }

  @Selector([TimesheetState])
  static collapsedUnitIds(state: TimesheetStateModel): number[] {
    return [...state.collapsedUnitIds];
  }
}
