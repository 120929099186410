import { UserPlanningTimeTrackingRowModel } from '../../../models/user-planning-time-tracking-row';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningTimeTrackingEntryViewDtoModel } from './user-planning-week-tracking-entry-view.dto.model';
import { UserPlanningTimeTrackingRowDto } from '../../interfaces/dtos/user-planning-time-tracking.dto';

export class UserPlanningTimeTrackingRowDtoModel extends UserPlanningTimeTrackingRowModel {
  constructor(
    public weeks: UserPlanningTimeTrackingEntryViewDtoModel[],
    readonly unitCategoryId: number | null = null,
    readonly categoryName: string | null = null,
    readonly sapCode: string | null = null,
    readonly isVisible: boolean,
    public unitName: string | null = null,
    readonly totalPlanned: number | null = null,
    readonly totalRecorded: number | null = null,
    readonly isEnabled: boolean = true,
    public canDelete: boolean = false,
  ) {
    super(weeks);
  }

  static fromJSON(json: UserPlanningTimeTrackingRowDto): UserPlanningTimeTrackingRowDtoModel {
    assertRequiredProperties(json, [
      'weeks',
    ]);

    return new UserPlanningTimeTrackingRowDtoModel(
      (Array.isArray(json.weeks) ? json.weeks : [])
        .map((item) => UserPlanningTimeTrackingEntryViewDtoModel.fromJSON(item)),
      json.unitCategoryId,
      json.categoryName,
      json.sapCode,
      json.isVisible,
      json.unitName,
      json.totalPlanned,
      json.totalRecorded,
      json.isEnabled,
    );
  }

  clone(): UserPlanningTimeTrackingRowDtoModel {
    return new UserPlanningTimeTrackingRowDtoModel(
      this.weeks.map((item) => item.clone()),
      this.unitCategoryId,
      this.categoryName,
      this.sapCode,
      this.isVisible,
      this.unitName,
      this.totalPlanned,
      this.totalRecorded,
      this.isEnabled,
    );
  }

  toJSON(): UserPlanningTimeTrackingRowDto {
    return {
      weeks: this.weeks.map((item) => item.toJSON()),
      unitCategoryId: this.unitCategoryId,
      categoryName: this.categoryName,
      sapCode: this.sapCode,
      isVisible: this.isVisible,
      unitName: this.unitName,
      totalPlanned: this.totalPlanned,
      totalRecorded: this.totalRecorded,
      isEnabled: this.isEnabled,
    };
  }
}
