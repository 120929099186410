import { UnitPlanningSlimDtoModel } from './unit-planning-slim.dto.model';
import { UnitPlanningUserDtoModel } from './unit-planning-user.dto.model';
import { UnitPlanningDto } from '../../interfaces/dtos/unit-planning.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitPlanningNonHumanResourceDtoModel } from './unit-planning-non-human-resource.dto.model';
import { UnitPlanningWeekTotalDtoModel } from './unit-planning-week-total.dto.model';

export class UnitPlanningDtoModel {
  constructor(
    readonly unit: UnitPlanningSlimDtoModel,
    readonly users: UnitPlanningUserDtoModel[] | null,
    readonly nonHumanResources: UnitPlanningNonHumanResourceDtoModel[] | null,
    readonly weeksTotal: UnitPlanningWeekTotalDtoModel[] | null,
    readonly totalPlannedHours: number | null,
  ) {
    if (users) {
      this.users = [...users];
    }

    if (nonHumanResources) {
      this.nonHumanResources = [...nonHumanResources];
    }
  }

  static fromJSON(json: UnitPlanningDto): UnitPlanningDtoModel {
    assertRequiredProperties(json, [
      'unit',
    ]);

    return new UnitPlanningDtoModel(
      UnitPlanningSlimDtoModel.fromJSON(json.unit),
      (Array.isArray(json.users) ? json.users : [])
        .map(UnitPlanningUserDtoModel.fromJSON),
      (Array.isArray(json.nonHumanResources) ? json.nonHumanResources : [])
        .map(UnitPlanningNonHumanResourceDtoModel.fromJSON),
      (Array.isArray(json.weeksTotal) ? json.weeksTotal : [])
        .map(UnitPlanningWeekTotalDtoModel.fromJSON),
      json.totalPlannedHours,
    );
  }

  clone(): UnitPlanningDtoModel {
    return new UnitPlanningDtoModel(
      this.unit.clone(),
      this.users?.map((item) => item.clone()) || null,
      this.nonHumanResources?.map((item) => item.clone()) || null,
      this.weeksTotal?.map((item) => item.clone()) || null,
      this.totalPlannedHours,
    );
  }
}
