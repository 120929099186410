import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { QuestionsListStateModel } from './questions-list-state.model';
import {
  InitQuestionsListSettings,
  ResetQuestionsListOptions,
  ResetQuestionsListSettings,
  SaveQuestionsListSettings,
  SetQuestionsListOptions,
  SetQuestionsListSelectedColumns,
  SetQuestionsListSettings,
} from './questions-list.actions';
import { questionsViewListColumnsInfo } from '../../../api/meta-info/questions-list.info';
import { UserService } from '../../../api/services/user.service';
import { QUESTIONS_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultQuestionsListState: QuestionsListStateModel = {
  selectedColumns: Array.from(questionsViewListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: QUESTIONS_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<QuestionsListStateModel>({
  name: 'questionsList',
  defaults: defaultQuestionsListState,
})
@Injectable({
  providedIn: 'root',
})
export class QuestionsListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetQuestionsListSelectedColumns)
  setQuestionsListSelectedColumns$(
    ctx: StateContext<QuestionsListStateModel>,
    { columns }: SetQuestionsListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, questionsViewListColumnsInfo));

    return ctx.dispatch(new SaveQuestionsListSettings(ctx.getState()));
  }

  @Action(SetQuestionsListOptions)
  setQuestionsListOptions$(
    ctx: StateContext<QuestionsListStateModel>,
    { options }: SetQuestionsListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, questionsViewListColumnsInfo));

    return ctx.dispatch(new SaveQuestionsListSettings(ctx.getState()));
  }

  @Action(ResetQuestionsListOptions)
  resetQuestionsListOptions$(ctx: StateContext<QuestionsListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveQuestionsListSettings(ctx.getState()));
  }

  @Action(InitQuestionsListSettings)
  initQuestionsListSettings(
    ctx: StateContext<QuestionsListStateModel>,
    { settings }: InitQuestionsListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, questionsViewListColumnsInfo));
  }

  @Action(SetQuestionsListSettings)
  setQuestionsListSettings$(
    ctx: StateContext<QuestionsListStateModel>,
    { settings }: SetQuestionsListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, questionsViewListColumnsInfo));

    return ctx.dispatch(new SaveQuestionsListSettings(ctx.getState()));
  }

  @Action(ResetQuestionsListSettings)
  resetQuestionsListSettings(ctx: StateContext<QuestionsListStateModel>): void {
    ctx.setState(defaultQuestionsListState);
  }

  @Action(SaveQuestionsListSettings)
  saveQuestionsListSettings$(
    ctx: StateContext<QuestionsListStateModel>,
    { settings }: SaveQuestionsListSettings,
  ): Observable<void> {
    return this.userService.SaveQuestionsListSettings$(settings);
  }
}
