import { UnitSelectorListResponse } from '../../interfaces/responses/unit-selector-list.response';
import { UnitSelectorDtoModel } from '../dtos/unit-selector.dto.model';
import { MetadataModel } from '../metadata.model';

export class UnitSelectorListResponseModel {
  constructor(
    readonly items: readonly UnitSelectorDtoModel[],
    readonly metadata: MetadataModel,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: UnitSelectorListResponse): UnitSelectorListResponseModel {
    return new UnitSelectorListResponseModel(
      json.items.map((item) => UnitSelectorDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }
}
