import { Injectable, computed, inject, signal } from '@angular/core';
import { CollAppApiService } from '../api/services/collapp-api.service';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { UnitCostCenterModel } from '../api/models/dtos/unit-cost-center.dto.model';
import { CostCenterModel } from '../api/models/dtos/cost-center.dto.model';
import { UnitCostCenterActivityTypeModel } from '../api/models/dtos/unit-cost-center-activity-type.dto.model';
import { ActivityTypesService } from './activity-types.service';
import { ActivityTypeDto } from '../api/interfaces/dtos/unit-cost-center-activity-type.dto';

@Injectable({
  providedIn: 'root'
})
export class CostCenterService {

  readonly costCenters = signal<UnitCostCenterModel[]>([]);
  readonly allCostCenters = signal<UnitCostCenterModel[]>([]);
  readonly availableCostCenters = computed(() => this.allCostCenters().filter(
    (costCenter) => !this.costCenters().some((cs) => cs?.costCenter === costCenter.costCenter)
  ));
  readonly canChangeCostCenter = computed(() => this.availableCostCenters().length > 0);
  readonly canAddCostCenter = computed(() => this.canChangeCostCenter() && this.availableCostCenters().length > 0);
  readonly allActivityTypesByCostCenter = signal<Record<string, UnitCostCenterActivityTypeModel[]>>({});
  readonly isInitDone = signal<boolean>(false);
  readonly showErrors = signal<boolean>(false);
  readonly errors = computed(() => this.showErrors() ? this.getErrorMessages(this.costCenters()) : []);
  readonly isDirty = computed(() => {
    if (this.isInitDone() === false){
      return false;
    }
    const {initialCostCenters} = this;
    const currentCostCenters = this.costCenters();
    if (initialCostCenters.length !== currentCostCenters.length) {
      return true;
    }
    return currentCostCenters.some((costCenter, index) => {
      const initialCostCenter = initialCostCenters[index];
      if (initialCostCenter.costCenter !== costCenter.costCenter) {
        return true;
      }
      if (initialCostCenter.isDefault !== costCenter.isDefault) {
        return true;
      }
      if (initialCostCenter.unitCostCenterActivityTypes.length !== costCenter.unitCostCenterActivityTypes.length) {
        return true;
      }
      return costCenter.unitCostCenterActivityTypes.some((activityType, i) => {
        const initialActivityType = initialCostCenter.unitCostCenterActivityTypes[i];
        if (initialActivityType.activityType !== activityType.activityType) {
          return true;
        }
        if (initialActivityType.isDefault !== activityType.isDefault) {
          return true;
        }
        return false;
      });
    });
  });
  private initialCostCenters: UnitCostCenterModel[] = [];
  private readonly apiService = inject(CollAppApiService);
  private readonly activityTypeService = inject(ActivityTypesService);

  async init(parentUnitId: string, initialCostCenters: UnitCostCenterModel[] = []): Promise<void> {
    if (!parentUnitId) {
      throw new Error('parentUnitId is required');
      // TODO: add logging
    }
    this.isInitDone.set(false);
    await this.setInitialCostCenters(initialCostCenters);
    await this.initAllCostCenters(parentUnitId);
    this.costCenters.set(initialCostCenters);
    this.isInitDone.set(true);
  }

  async changeCostCenterByIndex(index: number, costCenter: UnitCostCenterModel): Promise<void> {
    if (!costCenter) {
      throw new Error('costCenter is required');
    }
    const isOldIndexDefault = this.costCenters()[index].isDefault;
    const newCostCenter = new UnitCostCenterModel(costCenter.unitCostCenterId, costCenter.costCenter, costCenter.description, isOldIndexDefault, costCenter.unitCostCenterActivityTypes);
    this.costCenters.set(this.costCenters().map((cc, i) => i === index ? newCostCenter : cc));
    await this.addActivityTypesByCostCenter(costCenter.costCenter);
  }

  async addActivityTypesByCostCenter(costCenter: string): Promise<void> {
    if (!costCenter) {
      throw new Error('costCenter is required');
    }
    const allActivityTypesByCostCenter = this.allActivityTypesByCostCenter();
    const currentActivityTypesForCostCenter = allActivityTypesByCostCenter?.[costCenter];
    if (currentActivityTypesForCostCenter?.length) {
      return;
    }
    const activityTypes = (await firstValueFrom(this.activityTypeService.getActivityTypesByCostCenter$(costCenter))).map(r => UnitCostCenterActivityTypeModel.fromJSON(this.activityTypeToActivityTypeModel(r)));
    // add a new record to the signal
    this.allActivityTypesByCostCenter.set({...allActivityTypesByCostCenter, [costCenter]: activityTypes});
  }

  removeCostCenterByIndex(index: number): void {
    this.costCenters.set(this.costCenters().filter((_, i) => i !== index));
  }

  changeCostCenterDefaultByIndex(index: number): void {
    this.costCenters.set(this.costCenters().map((costCenter, i) => new UnitCostCenterModel(
        costCenter.unitCostCenterId,
        costCenter.costCenter,
        costCenter.description,
        i === index,
        costCenter.unitCostCenterActivityTypes,
      ))
    );
  }

  changeActivityTypeDefaultByIndex(costCenterIndex: number, activityTypeIndex: number): void {
    const costCenter = this.costCenters()[costCenterIndex];
    if (!costCenter) {
      throw new Error('costCenter with index not found');
    }
    const activityTypes = costCenter.unitCostCenterActivityTypes;
    const newActivityTypes = activityTypes.map((at, i) => new UnitCostCenterActivityTypeModel(at.activityType, i === activityTypeIndex, at.description, at.unitCostCenterActivityTypeId));
    const newCostCenter = new UnitCostCenterModel(costCenter.unitCostCenterId, costCenter.costCenter, costCenter.description, costCenter.isDefault, newActivityTypes);
    this.costCenters.set(this.costCenters().map((cc, i) => i === costCenterIndex ? newCostCenter : cc));
  }

  removeActivityTypeByIndex(costCenterIndex: number, activityTypeIndex: number): void {
    const costCenter = this.costCenters()[costCenterIndex];
    if (!costCenter) {
      throw new Error('costCenter with index not found');
    }
    const activityTypes = costCenter.unitCostCenterActivityTypes;
    const newActivityTypes = activityTypes.filter((_, i) => i !== activityTypeIndex);
    const newCostCenter = new UnitCostCenterModel(costCenter.unitCostCenterId, costCenter.costCenter, costCenter.description, costCenter.isDefault, newActivityTypes);
    this.costCenters.set(this.costCenters().map((cc, i) => i === costCenterIndex ? newCostCenter : cc));
  }

  changeActivityTypeByIndex(costCenterIndex: number, activityTypeIndex: number, activityType: UnitCostCenterActivityTypeModel): void {
    const costCenter = this.costCenters()[costCenterIndex];
    if (!costCenter) {
      throw new Error('costCenter with index not found');
    }
    const activityTypes = costCenter.unitCostCenterActivityTypes;
    const isOldActivityTypeDefault = activityTypes[activityTypeIndex].isDefault;
    const newActivityType = new UnitCostCenterActivityTypeModel(activityType.activityType, isOldActivityTypeDefault, activityType.description, activityType.unitCostCenterActivityTypeId);
    const newActivityTypes = activityTypes.map((at, i) => i === activityTypeIndex ? newActivityType : at);
    const newCostCenter = new UnitCostCenterModel(costCenter.unitCostCenterId, costCenter.costCenter, costCenter.description, costCenter.isDefault, newActivityTypes);
    this.costCenters.set(this.costCenters().map((cc, i) => i === costCenterIndex ? newCostCenter : cc));
  }

  addEmptyActivityTypeByIndex(costCenterIndex: number): void {
    const costCenter = this.costCenters()[costCenterIndex];
    if (!costCenter) {
      throw new Error('costCenter with index not found');
    }
    const activityTypes = costCenter.unitCostCenterActivityTypes;
    const isDefault = !this.isActivityTypeDefaultSetByIndex(costCenterIndex);
    const newActivityTypes = [...activityTypes, new UnitCostCenterActivityTypeModel('', isDefault, '')];
    const newCostCenter = new UnitCostCenterModel(costCenter.unitCostCenterId, costCenter.costCenter, costCenter.description, costCenter.isDefault, newActivityTypes);
    this.costCenters.set(this.costCenters().map((cc, i) => i === costCenterIndex ? newCostCenter : cc));
  }

  isActivityTypeDefaultSetByIndex(costCenterIndex: number): boolean {
    const costCenter = this.costCenters()[costCenterIndex];
    if (!costCenter) {
      throw new Error('costCenter with index not found');
    }
    return costCenter.unitCostCenterActivityTypes.some((at) => at.isDefault);
  }

  addEmptyCostCenter(): void {
    const shouldBeDefault = !this.costCenters().some((cc) => cc.isDefault);
    this.costCenters.set([...this.costCenters(), new UnitCostCenterModel(null, '', '', shouldBeDefault, [])]);
  }

  getDeletedCostCenters(): UnitCostCenterModel[] {
    return this.initialCostCenters.filter((costCenter) => !this.costCenters().some((cc) => cc.uid === costCenter.uid));
  }

  getDeletedCostCenterIds(): number[] {
    const deletedIds = this.getDeletedCostCenters().filter((cc) => !!cc.unitCostCenterId).map((cc) => Number(cc.unitCostCenterId));
    return deletedIds;
  }

  getDeletedActivityTypeIds(): number[] {
    return this.getDeletedActivityTypes().map((at) => at.unitCostCenterActivityTypeId).filter(at => at !== null) as number[];
  }

  // deleted activity types are excluded if the cost center is deleted
  getDeletedActivityTypes(): UnitCostCenterActivityTypeModel[] {
    return this.initialCostCenters
    .reduce((acc, costCenter) => {
      const currentCostCenter = this.costCenters().find((cc) => cc.costCenter === costCenter.costCenter);
      if (!currentCostCenter) {
        return [...acc, ...costCenter.unitCostCenterActivityTypes];
      }
      const currentActivityTypes = currentCostCenter.unitCostCenterActivityTypes;
      return [...acc, ...costCenter.unitCostCenterActivityTypes.filter((at) => !currentActivityTypes.some((cat) => cat.unitCostCenterActivityTypeId === at.unitCostCenterActivityTypeId))];
    }, []);
  }

  /**
   * There must be a default cost center
   * There must be at least one activity type for each cost center
   * All cost centers must have a unique costCenter (remove the dummy ones)
   * All activity types within each costCenter must have a unique activityType (remove the dummy ones)
   */
  getErrorMessages(costCenters: UnitCostCenterModel[]): string[] {
    const errorMessages: string[] = [];

    if (!costCenters.some((cc) => cc.isDefault)) {
      errorMessages.push('There must be a default cost center');
    }
    if (!costCenters.every((cc) => cc.unitCostCenterActivityTypes.length > 0)) {
      errorMessages.push('There must be at least one activity type for each cost center');
    }
    if (costCenters.length !== new Set(costCenters.map((cc) => cc.costCenter)).size) {
      errorMessages.push('A cost center is missing');
    }
    if (costCenters.some((cc) => cc.unitCostCenterActivityTypes.some((at) => at.activityType === ''))) {
      errorMessages.push('A activity is added but not filled out');
    }
    return errorMessages;
  }

  getCostCenterByUnitId$(unitId: number): Observable<CostCenterModel[]> {
    return this.apiService.getContractCostCenters$(unitId)
    .pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((response: HttpResponse<CostCenterModel[]>) => response.body!.map(CostCenterModel.fromJSON)),
    );
  }

  private activityTypeToActivityTypeModel(activityType: ActivityTypeDto): UnitCostCenterActivityTypeModel {
    return UnitCostCenterActivityTypeModel.fromJSON({unitCostCenterActivityTypeId: null, activityType: activityType.activityTypeCode, isDefault: false, description: activityType.activityTypeDescription});
  }

  private async setInitialCostCenters(costCenters: UnitCostCenterModel[]): Promise<void> {
    this.initialCostCenters = costCenters;
    await Promise.all(costCenters.map((costCenter) => this.addActivityTypesByCostCenter(costCenter.costCenter)));
  }

  private async initAllCostCenters(l3UnitId: string): Promise<void> {
    const costCenters = await this.getUnitCostCenters(l3UnitId);
    this.allCostCenters.set(costCenters);
  }

  private getCostCenters$(unitId: string): Observable<CostCenterModel[]> {
    return this.apiService
    .getUnitCostCentersByUnitId$(unitId)
    .pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((response: HttpResponse<CostCenterModel[]>) => response.body!.map(CostCenterModel.fromJSON)),

    );
  }

  // they are always missing the id and the activity types and isDefault is always false
  private getUnitCostCenters(unitId: string): Promise<UnitCostCenterModel[]> {
    return firstValueFrom((this.getCostCenters$(unitId)
    .pipe(
      map((response: CostCenterModel[]) => response.map(this.costCenterToUnitCostCenter)),
    )));
  }

  private costCenterToUnitCostCenter(costCenter: CostCenterModel): UnitCostCenterModel {
    return new UnitCostCenterModel(null, costCenter.costCenter, costCenter.description, false, []);
  }
}
