import moment from 'moment';
import { PlanningResource, PlanningResourceUnitAssignment } from '../../../modules/user/planning2/planning-resource.interface';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceDto } from '../../interfaces/dtos/non-human-resource.dto';
import { MetadataModel } from '../metadata.model';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { NonHumanResourceUnitAssignmentDtoModel } from './non-human-resource-unit-assignment.dto.model';

export class NonHumanResourceDtoModel implements ApiModelNew, PlanningResource {
  constructor(
    readonly nonHumanResourceId: string,
    readonly name: string,
    readonly systemId: string | null,
    readonly remark: string | null,
    readonly payrollCompany: string | null,
    readonly costCenter: string | null,
    readonly countryCode: string | null,
    readonly countryName: string | null,
    readonly city: string | null,
    readonly divisionCode: string | null,
    readonly productGroupCode: string | null,
    readonly unit: UnitSlimDtoModel | null,
    readonly businessUnitCode: string | null,
    readonly hourlyRateCategory: string | null,
    readonly workingHoursPerWeek: number | null,
    readonly nonHumanResourceUnitAssignments: NonHumanResourceUnitAssignmentDtoModel[] | null,
    readonly metadata: MetadataModel = new MetadataModel(),
    readonly validFrom: moment.Moment | null = null,
    readonly validUntil: moment.Moment | null = null,
    readonly capacityUnit: string | null = null,
  ) {
    this.unit = (unit != null ? unit.clone() : unit);
    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);

    if (nonHumanResourceUnitAssignments) {
      this.nonHumanResourceUnitAssignments = nonHumanResourceUnitAssignments.map((assignment) => assignment.clone());
    }
  }

  get id(): string {
    return this.nonHumanResourceId;
  }

  get isHumanResource(): boolean {
    return false;
  }

  get unitAssignments(): PlanningResourceUnitAssignment[] | null {
    return this.nonHumanResourceUnitAssignments;
  }

  static fromJSON(json: NonHumanResourceDto): NonHumanResourceDtoModel {
    assertRequiredProperties(json, [
      'nonHumanResourceId',
      'name',
    ]);

    return new NonHumanResourceDtoModel(
      json.nonHumanResourceId,
      json.name,
      json.systemId,
      json.remark,
      json.payrollCompany,
      json.costCenter,
      json.countryCode,
      json.countryName,
      json.city,
      json.divisionCode,
      json.productGroupCode,
      (json.unit ? UnitSlimDtoModel.fromJSON(json.unit) : null),
      json.businessUnitCode,
      json.hourlyRateCategory,
      json.workingHoursPerWeek,
      (json.nonHumanResourceUnitAssignments
        ? json.nonHumanResourceUnitAssignments
          .map((assignment) => NonHumanResourceUnitAssignmentDtoModel.fromJSON(assignment))
        : null),
      new MetadataModel(json.metadata || {}),
      (json.validFrom ? moment(json.validFrom).parseZone() : null),
      (json.validUntil ? moment(json.validUntil).parseZone() : null),
      json.capacityUnit,
    );
  }

  /**
   * If no value is given the optional field is evaluated as not valid.
   */
  isValidFrom(startDate: moment.Moment): boolean {
    if (this.validFrom != null) {
      return this.validFrom.isSameOrBefore(startDate) || startDate < moment();
    }

    return false;
  }

  /**
   * If no value is given the optional field is evaluated as valid.
   */
  isValidUntil(endDate: moment.Moment): boolean {
    if (this.validUntil != null) {
      return this.validUntil.isSameOrAfter(endDate);
    }

    return true;
  }

  isValidUntilInPast(checkDate: moment.Moment = moment()): boolean {
    return this.validUntil != null && this.validUntil.isBefore(checkDate);
  }

  toJSON(): NonHumanResourceDto {
    return {
      nonHumanResourceId: this.nonHumanResourceId,
      name: this.name,
      systemId: this.systemId,
      remark: this.remark,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      countryCode: this.countryCode,
      countryName: this.countryName,
      city: this.city,
      divisionCode: this.divisionCode,
      productGroupCode: this.productGroupCode,
      unit: this.unit ? this.unit.toJSON() : null,
      businessUnitCode: this.businessUnitCode,
      hourlyRateCategory: this.hourlyRateCategory,
      workingHoursPerWeek: this.workingHoursPerWeek,
      nonHumanResourceUnitAssignments: this.nonHumanResourceUnitAssignments
        ? this.nonHumanResourceUnitAssignments.map((assignment) => assignment.toJSON())
        : null,
      metadata: this.metadata,
      validFrom: this.validFrom ? this.validFrom.toJSON() : null,
      validUntil: this.validUntil ? this.validUntil.toJSON() : null,
      capacityUnit: this.capacityUnit,
    };
  }

  clone(overrides?: Partial<NonHumanResourceDtoModel>): NonHumanResourceDtoModel {
    const copy = { ...this, ...overrides };

    return new NonHumanResourceDtoModel(
      copy.nonHumanResourceId,
      copy.name,
      copy.systemId,
      copy.remark,
      copy.payrollCompany,
      copy.costCenter,
      copy.countryCode,
      copy.countryName,
      copy.city,
      copy.divisionCode,
      copy.productGroupCode,
      copy.unit ? copy.unit.clone() : null,
      copy.businessUnitCode,
      copy.hourlyRateCategory,
      copy.workingHoursPerWeek,
      copy.nonHumanResourceUnitAssignments
        ? copy.nonHumanResourceUnitAssignments.map((assignment) => assignment.clone())
        : null,
      copy.metadata.clone(),
      copy.validFrom ? copy.validFrom.clone() : null,
      copy.validUntil ? copy.validUntil.clone() : null,
    );
  }
}
