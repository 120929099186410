import { WorkPackagePlanningRangeStateModel } from './work-package-planning-range-state.model';

/**
 * Action types for WorkPackagePlanningRangeState
 */
export enum WorkPackagePlanningRangeActionTypes {
  InitWorkPackagePlanningRangeSettings = '[WP Planning API] Initialize WP Planning Range Settings',
  SetWorkPackagePlanningRangeSettings = '[WP Planning API] Set WP Planning Range Settings',
  ResetWorkPackagePlanningRangeSettings = '[WP Planning API] Reset WP Planning Range Settings',
  SaveWorkPackagePlanningRangeSettings = '[WP Planning API] Save WP Planning Range Settings',
}

export class InitWorkPackagePlanningRangeSettings {
  static readonly type: string = WorkPackagePlanningRangeActionTypes.InitWorkPackagePlanningRangeSettings;

  constructor(public settings: WorkPackagePlanningRangeStateModel) {}
}

export class SetWorkPackagePlanningRangeSettings {
  static readonly type: string = WorkPackagePlanningRangeActionTypes.SetWorkPackagePlanningRangeSettings;

  constructor(public settings: WorkPackagePlanningRangeStateModel) {}
}

export class ResetWorkPackagePlanningRangeSettings {
  static readonly type: string = WorkPackagePlanningRangeActionTypes.ResetWorkPackagePlanningRangeSettings;
}

export class SaveWorkPackagePlanningRangeSettings {
  static readonly type: string = WorkPackagePlanningRangeActionTypes.SaveWorkPackagePlanningRangeSettings;

  constructor(public settings: WorkPackagePlanningRangeStateModel) {}
}
