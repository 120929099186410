import { Selector } from '@ngxs/store';
import { TimecardRangeStateModel } from './timecard-range-state.model';
import { TimecardRangeState } from './timecard-range.state';

export class TimecardRangeSelectors {
  @Selector([TimecardRangeState])
  static range(state: TimecardRangeStateModel): TimecardRangeStateModel['range'] {
    return state.range;
  }
}
