import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkpackageSidepanelStateModel } from './work-package-sidepanel-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  CloseWorkpackageSidepanel,
  InitWorkpackageSidepanel,
  OpenWorkpackageSidepanel,
  ToggleWorkpackageSidepanel,
} from './work-package-sidepanel.actions';

const openState: WorkpackageSidepanelStateModel = { isOpen: true };
const closedState: WorkpackageSidepanelStateModel = { isOpen: false };
const defaultState: WorkpackageSidepanelStateModel = openState;

@State<WorkpackageSidepanelStateModel>({
  name: 'WorkPackageSidePanel',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkpackageSidepanelState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitWorkpackageSidepanel)
  initWorkpackageSidepanel(
    ctx: StateContext<WorkpackageSidepanelStateModel>,
    { isOpen }: InitWorkpackageSidepanel,
  ): void {
    ctx.setState(
      isOpen,
    );
  }

  @Action(OpenWorkpackageSidepanel)
  openWorkpackageSidepanel$(ctx: StateContext<WorkpackageSidepanelStateModel>): Observable<void> {
    ctx.patchState(openState);

    return this.userService.saveWorkPackageSidepanel$(openState);
  }

  @Action(CloseWorkpackageSidepanel)
  closeWorkpackageSidepanel$(ctx: StateContext<WorkpackageSidepanelStateModel>): Observable<void> {
    ctx.patchState(closedState);

    return this.userService.saveWorkPackageSidepanel$(closedState);
  }

  @Action(ToggleWorkpackageSidepanel)
  toggleWorkpackageSidepanel$(ctx: StateContext<WorkpackageSidepanelStateModel>): Observable<void> {
    const action = ctx.getState().isOpen ? new CloseWorkpackageSidepanel() : new OpenWorkpackageSidepanel();

    return ctx.dispatch(action);
  }
}
