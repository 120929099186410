import { Selector } from '@ngxs/store';
import { UserPlanningTotalsStateModel } from './user-planning-totals-state.model';
import { UserPlanningTotalsState } from './user-planning-totals.state';

export class UserPlanningTotalsSelectors {
  @Selector([UserPlanningTotalsState])
  static selectedTotals(state: UserPlanningTotalsStateModel): UserPlanningTotalsStateModel['selectedTotals'] {
    return state.selectedTotals;
  }
}
