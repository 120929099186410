import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CollAppApiService } from './collapp-api.service';
import { DashboardViewResponse } from '../interfaces/responses/dashboard-view.response';
import { DashboardViewResponseModel } from '../models/responses/dashboard-view.response.model';
import { ProjectDashboardDto } from '../interfaces/dtos/project-dashboard.dto';
import { ProjectDashboardListResponseModel } from '../models/responses/project-dashboard-list.response.model';
import { WorkPackageDashboardDto } from '../interfaces/dtos/work-package-dashboard.dto';
import { WorkPackageDashboardListResponseModel } from '../models/responses/work-package-dashboard-list.response.model';
import { QuestionDashboardDto } from '../interfaces/dtos/question-dashboard.dto';
import { QuestionDashboardListResponseModel } from '../models/responses/question-dashboard-list.response.model';
import { WorkPackageStatus } from '../../models/work-package-status.enum';
import { DashboardWorkPackageViewType } from '../../models/dashboard-work-package-view-type.enum';
import { DashboardFiltersVisibilityResponseModel } from '../models/responses/dashboard-filters-visibility.response.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private apiService: CollAppApiService,
  ) { }

  /**
   * Fetches the dashboard view.
   *
   * @return {Observable<DashboardViewResponseModel>}
   */
  getDashboard$(): Observable<DashboardViewResponseModel> {
    return this.apiService
      .getDashboard$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<DashboardViewResponse>) => DashboardViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Fetches the latest projects of a given user for display on the dashboard view.
   *
   * @param userId
   * @return {Observable<ProjectDashboardListResponseModel>}
   */
  getDashboardProjects$(userId: string): Observable<ProjectDashboardListResponseModel> {
    return this.apiService
      .getDashboardProjects$(userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectDashboardDto[]>) => {
          const items = response.body!;
          const numItems = (Array.isArray(items) ? items.length : 0);
          const metadata = {
            paginationInfo: {
              pageNumber: 1,
              pageSize: numItems,
              numberOfTotalResults: numItems,
            },
          };

          return ProjectDashboardListResponseModel.fromJSON({
            items,
            metadata,
          });
        }),
      );
  }

  /**
   * Fetches the latest work packages of a given user for display on the dashboard view,
   * filtered by their status.
   *
   * @param userId
   * @param status
   * @param forSupplierUnitCoordinator
   * @return {Observable<WorkPackageDashboardListResponseModel>}
   */
  getDashboardWorkPackages$(
    userId: string,
    status: WorkPackageStatus[],
    viewTypes: DashboardWorkPackageViewType[],
    forSupplierUnitCoordinator?: boolean,
  ): Observable<WorkPackageDashboardListResponseModel> {
    return this.apiService
      .getDashboardWorkPackages$(userId, status, viewTypes, forSupplierUnitCoordinator)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageDashboardDto[]>) => {
          const items = response.body!;
          const numItems = (Array.isArray(items) ? items.length : 0);
          const metadata = {
            paginationInfo: {
              pageNumber: 1,
              pageSize: numItems,
              numberOfTotalResults: numItems,
            },
          };

          return WorkPackageDashboardListResponseModel.fromJSON({
            items,
            metadata,
          });
        }),
      );
  }

  /**
   * Fetches the visibility state of the dashboard filters.
   *
   * @return {Observable<DashboardFiltersVisibilityResponseModel>}
   */
  getDashboardFiltersVisibility$(): Observable<DashboardFiltersVisibilityResponseModel> {
    return this.apiService
      .getDashboardFiltersVisibility$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<DashboardFiltersVisibilityResponseModel>) => DashboardFiltersVisibilityResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Fetches the latest questions of a given user for display on the dashboard view.
   *
   * @param userId
   * @return {Observable<WorkPackageDashboardListResponseModel>}
   */
  getDashboardQuestions$(userId: string): Observable<QuestionDashboardListResponseModel> {
    return this.apiService
      .getDashboardQuestions$(userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<QuestionDashboardDto[]>) => {
          const items = response.body!;
          const numItems = (Array.isArray(items) ? items.length : 0);
          const metadata = {
            paginationInfo: {
              pageNumber: 1,
              pageSize: numItems,
              numberOfTotalResults: numItems,
            },
          };

          return QuestionDashboardListResponseModel.fromJSON({
            items,
            metadata,
          });
        }),
      );
  }
}
