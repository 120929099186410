<div class="toast" [ngClass]="['toasty-type-' + toast.type]" [@flyInOut]="toast.state" (click)="click($event)">
  @if (toast.showClose) {
    <div class="toast-close" (click)="close($event)">
      <mat-icon>close</mat-icon>
    </div>
  }
  @if (toast.portal || toast.title || toast.msg) {
    <div class="toast-icon">
      @if (toast.type === 'success') {
        <mat-icon>check</mat-icon>
      }
      @if (toast.type !== 'success') {
        <mat-icon>{{toast.type}}</mat-icon>
      }
    </div>
  }
  @if (toast.portal || toast.title || toast.msg) {
    <div class="toast-text">
      @if (toast.portal) {
        <ng-template [cdkPortalOutlet]="toast.portal"></ng-template>
      } @else {
        @if (toast.title) {
          <span class="toast-title" [innerHTML]="toast.title | safeHtml"></span>
        }
        @if (toast.msg) {
          <span class="toast-msg" [innerHtml]="toast.msg | safeHtml"></span>
        }
      }
    </div>
  }
</div>