import { UnitListDto } from '../../interfaces/dtos/unit-list.dto';
import { UnitLevel } from '../../../models/unit-level.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitListDtoModel {
  constructor(
    readonly unitId: number,
    readonly code: string,
    readonly level: UnitLevel,
    readonly name: string,
    readonly deputyUnitCoodinators: string,
    readonly unitCoordinatorFullName: string,
    readonly parentUnitId: number | null = null,
    readonly isDisabled: boolean,
  ) {
  }

  static fromJSON(json: UnitListDto): UnitListDtoModel {
    assertRequiredProperties(json, [
      'unitId',
      'code',
      'level',
      'name',
      // @TODO Verify once seed data was fixed, because otherwise no units will be created for the base data
      // 'unitCoordinator',
      // 'deputyUnitCoordinators',
    ]);

    return new UnitListDtoModel(
      json.unitId,
      json.code,
      json.level,
      json.name,
      json.deputyUnitCoodinators,
      json.unitCoordinatorFullName,
      json.parentUnitId,
      json.isDisabled,
    );
  }
}
