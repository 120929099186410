/**
 * Contains all the states a work package can be in.
 */
export enum WorkPackageStatus {
  Draft = 0,
  Forecasted = 1,
  Issued = 2,
  Confirmed = 3,
  InProgress = 4,
  OnHold = 5,
  ReadyToResumeWork = 6,
  ScopeChange = 7,
  ReadyForScopeChangeApproval = 8,
  InQualityAssurance = 9,
  ReadyForApproval = 10,
  Closed = 11,
}

export const workPackageStatusName = new Map<WorkPackageStatus, string>([
  [WorkPackageStatus.Draft, 'Draft'],
  [WorkPackageStatus.Forecasted, 'Forecasted'],
  [WorkPackageStatus.Issued, 'Issued'],
  [WorkPackageStatus.Confirmed, 'Confirmed'],
  [WorkPackageStatus.InProgress, 'In Progress'],
  [WorkPackageStatus.OnHold, 'On Hold'],
  [WorkPackageStatus.ReadyToResumeWork, 'Ready to Resume Work'],
  [WorkPackageStatus.ScopeChange, 'Scope Change'],
  [WorkPackageStatus.ReadyForScopeChangeApproval, 'Ready for Scope Change Approval'],
  [WorkPackageStatus.InQualityAssurance, 'In Quality Assurance'],
  [WorkPackageStatus.ReadyForApproval, 'Ready for Approval'],
  [WorkPackageStatus.Closed, 'Closed'],
]);

/**
 * A simple object that maps the upcoming work package transition.
 */
export class WorkPackageStatusTransition {
  constructor(
    public projectId: number,
    public workPackageId: number,
    public from: WorkPackageStatus,
    public to: WorkPackageStatus,
    public getTransitionInfo?: boolean,
  ) {}

  getFromStatusName(): string {
    return workPackageStatusName.get(this.from) || '';
  }

  getToStatusName(): string {
    return workPackageStatusName.get(this.to) || '';
  }
}
