import { DEFAULT_RETURN_URL } from '../shared/constants';

/**
 * Checks whether an url is relative to this app.
 */
export function getRelativeUrlOrDefault(url?: string | null, defaultUrl: string = DEFAULT_RETURN_URL): string {
  // only allow urls relative to this app!
  return url && url.match(/^\/[^/]/)
    ? url
    : defaultUrl;
}

/**
 * ucs-2 string to base64 encoded ascii
 */
export function utoa(value: string): string {
  return window.btoa(unescape(encodeURIComponent(value)));
}

/**
 * base64 encoded ascii to ucs-2 string
 */
export function atou(value: string): string {
  return decodeURIComponent(escape(window.atob(value)));
}
