import {
  Component, EventEmitter, HostBinding, Input, OnDestroy, Output, ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import {
  filter, finalize, takeUntil, tap,
} from 'rxjs/operators';

import { LoadingIndicatorService } from '../../../services/loading-indicator.service';
import { PrimaryNavItem, views } from '../../../app-nav-views';
import { UserSelectors } from '../../../state/user';
import { UserDtoModel } from '../../../api/models/dtos/user.dto.model';
import { SimpleNavItem } from '../../../models/simple-nav-item.interface';
import { AccessControlService } from '../../../services/access-control.service';
import { utoa } from '../../../helpers/login.utility';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { BaseDataSelectors } from '../../../state';
import { ResourceLinkDtoModel } from '../../../api/models/dtos/resource-link.dto.model';

@Component({
  selector: 'collapp-primary-nav',
  templateUrl: './primary-nav.component.html',
  styleUrls: ['./primary-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrimaryNavigationComponent implements OnDestroy {
  @HostBinding('class.primary-nav')
  readonly primaryNavClass: boolean = true;

  @Input()
  open: boolean = true;

  @Output()
  readonly toggle: EventEmitter<void> = new EventEmitter<void>();

  @Select(UserSelectors.user) user$!: Observable<UserDtoModel>;

  @Select(UserSelectors.originalUser) originalUser$!: Observable<UserDtoModel>;

  links: ResourceLinkDtoModel[] = [];

  readonly menuItemTooltipDelay: number = 200;

  readonly profileUserTooltipDelay: number = 400;

  items: PrimaryNavItem[] = [];

  adminItems: PrimaryNavItem[] = [];

  userItems: PrimaryNavItem[] = [];

  private destroyed$: Subject<void> = new Subject();

  private currentRoute: string = '';

  constructor(
    private accessControlService: AccessControlService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService,
    private errorHandlerService: ErrorHandlerService,
    private store: Store,
  ) {
    // prepare menu-items
    this.items = views.filter((view) => !view.adminClass);
    this.adminItems = views.filter((view) => view.adminClass);
    this.userItems = [
      {
        shortname: 'Timecard',
        icon: 'ic-timecard',
        name: 'Timecard',
        adminClass: false,
        link: ['/user/timecard'],
        activeMenuRegex: '/user/timecard',
        hidden: (): boolean => false,
      },
      {
        shortname: 'Planning',
        icon: 'ic-resourceplanning',
        name: 'Planning',
        adminClass: false,
        // Note you can not use PLANNING_PATH otherwise the app just does not load
        link: ['/user/planning'],
        activeMenuRegex: '/user/planning',
        hidden: (): boolean => false,
      }];

    this.store.select(BaseDataSelectors.links)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((links) => {
        this.links = links;
      });

    router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        tap((event: RouterEvent) => {
          this.currentRoute = event.url;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((_) => {
        // nothing
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  isAciveLink(item: PrimaryNavItem): boolean {
    return !!(this.currentRoute.match(`${item.activeMenuRegex}`)?.length);
  }

  toggleMenuOpen(): void {
    this.toggle.next();
  }

  logOutSubstituteUser(): void {
    this.loadingIndicatorService.addLoadingRequest();
    this.accessControlService.logoutSubstituteUser$()
      .pipe(
        finalize(() => {
          this.loadingIndicatorService.removeLoadingRequest();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        () => {
          const currentUrl = this.router.routerState.snapshot.url;
          this.router.navigate(['/redirect', utoa(currentUrl)]);
        },
        (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Could not log out from SU');
        },
      );
  }

  logOutUser(): void {
    this.accessControlService.logout();
  }

  decode(url: string): string {
    return window.decodeURIComponent(url);
  }

  getExternalLink(item: PrimaryNavItem): string | null {
    const url = this.links.find((link) => link.linkCode === item.linkCode)?.url;

    return url ? window.decodeURIComponent(url) : null;
  }

  /**
   * @TODO Merge similar functions `navItemTrackByFn`
   * @param {number} index
   * @param {SimpleNavItem} item
   */
  navItemTrackByFn(index: number, item: SimpleNavItem): string {
    return `${item.name}:${item.link ? item.link.join('/') : item.linkCode}`;
  }
}
