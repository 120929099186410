import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { ProjectService } from '../api/services/project.service';
import { ProjectViewResponseModel } from '../api/models/responses/project-view.response.model';
import { ClearViews, SetProjectView, UserStateActions } from '../state';
import { ProjectRecentListDtoModel } from '../api/models/dtos/project-recent-list.dto.model';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectViewResolver  {
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private store: Store,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ProjectViewResponseModel> {
    const projectId = route.paramMap.get('id');

    if (projectId == null) {
      throw new Error('Route param \'id\' cannot be null');
    }

    return this.projectService
      .getProjectView$(+projectId)
      .pipe(
        switchMap((result) => this.store.dispatch(new ClearViews())
          .pipe(
            switchMap(() => this.store.dispatch(new SetProjectView(result))),
            mapTo(result),
          )),
        switchMap((project) => {
          const recentProject = new ProjectRecentListDtoModel(project.projectId, project.title);

          return this.store.dispatch(new UserStateActions.AddRecentProject(recentProject))
            .pipe(
              mapTo(project),
              // Ignore any errors
              catchError(() => of(project)),
            );
        }),
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, `An error has occurred while loading the project with the ID '${projectId}'`);

          this.router.navigate(['/projects', 'all']);

          return throwError(error);
        }),
      );
  }
}
