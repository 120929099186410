import { TimeTrackingRowDto } from '../../interfaces/dtos/time-tracking-row.dto';
import { TimeTrackingEntryViewDtoModel } from './time-tracking-entry-view.dto.model';
import { TimecardCategoryCode } from '../../../models/timecard-category-code.enum';
import { TimeTrackingRowModel } from '../../../models/time-tracking-row';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingRowDtoModel extends TimeTrackingRowModel {
  constructor(
    days: TimeTrackingEntryViewDtoModel[],
    readonly unitCategoryId: number,
    readonly unitName: string | null = null,
    readonly timecardCategoryName: string | null = null,
    readonly timecardCategoryCode: TimecardCategoryCode | null = null,
    readonly totalPlannedHours: number | null = null,
    readonly sapCode: string | null = null,
    readonly isVisible: boolean = true,
    public hourlyRateLabel: string | null = null,
    public activityTypeDescription: string | null = null,
    public canDelete: boolean = false,
  ) {
    super(days);
  }

  static fromJSON(json: TimeTrackingRowDto): TimeTrackingRowDtoModel {
    assertRequiredProperties(json, [
      'days',
    ]);

    return new TimeTrackingRowDtoModel(
      (Array.isArray(json.days) ? json.days : [])
        .map((item) => TimeTrackingEntryViewDtoModel.fromJSON(item)),
      json.unitCategoryId,
      json.unitName,
      json.timecardCategoryName,
      json.timecardCategoryCode,
      json.totalPlannedHours,
      json.sapCode,
      json.isVisible,
      json.hourlyRateLabel,
      json.activityTypeDescription,
    );
  }

  /**
   * Extracts the hours of specific week day
   * @param dayIndex
   */
  getHoursOfWeekDay(dayIndex: number): number {
    if (this.days[dayIndex]) {
      const hour = +`${this.days[dayIndex].value}`.replace(',', '.');

      if (!Number.isNaN(hour)) {
        return hour;
      }
    }

    return 0;
  }

  clone(): TimeTrackingRowDtoModel {
    return new TimeTrackingRowDtoModel(
      this.days.map((day) => day.clone()),
      this.unitCategoryId,
      this.unitName,
      this.timecardCategoryName,
      this.timecardCategoryCode,
      this.totalPlannedHours,
      this.sapCode,
      this.isVisible,
      this.hourlyRateLabel,
      this.activityTypeDescription,
      this.canDelete,
    );
  }

  toJSON(): TimeTrackingRowDto {
    return {
      days: this.days.map((item) => item.toJSON()),
      unitCategoryId: this.unitCategoryId,
      unitName: this.unitName,
      timecardCategoryName: this.timecardCategoryName,
      timecardCategoryCode: this.timecardCategoryCode,
      totalPlannedHours: this.totalPlannedHours,
      sapCode: this.sapCode,
      isVisible: this.isVisible,
      hourlyRateLabel: this.hourlyRateLabel,
      activityTypeDescription: this.activityTypeDescription,
    };
  }
}
