import { ProjectPlanningViewResponse } from '../../interfaces/responses/project-planning-view.response';
import { ProjectPlanningLevelDtoModel } from '../dtos/project-planning-level.dto.model';

export class ProjectPlanningViewResponseModel {
  constructor(
    readonly projectId: number,
    readonly levels: ProjectPlanningLevelDtoModel[],
  ) {
    if (levels) {
      this.levels = [...levels];
    }
  }

  static fromJSON(json: ProjectPlanningViewResponse): ProjectPlanningViewResponseModel {
    return new ProjectPlanningViewResponseModel(
      json.projectId,
      (Array.isArray(json.levels) ? json.levels : [])
        .map(ProjectPlanningLevelDtoModel.fromJSON),
    );
  }

  clone(): ProjectPlanningViewResponseModel {
    return new ProjectPlanningViewResponseModel(
      this.projectId,
      this.levels?.map((item) => item.clone()) || null,
    );
  }
}
