import { MinimalUser } from '../../../models/user.interface';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserMinimalDto } from '../../interfaces/dtos/user-minimal.dto';

export class UserMinimalModel implements MinimalUser {
  constructor(
    readonly fullName: string,
    readonly photoUrl?: string | null,
  ) {}

  static fromJSON(json: UserMinimalDto): UserMinimalModel {
    assertRequiredProperties(json, [
      'fullName',
      'photoUrl',
    ]);

    return new UserMinimalModel(
      json.fullName,
      json.photoUrl,
    );
  }

  toJSON(): UserMinimalDto {
    return {
      fullName: this.fullName,
      photoUrl: this.photoUrl,
    };
  }

  clone(): UserMinimalModel {
    return new UserMinimalModel(
      this.fullName,
      this.photoUrl,
    );
  }
}
