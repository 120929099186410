import moment, { Moment } from 'moment';
import { ProjectViewResponse } from '../../interfaces/responses/project-view.response';
import { LevelSlimDtoModel } from '../dtos/level-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { ContractDtoModel } from '../dtos/contract.dto.model';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { ProjectViewResponseMetadata } from '../../interfaces/metadata';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectViewResponseModel {
  canUpdateProjectStructureFromSap: boolean;

  canUpdateProjectStructureManually: boolean;

  canCreateWorkPackage: boolean;

  canArchiveProject: boolean;

  canUnarchiveProject: boolean;

  canExportProject: boolean;

  isUserSupplierUnitCoordinatorForProject: boolean;

  // eslint-disable-next-line complexity
  constructor(
    readonly projectId: number,
    readonly title: string,
    readonly status: string | null = null,
    readonly sapNumber: string | null = null,
    readonly projectNumber: string = '',
    readonly projectTypeCode: ProjectTypeCode = ProjectTypeCode.Unknown,
    readonly customerCountryCode: string = '',
    readonly getDate: Moment | null = null,
    readonly structure: readonly LevelSlimDtoModel[] = [],
    readonly submitterUnit: UnitSlimDtoModel | null = null,
    readonly childUnitContracts: readonly ContractDtoModel[] = [],
    readonly metadata: MetadataModel<ProjectViewResponseMetadata> = new MetadataModel(),
  ) {
    this.structure = [...structure];
    this.childUnitContracts = [...childUnitContracts];

    this.canUpdateProjectStructureFromSap = (this.metadata.fields
      && this.metadata.fields.canUpdateProjectStructureFromSap) || false;
    this.canUpdateProjectStructureManually = (this.metadata.fields
      && this.metadata.fields.canUpdateProjectStructureManually) || false;
    this.canCreateWorkPackage = (this.metadata.fields && this.metadata.fields.canCreateWorkPackage) || false;
    this.canArchiveProject = (this.metadata.fields && this.metadata.fields.canArchiveProject) || false;
    this.canUnarchiveProject = (this.metadata.fields && this.metadata.fields.canUnarchiveProject) || false;
    this.canExportProject = (this.metadata.fields && this.metadata.fields.canExportProject) || false;
    this.isUserSupplierUnitCoordinatorForProject = (this.metadata.fields
      && this.metadata.fields.isUserSupplierUnitCoordinatorForProject) || false;
  }

  static getLevelPath(levelId?: number, structure: readonly LevelSlimDtoModel[] = []): LevelSlimDtoModel[] {
    if (!levelId || structure.length === 0) {
      return [];
    }

    const structureLen = structure.length;
    let levelPath: LevelSlimDtoModel[] = [];
    let i = 0;

    // Look for work package in level and receive the path
    while (levelPath.length === 0 && i < structureLen) {
      levelPath = structure[i].getLevelPath(levelId);
      i += 1;
    }

    return levelPath;
  }

  static fromJSON(json: ProjectViewResponse): ProjectViewResponseModel {
    assertRequiredProperties(json, [
      'projectId',
      'title',
    ]);

    return new ProjectViewResponseModel(
      json.projectId,
      json.title,
      json.projectUserStatus,
      json.sapNumber,
      json.projectNumber ?? undefined,
      (json.projectTypeCode != null
        ? json.projectTypeCode
        : ProjectTypeCode.Unknown
      ),
      json.customerCountryCode || '',
      (json.getDate
        ? moment(json.getDate)
        : null
      ),
      (Array.isArray(json.structure)
        ? json.structure
          .map((item) => LevelSlimDtoModel.fromJSON(item))
        : []
      ),
      (json.submitterUnit
        ? UnitSlimDtoModel.fromJSON(json.submitterUnit)
        : null
      ),
      (Array.isArray(json.childUnitContracts)
        ? json.childUnitContracts
          .map((item) => ContractDtoModel.fromJSON(item))
        : []
      ),
      // FIXME remove cast to any
      new MetadataModel<ProjectViewResponseMetadata>(json.metadata || {} as any),
    );
  }

  /**
   * Looks recursively if the work package exists in the project. If the work package was found,
   * the path of levels is returned as an array: [l1, l2, l3, ...]
   *
   * @param levelId
   */
  getLevelPath(levelId: number): LevelSlimDtoModel[] {
    return ProjectViewResponseModel.getLevelPath(levelId, this.structure);
  }

  /**
   * Returns contracts of submitter it has with a supplier.
   * @param supplierId
   */
  getSubmitterContractsBySupplierId(supplierId: number): ContractDtoModel[] {
    return this.childUnitContracts
      .filter((contract) => contract.supplierUnit && contract.supplierUnit.unitId === supplierId);
  }

  hasSubmitterContractsWithSupplier(supplierId: number): boolean {
    return this.getSubmitterContractsBySupplierId(supplierId).length > 0;
  }

  toJSON(): ProjectViewResponse {
    return {
      projectId: this.projectId,
      title: this.title,
      projectUserStatus: this.status,
      sapNumber: this.sapNumber,
      projectNumber: this.projectNumber,
      projectTypeCode: this.projectTypeCode,
      customerCountryCode: this.customerCountryCode,
      getDate: this.getDate ? this.getDate.toISOString() : null,
      structure: this.structure.map((item) => item.toJSON()),
      submitterUnit: this.submitterUnit ? this.submitterUnit.toJSON() : null,
      childUnitContracts: this.childUnitContracts.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
