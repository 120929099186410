import { UnitPlanningResourceDtoModel } from '../../../api/models/dtos/unit-planning-resource.dto.model';
import { PlanningCategorySelectItem } from '../../../components/planning-category-select';
import { SkillFilterModel } from '../../../components/skill-filter/skill-filter.component';
import { Unit } from '../../../models/unit.interface';

export enum UnitPlanningActionTypes {
  InitUnitPlanningSettings = '[API] Init Unit Planning Settings State',
  AddUnitPlanningRecentUnit = '[UnitPlanning] Add Unit Planning Recent Unit',
  SetUnitPlanningFilters = '[UnitPlanning] Set Unit Planning Filters',
}

/**
 * In the backend only a list of recent units is stored. The week and year is
 * not stored in the backend.
 */
export class InitUnitPlanningSettings {
  static readonly type: string = UnitPlanningActionTypes.InitUnitPlanningSettings;

  constructor(
    readonly recentUnits: readonly Unit[],
  ) {}
}

export class AddUnitPlanningRecentUnit {
  static readonly type: string = UnitPlanningActionTypes.AddUnitPlanningRecentUnit;

  constructor(
    public unit: Unit,
  ) {}
}

export class SetUnitPlanningFilters {
  static readonly type: string = UnitPlanningActionTypes.SetUnitPlanningFilters;

  constructor(
    public unit: number,
    public data: {
      selectedSkills?: SkillFilterModel[];
      selectedResources?: UnitPlanningResourceDtoModel[];
      selectedCategories?: PlanningCategorySelectItem[];
    },
  ) {}
}
