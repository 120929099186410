import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
 *
 * https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a
*/
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  private units: string[] = [
    'bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
  ];

  transform(bytes: number = 0, precision: number = 2): string {
    let parsedBytes = bytes;

    if (Number.isNaN(parseFloat(String(parsedBytes))) || !Number.isFinite(parsedBytes)) { return '?'; }

    let unit = 0;

    while (parsedBytes >= 1024) {
      parsedBytes /= 1024;
      unit += 1;
    }

    return `${parsedBytes.toFixed(+precision)} ${this.units[unit]}`;
  }
}
