import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '../../models/unit.interface';

@Pipe({
  name: 'collAppUnitLevel',
})
export class CollAppUnitLevelPipe implements PipeTransform {
  transform(unit?: Unit): number {
    if (unit == null) {
      return 0;
    }

    if (unit.level != null) {
      return unit.level + 1;
    }

    return 0;
  }
}
