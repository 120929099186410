import {
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  CdkPortalOutletAttachedRef, ComponentPortal, Portal, ComponentType,
} from '@angular/cdk/portal';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Calculates the width of the scrollbar of the users browser.
 */
export function calculateScrollBarWidth(document: HTMLDocument): number {
  // Create the measurement DIV
  const scrollDiv = document.createElement('div');
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Delete the DIV
  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService implements OnDestroy {
  // Observables for the CDK Portal Outlets in the main app component.
  readonly sidebarPortal$: Observable<Portal<any>>;

  readonly navbarPortal$: Observable<Portal<any>>;

  readonly asidePortal$: Observable<Portal<any>>;

  // Observables with the component instances from the CDK Portal Outlets in the main app component.
  // !!! Do not keep references to these components in order to preserve the Angular lifecycle events !!!
  // These component references are used *ONLY* to facilitate router-specific tasks.
  readonly sidebarComponent$: Observable<any>;

  readonly navbarComponent$: Observable<any>;

  readonly asideComponent$: Observable<any>;

  hasEmbeddedSidebar: boolean = false;

  // Subjects for the CDK Portal Outlets in the main app component.
  private sidebarPortalSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private navbarPortalSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private asidePortalSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  // Subjects of the component instances from the CDK Portal Outlets in the main app component.
  private sidebarComponentSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private navbarComponentSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private asideComponentSubject$: BehaviorSubject<any> = new BehaviorSubject(undefined);

  private renderer: Renderer2;

  private injector: Injector | undefined;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected rendererFactory: RendererFactory2,
    private defaultInjector: Injector,
  ) {
    this.injector = this.defaultInjector;

    this.sidebarPortal$ = this.sidebarPortalSubject$.asObservable();
    this.navbarPortal$ = this.navbarPortalSubject$.asObservable();
    this.asidePortal$ = this.asidePortalSubject$.asObservable();

    this.sidebarComponent$ = this.sidebarComponentSubject$.asObservable();
    this.navbarComponent$ = this.navbarComponentSubject$.asObservable();
    this.asideComponent$ = this.asideComponentSubject$.asObservable();

    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnDestroy(): void {
    this.sidebarPortalSubject$.complete();
    this.navbarPortalSubject$.complete();
    this.asidePortalSubject$.complete();
    this.sidebarComponentSubject$.complete();
    this.navbarComponentSubject$.complete();
    this.asideComponentSubject$.complete();
  }

  setSidebarComponent(callback?: () => Promise<ComponentType<any>> | ComponentType<any>): void {
    if (callback != null) {
      Promise.resolve(callback())
        .then((component) => {
          const sidebarComponentPortal = new ComponentPortal(
            component,
            null,
            this.injector,
          );

          this.sidebarPortalSubject$.next(sidebarComponentPortal);
        });
    } else {
      this.sidebarPortalSubject$.next(undefined);
    }
  }

  setNavbarComponent(callback?: () => Promise<ComponentType<any>> | ComponentType<any>): void {
    if (callback != null) {
      Promise.resolve(callback())
        .then((component) => {
          const navbarComponentPortal = new ComponentPortal(
            component,
            null,
            this.injector,
          );

          this.navbarPortalSubject$.next(navbarComponentPortal);
        });
    } else {
      this.navbarPortalSubject$.next(undefined);
    }
  }

  setAsideComponent(callback?: () => Promise<ComponentType<any>> | ComponentType<any>): void {
    if (callback != null) {
      Promise.resolve(callback())
        .then((component) => {
          const asideComponentPortal = new ComponentPortal(
            component,
            null,
            this.injector,
          );

          this.asidePortalSubject$.next(asideComponentPortal);
        });
    } else {
      this.asidePortalSubject$.next(undefined);
    }
  }

  setEmbeddedSidebar(hasSidebar: boolean): void {
    this.hasEmbeddedSidebar = hasSidebar;
  }

  setContext(injector: Injector): void {
    this.injector = injector;
  }

  resetContext(): void {
    this.injector = this.defaultInjector;
  }

  /**
   * Updates the reference of the current sidebar component.
   *
   * === Important ===
   * This event handler should be attached to the `attached` event of the
   * matching cdkPortalOutlet directive. This is necessary in order to acquire
   * a globally available reference to the component instantiated within the
   * component portal.
   * =================
   *
   * @param {CdkPortalOutletAttachedRef} ref
   */
  onSidebarPortalAttached(ref: CdkPortalOutletAttachedRef): void {
    if (ref != null && ref instanceof ComponentRef && ref.instance != null) {
      this.sidebarComponentSubject$.next(ref.instance);
    } else {
      this.sidebarComponentSubject$.next(undefined);
    }
  }

  /**
   * Updates the reference of the current navbar component.
   *
   * === Important ===
   * This event handler should be attached to the `attached` event of the
   * matching cdkPortalOutlet directive. This is necessary in order to acquire
   * a globally available reference to the component instantiated within the
   * component portal.
   * =================
   *
   * @param {CdkPortalOutletAttachedRef} ref
   */
  onNavbarPortalAttached(ref: CdkPortalOutletAttachedRef): void {
    if (ref != null && ref instanceof ComponentRef && ref.instance != null) {
      this.navbarComponentSubject$.next(ref.instance);
    } else {
      this.navbarComponentSubject$.next(undefined);
    }
  }

  /**
   * Updates the reference of the current aside component.
   *
   * === Important ===
   * This event handler should be attached to the `attached` event of the
   * matching cdkPortalOutlet directive. This is necessary in order to acquire
   * a globally available reference to the component instantiated within the
   * component portal.
   * =================
   *
   * @param {CdkPortalOutletAttachedRef} ref
   */
  onAsidePortalAttached(ref: CdkPortalOutletAttachedRef): void {
    if (ref != null && ref instanceof ComponentRef && ref.instance != null) {
      this.asideComponentSubject$.next(ref.instance);
    } else {
      this.asideComponentSubject$.next(undefined);
    }
  }
}
