import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UnitPlanningSidepanelIndexStateModel } from './unit-planning-sidepanel-index-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  InitUnitPlanningSidepanelIndex, ResetUnitPlanningSidepanelIndex, SetUnitPlanningSidepanelIndex,
} from './unit-planning-sidepanel-index.actions';

const defaultState: UnitPlanningSidepanelIndexStateModel = { index: 0 };

@State<UnitPlanningSidepanelIndexStateModel>({
  name: 'UnitPlanningSidePanelIndex',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitPlanningSidepanelIndexState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitUnitPlanningSidepanelIndex)
  initUnitPlanningSidepanelIndex(
    ctx: StateContext<UnitPlanningSidepanelIndexStateModel>,
    { settings }: InitUnitPlanningSidepanelIndex,
  ): void {
    ctx.setState(
      { index: settings.index },
    );
  }

  @Action(SetUnitPlanningSidepanelIndex)
  setUnitPlanningSidepanelIndex$(
    ctx: StateContext<UnitPlanningSidepanelIndexStateModel>,
    { settings }: SetUnitPlanningSidepanelIndex,
  ): Observable<void> {
    ctx.patchState({ index: settings.index });

    return this.userService.saveUnitPlanningSidePanelIndex$(ctx.getState());
  }

  @Action(ResetUnitPlanningSidepanelIndex)
  resetUnitPlanningSidepanelIndex(ctx: StateContext<UnitPlanningSidepanelIndexStateModel>): void {
    ctx.setState(defaultState);
  }
}
