import { assertRequiredProperties } from '../../utilities/api.utility';
import { ProjectLevelDto, ProjectPlanningTotalsType } from '../../interfaces/dtos/project-level.dto';
import { ProjectPlanningWorkpackageDtoModel } from './project-planning-workpackage.dto.model';
import { ProjectWorkpackageWeekDtoModel } from './project-planning-workpackage-week.dto.model';

export class ProjectPlanningLevelDtoModel {
  constructor(
    readonly children: ProjectPlanningLevelDtoModel[],
    readonly totals: {[key in ProjectPlanningTotalsType]: number},
    readonly levelId: number,
    readonly name: string[],
    readonly codePath: string,
    readonly levelNumber: number,
    readonly workPackages: ProjectPlanningWorkpackageDtoModel[],
    readonly hasWorkPackages: boolean,
    readonly weeks: ProjectWorkpackageWeekDtoModel[],
  ) {
    if (workPackages) {
      this.workPackages = [...workPackages];
    }
    if (weeks) {
      this.weeks = [...weeks];
    }
    if (children) {
      this.children = [...children];
    }
    if (name) {
      this.name = [...name];
    }
  }

  static fromJSON(json: ProjectLevelDto): ProjectPlanningLevelDtoModel {
    assertRequiredProperties(json, [
      'levelId',
    ]);

    const workpackages = (Array.isArray(json.workPackages) ? json.workPackages : [])
      .map(ProjectPlanningWorkpackageDtoModel.fromJSON);

    const weeks = (Array.isArray(json.weeks) ? json.weeks.map(ProjectWorkpackageWeekDtoModel.fromJSON) : []);

    return new ProjectPlanningLevelDtoModel(
      json.children?.map(ProjectPlanningLevelDtoModel.fromJSON) || null,
      json.totals,
      json.levelId,
      json.name,
      json.codePath,
      json.levelNumber,
      workpackages,
      json.hasWorkPackages,
      weeks,
    );
  }

  clone(): ProjectPlanningLevelDtoModel {
    return new ProjectPlanningLevelDtoModel(
      this.children,
      this.totals,
      this.levelId,
      [...this.name],
      this.codePath,
      this.levelNumber,
      this.workPackages?.map((item) => item.clone()) || null,
      this.hasWorkPackages,
      this.weeks?.map((item) => item.clone()) || null,
    );
  }
}
