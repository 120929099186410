import { UnitPlanningSidepanelStateModel } from './unit-planning-sidepanel-state.model';

/**
 * Action types for UnitPlanningSidepanel
 */
export enum UnitPlanningSidepanelActionTypes {
  InitUnitPlanningSidepanel = '[UnitPlanningSidepanel API] Initialize Sidepanel isOpen',
  OpenUnitPlanningSidepanel = '[UnitPlanningSidepanel API] Set UnitPlanningSidepanel open',
  CloseUnitPlanningSidepanel = '[UnitPlanningSidepanel API] Set UnitPlanningSidepanel closed',
  ToggleUnitPlanningSidepanel = '[UnitPlanningSidepanel API] Toggle UnitPlanningSidepanel',
}

export class InitUnitPlanningSidepanel {
  static readonly type: string = UnitPlanningSidepanelActionTypes.InitUnitPlanningSidepanel;

  constructor(public isOpen: UnitPlanningSidepanelStateModel) {}
}

export class OpenUnitPlanningSidepanel {
  static readonly type: string = UnitPlanningSidepanelActionTypes.OpenUnitPlanningSidepanel;
}

export class CloseUnitPlanningSidepanel {
  static readonly type: string = UnitPlanningSidepanelActionTypes.CloseUnitPlanningSidepanel;
}

export class ToggleUnitPlanningSidepanel {
  static readonly type: string = UnitPlanningSidepanelActionTypes.ToggleUnitPlanningSidepanel;
}
