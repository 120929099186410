import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { ReadonlyCompleteMap } from '../../app.types';
import { QuestionDtoModel } from '../models/dtos/question.dto.model';

export type WorkPackageQuestionListColumnKeys = ObjectPropertyNames<QuestionDtoModel>;
const workPackageQuestionListColumnsInfoObj: ListColumnsInfo<QuestionDtoModel> = {
  questionId: {
    label: 'Question Id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  title: {
    label: 'Title',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  text: {
    label: 'Text',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  status: {
    label: 'Status',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  replyCounter: {
    label: 'Replies',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  createdOn: {
    label: 'Created On',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  creator: {
    label: 'Created By',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  attachments: {
    label: 'Attachments',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  creatorWorkPackageRole: {
    label: 'Creator Role',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  metadata: {
    label: 'Metadata',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const workPackageQuestionListColumnsInfo: ReadonlyCompleteMap<
WorkPackageQuestionListColumnKeys,
ListColumnInfo
> = new Map([
  ['questionId', workPackageQuestionListColumnsInfoObj.questionId],
  ['title', workPackageQuestionListColumnsInfoObj.title],
  ['status', workPackageQuestionListColumnsInfoObj.text],
  ['replyCounter', workPackageQuestionListColumnsInfoObj.replyCounter],
  ['createdOn', workPackageQuestionListColumnsInfoObj.createdOn],
  ['creator', workPackageQuestionListColumnsInfoObj.creator],
  ['attachments', workPackageQuestionListColumnsInfoObj.attachments],
  ['creatorWorkPackageRole', workPackageQuestionListColumnsInfoObj.creatorWorkPackageRole],
]) as ReadonlyCompleteMap<WorkPackageQuestionListColumnKeys, ListColumnInfo>;
