import { WorkPackageSidepanelIndexStateModel } from './work-package-sidepanel-index-state.model';

/**
 * Action types for WorkPackageSidepanelIndex
 */
export enum WorkPackageSidepanelIndexActionTypes {
  InitWorkPackageSidepanelIndex = '[WorkPackageSidepanelIndex API] Initialize Sidepanel',
  SetWorkPackageSidepanelIndex = '[WorkPackageSidepanelIndex API] Set WorkPackageSidepanelIndex',
  ResetWorkPackageSidepanelIndex = '[WorkPackageSidepanelIndex API] Reset WorkPackageSidepanelIndex',
}

export class InitWorkPackageSidepanelIndex {
  static readonly type: string = WorkPackageSidepanelIndexActionTypes.InitWorkPackageSidepanelIndex;

  constructor(public settings: WorkPackageSidepanelIndexStateModel) {}
}

export class ResetWorkPackageSidepanelIndex {
  static readonly type: string = WorkPackageSidepanelIndexActionTypes.ResetWorkPackageSidepanelIndex;
}

export class SetWorkPackageSidepanelIndex {
  static readonly type: string = WorkPackageSidepanelIndexActionTypes.SetWorkPackageSidepanelIndex;

  constructor(public settings: WorkPackageSidepanelIndexStateModel) {}
}
