import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { sharedStates } from '../state';
import {
  CollappQuestionStatusPipe,
  CollAppProjectTypePipe,
  CollAppTimecardStatusPipe,
  CollAppUnitLevelPipe,
  CollAppUserPipe,
  CollAppWorkPackageRolePipe,
  DecimalPipe,
  FileExtensionPipe,
  FileSizePipe,
  FunctionPipe,
  HoursPipe,
  CodePathWithProjectPipe,
  chrcPairTooltipPipe,
  chrcPairPipe,
  MomentLocalPipe,
  MomentPipe,
  MomentUtcPipe,
  SafePipe,
  StripHtmlPipe,
  ToMomentPipe,
  CapacityUnitPipe,
  CapacityUnitShortPipe,
} from './pipes';
import { LocalDateHelperService } from './services';
import { NgHost } from './angular-contrib-host/host';
import { FeatureFlagDirective } from './directives';
import { ElevateOnHoverDirective } from './directives/elevate-on-hover/elevate-on-hover.directive';
import { WorkpackageTitlePipePipe } from './pipes/workpackage-title-pipe.pipe';
import { TimeTrackingStatusPipe } from './pipes/time-tracking-status.pipe';
import { WpFullTitlePipe } from './pipes/wp-fulltitle.pipe';
import { NpcCategoryNamePipe } from './pipes/npc-categoryname.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CostObjectPipe } from './pipes/costObject.pipe';

@NgModule({
  imports: [
    CommonModule,
    // @TODO Is this correct?
    NgxsModule.forFeature(sharedStates),
    TranslateModule,
    CostObjectPipe,
  ],
  exports: [
    TranslateModule,
    CollAppProjectTypePipe,
    CollAppTimecardStatusPipe,
    CollAppUnitLevelPipe,
    CollAppUserPipe,
    CollAppWorkPackageRolePipe,
    CollappQuestionStatusPipe,
    FileExtensionPipe,
    DecimalPipe,
    FileSizePipe,
    FunctionPipe,
    WorkpackageTitlePipePipe,
    HoursPipe,
    CapacityUnitPipe,
    CapacityUnitShortPipe,
    CodePathWithProjectPipe,
    chrcPairTooltipPipe,
    chrcPairPipe,
    StripHtmlPipe,
    SafePipe,
    NgHost,
    MomentPipe,
    MomentLocalPipe,
    MomentUtcPipe,
    ToMomentPipe,
    FeatureFlagDirective,
    ElevateOnHoverDirective,
    TimeTrackingStatusPipe,
    WpFullTitlePipe,
    NpcCategoryNamePipe,
    CostObjectPipe,
  ],
  declarations: [
    CollAppProjectTypePipe,
    CollAppTimecardStatusPipe,
    CollAppUnitLevelPipe,
    CollAppUserPipe,
    CollAppWorkPackageRolePipe,
    FileExtensionPipe,
    CollappQuestionStatusPipe,
    DecimalPipe,
    FileSizePipe,
    FunctionPipe,
    HoursPipe,
    CapacityUnitPipe,
    CapacityUnitShortPipe,
    CodePathWithProjectPipe,
    chrcPairTooltipPipe,
    chrcPairPipe,
    StripHtmlPipe,
    SafePipe,
    NgHost,
    MomentPipe,
    MomentLocalPipe,
    MomentUtcPipe,
    ToMomentPipe,
    FeatureFlagDirective,
    ElevateOnHoverDirective,
    WorkpackageTitlePipePipe,
    TimeTrackingStatusPipe,
    WpFullTitlePipe,
    NpcCategoryNamePipe,
  ],
  providers: [
    LocalDateHelperService,
    MomentPipe,
    MomentLocalPipe,
    MomentUtcPipe,
    TimeTrackingStatusPipe,
  ],
})
export class CollappCommonModule {
}
