import { SkillRatingSkillDtoModel } from './skill-ratings-skill.dto.model';
import { SkillRatingCategoryDto } from '../../interfaces/dtos/skill-rating-category.dto';

export class SkillRatingCategoryDtoModel {
  constructor(
    readonly skills: SkillRatingSkillDtoModel[],
    readonly id: number,
    readonly name: string | null,
  ) {
    this.skills = skills.map((skill) => skill.clone());
  }

  static fromJSON(json: SkillRatingCategoryDto): SkillRatingCategoryDtoModel {
    return new SkillRatingCategoryDtoModel(
      json.skills.map(SkillRatingSkillDtoModel.fromJSON),
      json.id,
      json.name,
    );
  }

  clone(): SkillRatingCategoryDtoModel {
    return new SkillRatingCategoryDtoModel(
      this.skills.map((skill) => skill.clone()),
      this.id,
      this.name,
    );
  }

  toJSON(): SkillRatingCategoryDto {
    return {
      skills: this.skills.map((skill) => skill.toJSON()),
      id: this.id,
      name: this.name,
    };
  }
}
