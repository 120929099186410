import { CollappFilterDropdownListRadioSetting } from '../../../models/collapp-filter-dropdown-list-radio.interface';
import { ProjectPlanningFiltersSettingsModel } from '../../../models/project-planning-filter-settings.interface';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { UserSettingsDto } from '../../interfaces/dtos/user-settings.dto';
import { ProjectRecentListDtoModel } from './project-recent-list.dto.model';
import { ProjectListStateModel } from '../../../state/settings/project-list';
import { UnitListStateModel } from '../../../state/settings/unit-list';
import { UserListStateModel } from '../../../state/settings/user-list';
import { WorkPackageListStateModel } from '../../../state/settings/work-package-list';
import { WorkPackageQuestionListStateModel } from '../../../state/settings/work-package-question-list';
import { QuestionsListStateModel } from '../../../state/settings/questions-list';
import { UserResourceListStateModel } from '../../../state/settings/user-resource-list';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { AttachmentListStateModel } from '../../../state/settings/attachment-list/attachment-list-state.model';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { NonHumanResourceListStateModel } from '../../../state/settings/non-human-resource-list';
import { NonHumanResourcePlanningListStateModel } from '../../../state/settings/non-human-resource-planning-list/non-human-resource-planning-list-state.model';
import { UnitSkillDtoModel } from './unit-skill.dto.model';
import { ActivitiesStateModel } from '../../../state/settings/activities/activities-state.model';
import { DashboardWorkPackageViewType } from '../../../models/dashboard-work-package-view-type.enum';
import { RangeSettingModel } from './range-setting.dto.model';
import { ProjectStructureFiltersSettingsModel } from '../../../models/project-structure-filter-settings.interface';

export class UserSettingsDtoModel {
  constructor(
    readonly recentProjects: readonly ProjectRecentListDtoModel[] = [],
    readonly projectsTable: ProjectListStateModel | null = null,
    readonly unitsTable: UnitListStateModel | null = null,
    readonly nonHumanResourcesTable: NonHumanResourceListStateModel | null = null,
    readonly usersTable: UserListStateModel | null = null,
    readonly workPackagesTable: WorkPackageListStateModel | null = null,
    readonly workPackageQuestionsTable: WorkPackageQuestionListStateModel | null,
    readonly questionsTable: QuestionsListStateModel | null = null,
    readonly userResourceTable: UserResourceListStateModel | null,
    readonly attachmentList: AttachmentListStateModel | null = null,
    readonly timesheetWithCrossUtilizeUsers: boolean | null = null,
    readonly recentTimesheetUnits: readonly UnitSlimDtoModel[] = [],
    readonly recentSkillUnits: readonly UnitSkillDtoModel[] = [],
    readonly recentPlanningUnits: readonly UnitSlimDtoModel[] = [],
    readonly recentUsedSupplierUnits: readonly UnitSlimDtoModel[] = [],
    readonly nonHumanResourcesPlanningTable: NonHumanResourcePlanningListStateModel | null = null,
    readonly activities: ActivitiesStateModel | null = null,
    readonly dashboardWorkPackageFilters: DashboardWorkPackageViewType[] | null = null,
    readonly userPlanningTotals: string[] | null = null,
    readonly unitPlanningTotals: string[] | null = null,
    readonly userPlanningRange: RangeSettingModel | null = null,
    readonly unitPlanningRange: RangeSettingModel | null = null,
    readonly projectPlanningRange: RangeSettingModel | null = null,
    readonly projectPlanningTotals: string[] | null = null,
    readonly timecardRange: RangeSettingModel | null = null,
    readonly timesheetRange: RangeSettingModel | null = null,
    readonly workPackagePlanningRange: RangeSettingModel | null = null,
    readonly workPackagePlanningTotals: string[] | null = null,
    readonly timesheetTotals: string[] | null = null,
    readonly workPackageSidePanel: boolean | null = null,
    readonly userPlanningSidePanel: boolean | null = null,
    readonly unitPlanningSidePanel: boolean | null = null,
    readonly unitPlanningSidePanelIndex: number | null = null,
    readonly userPlanningSidePanelIndex: number | null = null,
    readonly workPackageSidePanelIndex: number | null = null,
    readonly unitPlanningCapacityUnit: CapacityUnits | null = null,
    readonly projectPlanningCapacityUnit: CapacityUnits | null = null,
    public projectsStrucureHeaderFilters?: ProjectStructureFiltersSettingsModel | null,
    public projectPlanningHeaderFilters?: ProjectPlanningFiltersSettingsModel | null,
    public timecardHeaderfilters?: CollappFilterDropdownListRadioSetting[] | null,
    public planningHeaderfilters?: CollappFilterDropdownListRadioSetting[] | null,
    public projectPlanningCompactFilter?: boolean | null,
  ) {
    this.recentProjects = [...recentProjects];
    this.recentTimesheetUnits = recentTimesheetUnits.map((unit) => unit.clone());
    this.recentSkillUnits = recentSkillUnits.map((unit) => unit.clone());
    this.recentPlanningUnits = recentPlanningUnits.map((unit) => unit.clone());
    this.recentUsedSupplierUnits = recentUsedSupplierUnits.map((unit) => unit.clone());
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static fromJSON(json: UserSettingsDto): UserSettingsDtoModel {
    assertRequiredProperties(json, [
      // 'recentProjects',
    ]);
    const capacityUnit = Object.values(CapacityUnits)
      .filter((cu) => cu.toString() === json?.unitPlanningCapacityUnit)?.[0] || null;
    const projectPlanningCapacityUnit = Object.values(CapacityUnits)
      .filter((cu) => cu.toString() === json?.projectPlanningCapacityUnit)?.[0] || null;

    return new UserSettingsDtoModel(
      (Array.isArray(json.recentProjects) ? json.recentProjects : [])
        .map((item) => ProjectRecentListDtoModel.fromJSON(item)),
      (json.projectsTable
        ? JSON.parse(json.projectsTable)
        : null),
      (json.unitsTable
        ? JSON.parse(json.unitsTable)
        : null),
      (json.nonHumanResourcesTable
        ? JSON.parse(json.nonHumanResourcesTable)
        : null),
      (json.usersTable
        ? JSON.parse(json.usersTable)
        : null),
      (json.workPackagesTable
        ? JSON.parse(json.workPackagesTable)
        : null),
      (json.workPackageQuestionsTable
        ? JSON.parse(json.workPackageQuestionsTable)
        : null),
      (json.questionsTable
        ? JSON.parse(json.questionsTable)
        : null),
      (json.userResourceTable
        ? JSON.parse(json.userResourceTable)
        : null),
      (json.attachmentList
        ? JSON.parse(json.attachmentList)
        : null),
      (json.timesheetWithCrossUtilizeUsers != null
        ? json.timesheetWithCrossUtilizeUsers
        : null),
      (Array.isArray(json.recentTimesheetUnits) ? json.recentTimesheetUnits : [])
        .map((item) => UnitSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.recentSkillUnits) ? json.recentSkillUnits : [])
        .map((item) => UnitSkillDtoModel.fromJSON(item)),
      (Array.isArray(json.recentPlanningUnits) ? json.recentPlanningUnits : [])
        .map((item) => UnitSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.recentUsedSupplierUnits) ? json.recentUsedSupplierUnits : [])
        .map((item) => UnitSlimDtoModel.fromJSON(item)),
      (json.nonHumanResourcesPlanningTable
        ? JSON.parse(json.nonHumanResourcesPlanningTable)
        : null),
      (json.activities
        ? JSON.parse(json.activities)
        : null),
      json.dashboardWorkPackageFilters,
      json.userPlanningTotals ? JSON.parse(json.userPlanningTotals) : null,
      json.unitPlanningTotals ? JSON.parse(json.unitPlanningTotals) : null,
      json.userPlanningRange ? RangeSettingModel.fromJSON(JSON.parse(json.userPlanningRange)) : null,
      json.unitPlanningRange ? RangeSettingModel.fromJSON(JSON.parse(json.unitPlanningRange)) : null,
      json.projectPlanningRange ? RangeSettingModel.fromJSON(JSON.parse(json.projectPlanningRange)) : null,
      json.projectPlanningTotals ? JSON.parse(json.projectPlanningTotals) : null,
      json.timecardRange ? RangeSettingModel.fromJSON(JSON.parse(json.timecardRange)) : null,
      json.timesheetRange ? RangeSettingModel.fromJSON(JSON.parse(json.timesheetRange)) : null,
      json.workPackagePlanningRange ? RangeSettingModel.fromJSON(JSON.parse(json.workPackagePlanningRange)) : null,
      json.workPackagePlanningTotals ? JSON.parse(json.workPackagePlanningTotals) : null,
      json.timesheetTotals ? JSON.parse(json.timesheetTotals) : null,
      !!json.workPackageSidePanel,
      !!json.userPlanningSidePanel,
      !!json.unitPlanningSidePanel,
      json.unitPlanningSidePanelIndex ? json.unitPlanningSidePanelIndex : null,
      json.userPlanningSidePanelIndex ? json.userPlanningSidePanelIndex : null,
      json.workPackageSidePanelIndex ? json.workPackageSidePanelIndex : null,
      capacityUnit,
      projectPlanningCapacityUnit,
      json.projectsStructureHeaderFilters
        ? ProjectStructureFiltersSettingsModel.fromJSON(JSON.parse(json.projectsStructureHeaderFilters)) : null,
      json.projectPlanningHeaderFilters
        ? ProjectPlanningFiltersSettingsModel.fromJSON(JSON.parse(json.projectPlanningHeaderFilters)) : null,
      json.timecardHeaderfilters
        ? JSON.parse(json.timecardHeaderfilters) : null,
      json.planningHeaderfilters
        ? JSON.parse(json.planningHeaderfilters) : null,
      !!json.projectPlanningCompactFilter,
    );
  }

  clone(): UserSettingsDtoModel {
    return UserSettingsDtoModel.fromJSON(this.toJSON());
  }

  // eslint-disable-next-line complexity
  toJSON(): UserSettingsDto {
    return {
      recentProjects: [...this.recentProjects],
      projectsTable: this.projectsTable ? JSON.stringify(this.projectsTable) : null,
      unitsTable: this.unitsTable ? JSON.stringify(this.unitsTable) : null,
      nonHumanResourcesTable: this.nonHumanResourcesTable ? JSON.stringify(this.nonHumanResourcesTable) : null,
      usersTable: this.usersTable ? JSON.stringify(this.usersTable) : null,
      workPackagesTable: this.workPackagesTable ? JSON.stringify(this.workPackagesTable) : null,
      workPackageQuestionsTable: this.workPackageQuestionsTable ? JSON.stringify(this.workPackageQuestionsTable) : null,
      questionsTable: this.questionsTable ? JSON.stringify(this.questionsTable) : null,
      userResourceTable: this.userResourceTable ? JSON.stringify(this.userResourceTable) : null,
      attachmentList: this.attachmentList ? JSON.stringify(this.attachmentList) : null,
      timesheetWithCrossUtilizeUsers: this.timesheetWithCrossUtilizeUsers != null
        ? this.timesheetWithCrossUtilizeUsers
        : null,
      recentTimesheetUnits: this.recentTimesheetUnits.map((model) => model.toJSON()),
      recentSkillUnits: this.recentSkillUnits.map((model) => model.toJSON()),
      recentPlanningUnits: this.recentPlanningUnits.map((model) => model.toJSON()),
      recentUsedSupplierUnits: this.recentUsedSupplierUnits.map((model) => model.toJSON()),
      nonHumanResourcesPlanningTable: this.nonHumanResourcesPlanningTable != null
        ? JSON.stringify(this.nonHumanResourcesPlanningTable)
        : null,
      activities: this.activities != null ? JSON.stringify(this.activities) : null,
      dashboardWorkPackageFilters: this.dashboardWorkPackageFilters ? [...this.dashboardWorkPackageFilters] : null,
      userPlanningTotals: this.userPlanningTotals ? JSON.stringify(this.userPlanningTotals) : null,
      unitPlanningTotals: this.unitPlanningTotals ? JSON.stringify(this.unitPlanningTotals) : null,
      userPlanningRange: this.userPlanningRange ? JSON.stringify(this.userPlanningRange.toJSON()) : null,
      unitPlanningRange: this.unitPlanningRange ? JSON.stringify(this.unitPlanningRange.toJSON()) : null,
      projectPlanningRange: this.projectPlanningRange ? JSON.stringify(this.projectPlanningRange.toJSON()) : null,
      projectPlanningTotals: this.projectPlanningTotals ? JSON.stringify(this.projectPlanningTotals) : null,
      timecardRange: this.timecardRange ? JSON.stringify(this.timecardRange.toJSON()) : null,
      timesheetRange: this.timesheetRange ? JSON.stringify(this.timesheetRange.toJSON()) : null,
      workPackagePlanningRange: this.workPackagePlanningRange
        ? JSON.stringify(this.workPackagePlanningRange.toJSON())
        : null,
      timesheetTotals: this.timesheetTotals ? JSON.stringify(this.timesheetTotals) : null,
      workPackagePlanningTotals: this.workPackagePlanningTotals ? JSON.stringify(this.workPackagePlanningTotals) : null,
      workPackageSidePanel: this.workPackageSidePanel != null ? this.workPackageSidePanel : null,
      userPlanningSidePanel: this.userPlanningSidePanel != null ? this.userPlanningSidePanel : null,
      unitPlanningSidePanel: this.unitPlanningSidePanel != null ? this.unitPlanningSidePanel : null,
      unitPlanningSidePanelIndex: this.unitPlanningSidePanelIndex != null ? this.unitPlanningSidePanelIndex : null,
      userPlanningSidePanelIndex: this.userPlanningSidePanelIndex != null ? this.userPlanningSidePanelIndex : null,
      workPackageSidePanelIndex: this.workPackageSidePanelIndex != null ? this.workPackageSidePanelIndex : null,
      unitPlanningCapacityUnit: this.unitPlanningCapacityUnit?.toString() || null,
      projectPlanningCapacityUnit: this.projectPlanningCapacityUnit?.toString() || null,
      // eslint-disable-next-line max-len
      projectPlanningHeaderFilters: this.projectPlanningHeaderFilters ? JSON.stringify(this.projectPlanningHeaderFilters) : null,
      timecardHeaderfilters: this.timecardHeaderfilters ? JSON.stringify(this.timecardHeaderfilters) : null,
      planningHeaderfilters: this.planningHeaderfilters ? JSON.stringify(this.planningHeaderfilters) : null,
      // eslint-disable-next-line max-len
      projectPlanningCompactFilter: !!this.projectPlanningCompactFilter,
    };
  }
}
