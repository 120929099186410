import { QuestionReplyListResponseMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { ReplyListResponse } from '../../interfaces/responses/reply-list.response';
import { ReplyDtoModel } from '../dtos/reply-dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ReplyListResponseModel {
  constructor(
    readonly items: readonly ReplyDtoModel[],
    readonly metadata: MetadataModel<QuestionReplyListResponseMetadata>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: ReplyListResponse): ReplyListResponseModel {
    assertRequiredProperties(json, [
      // 'items',
    ]);

    return new ReplyListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => ReplyDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }
}
