<header class="collapp-dialog__header">
  <h2 class="collapp-dialog__title">
    {{ title }}
  </h2>
  <button type="button" class="collapp-dialog__close" (click)="cancel()">
    <span class="ic-close"></span>
  </button>
</header>

<form [formGroup]="form">
  <mat-dialog-content>
    <p class="collapp-dialog__message">
      {{ message }}
    </p>

    <div class="notification-dialog__body">
      <collapp-form-section [label]="'Comment'">
      <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
        <mat-form-field
          fxFill="100%"
          class="collapp-dialog__form-field--compact">
          <textarea
            matInput
            [placeholder]="'Leave a comment ' + (isCommentRequired ? '*' : '')"
            cdkTextareaAutosize
            [required]="isCommentRequired"
            [formControlName]="'comment'"
            class="collapp-dialog__textarea"
            cdkAutosizeMinRows="1"
            >
          </textarea>
          @if (comment.hasError('required')) {
            <mat-error>
              Field is <strong>required</strong>.
            </mat-error>
          }
        </mat-form-field>
      </div>
      @if (recipients.length > 0) {
        <div class="collapp-dialog__recipient">
          <div class="collapp-dialog__recipient-icon">
            <mat-icon role="img" aria-hidden="true">email</mat-icon>
          </div>
          <div class="collapp-dialog__recipient-message">
            Email notification will be sent to: {{ recipientsAsString }}
          </div>
        </div>
      }
      @if (recipients.length > 0 && showSendNotifications) {
        <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
          <div class="collapp-dialog__send-notifications">
            <mat-checkbox
              [checked]="true"
              [formControl]="sendNotifications">
              <span>
                Send Notifications
              </span>
            </mat-checkbox>
          </div>
        </div>
      }
    </collapp-form-section>
  </div>
</mat-dialog-content>

<footer class="collapp-dialog__footer">
  <span class="flex-spacer"></span>
  <button
    type="button"
    collapp-stroked-button
    (click)="cancel()"
    >Cancel
  </button>
  <button
    type="submit"
    collapp-flat-button
    color="primary"
    (click)="confirm()"
    >OK
  </button>
</footer>
</form>
