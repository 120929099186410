import moment from 'moment';
import { ToastOptions } from '../shared/toasty';

/**
 * Returns an options object for a Toasty error message, describing the given API error.
 *
 * @param title  -  The title of the message
 * @param error  -  The error coming from the API response
 */
export function getToastForApiError(title: string, error: Error): ToastOptions {
  const today = moment()
    .utc();
  const formattedDate = `${today.format('YYYY-MM-DD HH:mm:ss')} UTC`;
  const msg = `
    ${error.message.replace('\n', '<br/>\n')}
    ${error.name ? `<span class="collapp-api-error-status">(${error.name})</span>` : ''}
    <span class="collapp-api-error-date">${formattedDate}</span>
  `;

  return {
    title,
    msg,
  };
}
