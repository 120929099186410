import { UserPlanningTotalsStateModel } from './user-planning-totals-state.model';

/**
 * Action types for UserPlanningTotalsState
 */
export enum UserPlanningTotalsActionTypes {
  InitUserPlanningTotalsSettings = '[User Planning API] Initialize User Planning Totals Settings',
  SetUserPlanningTotalsSettings = '[User Planning API] Set User Planning Totals Settings',
  ResetUserPlanningTotalsSettings = '[User Planning API] Reset User Planning Totals Settings',
  SaveUserPlanningTotalsSettings = '[User Planning API] Save User Planning Totals Settings',
}

export class InitUserPlanningTotalsSettings {
  static readonly type: string = UserPlanningTotalsActionTypes.InitUserPlanningTotalsSettings;

  constructor(public settings: UserPlanningTotalsStateModel) {}
}

export class SetUserPlanningTotalsSettings {
  static readonly type: string = UserPlanningTotalsActionTypes.SetUserPlanningTotalsSettings;

  constructor(public settings: UserPlanningTotalsStateModel) {}
}

export class ResetUserPlanningTotalsSettings {
  static readonly type: string = UserPlanningTotalsActionTypes.ResetUserPlanningTotalsSettings;
}

export class SaveUserPlanningTotalsSettings {
  static readonly type: string = UserPlanningTotalsActionTypes.SaveUserPlanningTotalsSettings;

  constructor(public settings: UserPlanningTotalsStateModel) {}
}
