import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CollappCommonModule } from '../../collapp-common';
import { PersonIconModule } from '../person-icon';
import { PersonComponent } from './person.component';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    CollappCommonModule,
    PersonIconModule,
  ],
  exports: [
    PersonIconModule,
    PersonComponent,
  ],
  declarations: [
    PersonComponent,
  ],
})
export class PersonModule { }
