import { DashboardFiltersVisibilityResponse } from '../../interfaces/responses/dashboard-filters-visibility.response';

export class DashboardFiltersVisibilityResponseModel {
  constructor(
    readonly isSelfAssignViewFilterVisible: boolean,
    readonly isSubmitterViewFilterVisible: boolean,
    readonly isSupplierViewFilterVisible: boolean,
  ) {}

  static fromJSON(json: DashboardFiltersVisibilityResponse): DashboardFiltersVisibilityResponseModel {
    return new DashboardFiltersVisibilityResponseModel(
      json.isSelfAssignViewFilterVisible,
      json.isSubmitterViewFilterVisible,
      json.isSupplierViewFilterVisible,
    );
  }
}
