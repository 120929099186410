import { Selector } from '@ngxs/store';
import { UnitPlanningTotalsStateModel } from './unit-planning-totals-state.model';
import { UnitPlanningTotalsState } from './unit-planning-totals.state';

export class UnitPlanningTotalsSelectors {
  @Selector([UnitPlanningTotalsState])
  static selectedTotals(state: UnitPlanningTotalsStateModel): UnitPlanningTotalsStateModel['selectedTotals'] {
    return state.selectedTotals;
  }

  @Selector([UnitPlanningTotalsState])
  static selectedCapacityUnit(state: UnitPlanningTotalsStateModel): UnitPlanningTotalsStateModel['selectedCapacityUnit'] {
    return state.selectedCapacityUnit;
  }
}
