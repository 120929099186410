import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { UnitPlanningSidepanelIndexStateModel } from './unit-planning-sidepanel-index-state.model';
import { UnitPlanningSidepanelIndexState } from './unit-planning-sidepanel-index.state';

@Injectable({
  providedIn: 'root',
})
export class UnitPlanningSidepanelIndexSelectors {
  @Selector([UnitPlanningSidepanelIndexState])
  static index(state: UnitPlanningSidepanelIndexStateModel): number {
    return state.index;
  }
}
