import moment from 'moment';
import { MilestoneBaseModel } from '../base/milestone.base.model';
import { MilestoneImportDto } from '../../interfaces/dtos/milestone-import.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class MilestoneImportDtoModel extends MilestoneBaseModel {
  static fromJSON(json: MilestoneImportDto): MilestoneImportDtoModel {
    assertRequiredProperties(json, [
      'title',
      'dueDate',
      'isCompleted',
      'sortOrder',
    ]);

    return new MilestoneImportDtoModel(
      json.title,
      moment(json.dueDate)
        .parseZone(),
      json.isCompleted,
      json.sortOrder,
    );
  }

  clone(overrides?: Partial<MilestoneImportDtoModel>): MilestoneImportDtoModel {
    return new MilestoneImportDtoModel(
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.dueDate != null
        ? overrides.dueDate
        : this.dueDate
      ),
      (overrides && overrides.isCompleted != null
        ? overrides.isCompleted
        : this.isCompleted
      ),
      (overrides && overrides.sortOrder != null
        ? overrides.sortOrder
        : this.sortOrder
      ),
    );
  }
}
