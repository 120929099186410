import {
  Optional, Pipe, PipeTransform, ɵstringify as stringify,
} from '@angular/core';
import { Moment } from 'moment';
import { CollappDateAdapter } from '../../collapp-core';
import { isEmpty } from '../../helpers/utilities';
import { DateFormatPatterns, formatDate } from '../date';

@Pipe({
  name: 'momentLocal',
})
export class MomentLocalPipe implements PipeTransform {
  private dateAdapter: CollappDateAdapter;

  constructor(
  @Optional() dateAdapter?: CollappDateAdapter,
  ) {
    if (!dateAdapter) {
      throw new Error(`${stringify(MomentLocalPipe)}: No provider found for MomentDateAdapter.`);
    }

    this.dateAdapter = dateAdapter;
  }

  /**
   * Converts the date to the current local time and outputs a string according to the given format.
   *
   * @param [keepLocalTime=false] - Passing true will keep the same local time, but at the expense of choosing
   * a different point in Universal Time.
   */
  transform(
    value: Moment | null | undefined,
    format?: DateFormatPatterns | string,
    keepLocalTime?: boolean,
  ): string | null {
    if (isEmpty(value)) {
      return null;
    }

    try {
      return formatDate(value, format, this.dateAdapter.utcOffset(), keepLocalTime);
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(MomentLocalPipe)}'`);
    }
  }
}
