import { TimeTrackingDayInfoDtoModel } from '../dtos/time-tracking-day-info.dto.model';
import { TimeTrackingRowDtoModel } from '../dtos/time-tracking-row.dto.model';
import { MetadataModel } from '../metadata.model';
import { TimeTrackingStatus } from '../../../models/time-tracking-status.enum';
import { TimesheetViewResponse } from '../../interfaces/responses/timesheet-view.response';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { TimesheetViewResponseMetadata } from '../../interfaces/metadata';
import { TimeTrackingUnitUsersDtoModel } from '../dtos/time-tracking-unit-users.dto.models';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimesheetViewResponseModel {
  constructor(
    readonly status: TimeTrackingStatus,
    readonly days: readonly TimeTrackingDayInfoDtoModel[],
    readonly sums: readonly TimeTrackingRowDtoModel[],
    readonly unit: UnitSlimDtoModel,
    readonly unitUsers: readonly TimeTrackingUnitUsersDtoModel[] = [],
    readonly totalPlanned: number,
    readonly totalPlannedNonProductiveHours: number,
    readonly totalPlannedProductiveHours: number,
    readonly metadata: MetadataModel<TimesheetViewResponseMetadata> = new MetadataModel(),
    readonly isSubmitterView: boolean,
    readonly isAllowedToChangeSubmitterView: boolean,
  ) {
    this.days = [...days];
    this.sums = [...sums];
    this.unitUsers = [...unitUsers];
  }

  static fromJSON(json: TimesheetViewResponse): TimesheetViewResponseModel {
    assertRequiredProperties(json, [
      'status',
      'days',
      'sums',
      'unit',
      'isSubmitterView',
      'isAllowedToChangeSubmitterView'
    ]);

    return new TimesheetViewResponseModel(
      json.status,
      (Array.isArray(json.days) ? json.days : [])
        .map((item) => TimeTrackingDayInfoDtoModel.fromJSON(item)),
      (Array.isArray(json.sums) ? json.sums : [])
        .map((item) => TimeTrackingRowDtoModel.fromJSON(item)),
      UnitSlimDtoModel.fromJSON(json.unit),
      (Array.isArray(json.unitUsers) ? json.unitUsers : [])
        .map((item) => TimeTrackingUnitUsersDtoModel.fromJSON(item)),
      json.totalPlanned,
      json.totalPlannedNonProductiveHours,
      json.totalPlannedProductiveHours,
      new MetadataModel(json.metadata),
      json.isSubmitterView,
      json.isAllowedToChangeSubmitterView,
    );
  }

  /**
   * Flag which indicates whether the user is privileged to reject or approve timecards.
   */
  canRejectAndApproveMultipleTimecards(): boolean {
    if (this.status === TimeTrackingStatus.Done) {
      return false;
    }

    return this.metadata.fields && this.metadata.fields.canRejectAndApproveMultipleTimecards;
  }

  /**
   * Whether the user is privileged to export this timesheet view.
   */
  canExportTimesheet(): boolean {
    return this.metadata.fields && this.metadata.fields.canExportTimesheet;
  }

  /**
   * Whether the user is privileged to submit timecard information
   */
  shouldSubmitBeDisplayed(): boolean {
    return this.metadata.fields && this.metadata.fields.shouldSubmitBeDisplayed;
  }

  canAccessTimecards(): boolean {
    return this.metadata.fields && this.metadata.fields.canAccessTimecards;
  }

  /**
   * Whether this timesheet is enabled / valid for an export.
   */
  isTimesheetExportApproved(): boolean {
    return this.metadata.fields && this.metadata.fields.isTimesheetExportApproved;
  }

  toJSON(): TimesheetViewResponse {
    return {
      status: this.status,
      days: this.days.map((item) => item.toJSON()),
      sums: this.sums.map((item) => item.toJSON()),
      unit: this.unit.toJSON(),
      unitUsers: this.unitUsers.map((item) => item.toJSON()),
      totalPlanned: this.totalPlanned,
      totalPlannedNonProductiveHours: this.totalPlannedNonProductiveHours,
      totalPlannedProductiveHours: this.totalPlannedProductiveHours,
      metadata: this.metadata.toJSON(),
      isSubmitterView: this.isSubmitterView,
      isAllowedToChangeSubmitterView: this.isAllowedToChangeSubmitterView,
    };
  }
}
