import { LevelCreateResponse } from '../../interfaces/responses/level-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelCreateResponseModel {
  constructor(
    readonly levelId: number,
  ) {
  }

  static fromJSON(json: LevelCreateResponse): LevelCreateResponseModel {
    assertRequiredProperties(json, [
      'levelId',
    ]);

    return new LevelCreateResponseModel(
      json.levelId,
    );
  }

  toJSON(): LevelCreateResponse {
    return {
      levelId: this.levelId,
    };
  }
}
