import { UnitViewResponse, UnitViewResponseMetadata } from '../../interfaces/responses/unit-view.response';
import { ContractDtoModel } from '../dtos/contract.dto.model';
import { MetadataModel } from '../metadata.model';
import { UnitLevel } from '../../../models/unit-level.enum';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { UnitCostCenterModel } from '../dtos/unit-cost-center.dto.model';
import { NonProductiveCategoryModel } from '../../../models/non-productive-category.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { HIDDEN_LEVELS_DEFAULT } from 'src/app/modules/administration/units/dialogs/edit-unit/edit-unit-dialog.component';

export class UnitViewResponseModel {
  readonly canEditUnitDetails: boolean;

  /** @deprecated */
  readonly canEditUnitContracts: boolean;

  canDisableUnit: boolean;

  constructor(
    readonly unitId: number,
    readonly name: string,
    readonly level: UnitLevel,
    readonly code: string,
    readonly parentUnit: UnitSlimDtoModel | null = null,
    readonly unitCoordinator: UserSlimDtoModel | null = null,
    readonly defects: readonly string[] = [],
    readonly nonProductiveCategories: readonly NonProductiveCategoryModel[] = [],
    readonly deputyUnitCoordinators: readonly UserSlimDtoModel[] = [],
    readonly submitterContracts: readonly ContractDtoModel[] = [],
    readonly supplierContracts: readonly ContractDtoModel[] = [],
    readonly unitCostCenters: readonly UnitCostCenterModel[] = [],
    public isDisabled: boolean,
    readonly migratedToEtime: boolean,
    readonly migratedToReiwa: boolean,
    readonly metadata: MetadataModel<UnitViewResponseMetadata> = new MetadataModel(),
    readonly directUserSubmissionOfTimecard: boolean,
    readonly hiddenLevels: string,
    readonly payrollCompany: string = '',
    readonly profitCenter: string = '',
  ) {
    this.defects = [...defects];
    this.nonProductiveCategories = [...nonProductiveCategories];
    this.deputyUnitCoordinators = [...deputyUnitCoordinators];
    this.submitterContracts = [...submitterContracts];
    this.supplierContracts = [...supplierContracts];
    this.unitCostCenters = [...unitCostCenters];
    this.canEditUnitDetails = !!this.metadata.fields.canEditUnitDetails;
    this.canEditUnitContracts = !!this.metadata.fields.canEditUnitContracts;
    this.canDisableUnit = !!this.metadata.fields.canDisableUnit;
  }

  static fromJSON(json: UnitViewResponse): UnitViewResponseModel {
    assertRequiredProperties(json, [
      'unitId',
      'name',
      'level',
      'code',
      'metadata',
      'costCenters',
    ]);

    return new UnitViewResponseModel(
      json.unitId,
      json.name,
      json.level,
      json.code,
      (json.parentUnit
        ? UnitSlimDtoModel.fromJSON(json.parentUnit)
        : null
      ),
      (json.unitCoordinator
        ? UserSlimDtoModel.fromJSON(json.unitCoordinator)
        : null
      ),
      [...(Array.isArray(json.defects) ? json.defects : [])],
      (Array.isArray(json.nonProductiveCategories) ? json.nonProductiveCategories : [])
        .map((item) => NonProductiveCategoryModel.fromJSON(item)),
      (Array.isArray(json.deputyUnitCoordinators) ? json.deputyUnitCoordinators : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.submitterContracts) ? json.submitterContracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      (Array.isArray(json.supplierContracts) ? json.supplierContracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      (Array.isArray(json.costCenters) ? json.costCenters : [])
        .map((item) => UnitCostCenterModel.fromJSON(item)),
      json.isDisabled,
      json.migratedToEtime,
      json.migratedToReiwa,
      new MetadataModel(json.metadata || {}),
      json.directUserSubmissionOfTimecard,
      json.hiddenLevels || HIDDEN_LEVELS_DEFAULT,
      json.payrollCompany || '',
      json.profitCenter || '',
    );
  }

  toJSON(): UnitViewResponse {
    return {
      unitId: this.unitId,
      name: this.name,
      level: this.level,
      code: this.code,
      parentUnit: (this.parentUnit ? this.parentUnit.toJSON() : null),
      unitCoordinator: (this.unitCoordinator ? this.unitCoordinator.toJSON() : null),
      defects: [...this.defects],
      nonProductiveCategories: this.nonProductiveCategories.map((item) => item.toJSON()),
      deputyUnitCoordinators: this.deputyUnitCoordinators.map((item) => item.toJSON()),
      submitterContracts: this.submitterContracts.map((item) => item.toJSON()),
      supplierContracts: this.supplierContracts.map((item) => item.toJSON()),
      costCenters: this.unitCostCenters.map((item) => item.toJSON()),
      isDisabled: this.isDisabled,
      migratedToEtime: this.migratedToEtime,
      migratedToReiwa: this.migratedToReiwa,
      metadata: this.metadata.toJSON(),
      directUserSubmissionOfTimecard: this.directUserSubmissionOfTimecard,
      hiddenLevels: this.hiddenLevels,
      payrollCompany: this.payrollCompany,
      profitCenter: this.profitCenter
    };
  }
}
