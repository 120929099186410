import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ProjectStateModel } from './project-state.model';
import {
  ClearViews,
  RefreshAllProjectViews,
  RefreshProjectStructure,
  RefreshProjectView,
  RefreshWorkPackageView,
  SetProjectActivityView,
  SetProjectScopeView,
  SetProjectView,
  SetWorkPackageView,
} from './project.actions';
import { ProjectService } from '../../api/services/project.service';
import { ProjectViewResponseModel } from '../../api/models/responses/project-view.response.model';
import { ProjectScopeViewResponseModel } from '../../api/models/responses/project-scope-view.response.model';
import { WorkPackageViewResponseModel } from '../../api/models/responses/work-package-view.response.model';
import { LevelListNestedDtoModel } from '../../api/models/dtos/level-list-nested.dto.model';

const defaultProjectState: ProjectStateModel = {
  projectView: null,
  projectStructure: [],
  projectScopeView: null,
  projectActivityView: null,
  workPackageView: null,
};

@State<ProjectStateModel>({
  name: 'project',
  defaults: defaultProjectState,
})
@Injectable({
  providedIn: 'root',
})
export class ProjectState {
  constructor(
    private projectService: ProjectService,
  ) {
  }

  @Action(ClearViews)
  clearViews({ patchState }: StateContext<ProjectStateModel>): void {
    patchState({ ...defaultProjectState });
  }

  @Action(SetProjectView)
  setProjectView({ patchState }: StateContext<ProjectStateModel>, { view }: SetProjectView): void {
    patchState({ projectView: view });
  }

  @Action(SetProjectScopeView)
  setProjectScopeView({ patchState }: StateContext<ProjectStateModel>, { view }: SetProjectScopeView): void {
    patchState({ projectScopeView: view });
  }

  @Action(SetProjectActivityView)
  setProjectActivityView(
    { patchState }: StateContext<ProjectStateModel>,
    { view }: SetProjectActivityView,
  ): void {
    patchState({ projectActivityView: view });
  }

  @Action(SetWorkPackageView)
  setWorkPackageView({ patchState }: StateContext<ProjectStateModel>, { view }: SetWorkPackageView): void {
    patchState({ workPackageView: view });
  }

  @Action(RefreshAllProjectViews)
  refreshAllProjectViews$(
    ctx: StateContext<ProjectStateModel>,
    { projectId }: RefreshAllProjectViews,
  ): Observable<ProjectStateModel> {
    return forkJoin([
      this.projectService.getProjectView$(projectId),
      this.projectService.getProjectScopeView$(projectId),
      // this.projectService.getProjectActivityView(projectId),
    ])
      .pipe(
        tap(([projectView, projectScopeView]: [ProjectViewResponseModel, ProjectScopeViewResponseModel]) => {
          ctx.patchState({
            projectView,
            projectScopeView,
          });
        }),
        map(() => ctx.getState()),
      );
  }

  @Action(RefreshProjectView)
  refreshProjectView$(
    ctx: StateContext<ProjectStateModel>,
    { projectId }: RefreshProjectView,
  ): Observable<ProjectStateModel> {
    return this.projectService
      .getProjectView$(projectId)
      .pipe(
        tap((projectView) => {
          ctx.patchState({ projectView });
        }),
        map(() => ctx.getState()),
      );
  }

  @Action(RefreshProjectStructure)
  refreshProjectStructure$(
    ctx: StateContext<ProjectStateModel>,
    { projectId }: RefreshProjectStructure,
  ): Observable<ProjectStateModel> {
    return this.projectService
      .getProjectStructure$(projectId)
      .pipe(
        tap((projectStructure) => {
          ctx.patchState({
            projectStructure: projectStructure.items as LevelListNestedDtoModel[],
          });
        }),
        map(() => ctx.getState()),
      );
  }

  @Action(RefreshWorkPackageView)
  refreshWorkPackageView$(
    ctx: StateContext<ProjectStateModel>,
    { projectId, workPackageId }: RefreshWorkPackageView,
  ): Observable<ProjectStateModel> {
    return forkJoin([
      // this.projectService.getProjectView(projectId),
      this.projectService.getWorkPackageView$(projectId, workPackageId),
    ])
      .pipe(
        tap(([workPackageView]: [/* ProjectViewResponseModel, */ WorkPackageViewResponseModel]) => {
          ctx.patchState({ // projectView,
            workPackageView,
          });
        }),
        map(() => ctx.getState()),
      );
  }
}
