import { MetadataModel } from '../metadata.model';
import { UserListResponseMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitPlanningUserListViewResponse } from '../../interfaces/responses/unit-planning-user-list-view.response';
import { UnitPlanningResourceDtoModel } from '../dtos/unit-planning-resource.dto.model';

export class UnitPlanningUserListViewResponseModel {
  constructor(
    readonly items: readonly UnitPlanningResourceDtoModel[],
    readonly metadata: MetadataModel<UserListResponseMetadata> = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: UnitPlanningUserListViewResponse): UnitPlanningUserListViewResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new UnitPlanningUserListViewResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => UnitPlanningResourceDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): UnitPlanningUserListViewResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
