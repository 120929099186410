import { ProjectPlanningTotalsStateModel } from './project-planning-totals-state.model';

/**
 * Action types for ProjectPlanningTotalsState
 */
export enum ProjectPlanningTotalsActionTypes {
  InitProjectPlanningTotalsSettings = '[Project Planning API] Initialize Project Planning Totals Settings',
  SetProjectPlanningTotalsSettings = '[Project Planning API] Set Project Planning Totals Settings',
  ResetProjectPlanningTotalsSettings = '[Project Planning API] Reset Project Planning Totals Settings',
  SaveProjectPlanningTotalsSettings = '[Project Planning API] Save Project Planning Totals Settings',
}

export class InitProjectPlanningTotalsSettings {
  static readonly type: string = ProjectPlanningTotalsActionTypes.InitProjectPlanningTotalsSettings;

  constructor(public settings: ProjectPlanningTotalsStateModel) {}
}

export class SetProjectPlanningTotalsSettings {
  static readonly type: string = ProjectPlanningTotalsActionTypes.SetProjectPlanningTotalsSettings;

  constructor(public settings: ProjectPlanningTotalsStateModel) {}
}

export class ResetProjectPlanningTotalsSettings {
  static readonly type: string = ProjectPlanningTotalsActionTypes.ResetProjectPlanningTotalsSettings;
}

export class SaveProjectPlanningTotalsSettings {
  static readonly type: string = ProjectPlanningTotalsActionTypes.SaveProjectPlanningTotalsSettings;

  constructor(public settings: ProjectPlanningTotalsStateModel) {}
}
