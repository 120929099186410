import {
  Injectable, Injector, OnDestroy, Optional,
} from '@angular/core';
import {
  NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,
} from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import * as Common from '@microsoft/applicationinsights-common';
import { ApplicationInsights, DistributedTracingModes, Snippet } from '@microsoft/applicationinsights-web';
import {
  ICustomProperties, ITelemetryItem, ITelemetryPlugin, IChannelControls,
} from '@microsoft/applicationinsights-core-js';

type AppInsightsConfig = Snippet['config'];

/* eslint-disable no-console */

export class AngularApplicationInsightsConfig implements AppInsightsConfig {
  disableAngularRouteTracking?: boolean;

  instrumentationKey?: string;

  connectionString?: string;

  diagnosticLogInterval?: number;

  maxMessageLimit?: number;

  loggingLevelConsole?: number;

  loggingLevelTelemetry?: number;

  enableDebugExceptions?: boolean;

  endpointUrl?: string;

  extensionConfig?: Record<string, any>;

  extensions?: ITelemetryPlugin[];

  channels?: IChannelControls[][];

  emitLineDelimitedJson?: boolean;

  accountId?: string;

  sessionRenewalMs?: number;

  sessionExpirationMs?: number;

  maxBatchSizeInBytes?: number;

  maxBatchInterval?: number;

  enableDebug?: boolean;

  disableExceptionTracking?: boolean;

  disableTelemetry?: boolean;

  samplingPercentage?: number;

  autoTrackPageVisitTime?: boolean;

  enableAutoRouteTracking?: boolean;

  disableAjaxTracking?: boolean;

  disableFetchTracking?: boolean;

  overridePageViewDuration?: boolean;

  maxAjaxCallsPerView?: number;

  disableDataLossAnalysis?: boolean;

  disableCorrelationHeaders?: boolean;

  distributedTracingMode?: DistributedTracingModes;

  correlationHeaderExcludedDomains?: string[];

  disableFlushOnBeforeUnload?: boolean;

  disableFlushOnUnload?: boolean;

  enableSessionStorageBuffer?: boolean;

  isCookieUseDisabled?: boolean;

  cookieDomain?: string;

  isRetryDisabled?: boolean;

  url?: string;

  isStorageUseDisabled?: boolean;

  isBeaconApiDisabled?: boolean;

  sdkExtension?: string;

  isBrowserLinkTrackingEnabled?: boolean;

  appId?: string;

  enableCorsCorrelation?: boolean;

  namePrefix?: string;

  enableRequestHeaderTracking?: boolean;

  enableResponseHeaderTracking?: boolean;

  enableAjaxErrorStatusText?: boolean;

  onunloadDisableBeacon?: boolean;
}

type AppInsightsPublicInterface = Omit<Omit<Common.IAppInsights, '_onerror'>, 'getCookieMgr'>;

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService implements AppInsightsPublicInterface, OnDestroy {
  get context(): ApplicationInsights['context'] | undefined {
    return this.appInsights ? this.appInsights.context : undefined;
  }

  appInsights?: ApplicationInsights;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private _injector: Injector,
    @Optional() private config?: AngularApplicationInsightsConfig,
  ) {
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackEvent(event: Common.IEventTelemetry, customProperties?: ICustomProperties): void;

  /** @deprecated */
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  trackEvent(eventName: Common.IEventTelemetry['name'], customProperties?: ICustomProperties): void;

  /**
   * Log a user action or other occurrence.
   */
  trackEvent(event: Common.IEventTelemetry | Common.IEventTelemetry['name'], customProperties?: ICustomProperties): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackEvent(typeof event === 'string' ? { name: event } : event, customProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  trackPageView(pageView?: Common.IPageViewTelemetry): void;

  /** @deprecated */
  trackPageView(name?: Common.IPageViewTelemetry['name'], uri?: Common.IPageViewTelemetry['uri'], additionalTelemetry?: Omit<Common.IPageViewTelemetry, 'name' | 'uri'>): void;

  /**
   * Logs that a page, or similar container was displayed to the user.
   */
  trackPageView(pageView?: Common.IPageViewTelemetry | Common.IPageViewTelemetry['name'], uri?: Common.IPageViewTelemetry['uri'], additionalTelemetry?: Omit<Common.IPageViewTelemetry, 'name' | 'uri'>): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackPageView(typeof pageView === 'string' || uri != null || additionalTelemetry != null ? {
          name: pageView as string,
          uri,
          ...additionalTelemetry,
        } : pageView);
      } catch (ex) {
        console.warn('Angular application insights Error [trackPageView]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a bag of performance information via the customProperties field.
   */
  trackPageViewPerformance(pageViewPerformance: Common.IPageViewPerformanceTelemetry): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackPageViewPerformance(pageViewPerformance);
      } catch (ex) {
        console.warn('Angular application insights Error [trackPageViewPerformance]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  trackException(exception: Common.IExceptionTelemetry): void;

  /** @deprecated */
  trackException(exception: Error, severityLevel?: Common.SeverityLevel | number, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'error' | 'exception' | 'severityLevel'>): void;

  /**
   * Log an exception that you have caught.
   */
  trackException(exception: Common.IExceptionTelemetry | Error, severityLevel?: Common.SeverityLevel | number, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'error' | 'exception' | 'severityLevel'>): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackException('name' in exception && 'message' in exception ? {
          exception,
          severityLevel,
          ...additionalTelemetry,
        } : exception);
      } catch (ex) {
        console.warn('Angular application insights Error [trackException]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  trackTrace(message: string, securityLevel?: Common.SeverityLevel, additionalTelemetry?: Omit<Common.ITraceTelemetry, 'message' | 'severityLevel'>): void;

  /** @deprecated */
  trackTrace(trace: Common.ITraceTelemetry, customProperties?: ICustomProperties): void;

  /**
   * Log a diagnostic scenario such entering or leaving a function.
   */
  trackTrace(trace: Common.ITraceTelemetry | string, customProperties?: ICustomProperties | Common.SeverityLevel, additionalTelemetry?: Omit<Common.IExceptionTelemetry, 'message' | 'severityLevel'>): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackTrace(typeof trace === 'string' ? {
          message: trace,
          severityLevel: (typeof customProperties === 'number' ? customProperties : undefined),
          ...additionalTelemetry,
        } : trace, typeof trace === 'string' ? undefined : customProperties as ICustomProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackTrace]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a numeric value that is not associated with a specific event. Typically used
   * to send regular reports of performance indicators.
   *
   * To send a single measurement, just use the `name` and `average` fields
   * of {@param metric}.
   *
   * If you take measurements frequently, you can reduce the telemetry bandwidth by
   * aggregating multiple measurements and sending the resulting average and modifying
   * the `sampleCount` field of {@param metric}.
   */
  trackMetric(metric: Common.IMetricTelemetry, customProperties?: ICustomProperties): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackMetric(metric, customProperties);
      } catch (ex) {
        console.warn('Angular application insights Error [trackMetric]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log a dependency call (e.g. ajax)
   */
  trackDependencyData(dependency: Common.IDependencyTelemetry): void {
    if (this.appInsights) {
      try {
        this.appInsights.trackDependencyData(dependency);
      } catch (ex) {
        console.warn('Angular application insights Error [trackDependencyData]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Starts the timer for tracking a page load time. Use this instead of `trackPageView` if you want to
   * control when the page view timer starts and stops, but don't want to calculate the duration yourself.
   * This method doesn't send any telemetry. Call `stopTrackPage` to log the end of the page view and send the event.
   * @param name A string that identifies this item, unique within this HTML document. Defaults to the document title.
   */
  startTrackPage(name?: string): void {
    if (this.appInsights) {
      try {
        this.appInsights.startTrackPage(name);
      } catch (ex) {
        console.warn('Angular application insights Error [startTrackPage]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Stops the timer that was started by calling `startTrackPage` and sends the pageview load time telemetry with
   * the specified properties and measurements.
   * The duration of the page view will be the time between calling `startTrackPage` and `stopTrackPage`.
   */
  stopTrackPage(
    name?: string,
    url?: string,
    customProperties?: Record<string, any>,
    measurements?: Record<string, number>,
  ): void {
    if (this.appInsights) {
      try {
        this.appInsights.stopTrackPage(name, url, customProperties, measurements);
      } catch (ex) {
        console.warn('Angular application insights Error [stopTrackPage]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
   * @param   name    A string that identifies this item, unique within this HTML document.
   */
  startTrackEvent(name?: string): void {
    if (this.appInsights) {
      try {
        this.appInsights.startTrackEvent(name);
      } catch (ex) {
        console.warn('Angular application insights Error [startTrackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Log an extended event that you started timing with `startTrackEvent`.
   */
  stopTrackEvent(
    name: string,
    properties?: Record<string, string>,
    measurements?: Record<string, number>,
  ): void {
    if (this.appInsights) {
      try {
        this.appInsights.stopTrackEvent(name, properties, measurements);
      } catch (ex) {
        console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Set the authenticated user id and the account id. Used for identifying a specific signed-in user. Parameters
   * must not contain whitespace or ,;=|
   *
   * The method will only set the `authenticatedUserId` and `accountId` in the current page view. To set them for
   * the whole session, you should set `storeInCookie = true`
   */
  setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
    if (this.appInsights) {
      try {
        this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
      } catch (ex) {
        console.warn('Angular application insights Error [setAuthenticatedUserContext]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Clears the authenticated user id and account id. The associated cookie is cleared, if present.
   */
  clearAuthenticatedUserContext(): void {
    if (this.appInsights) {
      try {
        this.appInsights.clearAuthenticatedUserContext();
      } catch (ex) {
        console.warn('Angular application insights Error [clearAuthenticatedUserContext]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  /**
   * Manually trigger an immediate send of all telemetry still in the buffer.
   * @param {boolean} [async=true]
   */
  flush(async?: boolean): void {
    if (this.appInsights) {
      try {
        this.appInsights.flush(async);
      } catch (ex) {
        console.warn('Angular application insights Error [flush]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  addTelemetryInitializer(telemetryInitializer: (item: ITelemetryItem) => boolean | void): void {
    if (this.appInsights) {
      try {
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
      } catch (ex) {
        console.warn('Angular application insights Error [addTelemetryInitializer]: ', ex);
      }
    } else {
      console.warn('Application insights is not available. Telemetry will not be sent.');
    }
  }

  init(): void {
    if (this.config != null) {
      if (this.config.instrumentationKey) {
        try {
          const appInsights = new ApplicationInsights({
            config: this.config,
          });
          appInsights.loadAppInsights();
          this.appInsights = appInsights;

          const router = this.getRouter();

          // eslint-disable-next-line max-depth
          if (this.config.disableAngularRouteTracking || !router) {
            return;
          }

          router.events
            .pipe(
              filter((event) => event instanceof NavigationStart),
              takeUntil(this.destroyed$),
            )
            .subscribe((event: NavigationStart) => {
              this.startTrackPage(event.url);
            });

          router.events
            .pipe(
              filter((event) => (
                event instanceof NavigationEnd
                || event instanceof NavigationCancel
                || event instanceof NavigationError
              )),
              takeUntil(this.destroyed$),
            )
            .subscribe((event: NavigationEnd) => {
              this.stopTrackPage(event.url);
            });
        } catch (ex) {
          console.warn('Angular application insights Error [loadAppInsights]: ', ex);
        }
      } else {
        console.warn('An instrumentationKey value is required to initialize AppInsightsService');
      }
    } else {
      console.warn('You need forRoot on ApplicationInsightsModule, with instrumentationKey set at least');
    }
  }

  private getRouter(): Router | undefined {
    try {
      return this._injector.get(Router);
    } catch (ex) {
      // do nothing
    }

    return undefined;
  }
}

/* eslint-enable no-console */
