import { UserDtoModel } from '../../api/models/dtos/user.dto.model';

export class RefreshBaseData {
  static readonly type: string = '[App] Refresh Base Data';

  constructor(
    public user: UserDtoModel,
    public force: boolean = false,
  ) {}
}

export class ClearBaseData {
  static readonly type: string = '[App] Clear Base Data';
}

export class CreateUnit {
  static readonly type: string = '[App] Created Unit';

  constructor(public unitId: number) {}
}

export class UpdateUnit {
  static readonly type: string = '[App] Updated Unit';

  constructor(public unitId: number) {}
}

export class DisableUnit {
  static readonly type: string = '[App] Disabled Unit';

  constructor(public unitId: number) {}
}

export class CreateQuestion {
  static readonly type: string = '[App] Create Question';
}

export class UpdateQuestion {
  static readonly type: string = '[App] Update Question';
}

export class CreateConclusion {
  static readonly type: string = '[App] Create Conclusion';
}

export class CreateReopen {
  static readonly type: string = '[App] Create Reopen';
}

export class CreateReply {
  static readonly type: string = '[App] Create Reply';
}

/**
 * Currently only used for dispatching events. No data is persisted in the store.
 */
export class CreateUser {
  static readonly type: string = '[App] Create User';

  // constructor(public user: UserListDtoModel) {}
}

/**
 * Currently only used for dispatching events. No data is persisted in the store.
 */
export class UpdateUser {
  static readonly type: string = '[App] Update User';

  // constructor(public user: UserListDtoModel) {}
}

/**
 * Currently only used for dispatching events. No data is persisted in the store.
 */
export class CreateNonHumanResource {
  static readonly type: string = '[App] Create Non-Human Resource';

  // constructor(public user: NonHumanResourceListDtoModel) {}
}

/**
 * Currently only used for dispatching events. No data is persisted in the store.
 */
export class UpdateNonHumanResource {
  static readonly type: string = '[App] Update Non-Human Resource';

  // constructor(public user: NonHumanResourceListDtoModel) {}
}

export class MarkImpersonation {
  static readonly type: string = '[App] Mark impersonation';

  constructor(public state: boolean) {}
}
