import { ProjectActivityViewResponse } from '../../interfaces/responses/project-activity-view.response';
import { ActivityDtoModel } from '../dtos/activity.dto.model';
import { ProjectActivityListResponseMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectActivityViewResponseModel {
  constructor(
    readonly projectId: number,
    readonly activities: readonly ActivityDtoModel[],
    readonly metadata: MetadataModel<ProjectActivityListResponseMetadata>,
  ) {
    this.activities = [...activities];
  }

  static fromJSON(json: ProjectActivityViewResponse): ProjectActivityViewResponseModel {
    assertRequiredProperties(json, [
      'projectId',
    ]);

    return new ProjectActivityViewResponseModel(
      json.projectId,
      (Array.isArray(json.activities) ? json.activities : [])
        .map((activity) => ActivityDtoModel.fromJSON(activity)),
      new MetadataModel(json.metadata),
    );
  }
}
