import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class CustomHttpErrorResponse extends HttpErrorResponse {
  name: any;

  message: string;

  constructor(name: string, message: string, init: {
    error?: any;
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
  }) {
    super(init);

    this.name = name;
    this.message = message;
  }
}
