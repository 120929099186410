import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { forkJoin, Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDataStateModel } from './base-data-state.model';
import { BaseDataService } from '../../api/services/base-data.service';
import { CountryDtoModel } from '../../api/models/dtos/country.dto.model';
import { GateDtoModel } from '../../api/models/dtos/gate.dto.model';
import { ClearBaseData, MarkImpersonation, RefreshBaseData } from './base-data.actions';
import { ProjectTypeDtoModel } from '../../api/models/dtos/project-type.dto.model';
import { RoleListDtoModel } from '../../api/models/dtos/role-list.dto.model';
import { RatingDtoModel } from '../../api/models/dtos/rating.dto.model';
import { ContractModel } from '../../api/contract-model';
import { ContractModelType } from '../../models/contract-model-type.enum';
import { ResourceLinkDtoModel } from '../../api/models/dtos/resource-link.dto.model';

function countriesSortFn(a: CountryDtoModel, b: CountryDtoModel): number {
  return a.name.localeCompare(b.name);
}

// @TODO Move these to the API?
const baseContractModels: ContractModel[] = [
  {
    contractModelId: ContractModelType.NamedResources,
    name: 'Named Resources',
  },
  {
    contractModelId: ContractModelType.ProjectBasedOrder,
    name: 'Project-based Order',
  },
];

const defaultBaseDataState: BaseDataStateModel = {
  countries: [],
  gates: [],
  projectTypes: [],
  roles: [],
  ratings: [],
  links: [],
  contractModels: [...baseContractModels],
  eTag: '',
  hasImpersonatedUser: false,
};

@State<BaseDataStateModel>({
  name: 'baseData',
  defaults: defaultBaseDataState,
})
@Injectable({
  providedIn: 'root',
})
export class BaseDataState {
  constructor(
    private baseDataService: BaseDataService,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  @Action(RefreshBaseData)
  // eslint-disable-next-line complexity
  refreshBaseData$(
    ctx: StateContext<BaseDataStateModel>,
    { user, force }: RefreshBaseData,
  ): Observable<BaseDataStateModel> {
    const state = ctx.getState();

    const refresh = force || state.hasImpersonatedUser || state.eTag !== user.metadata?.fields.eTag_lookup;

    const requests = [
      refresh || state.countries.length === 0
        ? this.baseDataService.getAllCountries$()
        : from([state.countries]),
      refresh || state.gates.length === 0
        ? this.baseDataService.getAllGates$()
        : from([state.gates]),
      refresh || state.projectTypes.length === 0
        ? this.baseDataService.getAllProjectTypes$()
        : from([state.projectTypes]),
      refresh || state.roles.length === 0
        ? this.baseDataService.getAllRoles$()
        : from([state.roles.map((role) => RoleListDtoModel.fromJSON(role))]),
      refresh || state.ratings.length === 0
        ? this.baseDataService.getAllRatings$()
        : from([state.ratings]),
      refresh || state.links.length === 0
        ? this.baseDataService.getAllLinks$()
        : from([state.links]),
    ];

    return forkJoin(requests)
      .pipe(
        map(([
          countries,
          gates,
          projectTypes,
          roles,
          ratings,
          links,
        ]: [
          CountryDtoModel[],
          GateDtoModel[],
          ProjectTypeDtoModel[],
          RoleListDtoModel[],
          RatingDtoModel[],
          ResourceLinkDtoModel[]
        ]) => ({
          countries: [...countries].sort(countriesSortFn),
          gates,
          projectTypes,
          roles,
          ratings,
          links,
        })),
        map((result) => {
          ctx.patchState({
            ...result,
            eTag: user.metadata.fields.eTag_lookup,
          });

          return ctx.getState();
        }),
      );
  }

  @Action(ClearBaseData)
  clearBaseData(ctx: StateContext<BaseDataStateModel>): void {
    ctx.setState(defaultBaseDataState);
  }

  @Action(MarkImpersonation)
  markImpersonation(ctx: StateContext<BaseDataStateModel>, { state }: MarkImpersonation): void {
    ctx.patchState({
      hasImpersonatedUser: state,
    });
  }
}
