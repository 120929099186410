import { UnitCreateResponse } from '../../interfaces/responses/unit-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitCreateResponseModel {
  constructor(
    readonly unitId: number,
  ) {
  }

  static fromJSON(json: UnitCreateResponse): UnitCreateResponseModel {
    assertRequiredProperties(json, [
      'unitId',
    ]);

    return new UnitCreateResponseModel(
      json.unitId,
    );
  }

  toJSON(): UnitCreateResponse {
    return {
      unitId: this.unitId,
    };
  }
}
