import { CollappFilterDropdownListRadioModel } from '../../../models/collapp-filter-dropdown-list-radio.interface';
import { PlanningResource, PlanningViewResponseModel } from '../../../modules/user/planning2/planning-resource.interface';
import { MetadataModel } from '../metadata.model';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CapacityDtoModel } from '../dtos/capacity.dto.model';
import { UserPlanningTimeTrackingRowDtoModel } from '../dtos/user-planning-time-tracking-row.dto.model';
import { UserPlanningTimeTrackingProjectDtoModel } from '../dtos/user-planning-time-tracking-project.dto.model';
import { NonHumanResourcePlanningViewResponse } from '../../interfaces/responses/non-human-resource-planning-view.response';
import { NonHumanResourceDtoModel } from '../dtos/non-human-resource.dto.model';
import { UnitBackgroundColorDtoModel } from '../dtos/unit-background-color.dto.model';

export class NonHumanResourcePlanningViewResponseModel implements PlanningViewResponseModel {
  /**
   * Flag which indicates whether the user is privileged to update non productive weeks.
   */
  readonly canUpdatePlannedNonProductiveWeeks: boolean;

  /**
   * Flag which indicates whether the user is privileged to update planned project weeks.
   */
  readonly canUpdatePlannedProjectWeeks: boolean;

  constructor(
    readonly nonProductiveCategories: readonly UserPlanningTimeTrackingRowDtoModel[] = [],
    readonly unit: UnitSlimDtoModel | null,
    readonly nonHumanResource: NonHumanResourceDtoModel,
    readonly projects: readonly UserPlanningTimeTrackingProjectDtoModel[] = [],
    readonly capacities: CapacityDtoModel[],
    readonly unitBackgroundColors: UnitBackgroundColorDtoModel[] = [],
    readonly metadata: MetadataModel,
    public headerFilters: CollappFilterDropdownListRadioModel[],
  ) {
    this.nonProductiveCategories = [...nonProductiveCategories];
    this.projects = [...projects];
    this.capacities = [...capacities];
    this.unitBackgroundColors = unitBackgroundColors.map((item) => item.clone());

    this.canUpdatePlannedNonProductiveWeeks = (this.metadata.fields
      && this.metadata.fields.canUpdatePlannedNonProductiveWeeks) || false;

    this.canUpdatePlannedProjectWeeks = (this.metadata.fields
      && this.metadata.fields.canUpdatePlannedProjectWeeks) || false;
  }

  get resource(): PlanningResource {
    return this.nonHumanResource;
  }

  static fromJSON(json: NonHumanResourcePlanningViewResponse): NonHumanResourcePlanningViewResponseModel {
    assertRequiredProperties(json, [
      'nonHumanResource',
    ]);

    return new NonHumanResourcePlanningViewResponseModel(
      (Array.isArray(json.nonProductiveCategories)
        ? json.nonProductiveCategories
          .map((item) => UserPlanningTimeTrackingRowDtoModel.fromJSON(item))
        : []
      ),
      json.unit ? UnitSlimDtoModel.fromJSON(json.unit) : null,
      NonHumanResourceDtoModel.fromJSON(json.nonHumanResource),
      (Array.isArray(json.projects)
        ? json.projects
          .map((item) => UserPlanningTimeTrackingProjectDtoModel.fromJSON(item))
        : []
      ),
      (Array.isArray(json.capacities)
        ? json.capacities
          .map((item) => CapacityDtoModel.fromJSON(item))
        : []
      ),
      (Array.isArray(json.unitBackgroundColors)
        ? json.unitBackgroundColors
          .map((item) => UnitBackgroundColorDtoModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata),
      json.headerFilters,
    );
  }

  clone(): NonHumanResourcePlanningViewResponseModel {
    return new NonHumanResourcePlanningViewResponseModel(
      this.nonProductiveCategories.map((item) => item.clone()),
      this.unit ? this.unit.clone() : null,
      this.nonHumanResource.clone(),
      this.projects.map((item) => item.clone()),
      this.capacities.map((item) => item.clone()),
      this.unitBackgroundColors.map((item) => item.clone()),
      this.metadata.clone(),
      this.headerFilters.map((item) => JSON.parse(JSON.stringify(item))),
    );
  }
}
