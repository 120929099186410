import { SkillRatingCategoryDtoModel } from '../dtos/skill-rating-category.dto.model';
import { UserBaseSlimDtoModel } from '../dtos/user-base-slim.dto.model';
import { SkillRatingResponse } from '../../interfaces/responses/skill-rating.response';

export class SkillRatingResponseModel {
  constructor(
    readonly categories: SkillRatingCategoryDtoModel[],
    readonly users: UserBaseSlimDtoModel[],
  ) {
    this.categories = categories.map((category) => category.clone());
    this.users = users.map((user) => user.clone());
  }

  static fromJSON(json: SkillRatingResponse): SkillRatingResponseModel {
    return new SkillRatingResponseModel(
      json.categories.map(SkillRatingCategoryDtoModel.fromJSON),
      json.users.map(UserBaseSlimDtoModel.fromJSON),
    );
  }

  toJSON(): SkillRatingResponse {
    return {
      categories: this.categories.map((category) => category.clone()),
      users: this.users.map((user) => user.clone()),
    };
  }
}
