<div class="person-icon__wrap" [matBadge]="badge" [matBadgeSize]="badge ? 'small' : undefined">
  <div class="person-icon__inner" [class.person-icon__inner--rect]="isRect" [style.background-color]="backgroundColor">
    @if (avatarUnavailable && !unknown && initials) {
      <svg class="person-icon__svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
        <text x="50%" y="50%" dy=".35em">{{initials}}</text>
      </svg>
    }
    @if (avatarUnavailable && (unknown || !initials)) {
      <svg class="person-icon__svg" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
        <path fill="#000" fill-opacity=".33" d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 0 1-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 0 1-6 3.22z"/>
      </svg>
    }
    @if (userImageSrc) {
      <img
        class="person-icon__image"
        [class.person-icon__image--hidden]="avatarUnavailable || unknown"
        [src]="userImageSrc | safe: 'resourceUrl'"
        (error)="onAvatarError()"
        />
    }
  </div>
  @if (overlayTemplate) {
    <div class="person-icon__overlay">
      <ng-template [ngTemplateOutlet]="overlayTemplate"></ng-template>
    </div>
  }
  @if (hat !== hatTypes.NONE) {
    <div class="person-icon__overlay">
      @switch (hat) {
        @case (hatTypes.SANTA) {
          <svg class="person-icon__svg person-icon__svg--cap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
            <path fill="#000" d="M70.4 119.877a12.899 12.899 0 0 1 3.2-6.794c1.403-1.428 4.455-1.452 5.465-2.486.812-.886.664-4.382.837-5.662.418-2.954.984-5.907 1.55-8.837 4.013-20.258 11.471-35.815 33.92-38.917 10.166-1.427 18.339-.467 26.29 6.474a66.51 66.51 0 0 1 9.747 11.077 13.612 13.612 0 0 0 3.89 4.037c1.821 1.157 2.756 1.157 3.79 3.126a23.04 23.04 0 0 1 2.806 9.65c0 1.846-.295 7.95-2.658 8.147-.739.05-3.939-3.84-4.874-4.554a30.227 30.227 0 0 0-5.908-3.667 24.812 24.812 0 0 0-12.947-2.117c-9.034.935-17.133 6.326-23.631 12.307a79.532 79.532 0 0 0-10.782 12.136c-2.24 3.126-2.609 7.754-6.892 6.646.172 8.96-4.628 13.44-12.308 14.966-9.403 0-13.243-6.646-11.495-15.508v-.024z"/>
            <path fill="#E32313" d="M119.557 68.997c-1.625 1.33-9.674 6.646-9.108 9.083 1.231.123 4.899-2.659 5.982-3.126a13.637 13.637 0 0 1 6.744-1.723c-6.203 2.56-17.329 6.941-18.51 14.67a91.47 91.47 0 0 1 23.975-10.166 56.074 56.074 0 0 1 12.012-1.772 40.665 40.665 0 0 1 5.465.172c1.083.123 4.677 1.379 5.588.985-8.739-11.446-21.563-22.055-36.603-18.462-7.385 1.723-14.474 3.471-19.693 9.403a51.274 51.274 0 0 0-9.6 19.816 145.183 145.183 0 0 0-2.855 11.692c-.394 1.896-2.83 8.936-1.87 10.536.344.59 3.248.59 3.716.738 1.157.37 2.068 1.526 3.2 1.822 2.88.763.763.935 2.462-1.108a24.32 24.32 0 0 0 2.215-5.416 169.107 169.107 0 0 1 10.61-20.996c3.396-5.613 8.96-15.262 16.27-16.148z"/>
            <path fill="#FFF" d="M91.57 114.166c1.476-1.428 15.31-16.886 14.768-17.526-.246-.246-8.443 9.354-9.649 8.123-.738-.738 2.88-7.286 3.274-8.123 1.575-3.397 2.29-5.563 5.219-7.63 5.661-3.94 13.612-6.573 20.061-8.985 5.563-2.093 34.215-6.351 32.665 4.824-5.957-1.821-14.425-3.224-20.382-.615 8.37-.419 30.277-.862 21.612 13.144-2.806-1.526-4.873-4.184-7.63-5.809a25.059 25.059 0 0 0-9.674-3.224 34.93 34.93 0 0 0-22.868 5.292 66.29 66.29 0 0 0-16.615 16.123c-1.576 2.092-4.382 8.59-6.794 9.575-2.019.813-4.874-4.307-3.988-5.169z"/>
            <path fill="#FFF" d="M81.723 111.803c14.154 0 14.105 22.055-.074 22.055-14.227 0-14.178-22.08.074-22.055z"/>
          </svg>
        }
        @case (hatTypes.BUNNY) {
          <svg class="person-icon__svg person-icon__svg--cap" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="256" height="256">
            <path fill="#000" d="M139.328 90.208c-.298 0-4.821-.74-7.521-1.27-.605-.118-1.212-.304-1.823-.93-.908-.933-.738-3.09.729-9.232.143-.601.275-1.12.309-1.352 2.196-14.982 5.654-25.831 9.777-36.626 3.561-9.322 8.242-19.894 15.968-27.628 4.459-4.464 8.375-6.728 11.641-6.728.436 0 .865.042 1.275.124 5.829 1.172 6.198 10.228 6.375 14.58l.007.178c.548 13.364-3.357 26.84-10.713 36.975-2.498 3.44-5.18 6.82-7.772 10.087-1.719 2.165-3.496 4.405-5.198 6.635a655.352 655.352 0 0 0-6.133 8.208c-.344.466-.727 1.08-1.133 1.73-1.459 2.338-3.275 5.249-5.788 5.249z"/>
            <path fill="#000" d="M86.785 95.312a2.307 2.307 0 0 1-1.493-.544c-1.257-1.048-1.92-3.215-2.452-4.957-.122-.398-.237-.774-.341-1.073-.894-2.558-1.781-5.01-2.175-7.837-.691-4.96-2.188-10.112-1.844-15.423.636-9.8 5.86-16.248 13.974-17.248 3.295-.407 6.798-.803 10.226-.803 1.855 0 3.5.114 5.032.348 4.856.743 7.977 2.794 9.858 6.954 5 11.053 8.492 22.116 10.932 32.311l.255 1.069s-10.289 2.165-10.728 2.165c-1.185 0-1.907-.635-2.798-1.532-2.02-2.033-3.729-3.732-5.496-5.411-2.803-2.664-5.692-5.41-8.88-7.584-.976 2.088-2.986 6.14-5.262 10.008-5.042 8.566-7.442 9.557-8.808 9.557z"/>
            <path fill="#FF80E1" d="M138.575 89.347l-6.827-1.237 9.565-41.778 17.883-31.174 13.775 4.712-6.284 27.67s-28.067 41.442-28.112 41.807zM119.524 89.18l-18.809-20.495 10.391-15.587 9.425 16.916 7.249 17.561z"/>
            <path fill="#FFF" d="M138.575 89.347c.58-4.697 5.656-10.995 8.176-14.921 3.479-5.419 7.134-10.727 10.413-16.272 5.631-9.519 12.566-21.852 11.534-33.304-.467-5.177-3.526-8.29-8.407-4.876-4.33 3.028-7.251 8.4-9.671 12.947-5.669 10.653-9.958 21.956-13.088 33.597-1.535 5.706-3.204 11.497-4.102 17.34-.13.843-.333 6.35-2.71 3.432-.894-1.098 1.08-8.387 1.313-9.684 2.232-12.435 4.336-24.993 9.726-36.441 4.508-9.575 8.294-19.82 15.735-27.27 2.658-2.661 7.769-6.751 11.973-5.906 5.113 1.027 5.411 9.47 5.571 13.378.524 12.772-3.622 26.06-10.518 36.328-3.928 5.85-8.01 10.022-12.284 15.622-2.075 2.72-4.781 6.55-6.813 9.301-1.685 2.281-4.01 7.53-6.848 6.729zM119.524 89.18c-1.701.508-2.254.042-3.45-1.162a270.812 270.812 0 0 0-5.518-5.432c-3.119-2.965-6.372-6.051-10.027-8.356-1.2 2.799-10.774 22.406-14.374 19.406-1.29-1.074-2.039-3.708-2.573-5.237-.881-2.523-1.526-5.138-2.145-7.736-1.174-4.926-2.148-10.031-1.818-15.118.528-8.128 4.482-14.682 13.075-16.295 4.726-.888 10.204-1.19 14.976-.46 4.526.693 6.903 2.506 8.785 6.658 4.618 10.187 8.722 21.246 11.325 32.127-1.019.172-2.031.29-2.78-.405-.726-.676-1.024-3.854-1.356-4.88a105.306 105.306 0 0 0-3.493-9.2c-2.598-6.024-5.721-11.723-8.966-17.414-2.637 2.899-4.47 6.825-6.477 10.19-.538.902-1.612 2.076-1.359 3.19.214.942 2.029 2.26 2.65 2.929 2.6 2.795 5.267 5.539 7.682 8.5 1.21 1.483 2.346 3.023 3.476 4.568.853 1.167 2.378 2.667 2.367 4.127z"/>
            <path fill="#000" d="M110.562 54.97c.346-.524 1.364-.4.623.706-2.117 3.154-5.746 8.575-7.486 12.284-1.504 3.204-2.272 7.105-2.773 7.461-.612.435-.484-.891-.397-1.191 1.374-4.728 1.93-6.31 4.475-10.68 1.082-1.857 1.82-2.87 3.685-5.89.753-1.22 1.058-1.45 1.873-2.69z"/>
          </svg>
        }
      }
    </div>
  }
</div>

