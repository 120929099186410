import { UserCalendarLinksResponse } from '../../interfaces/responses/user-calendar-links-response';
import { UnitCalendarLinkDtoModel } from '../dtos/unit-calendar-link.dto.model';

export class UserCalendarLinksResponseModel {
  constructor(
    readonly calendarAllLink: string | null,
    readonly calendarProductiveLink: string | null,
    readonly calendarNonProductiveLink: string | null,
    readonly calendarUnitProductiveLinks: UnitCalendarLinkDtoModel[] | null,
    readonly calendarUnitNonProductiveLinks: UnitCalendarLinkDtoModel[] | null,
  ) {}

  static fromJSON(json: UserCalendarLinksResponse): UserCalendarLinksResponseModel {
    return new UserCalendarLinksResponseModel(
      json.calendarAllLink,
      json.calendarProductiveLink,
      json.calendarNonProductiveLink,
      json.calendarUnitProductiveLinks
        ? json.calendarUnitProductiveLinks.map((link) => UnitCalendarLinkDtoModel.fromJSON(link))
        : null,
      json.calendarUnitNonProductiveLinks
        ? json.calendarUnitNonProductiveLinks.map((link) => UnitCalendarLinkDtoModel.fromJSON(link))
        : null,
    );
  }

  toJSON(): UserCalendarLinksResponse {
    return {
      calendarAllLink: this.calendarAllLink,
      calendarProductiveLink: this.calendarProductiveLink,
      calendarNonProductiveLink: this.calendarNonProductiveLink,
      calendarUnitProductiveLinks: this.calendarUnitProductiveLinks
        ? this.calendarUnitProductiveLinks.map((link) => link.toJSON())
        : null,
      calendarUnitNonProductiveLinks: this.calendarUnitNonProductiveLinks
        ? this.calendarUnitNonProductiveLinks.map((link) => link.toJSON())
        : null,
    };
  }
}
