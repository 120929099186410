/**
 * Action types for settings.
 */
export enum SettingsActionTypes {
  SetTestString = '[Settings] Set Test String',
}

export class SetTestString {
  static readonly type: string = SettingsActionTypes.SetTestString;

  constructor(public test: string) {}
}
