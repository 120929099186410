import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceCreateResponse } from '../../interfaces/responses/non-human-resource-create.response';

export class NonHumanResourceCreateResponseModel {
  constructor(
    readonly nonHumanResourceId: number,
  ) {
  }

  static fromJSON(json: NonHumanResourceCreateResponse): NonHumanResourceCreateResponseModel {
    assertRequiredProperties(json, [
      'nonHumanResourceId',
    ]);

    return new NonHumanResourceCreateResponseModel(
      json.nonHumanResourceId,
    );
  }

  toJSON(): NonHumanResourceCreateResponse {
    return {
      nonHumanResourceId: this.nonHumanResourceId,
    };
  }
}
