import { Inject, Injectable, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW } from '../collapp-common';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private zone: NgZone,
  ) {}

  /**
   * Copy the text value to the clipboard.
   *
   * @param {string} text
   */
  copy(text: string): Promise<void> {
    // For modern browsers use the Async Clipboard API.
    if (this.window.navigator.clipboard) {
      return this.window.navigator.clipboard.writeText(text);
    }

    return new Promise((resolve, reject) => {
      this.zone.runOutsideAngular(() => {
        let success: boolean = false;

        // Fallback to document.execCommand('copy').
        const textArea = this.createTextArea();

        try {
          this.document.body.appendChild(textArea);

          // Set the value
          textArea.value = text;

          // Select the value (creating an active Selection range).
          textArea.focus();
          textArea.select();
          textArea.setSelectionRange(0, textArea.value.length);

          // Ask the browser to copy the current selection to the clipboard.
          success = this.document.execCommand('copy');
          textArea.blur();
          const selection = this.window.getSelection();
          if (selection) {
            selection.removeAllRanges();
          }

          success = success && this.isCopySuccessInIE11();
        } catch (error) {
          // ignore
        }

        // Remove the textarea from the DOM.
        textArea?.parentNode?.removeChild(textArea);

        this.zone.run(() => {
          if (success) {
            resolve();
          } else {
            reject();
          }
        });
      });
    });
  }

  /**
   * Create a textarea off-screen to hold the contents to copy.
   */
  private createTextArea(): HTMLTextAreaElement {
    const isRTL = (this.document.documentElement != null && this.document.documentElement.getAttribute('dir') === 'rtl') || false;
    const textArea: HTMLTextAreaElement = this.document.createElement('textarea');

    // Prevent zooming on iOS
    textArea.style.fontSize = '12pt';

    // Reset box model
    textArea.style.border = '0';
    textArea.style.padding = '0';
    textArea.style.margin = '0';

    // Move element out of screen horizontally
    textArea.style.position = 'absolute';
    textArea.style[isRTL ? 'right' : 'left'] = '-9999px';

    // Move element to the same position vertically
    const yPosition = this.window.pageYOffset
      || (this.document.documentElement != null ? this.document.documentElement.scrollTop : 0);

    textArea.style.top = `${yPosition}px`;

    textArea.setAttribute('readonly', '');

    return textArea;
  }

  /**
   * This is for IE11 returning true even if copy fails.
   */
  private isCopySuccessInIE11(): boolean {
    const { clipboardData } = this.window as any;
    if (clipboardData && clipboardData.getData) {
      if (!clipboardData.getData('Text')) {
        return false;
      }
    }

    return true;
  }
}
