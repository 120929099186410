import { Selector } from '@ngxs/store';
import { UserListState } from './user-list.state';
import { UserListOptions } from './user-list.actions';
import { UserListStateModel } from './user-list-state.model';

export class UserListSelectors {
  @Selector([UserListState])
  static selectedColumns(state: UserListStateModel): UserListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([UserListState])
  static options(state: UserListStateModel): UserListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: { ...state.columnFilters },
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
