import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkPackagePlanningRangeStateModel } from './work-package-planning-range-state.model';
import {
  InitWorkPackagePlanningRangeSettings,
  ResetWorkPackagePlanningRangeSettings,
  SaveWorkPackagePlanningRangeSettings,
  SetWorkPackagePlanningRangeSettings,
} from './work-package-planning-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultWorkPackagePlanningRangeState: WorkPackagePlanningRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<WorkPackagePlanningRangeStateModel>({
  name: 'WorkPackagePlanningRange',
  defaults: defaultWorkPackagePlanningRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackagePlanningRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitWorkPackagePlanningRangeSettings)
  initWorkPackagePlanningRangeSettings(
    ctx: StateContext<WorkPackagePlanningRangeStateModel>,
    { settings }: InitWorkPackagePlanningRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetWorkPackagePlanningRangeSettings)
  setWorkPackagePlanningRangeSettings$(
    ctx: StateContext<WorkPackagePlanningRangeStateModel>,
    { settings }: SetWorkPackagePlanningRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveWorkPackagePlanningRangeSettings(ctx.getState()));
  }

  @Action(ResetWorkPackagePlanningRangeSettings)
  resetWorkPackagePlanningRangeSettings(ctx: StateContext<WorkPackagePlanningRangeStateModel>): void {
    ctx.setState(defaultWorkPackagePlanningRangeState);
  }

  @Action(SaveWorkPackagePlanningRangeSettings)
  saveWorkPackagePlanningRangeSettings$(
    ctx: StateContext<WorkPackagePlanningRangeStateModel>,
    { settings }: SaveWorkPackagePlanningRangeSettings,
  ): Observable<void> {
    return this.userService.saveWorkPackagePlanningRangeSettings$(settings);
  }
}
