import { UserPlanningRangeStateModel } from './user-planning-range-state.model';

/**
 * Action types for UserPlanningRangeState
 */
export enum UserPlanningRangeActionTypes {
  InitUserPlanningRangeSettings = '[User Planning API] Initialize User Planning Range Settings',
  SetUserPlanningRangeSettings = '[User Planning API] Set User Planning Range Settings',
  ResetUserPlanningRangeSettings = '[User Planning API] Reset User Planning Range Settings',
  SaveUserPlanningRangeSettings = '[User Planning API] Save User Planning Range Settings',
}

export class InitUserPlanningRangeSettings {
  static readonly type: string = UserPlanningRangeActionTypes.InitUserPlanningRangeSettings;

  constructor(public settings: UserPlanningRangeStateModel) {}
}

export class SetUserPlanningRangeSettings {
  static readonly type: string = UserPlanningRangeActionTypes.SetUserPlanningRangeSettings;

  constructor(public settings: UserPlanningRangeStateModel) {}
}

export class ResetUserPlanningRangeSettings {
  static readonly type: string = UserPlanningRangeActionTypes.ResetUserPlanningRangeSettings;
}

export class SaveUserPlanningRangeSettings {
  static readonly type: string = UserPlanningRangeActionTypes.SaveUserPlanningRangeSettings;

  constructor(public settings: UserPlanningRangeStateModel) {}
}
