import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { NonHumanResourceListStateModel } from './non-human-resource-list-state.model';
import {
  InitNonHumanResourceListSettings,
  ResetNonHumanResourceListOptions,
  ResetNonHumanResourceListSettings,
  SaveNonHumanResourceListSettings,
  SetNonHumanResourceListOptions,
  SetNonHumanResourceListSelectedColumns,
  SetNonHumanResourceListSettings,
} from './non-human-resource-list.actions';
import { nonHumanResourcesListColumnsInfo } from '../../../api/meta-info/non-human-resources-list.info';
import { UserService } from '../../../api/services/user.service';
import { UNIT_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultNonHumanResourceListState: NonHumanResourceListStateModel = {
  selectedColumns: Array.from(nonHumanResourcesListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: UNIT_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<NonHumanResourceListStateModel>({
  name: 'nonHumanResourceList',
  defaults: defaultNonHumanResourceListState,
})
@Injectable({
  providedIn: 'root',
})
export class NonHumanResourceListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetNonHumanResourceListSelectedColumns)
  setNonHumanResourceListSelectedColumns$(
    ctx: StateContext<NonHumanResourceListStateModel>,
    { columns }: SetNonHumanResourceListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, nonHumanResourcesListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourceListSettings(ctx.getState()));
  }

  @Action(SetNonHumanResourceListOptions)
  setNonHumanResourceListOptions$(
    ctx: StateContext<NonHumanResourceListStateModel>,
    { options }: SetNonHumanResourceListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, nonHumanResourcesListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourceListSettings(ctx.getState()));
  }

  @Action(ResetNonHumanResourceListOptions)
  resetNonHumanResourceListOptions$(ctx: StateContext<NonHumanResourceListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveNonHumanResourceListSettings(ctx.getState()));
  }

  @Action(InitNonHumanResourceListSettings)
  InitNonHumanResourceListSettings(
    ctx: StateContext<NonHumanResourceListStateModel>,
    { settings }: InitNonHumanResourceListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, nonHumanResourcesListColumnsInfo));
  }

  @Action(SetNonHumanResourceListSettings)
  setNonHumanResourceListSettings$(
    ctx: StateContext<NonHumanResourceListStateModel>,
    { settings }: SetNonHumanResourceListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, nonHumanResourcesListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourceListSettings(ctx.getState()));
  }

  @Action(ResetNonHumanResourceListSettings)
  resetNonHumanResourceListSettings(ctx: StateContext<NonHumanResourceListStateModel>): void {
    ctx.setState(defaultNonHumanResourceListState);
  }

  @Action(SaveNonHumanResourceListSettings)
  saveNonHumanResourceListSettings$(
    ctx: StateContext<NonHumanResourceListStateModel>,
    { settings }: SaveNonHumanResourceListSettings,
  ): Observable<void> {
    return this.userService.saveNonHumanResourceListSettings$(settings);
  }
}
