import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UnitListStateModel } from './unit-list-state.model';
import {
  InitUnitListSettings,
  ResetUnitListOptions,
  ResetUnitListSettings,
  SaveUnitListSettings,
  SetUnitListOptions,
  SetUnitListSelectedColumns,
  SetUnitListSettings,
} from './unit-list.actions';
import { unitsListColumnsInfo } from '../../../api/meta-info/units-list.info';
import { UserService } from '../../../api/services/user.service';
import { UNIT_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUnitListState: UnitListStateModel = {
  selectedColumns: Array.from(unitsListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: UNIT_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UnitListStateModel>({
  name: 'unitList',
  defaults: defaultUnitListState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetUnitListSelectedColumns)
  setUnitListSelectedColumns$(
    ctx: StateContext<UnitListStateModel>,
    { columns }: SetUnitListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, unitsListColumnsInfo));

    return ctx.dispatch(new SaveUnitListSettings(ctx.getState()));
  }

  @Action(SetUnitListOptions)
  setUnitListOptions$(ctx: StateContext<UnitListStateModel>, { options }: SetUnitListOptions): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, unitsListColumnsInfo));

    return ctx.dispatch(new SaveUnitListSettings(ctx.getState()));
  }

  @Action(ResetUnitListOptions)
  resetUnitListOptions$(ctx: StateContext<UnitListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveUnitListSettings(ctx.getState()));
  }

  @Action(InitUnitListSettings)
  InitUnitListSettings(ctx: StateContext<UnitListStateModel>, { settings }: InitUnitListSettings): void {
    ctx.patchState(this.cloneListSettings(settings, unitsListColumnsInfo));
  }

  @Action(SetUnitListSettings)
  setUnitListSettings$(
    ctx: StateContext<UnitListStateModel>,
    { settings }: SetUnitListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, unitsListColumnsInfo));

    return ctx.dispatch(new SaveUnitListSettings(ctx.getState()));
  }

  @Action(ResetUnitListSettings)
  resetUnitListSettings(ctx: StateContext<UnitListStateModel>): void {
    ctx.setState(defaultUnitListState);
  }

  @Action(SaveUnitListSettings)
  saveUnitListSettings$(
    ctx: StateContext<UnitListStateModel>,
    { settings }: SaveUnitListSettings,
  ): Observable<void> {
    return this.userService.saveUnitListSettings$(settings);
  }
}
