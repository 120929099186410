import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withFunc',
})
export class FunctionPipe implements PipeTransform {
  transform<T, R>(value: T, handler: (value: T) => R, context?: any): R {
    if (context) {
      return handler.call(context, value);
    }

    return handler(value);
  }
}
