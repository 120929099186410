import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { ProjectPlanningTotalsStateModel } from './project-planning-totals-state.model';
import {
  InitProjectPlanningTotalsSettings,
  ResetProjectPlanningTotalsSettings,
  SaveProjectPlanningTotalsSettings,
  SetProjectPlanningTotalsSettings,
} from './project-planning-totals.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultProjectPlanningTotalsState: ProjectPlanningTotalsStateModel = {
  selectedTotals: ['total-available-to-be-planned', 'confirmed', 'reported', 'total'],
  selectedCapacityUnit: CapacityUnits.HoursWeek,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<ProjectPlanningTotalsStateModel>({
  name: 'ProjectPlanningTotals',
  defaults: defaultProjectPlanningTotalsState,
})
@Injectable({
  providedIn: 'root',
})
export class ProjectPlanningTotalsState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitProjectPlanningTotalsSettings)
  initProjectPlanningTotalsSettings(
    ctx: StateContext<ProjectPlanningTotalsStateModel>,
    { settings }: InitProjectPlanningTotalsSettings,
  ): void {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
      selectedCapacityUnit: settings.selectedCapacityUnit,
    });
  }

  @Action(SetProjectPlanningTotalsSettings)
  setProjectPlanningTotalsSettings$(
    ctx: StateContext<ProjectPlanningTotalsStateModel>,
    { settings }: SetProjectPlanningTotalsSettings,
  ): Observable<void> {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
      selectedCapacityUnit: settings.selectedCapacityUnit,
    });

    return ctx.dispatch(new SaveProjectPlanningTotalsSettings(ctx.getState()));
  }

  @Action(ResetProjectPlanningTotalsSettings)
  resetProjectPlanningTotalsSettings(ctx: StateContext<ProjectPlanningTotalsStateModel>): void {
    ctx.setState(defaultProjectPlanningTotalsState);
  }

  @Action(SaveProjectPlanningTotalsSettings)
  saveProjectPlanningTotalsSettings$(
    ctx: StateContext<ProjectPlanningTotalsStateModel>,
    { settings }: SaveProjectPlanningTotalsSettings,
  ): Observable<void> {
    return this.userService.saveProjectPlanningTotalsSettings$(settings);
  }
}
