import moment from 'moment';
import { LevelInfoResponse } from '../../interfaces/responses/level-info.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelInfoResponseModel {
  constructor(
    readonly availableHours: number,
    readonly hours: number,
    readonly endDate: moment.Moment | null,
    readonly startDate: moment.Moment | null,
    readonly isUserUnitCoordinator: boolean = false,
  ) {
    this.endDate = endDate ? endDate.clone() : null;
    this.startDate = startDate ? startDate.clone() : null;
  }

  static fromJSON(json: LevelInfoResponse): LevelInfoResponseModel {
    assertRequiredProperties(json, [
      'availableHours',
      'hours',
      'isUserUnitCoordinator',
    ]);
    const {
      availableHours, endDate, startDate, hours, isUserUnitCoordinator,
    } = json;

    return new LevelInfoResponseModel(
      availableHours,
      hours,
      endDate ? moment(endDate).parseZone() : null,
      startDate ? moment(startDate).parseZone() : null,
      isUserUnitCoordinator,
    );
  }

  clone(): LevelInfoResponseModel {
    const {
      availableHours, endDate, startDate, hours,
    } = this;
    const newEndDate = endDate?.clone() ?? null;
    const newStartDate = startDate ? startDate.clone() : null;

    return new LevelInfoResponseModel(availableHours, hours, newEndDate, newStartDate, this.isUserUnitCoordinator);
  }
}
