import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../helpers/utilities';

@Pipe({
  name: 'responsiblesPipe',
})
export class ResponsiblesPipe implements PipeTransform {
  transform(value: string, maxResponsibles: number): string {
    if (!isEmpty(value) && value.includes(', ')) {
      const listOfResponsibles = value.split(', ');
      if (listOfResponsibles.length <= maxResponsibles) {
        return value;
      }

      return `${listOfResponsibles
        .splice(0, maxResponsibles)
        .join(', ')} ...`;
    }

    return value;
  }
}
