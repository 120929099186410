import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserPlanningTotalsStateModel } from './user-planning-totals-state.model';
import {
  InitUserPlanningTotalsSettings,
  ResetUserPlanningTotalsSettings,
  SaveUserPlanningTotalsSettings,
  SetUserPlanningTotalsSettings,
} from './user-planning-totals.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUserPlanningTotalsState: UserPlanningTotalsStateModel = {
  selectedTotals: ['row-total-capacity', 'row-free-capacity', 'row-total-planned-capacity', 'column-total-recorded'],
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UserPlanningTotalsStateModel>({
  name: 'UserPlanningTotals',
  defaults: defaultUserPlanningTotalsState,
})
@Injectable({
  providedIn: 'root',
})
export class UserPlanningTotalsState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitUserPlanningTotalsSettings)
  initUserPlanningTotalsSettings(
    ctx: StateContext<UserPlanningTotalsStateModel>,
    { settings }: InitUserPlanningTotalsSettings,
  ): void {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });
  }

  @Action(SetUserPlanningTotalsSettings)
  setUserPlanningTotalsSettings$(
    ctx: StateContext<UserPlanningTotalsStateModel>,
    { settings }: SetUserPlanningTotalsSettings,
  ): Observable<void> {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });

    return ctx.dispatch(new SaveUserPlanningTotalsSettings(ctx.getState()));
  }

  @Action(ResetUserPlanningTotalsSettings)
  resetUserPlanningTotalsSettings(ctx: StateContext<UserPlanningTotalsStateModel>): void {
    ctx.setState(defaultUserPlanningTotalsState);
  }

  @Action(SaveUserPlanningTotalsSettings)
  saveUserPlanningTotalsSettings$(
    ctx: StateContext<UserPlanningTotalsStateModel>,
    { settings }: SaveUserPlanningTotalsSettings,
  ): Observable<void> {
    return this.userService.saveUserPlanningTotalsSettings$(settings);
  }
}
