import { Selector } from '@ngxs/store';
import { NonHumanResourceListState } from './non-human-resource-list.state';
import { NonHumanResourceListOptions } from './non-human-resource-list.actions';
import { NonHumanResourceListStateModel } from './non-human-resource-list-state.model';

export class NonHumanResourceListSelectors {
  @Selector([NonHumanResourceListState])
  static selectedColumns(state: NonHumanResourceListStateModel): NonHumanResourceListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([NonHumanResourceListState])
  static options(state: NonHumanResourceListStateModel): NonHumanResourceListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: { ...state.columnFilters },
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
