import { Unit } from '../../../models/unit.interface';

/**
 * Store actions for manipulating timesheet user settings.
 */
export enum WorkPackageActionTypes {
  InitWorkPackageSettings = '[API] Init Work Package Settings State',
  AddWorkPackageRecentSupplierUnit = '[WorkPackage] Add Work Package Recent Supplier Unit',
}

/**
 * In the backend only a list of recent units is stored. The week and year is
 * not stored in the backend.
 */
export class InitWorkPackageSettings {
  static readonly type: string = WorkPackageActionTypes.InitWorkPackageSettings;

  constructor(
    readonly recentSupplierUnits: readonly Unit[],
  ) {}
}

export class AddWorkPackageRecentSupplierUnit {
  static readonly type: string = WorkPackageActionTypes.AddWorkPackageRecentSupplierUnit;

  constructor(
    public unit: Unit,
  ) {}
}
