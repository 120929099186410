import { Int32IdNameDto } from '../../interfaces/dtos/int32-id-name.dto';

export class Int32IdNameDtoModel {
  constructor(
    readonly id: number,
    readonly name: string,
  ) {}

  static fromJSON(json: Int32IdNameDto): Int32IdNameDtoModel {
    return new Int32IdNameDtoModel(
      json.id,
      json.name,
    );
  }

  clone(): Int32IdNameDtoModel {
    return new Int32IdNameDtoModel(
      this.id,
      this.name,
    );
  }

  toJSON(): Int32IdNameDto {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
