import moment from 'moment';
import { UnitPlanningUserWeekTotalDto } from '../../interfaces/dtos/unit-planning-user-week-total.dto';

export class UnitPlanningUserWeekTotalDtoModel {
  constructor(
    readonly weekStartDate: moment.Moment,
    readonly totalProductivePlannedHours: number | null,
    readonly totalNonProductivePlannedHours: number | null,
    readonly totalPlannedHours: number | null,
    readonly workingDays: string | null,
  ) {
    this.weekStartDate = weekStartDate.clone();
  }

  static fromJSON(json: UnitPlanningUserWeekTotalDto): UnitPlanningUserWeekTotalDtoModel {
    return new UnitPlanningUserWeekTotalDtoModel(
      moment(json.weekStartDate).parseZone(),
      json.totalProductivePlannedHours,
      json.totalNonProductivePlannedHours,
      json.totalPlannedHours,
      json.workingDays,
    );
  }

  clone(): UnitPlanningUserWeekTotalDtoModel {
    return new UnitPlanningUserWeekTotalDtoModel(
      this.weekStartDate.clone(),
      this.totalProductivePlannedHours,
      this.totalNonProductivePlannedHours,
      this.totalPlannedHours,
      this.workingDays,
    );
  }
}
