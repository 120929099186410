/**
 * Check and return true if an object is type of string
 */
export function isString(obj: any): obj is string {
  return typeof obj === 'string';
}

/**
 * Check and return true if an object is type of number
 */
export function isNumber(obj: any): obj is number {
  return typeof obj === 'number';
}

/**
 * Check and return true if an object is type of Function
 */
export function isFunction(obj: any): obj is () => any {
  return typeof obj === 'function';
}
