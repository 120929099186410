import { Selector } from '@ngxs/store';
import { WorkPackagePlanningRangeStateModel } from './work-package-planning-range-state.model';
import { WorkPackagePlanningRangeState } from './work-package-planning-range.state';

export class WorkPackagePlanningRangeSelectors {
  @Selector([WorkPackagePlanningRangeState])
  static range(state: WorkPackagePlanningRangeStateModel): WorkPackagePlanningRangeStateModel['range'] {
    return state.range;
  }
}
