import moment from 'moment';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { ResponsibleUserDto } from '../../interfaces/dtos/responsible-user.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { ResponsibleUserMetadata } from '../../interfaces/metadata';
import { PlanningEntryDtoModel } from './planning-entry.dto.model';
import { CapacityDtoModel } from './capacity.dto.model';

export class ResponsibleUserDtoModel extends UserSlimDtoModel {
  readonly canRemoveResponsibleUser: boolean;

  readonly canRemoveResponsibleUserMessage: string | null;

  readonly canEditResponsibleUser: boolean;

  readonly capacityUnit: CapacityUnits = CapacityUnits.HoursWeek;

  constructor(
    userId: string,
    fullName: string,
    email: string,
    photoUrl: string | null,
    validFrom: moment.Moment | null,
    validUntil: moment.Moment | null,
    /** This is the total of all the planningEntries of the user, even those not visible or transmitted */
    public plannedHours: number,
    public reportedHours: number,
    public isCoordinator: boolean,
    readonly wasUserPlannedInNewVersion: boolean,
    readonly planningEntries: PlanningEntryDtoModel[],
    readonly capacities: CapacityDtoModel[],
    readonly metadata: MetadataModel<ResponsibleUserMetadata> = new MetadataModel(),
  ) {
    super(
      userId,
      fullName,
      email,
      photoUrl,
      validFrom,
      validUntil,
    );
    this.planningEntries = [...planningEntries];
    this.capacities = [...capacities];
    this.planningEntries = [...planningEntries];

    this.canRemoveResponsibleUser = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canRemoveResponsibleUser) || false;

    this.canRemoveResponsibleUserMessage = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canRemoveResponsibleUserMessage) || null;

    this.canEditResponsibleUser = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canEditResponsibleUser) || false;
  }

  getId(): string {
    return this.userId;
  }

  getName(): string {
    return this.fullName;
  }

  static fromJSON(json: ResponsibleUserDto, isCoordinator: boolean = false): ResponsibleUserDtoModel {
    return new ResponsibleUserDtoModel(
      json.userId,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.reportedHours || 0,
      (json.isCoordinator != null ? json.isCoordinator : isCoordinator),
      (json.wasUserPlannedInNewVersion != null ? json.wasUserPlannedInNewVersion : false),
      (Array.isArray(json.planningEntries) ? json.planningEntries : [])
        .map((item) => PlanningEntryDtoModel.fromJSON(item)),
      (Array.isArray(json.capacities) ? json.capacities : [])
        .map((item) => CapacityDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {} as any),
    );
  }

  static createFromUserListDtoModel(
    user?: UserSlimDtoModel,
    isCoordinator: boolean = false,
  ): ResponsibleUserDtoModel {
    if (!user) {
      throw new Error('No ResponsibleUserDtoModel can be created from UserSlimDtoModel');
    }

    const metadata = {
      fields: {
        canRemoveResponsibleUser: true,
        canEditResponsibleUser: true,
        canRemoveResponsibleUserMessage: null,
      },
    };
    const {
      userId, fullName, email, photoUrl, validFrom, validUntil,
    } = user;

    return new ResponsibleUserDtoModel(
      userId,
      fullName,
      email,
      photoUrl,
      (validFrom ? validFrom.clone() : null),
      (validUntil ? validUntil.clone() : null),
      0,
      0,
      isCoordinator,
      false,
      [],
      [],
      new MetadataModel(metadata),
    );
  }

  toJSON(): ResponsibleUserDto {
    return Object.assign(super.toJSON(), {
      planningEntries: this.planningEntries.map((item) => item.toJSON()),
      plannedHours: this.plannedHours,
      reportedHours: this.reportedHours,
      isCoordinator: this.isCoordinator,
      wasUserPlannedInNewVersion: this.wasUserPlannedInNewVersion,
      capacities: this.capacities.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    });
  }
}
