import { ErrorHandler, TransferState, LOCALE_ID, Provider } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { ApplicationInsightsService } from './modules/application-insights';
import { httpInterceptorProviders } from './http-interceptors';
import { AccessGuard } from './guards/access.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { UserService } from './api/services/user.service';
import { AccessControlService } from './services/access-control.service';
import { ClipboardService } from './services/clipboard.service';
import { CollAppApiService } from './api/services/collapp-api.service';
import { KeyboardShortcutsService } from './services/keyboard-shortcuts.service';
import { BaseDataService } from './api/services/base-data.service';
import { LayoutService } from './services/layout.service';
import { DashboardService } from './api/services/dashboard.service';
import { BasicDialogService } from './services/basic-dialog.service';
import { CollappDialogService } from './services/collapp-dialog.service';
import { ProjectService } from './api/services/project.service';
import { UnitService } from './api/services/unit.service';
import { ProjectViewResolver } from './services/project-view-resolver.service';
import { ProjectScopeResolver } from './services/project-scope-resolver.service';
import { WorkPackageViewResolver } from './services/work-package-view-resolver.service';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { ExportService } from './services/export.service';
import { LoginGuard } from './guards/login.guard';
import { TimeTrackingService } from './api/services/time-tracking.service';
import { NonHumanResourceService } from './api/services/non-human-resource.service';
import { environment } from '../environments/environment';
import { SkillsService } from './api/services/skills.service';
import '@angular/common/locales/global/de-CH';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.clientId,
      authority: environment.msalConfig.instance,
      redirectUri: environment.msalConfig.redirectUri || '/login',
      navigateToLoginRequestUrl: false,
      postLogoutRedirectUri: environment.msalConfig.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);
  protectedResourceMap.set('//login.microsoftonline.com', ['user.read']);
  protectedResourceMap.set(`${environment.apiEndpoint}`, [
    `api://${environment.msalConfig.apiEndpointId}/read_as_user`,
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    authRequest: {
      scopes: [environment.msalConfig.tenant!],
    },
    interactionType: InteractionType.Redirect,
  };
}

export const APP_PROVIDERS: Provider[] = [
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandlerService,
  },
  httpInterceptorProviders,
  ApplicationInsightsService,
  ClipboardService,
  CollAppApiService,
  KeyboardShortcutsService,
  UserService,
  AccessControlService,
  AccessGuard,
  LoginGuard,
  CanDeactivateGuard,
  BaseDataService,
  LayoutService,
  DashboardService,
  BasicDialogService,
  CollappDialogService,
  ProjectService,
  ProjectViewResolver,
  ProjectScopeResolver,
  TransferState,
  TimeTrackingService,
  UnitService,
  NonHumanResourceService,
  WorkPackageViewResolver,
  ExportService,
  SkillsService,
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalGuard,
  MsalBroadcastService,
  MsalService,
  {
    provide: LOCALE_ID,
    useValue: 'de-CH',
  },
];
