import { GenericListOptions } from '../generic-list';
import { NonHumanResourcesListColumnKey } from '../../../api/meta-info/non-human-resources-list.info';
import { NonHumanResourceColumnFilters } from '../../../api/filter-columns';
import { NonHumanResourceListStateModel } from './non-human-resource-list-state.model';

/**
 * Action types for NonHumanResourceListState
 */
export enum NonHumanResourceListActionTypes {
  SetNonHumanResourceListSelectedColumns = '[Non-Human Resource List Page] Set Selected Columns',
  SetNonHumanResourceListOptions = '[Non-Human Resource List Page] Set Options',
  ResetNonHumanResourceListOptions = '[Non-Human Resource List Page] Reset Options',
  InitNonHumanResourceListSettings = '[User API] Initialize Non-Human Resource List Settings',
  SetNonHumanResourceListSettings = '[User API] Set Non-Human Resource List Settings',
  ResetNonHumanResourceListSettings = '[User API] Reset Non-Human Resource List Settings',
  SaveNonHumanResourceListSettings = '[User API] Save Non-Human Resource List Settings',
}

export interface NonHumanResourceListOptions extends GenericListOptions {
  columnFilters: NonHumanResourceColumnFilters;
}

export class SetNonHumanResourceListSelectedColumns {
  static readonly type: string = NonHumanResourceListActionTypes.SetNonHumanResourceListSelectedColumns;

  constructor(public columns: NonHumanResourcesListColumnKey[]) {}
}

export class SetNonHumanResourceListOptions {
  static readonly type: string = NonHumanResourceListActionTypes.SetNonHumanResourceListOptions;

  constructor(public options: NonHumanResourceListOptions) {}
}

export class ResetNonHumanResourceListOptions {
  static readonly type: string = NonHumanResourceListActionTypes.ResetNonHumanResourceListOptions;
}

export class InitNonHumanResourceListSettings {
  static readonly type: string = NonHumanResourceListActionTypes.InitNonHumanResourceListSettings;

  constructor(public settings: NonHumanResourceListStateModel) {}
}

export class SetNonHumanResourceListSettings {
  static readonly type: string = NonHumanResourceListActionTypes.SetNonHumanResourceListSettings;

  constructor(public settings: NonHumanResourceListStateModel) {}
}

export class ResetNonHumanResourceListSettings {
  static readonly type: string = NonHumanResourceListActionTypes.ResetNonHumanResourceListSettings;
}

export class SaveNonHumanResourceListSettings {
  static readonly type: string = NonHumanResourceListActionTypes.SaveNonHumanResourceListSettings;

  constructor(public settings: NonHumanResourceListStateModel) {}
}
