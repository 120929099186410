import { Injectable } from '@angular/core';
import { ApplicationInsightsService } from '../modules/application-insights';
import { ToastyService } from '../shared/toasty';
import { getToastForApiError } from '../helpers/error-message.utility';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private appInsightsService: ApplicationInsightsService,
    private toastyService: ToastyService,
  ) {
  }

  /**
   * Tracks the error through Application Insights without notifying the user.
   */
  trackError(error: Error): void {
    this.appInsightsService.trackException({ exception: error });
  }

  /**
   * Displays an error toast to the user and tracks the error through Application Insights.
   */
  handleError(error: Error, title: string = 'An error occurred'): void {
    this.notifyUser(error, title);
    this.appInsightsService.trackException({ exception: error });
  }

  /**
   * Displays an error toast to the user.
   */
  notifyUser(error: Error, title: string = 'An error occurred'): void {
    this.toastyService.error(getToastForApiError(title, error));
  }
}
