import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DatesRangeComponent } from './dates-range.component';
import { CollappCommonModule } from '../../collapp-common';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    CollappCommonModule,
  ],
  exports: [
    DatesRangeComponent,
  ],
  declarations: [
    DatesRangeComponent,
  ],
})
export class DatesRangeModule {
}
