import { UserPlanningSidepanelIndexStateModel } from './user-planning-sidepanel-index-state.model';

/**
 * Action types for UserPlanningSidepanelIndex
 */
export enum UserPlanningSidepanelIndexActionTypes {
  InitUserPlanningSidepanelIndex = '[UserPlanningSidepanelIndex API] Initialize Sidepanel',
  SetUserPlanningSidepanelIndex = '[UserPlanningSidepanelIndex API] Set UserPlanningSidepanelIndex',
  ResetUserPlanningSidepanelIndex = '[UserPlanningSidepanelIndex API] Reset UserPlanningSidepanelIndex',
}

export class InitUserPlanningSidepanelIndex {
  static readonly type: string = UserPlanningSidepanelIndexActionTypes.InitUserPlanningSidepanelIndex;

  constructor(public settings: UserPlanningSidepanelIndexStateModel) {}
}

export class ResetUserPlanningSidepanelIndex {
  static readonly type: string = UserPlanningSidepanelIndexActionTypes.ResetUserPlanningSidepanelIndex;
}

export class SetUserPlanningSidepanelIndex {
  static readonly type: string = UserPlanningSidepanelIndexActionTypes.SetUserPlanningSidepanelIndex;

  constructor(public settings: UserPlanningSidepanelIndexStateModel) {}
}
