import { ProjectListResponse } from '../../interfaces/responses/project-list.response';
import { WithPaginationInfo } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { ProjectListViewDtoModel } from '../dtos/project-list-view.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectListResponseModel {
  constructor(
    readonly items: readonly ProjectListViewDtoModel[],
    readonly metadata: MetadataModel<WithPaginationInfo>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: ProjectListResponse): ProjectListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new ProjectListResponseModel(
      json.items.map((item) => ProjectListViewDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): ProjectListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
