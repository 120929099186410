import { GateListResponse } from '../../interfaces/responses/gate-list.response';
import { GateDtoModel } from '../dtos/gate.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class GateListResponseModel {
  constructor(
    readonly items: readonly GateDtoModel[],
    readonly metadata: MetadataModel = new MetadataModel({}),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: GateListResponse): GateListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new GateListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => GateDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  clone(): GateListResponseModel {
    return new GateListResponseModel(
      this.items.map((item) => item.clone()),
      this.metadata.clone(),
    );
  }

  toJSON(): GateListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
