import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { NonHumanResourcePlanningSlimDtoModel } from './non-human-resource-planning-slim.dto.model';
import { UserPlanningSlimDtoModel } from './user-planning-slim.dto.model';

export class UserPlanningResourceSlimDtoModel {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly photoUrl: string | null = null,
    readonly capacityUnit: CapacityUnits | null,
    readonly isHuman: boolean | null = null,
  ) {
    if (isHuman) {
      this.capacityUnit = CapacityUnits.HoursWeek;
    }
  }

  static fromNonHumanResourcePlanningSlimDtoModel(
    model: NonHumanResourcePlanningSlimDtoModel,
  ): UserPlanningResourceSlimDtoModel {
    return new UserPlanningResourceSlimDtoModel(
      model.nonHumanResourceId,
      model.name || '',
      '',
      model.capacityUnit,
      false,
    );
  }

  static fromUserPlanningSlimDtoModel(
    model: UserPlanningSlimDtoModel,
  ): UserPlanningResourceSlimDtoModel {
    return new UserPlanningResourceSlimDtoModel(
      model.userId,
      model.fullName,
      model.photoUrl,
      CapacityUnits.HoursWeek,
      true,
    );
  }

  clone(): UserPlanningResourceSlimDtoModel {
    return new UserPlanningResourceSlimDtoModel(
      this.id,
      this.name,
      this.photoUrl,
      this.capacityUnit,
      this.isHuman,
    );
  }
}
