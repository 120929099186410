/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
// eslint-disable-next-line max-classes-per-file
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm,
} from '@angular/forms';
import {
  CanDisable,
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinDisabled,
  mixinDisableRipple,
  mixinErrorState,
  mixinTabIndex,
} from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  BehaviorSubject, merge, Observable, of, Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import moment from 'moment';
import { CompareWithUtility } from '../../helpers/compare-with.utility';
import { UnitSelectorDtoModel } from '../../api/models/dtos/unit-selector.dto.model';
import { UnitSelectorAvailableRequestOptions, UnitSelectorRequestOptions, UnitService } from '../../api/services/unit.service';
import { DEBOUNCE_INPUT_CHANGE_TIME_SMALL } from '../../shared/constants';
import { Unit, UnitIdentity } from '../../models/unit.interface';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UnitLevel } from '../../models/unit-level.enum';
import { UnitSkillDtoModel } from '../../api/models/dtos/unit-skill.dto.model';

// TODO Maybe adapt nullable level in UnitIdentity base type
export interface UnitWithOptionalLevel extends UnitIdentity {
  name: string;
  level: UnitLevel | null;
}

/**
 * The possible types the unit selector fetches
 * its values:
 * - AllUnits: Will fetch all / consider all units while filtering
 * - UserUnitsWithChildren: Considers all units that a user has access to
 * - UserL3Units: All L3 units of a user (considers the path to parents).
 * - AllL1ToL4Units: All Units belonging to the L1 path
 * - AllL4Units: All L4 Units that exist
 * - UserL1Units: Every unit that is part of the L1 of the user
 * - WorkPackageUnitsSubmitterView: User can filter all submitter units of WP they can see
 * - WorkPackageUnitsSupplierView: User can filter all supplier units of WP they can see
 * - UserCoordinatorUnitsAndContractSuppliers: Every unit the user is (deputy) coordinator of and the
 *   unit's contract suppliers
 */
export enum UnitSelectFetchType {
  AllUnits = 'AllUnits',
  UserUnitsWithChildren = 'UserUnitsWithChildren',
  UserL3Units = 'UserL3Units',
  AllL1ToL4Units = 'AllL1ToL4Units',
  AllL4Units = 'AllL4Units',
  UserL1Units = 'UserL1Units',
  WorkPackageUnitsSubmitterView = 'WorkPackageUnitsSubmitterView',
  WorkPackageUnitsSupplierView = 'WorkPackageUnitsSupplierView',
  UserCoordinatorUnitsAndContractSuppliers = 'UserCoordinatorUnitsAndContractSuppliers',
  Skills = 'Skills',
  AllL1Units = 'AllL1Units',
  AvailableUnitsForWorkPackage = 'AvailableUnitsForWorkPackage',
}

const GROUP_RECENTLY_VIEWED = 'Recently viewed';
const GROUP_SEARCH_RESULTS = 'Search results';

// Boilerplate for applying mixins to MatSelect.
export class UnitSelectBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: Subject<void>
  ) {}
}
// eslint-disable-next-line no-underscore-dangle
export const _UnitSelectMixinBase = mixinDisableRipple(mixinTabIndex(mixinDisabled(mixinErrorState(UnitSelectBase))));

@Component({
  selector: 'collapp-unit-select',
  exportAs: 'collappUnitSelect',
  templateUrl: './unit-select.component.html',
  styleUrls: ['./unit-select.component.scss'],
  providers: [
    // eslint-disable-line @typescript-eslint/no-use-before-define
    { provide: MatFormFieldControl, useExisting: UnitSelectComponent },
  ],
  inputs: [ // eslint-disable-line @angular-eslint/no-inputs-metadata-property
    '_disabled',
    'disableRipple',
    'tabIndex',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitSelectComponent<T extends Unit> extends _UnitSelectMixinBase
  implements OnInit, OnDestroy, DoCheck, OnChanges, ControlValueAccessor, CanDisable,
    MatFormFieldControl<T>, CanUpdateErrorState {
  static nextId: number = 0;

  @HostBinding('class.collapp-unit-select')
  readonly unitSelectClass: boolean = true;

  // === MatFormFieldControl ===

  /** The value of the control. */
  get value(): T | null { return this.unitControl.value; }

  @Input()
  set value(value: T | null) {
    this.writeValue(value);
  }

  /** The element ID for this control. */
  @HostBinding()
  @Input()
  get id(): string { return this._id; }

  set id(value: string) {
    this._id = (value != null ? value : this._uid);
    this.stateChanges.next();
  }

  /** The placeholder for this control. */
  get placeholder(): string { return this._placeholder; }

  @Input()
  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this.stateChanges.next();
    }
  }

  /** Whether to only show a summary (x units selected) or the list of selected units */
  get showSummary(): boolean { return this._showSummary; }

  @Input()
  set showSummary(value: boolean) {
    if (this._showSummary !== value) {
      this._showSummary = value;
      this.stateChanges.next();
    }
  }

  /**
   * Sets a list of units as recently viewed.
   * Recently viewed units are listed separately from searched units.
   */
  @Input()
  set recentUnits(units: readonly T[]) {
    if (this._recentUnits != null) {
      const recentUnits = units.map((unit) => {
        const option = new UnitSelectorDtoModel(
          unit.unitId,
          unit.name,
          unit.level,
          null,
          null,
        );

        option.group = GROUP_RECENTLY_VIEWED;

        return option;
      });

      // Replace current recently viewed units
      const currentList = this.filteredUnitsSubject$.value;
      this._recentUnits = recentUnits;

      const updatedList = [
        ...recentUnits,
        ...(currentList as UnitSelectorDtoModel[])
          .filter((unit) => unit.group !== GROUP_RECENTLY_VIEWED)
          .map((unit) => {
            // eslint-disable-next-line no-param-reassign
            unit.group = GROUP_SEARCH_RESULTS;

            return unit;
          }),
      ];

      this.filteredUnitsSubject$.next(updatedList);
      this.numberOfResults = updatedList.length;
      this.stateChanges.next();
    }
  }

  /**
   * Sets a list of units as recently viewed.
   * Recently viewed units are listed separately from searched units.
   */
  @Input()
  set recentSkillUnits(units: readonly UnitSkillDtoModel[]) {
    if (this._recentSkillUnits != null) {
      const recentUnits = units.map((unit) => {
        const option = UnitSkillDtoModel.fromJSON(unit.toJSON());

        // eslint-disable-next-line no-param-reassign
        option.group = GROUP_RECENTLY_VIEWED;

        return option;
      });

      // Replace current recently viewed units
      const currentList = this.filteredUnitsSubject$.value;
      this._recentSkillUnits = recentUnits;

      const updatedList = [
        ...recentUnits,
        ...(currentList as UnitSkillDtoModel[])
          .filter((unit) => unit.group !== GROUP_RECENTLY_VIEWED)
          .map((unit) => {
            // eslint-disable-next-line no-param-reassign
            unit.group = GROUP_SEARCH_RESULTS;

            return unit;
          }),
      ];

      this.filteredUnitsSubject$.next(updatedList);
      this.numberOfResults = updatedList.length;
      this.stateChanges.next();
    }
  }

  @Input()
  workPackageId: number | null = null;

  @Input()
  projectId: number | null = null;

  @Input()
  contractSupplierUnitId: number | null = null;

  @Input()
  canAddSupplierUnit: boolean | null = null;

  /** Whether the control is focused. */
  get focused(): boolean {
    return this.unitSelect.focused;
  }

  /** Whether the control is empty. */
  get empty(): boolean {
    return (
      this.unitControl.value == null
      || (
        Array.isArray(this.unitControl.value)
        && this.unitControl.value.length === 0
      )
    );
  }

  /** Whether the `MatFormField` label should try to float. */
  @HostBinding('class.mat-form-field-should-float')
  get shouldLabelFloat(): boolean {
    return true;
  }

  /** Whether the component is required */
  get required(): boolean { return this._required; }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  /**
   * Whether the component is disabled
   *
   * Already set through the Disabled mixin.
   */
  // @Input()
  // public disabled: boolean;

  /** An object used to control when error messages are shown. */
  @Input()
  errorStateMatcher!: ErrorStateMatcher;

  // === MatFormFieldControl additional custom ===

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';

  /**
   * The number of elements to be shown in the list, after filtering.
   */
  @Input()
  limit: number = 40;

  @Input()
  date: moment.Moment | null = null;

  /**
   * The fetch type which should be applied when getting the list.
   */
  @Input()
  fetchType: UnitSelectFetchType = UnitSelectFetchType.UserUnitsWithChildren;

  /**
   * Include children parameter
   */
  @Input()
  includeChildren: boolean = false;

  @Output()
  readonly loading: Observable<boolean>;

  @ViewChild('unitSelect', { static: true })
  private unitSelect!: NgSelectComponent;

  /**
   * An optional name for the control type that can be used to distinguish `mat-form-field` elements
   * based on their control type. The form field will add a class,
   * `mat-form-field-type-{{controlType}}` to its root element.
   */
  readonly controlType: string = 'unit-select';

  /**
   * Whether the input is currently in an autofilled state. If property is not present on the
   * control it is assumed to be false.
   */
  readonly autofilled: boolean = false;

  // === /MatFormFieldControl ===

  // === /MatFormFieldControl additional custom ===

  // === Custom controls ===

  /**
   * Contains a list of items that are supposed to be disabled.
   * It resets the map whenever changes occur.
   * @param items
   */
  @Input()
  set disabledItems(items: UnitIdentity[]) {
    const newItems = (Array.isArray(items) ? items : []);

    this.disabledUnitIds.clear();

    newItems.forEach((item) => {
      this.disabledUnitIds.add(item.unitId);
    });
  }

  /**
   * Flag that indicates whether multiple values can be selected.
   */
  get multiple(): boolean { return this._multiple; }

  @Input()
  set multiple(value: boolean) {
    this._multiple = coerceBooleanProperty(value);
  }

  get clearSearchOnAdd(): boolean { return this._clearSearchOnAdd; }

  @Input()
  set clearSearchOnAdd(value: boolean) {
    this._clearSearchOnAdd = coerceBooleanProperty(value);
  }

  get closeOnSelect(): boolean { return this._closeOnSelect; }

  @Input()
  set closeOnSelect(value: boolean) {
    this._closeOnSelect = coerceBooleanProperty(value);
  }

  /**
   * Flag that indicates whether a unfiltered list should be fetch on init.
   * If set to true, the user will have to filter, before seeing a list.
   */
  get initFetch(): boolean { return this._initFetch; }

  @Input()
  set initFetch(value: boolean) {
    this._initFetch = coerceBooleanProperty(value);
  }

  /**
   * If set to true in combination with initFetch,
   * the first value of the provided list will be set.
   */
  get setInitFetchDefault(): boolean { return this._setInitFetchDefault; }

  @Input()
  set setInitFetchDefault(value: boolean) {
    this._setInitFetchDefault = coerceBooleanProperty(value);
  }

  /**
   * Allows the currently selected value to be cleared.
   */
  get clearable(): boolean { return this._clearable; }

  @Input()
  set clearable(value: boolean) {
    this._clearable = coerceBooleanProperty(value);
  }

  // === \ Custom controls ===

  readonly unitsCompare: (o1: any, o2: any) => boolean = CompareWithUtility.unitsCompare;

  get isLoading(): boolean { return this.loadingSubject$.value; }

  get isSkillUnitSelector(): boolean { return this.fetchType === UnitSelectFetchType.Skills; }

  listInitialized: boolean = false;

  filteredUnits$: Observable<UnitSelectorDtoModel[] | UnitSkillDtoModel[]>;

  numberOfResults: number = 0;

  numberOfMaxTotalResults: number = 0;

  // it must be public to use in the ng-select
  // eslint-disable-next-line rxjs/no-exposed-subjects
  typeahead$: Subject<string> = new Subject();

  unitControl: UntypedFormControl = new UntypedFormControl();

  get summary(): string {
    if (this._multiple && this.value instanceof Array) {
      const selectedUnits = (this.value as UnitSelectorDtoModel[]).length;

      return `${selectedUnits} ${selectedUnits === 1 ? 'unit' : 'units'} selected`;
    }

    return '';
  }

  units: UnitSelectorDtoModel[] | UnitSkillDtoModel[] = [];

  // === MatFormFieldControl ===
  private _placeholder: string = '';

  private _required: boolean = false;
  // === \ MatFormFieldControl ===

  // === Custom controls ===
  /**
   * Unit IDs in this map will be set to disabled in the list
   */
  private disabledUnitIds: Set<number> = new Set();

  private _multiple: boolean = false;

  private _initFetch: boolean = false;

  private _setInitFetchDefault: boolean = false;

  private _clearable: boolean = true;

  private _clearSearchOnAdd: boolean = true;

  private _closeOnSelect: boolean = true;

  private _showSummary: boolean = false;

  private _recentUnits: readonly UnitSelectorDtoModel[] = [];

  private _recentSkillUnits: readonly UnitSkillDtoModel[] = [];
  // === \ Custom controls ===

  private filteredUnitsSubject$: BehaviorSubject<
  UnitSelectorDtoModel[] | UnitSkillDtoModel[]
  > = new BehaviorSubject([]);

  private loadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _id: string;

  /** Unique id for this input. */
  // eslint-disable-next-line no-plusplus
  private _uid: string = `collapp-unit-select-${UnitSelectComponent.nextId++}`;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Self() ngControl: NgControl,
    // eslint-disable-next-line @angular-eslint/no-attribute-decorator
    @Attribute('tabindex') tabIndex: string,
    private errorHandlerService: ErrorHandlerService,
    private unitService: UnitService
  ) {
    super(
      _defaultErrorStateMatcher,
      _parentForm,
      _parentFormGroup,
      ngControl,
      new Subject<void>()
    );

    if (this.ngControl != null) {
      // Note: we provide the value accessor through here, instead of
      // the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }

    this.tabIndex = Number.parseInt(tabIndex, 10) || 0;

    // Set default id to internal uid
    this._id = this._uid;

    this.filteredUnits$ = this.filteredUnitsSubject$.asObservable();
    this.loading = this.loadingSubject$.asObservable();
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle, max-lines-per-function
  ngOnInit(): void {
    this.unitControl
      .valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((value) => {
        this._onChange(value);
        this.stateChanges.next();
      });

    const initialDropDownOpen$ = this.unitSelect
      .openEvent
      .pipe(
        filter(() => !this.listInitialized),
        mapTo(''),
        takeUntil(this.destroyed$),
        share(),
      );

    // eslint-disable-next-line no-nested-ternary
    const listeners$ = this.isSkillUnitSelector
      ? (this.initFetch ? of('') : initialDropDownOpen$)
      : merge(
        (this.initFetch ? of('') : initialDropDownOpen$),
        this.typeahead$,
      ).pipe(
        debounceTime(DEBOUNCE_INPUT_CHANGE_TIME_SMALL),
        map((value) => (value ? value?.trim() : '')),
        distinctUntilChanged(),
      );

    const unitControlChange$ = this.unitControl.valueChanges
      .pipe(
        filter((value) => !!value?.unitId),
        distinctUntilChanged((a, b) => a.unitId === b.unitId),
        takeUntil(this.destroyed$),
      );

    // Listens for changes in the filterable dropdown
    (this.canAddSupplierUnit
      ? merge(listeners$, unitControlChange$)
      : listeners$
    )
      .pipe(
        tap(() => this.loadingSubject$.next(true)),
        // eslint-disable-next-line max-lines-per-function
        switchMap((filterString) => {
          if (this.fetchType === UnitSelectFetchType.AvailableUnitsForWorkPackage) {
            if (this.workPackageId == null || this.projectId == null) {
              throw new Error('WworkPackageId and projectId must be set');
            }

            const requestOptions: UnitSelectorAvailableRequestOptions = {
              workPackageId: this.workPackageId,
              projectId: this.projectId,
              filterString,
              limit: this.limit,
            };

            return this.unitService.getAvailableUnitsForUnitSelector$(requestOptions)
              .pipe(
                map((response) => [...response.items]),
              );
          }
          const requestOptions: UnitSelectorRequestOptions = {
            unitFetchType: this.fetchType,
            includeChildren: this.includeChildren,
            limit: this.limit,
            date: this.date?.toISOString(),
          };

          if (filterString.length > 0) {
            requestOptions.filterString = filterString;
          }

          if (this.fetchType === UnitSelectFetchType.AllL1Units) {
            return this.unitService.getL1Units$()
              .pipe(
                map((response) => [...response]),
              );
          }

          if (this.fetchType === UnitSelectFetchType.Skills) {
            return this.unitService
              .getSkillUnits$()
              .pipe(
                map((response) => [...this._recentSkillUnits, ...response.map((unit) => {
                  // eslint-disable-next-line no-param-reassign
                  unit.group = GROUP_SEARCH_RESULTS;

                  return unit;
                })]),
              );
          }

          return this.unitService
            .getUnitsForUnitSelector$(requestOptions)
            .pipe(
              tap((response) => {
                if (filterString === '') {
                  if (response.metadata.paginationInfo) {
                    this.numberOfMaxTotalResults = response.metadata.paginationInfo.numberOfTotalResults || 0;
                  }
                }
              }),
              map((response) => {
                const searchedUnits = response.items.map((unit) => this.extendSearchedUnits(unit));

                if (!filterString || filterString.trim() === '') {
                  // Show recent units if filter string does not contain anything
                  return [...this._recentUnits, ...searchedUnits];
                }

                return searchedUnits;
              }),
            );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (units: UnitSelectorDtoModel[] | UnitSkillDtoModel[]) => {
          this.loadingSubject$.next(false);

          this.filteredUnitsSubject$.next(units);
          this.numberOfResults = units.length;

          if (!this.listInitialized) {
            this.listInitialized = true;

            if (
              this.setInitFetchDefault
              && this.value == null
              && units.length > 0
            ) {
              // if no item was selected previously
              // Preselects the first value of the initial fetch,
              this.writeValue(units[0]);
            }
          }

          this.units = units;
        },
        (error: unknown) => {
          this.loadingSubject$.next(false);
          this.errorHandlerService.handleError(error as Error, 'Error occurred while fetching units');
        },
      );
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnChanges(changes: SimpleChanges): void {
    // Updating the disabled state is handled by `mixinDisabled`, but we need to additionally let
    // the parent form field know to run change detection when the disabled state changes.
    if (changes.disabled) {
      this.setUnitControlDisabledState(changes.disabled.currentValue);
      this.stateChanges.next();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
  ngOnDestroy(): void {
    this.filteredUnitsSubject$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // === ControlValueAccessor ===

  /**
   * Sets the model value. Implemented as part of ControlValueAccessor.
   *
   * @param value
   */
  writeValue(value: UnitWithOptionalLevel | null): void {
    this.unitControl.setValue(value);
    this.stateChanges.next();
  }

  /**
   * Registers a callback to be triggered when the model value changes.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback to be triggered when the control is touched.
   * Implemented as part of ControlValueAccessor.
   *
   * @param fn - Callback to be registered.
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Sets the disabled state of the control. Implemented as a part of ControlValueAccessor.
   *
   * @param {boolean} isDisabled - Whether the control should be disabled.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.setUnitControlDisabledState(isDisabled);
    this.stateChanges.next();
  }

  // === /ControlValueAccessor ===

  // === MatFormFieldControl ===

  /** Sets the list of element IDs that currently describe this control. */
  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  /** Handles a click on the control's container. */
  onContainerClick(): void {
    // Nothing
  }

  // === /MatFormFieldControl ===

  unitTrackByFn(unit: UnitSelectorDtoModel): number {
    return unit.unitId;
  }

  unitOptionTrackByFn(index: number, unit: UnitSelectorDtoModel): number {
    return unit.unitId;
  }

  onBlur(): void {
    this.ngControl.control?.markAsTouched();
  }

  searchUnits(term: string, item: UnitSkillDtoModel): boolean {
    const searchTerm = term.toLowerCase();

    return item.name.toLowerCase().indexOf(searchTerm) !== -1;
  }

  private setUnitControlDisabledState(disabled: boolean): void {
    if (this.unitControl.disabled !== disabled) {
      if (disabled) {
        this.unitControl.disable();
      } else {
        this.unitControl.enable();
      }
    }
  }

  // =========

  /** The method to be called in order to update ngModel */
  private _onChange: (value: any) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   */
  private _onTouched: () => any = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  // =========

  /**
   * Sets the selector item to disabled, if the unit is part of the
   * disabled map. Sets a group to distinguish between recently viewed and searched units.
   */
  private extendSearchedUnits(unit: UnitSelectorDtoModel): UnitSelectorDtoModel {
    /* eslint-disable no-param-reassign */

    unit.disabled = this.disabledUnitIds.has(unit.unitId);

    if (this._recentUnits != null && this._recentUnits.length > 0) {
      unit.group = GROUP_SEARCH_RESULTS;
    }

    return unit;

    /* eslint-enable no-param-reassign */
  }
}
