import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkPackageQuestionListStateModel } from './work-package-question-list-state.model';
import {
  InitWorkPackageQuestionListSettings,
  ResetWorkPackageQuestionListSettings,
  SaveWorkPackageQuestionListSettings,
  SetWorkPackageQuestionListOptions,
  SetWorkPackageQuestionListSelectedColumns,
  SetWorkPackageQuestionListSettings,
} from './work-package-question-list.actions';
import { UserService } from '../../../api/services/user.service';
import { USER_RESOURCE_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';
import { workPackageQuestionListColumnsInfo } from '../../../api/meta-info/work-package-questions-list.info';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultWorkPackageQuestionListState: WorkPackageQuestionListStateModel = {
  selectedColumns: Array.from(workPackageQuestionListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: USER_RESOURCE_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<WorkPackageQuestionListStateModel>({
  name: 'workPackageQuestionList',
  defaults: defaultWorkPackageQuestionListState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackageQuestionListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetWorkPackageQuestionListSelectedColumns)
  setWorkPackageQuestionListSelectedColumns$(
    ctx: StateContext<WorkPackageQuestionListStateModel>,
    { columns }: SetWorkPackageQuestionListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, workPackageQuestionListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageQuestionListSettings(ctx.getState()));
  }

  @Action(SetWorkPackageQuestionListOptions)
  setWorkPackageQuestionListOptions$(
    ctx: StateContext<WorkPackageQuestionListStateModel>,
    { options }: SetWorkPackageQuestionListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, workPackageQuestionListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageQuestionListSettings(ctx.getState()));
  }

  @Action(InitWorkPackageQuestionListSettings)
  initWorkPackageQuestionListSettings(
    ctx: StateContext<WorkPackageQuestionListStateModel>,
    { settings }: InitWorkPackageQuestionListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, workPackageQuestionListColumnsInfo));
  }

  @Action(SetWorkPackageQuestionListSettings)
  setWorkPackageQuestionListSettings$(
    ctx: StateContext<WorkPackageQuestionListStateModel>,
    { settings }: SetWorkPackageQuestionListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, workPackageQuestionListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageQuestionListSettings(ctx.getState()));
  }

  @Action(ResetWorkPackageQuestionListSettings)
  resetWorkPackageQuestionListSettings(ctx: StateContext<WorkPackageQuestionListStateModel>): void {
    ctx.setState(defaultWorkPackageQuestionListState);
  }

  @Action(SaveWorkPackageQuestionListSettings)
  saveWorkPackageQuestionListSettings$(
    ctx: StateContext<WorkPackageQuestionListStateModel>,
    { settings }: SaveWorkPackageQuestionListSettings,
  ): Observable<void> {
    return this.userService.SaveWorkPackageQuestionListSettings$(settings);
  }
}
