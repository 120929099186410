import { GenericListOptions } from '../generic-list';
import { AttachmentColumnFilters } from '../../../api/filter-columns';
import { AttachmentListStateModel } from './attachment-list-state.model';

/**
 * Action types for AttachmentListState
 */
export enum AttachmentListActionTypes {
  InitAttachmentListSettings = '[Attachments] Initialize Attachment List Settings',
  SaveAttachmentListOptions = '[Attachments] Save Options',
  SetAttachmentListSettings = '[Attachments] Set Attachment List Settings',
  ResetAttachmentListSettings = '[Attachments] Reset Attachment List Settings',
  SaveAttachmentListSettings = '[Attachments] Save Attachment List Settings',
}

export interface AttachmentListOptions extends GenericListOptions {
  columnFilters: AttachmentColumnFilters;
}

export class SaveAttachmentListOptions {
  static readonly type: string = AttachmentListActionTypes.SaveAttachmentListOptions;

  constructor(public options: AttachmentListOptions) {}
}

export class InitAttachmentListSettings {
  static readonly type: string = AttachmentListActionTypes.InitAttachmentListSettings;

  constructor(public settings: AttachmentListStateModel) {}
}

export class SetAttachmentListSettings {
  static readonly type: string = AttachmentListActionTypes.SetAttachmentListSettings;

  constructor(public settings: AttachmentListStateModel) {}
}

export class ResetAttachmentListSettings {
  static readonly type: string = AttachmentListActionTypes.ResetAttachmentListSettings;
}

export class SaveAttachmentListSettings {
  static readonly type: string = AttachmentListActionTypes.SaveAttachmentListSettings;

  constructor(public settings: AttachmentListStateModel) {}
}
