import moment from 'moment';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitPlanningCapacityDto } from '../../interfaces/dtos/unit-planning-capacity.dto';
import { CapacityDtoModel } from './capacity.dto.model';

export class UnitPlanningCapacityDtoModel {
  constructor(
    readonly totalHours: number,
    public plannedHours: number,
    public availableHours: number,
    public totalProductiveHours: number,
    public totalNonProductiveHours: number,
    readonly weekStartDate: moment.Moment | null,
    readonly availability: number,
    public hasHoliday: boolean,
    public holidayDescription: string | null = null,
    public holidayHours: number,
  ) {}

  static fromJSON(json: UnitPlanningCapacityDto): UnitPlanningCapacityDtoModel {
    assertRequiredProperties(json, [
      'totalHours',
      'plannedHours',
      'availableHours',
    ]);

    return new UnitPlanningCapacityDtoModel(
      json.totalHours,
      json.plannedHours,
      json.availableHours,
      json.totalProductiveHours,
      json.totalNonProductiveHours,
      (json.weekStartDate
        ? moment(json.weekStartDate)
          .parseZone()
        : null
      ),
      CapacityDtoModel.getAvailability(json.plannedHours, json.availableHours),
      json.hasHoliday,
      json.holidayDescription,
      json.holidayHours,
    );
  }

  static getAvailability(plannedHours: number, availableHours: number): number {
    return (plannedHours / availableHours) * 100;
  }

  clone(): UnitPlanningCapacityDtoModel {
    return new UnitPlanningCapacityDtoModel(
      this.totalHours,
      this.plannedHours,
      this.availableHours,
      this.totalProductiveHours,
      this.totalNonProductiveHours,
      this.weekStartDate != null ? this.weekStartDate.clone() : null,
      this.availability,
      this.hasHoliday,
      this.holidayDescription,
      this.holidayHours,
    );
  }

  toJSON(): UnitPlanningCapacityDto {
    return {
      totalHours: this.totalHours,
      plannedHours: this.plannedHours,
      availableHours: this.availableHours,
      totalProductiveHours: this.totalProductiveHours,
      totalNonProductiveHours: this.totalNonProductiveHours,
      weekStartDate: (this.weekStartDate != null ? this.weekStartDate.toJSON() : null),
      hasHoliday: this.hasHoliday,
      holidayDescription: this.holidayDescription,
      holidayHours: this.holidayHours,
    };
  }
}
