import { Selector } from '@ngxs/store';
import { TimesheetTotalsStateModel } from './timesheet-totals-state.model';
import { TimesheetTotalsState } from './timesheet-totals.state';

export class TimesheetTotalsSelectors {
  @Selector([TimesheetTotalsState])
  static selectedTotals(state: TimesheetTotalsStateModel): TimesheetTotalsStateModel['selectedTotals'] {
    return state.selectedTotals;
  }
}
