import { ProjectWorkpackageDto } from '../../interfaces/dtos/project-workpackage.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { ProjectWorkpackageTotalsDtoModel } from './project-planning-workpackage-totals.dto.model';
import { ProjectWorkpackageWeekDtoModel } from './project-planning-workpackage-week.dto.model';

export class ProjectPlanningWorkpackageDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly sequenceNumber: number,
    readonly title: string,
    readonly totals: ProjectWorkpackageTotalsDtoModel,
    readonly weeks: ProjectWorkpackageWeekDtoModel[],
  ) {
    if (totals) {
      this.totals = totals.clone();
    }
    if (weeks) {
      this.weeks = [...weeks];
    }
  }

  static fromJSON(json: ProjectWorkpackageDto): ProjectPlanningWorkpackageDtoModel {
    assertRequiredProperties(json, [
      'workPackageId',
    ]);

    const totals = ProjectWorkpackageTotalsDtoModel.fromJSON(json.totals);
    const weeks = (Array.isArray(json.weeks) ? json.weeks : [])
      .map(ProjectWorkpackageWeekDtoModel.fromJSON);

    return new ProjectPlanningWorkpackageDtoModel(
      json.workPackageId,
      json.sequenceNumber,
      json.title,
      totals,
      weeks,
    );
  }

  clone(): ProjectPlanningWorkpackageDtoModel {
    return new ProjectPlanningWorkpackageDtoModel(
      this.workPackageId,
      this.sequenceNumber,
      this.title,
      this.totals.clone() || null,
      this.weeks?.map((item) => item.clone()) || null,
    );
  }
}
