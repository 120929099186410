import { ContractDtoModel } from './contract.dto.model';
import { WorkpackageSupplierSettingsDto } from '../../interfaces/dtos/workpackageSupplierSettings.dto';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { MetadataModel } from '../metadata.model';

export class WorkpackageSupplierSettingsDtoModel {
  constructor(
    readonly additionalSupplierProjectTypeCode: string = ProjectTypeCode.SameAsSubmitter,
    readonly additionalSupplierProjectNumber: string | null = null,
    readonly additionalSupplierActivityItemCategoryNumber: string | null = null,
    public contracts: ContractDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.contracts = [...contracts];
  }

  static fromJSON(json: WorkpackageSupplierSettingsDto): WorkpackageSupplierSettingsDtoModel {
    // assertRequiredProperties(json, [
    //   'additionalSupplierProjectTypeCode',
    // ]);

    return new WorkpackageSupplierSettingsDtoModel(
      json.additionalSupplierProjectTypeCode,
      json.additionalSupplierProjectNumber,
      json.additionalSupplierActivityItemCategoryNumber,
      (Array.isArray(json.contracts) ? json.contracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  clone(): WorkpackageSupplierSettingsDtoModel {
    return new WorkpackageSupplierSettingsDtoModel(
      this.additionalSupplierProjectTypeCode,
      this.additionalSupplierProjectNumber,
      this.additionalSupplierActivityItemCategoryNumber,
      this.contracts.map((item) => item.clone()),
      this.metadata.clone(),
    );
  }

  toJSON(): WorkpackageSupplierSettingsDto {
    return {
      additionalSupplierProjectTypeCode: this.additionalSupplierProjectTypeCode,
      additionalSupplierProjectNumber: this.additionalSupplierProjectNumber,
      additionalSupplierActivityItemCategoryNumber: this.additionalSupplierActivityItemCategoryNumber,
      contracts: this.contracts.map((item) => item.toJSON()),
      metadata: this.metadata,
    };
  }
}
