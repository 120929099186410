import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { TimesheetRangeStateModel } from './timesheet-range-state.model';
import {
  InitTimesheetRangeSettings,
  ResetTimesheetRangeSettings,
  SaveTimesheetRangeSettings,
  SetTimesheetRangeSettings,
} from './timesheet-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultTimesheetRangeState: TimesheetRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<TimesheetRangeStateModel>({
  name: 'TimesheetRange',
  defaults: defaultTimesheetRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class TimesheetRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitTimesheetRangeSettings)
  initTimesheetRangeSettings(
    ctx: StateContext<TimesheetRangeStateModel>,
    { settings }: InitTimesheetRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetTimesheetRangeSettings)
  setTimesheetRangeSettings$(
    ctx: StateContext<TimesheetRangeStateModel>,
    { settings }: SetTimesheetRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveTimesheetRangeSettings(ctx.getState()));
  }

  @Action(ResetTimesheetRangeSettings)
  resetTimesheetRangeSettings(ctx: StateContext<TimesheetRangeStateModel>): void {
    ctx.setState(defaultTimesheetRangeState);
  }

  @Action(SaveTimesheetRangeSettings)
  saveTimesheetRangeSettings$(
    ctx: StateContext<TimesheetRangeStateModel>,
    { settings }: SaveTimesheetRangeSettings,
  ): Observable<void> {
    return this.userService.saveTimesheetRangeSettings$(settings);
  }
}
