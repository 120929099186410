<div>
  <header class="collapp-dialog__header">
    <h2 class="collapp-dialog__title">
      <ng-container>Add Additional Suppliers</ng-container>
    </h2>
    <button type="button" class="collapp-dialog__close" (click)="cancel()" >
      <span class="ic-close"></span>
    </button>
  </header>
  <mat-dialog-content class="wp-list-additional-suppliers-dialog__content">
    <form [formGroup]="form">
      <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
        <mat-form-field floatLabel="always" fxFlex>
          <mat-label>Supplier Unit</mat-label>
          <ng-select
            [required]="true"
            ngSelectMat
            [items]="suppliers"
            [bindLabel]="'name'"
            [formControl]="supplierControl"
            [appendTo]="'body'"
            [required]="true"
            >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-option-text [tooltip]="item.name">{{item.name}}</ng-option-text>
            </ng-template>
          </ng-select>
        </mat-form-field>
        <mat-form-field floatLabel="always" fxFlex>
          <mat-label>{{"common.hrc" | translate}}</mat-label>
          <ng-select
            class="multi-ngselect-special-width"
            ngSelectMat
            [items]="contractHourlyRateCategoryPairs"
            [bindLabel]="'supplierHourlyRateCategory'"
            [formControl]="supplierHourlyRateCategoryControl"
            [appendTo]="'body'"
            [required]="true"
            [multiple]="true"
            (open)="onOpen()"
            (close)="onClose()"
            >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-option-text [tooltip]="item | chrcPairTooltip">
                {{ isDropdownOpen ? (item | chrcPair) : item.supplierHourlyRateCategory}}
              </ng-option-text>
            </ng-template>
          </ng-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <footer class="collapp-dialog__footer">
    <span class="flex-spacer"></span>
    <button
      type="button"
      collapp-stroked-button
      (click)="cancel()"
      >
      @if (!form.dirty) {
        Close
      }
      @if (form.dirty) {
        Cancel
      }
    </button>
    <button
      [disabled]="!form.dirty || !form.valid || contractHourlyRateCategoryPairs.length === 0"
      type="submit"
      collapp-flat-button
      color="primary"
      (click)="add()"
      >
      Add
    </button>
  </footer>

</div>
