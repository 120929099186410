import { ProjectSelectorListResponse } from '../../interfaces/responses/project-selector-list.response';
import { ProjectSelectorDtoModel } from '../dtos/project-selector.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectSelectorListResponseModel {
  constructor(
    readonly items: readonly ProjectSelectorDtoModel[],
  ) {
    this.items = [...items];
  }

  static fromJSON(json: ProjectSelectorListResponse): ProjectSelectorListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new ProjectSelectorListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => ProjectSelectorDtoModel.fromJSON(item)),
    );
  }

  clone(): ProjectSelectorListResponseModel {
    return new ProjectSelectorListResponseModel(
      this.items.map((item) => item.clone()),
    );
  }

  toJSON(): ProjectSelectorListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
    };
  }
}
