import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'collapp-badge',
  templateUrl: './collapp-badge.component.html',
  styleUrls: ['./collapp-badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CollAppBadgeComponent {
  @Input() text: string = '';

  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
