import { ApiModelNew } from '../types';
import { QuestionDashboardListResponse } from '../../interfaces/responses/question-dashboard-list.response';
import { QuestionDashboardDtoModel } from '../dtos/question-dashboard.dto.model';
import { Metadata } from '../../interfaces/metadata';

export class QuestionDashboardListResponseModel implements ApiModelNew {
  constructor(
    readonly items: readonly QuestionDashboardDtoModel[],
    readonly metadata: Metadata,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: QuestionDashboardListResponse): QuestionDashboardListResponseModel {
    return new QuestionDashboardListResponseModel(
      json.items
        ? json.items.map((item) => QuestionDashboardDtoModel.fromJSON(item))
        : [],
      json.metadata || {},
    );
  }

  toJSON(): QuestionDashboardListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: { ...this.metadata },
    };
  }

  clone(overrides?: Partial<QuestionDashboardListResponseModel>): QuestionDashboardListResponseModel {
    return new QuestionDashboardListResponseModel(
      overrides && overrides.items
        ? overrides.items || []
        : this.items.map((item) => item.clone()),
      ({
        ...(overrides && overrides.metadata
          ? overrides.metadata
          : this.metadata),
      }),
    );
  }
}
