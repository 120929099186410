import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CollappFilterDropdownListRadioSetting } from '../../models/collapp-filter-dropdown-list-radio.interface';
import { UnitPlanningViewResponseModel2 } from '../models/responses/unit-planning-view.response.model2';
import { TimesheetViewResponseModel } from '../models/responses/timesheet-view.response.model';
import { CollAppApiService } from './collapp-api.service';
import { TimesheetViewResponse } from '../interfaces/responses/timesheet-view.response';
import { TimeTrackingWeekNavigatorViewResponseModel } from '../models/responses/time-tracking-week-navigator-view.response.model';
import { TimeTrackingWeekNavigatorViewResponse } from '../interfaces/responses/time-tracking-week-navigator-view.response';
import { TimecardSaveRequestModel } from '../models/requests/timecard-save.request.model';
import { TimecardStatusUpdateRequestModel } from '../models/requests/timecard-status-update.request.model';
import { TimecardViewResponseModel } from '../models/responses/timecard-view.response.model';
import { TimecardViewResponse } from '../interfaces/responses/timecard-view.response';
import { FileDownloadResponseModel } from '../models/responses/file-download.response.model';
import { FileDownloadResponse } from '../interfaces/responses/file-download.response';
import { UserPlanningViewResponseModel } from '../models/responses/user-planning-view.response.model';
import { UserPlanningViewResponse } from '../interfaces/responses/user-planning-view.response';
import { PlanningSaveRequestModel } from '../models/requests/planning-save.request.model';
import { UnitPlanningViewResponse } from '../interfaces/responses/unit-planning-view.response';
import { UnitPlanningViewResponseModel } from '../models/responses/unit-planning-view.response.model';
import { NonHumanResourcePlanningViewResponseModel } from '../models/responses/non-human-resource-planning-view.response.model';
import { NonHumanResourcePlanningViewResponse } from '../interfaces/responses/non-human-resource-planning-view.response';
import { UnitPlanningViewRequestModel } from '../models/requests/unit-planning-view.request.model';
import { TimesheetExportViewType } from 'src/app/modules/timesheet/timesheet.component';

@Injectable({
  providedIn: 'root',
})
export class TimeTrackingService {
  constructor(private apiService: CollAppApiService) {}

  /**
   * Fetches information about the work weeks in the Time Card WeekNavigator,
   * with an amount of weeks from the given week and year.
   * The amount can be a positive or negative Week Number.
   *
   * @param userId
   * @param year
   * @param week
   * @param amount
   */
  getTimecardWeeks$(
    userId: string,
    year: number,
    week: number,
    amount: number,
  ): Observable<TimeTrackingWeekNavigatorViewResponseModel> {
    return this.apiService
      .getTimecardWeeks$(userId, year, week, amount)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        // eslint-disable-next-line max-len
        map((response: HttpResponse<TimeTrackingWeekNavigatorViewResponse>) => TimeTrackingWeekNavigatorViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Updates a timecard of a user
   *
   * @return {Observable<void>}
   */
  putTimecard$(
    userId: string,
    year: number,
    week: number,
    request: TimecardSaveRequestModel,
  ): Observable<FileDownloadResponse | null> {
    return this.apiService
      .putTimecard$(userId, year, week, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => response.body),
      );
  }

  /**
   * Confirms the planning of a user
   *
   * @return {Observable<void>}
   */
  confirmUserPlanning$(userId: string, request: PlanningSaveRequestModel): Observable<void> {
    return this.apiService
      .confirmUserPlanning$(userId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Cancels the planning of a user
   *
   * @return {Observable<void>}
   */
  cancelUserPlanning$(userId: string, request: PlanningSaveRequestModel): Observable<void> {
    return this.apiService
      .cancelUserPlanning$(userId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Saves the planning of a NHR
   *
   * @return {Observable<void>}
   */
  saveNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequestModel,
  ): Observable<void> {
    return this.apiService
      .saveNonHumanResourcePlanning$(nonHumanResourceId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Confirms the planning of a NHR
   *
   * @return {Observable<void>}
   */
  confirmNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequestModel,
  ): Observable<void> {
    return this.apiService
      .confirmNonHumanResourcePlanning$(nonHumanResourceId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Cancels the planning of a NHR
   *
   * @return {Observable<void>}
   */
  cancelNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    request: PlanningSaveRequestModel,
  ): Observable<void> {
    return this.apiService
      .cancelNonHumanResourcePlanning$(nonHumanResourceId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates the statuses of timecards from users.
   */
  putTimecardStatus$(
    year: number,
    week: number,
    request: TimecardStatusUpdateRequestModel,
  ): Observable<FileDownloadResponse | null> {
    return this.apiService
      .putTimecardStatus$(year, week, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => response.body),
      );
  }

  /**
   * Gets a time card of a specific user
   *
   * @param userId
   * @param year
   * @param week
   */
  getTimecard$(
    userId: string,
    year: number,
    week: number,
    isMyTimecard: boolean,
    timecardFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<TimecardViewResponseModel> {
    return this.apiService
      .getTimecard$(userId, year, week, isMyTimecard, timecardFilters)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<TimecardViewResponse>) => TimecardViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets planning of a specific user
   */
  getUserPlanning$(
    userId: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks?: number,
    headerFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<UserPlanningViewResponseModel> {
    return this.apiService
      .getUserPlanning$(userId, isoWeekYear, isoWeek, numberOfWeeks, headerFilters)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserPlanningViewResponse>) => UserPlanningViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets planning of a specific nonHumanResource
   */
  getNonHumanResourcePlanning$(
    nonHumanResourceId: string,
    isoWeekYear: number,
    isoWeek: number,
    numberOfWeeks?: number,
    headerFilters?: CollappFilterDropdownListRadioSetting[],
  ): Observable<NonHumanResourcePlanningViewResponseModel> {
    return this.apiService
      .getNonHumanResourcePlanning$(nonHumanResourceId, isoWeekYear, isoWeek, numberOfWeeks, headerFilters)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<NonHumanResourcePlanningViewResponse>) => NonHumanResourcePlanningViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets planning of a specific unit
   */
  getUnitPlanning$(
    unitId: number,
    request: UnitPlanningViewRequestModel,
  ): Observable<UnitPlanningViewResponseModel> {
    return this.apiService
      .getUnitPlanning$(unitId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitPlanningViewResponse>) => UnitPlanningViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets planning of a specific unit
   */
  getUnitPlanning2$(
    unitId: number,
    request: UnitPlanningViewRequestModel,
  ): Observable<UnitPlanningViewResponseModel2> {
    return this.apiService
      .getUnitPlanning$(unitId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitPlanningViewResponse>) => UnitPlanningViewResponseModel2
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a timesheet of a unit.
   */
  getTimesheet$(
    unitId: number,
    year: number,
    week: number,
    withCrossUtilize: boolean,
    isSubmitterView: boolean | undefined,
  ): Observable<TimesheetViewResponseModel> {
    return this
      .apiService
      .getTimesheet$(unitId, year, week, withCrossUtilize, isSubmitterView)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<TimesheetViewResponse>) => TimesheetViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of weeks for the timesheet week navigator.
   *
   * @param unitId
   * @param year
   * @param week
   * @param amount
   */
  getTimesheetWeeks$(
    unitId: number,
    year: number,
    week: number,
    amount: number,
  ): Observable<TimeTrackingWeekNavigatorViewResponseModel> {
    return this.apiService
      .getTimesheetWeeks$(unitId, year, week, amount)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        // eslint-disable-next-line max-len
        map((response: HttpResponse<TimeTrackingWeekNavigatorViewResponse>) => TimeTrackingWeekNavigatorViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Generates an Excel file export of the given timesheet.
   */
  getTimesheetExport$(
    unitId: number,
    year: number,
    week: number,
    withCrossUtilize: boolean,
    templateView: TimesheetExportViewType,
  ): Observable<FileDownloadResponseModel> {
    return this.apiService
      .getTimesheetExport$(unitId, year, week, withCrossUtilize, templateView)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => FileDownloadResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Generates an Excel file export of the given timesheet.
   */
  getTimecardExport$(userId: string, year: number, week: number): Observable<FileDownloadResponseModel> {
    return this.apiService
      .getTimecardExport$(userId, year, week)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => FileDownloadResponseModel.fromJSON(response.body!)),
      );
  }
}
