/* eslint-disable max-classes-per-file */
/* eslint-disable complexity */
import {
  Component,
  DoCheck,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, FormGroupDirective, NgControl, NgForm,
} from '@angular/forms';
import {
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinErrorState,
} from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AsyncSubject,
  BehaviorSubject,
  combineLatest,
  concat,
  merge,
  Observable,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import moment from 'moment';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
  debounceTime,
  expand,
  filter,
  finalize,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TypedFormGroup } from '../../form/typed-form-group';
import { TypedFormBuilder } from '../../form/typed-form-builder';
import { AttachmentListSelectors } from '../../state/settings/attachment-list/attachment-list.selectors';
import { LIST_COLUMNS_FILTER_CHANGE_DEBOUNCE_TIME } from '../../shared/constants';
import { SaveAttachmentListOptions } from '../../state/settings/attachment-list';
import { UserSlimDtoModel } from '../../api/models/dtos/user-slim.dto.model';
import { ProjectService } from '../../api/services/project.service';
import { AzureBlobService, AzureBlobUploadResponse } from '../../services/azure-blob.service';
import { AttachmentCreateRequestModel } from '../../api/models/requests/attachment-create.request.model';
import { pathInfo } from '../../helpers/path.utility';
import { AttachmentSlimDtoModel } from '../../api/models/dtos/attachment-slim.dto.model';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { formatCollAppUser, MomentPipe } from '../../collapp-common';
import { CollappDateAdapter } from '../../collapp-core';
import {
  OutdateAttachmentDialogComponent,
} from './components/outdate-attachment-dialog';
import { AttachmentSlimDtoMetadata } from '../../api/interfaces/metadata';
import { getFileExtensionFromFilename } from '../../helpers/file-type.utility';
import { DocumentType } from '../../models/document-type.enum';
import { MetadataModel } from '../../api/models/metadata.model';
import { AttachmentColumnFilters } from '../../api/filter-columns';
import { attachmentsListColumnsWithFilters } from '../../api/meta-info/attachments-list.info';
import { isEmpty } from '../../helpers/utilities';

// @TODO Limitation of the API
const ATTACHMENT_NAME_MAX_CHARS: number = 254;
const BLOB_NAME_MAX_CHARS: number = 254;

const ATTACHMENT_STATE_OUTDATED = 'Outdated';
const ATTACHMENT_STATE_CURRENT = 'Active';
const ATTACHMENT_STATE_ALL = 'All';

/**
 * The state a displayed attachment can be in.
 */
export enum FileAttachmentState {
  DEFAULT,
  OUTDATED,
  NEW,
}

/**
 * The current state of the upload.
 */
export enum FormAttachmentFileStatus {
  PENDING = 'pending',
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  REMOVED = 'removed',
}

export interface FormAttachmentsTableData {
  fileName: string;
  fileType: string;
  fileSize: number;
  uploader?: UserSlimDtoModel | null;
  uploadedOn?: moment.Moment | null;
  status: FormAttachmentFileStatus;
}

export class ExistingFormAttachmentFile extends AttachmentSlimDtoModel implements FormAttachmentsTableData {
  status: FormAttachmentFileStatus;

  url: string | null;

  get state(): FileAttachmentState {
    return (this.outdatedOn != null
      ? FileAttachmentState.OUTDATED
      : FileAttachmentState.DEFAULT
    );
  }

  protected readonly _canOutdateAttachment: boolean;

  constructor(
    attachmentId: string,
    name: string,
    type: string,
    size: number,
    url: string | null,
    uploader: UserSlimDtoModel | null,
    uploadedOn: moment.Moment | null,
    outdater: UserSlimDtoModel | null,
    outdatedOn: moment.Moment | null,
    outdatedComment: string | null,
    status: FormAttachmentFileStatus,
    questionId: number | null,
    metadata: MetadataModel<AttachmentSlimDtoMetadata>,
  ) {
    super(
      attachmentId,
      DocumentType.Project, // Just set something
      name,
      type,
      size,
      uploader,
      uploadedOn || moment(),
      outdater,
      outdatedOn,
      outdatedComment,
      null, // projectId,
      null, // workPackageId,
      questionId,
      metadata,
    );

    this.url = url;
    this.status = (status != null ? status : FormAttachmentFileStatus.UPLOADED);

    this._canOutdateAttachment = !!(this.metadata.fields?.canOutdateAttachment);
  }

  canOutdateAttachment(): boolean {
    return this._canOutdateAttachment;
  }

  // eslint-disable-next-line complexity
  clone(overrides?: Partial<Omit<ExistingFormAttachmentFile, 'toJSON' | 'clone'>>): ExistingFormAttachmentFile {
    // FIXME implement all overrides
    return new ExistingFormAttachmentFile(
      this.attachmentId,
      this.name,
      this.type,
      this.size,
      this.url,
      overrides?.uploader?.clone() ?? this.uploader?.clone() ?? null,
      overrides?.uploadedOn?.clone() ?? this.uploadedOn?.clone() ?? null,
      overrides?.outdater?.clone() ?? this.outdater?.clone() ?? null,
      overrides?.outdatedOn?.clone() ?? this.outdatedOn?.clone() ?? null,
      overrides?.outdatedComment ?? this.outdatedComment ?? null,
      overrides?.status ?? this.status,
      this.questionId,
      this.metadata.clone(),
    );
  }
}

export class NewFormAttachmentFile implements FormAttachmentsTableData {
  /**
   * @deprecated
   */
  get fileName(): string { return this.name; }

  /**
   * @deprecated
   */
  get fileType(): string { return this.type; }

  /**
   * @deprecated
   */
  get fileSize(): number { return this.size; }

  /**
   * @deprecated
   */
  get fileLastModified(): number { return this.lastModified; }

  get uploadProgress(): number { return this._uploadProgress; }

  set uploadProgress(value: number) {
    this._uploadProgress = value;
    this._uploadProgressPercent = Math.round(value * 100);
  }

  get uploadProgressPercent(): number { return this._uploadProgressPercent; }

  get state(): FileAttachmentState { return FileAttachmentState.NEW; }

  private _uploadProgress: number = 0;

  private _uploadProgressPercent: number = 0;

  constructor(
    public file: File | null,
    public attachmentFile: AttachmentCreateRequestModel | null,
    readonly name: string,
    readonly type: string,
    readonly size: number,
    readonly lastModified: number,
    public uploader: UserSlimDtoModel | null = null,
    public uploadedOn: moment.Moment | null = null,
    public outdater: UserSlimDtoModel | null = null,
    public outdatedOn: moment.Moment | null = null,
    public outdatedComment: string | null = null,
    public status: FormAttachmentFileStatus = FormAttachmentFileStatus.PENDING,
  ) {
  }

  canOutdateAttachment(): boolean {
    return false;
  }
}

export type FormAttachmentFile = NewFormAttachmentFile | ExistingFormAttachmentFile;

// Boilerplate for applying mixins to FormAttachmentsComponent.
export class FormAttachmentsComponentBase {
  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
    public stateChanges: Subject<void>
  ) {}
}
// eslint-disable-next-line no-underscore-dangle
export const _FormAttachmentsComponentBase = mixinErrorState(FormAttachmentsComponentBase);

type AttachmentListTableColumnNames = 'fileName'
| 'fileType'
| 'fileSize'
| 'uploadedOn'
| 'uploader'
| 'markAsOutdated'
| 'uploadedIndication'
| 'actions';

@Component({
  selector: 'collapp-form-attachments',
  templateUrl: './form-attachments.component.html',
  styleUrls: ['./form-attachments.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FormAttachmentsComponent }],
})
export class FormAttachmentsComponent
  extends _FormAttachmentsComponentBase
  implements MatFormFieldControl<AttachmentCreateRequestModel[]>,
  ControlValueAccessor,
  CanUpdateErrorState,
  DoCheck,
  OnInit,
  OnDestroy {
  static nextId: number = 0;

  // === MatFormFieldControl ===

  /** The value of the control. */
  get value(): AttachmentCreateRequestModel[] {
    return this._newFiles;
  }

  @Input()
  set value(value: AttachmentCreateRequestModel[]) {
    this.writeValue(value);
  }

  /**
   * Shows a loading indicator if it is set to true
   */
  @Input()
  loading: boolean = false;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `MatFormField`
   * needs to run change detection.
   *
   * Already set through errorState mixin.
   */
  // public stateChanges: Subject<void> = new Subject();

  /** The element ID for this control. */
  @Input()
  id: string = `collapp-form-attachments-${FormAttachmentsComponent.nextId++}`; // eslint-disable-line no-plusplus

  @HostBinding('id')
  get hostId(): string {
    return this.id;
  }

  /** The placeholder for this control. */
  get placeholder(): string { return this._placeholder; }

  @Input()
  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this.stateChanges.next();
    }
  }

  /**
   * Whether the input is currently in an autofilled state. If property is not present on the
   * control it is assumed to be false.
   */
  @Input()
  autofilled: boolean = false;

  // === /MatFormFieldControl ===

  /** An object used to control when error messages are shown. */
  @Input()
  errorStateMatcher!: ErrorStateMatcher;

  // === MatFormFieldControl additional custom ===

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';

  @Input() allowDownloadAll: boolean = false;

  /**
   * Triggers when file is dropped in zone
   * @type {EventEmitter<NewFormAttachmentFile>}
   */
  @Output()
  readonly dropped: EventEmitter<NewFormAttachmentFile> = new EventEmitter<NewFormAttachmentFile>();

  /**
   * Triggers when file has completed uploading to the blob storage
   * @type {EventEmitter<ExistingFormAttachmentFile>}
   */
  @Output()
  readonly uploadComplete: EventEmitter<NewFormAttachmentFile> = new EventEmitter<NewFormAttachmentFile>();

  /**
   * Triggers when file is marked as removed
   * @type {EventEmitter<ExistingFormAttachmentFile>}
   */
  @Output()
  readonly markedForRemoval: EventEmitter<ExistingFormAttachmentFile> = new EventEmitter();

  /**
   * Triggers when the file isn't marked as removed anymore
   * @type {EventEmitter<ExistingFormAttachmentFile>}
   */
  @Output()
  readonly unmarkedForRemoval: EventEmitter<ExistingFormAttachmentFile> = new EventEmitter();

  @Output()
  readonly removedFiles: EventEmitter<ExistingFormAttachmentFile[]> = new EventEmitter();

  /**
   * Triggers when a file is outdated
   */
  @Output()
  readonly outdatedAttachment: EventEmitter<void> = new EventEmitter();

  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  /** Gets the NgControl for this control. */
  // public ngControl: NgControl | null;

  /** Whether the control is focused. */
  focused: boolean = false;

  /** Whether the control is empty. */
  get empty(): boolean {
    return (this._newFiles.length === 0);
  }

  /** Whether the `MatFormField` label should try to float. */
  @HostBinding('class.mat-form-field-should-float')
  get shouldLabelFloat(): boolean {
    return true;
  }

  /** Whether the control is required. */
  get required(): boolean { return this._required; }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  /** Whether the control is disabled. */
  get disabled(): boolean { return this._disabled; }

  @Input()
  set disabled(value: boolean) {
    this.setDisabledState(coerceBooleanProperty(value));
    this.stateChanges.next();
  }

  /** Whether the outdating functionality should be disabled */
  get attachmentOutdatingDisabled(): boolean { return this._attachmentsOutdatingDisabled; }

  @Input()
  set attachmentOutdatingDisabled(allow: boolean) {
    this._attachmentsOutdatingDisabled = coerceBooleanProperty(allow);
  }

  /** To disallow the removing of the existing */
  get existingAttachmentsRemovalDisabled(): boolean { return this._existingAttachmentsRemovalDisabled; }

  @Input()
  set existingAttachmentsRemovalDisabled(allow: boolean) {
    this._existingAttachmentsRemovalDisabled = coerceBooleanProperty(allow);
  }

  get hideFilters(): boolean { return this._hideFilters; }

  @Input()
  set hideFilters(value: boolean) {
    this._hideFilters = coerceBooleanProperty(value);
  }

  /**
   * Whether the control is in an error state.
   *
   * Already set through errorState mixin.
   */
  // public errorState: boolean;

  /**
   * An optional name for the control type that can be used to distinguish `mat-form-field` elements
   * based on their control type. The form field will add a class,
   * `mat-form-field-type-{{controlType}}` to its root element.
   */
  controlType: string = 'attachments';

  // === /MatFormFieldControl additional custom ===

  // === custom controls ===

  get existingFiles(): ExistingFormAttachmentFile[] { return this._existingAttachmentsSubject$.value; }

  @Input()
  set existingFiles(value: ExistingFormAttachmentFile[]) {
    const existingFiles = (value || []).map((item) => new ExistingFormAttachmentFile(
      item.attachmentId,
      item.fileName,
      item.fileType,
      item.fileSize,
      item.url,
      (item.uploader ? item.uploader.clone() : null),
      (item.uploadedOn != null ? item.uploadedOn.clone() : null),
      (item.outdater != null ? item.outdater.clone() : null),
      (item.outdatedOn != null ? item.outdatedOn.clone() : null),
      item.outdatedComment,
      FormAttachmentFileStatus.UPLOADED,
      // TODO: This a temporary quick fix to allow to jump to questions.
      item.questionId || null,
      item.metadata.clone(),
    ));

    this._existingAttachmentsSubject$.next(existingFiles);
  }

  get allowDuplicates(): boolean { return this._allowDuplicates; }

  @Input()
  set allowDuplicates(value: boolean) {
    this._allowDuplicates = coerceBooleanProperty(value);
  }

  get combinedFileSize(): number { return this._combinedFileSize; }

  get uploader(): UserSlimDtoModel | null { return this._uploader; }

  @Input()
  set uploader(value: UserSlimDtoModel | null) {
    this._uploader = value;
  }

  get uploadsPending(): boolean { return this.newAttachmentUploads.size > 0; }

  dataSource: MatTableDataSource<FormAttachmentFile> = new MatTableDataSource();

  readonly displayedColumns: readonly AttachmentListTableColumnNames[] = [
    'fileName',
    'fileType',
    'fileSize',
    'uploadedOn',
    'uploader',
    'markAsOutdated',
    'uploadedIndication',
    'actions',
  ];

  /**
   * Be aware that you should check sorting of the filter columns when changing the displayed columns!
   */
  readonly displayedFilterColumns: readonly string[] = [
    'fileNameFilter',
    'fileTypeFilter',
    'fileSizeFilter',
    'uploadedOnFilter',
    'uploaderFilter',
    'markAsOutdatedFilter',
  ];

  attachmentStatus: typeof FormAttachmentFileStatus = FormAttachmentFileStatus;

  get existingAttachmentsCount(): number { return this._existingAttachmentsSubject$.value.length; }

  get newAttachmentsCount(): number { return this._newAttachmentsSubject$.value.length; }

  get allAttachmentsCount(): number { return this.existingAttachmentsCount + this.newAttachmentsCount; }

  get filteredAttachmentsCount(): number { return this.dataSource.filteredData.length; }

  attachmentListFilterForm: TypedFormGroup<AttachmentColumnFilters>;

  get outdatedFilterControl(): UntypedFormControl {
    return this.attachmentListFilterForm.controls.outdater as UntypedFormControl;
  }

  outdatedStateList: readonly string[] = [
    ATTACHMENT_STATE_OUTDATED,
    ATTACHMENT_STATE_CURRENT,
    ATTACHMENT_STATE_ALL,
  ];

  newAttachments$: Observable<readonly NewFormAttachmentFile[]>;

  existingAttachments$: Observable<readonly ExistingFormAttachmentFile[]>;

  allAttachments$: Observable<readonly FormAttachmentFile[]>;

  filteredAttachments$: Observable<readonly FormAttachmentFile[]>;

  // === /custom controls ===

  private _placeholder: string = '';

  private _required: boolean = false;

  private _disabled: boolean = false;

  private _attachmentsOutdatingDisabled: boolean = false;

  private _existingAttachmentsRemovalDisabled: boolean = false;
  // === custom controls ===

  private _allowDuplicates: boolean = false;

  private _hideFilters: boolean = true;

  private _combinedFileSize: number = 0;

  private _uploader: UserSlimDtoModel | null = null;

  private _newFiles: AttachmentCreateRequestModel[] = [];

  private newAttachmentUploads: Map<NewFormAttachmentFile, Subscription> = new Map();

  private existingAttachmentUrlRenewals: Map<ExistingFormAttachmentFile, Subscription> = new Map();

  private _filtersChange$: Observable<Partial<AttachmentColumnFilters>>;

  private _newAttachmentsSubject$: BehaviorSubject<NewFormAttachmentFile[]> = new BehaviorSubject([]);

  private _existingAttachmentsSubject$: BehaviorSubject<ExistingFormAttachmentFile[]> = new BehaviorSubject([]);

  /** Dialog for outdating an attachment */
  private outdateAttachmentDialogRef: MatDialogRef<OutdateAttachmentDialogComponent> | null = null;

  private destroyed$: Subject<void> = new Subject();

  // === /custom controls ===

  /**
   * @see https://angular.io/api/forms/ControlValueAccessor
   */
  // eslint-disable-next-line max-lines-per-function
  constructor(
  @Optional() @Self() ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private azureBlobService: AzureBlobService,
    private errorHandlerService: ErrorHandlerService,
    private dateAdapter: CollappDateAdapter,
    private momentPipe: MomentPipe,
    private _formBuilder: UntypedFormBuilder,
    private store: Store
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl, new Subject<void>());

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.dataSource.sortingDataAccessor = (
      item: FormAttachmentFile,
      property: AttachmentListTableColumnNames,
    ): string | number => {
      switch (property) {
        case 'fileName':
          return item.fileName.toLowerCase();
        case 'fileType':
          // UpperCase File Extensions will be not proper sorted.
          return getFileExtensionFromFilename(item.fileName);
        case 'fileSize':
          return item.fileSize;
        case 'uploadedIndication':
        case 'uploadedOn':
          return (item.uploadedOn ? +item.uploadedOn : 0);
        case 'uploader':
          return (item.uploader ? item.uploader.fullName : '');
        // 'markAsOutdated' aka 'status'
        case 'markAsOutdated':
          if (this.isNewFormAttachmentFile(item)) {
            return 2;
          }

          if (this.isAttachmentOutdated(item)) {
            return 0;
          }

          return 1;
        default:
          return 0;
      }
    };

    const formBuilder = _formBuilder as TypedFormBuilder;

    this.attachmentListFilterForm = formBuilder
      .group<AttachmentColumnFilters>(
      attachmentsListColumnsWithFilters
        .reduce((obj: any, value: string) => ({ ...obj, [value]: [''] }), {}),
    );

    this.existingAttachments$ = this._existingAttachmentsSubject$.asObservable();
    this.newAttachments$ = this._newAttachmentsSubject$.asObservable();

    /*
     * All files of the view (existing and new) as a shared observable.
     */
    this.allAttachments$ = combineLatest([
      this.existingAttachments$,
      this.newAttachments$,
    ])
      .pipe(
        map(([existingFiles, newFiles]) => [
          ...existingFiles,
          ...newFiles,
        ]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );

    /*
     * In case the filter form changes, we normalize the filter values for future use, i.e. removing
     * empty values.
     * Do not directly listen to the filter form value changes but use this observable here.
     * No debounce time has been added here since this is only used when persisting the
     * filter settings in `ngOnInit()`.
     */
    this._filtersChange$ = this.attachmentListFilterForm.valueChanges
      .pipe(
        map((filterForm: Partial<AttachmentColumnFilters>) => this.sanitizeFilters(filterForm)),
        takeUntil(this.destroyed$),
      );

    /*
     * The filtered files are all files (existing and new) with the filters from the filter form applied.
     */
    this.filteredAttachments$ = merge(
      this.allAttachments$,
      this._filtersChange$,
    )
      .pipe(
        map(() => this.getColumnFilters()),
        withLatestFrom(this.allAttachments$),
        map(([filters, allFiles]) => allFiles.filter((file) => {
          let included = true;

          if (filters.outdater) {
            switch (filters.outdater) {
              case ATTACHMENT_STATE_OUTDATED:
                included = file.outdater != null;
                break;
              case ATTACHMENT_STATE_CURRENT:
                included = file.outdater == null;
                break;
              default:
                break;
            }
          }

          return included;
        })),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    const options = this.store.selectSnapshot(AttachmentListSelectors.options);
    this.sort.active = options.sortColumn;
    this.sort.direction = options.sortDirection;

    this.dataSource.sort = this.sort;
    this.attachmentListFilterForm.patchValue(options.columnFilters);

    /*
     * If the existing files change, we need to do some housekeeping with our subscriptions
     * for generating the Azure Blob Storage file download URLs since they can expire and / or
     * we don't want to keep subscriptions for files that have been removed.
     */
    this.existingAttachments$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((existingFiles) => {
        const urlRenewalAttachmentsToBeRemoved: Set<ExistingFormAttachmentFile> = new Set(
          this.existingAttachmentUrlRenewals ? this.existingAttachmentUrlRenewals.keys() : [],
        );

        existingFiles.forEach((existingFile) => {
          if (urlRenewalAttachmentsToBeRemoved.has(existingFile)) {
            // Remove previously existing attachment renewals, leaving only obsolete attachments.
            urlRenewalAttachmentsToBeRemoved.delete(existingFile);
          } else {
            // Fetch the access restricted attachment url and
            // keep refreshing it before its `validUntil` is reached.
            const subscription = this.getAttachmentDownloadUrlRenewalSubscription(existingFile);
            this.existingAttachmentUrlRenewals.set(existingFile, subscription);
          }
        });

        // Everything left in `urlRenewalAttachmentsToBeRemoved` can be removed.
        urlRenewalAttachmentsToBeRemoved.forEach((attachment) => {
          const subscription = this.existingAttachmentUrlRenewals.get(attachment);
          if (subscription) {
            subscription.unsubscribe();
          }
          this.existingAttachmentUrlRenewals.delete(attachment);
        });
      });

    /*
     * If a new attachment file upload completed, refresh
     * the value of this control.
     */
    merge(
      this.newAttachments$,
      this.uploadComplete,
    )
      .pipe(
        withLatestFrom(this.newAttachments$),
        map(([_, files]) => files
          .map((file) => file.attachmentFile)
          .filter((file): file is AttachmentCreateRequestModel => file != null)),
        takeUntil(this.destroyed$),
      )
      .subscribe((files) => {
        if (files.length > 0) {
          this._newFiles = files;
          this._onChange(this.value);
        }
      });

    /*
     * If the files that should be displayed in the view change, we need to
     *  1) set the data source of the table to the current list of files
     *  2) re-calculate the total file size.
     */
    this.filteredAttachments$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((files) => {
        this.dataSource.data = [...files];
        this.calculateTotalFileSize(files);
        // this._onChange(this.value);
      });

    /*
     * Automatically trigger the `removedFiles` EventEmitter when a file has been marked or
     * unmarked for removal.
     */
    merge(
      this.markedForRemoval,
      this.unmarkedForRemoval,
    )
      .pipe(
        withLatestFrom(this.existingAttachments$),
        map(([_, existingFiles]) => existingFiles.filter((file) => file.status === FormAttachmentFileStatus.REMOVED)),
        takeUntil(this.destroyed$),
      )
      .subscribe((removedFiles) => {
        this.removedFiles.emit(removedFiles);
      });

    /*
     * In case the user settings for the attachment list change (filters, sorting), the
     * new settings should be persisted.
     */
    merge(
      this._filtersChange$,
      this.sort.sortChange,
    )
      .pipe(
        debounceTime(LIST_COLUMNS_FILTER_CHANGE_DEBOUNCE_TIME),
        map(() => {
          const columnFilters = this.getColumnFilters();
          const sortColumn = this.sort.active;
          const sortDirection = this.sort.direction;

          return {
            columnFilters,
            sortColumn,
            sortDirection,
          };
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(({ columnFilters, sortColumn, sortDirection }) => {
        // Fire & forget
        // eslint-disable-next-line rxjs/no-ignored-observable
        this.store.dispatch(new SaveAttachmentListOptions({
          pageIndex: 0,
          pageSize: 0,
          columnFilters,
          sortColumn,
          sortDirection,
        }));
      });
  }

  ngOnDestroy(): void {
    this._existingAttachmentsSubject$.complete();
    this._newAttachmentsSubject$.complete();
    this.stateChanges.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  // === ControlValueAccessor ===

  /**
   * Writes a new value to the element.
   *
   * This method is called by the forms API to write to the view when programmatic
   * changes from model to view are requested.
   *
   * @param obj The new value for the element
   */
  // eslint-disable-next-line max-lines-per-function
  writeValue(
    obj: AttachmentCreateRequestModel
    | AttachmentCreateRequestModel[]
    | AttachmentSlimDtoModel
    | AttachmentSlimDtoModel[],
  ): void {
    const wasPristine = (this.ngControl ? this.ngControl.pristine : true);
    const files = (Array.isArray(obj) ? obj : [obj]);

    const newFormAttachmentFiles: NewFormAttachmentFile[] = [];
    const existingFormAttachmentFiles: ExistingFormAttachmentFile[] = [];

    let unmappableFilesCount = 0;

    files.forEach((file) => {
      if (file instanceof AttachmentCreateRequestModel) {
        // I don't know why the obj can be such a instance
        return;
      }
      if (file instanceof AttachmentSlimDtoModel) {
        existingFormAttachmentFiles.push(new ExistingFormAttachmentFile(
          file.attachmentId,
          file.name,
          file.type,
          file.size,
          null,
          (file.uploader ? file.uploader.clone() : null),
          (file.uploadedOn ? file.uploadedOn.clone() : null),
          null,
          null,
          null,
          FormAttachmentFileStatus.UPLOADED,
          // TODO: This a temporary quick fix to allow to jump to questions.
          file.questionId,
          file.metadata.clone(),
        ));
      } else {
        unmappableFilesCount += 1;
      }
    });

    if (existingFormAttachmentFiles.length > 0) {
      this._existingAttachmentsSubject$.next(existingFormAttachmentFiles);
    }
    this._newAttachmentsSubject$.next(newFormAttachmentFiles);

    // We 'reset' the value and notify the parent form of the changes while still keeping the formControl pristine.
    if (
      this.ngControl
      && wasPristine
      && (
        newFormAttachmentFiles.length > 0
        || existingFormAttachmentFiles.length > 0
        || unmappableFilesCount > 0
      )
    ) {
      this.ngControl.reset(this.value);
    }

    this.stateChanges.next();
  }

  /**
   * Registers a callback function that is called when the control's value
   * changes in the UI.
   *
   * This method is called by the forms API on initialization to update the form
   * model when values propagate from the view to the model.
   *
   * @param fn The callback function to register
   */
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization
   * to update the form model on blur.
   *
   * @param fn The callback function to register
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Function that is called by the forms API when the control status changes to
   * or from 'DISABLED'. Depending on the status, it enables or disables the
   * appropriate DOM element.
   *
   * @param isDisabled The disabled status to set on the element
   */
  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  // === /ControlValueAccessor ===

  // === MatFormFieldControl ===

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(): void {
    if (!this.disabled && !this.focused) {
      // this.focused = true;
    }
  }

  // === /MatFormFieldControl ===

  // === Binding to ngx-file-drop ===

  /**
   * Creates an attachment out of the dropped file
   * and adds it to the list of to-be-uploaded files (FormData)
   */
  onDrop(droppedFiles: NgxFileDropEntry[]): void {
    const observables: AsyncSubject<File>[] = [];

    droppedFiles.forEach((droppedFile) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        const subject$ = new AsyncSubject<File>();
        observables.push(subject$);
        fileEntry.file((file: File) => {
          subject$.next(file);
          subject$.complete();
        });
      }
    });

    concat(...observables)
      .pipe(
        finalize(() => {
          this.startNewFileUploads();
          // Set status column filter to "Active" when a file is added
          this.attachmentListFilterForm.patchValue({ outdater: ATTACHMENT_STATE_CURRENT });
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (file) => {
          this.addFile(file);
        },
      );
  }

  // === /Binding to ngx-file-drop ===

  /**
   * TODO: This a temporary quick fix to allow to jump to questions.
   * It is deprecated and will be replaced/refactored with: https://yooapps.jira.com/browse/COLLAPP-1309
   */
  onQuestionClick(questionId: number): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          tab: 'questions',
          questionId,
        },
        queryParamsHandling: 'merge',
      },
    );
  }

  getAttachmentTooltip(attachment: FormAttachmentFile): string | null {
    if (this.isAttachmentOutdated(attachment)) {
      return `Marked as outdated by ${formatCollAppUser(attachment.outdater)} on ${this.momentPipe.transform(attachment.outdatedOn)}:\n${attachment.outdatedComment}`;
    }

    return null;
  }

  isNewFormAttachmentFile(file: any): file is NewFormAttachmentFile {
    return (file instanceof NewFormAttachmentFile);
  }

  isExistingFormAttachmentFile(file: any): file is ExistingFormAttachmentFile {
    return (file instanceof ExistingFormAttachmentFile);
  }

  isAttachmentRemoved(attachment: FormAttachmentFile): boolean {
    return (attachment.status === FormAttachmentFileStatus.REMOVED);
  }

  isAttachmentOutdated(attachment: FormAttachmentFile): boolean {
    return (attachment.outdatedOn != null);
  }

  isAttachmentOutdateable(attachment: FormAttachmentFile | ExistingFormAttachmentFile): boolean {
    return this.isAttachmentDownloadable(attachment)
      && !this.isAttachmentOutdated(attachment)
      && attachment.canOutdateAttachment()
      && !this.attachmentOutdatingDisabled;
  }

  isAttachmentDownloadable(attachment: FormAttachmentFile): boolean {
    return this.isExistingFormAttachmentFile(attachment);
  }

  isAttachmentUploading(attachment: FormAttachmentFile): boolean {
    return (
      attachment.status === FormAttachmentFileStatus.UPLOADING
    );
  }

  isAttachmentResumable(attachment: FormAttachmentFile): boolean {
    return (
      this.isNewFormAttachmentFile(attachment)
      && (
        attachment.status === FormAttachmentFileStatus.ERROR
        || attachment.status === FormAttachmentFileStatus.CANCELLED
      )
    );
  }

  isAttachmentRemovable(attachment: FormAttachmentFile): boolean {
    // If an attachment is new, it is removable.
    const newAttachmentIsRemovable = this.isNewFormAttachmentFile(attachment);

    // If an attachment exists, the ability to remove depends on the condition of removing existing attachments.
    const existingAttachmentIsNotRemovableByCondition = this.isExistingFormAttachmentFile(attachment)
      && !this.existingAttachmentsRemovalDisabled;

    return (
      !this.isAttachmentOutdated(attachment)
      && (
        attachment.status === FormAttachmentFileStatus.PENDING
        || attachment.status === FormAttachmentFileStatus.UPLOADED
        || attachment.status === FormAttachmentFileStatus.ERROR
        || attachment.status === FormAttachmentFileStatus.CANCELLED
      )
      && (
        existingAttachmentIsNotRemovableByCondition || newAttachmentIsRemovable
      )
    );
  }

  isAttachmentRestorable(attachment: FormAttachmentFile): boolean {
    return (
      attachment.status === FormAttachmentFileStatus.REMOVED
    );
  }

  attachmentUploadProgressBackgroundPosition(attachment: FormAttachmentFile): string {
    if (this.isNewFormAttachmentFile(attachment)) {
      return `${(100 - (attachment.uploadProgress * 100)).toFixed(2)}% 0`;
    }

    return '0 0';
  }

  markForRemoval(file: FormAttachmentFile): void {
    if (this.isNewFormAttachmentFile(file)) {
      const newFiles = this._newAttachmentsSubject$.value
        .filter((formAttachmentFile) => formAttachmentFile !== file);
      this._newAttachmentsSubject$.next(newFiles);
    } else {
      // eslint-disable-next-line no-param-reassign
      file.status = FormAttachmentFileStatus.REMOVED;
      this.markedForRemoval.emit(file);
    }
  }

  cancelUpload(attachment: FormAttachmentFile): void {
    if (this.isNewFormAttachmentFile(attachment)) {
      const upload = this.newAttachmentUploads.get(attachment);
      if (upload) {
        upload.unsubscribe();
        this.newAttachmentUploads.delete(attachment);
        // eslint-disable-next-line no-param-reassign
        attachment.status = FormAttachmentFileStatus.CANCELLED;
      }
    }
  }

  retryUpload(attachment: FormAttachmentFile): void {
    if (this.isNewFormAttachmentFile(attachment)) {
      this.uploadFile(attachment);

      // this._onTouched();
      // The next onChange is required even if no new files have been added yet to inform the
      // form validation of changes.
      this._onChange(this.value);
      this.stateChanges.next();
    }
  }

  unmarkForRemoval(file: FormAttachmentFile): void {
    if (this.isExistingFormAttachmentFile(file)) {
      // eslint-disable-next-line no-param-reassign
      file.status = FormAttachmentFileStatus.UPLOADED;
      this.unmarkedForRemoval.emit(file);
    }
  }

  openOutdateAttachmentDialog(attachment: ExistingFormAttachmentFile): void {
    if (this.outdateAttachmentDialogRef) {
      return;
    }

    if (!attachment) {
      return;
    }

    /* if (!this.workPackage.canEditSubmitterSettings()) {
      return;
    } */

    const dialogRef = this.dialog.open(OutdateAttachmentDialogComponent, {
      width: '300px',
      data: {
        attachment,
      },
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--small'],
    });

    dialogRef
      .afterClosed()
      .pipe(
        finalize(() => {
          dialogRef.close();
          this.outdateAttachmentDialogRef = null;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe((result) => {
        if (result) {
          this.replaceExistingAttachment(attachment, result);
          this.outdatedAttachment.emit();
        }
      });

    this.outdateAttachmentDialogRef = dialogRef;
  }

  private replaceExistingAttachment(
    attachment: ExistingFormAttachmentFile,
    replacement: ExistingFormAttachmentFile,
  ): void {
    const existingAttachments = this._existingAttachmentsSubject$.value;
    const index = existingAttachments.indexOf(attachment);
    if (index !== -1) {
      existingAttachments.splice(index, 1, replacement);
      this._existingAttachmentsSubject$.next(existingAttachments);
    }
  }

  private addFile(file: File): boolean {
    if (
      file instanceof File
      && !(
        !this.allowDuplicates
        && this.isDuplicateFile(file)
      )
    ) {
      const newFile = { type: file.type } || { type: '' };

      if (file.type === '') {
        newFile.type = this.fallbackForEmptyMimeType(file);
      }

      const newAttachment = new NewFormAttachmentFile(
        file,
        null,
        file.name,
        newFile.type,
        file.size,
        file.lastModified,
        (this.uploader ? this.uploader.clone() : null),
        this.dateAdapter.today(),
        null,
        null,
        null,
        FormAttachmentFileStatus.PENDING,
      );
      this._newAttachmentsSubject$.next([...this._newAttachmentsSubject$.value, newAttachment]);

      return true;
    }

    return false;
  }

  private fallbackForEmptyMimeType(file: File): string {
    return getFileExtensionFromFilename(file.name);
  }

  private isDuplicateFile(file: File): boolean {
    return (this._newAttachmentsSubject$.value
      .find((newFormAttachment) => (
        newFormAttachment.fileSize === file.size
          && newFormAttachment.fileType === file.type
          && newFormAttachment.fileName === file.name
          && newFormAttachment.fileLastModified === file.lastModified
      )) != null);
  }

  private _onChange: (value: any) => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  private _onTouched: () => void = () => {}; // eslint-disable-line @typescript-eslint/no-empty-function

  private startNewFileUploads(): void {
    const pendingFilesForUpload = this._newAttachmentsSubject$.value
      .filter((item) => item.status === FormAttachmentFileStatus.PENDING);

    pendingFilesForUpload.forEach((pendingFile) => {
      this.uploadFile(pendingFile);
    });

    // this._onTouched();
    // The next onChange is required even if no new files have been added yet to inform the form validation of changes.
    this._onChange(this.value);
    this.stateChanges.next();
  }

  // eslint-disable-next-line max-lines-per-function
  private uploadFile(attachment: NewFormAttachmentFile): void {
    /* eslint-disable no-param-reassign */

    if (
      attachment.status === FormAttachmentFileStatus.UPLOADING
      || attachment.status === FormAttachmentFileStatus.UPLOADED
      || attachment.file == null
    ) {
      return;
    }

    // Just making sure no previous subscription exists (will never happen but ¯\_(ツ)_/¯ )
    const existingSubscription = this.newAttachmentUploads.get(attachment);
    if (existingSubscription) {
      existingSubscription.unsubscribe();
    }

    attachment.status = FormAttachmentFileStatus.UPLOADING;
    attachment.uploadProgress = 0;

    const subscription = this.azureBlobService.uploadFile$(attachment.file, BLOB_NAME_MAX_CHARS)
      .pipe(
        finalize(() => {
          subscription.unsubscribe();
          this.newAttachmentUploads.delete(attachment);
          this._onTouched();
          this._onChange(this.value);
          this.stateChanges.next();
        }),
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            attachment.uploadProgress = (event.loaded / (event.total || 100)) || 0;
          }
        }),
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<AzureBlobUploadResponse>) => response.body!),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (response) => {
          attachment.uploadProgress = 1;
          attachment.status = FormAttachmentFileStatus.UPLOADED;

          // Limit fileName to ATTACHMENT_NAME_MAX_CHARS. Favour file extension over file name while truncating.
          if (response.fileName.length > ATTACHMENT_NAME_MAX_CHARS) {
            const info = pathInfo(response.fileName);
            if (info.extension.length > 0) {
              const extensionLength = Math.min(info.extension.length, ATTACHMENT_NAME_MAX_CHARS - 1);
              const basenameLength = Math.max(0, ATTACHMENT_NAME_MAX_CHARS - extensionLength - 1);
              response.fileName = `${info.basename.substr(0, basenameLength)}.${info.extension.substr(0, extensionLength)}`;
            } else {
              response.fileName = info.basename.substr(0, ATTACHMENT_NAME_MAX_CHARS);
            }
          }

          const attachmentFile = AttachmentCreateRequestModel.fromJSON(response);
          // Set attachmentFile so it can be removed later when removing the attachment.
          attachment.attachmentFile = attachmentFile;
          // Remove uploaded file since it's no longer needed. Might free up some resources.
          attachment.file = null;

          this.uploadComplete.emit(attachment);
        },
        () => {
          attachment.uploadProgress = 0;
          attachment.status = FormAttachmentFileStatus.ERROR;
        },
      );

    this.newAttachmentUploads.set(attachment, subscription);

    /* eslint-enable no-param-reassign */
  }

  private calculateTotalFileSize(formAttachmentFiles: readonly FormAttachmentFile[]): void {
    let totalFileSize = 0;

    formAttachmentFiles.forEach((file) => {
      totalFileSize += file.fileSize;
    });

    this._combinedFileSize = totalFileSize;
  }

  private getColumnFilters(): Partial<AttachmentColumnFilters> {
    return this.sanitizeFilters(this.attachmentListFilterForm.value);
  }

  private sanitizeFilters(value: Partial<AttachmentColumnFilters>): Partial<AttachmentColumnFilters> {
    return Object.entries(value)
      .reduce((filters, [key, filterValue]) => {
        if (!isEmpty(filterValue)) {
          return { ...filters, [key]: filterValue };
        }

        return filters;
      }, {} as Partial<AttachmentColumnFilters>);
  }

  /**
   * Creates a new subscription that fetches the access restricted attachment url and
   * keeps refreshing it before its `validUntil` is reached.
   */
  private getAttachmentDownloadUrlRenewalSubscription(file: ExistingFormAttachmentFile): Subscription {
    if (file.url) {
      return new Subscription();
    }

    return this.projectService
      .getDownloadUrl$(file.attachmentId)
      .pipe(
        expand((response) => {
          const now = moment();
          const duration = response.validUntil
            .diff(now);

          if (duration <= 0) {
            throw new Error('Invalid validity date given for attachment URL');
          }

          // Time in milliseconds, threshold of 87.5%
          // e.g. 2h * 0.875 = 1h 45m
          const dueTime = duration * 0.875;

          return timer(dueTime)
            .pipe(
              switchMap(() => this.projectService
                .getDownloadUrl$(file.attachmentId)),
            );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (response) => {
          // eslint-disable-next-line no-param-reassign
          file.url = response.url;
        },
        (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Could not fetch attachment URL');
        },
      );
  }
}
