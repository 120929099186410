import { ProjectTypeDto } from '../../interfaces/dtos/project-type.dto';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectTypeDtoModel {
  constructor(
    readonly projectTypeCode: ProjectTypeCode,
    readonly name: string,
    readonly numberOfLevels: number,
    readonly useOnlyAsSupplierProjectType: boolean,
    readonly useOnlyAsAdditionalSupplierProjectType: boolean,
  ) {
  }

  static fromJSON(json: ProjectTypeDto): ProjectTypeDtoModel {
    assertRequiredProperties(json, [
      'projectTypeCode',
      'name',
      'numberOfLevels',
      'useOnlyAsSupplierProjectType',
      'useOnlyAsAdditionalSupplierProjectType',
    ]);

    return new ProjectTypeDtoModel(
      json.projectTypeCode,
      json.name,
      json.numberOfLevels,
      json.useOnlyAsSupplierProjectType,
      json.useOnlyAsAdditionalSupplierProjectType,
    );
  }

  toJSON(): ProjectTypeDto {
    return {
      projectTypeCode: this.projectTypeCode,
      name: this.name,
      numberOfLevels: this.numberOfLevels,
      useOnlyAsSupplierProjectType: this.useOnlyAsSupplierProjectType,
      useOnlyAsAdditionalSupplierProjectType: this.useOnlyAsAdditionalSupplierProjectType,
    };
  }
}
