import { GenericListOptions } from '../generic-list';
import { ProjectsListColumnKey } from '../../../api/meta-info/projects-list.info';
import { ProjectColumnFilters } from '../../../api/filter-columns';
import { ProjectListStateModel } from './project-list-state.model';

/**
 * Action types for ProjectListState
 */
export enum ProjectListActionTypes {
  SetProjectListSelectedColumns = '[Project List Page] Set Selected Columns',
  SetProjectListOptions = '[Project List Page] Set Options',
  ResetProjectListOptions = '[Project List Page] Reset Options',
  InitProjectListSettings = '[User API] Initialize Project List Settings',
  SetProjectListSettings = '[User API] Set Project List Settings',
  ResetProjectListSettings = '[User API] Reset Project List Settings',
  SaveProjectListSettings = '[User API] Save Project List Settings',
}

export interface ProjectListOptions extends GenericListOptions {
  columnFilters: ProjectColumnFilters;
}

export class SetProjectListSelectedColumns {
  static readonly type: string = ProjectListActionTypes.SetProjectListSelectedColumns;

  constructor(public columns: ProjectsListColumnKey[]) {}
}

export class SetProjectListOptions {
  static readonly type: string = ProjectListActionTypes.SetProjectListOptions;

  constructor(public options: ProjectListOptions) {}
}

export class ResetProjectListOptions {
  static readonly type: string = ProjectListActionTypes.ResetProjectListOptions;
}

export class InitProjectListSettings {
  static readonly type: string = ProjectListActionTypes.InitProjectListSettings;

  constructor(public settings: ProjectListStateModel) {}
}

export class SetProjectListSettings {
  static readonly type: string = ProjectListActionTypes.SetProjectListSettings;

  constructor(public settings: ProjectListStateModel) {}
}

export class ResetProjectListSettings {
  static readonly type: string = ProjectListActionTypes.ResetProjectListSettings;
}

export class SaveProjectListSettings {
  static readonly type: string = ProjectListActionTypes.SaveProjectListSettings;

  constructor(public settings: ProjectListStateModel) {}
}
