import { ProjectPlanningFiltersSettingsDto } from '../api/interfaces/dtos/project-planning-filter-settings.dto';

export class ProjectPlanningFiltersSettingsModel {
  constructor(
    readonly wpStatus?: string[],
    readonly wpStatusType?: string,
    readonly wpSorting?: string,
    readonly wpSortDirection?: string,
  ) {
    this.wpStatus = wpStatus ? [...wpStatus] : [];
    this.wpStatusType = wpStatusType;
    this.wpSorting = wpSorting;
    this.wpSortDirection = wpSortDirection;
  }

  static fromJSON(json: ProjectPlanningFiltersSettingsDto): ProjectPlanningFiltersSettingsModel {
    return new ProjectPlanningFiltersSettingsModel(
      json.wpStatus,
      json.wpStatusType,
      json.wpSorting,
      json.wpSortDirection,
    );
  }

  toJSON(): ProjectPlanningFiltersSettingsDto {
    return {
      wpStatus: this.wpStatus,
      wpStatusType: this.wpStatusType,
      wpSorting: this.wpSorting,
      wpSortDirection: this.wpSortDirection,
    };
  }
}
