<header class="collapp-dialog__header">
  <h2 class="collapp-dialog__title">{{title}}</h2>
  <button type="button" class="collapp-dialog__close" mat-dialog-close>
    <span class="ic-close"></span>
  </button>
</header>

<mat-dialog-content>
  <div class="collapp-dialog__body" [innerHTML]="text"></div>
</mat-dialog-content>

<footer class="collapp-dialog__footer">
  <span class="flex-spacer"></span>

  @if (type !== basicDialogType.NAVIGATION_PROMPT) {
    @if (type === basicDialogType.PROMPT) {
      <button
        type="button"
        collapp-stroked-button
        id="basic-dialog-no"
        [mat-dialog-close]="false"
      >No</button>
    }
    @if (type === basicDialogType.PROMPT) {
      <button
        type="button"
        collapp-flat-button
        color="primary"
        (click)="confirm()"
        id="basic-dialog-yes"
        [disabled]="loading"
      [loading]="loading">Yes</button>
    }
    @if (type !== basicDialogType.PROMPT) {
      <button
        type="button"
        collapp-flat-button
        color="primary"
        id="basic-dialog-ok"
        [mat-dialog-close]="true"
      >OK</button>
    }
  }

  @if (type === basicDialogType.NAVIGATION_PROMPT) {
    <button
      class="basic-dialog-component__large-padding-button"
      type="button"
      collapp-stroked-button
      (click)="ignoreAndContinue()"
      id="basic-dialog-ok"
    >Discard changes</button>
    <button
      class="basic-dialog-component__large-padding-button"
      type="button"
      collapp-stroked-button
      id="basic-dialog-ok"
      [disabled]="loading"
      [loading]="loading"
      (click)="saveAndContinue()"
    >Save changes</button>
    <button
      class="basic-dialog-component__large-padding-button"
      type="button"
      collapp-flat-button
      color="primary"
      id="basic-dialog-ok"
    (click)="closeAndAbort()">Abort navigation</button>
  }
</footer>
