import moment from 'moment';
import { AttachmentOutdateResponse } from '../../interfaces/responses/attachment-outdate.response';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AttachmentOutdateResponseModel {
  constructor(
    readonly outdater: UserSlimDtoModel,
    readonly outdatedOn: moment.Moment,
    readonly outdatedComment: string,
  ) {
    this.outdatedOn = outdatedOn.clone();
  }

  static fromJSON(json: AttachmentOutdateResponse): AttachmentOutdateResponseModel {
    assertRequiredProperties(json, [
      'outdater',
      'outdatedOn',
      'outdatedComment',
    ]);

    return new AttachmentOutdateResponseModel(
      UserSlimDtoModel.fromJSON(json.outdater),
      moment(json.outdatedOn)
        .parseZone(),
      json.outdatedComment,
    );
  }

  toJSON(): AttachmentOutdateResponse {
    return {
      outdater: (this.outdater ? this.outdater.toJSON() : null),
      outdatedOn: (this.outdatedOn ? this.outdatedOn.toJSON() : null),
      outdatedComment: this.outdatedComment,
    };
  }
}
