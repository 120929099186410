import { CollappFilterDropdownListRadioModel } from '../../../models/collapp-filter-dropdown-list-radio.interface';
import { AttachmentSlimDtoModel } from '../dtos/attachment-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceViewResponse } from '../../interfaces/responses/non-human-resource-view.response';
import { Metadata } from '../../interfaces/metadata';
import { NonHumanResourceUnitAssignmentDtoModel } from '../dtos/non-human-resource-unit-assignment.dto.model';

export class NonHumanResourceViewResponseModel {
  constructor(
    readonly nonHumanResourceId: string,
    readonly systemId: string,
    readonly name: string | null,
    readonly remark: string | null,
    readonly payrollCompany: string | null,
    readonly costCenter: string | null,
    readonly city: string | null,
    readonly nonHumanResourceUnitAssignments: readonly NonHumanResourceUnitAssignmentDtoModel[] = [],
    readonly metadata: MetadataModel<Metadata> = new MetadataModel(),
    readonly countryCode: string | null,
    readonly productGroupCode: string | null,
    readonly businessUnitCode: string | null,
    readonly divisionCode: string | null,
    readonly capacityUnit: string | null,
    readonly attachments: readonly AttachmentSlimDtoModel[] = [],
    readonly type: string | null,
    readonly serialNumber: string | null,
    readonly version: string | null,
    readonly headerFilters: CollappFilterDropdownListRadioModel[],
  ) {
    this.nonHumanResourceUnitAssignments = [...nonHumanResourceUnitAssignments];
    this.attachments = [...attachments];
  }

  static fromJSON(json: NonHumanResourceViewResponse): NonHumanResourceViewResponseModel {
    assertRequiredProperties(json, [
      'nonHumanResourceId',
      'systemId',
      'metadata',
    ]);

    return new NonHumanResourceViewResponseModel(
      json.nonHumanResourceId,
      json.systemId,
      json.name,
      json.remark,
      json.payrollCompany,
      json.costCenter,
      json.city,
      (Array.isArray(json.nonHumanResourceUnitAssignments) ? json.nonHumanResourceUnitAssignments : [])
        .map((item) => NonHumanResourceUnitAssignmentDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
      json.countryCode,
      json.productGroupCode,
      json.businessUnitCode,
      json.divisionCode,
      json.capacityUnit,
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
      json.type,
      json.serialNumber,
      json.version,
      json.headerFilters,
    );
  }

  toJSON(): NonHumanResourceViewResponse {
    return {
      nonHumanResourceId: this.nonHumanResourceId,
      systemId: this.systemId,
      name: this.name,
      remark: this.remark,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      city: this.city,
      countryCode: this.countryCode,
      productGroupCode: this.productGroupCode,
      businessUnitCode: this.businessUnitCode,
      divisionCode: this.divisionCode,
      capacityUnit: this.capacityUnit,
      nonHumanResourceUnitAssignments: this.nonHumanResourceUnitAssignments.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
      attachments: this.attachments.map((item) => item.toJSON()),
      type: this.type,
      serialNumber: this.serialNumber,
      version: this.version,
      headerFilters: this.headerFilters,
    };
  }
}
