import { Injectable } from '@angular/core';
import { filter, map, mapTo } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { CollAppApiService } from './collapp-api.service';
import { SkillsResponseModel } from '../models/responses/skills.response.model';
import { SkillsResponse } from '../interfaces/responses/skills.response';
import { SkillCategoryDtoModel } from '../models/dtos/skill-category.dto.model';
import { SkillCategoryDto } from '../interfaces/dtos/skill-category.dto';
import { UpdateSkillCategoryRequestModel } from '../models/requests/update-skill-category.request.model';
import { UpdateSkillsRequestModel } from '../models/requests/update-skills.request.model';
import { SkillRatingResponseModel } from '../models/responses/skill-rating.response.model';
import { UpdateUserSkillRatingsRequestModel } from '../models/requests/update-user-skill-ratings.request.model';

@Injectable({
  providedIn: 'root',
})
export class SkillsService {
  constructor(private apiService: CollAppApiService) { }

  getSkills$(unitId: number): Observable<SkillsResponseModel> {
    return this.apiService
      .getSkills$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<SkillsResponse>) => SkillsResponseModel.fromJSON(response.body!)),
      );
  }

  putSkills$(unitId: number, request: UpdateSkillsRequestModel): Observable<void> {
    return this.apiService
      .putSkills$(unitId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  getSkillCategories$(unitId: number): Observable<SkillCategoryDtoModel[]> {
    return this.apiService
      .getSkillCategories$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<SkillCategoryDto[]>) => response.body!.map(SkillCategoryDtoModel.fromJSON)),
      );
  }

  putSkillCategories$(unitId: number, request: UpdateSkillCategoryRequestModel): Observable<void> {
    return this.apiService
      .putSkillCategories$(unitId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  getSkillRatings$(unitId: number): Observable<SkillRatingResponseModel> {
    return this.apiService
      .getSkillRatings$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<SkillRatingResponseModel>) => SkillRatingResponseModel.fromJSON(response.body!)),
      );
  }

  putSkillRatings$(unitId: number, request: UpdateUserSkillRatingsRequestModel): Observable<void> {
    return this.apiService
      .putSkillRatings$(unitId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }
}
