import { TimeTrackingWeekNavigatorViewResponse } from '../../interfaces/responses/time-tracking-week-navigator-view.response';
import { TimeTrackingWeekNavigatorItemDtoModel } from '../dtos/time-tracking-week-navigator-item.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingWeekNavigatorViewResponseModel {
  constructor(
    readonly weeks: readonly TimeTrackingWeekNavigatorItemDtoModel[],
  ) {
    this.weeks = [...weeks];
  }

  static fromJSON(json: TimeTrackingWeekNavigatorViewResponse): TimeTrackingWeekNavigatorViewResponseModel {
    assertRequiredProperties(json, [
      'weeks',
    ]);

    return new TimeTrackingWeekNavigatorViewResponseModel(
      (Array.isArray(json.weeks) ? json.weeks : [])
        .map((item) => TimeTrackingWeekNavigatorItemDtoModel.fromJSON(item)),
    );
  }

  toJSON(): TimeTrackingWeekNavigatorViewResponse {
    return {
      weeks: this.weeks.map((item) => item.toJSON()),
    };
  }
}
