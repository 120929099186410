<div class="collapp-attachments__body">
  @if (loading) {
    <div class="collapp-attachments-loader">
      <collapp-loading-spinner class="collapp-attachments-loader__spinner"></collapp-loading-spinner>
    </div>
  }
  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="collapp-attachments__table collapp-list-table"
    [class.collapp-attachments__table--disabled]="disabled"
    [hidden]="allAttachmentsCount === 0"
    >
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let attachment" [matTooltip]="getAttachmentTooltip(attachment)"> {{attachment.fileName}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fileType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let attachment" [matTooltip]="getAttachmentTooltip(attachment)"> {{attachment.fileName | fileExtension}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="fileSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
      <td mat-cell *matCellDef="let attachment" [matTooltip]="getAttachmentTooltip(attachment)"> {{attachment.fileSize | fileSize}} </td>
      <td mat-footer-cell *matFooterCellDef>@if (dataSource.data.length > 1) {
        {{combinedFileSize | fileSize}}
      }</td>
    </ng-container>

    <ng-container matColumnDef="uploadedOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Uploaded </th>
      <td mat-cell *matCellDef="let attachment" [matTooltip]="getAttachmentTooltip(attachment)">
        @if ((attachment | withFunc:isExistingFormAttachmentFile)) {
          {{attachment.uploadedOn | momentLocal}}
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="uploader">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Uploader </th>
      <td mat-cell *matCellDef="let attachment" [matTooltip]="getAttachmentTooltip(attachment)"> {{attachment.uploader | collAppUser}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="markAsOutdated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="ng-select-wrapper--centered">
        <span>{{"common.status" | translate}}</span>
        <mat-form-field [formGroup]="attachmentListFilterForm" class="collapp-attachments__select-status">
          <ng-select
            ngSelectMat
            class="collapp-attachments__ng-select-status ng-select--fit-content"
            [formControl]="outdatedFilterControl"
            [items]="outdatedStateList"
            [clearable]="false"
            [appendTo]="'body'"
            (click)="$event.stopPropagation()"
            >
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              {{outdatedFilterControl.value}}
            </ng-template>
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              <ng-option-text [tooltip]="item">{{item}}</ng-option-text>
            </ng-template>
          </ng-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let attachment">
        @if (isAttachmentOutdateable(attachment)) {
          <button
            type="button" disableRipple
            class="collapp-attachments__file-button"
            mat-icon-button
            [matTooltip]="'Mark attachment as outdated'"
            (click)="openOutdateAttachmentDialog(attachment)">
            <mat-icon>archive</mat-icon>
          </button>
        }
        @if (!isAttachmentOutdateable(attachment)) {
          <collapp-attachment-state-badge
          [state]="attachment.state"></collapp-attachment-state-badge>
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="uploadedIndication">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let attachment">
        @switch (attachment.status) {
          @case (attachmentStatus.PENDING) {
            <div class="mat-tooltip-wrapper" matTooltip="Attachment is pending upload">
              <mat-icon>cloud_queue</mat-icon>
            </div>
          }
          @case (attachmentStatus.UPLOADING) {
            <div class="mat-tooltip-wrapper" matTooltip="Attachment uploading...">
              <mat-icon>cloud_queue</mat-icon>
            </div>
          }
          @case (attachmentStatus.ERROR) {
            <div class="mat-tooltip-wrapper" matTooltip="Attachment upload error">
              <mat-icon>cloud_off</mat-icon>
            </div>
          }
          @case (attachmentStatus.CANCELLED) {
            <div class="mat-tooltip-wrapper" matTooltip="Attachment upload cancelled">
              <mat-icon>cloud_off</mat-icon>
            </div>
          }
          @default {
            <div class="mat-tooltip-wrapper" matTooltip="Attachment already uploaded">
              <mat-icon>cloud_done</mat-icon>
            </div>
          }
        }
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let attachment" class="collapp-attachments__file-actions">
        <div class="collapp-attachments__file-actions-wrap">
          @if (isAttachmentDownloadable(attachment)) {
            <a
              [disabled]="!attachment.url"
              class="collapp-attachments__file-button"
              mat-icon-button
              [matTooltip]="attachment.url ? 'Download attachment' : 'Generating URL...'"
              [href]="attachment.url ? attachment.url : '#'">
            <mat-icon>get_app</mat-icon>
          </a>
        }
        @if (!disabled && isAttachmentUploading(attachment)) {
          <button
            type="button"
            class="collapp-attachments__file-button"
            mat-icon-button
            [matTooltip]="'Cancel attachment upload'"
            (click)="cancelUpload(attachment)">
            <mat-icon>highlight_off</mat-icon>
          </button>
        }
        @if (!disabled && isAttachmentResumable(attachment)) {
          <button
            type="button"
            class="collapp-attachments__file-button"
            mat-icon-button
            [matTooltip]="'Retry attachment upload'"
            (click)="retryUpload(attachment)">
            <mat-icon>replay</mat-icon>
          </button>
        }
        @if (!disabled && isAttachmentRemovable(attachment)) {
          <button
            type="button"
            class="collapp-attachments__file-button"
            mat-icon-button
            [matTooltip]="'Remove attachment'"
            (click)="markForRemoval(attachment)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
        }
        @if (!disabled && isAttachmentRestorable(attachment)) {
          <button
            type="button"
            class="collapp-attachments__file-button"
            mat-icon-button
            [matTooltip]="'Undo attachment removal'"
            (click)="unmarkForRemoval(attachment)">
            <mat-icon>undo</mat-icon>
          </button>
        }
        @if (disabled && attachment.questionId != null) {
          <button
            type="button"
            class="collapp-attachments__file-button collapp-attachments__file-button--question-link"
            mat-icon-button
            [matTooltip]="'Go to Question'"
            (click)="onQuestionClick(attachment.questionId)"
            >
            <mat-icon>question_answer</mat-icon>
          </button>
        }
        @if (isAttachmentUploading(attachment)) {
          <div class="collapp-attachments__progress">
            {{attachment.uploadProgressPercent}}%
          </div>
        }
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="fileNameFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell">
    </th>
  </ng-container>

  <ng-container matColumnDef="fileTypeFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell">
    </th>
  </ng-container>

  <ng-container matColumnDef="fileSizeFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell">
    </th>
  </ng-container>

  <ng-container matColumnDef="uploadedOnFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell">
    </th>
  </ng-container>

  <ng-container matColumnDef="uploaderFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell">
    </th>
  </ng-container>

  <ng-container matColumnDef="markAsOutdatedFilter">
    <th mat-header-cell *matHeaderCellDef class="collapp-table-filter-cell" [attr.colspan]="displayedColumns.length - displayedFilterColumns.length + 1">
    </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  @if (!hideFilters) {
    <tr mat-header-row *matHeaderRowDef="displayedFilterColumns"
      class="example-second-header-row">
    </tr>
  }
  <tr mat-row *matRowDef="let attachment; columns: displayedColumns;"
    [class.collapp-attachments__attachment--uploading]="isAttachmentUploading(attachment)"
    [class.collapp-attachments__file--outdated]="isAttachmentOutdated(attachment)"
    [class.collapp-attachments__file--deleted]="isAttachmentRemoved(attachment)"
    [style.background-position]="attachmentUploadProgressBackgroundPosition(attachment)">
  </tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
@if (allAttachmentsCount > 0 && filteredAttachmentsCount === 0) {
  <div class="collapp-attachments__message-no-matches">
    No attachments match your filter criteria
  </div>
}
@if (newAttachmentsCount > 0) {
  <div class="collapp-attachments__new-uploads-hint">
    You have {{ newAttachmentsCount }} newly uploaded {{ newAttachmentsCount === 1 ? 'file' : 'files' }}
  </div>
}
@if (allAttachmentsCount === 0 && disabled) {
  <div class="collapp-attachments__message-no-attachments">
    No attachments available
  </div>
}
@if (!disabled) {
  <ngx-file-drop
    class="ngx-file-drop"
    [dropZoneClassName]="'collapp-attachments__drop'"
    (onFileDrop)="onDrop($event)">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="collapp-attachments__file-drop-content">
        <span class="ic-upload collapp-attachments__drop-icon"></span>
        <div class="collapp-attachments__file-drop-label">
          <!-- Dateien zum Anhängen ablegen oder durchsuchen. -->
          Drop files here to attach them or
          <button type="button" class="ngx-file-drop__browse-btn collapp-attachments__file-drop-btn" (click)="openFileSelector($event)" [id]="id + '-button'">select files</button>.
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>
}
</div>
@if (allowDownloadAll) {
  <div class="collapp-attachments__actions">
    @if (existingAttachmentsCount > 0) {
      <button
        type="button"
        collapp-stroked-button
        >
        Download all
      </button>
    }
  </div>
}
