import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseDataSelectors } from '../state/base-data';
import { CountryDtoModel } from '../api/models/dtos/country.dto.model';

@Pipe({
  name: 'collAppCountryName',
})
export class CollAppCountryNamePipe implements PipeTransform {
  constructor(
    private store: Store,
  ) {
  }

  transform(value?: string): any {
    if (value == null) {
      return '';
    }

    const country: CountryDtoModel | undefined = this.store
      .selectSnapshot(BaseDataSelectors.countryByCode(value));
    if (country) {
      return country.name;
    }

    return '';
  }
}
