import { TimesheetExportTemplateResponse } from '../../interfaces/responses/timesheet-export-template.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimesheetExportTemplateResponseModel {
  constructor(
    readonly timesheetExportTemplateColumns: string,
    readonly timesheetExportTemplateHighlighting: string,
    readonly timesheetExportNonProductiveTemplateColumns: string,
    readonly timesheetExportNonProductiveTemplateHighlighting: string,
  ) {
  }

  static fromJSON(json: TimesheetExportTemplateResponse): TimesheetExportTemplateResponseModel {
    assertRequiredProperties(json, [
      'timesheetExportTemplateColumns',
      'timesheetExportTemplateHighlighting',
      'timesheetExportNonProductiveTemplateColumns',
      'timesheetExportNonProductiveTemplateHighlighting',
    ]);

    return new TimesheetExportTemplateResponseModel(
      json.timesheetExportTemplateColumns,
      json.timesheetExportTemplateHighlighting,
      json.timesheetExportNonProductiveTemplateColumns,
      json.timesheetExportNonProductiveTemplateHighlighting,
    );
  }

  toJSON(): TimesheetExportTemplateResponse {
    return {
      timesheetExportTemplateColumns: this.timesheetExportTemplateColumns,
      timesheetExportTemplateHighlighting: this.timesheetExportTemplateHighlighting,
      timesheetExportNonProductiveTemplateColumns: this.timesheetExportNonProductiveTemplateColumns,
      timesheetExportNonProductiveTemplateHighlighting: this.timesheetExportNonProductiveTemplateHighlighting,
    };
  }
}
