import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { Observable, Subject, timer } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  share,
  takeUntil,
  filter,
} from 'rxjs/operators';

import {
  AccessControlError,
  AccessControlService,
  AuthenticationEnd,
  AuthenticationUserInfoModel,
} from '../../services/access-control.service';
import {
  KeyboardShortcutsService,
  Unlisten,
} from '../../services/keyboard-shortcuts.service';
import {
  DEFAULT_RETURN_URL,
  LOGIN_REDIRECT_DELAY,
  RETURN_URL_QUERY_PARAM,
} from '../../shared/constants';
import { getRelativeUrlOrDefault, utoa } from '../../helpers/login.utility';

/**
 * Types of possible authentication and authorization errors.
 */
export enum AccessErrorType {
  NONE,
  AUTHENTICATION_ERROR,
  UNAUTHORIZED,
  AUTHORIZATION_INVALID,
  AUTHORIZATION_EXPIRED,
  AUTHORIZATION_NOT_YET_VALID,
}

@Component({
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('overlay', { read: TemplateRef, static: true })
  private overlayTemplate!: TemplateRef<any>;

  returnUrl: string = DEFAULT_RETURN_URL;

  base64EncodedReturnUrl: string = '';

  redirectUri: string = '';

  isRedirectingToAuthenticationProvider: boolean = false;

  error: AccessControlError | null = null;

  basicUserInfo: AuthenticationUserInfoModel | undefined;

  accessErrorTypes: typeof AccessErrorType = AccessErrorType;

  accessErrorType: AccessErrorType = AccessErrorType.NONE;

  relativeAccessTime$: Observable<string | null>;

  private shortcutSubscription?: Unlisten;

  private dialogRef: MatDialogRef<any> | null = null;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    public accessControlService: AccessControlService,
    private keyboardShortcutsService: KeyboardShortcutsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private msalService: MsalService,
    private router: Router,
  ) {
    this.relativeAccessTime$ = timer(0, 1000).pipe(
      map(() => {
        if (this.accessErrorType === AccessErrorType.AUTHORIZATION_EXPIRED) {
          return (
            (this.basicUserInfo
              && this.basicUserInfo.validUntil
              && this.basicUserInfo.validUntil.fromNow())
              || null
          );
        }
        if (
          this.accessErrorType === AccessErrorType.AUTHORIZATION_NOT_YET_VALID
        ) {
          return (
            (this.basicUserInfo
              && this.basicUserInfo.validFrom
              && this.basicUserInfo.validFrom.fromNow())
              || null
          );
        }

        return null;
      }),
      distinctUntilChanged(),
      takeUntil(this.destroyed$),
      share(),
    );
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    this.accessControlService.events$
      .pipe(
        filter((auth: AuthenticationEnd) => auth?.authenticated),
        takeUntil(this.destroyed$),
      )
      .subscribe((_) => {
        this.accessErrorType = this.getAccessErrorType();

        if (this.accessControlService.authenticated) {
          this.router.navigate(['/dashboard']);
        }
      });

    this.shortcutSubscription = this.keyboardShortcutsService.listen(
      {
        E: (event: KeyboardEvent): void => {
          // Since this is a native browser action, we want to cancel the
          // default behavior and isolate it as a local action.
          event.preventDefault();

          if (!this.dialogRef) {
            const dialogRef = this.dialog.open(this.overlayTemplate);

            dialogRef
              .afterClosed()
              .pipe(takeUntil(this.destroyed$))
              .subscribe(() => {
                this.dialogRef = null;
              });

            this.dialogRef = dialogRef;
          } else {
            this.dialogRef.close();
          }
        },
      },
      {
        priority: 10,
      },
    );

    // Cache the current state to prevent changes in the view,
    // for example when clicking the logout button while still in this view.
    // All results should be final at this time.
    this.error = this.accessControlService.error;
    this.basicUserInfo = this.accessControlService.getUserInfoFromError();

    this.accessErrorType = this.getAccessErrorType();

    this.route.queryParamMap
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queryParamsMap) => {
        const returnUrlParam = queryParamsMap.get(RETURN_URL_QUERY_PARAM);
        // get return url from (route) parameters or default to '/'
        const returnUrl = getRelativeUrlOrDefault(
          returnUrlParam,
          DEFAULT_RETURN_URL,
        );

        this.returnUrl = returnUrl;
        this.base64EncodedReturnUrl = utoa(returnUrl);
        this.redirectUri = this.accessControlService.getAbsoluteUrl([
          '/login',
          this.base64EncodedReturnUrl,
        ]);
      });

    if (
      !this.accessControlService.authenticated
      && (!this.error || this.accessControlService.isUserLoginRequired(this.error))
    ) {
      this.isRedirectingToAuthenticationProvider = true;

      window.setTimeout(() => {
        if (this.location.path(true).includes('#')) {
          this.msalService
            .handleRedirectObservable()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((_) => {
              this.accessControlService.start();
            });
        } else if (!this.accessControlService.accessControlPending) {
          this.login();
        }
      }, LOGIN_REDIRECT_DELAY);
    }
  }

  ngOnDestroy(): void {
    if (this.shortcutSubscription) {
      this.shortcutSubscription();
    }

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  login(): void {
    this.accessControlService.login();
  }

  logout(): void {
    this.accessControlService.logout();
  }

  private getAccessErrorType(): AccessErrorType {
    if (!this.accessControlService.authenticated) {
      return AccessErrorType.AUTHENTICATION_ERROR;
    }

    if (!this.accessControlService.authorized) {
      if (this.accessControlService.isUserAuthorizationCurrentlyInvalid()) {
        return AccessErrorType.AUTHORIZATION_INVALID;
      }

      if (this.accessControlService.isUserAuthorizationExpired()) {
        return AccessErrorType.AUTHORIZATION_EXPIRED;
      }

      if (this.accessControlService.isUserAuthorizationNotYetValid()) {
        return AccessErrorType.AUTHORIZATION_NOT_YET_VALID;
      }

      return AccessErrorType.UNAUTHORIZED;
    }

    return AccessErrorType.NONE;
  }
}
