import { CountryListResponse } from '../../interfaces/responses/country-list.response';
import { CountryDtoModel } from '../dtos/country.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class CountryListResponseModel {
  constructor(
    readonly items: readonly CountryDtoModel[],
    readonly metadata: MetadataModel,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: CountryListResponse): CountryListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new CountryListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => CountryDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): CountryListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
