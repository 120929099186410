import { WorkPackageDashboardListResponse } from '../../interfaces/responses/work-package-dashboard-list.response';
import { WorkPackageDashboardDtoModel } from '../dtos/work-package-dashboard.dto.model';
import { MetadataModel } from '../metadata.model';

export class WorkPackageDashboardListResponseModel {
  constructor(
    readonly items: readonly WorkPackageDashboardDtoModel[],
    readonly metadata: MetadataModel,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: WorkPackageDashboardListResponse): WorkPackageDashboardListResponseModel {
    return new WorkPackageDashboardListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => WorkPackageDashboardDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): WorkPackageDashboardListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
