import { SkillCategoryDto } from '../../interfaces/dtos/skill-category.dto';

export class SkillCategoryDtoModel {
  constructor(
    readonly canDelete: boolean,
    readonly id: number,
    readonly name: string | null,
  ) {}

  static fromJSON(json: SkillCategoryDto): SkillCategoryDtoModel {
    return new SkillCategoryDtoModel(
      json.canDelete,
      json.id,
      json.name,
    );
  }
}
