import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkPackageSidepanelIndexStateModel } from './work-package-sidepanel-index-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  InitWorkPackageSidepanelIndex, ResetWorkPackageSidepanelIndex, SetWorkPackageSidepanelIndex,
} from './work-package-sidepanel-index.actions';

const defaultState: WorkPackageSidepanelIndexStateModel = { index: 0 };

@State<WorkPackageSidepanelIndexStateModel>({
  name: 'WorkPackageSidePanelIndex',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackageSidepanelIndexState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitWorkPackageSidepanelIndex)
  initWorkPackageSidepanelIndex(
    ctx: StateContext<WorkPackageSidepanelIndexStateModel>,
    { settings }: InitWorkPackageSidepanelIndex,
  ): void {
    ctx.setState(
      { index: settings.index },
    );
  }

  @Action(SetWorkPackageSidepanelIndex)
  setWorkPackageSidepanelIndex$(
    ctx: StateContext<WorkPackageSidepanelIndexStateModel>,
    { settings }: SetWorkPackageSidepanelIndex,
  ): Observable<void> {
    ctx.patchState({ index: settings.index });

    return this.userService.saveWorkPackageSidePanelIndex$(ctx.getState());
  }

  @Action(ResetWorkPackageSidepanelIndex)
  resetWorkPackageSidepanelIndex(ctx: StateContext<WorkPackageSidepanelIndexStateModel>): void {
    ctx.setState(defaultState);
  }
}
