import { Selector } from '@ngxs/store';
import { Unit } from '../../../models/unit.interface';
import { WorkPackageStateModel } from './work-package-state.model';
import { WorkPackageState } from './work-package.state';

export class WorkPackageSelectors {
  @Selector([WorkPackageState])
  static recentUsedSupplierUnits(state: WorkPackageStateModel): readonly Unit[] {
    return state.recentSupplierUnits;
  }

  /**
   * Get the most recent / latest unit that was viewed.
   */
  @Selector([WorkPackageState])
  static mostRecentUsedSupplierUnits(state: WorkPackageStateModel): Unit | undefined {
    return state.recentSupplierUnits.length > 0 ? state.recentSupplierUnits[0] : undefined;
  }
}
