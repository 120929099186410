import moment from 'moment';
import { UnitPlanningNonHumanResourceWeekTotalDto } from '../../interfaces/dtos/unit-planning-non-human-resource-week-total.dto';

export class UnitPlanningNonHumanResourceWeekTotalDtoModel {
  constructor(
    readonly weekStartDate: moment.Moment,
    readonly totalPlannedHours: number | null,
    readonly workingDays: string | null,
  ) {
    this.weekStartDate = weekStartDate.clone();
  }

  static fromJSON(
    json: UnitPlanningNonHumanResourceWeekTotalDto,
  ): UnitPlanningNonHumanResourceWeekTotalDtoModel {
    return new UnitPlanningNonHumanResourceWeekTotalDtoModel(
      moment(json.weekStartDate).parseZone(),
      json.totalPlannedHours,
      json.workingDays,
    );
  }

  clone(): UnitPlanningNonHumanResourceWeekTotalDtoModel {
    return new UnitPlanningNonHumanResourceWeekTotalDtoModel(
      this.weekStartDate.clone(),
      this.totalPlannedHours,
      this.workingDays,
    );
  }
}
