import moment from 'moment';
import { SuspensionDto } from '../../interfaces/dtos/suspension.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class SuspensionDtoModel {
  constructor(
    readonly suspensionId: number,
    readonly workPackageId: number,
    readonly estimatedNewEndDate: moment.Moment,
    readonly confirmedNewEndDate: moment.Moment | null,
    readonly createdOn: moment.Moment,
  ) {
    this.estimatedNewEndDate = estimatedNewEndDate.clone();
    this.confirmedNewEndDate = (confirmedNewEndDate != null ? confirmedNewEndDate.clone() : confirmedNewEndDate);
    this.createdOn = createdOn.clone();
  }

  static fromJSON(json: SuspensionDto): SuspensionDtoModel {
    assertRequiredProperties(json, [
      'suspensionId',
      'workPackageId',
      'estimatedNewEndDate',
      // 'confirmedNewEndDate',
      'createdOn',
    ]);

    return new SuspensionDtoModel(
      json.suspensionId,
      json.workPackageId,
      moment(json.estimatedNewEndDate)
        .parseZone(),
      (json.confirmedNewEndDate
        ? moment(json.confirmedNewEndDate)
          .parseZone()
        : null),
      moment(json.createdOn)
        .parseZone(),
    );
  }

  toJSON(): SuspensionDto {
    return {
      suspensionId: this.suspensionId,
      workPackageId: this.workPackageId,
      estimatedNewEndDate: this.estimatedNewEndDate.toJSON(),
      confirmedNewEndDate: this.confirmedNewEndDate ? this.confirmedNewEndDate.toJSON() : null,
      createdOn: this.createdOn.toJSON(),
    };
  }
}
