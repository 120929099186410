import { KpiDto } from '../../interfaces/dtos/kpi.dto';
import { RatingDtoModel } from './rating.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class KpiDtoModel {
  constructor(
    readonly firstPassedYield: number,
    readonly costOfPoorQuality: number,
    readonly submitterOrSupplierEfficiency: number,
    readonly supplierEfficiency: number,
    readonly onTimeDelivery: number,
    readonly rating: RatingDtoModel,
  ) {
  }

  static fromJSON(json: KpiDto): KpiDtoModel {
    assertRequiredProperties(json, [
      'firstPassedYield',
      'costOfPoorQuality',
      'submitterOrSupplierEfficiency',
      'supplierEfficiency',
      'onTimeDelivery',
      'rating',
    ]);

    return new KpiDtoModel(
      json.firstPassedYield,
      json.costOfPoorQuality,
      json.submitterOrSupplierEfficiency,
      json.supplierEfficiency,
      json.onTimeDelivery,
      RatingDtoModel.fromJSON(json.rating),
    );
  }

  toJSON(): KpiDto {
    return {
      firstPassedYield: this.firstPassedYield,
      costOfPoorQuality: this.costOfPoorQuality,
      submitterOrSupplierEfficiency: this.submitterOrSupplierEfficiency,
      supplierEfficiency: this.supplierEfficiency,
      onTimeDelivery: this.onTimeDelivery,
      rating: this.rating,
    };
  }
}
