/**
 * Contains all the states a timecard and timesheet can have
 */
export enum TimeTrackingStatus {
  Unknown = -1,
  Open = 0,
  Pending = 1,
  Done = 2,
  Rejected = 3,
  Submitted = 4,
  Ready = 5,
  Reopen = 6,
}

export const timeTrackingStatusCodeName: { [key in TimeTrackingStatus]: string } = Object.freeze({
  [TimeTrackingStatus.Unknown]: 'unknown',
  [TimeTrackingStatus.Open]: 'open',
  [TimeTrackingStatus.Pending]: 'pending',
  [TimeTrackingStatus.Done]: 'done',
  [TimeTrackingStatus.Rejected]: 'rejected',
  [TimeTrackingStatus.Submitted]: 'submitted',
  [TimeTrackingStatus.Ready]: 'ready',
  [TimeTrackingStatus.Reopen]: 'reopen',
  // TODO: check if ther will be a ready state and clearify the statusnr.
});
