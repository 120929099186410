import { RatingListResponse } from '../../interfaces/responses/rating-list.response';
import { RatingDtoModel } from '../dtos/rating.dto.model';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class RatingListResponseModel implements ApiModelNew {
  constructor(
    readonly items: readonly RatingDtoModel[],
  ) {
    this.items = [...items];
  }

  static fromJSON(json: RatingListResponse): RatingListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new RatingListResponseModel(
      json.items.map((item) => RatingDtoModel.fromJSON(item)),
    );
  }

  clone(overrides?: Partial<RatingListResponseModel>): RatingListResponseModel {
    return new RatingListResponseModel(
      (overrides && overrides.items
        ? overrides.items
        : this.items.map((item) => item.clone())
      ),
    );
  }

  toJSON(): RatingListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
    };
  }
}
