import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { TimesheetTotalsStateModel } from './timesheet-totals-state.model';
import {
  InitTimesheetTotalsSettings,
  ResetTimesheetTotalsSettings,
  SaveTimesheetTotalsSettings,
  SetTimesheetTotalsSettings,
} from './timesheet-totals.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultTimesheetTotalsState: TimesheetTotalsStateModel = {
  selectedTotals: ['total-hours', 'productive-hours', 'non-productive-hours'],
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<TimesheetTotalsStateModel>({
  name: 'TimesheetTotals',
  defaults: defaultTimesheetTotalsState,
})
@Injectable({
  providedIn: 'root',
})
export class TimesheetTotalsState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitTimesheetTotalsSettings)
  initTimesheetTotalsSettings(
    ctx: StateContext<TimesheetTotalsStateModel>,
    { settings }: InitTimesheetTotalsSettings,
  ): void {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });
  }

  @Action(SetTimesheetTotalsSettings)
  setTimesheetTotalsSettings$(
    ctx: StateContext<TimesheetTotalsStateModel>,
    { settings }: SetTimesheetTotalsSettings,
  ): Observable<void> {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });

    return ctx.dispatch(new SaveTimesheetTotalsSettings(ctx.getState()));
  }

  @Action(ResetTimesheetTotalsSettings)
  resetTimesheetTotalsSettings(ctx: StateContext<TimesheetTotalsStateModel>): void {
    ctx.setState(defaultTimesheetTotalsState);
  }

  @Action(SaveTimesheetTotalsSettings)
  saveTimesheetTotalsSettings$(
    ctx: StateContext<TimesheetTotalsStateModel>,
    { settings }: SaveTimesheetTotalsSettings,
  ): Observable<void> {
    return this.userService.saveTimesheetTotalsSettings$(settings);
  }
}
