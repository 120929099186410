import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './spinner.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    LoadingSpinnerComponent,
  ],
  declarations: [
    LoadingSpinnerComponent,
  ],
})
export class SpinnerModule {
}
