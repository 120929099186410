import moment from 'moment';
import { ScopeChangeDto } from '../../interfaces/dtos/scope-change.dto';
import { AttachmentSlimDtoModel } from './attachment-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ScopeChangeDtoModel {
  constructor(
    readonly scopeChangeId: number,
    readonly workPackageId: number,
    readonly title: string,
    readonly scope: string,
    readonly createdOn: moment.Moment,
    readonly estimatedNewEndDate: moment.Moment,
    readonly estimatedAdditionalHours: number,
    readonly confirmedNewEndDate: moment.Moment | null,
    readonly confirmedAdditionalHours: number = 0,
    readonly attachments: AttachmentSlimDtoModel[] = [],
  ) {
    this.createdOn = createdOn.clone();
    this.estimatedNewEndDate = estimatedNewEndDate.clone();
    this.confirmedNewEndDate = (confirmedNewEndDate != null ? confirmedNewEndDate.clone() : confirmedNewEndDate);
    this.attachments = [...attachments];
  }

  static fromJSON(json: ScopeChangeDto): ScopeChangeDtoModel {
    assertRequiredProperties(json, [
      'scopeChangeId',
      'workPackageId',
      'title',
      'scope',
      'createdOn',
      'estimatedNewEndDate',
      'estimatedAdditionalHours',
      // 'confirmedNewEndDate',
      // 'confirmedAdditionalHours',
      // 'attachments',
    ]);

    return new ScopeChangeDtoModel(
      json.scopeChangeId,
      json.workPackageId,
      json.title,
      json.scope,
      moment(json.createdOn)
        .parseZone(),
      moment(json.estimatedNewEndDate)
        .parseZone(),
      json.estimatedAdditionalHours,
      (json.confirmedNewEndDate
        ? moment(json.confirmedNewEndDate)
          .parseZone()
        : null
      ),
      json.confirmedAdditionalHours || 0,
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
    );
  }

  clone(): ScopeChangeDtoModel {
    return new ScopeChangeDtoModel(
      this.scopeChangeId,
      this.workPackageId,
      this.title,
      this.scope,
      this.createdOn.clone(),
      this.estimatedNewEndDate.clone(),
      this.estimatedAdditionalHours,
      this.confirmedNewEndDate ? this.confirmedNewEndDate.clone() : null,
      this.confirmedAdditionalHours,
      this.attachments.map((item) => item.clone()),
    );
  }

  toJSON(): ScopeChangeDto {
    return {
      scopeChangeId: this.scopeChangeId,
      workPackageId: this.workPackageId,
      title: this.title,
      scope: this.scope,
      createdOn: this.createdOn.toJSON(),
      estimatedNewEndDate: this.estimatedNewEndDate.toJSON(),
      estimatedAdditionalHours: this.estimatedAdditionalHours,
      confirmedNewEndDate: this.confirmedNewEndDate ? this.confirmedNewEndDate.toJSON() : null,
      confirmedAdditionalHours: this.confirmedAdditionalHours,
      attachments: this.attachments.map((item) => item.toJSON()),
    };
  }
}
