import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { ProjectColumnFilters } from '../filter-columns';
import { ReadonlyCompleteMap } from '../../app.types';
import { ProjectListViewDto } from '../interfaces/dtos/project-list-view';

export type ProjectsListColumnKey = ObjectPropertyNames<ProjectListViewDto>;

// This defines the object that should implement all properties of 'ProjectListDto' exactly.
// It's used for linting mostly and discarded later.
const projectsListColumnsInfoObj: ListColumnsInfo<ProjectListViewDto> = {
  projectId: {
    label: 'Project ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  sapNumber: {
    label: 'Sap Number',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: true,
  },
  projectNumber: {
    label: 'Number',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  title: {
    label: 'Project Title',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  scope: {
    label: 'Description',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  projectManagerFullName: {
    label: 'Project Manager',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  technicalProjectManagerFullName: {
    label: 'Technical Project Manager',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  submitterUnitName: {
    label: 'Submitter Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  gateName: {
    label: 'Gate',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  customerCountryCode: {
    label: 'Customer Country',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  placeOfManufacturingCountryCode: {
    label: 'Place of Manufacturing Country',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  emtOrderFromUnitName: {
    label: 'EMT-Order From Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  emtOrderFromCountryCode: {
    label: 'EMT-Order From Country',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  status: {
    label: 'Status',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  projectTypeCode: {
    label: 'Type',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  payrollCompany: {
    label: 'Payroll Company',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  reportedHours: {
    label: 'Total Reported Hours',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  percentDone: {
    label: '% Done',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  projectUserStatus: {
    label: 'Project Status',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const projectsListColumnsInfo: ReadonlyCompleteMap<
ProjectsListColumnKey,
ListColumnInfo
> = new Map([
  ['projectId', projectsListColumnsInfoObj.projectId],
  ['sapNumber', projectsListColumnsInfoObj.sapNumber],
  ['projectNumber', projectsListColumnsInfoObj.projectNumber],
  ['title', projectsListColumnsInfoObj.title],
  ['scope', projectsListColumnsInfoObj.scope],
  ['projectManagerFullName', projectsListColumnsInfoObj.projectManagerFullName],
  ['technicalProjectManagerFullName', projectsListColumnsInfoObj.technicalProjectManagerFullName],
  ['submitterUnitName', projectsListColumnsInfoObj.submitterUnitName],
  ['gateName', projectsListColumnsInfoObj.gateName],
  ['customerCountryCode', projectsListColumnsInfoObj.customerCountryCode],
  ['placeOfManufacturingCountryCode', projectsListColumnsInfoObj.placeOfManufacturingCountryCode],
  ['emtOrderFromUnitName', projectsListColumnsInfoObj.emtOrderFromUnitName],
  ['emtOrderFromCountryCode', projectsListColumnsInfoObj.emtOrderFromCountryCode],
  ['status', projectsListColumnsInfoObj.status],
  ['projectTypeCode', projectsListColumnsInfoObj.projectTypeCode],
  ['payrollCompany', projectsListColumnsInfoObj.payrollCompany],
  ['percentDone', projectsListColumnsInfoObj.percentDone],
  ['projectUserStatus', projectsListColumnsInfoObj.projectUserStatus],
]) as ReadonlyCompleteMap<ProjectsListColumnKey, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const projectsListColumnsWithFilters: (keyof ProjectColumnFilters)[] = Array.from(
  projectsListColumnsInfo.entries(),
)
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof ProjectColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
