import { SkillRatingValueDto } from '../../interfaces/dtos/skill-rating-value.dto';

export class SkillRatingValueDtoModel {
  constructor(
    readonly userId: string,
    readonly value: number,
  ) {}

  static fromJSON(json: SkillRatingValueDto): SkillRatingValueDtoModel {
    return new SkillRatingValueDtoModel(
      json.userId,
      json.value,
    );
  }

  clone(): SkillRatingValueDtoModel {
    return new SkillRatingValueDtoModel(
      this.userId,
      this.value,
    );
  }

  toJSON(): SkillRatingValueDto {
    return {
      userId: this.userId,
      value: this.value,
    };
  }
}
