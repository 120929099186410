import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_RETURN_URL } from '../shared/constants';
import { atou, getRelativeUrlOrDefault } from '../helpers/login.utility';

@Component({
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    let returnUrl = DEFAULT_RETURN_URL;

    // Check for a base64 encoded payload
    const pathFromHere = this.route.snapshot.url
      .map((urlSegment) => urlSegment.path)
      .filter((path: string) => path !== '')
      .join('/');

    if (pathFromHere !== '') {
      const decodedReturnUrl = atou(pathFromHere);
      returnUrl = getRelativeUrlOrDefault(decodedReturnUrl, DEFAULT_RETURN_URL);
    }

    this.router.navigateByUrl(returnUrl);
  }
}
