import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkPackagePlanningTotalsStateModel } from './work-package-planning-totals-state.model';
import {
  InitWorkPackagePlanningTotalsSettings,
  ResetWorkPackagePlanningTotalsSettings,
  SaveWorkPackagePlanningTotalsSettings,
  SetWorkPackagePlanningTotalsSettings,
} from './work-package-planning-totals.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultWorkPackagePlanningTotalsState: WorkPackagePlanningTotalsStateModel = {
  selectedTotals: ['total'],
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<WorkPackagePlanningTotalsStateModel>({
  name: 'WorkPackagePlanningTotals',
  defaults: defaultWorkPackagePlanningTotalsState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackagePlanningTotalsState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitWorkPackagePlanningTotalsSettings)
  initWorkPackagePlanningTotalsSettings(
    ctx: StateContext<WorkPackagePlanningTotalsStateModel>,
    { settings }: InitWorkPackagePlanningTotalsSettings,
  ): void {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });
  }

  @Action(SetWorkPackagePlanningTotalsSettings)
  setWorkPackagePlanningTotalsSettings$(
    ctx: StateContext<WorkPackagePlanningTotalsStateModel>,
    { settings }: SetWorkPackagePlanningTotalsSettings,
  ): Observable<void> {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
    });

    return ctx.dispatch(new SaveWorkPackagePlanningTotalsSettings(ctx.getState()));
  }

  @Action(ResetWorkPackagePlanningTotalsSettings)
  resetWorkPackagePlanningTotalsSettings(ctx: StateContext<WorkPackagePlanningTotalsStateModel>): void {
    ctx.setState(defaultWorkPackagePlanningTotalsState);
  }

  @Action(SaveWorkPackagePlanningTotalsSettings)
  saveWorkPackagePlanningTotalsSettings$(
    ctx: StateContext<WorkPackagePlanningTotalsStateModel>,
    { settings }: SaveWorkPackagePlanningTotalsSettings,
  ): Observable<void> {
    return this.userService.saveWorkPackagePlanningTotalsSettings$(settings);
  }
}
