import { Component, HostBinding, Input } from '@angular/core';
import { FileAttachmentState } from '../../form-attachments.component';

@Component({
  selector: 'collapp-attachment-state-badge',
  templateUrl: './state-badge.component.html',
  styleUrls: ['./state-badge.component.scss'],
})
export class StateBadgeComponent {
  get state(): FileAttachmentState { return this._state; }

  @Input()
  set state(value: FileAttachmentState) {
    this._state = value;
    switch (value) {
      case FileAttachmentState.NEW: {
        this._stateLabel = 'New';
        break;
      }
      case FileAttachmentState.OUTDATED: {
        this._stateLabel = 'Outdated';
        break;
      }
      default: {
        this._stateLabel = '';
        break;
      }
    }
  }

  @HostBinding('class.state-badge--outdated')
  get isNew(): boolean { return this.state === FileAttachmentState.OUTDATED; }

  @HostBinding('class.state-badge--new')
  get isOutdated(): boolean { return this.state === FileAttachmentState.NEW; }

  get stateLabel(): string { return this._stateLabel; }

  private _state: FileAttachmentState = FileAttachmentState.DEFAULT;

  private _stateLabel: string = '';
}
