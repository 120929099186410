import { MetadataModel } from '../metadata.model';
import { WithPaginationInfo } from '../../interfaces/metadata';
import { QuestionsViewListResponse } from '../../interfaces/responses/questions-view-list.response';
import { QuestionListItemDtoModel } from '../dtos/question-list-item.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionsViewListResponseModel {
  constructor(
    readonly items: readonly QuestionListItemDtoModel[],
    readonly metadata: MetadataModel<WithPaginationInfo>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: QuestionsViewListResponse): QuestionsViewListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new QuestionsViewListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => QuestionListItemDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }
}
