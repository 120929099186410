import { UnitPlanningUserWorkPackageDtoModel } from './unit-planning-user-work-package.dto.model';
import { UnitPlanningProjectDto } from '../../interfaces/dtos/unit-planning-project.dto';

export class UnitPlanningProjectDtoModel {
  constructor(
    readonly projectId: number,
    readonly projectNumber: string,
    readonly projectTitle: string,
    readonly displayName: string,
    readonly workPackages: UnitPlanningUserWorkPackageDtoModel[],
  ) {
    if (this.workPackages) {
      this.workPackages = this.workPackages.map((wp) => wp.clone());
    }
  }

  static fromJSON(json: UnitPlanningProjectDto): UnitPlanningProjectDtoModel {
    return new UnitPlanningProjectDtoModel(
      json.projectId,
      json.projectNumber,
      json.projectTitle,
      json.displayName,
      json.workPackages.map((wp) => UnitPlanningUserWorkPackageDtoModel.fromJSON(wp)),
    );
  }

  clone(): UnitPlanningProjectDtoModel {
    return new UnitPlanningProjectDtoModel(
      this.projectId,
      this.projectNumber,
      this.projectTitle,
      this.displayName,
      this.workPackages.map((wp) => wp.clone()),
    );
  }
}
