import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitPlanningNonHumanResourceDto } from '../../interfaces/dtos/unit-planning-non-human-resource.dto';
import { UnitPlanningProjectDtoModel } from './unit-planning-project.dto.model';
import { MetadataSlimDtoModel } from './metadata-slim.dto.model';
import { NonHumanResourcePlanningSlimDtoModel } from './non-human-resource-planning-slim.dto.model';
import { UnitPlanningNonHumanResourceWeekTotalDtoModel } from './unit-planning-non-human-resource-week-total.dto.model';
import { SlimCapacityPlanningDtoModel } from './planning-capacity-slim.dto.model';

export class UnitPlanningNonHumanResourceDtoModel {
  constructor(
    readonly nonHumanResource: NonHumanResourcePlanningSlimDtoModel,
    readonly projects: UnitPlanningProjectDtoModel[],
    readonly totals: UnitPlanningNonHumanResourceWeekTotalDtoModel[],
    readonly capacities: SlimCapacityPlanningDtoModel[],
    readonly metadata: MetadataSlimDtoModel,
  ) {
    this.nonHumanResource = this.nonHumanResource.clone();

    if (projects) {
      this.projects = this.projects.map((project) => project.clone());
    }

    if (totals) {
      this.totals = this.totals.map((total) => total.clone());
    }

    if (capacities) {
      this.capacities = this.capacities.map((item) => item.clone());
    }
  }

  static fromJSON(json: UnitPlanningNonHumanResourceDto): UnitPlanningNonHumanResourceDtoModel {
    assertRequiredProperties(json, [
      'nonHumanResource',
    ]);

    return new UnitPlanningNonHumanResourceDtoModel(
      NonHumanResourcePlanningSlimDtoModel.fromJSON(json.nonHumanResource),
      (Array.isArray(json.projects) ? json.projects : [])
        .map(UnitPlanningProjectDtoModel.fromJSON),
      (Array.isArray(json.totals) ? json.totals : [])
        .map(UnitPlanningNonHumanResourceWeekTotalDtoModel.fromJSON),
      (Array.isArray(json.capacities) ? json.capacities : [])
        .map(SlimCapacityPlanningDtoModel.fromJSON),
      MetadataSlimDtoModel.fromJSON(json.metadata),
    );
  }

  clone(): UnitPlanningNonHumanResourceDtoModel {
    return new UnitPlanningNonHumanResourceDtoModel(
      this.nonHumanResource.clone(),
      this.projects?.map((item) => item.clone()) || null,
      this.totals?.map((item) => item.clone()) || null,
      this.capacities?.map((item) => item.clone()) || null,
      this.metadata.clone(),
    );
  }
}
