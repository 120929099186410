import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollappButtonModule } from '../collapp-button';
import { FormSectionComponent } from './form-section.component';

@NgModule({
  imports: [
    CommonModule,
    CollappButtonModule,
  ],
  exports: [
    FormSectionComponent,
  ],
  declarations: [
    FormSectionComponent,
  ],
})
export class FormSectionModule {
}
