import { GenericListOptions } from '../generic-list';
import { WorkPackageViewListColumnKeys } from '../../../api/meta-info/work-packages-list.info';
import { WorkPackageColumnFilters } from '../../../api/filter-columns';
import { WorkPackageListStateModel } from './work-package-list-state.model';

/**
 * Action types for WorkPackageListState
 */
export enum WorkPackageListActionTypes {
  SetWorkPackageListSelectedColumns = '[Work Package List Page] Set Selected Columns',
  SetWorkPackageListOptions = '[Work Package List Page] Set Options',
  ResetWorkPackageListOptions = '[Work Package List Page] Reset Options',
  InitWorkPackageListSettings = '[Work Package API] Initialize WorkPackage List Settings',
  SetWorkPackageListSettings = '[Work Package API] Set WorkPackage List Settings',
  ResetWorkPackageListSettings = '[Work Package API] Reset WorkPackage List Settings',
  SaveWorkPackageListSettings = '[Work Package API] Save Work Package List Settings',
}

export interface WorkPackageListOptions extends GenericListOptions {
  columnFilters: WorkPackageColumnFilters;
}

export class SetWorkPackageListSelectedColumns {
  static readonly type: string = WorkPackageListActionTypes.SetWorkPackageListSelectedColumns;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public columns: WorkPackageViewListColumnKeys[]) {}
}

export class SetWorkPackageListOptions {
  static readonly type: string = WorkPackageListActionTypes.SetWorkPackageListOptions;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public options: WorkPackageListOptions) {}
}

export class ResetWorkPackageListOptions {
  static readonly type: string = WorkPackageListActionTypes.ResetWorkPackageListOptions;
}

export class InitWorkPackageListSettings {
  static readonly type: string = WorkPackageListActionTypes.InitWorkPackageListSettings;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public settings: WorkPackageListStateModel) {}
}

export class SetWorkPackageListSettings {
  static readonly type: string = WorkPackageListActionTypes.SetWorkPackageListSettings;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public settings: WorkPackageListStateModel) {}
}

export class ResetWorkPackageListSettings {
  static readonly type: string = WorkPackageListActionTypes.ResetWorkPackageListSettings;
}

export class SaveWorkPackageListSettings {
  static readonly type: string = WorkPackageListActionTypes.SaveWorkPackageListSettings;
// eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(public settings: WorkPackageListStateModel) {}
}
