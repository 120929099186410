const FILE_TYPE_FALLBACK: string = 'bin';

/**
 * Returns the File Extensions from the Filename
 *
 * @param fileName
 */
export function getFileExtensionFromFilename(fileName: string): string {
  if (fileName === undefined || fileName === null) {
    return '';
  }

  if (fileName.length === 0) {
    return '';
  }

  let newFileType = '';
  const splittedFileName = fileName.split('.');
  if (splittedFileName.length > 1) {
    newFileType = splittedFileName[splittedFileName.length - 1];
  } else {
    newFileType = FILE_TYPE_FALLBACK;
  }

  return newFileType
    .toLowerCase();
}
