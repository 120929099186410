import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCommonModule, MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CollappAnchorComponent, CollappButtonComponent } from './button';

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    MatCommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    MatCommonModule,
    CollappButtonComponent,
    CollappAnchorComponent,
  ],
  declarations: [
    CollappButtonComponent,
    CollappAnchorComponent,
  ],
})
export class CollappButtonModule {
}
