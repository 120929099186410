import moment from 'moment';
import { UnitPlanningResourceDto } from '../../interfaces/dtos/unit-planning-resource.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitPlanningResourceDtoModel implements Omit<UnitPlanningResourceDto, 'validFrom' | 'validUntil'> {
  constructor(
    readonly planningResourceId: string,
    readonly fullName: string,
    readonly email: string | null,
    readonly photoUrl: string | null = null,
    readonly validFrom: moment.Moment | null = null,
    readonly validUntil: moment.Moment | null = null,
    readonly isUserType: boolean,
  ) {
    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);
  }

  get id(): string {
    return this.planningResourceId;
  }

  static fromJSON(json: UnitPlanningResourceDto): UnitPlanningResourceDtoModel {
    assertRequiredProperties(json, [
      'planningResourceId',
      'fullName',
    ]);

    return new UnitPlanningResourceDtoModel(
      json.planningResourceId,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.isUserType,
    );
  }

  /**
   * If no value is given the optional field is evaluated as not valid.
   */
  isValidFrom(startDate: moment.Moment): boolean {
    if (this.validFrom != null) {
      return this.validFrom.isSameOrBefore(startDate);
    }

    return false;
  }

  /**
   * If no value is given the optional field is evaluated as valid.
   */
  isValidUntil(endDate: moment.Moment): boolean {
    if (this.validUntil != null) {
      return this.validUntil.isSameOrAfter(endDate);
    }

    return true;
  }

  isValidUntilInPast(checkDate: moment.Moment = moment()): boolean {
    return this.validUntil != null && this.validUntil.isBefore(checkDate);
  }

  toJSON(): UnitPlanningResourceDto {
    return {
      planningResourceId: this.planningResourceId,
      fullName: this.fullName,
      email: this.email,
      photoUrl: this.photoUrl,
      validFrom: (this.validFrom ? this.validFrom.toJSON() : null),
      validUntil: (this.validUntil ? this.validUntil.toJSON() : null),
      isUserType: this.isUserType,
    };
  }

  clone(overrides?: Partial<UnitPlanningResourceDtoModel>): UnitPlanningResourceDtoModel {
    return new UnitPlanningResourceDtoModel(
      overrides && overrides.planningResourceId != null
        ? overrides.planningResourceId
        : this.planningResourceId,
      overrides && overrides.fullName != null
        ? overrides.fullName
        : this.fullName,
      overrides && overrides.email != null
        ? overrides.email
        : this.email,
      overrides && overrides.photoUrl !== undefined
        ? overrides.photoUrl
        : this.photoUrl,
      overrides && overrides.validFrom !== undefined
        ? overrides.validFrom
        : this.validFrom?.clone() || null,
      overrides && overrides.validUntil !== undefined
        ? overrides.validUntil
        : this.validUntil?.clone() || null,
      overrides && overrides.isUserType != null
        ? overrides.isUserType
        : this.isUserType,
    );
  }
}
