import moment from 'moment';
import { CapacityPlanningSlimDto } from '../../interfaces/dtos/planning-capacity-slim.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class SlimCapacityPlanningDtoModel {
  constructor(
    readonly weekStartDate: moment.Moment | null,
    readonly totalHours: number,
    readonly availableHours: number,
    public hasHoliday: boolean,
    public holidayDescription: string | null = null,
    public holidayHours: number,
  ) {
  }

  static fromJSON(json: CapacityPlanningSlimDto): SlimCapacityPlanningDtoModel {
    assertRequiredProperties(json, [
      'weekStartDate',
      'totalHours',
      'availableHours',
    ]);

    return new SlimCapacityPlanningDtoModel(
      (json.weekStartDate
        ? moment(json.weekStartDate)
          .parseZone()
        : null
      ),
      json.totalHours,
      json.availableHours,
      json.hasHoliday,
      json.holidayDescription,
      json.holidayHours,
    );
  }

  clone(): SlimCapacityPlanningDtoModel {
    return new SlimCapacityPlanningDtoModel(
      this.weekStartDate != null ? this.weekStartDate.clone() : null,
      this.totalHours,
      this.availableHours,
      this.hasHoliday,
      this.holidayDescription,
      this.holidayHours,
    );
  }

  toJSON(): CapacityPlanningSlimDto {
    return {
      weekStartDate: (this.weekStartDate != null ? this.weekStartDate.toJSON() : null),
      totalHours: this.totalHours,
      availableHours: this.availableHours,
      hasHoliday: this.hasHoliday,
      holidayDescription: this.holidayDescription,
      holidayHours: this.holidayHours,
    };
  }
}
