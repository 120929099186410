import { Selector } from '@ngxs/store';
import { UserResourceListStateModel } from './user-resource-list-state.model';
import { UserResourceListOptions } from './user-resource-list.actions';
import { UserResourceListState } from './user-resource-list.state';

export class UserResourceListSelectors {
  @Selector([UserResourceListState])
  static selectedColumns(state: UserResourceListStateModel): UserResourceListStateModel['selectedColumns'] {
    return state.selectedColumns;
  }

  @Selector([UserResourceListState])
  static options(state: UserResourceListStateModel): UserResourceListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: state.columnFilters,
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
