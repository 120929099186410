import moment from 'moment';
import { LevelSlimDto } from '../../interfaces/dtos/level-slim.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelSlimDtoModel {
  constructor(
    readonly levelId: number,
    readonly title: string,
    readonly codePath: string = '',
    readonly startDate: moment.Moment | null = null,
    readonly endDate: moment.Moment | null = null,
    readonly isDeletedInSap: boolean,
    readonly children: LevelSlimDtoModel[] = [],
  ) {
    this.startDate = (startDate != null ? startDate.clone() : startDate);
    this.endDate = (endDate != null ? endDate.clone() : endDate);
    this.children = [...children];
  }

  static fromJSON(json: LevelSlimDto): LevelSlimDtoModel {
    assertRequiredProperties(json, [
      'levelId',
      'title',
    ]);

    return new LevelSlimDtoModel(
      json.levelId,
      json.title,
      (json.codePath ?? undefined),
      (json.startDate
        ? moment(json.startDate)
          .parseZone()
        : null
      ),
      (json.endDate
        ? moment(json.endDate)
          .parseZone()
        : null
      ),
      json.isDeletedInSap,
      (Array.isArray(json.children) ? json.children : [])
        .map((item) => LevelSlimDtoModel.fromJSON(item)),
    );
  }

  clone(): LevelSlimDtoModel {
    return new LevelSlimDtoModel(
      this.levelId,
      this.title,
      this.codePath,
      this.startDate ? this.startDate.clone() : null,
      this.endDate ? this.endDate.clone() : null,
      this.isDeletedInSap,
      this.children.map((child) => child.clone()),
    );
  }

  /**
   * Recursively search for the received level id.
   * It will continue by searching its children.
   *
   * @param levelId
   */
  getLevelPath(levelId: number): LevelSlimDtoModel[] {
    if (this.levelId === levelId) {
      return [this];
    }

    // Look in children
    if (this.children.length > 0) {
      const childrenLen = this.children.length;
      let i = 0;
      let levels: LevelSlimDtoModel[] = [];

      while (levels.length === 0 && i < childrenLen) {
        levels = this.children[i].getLevelPath(levelId);
        i += 1;
      }

      if (levels.length > 0) {
        return [this, ...levels];
      }
    }

    return [];
  }

  toJSON(): LevelSlimDto {
    return {
      levelId: this.levelId,
      title: this.title,
      codePath: this.codePath,
      startDate: this.startDate ? this.startDate.toJSON() : null,
      endDate: this.endDate ? this.endDate.toJSON() : null,
      isDeletedInSap: this.isDeletedInSap,
      children: this.children.map((item) => item.toJSON()),
    };
  }
}
