import { ReopenCreateResponse } from '../../interfaces/responses/reopen-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ReopenCreateResponseModel {
  constructor(
    readonly replyId: number,
  ) {
  }

  static fromJSON(json: ReopenCreateResponse): ReopenCreateResponseModel {
    assertRequiredProperties(json, [
      'replyId',
    ]);

    return new ReopenCreateResponseModel(
      json.replyId,
    );
  }

  toJSON(): ReopenCreateResponse {
    return {
      replyId: this.replyId,
    };
  }
}
