import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { TimecardRangeStateModel } from './timecard-range-state.model';
import {
  InitTimecardRangeSettings,
  ResetTimecardRangeSettings,
  SaveTimecardRangeSettings,
  SetTimecardRangeSettings,
} from './timecard-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultTimecardRangeState: TimecardRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<TimecardRangeStateModel>({
  name: 'TimecardRange',
  defaults: defaultTimecardRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class TimecardRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitTimecardRangeSettings)
  initTimecardRangeSettings(
    ctx: StateContext<TimecardRangeStateModel>,
    { settings }: InitTimecardRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetTimecardRangeSettings)
  setTimecardRangeSettings$(
    ctx: StateContext<TimecardRangeStateModel>,
    { settings }: SetTimecardRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveTimecardRangeSettings(ctx.getState()));
  }

  @Action(ResetTimecardRangeSettings)
  resetTimecardRangeSettings(ctx: StateContext<TimecardRangeStateModel>): void {
    ctx.setState(defaultTimecardRangeState);
  }

  @Action(SaveTimecardRangeSettings)
  saveTimecardRangeSettings$(
    ctx: StateContext<TimecardRangeStateModel>,
    { settings }: SaveTimecardRangeSettings,
  ): Observable<void> {
    return this.userService.saveTimecardRangeSettings$(settings);
  }
}
