import moment from 'moment';
import { UserQuestionNotificationDto } from '../../interfaces/dtos/user-question-notification.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UserQuestionNotificationDtoModel extends UserSlimDtoModel {
  constructor(
    userId: string,
    fullName: string,
    email: string,
    photoUrl: string | null,
    validFrom: moment.Moment | null,
    validUntil: moment.Moment | null,
    public isChecked: boolean,
  ) {
    super(
      userId,
      fullName,
      email,
      photoUrl,
      validFrom,
      validUntil,
    );
  }

  static fromJSON(json: UserQuestionNotificationDto): UserQuestionNotificationDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'fullName',
      'email',
      'isChecked',
    ]);

    return new UserQuestionNotificationDtoModel(
      json.userId,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.isChecked,
    );
  }
}
