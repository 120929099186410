import moment from 'moment';
import { WorkPackageStatus } from '../models/work-package-status.enum';

/**
 * The minimal Work Package information needed by the WorkPackageLogicHelper.
 */
export interface WorkPackageForLogicChecks {
  status?: WorkPackageStatus;

  estimatedStartDate?: moment.Moment;
  latestEstimatedEndDate?: moment.Moment;
  plannedStartDate?: moment.Moment | null;
  latestPlannedEndDate?: moment.Moment | null;
  latestConfirmedEndDate?: moment.Moment | null;

  latestEstimatedHours: number;
  plannedHours: number;
  reportedHours?: number;
  latestConfirmedHours: number;

  costToComplete: number;
}

/**
 * A small utility class to help with business logic in the frontend.
 */
export class WorkPackageLogicHelper {
  /**
   * Checks whether a Work Package is in state ReadyForApproval or Closed.
   */
  static isInApproval(workPackage: WorkPackageForLogicChecks): boolean {
    return (
      workPackage.status === WorkPackageStatus.ReadyForApproval
      || workPackage.status === WorkPackageStatus.Closed
    );
  }

  /**
   * Checks whether a Work Package is in one of the proposal states, meaning it still has to be confirmed.
   * Proposal states are Draft, Forecasted and Issued.
   */
  static isInProposal(workPackage: WorkPackageForLogicChecks): boolean {
    return (
      workPackage.status === WorkPackageStatus.Draft
      || workPackage.status === WorkPackageStatus.Forecasted
      || workPackage.status === WorkPackageStatus.Issued
    );
  }

  /**
   * Returns the most appropriate start date depending on the Work Package's current state.
   */
  static getApplicableStartDate(workPackage: WorkPackageForLogicChecks): moment.Moment {
    if (WorkPackageLogicHelper.isInProposal(workPackage)) {
      return workPackage.estimatedStartDate!;
    }

    // Assume planned start date is available when Work Package is not in proposal anymore.
    return workPackage.plannedStartDate!;
  }

  /**
   * Returns the most appropriate end date depending on the Work Package's current state.
   */
  static getApplicableEndDate(workPackage: WorkPackageForLogicChecks): moment.Moment {
    if (WorkPackageLogicHelper.isInProposal(workPackage)) {
      return workPackage.latestEstimatedEndDate!;
    }

    // Assume planned end date is available when Work Package is not in proposal anymore.
    return workPackage.latestPlannedEndDate!;
  }

  /**
   * Checks whether a Work Package's planned end date exceeds the latest confirmed end date.
   */
  static isPlannedEndDateExceeded(workPackage: WorkPackageForLogicChecks): boolean {
    if (workPackage.latestPlannedEndDate && workPackage.latestConfirmedEndDate) {
      return workPackage.latestPlannedEndDate.isAfter(workPackage.latestConfirmedEndDate, 'day');
    }

    return false;
  }

  /**
   * Checks whether a Work Package's planned end date (or estimated end date) is overdue.
   */
  static isPlannedEndDateOverdue(workPackage: WorkPackageForLogicChecks): boolean {
    const nowNoTimeZone = moment().utcOffset(0, true);
    if (WorkPackageLogicHelper.isInProposal(workPackage)) {
      return workPackage.latestEstimatedEndDate!.isBefore(nowNoTimeZone, 'day');
    }

    if (WorkPackageLogicHelper.isInApproval(workPackage)) {
      return false;
    }

    if (workPackage.latestPlannedEndDate != null) {
      return workPackage.latestPlannedEndDate.isBefore(nowNoTimeZone, 'day');
    }

    return false;
  }

  /**
   * Checks whether a Work Package's estimated hours should be preferably shown at the current state.
   */
  static areEstimatedHoursShown(workPackage: WorkPackageForLogicChecks): boolean {
    return WorkPackageLogicHelper.isInProposal(workPackage);
  }

  /**
   * Checks whether a Work Package's planned hours exceed the latest confirmed hours.
   * This is ignored in the proposal state since `planned hours` are not yet available there.
   */
  static arePlannedHoursExceeded(workPackage: WorkPackageForLogicChecks): boolean {
    // In proposal state, the `plannedHours` are not yet available and therefore ignored
    if (WorkPackageLogicHelper.isInProposal(workPackage)) {
      return false;
    }

    const latestAvailableHours = workPackage.latestConfirmedHours || workPackage.latestEstimatedHours;

    return workPackage.plannedHours > latestAvailableHours;
  }

  /**
   * Checks whether the reported hours exceed the planned hours.
   */
  static isCostToCompleteExceeded(workPackage: WorkPackageForLogicChecks): boolean {
    return (workPackage.costToComplete < 0);
  }
}
