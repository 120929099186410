import { createSelector, Selector } from '@ngxs/store';
import { ProjectTypeCode } from '../../models/project-type.enum';
import { BaseDataState } from './base-data.state';
import { BaseDataStateModel } from './base-data-state.model';
import { CountryDtoModel } from '../../api/models/dtos/country.dto.model';
import { RoleListDtoModel } from '../../api/models/dtos/role-list.dto.model';
import { GateDtoModel } from '../../api/models/dtos/gate.dto.model';
import { RatingDtoModel } from '../../api/models/dtos/rating.dto.model';
import { ContractModel } from '../../api/contract-model';
import { ProjectTypeDtoModel } from '../../api/models/dtos/project-type.dto.model';
import { ResourceLinkDtoModel } from '../../api/models/dtos/resource-link.dto.model';

export class BaseDataSelectors {
  /**
   * Fetches a project type by its code (id), excluding types that can only be used as supplier.
   * @param code
   */
  static basicProjectTypeByCode(code: string): (state: BaseDataStateModel) => ProjectTypeDtoModel | undefined {
    return createSelector([BaseDataState], (state: BaseDataStateModel) => {
      const projectType = state.projectTypes.find((item) => item.projectTypeCode === code);

      if (projectType && !projectType.useOnlyAsSupplierProjectType) {
        return projectType;
      }

      return undefined;
    });
  }

  /**
   * Fetches a project type by its code (id). Includes all possibly project types.
   * @param code
   */
  static projectTypeByCode(code: string): (state: BaseDataStateModel) => ProjectTypeDtoModel | undefined {
    return createSelector(
      [BaseDataState],
      (state: BaseDataStateModel) => state.projectTypes.find((item) => item.projectTypeCode === code),
    );
  }

  /**
   * Fetches a role by its id.
   * @param roleId
   */
  static roleById(roleId: number): (state: BaseDataStateModel) => RoleListDtoModel | undefined {
    return createSelector(
      [BaseDataState],
      (state: BaseDataStateModel) => state.roles.find((item) => item.roleId === roleId),
    );
  }

  /**
   * Fetches a gate by its id.
   * @param gateId
   */
  static gateById(gateId: number): (state: BaseDataStateModel) => GateDtoModel | undefined {
    return createSelector(
      [BaseDataState],
      (state: BaseDataStateModel) => state.gates.find((item) => item.gateId === gateId),
    );
  }

  static countryByCode(code: string): (state: BaseDataStateModel) => CountryDtoModel | undefined {
    return createSelector(
      [BaseDataState],
      (state: BaseDataStateModel) => state.countries.find((country) => country.countryCode === code),
    );
  }

  @Selector([BaseDataState])
  static countries(state: BaseDataStateModel): CountryDtoModel[] {
    return state.countries;
  }

  @Selector([BaseDataState])
  static roles(state: BaseDataStateModel): RoleListDtoModel[] {
    return state.roles;
  }

  @Selector([BaseDataState])
  static gates(state: BaseDataStateModel): GateDtoModel[] {
    return state.gates;
  }

  @Selector([BaseDataState])
  static ratings(state: BaseDataStateModel): RatingDtoModel[] {
    return state.ratings;
  }

  @Selector([BaseDataState])
  static contractModels(state: BaseDataStateModel): ContractModel[] {
    return state.contractModels;
  }

  @Selector([BaseDataState])
  static links(state: BaseDataStateModel): ResourceLinkDtoModel[] {
    return state.links;
  }

  /**
   * Fetches the default project types, excluding types that can only be used as supplier.
   * @param state
   */
  @Selector([BaseDataState])
  static basicProjectTypes(state: BaseDataStateModel): ProjectTypeDtoModel[] {
    // Only use actual project types by
    // ignoring the ones marked as useOnlyAsSupplierProjectType & UseOnlyAsAdditionalSupplierProjectType
    // eslint-disable-next-line max-len
    return state.projectTypes.filter((projectType) => !projectType.useOnlyAsSupplierProjectType
      && !projectType.useOnlyAsAdditionalSupplierProjectType
     && projectType.projectTypeCode !== ProjectTypeCode.SameAsSupplier);
  }
}
