import moment from 'moment';
import { FileDownloadResponse } from '../../interfaces/responses/file-download.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class FileDownloadResponseModel {
  constructor(
    readonly url: string,
    readonly validUntil: moment.Moment,
  ) {
    this.validUntil = validUntil.clone();
  }

  static fromJSON(json: FileDownloadResponse): FileDownloadResponseModel {
    assertRequiredProperties(json, [
      'url',
      'validUntil',
    ]);

    return new FileDownloadResponseModel(
      json.url,
      moment(json.validUntil)
        .parseZone(),
    );
  }

  toJSON(): FileDownloadResponse {
    return {
      url: this.url,
      validUntil: this.validUntil.toJSON(),
    };
  }
}
