import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { BackgroundColorResponse } from '../../interfaces/responses/background-colors-response';

export class BackgroundColorDtoModel implements ApiModelNew {
  constructor(
    public unitBackgroundColorId: number,
    public unitId: number,
    public backgroundColor: string,
    public name: string,
  ) {
  }

  static fromJSON(json: BackgroundColorResponse): BackgroundColorDtoModel {
    assertRequiredProperties(json, [
      'unitBackgroundColorId',
      'unitId',
      'backgroundColor',
      'name',
    ]);

    return new BackgroundColorDtoModel(
      json.unitBackgroundColorId,
      json.unitId,
      json.backgroundColor,
      json.name,
    );
  }

  clone(): BackgroundColorDtoModel {
    return new BackgroundColorDtoModel(
      this.unitBackgroundColorId,
      this.unitId,
      this.backgroundColor,
      this.name,
    );
  }

  toJSON(): BackgroundColorResponse {
    return {
      unitBackgroundColorId: this.unitBackgroundColorId,
      unitId: this.unitId,
      backgroundColor: this.backgroundColor,
      name: this.name,
    };
  }
}
