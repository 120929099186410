import moment from 'moment';
import { ReplyDto } from '../../interfaces/dtos/reply.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { ReplyType } from '../../../models/reply-type.enum';
import { WorkPackageRole } from '../../../models/work-package-role.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ReplyDtoModel {
  constructor(
    readonly replyId: number,
    readonly questionId: number,
    readonly type: ReplyType,
    readonly text: string,
    readonly creator: UserSlimDtoModel,
    readonly createdOn: moment.Moment,
    readonly creatorWorkPackageRole: WorkPackageRole | null = null,
  ) {
    this.createdOn = createdOn.clone();
  }

  static fromJSON(json: ReplyDto): ReplyDtoModel {
    assertRequiredProperties(json, [
      'replyId',
      'questionId',
      'type',
      'text',
      'creator',
      'createdOn',
      // 'creatorWorkPackageRole',
    ]);

    return new ReplyDtoModel(
      json.replyId,
      json.questionId,
      json.type,
      json.text,
      UserSlimDtoModel.fromJSON(json.creator),
      moment(json.createdOn)
        .parseZone(),
      json.creatorWorkPackageRole,
    );
  }

  toJSON(): ReplyDto {
    return {
      replyId: this.replyId,
      questionId: this.questionId,
      type: this.type,
      text: this.text,
      creator: this.creator.toJSON(),
      createdOn: this.createdOn.toJSON(),
      creatorWorkPackageRole: this.creatorWorkPackageRole,
    };
  }
}
