import { QuestionListResponseMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { QuestionUserNotificationResponseModel } from './question-user-notification.response.model';
import { QuestionUserNotificationListResponse } from '../../interfaces/responses/question-user-notification-list.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionUserNotificationListResponseModel {
  constructor(
    readonly items: readonly QuestionUserNotificationResponseModel[],
    readonly metadata: MetadataModel<QuestionListResponseMetadata>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: QuestionUserNotificationListResponse): QuestionUserNotificationListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new QuestionUserNotificationListResponseModel(
      (Array.isArray(json.items)
        ? json.items
          .map((item) => QuestionUserNotificationResponseModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata),
    );
  }
}
