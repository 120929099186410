import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {
  map, skipWhile, take,
} from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AccessControlService } from '../services/access-control.service';
import { UserPermissions } from '../api/user-permissions';
import { UserSelectors } from '../state';
import { UserDtoModel } from '../api/models/dtos/user.dto.model';
import { RETURN_URL_QUERY_PARAM } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class AccessGuard  {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private accessControlService: AccessControlService,
    private router: Router,
    private store: Store,
  ) { }

  /**
   * A guard deciding if a route can be activated.
   * If the guard returns `true`, navigation will continue. If the guard returns `false`,
   * navigation will be cancelled. If the guard returns a `UrlTree`, current navigation will
   * be cancelled and a new navigation will be kicked off to the `UrlTree` returned from the
   * guard.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.checkAccessAndPermissions$(state.url, route.data.permissions);
  }

  /**
   * A guard deciding if a child route can be activated.
   * If the guard returns `true`, navigation will continue. If the guard returns `false`,
   * navigation will be cancelled. If the guard returns a `UrlTree`, current navigation will
   * be cancelled and a new navigation will be kicked off to the `UrlTree` returned from the
   * guard.
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  /**
   * A guard deciding if children can be loaded.
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const routeUrl = segments.reduce((path, segment) => `${path}/${segment.path}`, '');
    const permissions = (route.data ? route.data.permissions : undefined);
    const locationHref = this.document.location.href;
    const routeUrlIndex = locationHref.indexOf(routeUrl);

    let url = routeUrl;

    if (routeUrlIndex !== -1) {
      // @TODO This is a workaround for not having access to the entire route info
      // at this point. This is not 100% foolproof!
      const routeUrlWithQueryParams = locationHref.substr(routeUrlIndex);
      const routeUrlSignificantParts = routeUrlWithQueryParams.substr(routeUrl.length);
      if (routeUrlSignificantParts.match(/^\/?[?#]/)) {
        url = routeUrlWithQueryParams;
      }
    }

    return this.checkAccessAndPermissions$(url, permissions)
      .pipe(
        map((result) => {
          if (typeof result === 'boolean') {
            return result;
          }

          this.router.navigateByUrl(result);

          return false;
        }),
      );
  }

  private checkAccessAndPermissions$(returnUrl: string, permissions?: UserPermissions): Observable<boolean | UrlTree> {
    return this.checkAccessControl$()
      .pipe(
        map((hasAccess) => {
          let result: boolean | UrlTree = hasAccess;

          if (hasAccess) {
            if (!this.checkPermissions(permissions)) {
              // no permissions so redirect to no-access page
              result = this.router.createUrlTree(['/no-access']);
            }
          } else {
            // not logged in so redirect to login page with the return url
            result = this.router.createUrlTree(['/login'], { queryParams: { [RETURN_URL_QUERY_PARAM]: returnUrl } });
          }

          return result;
        }),
      );
  }

  private checkAccessControl$(): Observable<boolean> {
    return this.accessControlService
      .accessControlPending$
      .pipe(
        skipWhile((accessControlPending) => accessControlPending),
        take(1),
        map(() => this.accessControlService.access),
      );
  }

  private checkPermissions(permissions?: any): boolean {
    if (!permissions) {
      return true;
    }

    const user: UserDtoModel | null = this.store.selectSnapshot(UserSelectors.user);

    return user?.hasPermissions(permissions) ?? false;
  }
}
