import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { CollAppApiService } from './collapp-api.service';
import { NonHumanResourceColumnFilters } from '../filter-columns';
import { ListQueryOptions } from '../list-query-options';
import { NonHumanResourceListResponseModel } from '../models/responses/non-human-resource-list.response.model';
import { NonHumanResourceListResponse } from '../interfaces/responses/non-human-resource-list.response';
import { NonHumanResourceViewResponseModel } from '../models/responses/non-human-resource-view.response.model';
import { NonHumanResourceViewResponse } from '../interfaces/responses/non-human-resource-view.response';
import { NonHumanResourceCreateResponse } from '../interfaces/responses/non-human-resource-create.response';
import { NonHumanResourceCreateResponseModel } from '../models/responses/non-human-resource-create.response.model';
import { NonHumanResourceCreateRequestModel } from '../models/requests/non-human-resource-create.request.model';
import { NonHumanResourceUpdateRequestModel } from '../models/requests/non-human-resource-update.request.model';
import { NonHumanResourceDtoModel } from '../models/dtos/non-human-resource.dto.model';
import { NonHumanResourceDto } from '../interfaces/dtos/non-human-resource.dto';

@Injectable({
  providedIn: 'root',
})
export class NonHumanResourceService {
  constructor(private apiService: CollAppApiService) { }

  /**
   * Gets a list of all units
   */
  getNonHumanResources$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: NonHumanResourceColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<NonHumanResourceListResponseModel> {
    const options = new ListQueryOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);

    return this.apiService
      .getNonHumanResources$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<NonHumanResourceListResponse>) => NonHumanResourceListResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets the view response of the units in the administration section
   */
  getNonHumanResourcesViewResponse$(id: string): Observable<NonHumanResourceViewResponseModel> {
    return this
      .apiService
      .getNonHumanResourcesViewResponse$(id)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<NonHumanResourceViewResponse>) => NonHumanResourceViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new non-human resource.
   */
  postNonHumanResource$(
    nonHumanResource: NonHumanResourceCreateRequestModel,
  ): Observable<NonHumanResourceCreateResponseModel> {
    return this.apiService
      .postNonHumanResource$(nonHumanResource.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<NonHumanResourceCreateResponse>) => NonHumanResourceCreateResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing unit.
   */
  putNonHumanResource$(nonHumanResource: NonHumanResourceUpdateRequestModel): Observable<void> {
    return this.apiService
      .putNonHumanResource$(nonHumanResource.nonHumanResourceId, nonHumanResource.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets a single user by id.
   *
   * @param {string} nonHumanResourceId
   * @return {Observable<NonHumanResourceSlimDtoModel>}
   */
  getNonHumanResourceById$(nonHumanResourceId: string): Observable<NonHumanResourceDtoModel> {
    return this.apiService
      .getNonHumanResourceById$(nonHumanResourceId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<NonHumanResourceDto>) => NonHumanResourceDtoModel.fromJSON(response.body!)),
      );
  }
}
