import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SkillsStateModel } from './skills-state.model';
import {
  AddSkillsRecentUnit,
  InitSkillsSettings,
} from './skills.actions';

const MAX_NUMBER_OF_RECENT_UNITS = 3;

const defaultSkillsState: SkillsStateModel = {
  recentUnits: [],
};

@State<SkillsStateModel>({
  name: 'skills',
  defaults: defaultSkillsState,
})
@Injectable({
  providedIn: 'root',
})
export class SkillsState {
  @Action(InitSkillsSettings)
  initUserSkillsSettingsState(ctx: StateContext<SkillsStateModel>, { recentUnits }: InitSkillsSettings): void {
    ctx.patchState({
      recentUnits,
    });
  }

  @Action(AddSkillsRecentUnit)
  addSkillsRecentUnit(
    { patchState, getState }: StateContext<SkillsStateModel>,
    { unit }: AddSkillsRecentUnit,
  ): void {
    const { recentUnits } = getState();

    // Check if given unit already exists in queue
    const existingUnitIndex = recentUnits.findIndex((element) => (element.unitId === unit.unitId));

    // Create a new array of units with the most recent in front.
    const updatedRecentUnits = [unit, ...recentUnits];

    if (existingUnitIndex !== -1) {
      // Remove duplicates
      updatedRecentUnits.splice(existingUnitIndex + 1, 1);
    }

    // Limit queue to a given maximum number of entries.
    updatedRecentUnits.length = Math.min(updatedRecentUnits.length, MAX_NUMBER_OF_RECENT_UNITS);

    patchState({
      recentUnits: updatedRecentUnits,
    });
  }
}
