import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { NonHumanResourcePlanningListStateModel } from './non-human-resource-planning-list-state.model';
import {
  InitNonHumanResourcePlanningListSettings,
  ResetNonHumanResourcePlanningListSettings,
  SaveNonHumanResourcePlanningListSettings,
  SetNonHumanResourcePlanningListOptions,
  SetNonHumanResourcePlanningListSelectedColumns,
  SetNonHumanResourcePlanningListSettings,
} from './non-human-resource-planning-list.actions';
import { UserService } from '../../../api/services/user.service';
import { NON_HUMAN_RESOURCE_PLANNING_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';
import { nonHumanResourcePlanningListColumnsInfo } from '../../../api/meta-info/non-human-resources-planning-list.info';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultNonHumanResourcePlanningListState: NonHumanResourcePlanningListStateModel = {
  selectedColumns: Array.from(nonHumanResourcePlanningListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: NON_HUMAN_RESOURCE_PLANNING_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<NonHumanResourcePlanningListStateModel>({
  name: 'nonHumanResourcePlanningList',
  defaults: defaultNonHumanResourcePlanningListState,
})
@Injectable({
  providedIn: 'root',
})
export class NonHumanResourcePlanningListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetNonHumanResourcePlanningListSelectedColumns)
  setNonHumanResourcePlanningListSelectedColumns$(
    ctx: StateContext<NonHumanResourcePlanningListStateModel>,
    { columns }: SetNonHumanResourcePlanningListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, nonHumanResourcePlanningListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourcePlanningListSettings(ctx.getState()));
  }

  @Action(SetNonHumanResourcePlanningListOptions)
  setNonHumanResourcePlanningListOptions$(
    ctx: StateContext<NonHumanResourcePlanningListStateModel>,
    { options }: SetNonHumanResourcePlanningListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, nonHumanResourcePlanningListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourcePlanningListSettings(ctx.getState()));
  }

  @Action(InitNonHumanResourcePlanningListSettings)
  initNonHumanResourcePlanningListSettings(
    ctx: StateContext<NonHumanResourcePlanningListStateModel>,
    { settings }: InitNonHumanResourcePlanningListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, nonHumanResourcePlanningListColumnsInfo));
  }

  @Action(SetNonHumanResourcePlanningListSettings)
  setNonHumanResourcePlanningListSettings$(
    ctx: StateContext<NonHumanResourcePlanningListStateModel>,

    { settings }: SetNonHumanResourcePlanningListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, nonHumanResourcePlanningListColumnsInfo));

    return ctx.dispatch(new SaveNonHumanResourcePlanningListSettings(ctx.getState()));
  }

  @Action(ResetNonHumanResourcePlanningListSettings)
  resetNonHumanResourcePlanningListSettings(ctx: StateContext<NonHumanResourcePlanningListStateModel>): void {
    ctx.setState(defaultNonHumanResourcePlanningListState);
  }

  @Action(SaveNonHumanResourcePlanningListSettings)
  saveNonHumanResourcePlanningListSettings$(
    ctx: StateContext<NonHumanResourcePlanningListStateModel>,
    { settings }: SaveNonHumanResourcePlanningListSettings,
  ): Observable<void> {
    return this.userService.saveNonHumanResourcePlanningListSettings$(settings);
  }
}
