import moment from 'moment';
import { TimeTrackingWeekNavigatorItemDto } from '../../interfaces/dtos/time-tracking-week-navigator-item.dto';
import { TimeTrackingStatus } from '../../../models/time-tracking-status.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingWeekNavigatorItemDtoModel {
  constructor(
    readonly year: number,
    readonly weekNumber: number,
    readonly weekStartDate: moment.Moment,
    readonly status: TimeTrackingStatus = TimeTrackingStatus.Open,
  ) {
    this.weekStartDate = weekStartDate.clone();
  }

  static fromJSON(json: TimeTrackingWeekNavigatorItemDto): TimeTrackingWeekNavigatorItemDtoModel {
    assertRequiredProperties(json, [
      'year',
      'weekNumber',
      'weekStartDate',
      'status',
    ]);

    return new TimeTrackingWeekNavigatorItemDtoModel(
      json.year,
      json.weekNumber,
      moment(json.weekStartDate)
        .parseZone(),
      json.status,
    );
  }

  toJSON(): TimeTrackingWeekNavigatorItemDto {
    return {
      year: this.year,
      weekNumber: this.weekNumber,
      weekStartDate: this.weekStartDate.toJSON(),
      status: this.status,
    };
  }
}
