import moment from 'moment';
import { MilestoneBase } from '../../interfaces/base/milestone.base';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class MilestoneBaseModel {
  constructor(
    readonly title: string,
    readonly dueDate: moment.Moment,
    readonly isCompleted: boolean,
    readonly sortOrder: number,
  ) {
    this.dueDate = dueDate.clone();
  }

  static fromJSON(json: MilestoneBase): MilestoneBaseModel {
    assertRequiredProperties(json, [
      'title',
      'dueDate',
      'isCompleted',
      'sortOrder',
    ]);

    return new MilestoneBaseModel(
      json.title,
      moment(json.dueDate)
        .parseZone(),
      json.isCompleted,
      json.sortOrder,
    );
  }

  clone(overrides?: Partial<MilestoneBaseModel>): MilestoneBaseModel {
    return new MilestoneBaseModel(
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.dueDate != null
        ? overrides.dueDate
        : this.dueDate
      ),
      (overrides && overrides.isCompleted != null
        ? overrides.isCompleted
        : this.isCompleted
      ),
      (overrides && overrides.sortOrder != null
        ? overrides.sortOrder
        : this.sortOrder
      ),
    );
  }

  toJSON(): MilestoneBase {
    return {
      title: this.title,
      dueDate: this.dueDate.toJSON(),
      isCompleted: this.isCompleted,
      sortOrder: this.sortOrder,
    };
  }
}
