/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { BasicUser } from '../../models/user.interface';
import { formatCollAppUser } from '../../collapp-common';
import { isNumber } from '../../helpers/number.utility';

const PERSON_CONTENT_MIN_WIDTH: number = 155;

@Component({
  selector: 'person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonComponent implements OnInit, OnChanges, DoCheck, AfterViewInit, OnDestroy {
  @HostBinding('class.person')
  readonly personListItem: boolean = true;

  @HostBinding('title')
  get title(): string | null {
    return this.toolTip;
  }

  @HostBinding('class.person--disabled')
  _disabled: boolean = false;

  @Input()
  size: 'tiny' | 'small' | 'normal' = 'small';

  @Input()
  badge: string | null = null;

  @Input()
  tooltipSuffix: string | null = null;

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  label: string | null = null;

  @Input()
  user: BasicUser | null | undefined;

  get collapsible(): boolean { return this._collapsible; }

  @Input()
  set collapsible(value: boolean) {
    this._collapsible = coerceBooleanProperty(value);
  }

  get collapsibleMinWidth(): string | number | null | undefined { return this._collapsibleMinWidth; }

  @Input()
  set collapsibleMinWidth(value: string | number | null | undefined) {
    this._collapsibleMinWidth = value;
    if (value != null) {
      this._collapsibleMinWidthAndUnit = (isNumber(value) ? `${+value}px` : `${value}`);
    } else {
      this._collapsibleMinWidthAndUnit = null;
    }
  }

  get collapsibleMinWidthAndUnit(): string | null { return this._collapsibleMinWidthAndUnit; }

  toolTip: string = '';

  get hasLabel(): boolean { return this.label != null; }

  get displayName(): string { return this._displayName; }

  @HostBinding('class.person--is-collapsed')
  get isCollapsed(): boolean { return this.collapsible && this.isContentOverflowing; }

  get isTooltipVisible(): boolean {
    return (this.collapsible && this.isContentOverflowing) || this.isDisplayNameOverflowing;
  }

  @HostBinding('class.person--is-name-truncated')
  isDisplayNameOverflowing: boolean = false;

  @ViewChild('displayNameEl', { static: true })
  private displayNameEl?: ElementRef;

  isContentOverflowing: boolean = false;

  private _displayName: string = '';

  private _collapsible: boolean = false;

  private _collapsibleMinWidth: string | number | null | undefined;

  private _collapsibleMinWidthAndUnit: string | null = null;

  private _inputDirty: boolean = false;

  private _sizeDirty: boolean = false;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private hostRef: ElementRef,
  ) {
    this.collapsibleMinWidth = PERSON_CONTENT_MIN_WIDTH;
  }

  ngOnInit(): void {
    this.createTooltipText();
  }

  @HostListener('window:resize')
  onResize(): void {
    this._sizeDirty = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('user' in changes || 'tooltipSuffix' in changes) {
      this._inputDirty = true;
      this.createTooltipText();
    }
  }

  ngDoCheck(): void {
    if (this._inputDirty) {
      this._inputDirty = false;

      this._displayName = formatCollAppUser(this.user) || '';
      this.createTooltipText();
    }

    if (this.collapsible && this._sizeDirty) {
      this._sizeDirty = false;

      this.checkContentOverflow();
    }
  }

  ngAfterViewInit(): void {
    this._sizeDirty = true;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private createTooltipText(): void {
    const text = this.displayName + (this.tooltipSuffix ? ` (${this.tooltipSuffix})` : '');
    this.toolTip = text.length ? text : '';
  }

  private checkContentOverflow(): void {
    const hostElement = this.hostRef.nativeElement as HTMLDivElement;
    this.isContentOverflowing = (hostElement.scrollWidth > hostElement.clientWidth);

    if (this.displayNameEl) {
      const displayNameElement = this.displayNameEl.nativeElement as HTMLDivElement;
      this.isDisplayNameOverflowing = (displayNameElement.scrollWidth > displayNameElement.clientWidth);
    }
  }
}
