import moment from 'moment';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceListDto } from '../../interfaces/dtos/non-human-resource-list.dto';

export class NonHumanResourceListDtoModel {
  constructor(
    readonly nonHumanResourceId: string,
    readonly systemId: string | null,
    readonly name: string | null,
    readonly remark: string | null,
    readonly type: string | null,
    readonly version: string | null,
    readonly serialNumber: string | null,
    readonly unitName: string | null,
    readonly unitId: number | null,
    readonly unitCoordinatorFullName: string | null,
    readonly validFrom: moment.Moment | null,
    readonly validUntil: moment.Moment | null,
    readonly countryCode: string | null,
    readonly countryName: string | null,
    readonly productGroupCode: string | null,
    readonly businessUnitCode: string | null,
    readonly workingHoursPerWeek: number | null,
    readonly hourlyRateCategory: string | null,
    readonly divisionCode: string | null,
    readonly city: string | null,
    readonly costCenter: string | null,
    readonly payrollCompany: string | null,
  ) {}

  static fromJSON(json: NonHumanResourceListDto): NonHumanResourceListDtoModel {
    assertRequiredProperties(json, [
      'nonHumanResourceId',
    ]);

    return new NonHumanResourceListDtoModel(
      json.nonHumanResourceId,
      json.systemId,
      json.name,
      json.remark,
      json.type,
      json.version,
      json.serialNumber,
      json.unitName,
      json.unitId,
      json.unitCoordinatorFullName,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.countryCode,
      json.countryName,
      json.productGroupCode,
      json.businessUnitCode,
      json.workingHoursPerWeek,
      json.hourlyRateCategory,
      json.divisionCode,
      json.city,
      json.costCenter,
      json.payrollCompany,
    );
  }

  clone(): NonHumanResourceListDtoModel {
    return new NonHumanResourceListDtoModel(
      this.nonHumanResourceId,
      this.systemId,
      this.name,
      this.remark,
      this.type,
      this.version,
      this.serialNumber,
      this.unitName,
      this.unitId,
      this.unitCoordinatorFullName,
      this.validFrom ? this.validFrom.clone() : null,
      this.validUntil ? this.validUntil.clone() : null,
      this.countryCode,
      this.countryName,
      this.productGroupCode,
      this.businessUnitCode,
      this.workingHoursPerWeek,
      this.hourlyRateCategory,
      this.divisionCode,
      this.city,
      this.costCenter,
      this.payrollCompany,
    );
  }
}
