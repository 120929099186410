import { Injectable } from '@angular/core';
import {
  Action, Selector, State, StateContext,
} from '@ngxs/store';
import { TimecardViewExpandsActions } from './timecard-view-expands.actions';

export interface TimecardsViewExpandsStateModel {
  expandedProjectIds: string[];
  expandedSpecialRowNames: string[];
}

const defaultTimecardViewExpandsState: TimecardsViewExpandsStateModel = {
  expandedProjectIds: [],
  expandedSpecialRowNames: [],
};

@State<TimecardsViewExpandsStateModel>({
  name: 'TimecardViewExpandsState',
  defaults: defaultTimecardViewExpandsState,
})
@Injectable({
  providedIn: 'root',
})
export class TimecardViewExpandsState {
  @Selector()
  // eslint-disable-next-line max-len
  static expandsStatus(state: TimecardsViewExpandsStateModel): TimecardsViewExpandsStateModel {
    return state;
  }

  @Action(TimecardViewExpandsActions.SetExpandsIds)
  // eslint-disable-next-line max-len
  setExpandsIds(ctx: StateContext<TimecardsViewExpandsStateModel>, params: TimecardViewExpandsActions.SetExpandsIds): void {
    ctx.patchState({
      expandedProjectIds: params.expandedProjectIds,
      expandedSpecialRowNames: params.expandedSpecialRowNames,
    });
  }
}
