import moment from 'moment';
import { AttachmentSlimDto } from '../../interfaces/dtos/attachment-slim.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { DocumentType } from '../../../models/document-type.enum';
import { AttachmentSlimDtoMetadata } from '../../interfaces/metadata';
import { ApiModelNew } from '../types';
import { MetadataModel } from '../metadata.model';

export class AttachmentSlimDtoModel implements ApiModelNew {
  /**
   * @deprecated
   */
  get fileName(): string { return this.name; }

  /**
   * @deprecated
   */
  get fileType(): string { return this.type; }

  /**
   * @deprecated
   */
  get fileSize(): number { return this.size; }

  constructor(
    readonly attachmentId: string,
    /**
     * Defines a usual Attachment or a Output Document
     */
    readonly documentType: DocumentType,
    /**
     * The name of the file.
     */
    readonly name: string,
    /**
     * The MIME type of the file.
     */
    readonly type: string,
    /**
     * The size of the file in bytes.
     */
    readonly size: number,
    readonly uploader: UserSlimDtoModel | null = null,
    readonly uploadedOn: moment.Moment | null = null,
    readonly outdater: UserSlimDtoModel | null = null,
    readonly outdatedOn: moment.Moment | null = null,
    readonly outdatedComment: string | null = null,
    readonly projectId: number | null = null,
    readonly workPackageId: number | null = null,
    // TODO: This a temporary quick fix to allow to jump to questions. Remove it eventually.
    readonly questionId: number | null = null,
    readonly metadata: MetadataModel<AttachmentSlimDtoMetadata>,
    readonly url: string | null = null,
    readonly validUntil: moment.Moment | null = null,
  ) {
    this.uploadedOn = (uploadedOn != null ? uploadedOn.clone() : uploadedOn);
    this.outdatedOn = (outdatedOn != null ? outdatedOn.clone() : outdatedOn);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);
  }

  static fromJSON(json: AttachmentSlimDto): AttachmentSlimDtoModel {
    return new AttachmentSlimDtoModel(
      json.attachmentId,
      json.documentType,
      json.fileName,
      json.fileType,
      json.fileSize,
      (json.uploader
        ? UserSlimDtoModel.fromJSON(json.uploader)
        : null
      ),
      (json.uploadedOn
        ? moment(json.uploadedOn)
          .parseZone()
        : moment()
      ),
      (json.outdater
        ? UserSlimDtoModel.fromJSON(json.outdater)
        : null
      ),
      (json.outdatedOn
        ? moment(json.outdatedOn)
          .parseZone()
        : null
      ),
      json.outdatedComment,
      json.projectId,
      json.workPackageId,
      json.questionId,
      new MetadataModel(json.metadata),
      json.url,
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : moment()
      ),
    );
  }

  canOutdateAttachment(): boolean {
    return this.metadata.fields && this.metadata.fields.canOutdateAttachment;
  }

  clone(overrides?: Partial<AttachmentSlimDtoModel>): AttachmentSlimDtoModel {
    return new AttachmentSlimDtoModel(
      (overrides && overrides.attachmentId != null
        ? overrides.attachmentId
        : this.attachmentId
      ),
      (overrides && overrides.documentType != null
        ? overrides.documentType
        : this.documentType
      ),
      (overrides && overrides.name != null
        ? overrides.name
        : this.name
      ),
      (overrides && overrides.type != null
        ? overrides.type
        : this.type
      ),
      (overrides && overrides.size != null
        ? overrides.size
        : this.size
      ),
      (overrides && overrides.uploader !== undefined
        ? overrides.uploader
        : this.uploader?.clone() || null
      ),
      (overrides && overrides.uploadedOn !== undefined
        ? overrides.uploadedOn
        : this.uploadedOn?.clone() || null
      ),
      (overrides && overrides.outdater !== undefined
        ? overrides.outdater
        : this.outdater?.clone() || null
      ),
      (overrides && overrides.outdatedOn !== undefined
        ? overrides.outdatedOn
        : this.outdatedOn?.clone() || null
      ),
      (overrides && overrides.outdatedComment
        ? overrides.outdatedComment
        : this.outdatedComment
      ),
      (overrides && overrides.projectId
        ? overrides.projectId
        : this.projectId
      ),
      (overrides && overrides.workPackageId
        ? overrides.workPackageId
        : this.workPackageId
      ),
      (overrides && overrides.questionId
        ? overrides.questionId
        : this.questionId
      ),
      (overrides && overrides.metadata
        ? overrides.metadata
        : this.metadata.clone()
      ),
      (overrides && overrides.url
        ? overrides.url
        : this.url
      ),
      (overrides && overrides.validUntil
        ? overrides.validUntil
        : this.validUntil
      ),
    );
  }

  toJSON(): AttachmentSlimDto {
    return {
      attachmentId: this.attachmentId,
      projectId: this.projectId,
      workPackageId: this.workPackageId,
      questionId: this.questionId,
      documentType: this.documentType,
      fileName: this.fileName,
      fileType: this.fileType,
      fileSize: this.fileSize,
      uploader: (this.uploader ? this.uploader.toJSON() : null),
      uploadedOn: (this.uploadedOn ? this.uploadedOn.toJSON() : null),
      outdater: (this.outdater ? this.outdater.toJSON() : null),
      outdatedOn: (this.outdatedOn ? this.outdatedOn.toJSON() : null),
      outdatedComment: this.outdatedComment,
      metadata: this.metadata.toJSON(),
      url: this.url,
      validUntil: (this.validUntil ? this.validUntil.toJSON() : null),
    };
  }
}
