import { UnitPlanningUserWorkPackageDto } from '../../interfaces/dtos/unit-planning-user-work-package.dto';
import { UnitPlanningColumnTotalDtoModel } from './unit-planning-column-total.dto.model';
import { UnitPlanningUserWeekEntryViewSlimDtoModel } from './unit-planning-user-week-entry-view-slim.dto.model';

export class UnitPlanningUserWorkPackageDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly sequenceNumber: number,
    readonly title: string | null,
    readonly isCrossUtilized: boolean,
    readonly weeks: UnitPlanningUserWeekEntryViewSlimDtoModel[],
    readonly totals: UnitPlanningColumnTotalDtoModel | null,
  ) {
    this.weeks = this.weeks.map((week) => week.clone());
  }

  static fromJSON(json: UnitPlanningUserWorkPackageDto): UnitPlanningUserWorkPackageDtoModel {
    return new UnitPlanningUserWorkPackageDtoModel(
      json.workPackageId,
      json.sequenceNumber,
      json.title,
      json.isCrossUtilized,
      json.weeks.map((week) => UnitPlanningUserWeekEntryViewSlimDtoModel.fromJSON(week)),
      json.totals ? UnitPlanningColumnTotalDtoModel.fromJSON(json.totals) : null,
    );
  }

  clone(): UnitPlanningUserWorkPackageDtoModel {
    return new UnitPlanningUserWorkPackageDtoModel(
      this.workPackageId,
      this.sequenceNumber,
      this.title,
      this.isCrossUtilized,
      this.weeks.map((week) => week.clone()),
      this.totals?.clone() || null,
    );
  }
}
