import { WorkPackageUpdateStatusRequest } from '../../interfaces/requests/work-package-update-status.request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageUpdateStatusRequestModel {
  constructor(
    readonly workPackageId: number,
    readonly commentMessage: string = '',
    readonly defects: readonly string[] = [],
    readonly supplierUnitId: number | null = null,
    readonly sendNotifications: boolean = true,
  ) {
    this.defects = [...defects];
  }

  static fromJSON(json: WorkPackageUpdateStatusRequest): WorkPackageUpdateStatusRequestModel {
    assertRequiredProperties(json, [
      'workPackageId',
    ]);

    return new WorkPackageUpdateStatusRequestModel(
      json.workPackageId,
      json.commentMessage || '',
      (Array.isArray(json.defects)
        ? json.defects
        : []
      ),
      json.supplierUnitId,
      json.sendNotifications,
    );
  }

  toJSON(): WorkPackageUpdateStatusRequest {
    return {
      workPackageId: this.workPackageId,
      commentMessage: this.commentMessage,
      defects: [...this.defects],
      supplierUnitId: this.supplierUnitId,
      sendNotifications: this.sendNotifications,
    };
  }
}
