import moment from 'moment';
import { MetadataModel } from '../metadata.model';
import { LevelListDto } from '../../interfaces/dtos/level-list.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { MilestoneListDtoModel } from './milestone-list.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelListDtoModel {
  constructor(
    readonly levelId: number,
    readonly title: string,
    readonly projectNumberForSubmitterSettings: string,
    readonly activityItemCategoryNumberForSubmitterSettings: string,
    readonly sortOrder: number,
    readonly isHidden: boolean,
    readonly isDeletedInSap: boolean,
    readonly codePath: string = '',
    readonly startDate: moment.Moment | null = null,
    readonly endDate: moment.Moment | null = null,
    readonly reportedHours: number = 0,
    readonly hours: number = 0,
    readonly availableHours: number = 0,
    readonly hourlyRate: number = 0,
    readonly remainingHours: number | null = null,
    readonly cost: number | null = null,
    readonly costToComplete: number | null = null,
    readonly responsibleEngineer: UserSlimDtoModel | null = null,
    readonly hasChildren: boolean = false,
    readonly hasWorkPackages: boolean = false,
    readonly milestones: readonly MilestoneListDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
    readonly hasWarningSign: boolean | null = null,
    readonly warningMessage: string | null = null,
  ) {
    this.startDate = (startDate != null ? startDate.clone() : startDate);
    this.endDate = (endDate != null ? endDate.clone() : endDate);
    this.milestones = [...milestones];
  }

  static fromJSON(json: LevelListDto): LevelListDtoModel {
    assertRequiredProperties(json, [
      'levelId',
      'title',
      'sortOrder',
      'isHidden',
      'isDeletedInSap',
    ]);

    return new LevelListDtoModel(
      json.levelId,
      json.title,
      json.projectNumberForSubmitterSettings,
      json.activityItemCategoryNumberForSubmitterSettings,
      json.sortOrder,
      json.isHidden,
      json.isDeletedInSap,
      (json.codePath ?? undefined),
      json.startDate != null
        ? moment(json.startDate)
          .parseZone()
        : null,
      json.endDate != null
        ? moment(json.endDate)
          .parseZone()
        : null,
      json.reportedHours || 0,
      json.hours || 0,
      json.availableHours || 0,
      json.hourlyRate || 0,
      json.remainingHours,
      json.cost,
      json.costToComplete,
      (json.responsibleEngineer
        ? UserSlimDtoModel.fromJSON(json.responsibleEngineer)
        : undefined
      ),
      json.hasChildren,
      json.hasWorkPackages,
      (Array.isArray(json.milestones)
        ? json.milestones.map((item) => MilestoneListDtoModel.fromJSON(item))
        : undefined
      ),
      new MetadataModel(json.metadata || {}),
      json.hasWarningSign,
      json.warningMessage,
    );
  }

  clone(overrides?: Partial<LevelListDtoModel>): LevelListDtoModel {
    return new LevelListDtoModel(
      (overrides && overrides.levelId != null
        ? overrides.levelId
        : this.levelId
      ),
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.projectNumberForSubmitterSettings != null
        ? overrides.projectNumberForSubmitterSettings
        : this.projectNumberForSubmitterSettings
      ),
      (overrides && overrides.activityItemCategoryNumberForSubmitterSettings != null
        ? overrides.activityItemCategoryNumberForSubmitterSettings
        : this.activityItemCategoryNumberForSubmitterSettings
      ),
      (overrides && overrides.sortOrder != null
        ? overrides.sortOrder
        : this.sortOrder
      ),
      (overrides && overrides.isHidden != null
        ? overrides.isHidden
        : this.isHidden
      ),
      (overrides && overrides.isDeletedInSap != null
        ? overrides.isDeletedInSap
        : this.isDeletedInSap
      ),
      (overrides?.codePath ?? this.codePath),
      (overrides && overrides.startDate !== undefined
        ? overrides.startDate
        : this.startDate?.clone() || null
      ),
      (overrides && overrides.endDate !== undefined
        ? overrides.endDate
        : this.endDate?.clone() || null
      ),
      (overrides && overrides.reportedHours !== undefined
        ? overrides.reportedHours
        : this.reportedHours
      ),
      (overrides && overrides.hours !== undefined
        ? overrides.hours
        : this.hours
      ),
      (overrides && overrides.availableHours !== undefined
        ? overrides.availableHours
        : this.availableHours
      ),
      (overrides && overrides.hourlyRate !== undefined
        ? overrides.hourlyRate
        : this.hourlyRate
      ),
      (overrides && overrides.remainingHours !== undefined
        ? overrides.remainingHours
        : this.remainingHours
      ),
      (overrides && overrides.cost !== undefined
        ? overrides.cost
        : this.cost
      ),
      (overrides && overrides.costToComplete !== undefined
        ? overrides.costToComplete
        : this.costToComplete
      ),
      (overrides && overrides.responsibleEngineer !== undefined
        ? overrides.responsibleEngineer
        : this.responsibleEngineer?.clone() || null
      ),
      (overrides && overrides.hasChildren !== undefined
        ? overrides.hasChildren
        : this.hasChildren
      ),
      (overrides && overrides.hasWorkPackages !== undefined
        ? overrides.hasWorkPackages
        : this.hasWorkPackages
      ),
      (overrides && overrides.milestones !== undefined
        ? overrides.milestones
        : this.milestones.map((item) => item.clone())
      ),
      (overrides && overrides.metadata !== undefined
        ? overrides.metadata
        : this.metadata.clone()
      ),
      (overrides && overrides.hasWarningSign !== undefined
        ? overrides.hasWarningSign
        : this.hasWarningSign
      ),
      (overrides && overrides.warningMessage !== undefined
        ? overrides.warningMessage
        : this.warningMessage
      ),
    );
  }
}
