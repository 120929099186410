@if (canAdd && !forceDisabled) {
  <div
    class="collapp-unit-select__additional-supplier"
  [class.collapp-unit-select__additional-supplier--disabled]="
    !additionalSuppliersAndChrcpService.canAdd
    || !(additionalSuppliersAndChrcpService.availableToAdd$ | async)
    || (additionalSuppliersAndChrcpService.availableToAdd$ | async)?.length === 0
  "
    (click)="addSupplierUnit()"
    >
    @if ((additionalSuppliersAndChrcpService.availableToAdd$ | async) === undefined) {
      <collapp-loading-spinner
        [diameter]="16"
      ></collapp-loading-spinner>
    }
    @if ((additionalSuppliersAndChrcpService.availableToAdd$ | async) !== undefined) {
      <span
        class="collapp-unit-select__additional-supplier-text"
        >
      Add additional supplier
    </span>
  }
</div>
}
