/**
 * Contains all the different attachment types
 */
export enum DocumentType {
  Project = 0,
  WorkPackage = 1,
  ScopeChange = 2,
  Question = 3,
  Output = 4,
  Rework = 5,
}
