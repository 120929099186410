import moment from 'moment';
import { RangeSetting } from '../../interfaces/dtos/range-setting.dto';

export class RangeSettingModel {
  constructor(
    readonly fromDate: moment.Moment,
    readonly toDate: moment.Moment | null,
  ) {
    this.fromDate = fromDate.clone();
    this.toDate = toDate ? toDate.clone() : null;
  }

  static fromJSON(json: RangeSetting): RangeSettingModel {
    return new RangeSettingModel(
      moment(json.fromDate).parseZone(),
      json.toDate ? moment(json.toDate).parseZone() : null,
    );
  }

  toJSON(): RangeSetting {
    return {
      fromDate: this.fromDate.toString(),
      toDate: this.toDate ? this.toDate.toString() : null,
    };
  }
}
