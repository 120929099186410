import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { WorkpackageSidepanelStateModel } from './work-package-sidepanel-state.model';
import { WorkpackageSidepanelState } from './work-package-sidepanel.state';

@Injectable({
  providedIn: 'root',
})
export class WorkpackageSidepanelSelectors {
  @Selector([WorkpackageSidepanelState])
  static isOpen(state: WorkpackageSidepanelStateModel): boolean {
    return state.isOpen;
  }
}
