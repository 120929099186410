import { UnitSkillDto } from '../../interfaces/dtos/unit-skill.dto';

export class UnitSkillDtoModel {
  set group(name: string | undefined) {
    this._group = name;
  }

  get group(): string | undefined {
    return this._group;
  }

  private _group: string | undefined = undefined;

  constructor(
    readonly unitId: number,
    readonly name: string,
    readonly order: number,
    readonly canUpdateSkills: boolean,
    readonly canSelectSkills: boolean,
    readonly canUpdateCategories: boolean,
    readonly canManageMatrix: boolean,
    readonly level: number,
    readonly metadata: Record<any, any>,
  ) {}

  static fromJSON(json: UnitSkillDto): UnitSkillDtoModel {
    return new UnitSkillDtoModel(
      json.unitId,
      json.name,
      json.order,
      json.canUpdateSkills,
      json.canSelectSkills,
      json.canUpdateCategories,
      json.canManageMatrix,
      json.level,
      json.metadata,
    );
  }

  clone(): UnitSkillDtoModel {
    return new UnitSkillDtoModel(
      this.unitId,
      this.name,
      this.order,
      this.canUpdateSkills,
      this.canSelectSkills,
      this.canUpdateCategories,
      this.canManageMatrix,
      this.level,
      this.metadata,
    );
  }

  toJSON(): UnitSkillDto {
    return {
      unitId: this.unitId,
      name: this.name,
      order: this.order,
      canUpdateSkills: this.canUpdateSkills,
      canSelectSkills: this.canSelectSkills,
      canUpdateCategories: this.canUpdateCategories,
      canManageMatrix: this.canManageMatrix,
      level: this.level,
      metadata: this.metadata,
    };
  }
}
