import { CollappFilterDropdownListRadioSetting } from '../../models/collapp-filter-dropdown-list-radio.interface';
import { ProjectPlanningFiltersSettingsModel } from '../../models/project-planning-filter-settings.interface';
import { UserDtoModel } from '../../api/models/dtos/user.dto.model';
import { ProjectRecentListDtoModel } from '../../api/models/dtos/project-recent-list.dto.model';
import { TimecardSettings } from '../../models/time-tracking-settings';
import { ProjectStructureFiltersSettingsModel } from '../../models/project-structure-filter-settings.interface';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UserStateActions {
  export class SetLoginUser {
    static readonly type: string = '[User] Set Login User';

    constructor(public user: UserDtoModel) {}
  }

  export class ClearLoginUser {
    static readonly type: string = '[User] Clear Login User';
  }

  export class ClearAllUserData {
    static readonly type: string = '[User] Clear All User Data';
  }

  export class SetOriginalUser {
    static readonly type: string = '[User] Set Original User';

    constructor(public user: UserDtoModel) {}
  }

  export class ClearOriginalUser {
    static readonly type: string = '[User] Clear Original User';
  }

  export class AddRecentProject {
    static readonly type: string = '[User] Add Recent Project';

    constructor(public project: ProjectRecentListDtoModel) {}
  }

  export class SetRecentProjects {
    static readonly type: string = '[User] Set Recent Projects';

    constructor(public projects: readonly ProjectRecentListDtoModel[]) {}
  }

  export class SetRecentTimecard {
    static readonly type: string = '[User] Set Recent Timecard';

    constructor(public timecard: TimecardSettings) {}
  }

  export class UpdateTimecard {
    static readonly type: string = '[User] Update Timecard';
  }

  export class UpdateUserTimeZone {
    static readonly type: string = '[User] Update User TimeZone';

    constructor(public timeZone: string, public offset: number) {}
  }

  export class UpdateUserLocalLanguageSettings {
    static readonly type: string = '[User] Update User Local Language Settings';

    constructor(public useLocal: boolean) {}
  }

  export class UpdateProjectStructureFilterSettings {
    static readonly type: string = '[User] UpdateSettingsStructureFilters';

    constructor(
      public projectStructureHeaderFilterSettings: ProjectStructureFiltersSettingsModel,
    ) {}
  }

  export class UpdateTimecardHeaderFilterSettings {
    static readonly type: string = '[User] UpdateTimecardHeaderFilterSettings';

    constructor(
      public filterSettings: CollappFilterDropdownListRadioSetting[],
    ) {}
  }

  export class UpdatePlanningHeaderFilterSettings {
    static readonly type: string = '[User] UpdatePlanningHeaderFilterSettings';

    constructor(
      public filterSettings: CollappFilterDropdownListRadioSetting[],
    ) {}
  }

  export class UpdateProjectPlanningFilterSettings {
    static readonly type: string = '[User] UpdateSettingsProjectPlanningFilters';

    constructor(
      public projectPlanningHeaderFilterSettings: ProjectPlanningFiltersSettingsModel,
    ) {}
  }

  export class UpdateProjectPlanningCompactFilter {
    static readonly type: string = '[User] UpdateProjectPlanningCompactFilter';

    constructor(
      public projectPlanningCompactFilter: boolean,
    ) {}
  }
}
