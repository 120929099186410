
import { CostObjectDto } from '../../interfaces/dtos/cost-object.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class CostObjectDtoModel {
  constructor(
    readonly levelId: number,
    readonly projectTitle: string,
    readonly levelTitle: string,
    readonly code: string, // TODO: COLLAPP-4535 COST OBJECT | project number
    readonly item: string | null, // activityItemCategoryNumber
    readonly projectType: string, // PROJECT TYPE
    readonly serviceOrder: string,
  ) {
  }

  static fromJSON(json: CostObjectDto): CostObjectDtoModel {
    assertRequiredProperties(json, [
      'projectTitle',
      'code',
    ]);

    return new CostObjectDtoModel(
      json.levelId,
      json.projectTitle,
      json.levelTitle,
      json.code,
      json.item,
      json.projectType,
      json.serviceOrder,
    );
  }

  toJSON(): CostObjectDto {
    return {
      levelId: this.levelId,
      projectTitle: this.projectTitle,
      levelTitle: this.levelTitle,
      code: this.code,
      item: this.item || null,
      projectType: this.projectType,
      serviceOrder: this.serviceOrder,
    };
  }
}
