import { UnitPlanningSlimDtoModel } from '../../../api/models/dtos/unit-planning-slim.dto.model';
import { UnitSlimDtoModel } from '../../../api/models/dtos/unit-slim.dto.model';

/**
 * Store actions for manipulating timesheet user settings.
 */
export enum TimesheetActionTypes {
  InitUserTimesheetSettings = '[API] Init User Timesheet Settings State',
  SetTimesheetCrossUtilizationState = '[Timesheet] Set Timesheet Cross Utilization State',
  ResetTimesheetCrossUtilizationState = '[API] Reset Timesheet Cross Utilization State',
  SaveTimesheetCrossUtilizationSettings = '[API] Save Timesheet Cross Utilization Settings',
  AddTimesheetRecentUnit = '[Timesheet] Add Timesheet Recent Unit',
  AddExpandedUnitId = '[Timecards Page] Add Expanded Unit Id',
  RemoveExpandedUnitId = '[Timecards Page] Remove Expanded Unit Id',
}

/**
 * In the backend only the cross utilization flag and a list of recent units are stored.
 * The week and year is not stored in the backend.
 */
export class InitUserTimesheetSettings {
  static readonly type: string = TimesheetActionTypes.InitUserTimesheetSettings;

  constructor(
    readonly recentUnits: readonly UnitSlimDtoModel[],
    readonly withCrossUtilization: boolean,
  ) {}
}

export class SetTimesheetCrossUtilizationState {
  static readonly type: string = TimesheetActionTypes.SetTimesheetCrossUtilizationState;

  constructor(readonly withCrossUtilization: boolean) {}
}

export class ResetTimesheetCrossUtilizationState {
  static readonly type: string = TimesheetActionTypes.ResetTimesheetCrossUtilizationState;
}

export class SaveTimesheetCrossUtilizationSettings {
  static readonly type: string = TimesheetActionTypes.SaveTimesheetCrossUtilizationSettings;

  constructor(public withCrossUtilization: boolean) {}
}

export class AddTimesheetRecentUnit {
  static readonly type: string = TimesheetActionTypes.AddTimesheetRecentUnit;

  constructor(public unit: UnitSlimDtoModel | UnitPlanningSlimDtoModel) {}
}

export class AddExpandedUnitId {
  static readonly type: string = TimesheetActionTypes.AddExpandedUnitId;

  constructor(readonly unitId: number) {}
}

export class RemoveExpandedUnitId {
  static readonly type: string = TimesheetActionTypes.RemoveExpandedUnitId;

  constructor(readonly unitId: number) {}
}
