import moment from 'moment';
import { RatingDtoModel } from './rating.dto.model';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { FeedbackDto } from '../../interfaces/dtos/feedback.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class FeedbackDtoModel {
  constructor(
    readonly feedbackId: number,
    readonly createdOn: moment.Moment,
    readonly creator: UserSlimDtoModel,
    readonly isSupplierFeedback: boolean,
    readonly rating: RatingDtoModel,
    readonly comment: string = '',
  ) {
    this.createdOn = createdOn.clone();
  }

  static fromJSON(json: FeedbackDto): FeedbackDtoModel {
    assertRequiredProperties(json, [
      'feedbackId',
      'createdOn',
      'creator',
      'rating',
    ]);

    return new FeedbackDtoModel(
      json.feedbackId,
      moment(json.createdOn)
        .parseZone(),
      UserSlimDtoModel.fromJSON(json.creator),
      json.isSupplierFeedback,
      RatingDtoModel.fromJSON(json.rating),
      json.comment || '',
    );
  }

  toJSON(): FeedbackDto {
    return {
      feedbackId: this.feedbackId,
      createdOn: this.createdOn.toJSON(),
      creator: this.creator.toJSON(),
      isSupplierFeedback: this.isSupplierFeedback,
      rating: this.rating,
      comment: this.comment,
    };
  }
}
