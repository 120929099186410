import { escape } from 'html-escaper';
import { UnitCreateRequest } from '../../interfaces/requests/unit-create.request';
import { ContractSaveRequestModel } from './contract-save.request.model';
import { NonProductiveCategoryModel } from '../../../models/non-productive-category.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CostCenerSaveRequest } from '../../interfaces/requests/cost-center-save.request';

export class UnitCreateRequestModel {
  constructor(
    readonly code: string,
    readonly name: string,
    readonly unitCoordinatorId: string,
    readonly parentUnitId: number | null = null,
    readonly defects: readonly string[] = [],
    readonly deputyUnitCoordinatorIds: readonly string[] = [],
    readonly submitterContracts: readonly ContractSaveRequestModel[] = [],
    readonly nonProductiveCategories: readonly NonProductiveCategoryModel[] = [],
    readonly timesheetExportTemplateColumns: string = '',
    readonly timesheetExportTemplateHighlighting: string = '',
    readonly timesheetExportNonProductiveTemplateColumns: string = '',
    readonly timesheetExportNonProductiveTemplateHighlighting: string = '',
    readonly directUserSubmissionOfTimecard: boolean,
    readonly costCenters: readonly CostCenerSaveRequest[] = [],
    readonly hiddenLevels: string = '',
    readonly payrollCompany: string = '',
    readonly profitCenter: string = '',
  ) {
    this.defects = [...defects];
    this.deputyUnitCoordinatorIds = [...deputyUnitCoordinatorIds];
    this.submitterContracts = [...submitterContracts];
    this.nonProductiveCategories = [...nonProductiveCategories];
  }

  static fromJSON(json: UnitCreateRequest): UnitCreateRequestModel {
    assertRequiredProperties(json, [
      'code',
      'name',
      'unitCoordinatorId',
    ]);

    return new UnitCreateRequestModel(
      json.code,
      json.name,
      json.unitCoordinatorId,
      json.parentUnitId,
      json.defects || [],
      json.deputyUnitCoordinatorIds || [],
      (Array.isArray(json.submitterContracts) ? json.submitterContracts : [])
        .map((item) => ContractSaveRequestModel.fromJSON(item)),
      (Array.isArray(json.nonProductiveCategories) ? json.nonProductiveCategories : [])
        .map((item) => NonProductiveCategoryModel.fromJSON(item)),
      json.timesheetExportTemplateColumns || '',
      json.timesheetExportTemplateHighlighting || '',
      json.timesheetExportNonProductiveTemplateColumns || '',
      json.timesheetExportNonProductiveTemplateHighlighting || '',
      json.directUserSubmissionOfTimecard,
      // TODO: check
      (Array.isArray(json.costCenters) ? json.costCenters : [])
        .map((item) => item),
      json.hiddenLevels || '',
      json.payrollCompany || '',
      json.profitCenter || '',
    );
  }

  toJSON(): UnitCreateRequest {
    return {
      code: escape(this.code),
      name: escape(this.name),
      defects: [...this.defects].map((defect) => escape(defect)),
      unitCoordinatorId: this.unitCoordinatorId,
      parentUnitId: this.parentUnitId,
      deputyUnitCoordinatorIds: [...this.deputyUnitCoordinatorIds],
      submitterContracts: this.submitterContracts.map((item) => item.toJSON()),
      nonProductiveCategories: this.nonProductiveCategories.map((item) => item.toJSON()),
      timesheetExportTemplateColumns: this.timesheetExportTemplateColumns,
      timesheetExportTemplateHighlighting: this.timesheetExportTemplateHighlighting,
      timesheetExportNonProductiveTemplateColumns: this.timesheetExportNonProductiveTemplateColumns,
      timesheetExportNonProductiveTemplateHighlighting: this.timesheetExportNonProductiveTemplateHighlighting,
      directUserSubmissionOfTimecard: this.directUserSubmissionOfTimecard,
      // TODO: check
      costCenters: this.costCenters.map((item) => item),
      hiddenLevels: this.hiddenLevels,
      payrollCompany: this.payrollCompany,
      profitCenter: this.profitCenter,
    };
  }
}
