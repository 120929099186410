import { ProjectDashboardListResponse } from '../../interfaces/responses/project-dashboard-list.response';
import { ProjectDashboardDtoModel } from '../dtos/project-dashboard.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectDashboardListResponseModel {
  constructor(
    readonly items: readonly ProjectDashboardDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: ProjectDashboardListResponse): ProjectDashboardListResponseModel {
    assertRequiredProperties(json, [
    ]);

    return new ProjectDashboardListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => ProjectDashboardDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): ProjectDashboardListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
