import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxsModule } from '@ngxs/store';
import { sharedStates } from '../../state';
import { CollappCommonModule } from '../../collapp-common';
import { PersonIconComponent } from './person-icon.component';
import { PersonIconOverlayTemplateDirective } from './person-icon-overlay-template.directive';

@NgModule({
  imports: [
    CommonModule,
    MatBadgeModule,
    // @TODO Is this correct?
    NgxsModule.forFeature(sharedStates),
    CollappCommonModule,
  ],
  exports: [
    PersonIconComponent,
    PersonIconOverlayTemplateDirective,
  ],
  declarations: [
    PersonIconComponent,
    PersonIconOverlayTemplateDirective,
  ],
})
export class PersonIconModule { }
