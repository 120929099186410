import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { WorkPackageSidepanelIndexStateModel } from './work-package-sidepanel-index-state.model';
import { WorkPackageSidepanelIndexState } from './work-package-sidepanel-index.state';

@Injectable({
  providedIn: 'root',
})
export class WorkPackageSidepanelIndexSelectors {
  @Selector([WorkPackageSidepanelIndexState])
  static index(state: WorkPackageSidepanelIndexStateModel): number {
    return state.index;
  }
}
