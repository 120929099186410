import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitCostCenterActivityTypeDto } from '../../interfaces/dtos/unit-cost-center-activity-type.dto';

export class UnitCostCenterActivityTypeModel implements UnitCostCenterActivityTypeDto {
  constructor(
    readonly activityType: string,
    readonly isDefault: boolean,
    readonly description: string,
    readonly unitCostCenterActivityTypeId: number | null = null,
  ) {}

  static fromJSON(json: UnitCostCenterActivityTypeDto): UnitCostCenterActivityTypeModel {
    assertRequiredProperties(json, [
        'activityType',
    ]);

    return new UnitCostCenterActivityTypeModel(
      json.activityType,
      json.isDefault,
      json.description,
      json.unitCostCenterActivityTypeId,
    );
  }

  toJSON(): UnitCostCenterActivityTypeDto {
    return {
      activityType: this.activityType,
      isDefault: this.isDefault,
      description: this.description,
      unitCostCenterActivityTypeId: this.unitCostCenterActivityTypeId,
    };
  }
}
