import moment from 'moment';
import { WorkPackageReworkDto } from '../../interfaces/dtos/work-package-rework.dto';
import { AttachmentSlimDtoModel } from './attachment-slim.dto.model';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageReworkDtoModel implements ApiModelNew {
  constructor(
    readonly reworkId: number,
    readonly workPackageId: number,
    readonly createdOn: moment.Moment,
    readonly description: string = '',
    readonly defects: readonly string[] = [],
    readonly attachments: readonly AttachmentSlimDtoModel[] = [],
  ) {
    this.createdOn = createdOn.clone();
    this.defects = [...defects];
    this.attachments = [...attachments];
  }

  static fromJSON(json: WorkPackageReworkDto): WorkPackageReworkDtoModel {
    assertRequiredProperties(json, [
      'reworkId',
      'workPackageId',
      'createdOn',
    ]);

    return new WorkPackageReworkDtoModel(
      json.reworkId,
      json.workPackageId,
      moment(json.createdOn)
        .parseZone(),
      json.description,
      [...json.defects],
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
    );
  }

  // eslint-disable-next-line complexity
  clone(overrides?: Partial<WorkPackageReworkDtoModel>): WorkPackageReworkDtoModel {
    return new WorkPackageReworkDtoModel(
      (overrides && overrides.reworkId != null
        ? overrides.reworkId
        : this.reworkId
      ),
      (overrides && overrides.workPackageId != null
        ? overrides.workPackageId
        : this.workPackageId
      ),
      (overrides && overrides.createdOn != null
        ? overrides.createdOn
        : this.createdOn
      ),
      (overrides && overrides.description != null
        ? overrides.description
        : this.description
      ),
      (overrides && overrides.defects != null
        ? overrides.defects
        : [...this.defects]
      ),
      (overrides && overrides.attachments != null
        ? overrides.attachments
        : this.attachments.map((item) => item.clone())
      ),
    );
  }

  toJSON(): WorkPackageReworkDto {
    return {
      reworkId: this.reworkId,
      workPackageId: this.workPackageId,
      description: this.description,
      defects: [...this.defects],
      createdOn: this.createdOn.toJSON(),
      attachments: this.attachments.map((item) => item.toJSON()),
    };
  }
}
