import { LevelListResponse } from '../../interfaces/responses/level-list.response';
import { LevelListDtoModel } from '../dtos/level-list.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelListResponseModel {
  constructor(
    readonly items: readonly LevelListDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items]
      .sort((a, b) => (a.sortOrder - b.sortOrder));
  }

  static fromJSON(json: LevelListResponse): LevelListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    const items = json.items
      .map((item) => LevelListDtoModel.fromJSON(item))
      .sort((a, b) => (a.sortOrder - b.sortOrder));

    return new LevelListResponseModel(
      items,
      new MetadataModel(json.metadata || {}),
    );
  }

  clone(): LevelListResponseModel {
    return new LevelListResponseModel(
      this.items,
      this.metadata,
    );
  }
}
