@if (error) {
  <h1>
    Error logging out.
  </h1>
  <p>
    <strong>{{error.name}}</strong><br />
    {{error.message}}<br />
    Code: {{error.code}}
  </p>
} @else {
  <h1>
    You have been logged out.
  </h1>
  <p>
    Use the login button below to log back into the application.
  </p>
  <br />
  @if (!authenticated) {
    <button type="button" collapp-flat-button color="primary" (click)="login()">Login</button>
  }
}
