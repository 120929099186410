import { GenericListOptions } from '../generic-list';
import { UserResourceColumnFilters } from '../../../api/filter-columns';
import { UserResourceListStateModel } from './user-resource-list-state.model';

/**
 * Action types for UserResourceListState
 */
export enum UserResourceListActionTypes {
  SetUserResourceListSelectedColumns = '[ Resource Planning List Page] Set Selected Columns',
  SetUserResourceListOptions = '[ Resource Planning List Page] Set Options',
  InitUserResourceListSettings = '[ Resource Planning API] Initialize Resource Planning List Settings',
  SetUserResourceListSettings = '[ Resource Planning API] Set Resource Planning List Settings',
  ResetUserResourceListSettings = '[ Resource Planning API] Reset Resource Planning List Settings',
  SaveUserResourceListSettings = '[ Resource Planning API] Save Resource Planning List Settings',
}

export interface UserResourceListOptions extends GenericListOptions {
  columnFilters: UserResourceColumnFilters;
}

export class SetUserResourceListSelectedColumns {
  static readonly type: string = UserResourceListActionTypes.SetUserResourceListSelectedColumns;

  constructor(public columns: []) {}
}

export class SetUserResourceListOptions {
  static readonly type: string = UserResourceListActionTypes.SetUserResourceListOptions;

  constructor(public options: UserResourceListOptions) {}
}

export class InitUserResourceListSettings {
  static readonly type: string = UserResourceListActionTypes.InitUserResourceListSettings;

  constructor(public settings: UserResourceListStateModel) {}
}

export class SetUserResourceListSettings {
  static readonly type: string = UserResourceListActionTypes.SetUserResourceListSettings;

  constructor(public settings: UserResourceListStateModel) {}
}

export class ResetUserResourceListSettings {
  static readonly type: string = UserResourceListActionTypes.ResetUserResourceListSettings;
}

export class SaveUserResourceListSettings {
  static readonly type: string = UserResourceListActionTypes.SaveUserResourceListSettings;

  constructor(public settings: UserResourceListStateModel) {}
}
