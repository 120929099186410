import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { WorkPackageTransitionInformationResponse } from '../../interfaces/responses/work-package-transition-information.response';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageTransitionInformationResponseModel {
  constructor(
    readonly defects: readonly string[] = [],
    readonly l5Units: readonly UnitSlimDtoModel[] = [],
    readonly notificationRecipients: readonly UserSlimDtoModel[] = [],
  ) {
    this.defects = [...defects];
    this.l5Units = [...l5Units];
    this.notificationRecipients = [...notificationRecipients];
  }

  static fromJSON(
    json: WorkPackageTransitionInformationResponse,
  ): WorkPackageTransitionInformationResponseModel {
    assertRequiredProperties(json, [
    ]);

    return new WorkPackageTransitionInformationResponseModel(
      (Array.isArray(json.defects) ? json.defects : [])
        .map((item) => item),
      (Array.isArray(json.l5Units) ? json.l5Units : [])
        .map((item) => UnitSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.notificationRecipients) ? json.notificationRecipients : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
    );
  }

  toJSON(): WorkPackageTransitionInformationResponse {
    return {
      defects: [...this.defects],
      l5Units: this.l5Units.map((item) => item.toJSON()),
      notificationRecipients: this.notificationRecipients.map((item) => item.toJSON()),
    };
  }
}
