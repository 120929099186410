import { BasicWorkPackageViewListResponse } from '../../interfaces/responses/basic-work-package-view-list.response';
import { BasicWorkPackageViewListItemResponseModel } from './basic-work-package-view-list-item.response.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class BasicWorkPackageViewListResponseModel {
  constructor(
    readonly items: readonly BasicWorkPackageViewListItemResponseModel[],
  ) {
    this.items = [...items];
  }

  static fromJSON(json: BasicWorkPackageViewListResponse): BasicWorkPackageViewListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new BasicWorkPackageViewListResponseModel(
      (Array.isArray(json.items)
        ? json.items
          .map((item) => BasicWorkPackageViewListItemResponseModel.fromJSON(item))
        : []
      ),
    );
  }
}
