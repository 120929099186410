import moment from 'moment';
import { ProjectWorkpackageWeekDto } from '../../interfaces/dtos/project-workpackage-week.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { MetadataSlimDtoModel } from './metadata-slim.dto.model';
import { NoteInterfaceWithResourceData } from '../../interfaces/note.interface';

export class ProjectWorkpackageWeekDtoModel {
  constructor(
    readonly weekStartDate: moment.Moment | null,
    readonly plannedHours: number,
    readonly isHighlight: boolean,
    readonly metadata: MetadataSlimDtoModel,
    public notes: NoteInterfaceWithResourceData[] | null,
    public hasNotes: boolean = false,
  ) {}

  static fromJSON(json: ProjectWorkpackageWeekDto): ProjectWorkpackageWeekDtoModel {
    assertRequiredProperties(json, [
      'weekStartDate',
    ]);

    return new ProjectWorkpackageWeekDtoModel(
      json.weekStartDate ? moment(json.weekStartDate) : null,
      json.plannedHours,
      json.isHighlight,
      MetadataSlimDtoModel.fromJSON(json.metadata),
      json.notes,
      json.hasNotes,
    );
  }

  clone(): ProjectWorkpackageWeekDtoModel {
    return new ProjectWorkpackageWeekDtoModel(
      this.weekStartDate?.clone() || null,
      this.plannedHours,
      this.isHighlight,
      this.metadata?.clone() || null,
      this.notes?.map((note) => ({ ...note })) || null,
      this.hasNotes,
    );
  }
}
