import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TimesheetStateModel } from './timesheet-state.model';
import {
  AddExpandedUnitId,
  AddTimesheetRecentUnit,
  InitUserTimesheetSettings,
  RemoveExpandedUnitId,
  ResetTimesheetCrossUtilizationState,
  SaveTimesheetCrossUtilizationSettings,
  SetTimesheetCrossUtilizationState,
} from './timesheet.actions';
import { UserService } from '../../../api/services/user.service';

const MAX_NUMBER_OF_RECENT_UNITS = 4;

const defaultTimesheetState: TimesheetStateModel = {
  week: 0,
  year: 0,
  recentUnits: [],
  withCrossUtilization: false,
  collapsedUnitIds: [],
};

@State<TimesheetStateModel>({
  name: 'timesheet',
  defaults: defaultTimesheetState,
})
@Injectable({
  providedIn: 'root',
})
export class TimesheetState {
  constructor(
    private userService: UserService,
  ) {
  }

  @Action(InitUserTimesheetSettings)
  initUserTimesheetSettingsState(
    ctx: StateContext<TimesheetStateModel>,
    { recentUnits, withCrossUtilization }: InitUserTimesheetSettings,
  ): void {
    ctx.patchState({
      recentUnits,
      withCrossUtilization,
    });
  }

  @Action(SetTimesheetCrossUtilizationState)
  setCrossUtilizationStateState$(
    ctx: StateContext<TimesheetStateModel>,
    { withCrossUtilization }: SetTimesheetCrossUtilizationState,
  ): Observable<void> {
    ctx.patchState({
      withCrossUtilization,
    });

    return ctx.dispatch(new SaveTimesheetCrossUtilizationSettings(withCrossUtilization));
  }

  @Action(ResetTimesheetCrossUtilizationState)
  resetUserCrossUtilizationState(ctx: StateContext<TimesheetStateModel>): void {
    ctx.setState(defaultTimesheetState);
  }

  @Action(SaveTimesheetCrossUtilizationSettings)
  saveTimesheetSettings$(
    ctx: StateContext<TimesheetStateModel>,
    { withCrossUtilization }: SaveTimesheetCrossUtilizationSettings,
  ): Observable<void> {
    return this.userService.saveTimesheetCrossUtilizationSettings$(withCrossUtilization);
  }

  @Action(AddTimesheetRecentUnit)
  addTimesheetRecentUnit(
    { patchState, getState }: StateContext<TimesheetStateModel>,
    { unit }: AddTimesheetRecentUnit,
  ): void {
    const { recentUnits } = getState();

    // Check if given unit already exists in queue
    const existingUnitIndex = recentUnits.findIndex((element) => (element.unitId === unit.unitId));

    // Create a new array of units with the most recent in front.
    const updatedRecentUnits = [unit, ...recentUnits];

    if (existingUnitIndex !== -1) {
      // Remove duplicates
      updatedRecentUnits.splice(existingUnitIndex + 1, 1);
    }

    // Limit queue to a given maximum number of entries.
    updatedRecentUnits.length = Math.min(updatedRecentUnits.length, MAX_NUMBER_OF_RECENT_UNITS);

    patchState({
      recentUnits: updatedRecentUnits,
    });
  }

  @Action(AddExpandedUnitId)
  addExpandedUnitsId(ctx: StateContext<TimesheetStateModel>, { unitId }: AddExpandedUnitId): void {
    const { collapsedUnitIds } = ctx.getState();

    ctx.patchState({
      collapsedUnitIds: collapsedUnitIds.filter((id) => id !== unitId),
    });
  }

  @Action(RemoveExpandedUnitId)
  removeExpandedUnitsId(ctx: StateContext<TimesheetStateModel>, { unitId }: RemoveExpandedUnitId): void {
    const { collapsedUnitIds } = ctx.getState();

    const set = new Set(collapsedUnitIds);

    set.add(unitId);

    ctx.patchState({
      collapsedUnitIds: Array.from(set),
    });
  }
}
