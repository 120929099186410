import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserListStateModel } from './user-list-state.model';
import {
  InitUserListSettings,
  ResetUserListOptions,
  ResetUserListSettings,
  SaveUserListSettings,
  SetUserListOptions,
  SetUserListSelectedColumns,
  SetUserListSettings,
} from './user-list.actions';
import { usersListColumnsInfo } from '../../../api/meta-info/users-list.info';
import { UserService } from '../../../api/services/user.service';
import { USER_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUserListState: UserListStateModel = {
  selectedColumns: Array.from(usersListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: USER_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UserListStateModel>({
  name: 'userList',
  defaults: defaultUserListState,
})
@Injectable({
  providedIn: 'root',
})
export class UserListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetUserListSelectedColumns)
  setUserListSelectedColumns$(
    ctx: StateContext<UserListStateModel>,
    { columns }: SetUserListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, usersListColumnsInfo));

    return ctx.dispatch(new SaveUserListSettings(ctx.getState()));
  }

  @Action(SetUserListOptions)
  setUserListOptions$(ctx: StateContext<UserListStateModel>, { options }: SetUserListOptions): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, usersListColumnsInfo));

    return ctx.dispatch(new SaveUserListSettings(ctx.getState()));
  }

  @Action(ResetUserListOptions)
  resetUserListOptions$(ctx: StateContext<UserListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveUserListSettings(ctx.getState()));
  }

  @Action(InitUserListSettings)
  initUserListSettings(ctx: StateContext<UserListStateModel>, { settings }: InitUserListSettings): void {
    ctx.patchState(this.cloneListSettings(settings, usersListColumnsInfo));
  }

  @Action(SetUserListSettings)
  setUserListSettings$(
    ctx: StateContext<UserListStateModel>,
    { settings }: SetUserListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, usersListColumnsInfo));

    return ctx.dispatch(new SaveUserListSettings(ctx.getState()));
  }

  @Action(ResetUserListSettings)
  resetUserListSettings(ctx: StateContext<UserListStateModel>): void {
    ctx.setState(defaultUserListState);
  }

  @Action(SaveUserListSettings)
  saveUserListSettings$(
    ctx: StateContext<UserListStateModel>,
    { settings }: SaveUserListSettings,
  ): Observable<void> {
    return this.userService.saveUserListSettings$(settings);
  }
}
