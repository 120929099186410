import moment from 'moment';
import { environment } from '../../../environments/environment';
import { RequiredOnType } from '../../app.types';

export const getEndpointUrl = (pathname: string): string => environment.apiEndpoint + pathname;

export const obj2fd = (obj: any, form?: FormData, namespace?: string): FormData => {
  const fd = form || new FormData();
  let formKey;

  Object.entries(obj).forEach(([key, value]) => {
    formKey = namespace ? `${namespace}[${key}]` : key;

    if (value == null) {
      // Just skip value if null or undefined. FormData.delete() does not work cross-browser,
      // i.e. Internet Explorer / Edge does not support this spec.
      // fd.delete(formKey);

    } else if (moment.isMoment(value) || value instanceof Date) {
      fd.append(formKey, value.toISOString());
    } else if (typeof value === 'object' && !(value instanceof File)) {
      obj2fd(value, fd, formKey);
    } else if (value instanceof File) {
      // if it's a string or a File object
      // Don't use [] annotation for file arrays
      // Example attachments[1] => attachments
      fd.append(namespace || formKey, value);
    } else {
      fd.append(formKey, `${value}`);
    }
  });

  return fd;
};

export class AssertionError extends Error {
  readonly name: string = 'AssertionError';
}

/**
 * Checks whether the given required properties are null or undefined on the given object.
 * Per default, throws an exception on error.
 */
export function assertRequiredProperties<T extends Record<string, any>, KRequired extends keyof T>(
  obj: T,
  requiredProperties: KRequired[],
): asserts obj is RequiredOnType<T, KRequired> {
  requiredProperties.forEach((requiredProperty) => {
    if (obj[requiredProperty] == null) {
      throw new AssertionError(`Missing required property '${requiredProperty.toString()}' on object.`);
    }
  });
}
