import { MatDateFormats } from '@angular/material/core';
import { COLLAPP_DATE_FORMAT, COLLAPP_LONG_MONTH_YEAR_FORMAT, COLLAPP_SHORT_MONTH_YEAR_FORMAT } from '../collapp-core/date';

export const COLLAPP_MAT_MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: COLLAPP_DATE_FORMAT, // previous default: 'l'
  },
  display: {
    dateInput: COLLAPP_DATE_FORMAT, // previous default: 'l'
    monthYearLabel: COLLAPP_SHORT_MONTH_YEAR_FORMAT, // previous default: 'MMM YYYY'
    dateA11yLabel: 'LL', // Month name, day of month, year in current locale, i.e. "September 4 1986"
    monthYearA11yLabel: COLLAPP_LONG_MONTH_YEAR_FORMAT, // previous default: 'MMMM YYYY'
  },
};
