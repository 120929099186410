import { UnitPlanningViewResponse } from '../../interfaces/responses/unit-planning-view.response';
import { UnitCategoryDtoModel } from '../dtos/unit-category.dto.model';
import { UnitPlanningDtoModel } from '../dtos/unit-planning.dto.model';
import { UnitPlanningCapacityDtoModel } from '../dtos/unit-planning-capacity.dto.model';
import { MetadataSlimDtoModel } from '../dtos/metadata-slim.dto.model';
import { Int32IdNameDtoModel } from '../dtos/int32-id-name.dto.model';
import { UnitBackgroundColorDtoModel } from '../dtos/unit-background-color.dto.model';
import { AvailableProjectDtoModel } from '../dtos/available-project.dto.model';

export class UnitPlanningViewResponseModel {
  constructor(
    readonly capacities: UnitPlanningCapacityDtoModel[],
    readonly units: UnitPlanningDtoModel[],
    readonly nonProductiveCategories: UnitCategoryDtoModel[],
    readonly availableSkills: Int32IdNameDtoModel[],
    readonly unitBackgroundColors: UnitBackgroundColorDtoModel[],
    readonly availableProjects: AvailableProjectDtoModel[],
    readonly metadata: MetadataSlimDtoModel,
  ) {
    if (capacities) {
      this.capacities = [...capacities];
    }

    if (nonProductiveCategories) {
      this.nonProductiveCategories = [...nonProductiveCategories];
    }

    if (units) {
      this.units = [...units];
    }

    this.availableSkills = availableSkills.map((skill) => skill.clone());
    this.unitBackgroundColors = unitBackgroundColors.map((item) => item.clone());
    this.availableProjects = availableProjects.map((item) => item.clone());
  }

  static fromJSON(json: UnitPlanningViewResponse): UnitPlanningViewResponseModel {
    return new UnitPlanningViewResponseModel(
      (Array.isArray(json.capacities)
        ? json.capacities
          .map(UnitPlanningCapacityDtoModel.fromJSON)
        : []
      ),
      (Array.isArray(json.units)
        ? json.units
          .map(UnitPlanningDtoModel.fromJSON)
        : []
      ),
      (Array.isArray(json.nonProductiveCategories)
        ? json.nonProductiveCategories
          .map(UnitCategoryDtoModel.fromJSON)
        : []
      ),
      (Array.isArray(json.availableSkills)
        ? json.availableSkills
          .map(Int32IdNameDtoModel.fromJSON)
        : []
      ),
      (Array.isArray(json.unitBackgroundColors)
        ? json.unitBackgroundColors
          .map(UnitBackgroundColorDtoModel.fromJSON)
        : []
      ),
      (Array.isArray(json.availableProjects)
        ? json.availableProjects
          .map(AvailableProjectDtoModel.fromJSON)
        : []
      ),
      MetadataSlimDtoModel.fromJSON(json.metadata),
    );
  }

  clone(): UnitPlanningViewResponseModel {
    return new UnitPlanningViewResponseModel(
      this.capacities?.map((item) => item.clone()) || null,
      this.units?.map((item) => item.clone()) || null,
      this.nonProductiveCategories?.map((item) => item.clone()) || null,
      this.availableSkills?.map((item) => item.clone()),
      this.unitBackgroundColors?.map((item) => item.clone()),
      this.availableProjects?.map((item) => item.clone()),
      this.metadata.clone(),
    );
  }
}
