import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserPlanningSidepanelStateModel } from './user-planning-sidepanel-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  CloseUserPlanningSidepanel,
  InitUserPlanningSidepanel,
  OpenUserPlanningSidepanel,
  ToggleUserPlanningSidepanel,
} from './user-planning-sidepanel.actions';

const openState: UserPlanningSidepanelStateModel = { isOpen: true };
const closedState: UserPlanningSidepanelStateModel = { isOpen: false };
const defaultState: UserPlanningSidepanelStateModel = openState;

@State<UserPlanningSidepanelStateModel>({
  name: 'UserPlanningSidePanel',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class UserPlanningSidepanelState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitUserPlanningSidepanel)
  initUserPlanningSidepanel(
    ctx: StateContext<UserPlanningSidepanelStateModel>,
    { isOpen }: InitUserPlanningSidepanel,
  ): void {
    ctx.setState(
      isOpen,
    );
  }

  @Action(OpenUserPlanningSidepanel)
  openUserPlanningSidepanel$(ctx: StateContext<UserPlanningSidepanelStateModel>): Observable<void> {
    ctx.patchState(openState);

    return this.userService.saveUserPlanningSidePanel$(openState);
  }

  @Action(CloseUserPlanningSidepanel)
  closeUserPlanningSidepanel$(ctx: StateContext<UserPlanningSidepanelStateModel>): Observable<void> {
    ctx.patchState(closedState);

    return this.userService.saveUserPlanningSidePanel$(closedState);
  }

  @Action(ToggleUserPlanningSidepanel)
  toggleUserPlanningSidepanel$(ctx: StateContext<UserPlanningSidepanelStateModel>): Observable<void> {
    const action = ctx.getState().isOpen ? new CloseUserPlanningSidepanel() : new OpenUserPlanningSidepanel();

    return ctx.dispatch(action);
  }
}
