/**
 * Roles of WorkPackage
 */
export enum WorkPackageRole {
  'NoRole' = 0,
  'WorkPackageCreator' = 1,
  'ProjectManager' = 2,
  'TechnicalProjectManager' = 3,
  'SubmitterUnitCoordinator' = 4,
  'SubmitterUnitL1Coordinator' = 5,
  'SubmitterUnitL2Coordinator' = 6,
  'SubmitterUnitL3Coordinator' = 7,
  'SubmitterUnitL4Coordinator' = 8,
  'SubmitterUnitL5Coordinator' = 9,
  'SupplierUnitCoordinator' = 10,
  'SupplierUnitL1Coordinator' = 11,
  'SupplierUnitL2Coordinator' = 12,
  'SupplierUnitL3Coordinator' = 13,
  'SupplierUnitL4Coordinator' = 14,
  'SupplierUnitL5Coordinator' = 15,
  'WorkPackageCoordinator' = 16,
  'WorkPackageResponsible' = 17,
  'Administrator' = 18,
}
