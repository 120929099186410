import { WorkPackageSubmitterSettingsUpdateRequest } from '../../interfaces/requests/work-package-submitter-settings-update.request';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { ContractHourlyRateCategoryPairDtoModel } from '../dtos/contract-hourly-rate-category-pair.dto.model';

export class WorkPackageSubmitterSettingsUpdateRequestModel {
  constructor(
    readonly workPackageId: number,
    readonly submitterHourlyRateCategories: ContractHourlyRateCategoryPairDtoModel[] | null,
    readonly submitterProjectTypeCode: string,
    readonly submitterProjectNumber: string,
    readonly submitterActivityItemCategoryNumber: string | null,
  ) {
  }

  static fromJSON(
    json: WorkPackageSubmitterSettingsUpdateRequest,
  ): WorkPackageSubmitterSettingsUpdateRequestModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'submitterHourlyRateCategories',
      'submitterProjectTypeCode',
      'submitterProjectNumber',
      'submitterActivityItemCategoryNumber',
    ]);

    return new WorkPackageSubmitterSettingsUpdateRequestModel(
      json.workPackageId,
      // json.submitterHourlyRateCategories,
      (Array.isArray(json.submitterHourlyRateCategories) ? json.submitterHourlyRateCategories : []),
      json.submitterProjectTypeCode,
      json.submitterProjectNumber,
      json.submitterActivityItemCategoryNumber,
    );
  }

  toJSON(): WorkPackageSubmitterSettingsUpdateRequest {
    return {
      workPackageId: this.workPackageId,
      submitterHourlyRateCategories: this.submitterHourlyRateCategories,
      submitterProjectTypeCode: this.submitterProjectTypeCode,
      submitterProjectNumber: this.submitterProjectNumber,
      submitterActivityItemCategoryNumber: this.submitterActivityItemCategoryNumber,
    };
  }
}
