import { Pipe, PipeTransform, ɵstringify as stringify } from '@angular/core';
import moment, { Moment } from 'moment';
import { isEmpty } from '../../helpers/utilities';
import { DateFormatPatterns, formatDate } from '../date';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  /**
   * Uses the given date as-is and outputs a string according to the given format.
   */
  transform(value: Moment | string | null | undefined, format?: DateFormatPatterns | string): string | null {
    if (isEmpty(value)) {
      return null;
    }
    const momentValue = (typeof value === 'string') ? moment(value) : value;

    try {
      return formatDate(momentValue, format);
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(MomentPipe)}'`);
    }
  }
}
