import { WorkPackageListResponse } from '../../interfaces/responses/work-package-list.response';
import { WorkPackageListDtoModel } from '../dtos/work-package-list.dto.model';
import { MetadataModel } from '../metadata.model';
import { WithPaginationInfo } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageListResponseModel {
  constructor(
    readonly items: readonly WorkPackageListDtoModel[],
    readonly metadata: MetadataModel<WithPaginationInfo>,
  ) {
    this.items = [...items];
  }

  // TODO Remove
  // public toJSON(): WorkPackageListResponse {
  //   return {
  //     items: this.items.map((item) => item.toJSON()),
  //     metadata: this.metadata.toJSON(),
  //   };
  // }

  static fromJSON(json: WorkPackageListResponse): WorkPackageListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new WorkPackageListResponseModel(
      (Array.isArray(json.items)
        ? json.items
          .map((item) => WorkPackageListDtoModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata),
    );
  }
}
