import { RoleListDto } from '../../interfaces/dtos/role-list.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { MetadataModel } from '../metadata.model';

export class RoleListDtoModel {
  readonly userCanSelectRole: boolean;

  constructor(
    readonly roleId: number,
    readonly name: string,
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.userCanSelectRole = (this.metadata.fields && this.metadata.fields.userCanSelectRole) || false;
  }

  static fromJSON(json: RoleListDto): RoleListDtoModel {
    assertRequiredProperties(json, [
      'roleId',
      'name',
    ]);

    return new RoleListDtoModel(
      json.roleId,
      json.name,
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): RoleListDto {
    return {
      roleId: this.roleId,
      name: this.name,
      metadata: this.metadata.toJSON(),
    };
  }
}
