import { TimesheetRangeStateModel } from './timesheet-range-state.model';

/**
 * Action types for TimesheetRangeState
 */
export enum TimesheetRangeActionTypes {
  InitTimesheetRangeSettings = '[Timesheet API] Initialize Timesheet Range Settings',
  SetTimesheetRangeSettings = '[Timesheet API] Set Timesheet Range Settings',
  ResetTimesheetRangeSettings = '[Timesheet API] Reset Timesheet Range Settings',
  SaveTimesheetRangeSettings = '[Timesheet API] Save Timesheet Range Settings',
}

export class InitTimesheetRangeSettings {
  static readonly type: string = TimesheetRangeActionTypes.InitTimesheetRangeSettings;

  constructor(public settings: TimesheetRangeStateModel) {}
}

export class SetTimesheetRangeSettings {
  static readonly type: string = TimesheetRangeActionTypes.SetTimesheetRangeSettings;

  constructor(public settings: TimesheetRangeStateModel) {}
}

export class ResetTimesheetRangeSettings {
  static readonly type: string = TimesheetRangeActionTypes.ResetTimesheetRangeSettings;
}

export class SaveTimesheetRangeSettings {
  static readonly type: string = TimesheetRangeActionTypes.SaveTimesheetRangeSettings;

  constructor(public settings: TimesheetRangeStateModel) {}
}
