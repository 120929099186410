import { ProjectSelectorDto } from '../../interfaces/dtos/project-selector.dto';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { ContractDtoModel } from './contract.dto.model';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectSelectorDtoModel {
  constructor(
    readonly projectId: number,
    readonly title: string,
    readonly projectTypeCode: ProjectTypeCode,
    readonly projectNumber: string = '',
    readonly childUnitContracts: readonly ContractDtoModel[] = [],
    readonly submitterUnit: UnitSlimDtoModel | null = null,
  ) {
    this.childUnitContracts = [...childUnitContracts];
  }

  static fromJSON(json: ProjectSelectorDto): ProjectSelectorDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'title',
      'projectTypeCode',
    ]);

    return new ProjectSelectorDtoModel(
      json.projectId,
      json.title,
      json.projectTypeCode,
      json.projectNumber ?? undefined,
      (Array.isArray(json.childUnitContracts) ? json.childUnitContracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      (json.submitterUnit
        ? UnitSlimDtoModel.fromJSON(json.submitterUnit)
        : null
      ),
    );
  }

  clone(): ProjectSelectorDtoModel {
    return new ProjectSelectorDtoModel(
      this.projectId,
      this.title,
      this.projectTypeCode,
      this.projectNumber,
      this.childUnitContracts.map((item) => item.clone()),
      this.submitterUnit ? this.submitterUnit.clone() : null,
    );
  }

  toJSON(): ProjectSelectorDto {
    return {
      projectId: this.projectId,
      title: this.title,
      projectTypeCode: this.projectTypeCode,
      projectNumber: this.projectNumber,
      childUnitContracts: this.childUnitContracts.map((item) => item.toJSON()),
      submitterUnit: this.submitterUnit ? this.submitterUnit.toJSON() : null,
    };
  }
}
