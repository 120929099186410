import { ProjectCreateResponse } from '../../interfaces/responses/project-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectCreateResponseModel {
  constructor(
    readonly projectId: number,
  ) {
  }

  static fromJSON(json: ProjectCreateResponse): ProjectCreateResponseModel {
    assertRequiredProperties(json, [
      'projectId',
    ]);

    return new ProjectCreateResponseModel(
      json.projectId,
    );
  }

  toJSON(): ProjectCreateResponse {
    return {
      projectId: this.projectId,
    };
  }
}
