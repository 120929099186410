import {
  Component, HostBinding, Input, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BasicUser } from '../../models/user.interface';

@Component({
  selector: 'person-list-item',
  templateUrl: './person-list-item.component.html',
  styleUrls: ['./person-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonListItemComponent implements OnDestroy {
  @HostBinding('class.person-list-item')
  readonly personListItem: boolean = true;

  @Input()
  size: string = 'small';

  @Input()
  badge: string | null = null;

  @Input()
  tooltipSuffix: string | null = null;

  @Input()
  nameLength: 'large' | 'huge' |'normal' = 'normal';

  get user(): BasicUser | null | undefined {
    return this._user;
  }

  @Input()
  set user(value: BasicUser | null | undefined) {
    this._user = value;
  }

  private _user: BasicUser | null | undefined;

  private destroyed$: Subject<void> = new Subject();

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
