import { UnitPlanningUserDto } from '../../interfaces/dtos/unit-planning-user.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningSlimDtoModel } from './user-planning-slim.dto.model';
import { MetadataSlimDtoModel } from './metadata-slim.dto.model';
import { UnitPlanningProjectDtoModel } from './unit-planning-project.dto.model';
import { UnitPlanningUserWeekTotalDtoModel } from './unit-planning-user-week-total.dto.model';
import { UnitPlanningWeekRowSlimDtoModel } from './unit-planning-week-row-slim.dto.model';
import { SlimCapacityPlanningDtoModel } from './planning-capacity-slim.dto.model';

export class UnitPlanningUserDtoModel {
  constructor(
    readonly user: UserPlanningSlimDtoModel,
    readonly totals: UnitPlanningUserWeekTotalDtoModel[],
    readonly projects: UnitPlanningProjectDtoModel[],
    readonly nonProductiveCategories: UnitPlanningWeekRowSlimDtoModel[],
    readonly capacities: SlimCapacityPlanningDtoModel[],
    readonly metadata: MetadataSlimDtoModel,
  ) {
    if (totals) {
      this.totals = totals.map((total) => total.clone());
    }

    if (projects) {
      this.projects = projects.map((project) => project.clone());
    }

    if (nonProductiveCategories) {
      this.nonProductiveCategories = nonProductiveCategories.map((npc) => npc.clone());
    }

    if (capacities) {
      this.capacities = capacities
        .map((item) => item.clone())
        .sort((a, b) => (a.weekStartDate && b.weekStartDate && a.weekStartDate.isBefore(b.weekStartDate) ? -1 : 1));
    }

    this.metadata = this.metadata.clone();
  }

  static fromJSON(json: UnitPlanningUserDto): UnitPlanningUserDtoModel {
    assertRequiredProperties(json, [
      'user',
    ]);

    return new UnitPlanningUserDtoModel(
      UserPlanningSlimDtoModel.fromJSON(json.user),
      (Array.isArray(json.totals) ? json.totals : [])
        .map(UnitPlanningUserWeekTotalDtoModel.fromJSON),
      (Array.isArray(json.projects) ? json.projects : [])
        .map(UnitPlanningProjectDtoModel.fromJSON),
      (Array.isArray(json.nonProductiveCategories) ? json.nonProductiveCategories : [])
        .map(UnitPlanningWeekRowSlimDtoModel.fromJSON),
      (Array.isArray(json.capacities) ? json.capacities : [])
        .map(SlimCapacityPlanningDtoModel.fromJSON),
      MetadataSlimDtoModel.fromJSON(json.metadata),
    );
  }

  clone(): UnitPlanningUserDtoModel {
    return new UnitPlanningUserDtoModel(
      this.user.clone(),
      this.totals?.map((item) => item.clone()) || null,
      this.projects?.map((item) => item.clone()) || null,
      this.nonProductiveCategories?.map((item) => item.clone()) || null,
      this.capacities?.map((item) => item.clone()) || null,
      this.metadata.clone(),
    );
  }
}
