/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import {
  map, switchMap, take, tap,
} from 'rxjs/operators';
import { ContractDtoModel } from '../api/models/dtos/contract.dto.model';
import { ProjectService } from '../api/services/project.service';
import { ContractHourlyRateCategoryPairDtoModel } from '../api/models/dtos/contract-hourly-rate-category-pair.dto.model';
import { WorkpackageSupplierSettingsDtoModel } from '../api/models/dtos/workpackage-supplier-setting.dto.model';
import { UnitSlimDtoModel } from '../api/models/dtos/unit-slim.dto.model';
import { AdditionalSuppliersUpdateRequest } from '../api/interfaces/requests/work-package-additional-suppliers-update.request';
import { WorkpackageSupplierSettingsUpdateDtoModel } from '../api/models/dtos/workpackage-supplier-setting-update.dto.model';
import { MetadataModel } from '../api/models/metadata.model';
import { WpMinimalSettingsInterface } from '../models/wp-minimal-settings.interface';
import { SameAsType } from '../models/sameAs.enum';

// hrc = hourly rate category is now called activity item category
@Injectable({
  providedIn: 'root',
})
export class AdditionalSuppliersAndChrcpService {
  isLoading = true;

  isSaving = false;

  lastAddedSupplierUnit: UnitSlimDtoModel | null = null;

  private isModifiedSubject$ = new BehaviorSubject<boolean>(false);

  isModified$ = this.isModifiedSubject$.asObservable();

  private readonly availableToAddSubject$ = new BehaviorSubject<ContractDtoModel[]>([]);

  private currentSubject$: BehaviorSubject<WorkpackageSupplierSettingsDtoModel[]> = new BehaviorSubject([]);

  private addedSubject$: BehaviorSubject<WorkpackageSupplierSettingsDtoModel[]> = new BehaviorSubject([]);

  private readonly combinedSubject$ = new BehaviorSubject<WorkpackageSupplierSettingsDtoModel[]>([]);

  combined$: Observable<WorkpackageSupplierSettingsDtoModel[]> = this.combinedSubject$.asObservable();

  readonly availableToAdd$: Observable<ContractDtoModel[]> = this.availableToAddSubject$.asObservable();

  canAdd = false;

  projectId: number | undefined;

  workPackageId: number | undefined;

  contractSupplierUnitId: number | undefined;

  get isModified(): boolean {
    return this.isModifiedSubject$.getValue();
  }

  get availableContracts(): ContractDtoModel[] {
    return this.availableToAddSubject$.getValue();
  }

  private get current(): WorkpackageSupplierSettingsDtoModel[] {
    return this.currentSubject$.getValue();
  }

  private set current(value: WorkpackageSupplierSettingsDtoModel[]) {
    this.currentSubject$.next(value);
  }

  private get added(): WorkpackageSupplierSettingsDtoModel[] {
    return this.addedSubject$.getValue();
  }

  private set added(value: WorkpackageSupplierSettingsDtoModel[]) {
    this.addedSubject$.next(value);
  }

  constructor(private projectService: ProjectService) {
    combineLatest([this.currentSubject$, this.addedSubject$]).pipe(
      map(([current, added]) => [...current, ...added]),
    ).subscribe((combined) => {
      this.combinedSubject$.next(combined);
    });
    this.addedSubject$.subscribe((added) => {
      this.isModifiedSubject$.next(added.length > 0);
    });
  }

  reset(): void {
    this.current = [];
    this.added = [];
    this.availableToAddSubject$.next([]);
    this.isLoading = true;
    this.isSaving = false;
  }

  loadData$(projectId: number, workPackageId: number, contractSupplierUnitId: number): Observable<void> {
    this.isLoading = true;
    return this.projectService.getCurrentAdditionalSuppliers$(
      projectId,
      workPackageId,
    ).pipe(
      take(1),
      tap((currentAdditionalSuppliers) => {
        this.current = currentAdditionalSuppliers;
      }),
      switchMap((currentAdditionalSuppliers) => {
        const expcludedPairdIds = currentAdditionalSuppliers
          .reduce(
            (acc, curr) => [...acc, ...curr.contracts],
            [],
          )
          .reduce(
            (acc, curr) => [...acc, ...curr.contractHourlyRateCategoryPairs.map(
              (chrcp) => chrcp.contractHourlyRateCategoryPairId,
            )],
            [],
          ).filter((id) => id !== null) as number[];

        return this.projectService.getAdditionalSuppliers$(
          projectId,
          workPackageId,
          contractSupplierUnitId,
          expcludedPairdIds,
        );
      }),
      tap((availableAdditionalSuppliers) => {
        this.availableToAddSubject$.next(availableAdditionalSuppliers);
        this.isLoading = false;
      }),
      map(() => undefined),
    );
  }

  save$(projectId: number, workPackageId: number): Observable<void> {
    this.isSaving = true;
    const additionalSuppliers = [...this.current, ...this.added];

    const workpackageSupplierSettings: WorkpackageSupplierSettingsUpdateDtoModel[] = additionalSuppliers.map(
      (as) => WorkpackageSupplierSettingsUpdateDtoModel.fromWorkpackageSupplierSettingsDtoModel(as, workPackageId),
    );

    const request: AdditionalSuppliersUpdateRequest = {
      workPackageId,
      additionalSupplierSettings: workpackageSupplierSettings.map((wpss) => wpss.toJSON()),
    };

    return this.projectService.putAdditionalSuppliers$(
      projectId,
      workPackageId,
      request,
    );
  }

  /**
   * Adjusts the added and available additional suppliers based on the chrcps
   * @param chrcps ContractHourlyRateCategoryPairDtoModel[]
   */
  adjustSuppliersByCHRCP(
    chrcps: ContractHourlyRateCategoryPairDtoModel[],
  ): void {
    chrcps.forEach((chrcp) => {
      this.adjustAddedAdditionalSupplier(chrcp);
      this.adjustAvailableAdditionalSupplierToAdd(chrcp);
    });
  }

  // eslint-disable-next-line max-len
  // public getAvailableHourlyRateCategoryPairsByContractId$(contractId: number):
  // Observable<ContractHourlyRateCategoryPairDtoModel[]> {

  update(form: WpMinimalSettingsInterface, index: number): void {
    const isCurrentIndex = index < this.current.length;
    const newIndex = isCurrentIndex ? index : index - this.current.length;
    const entryToUpdate = isCurrentIndex ? this.current[index] : this.added[newIndex];
    const existingContract = entryToUpdate.contracts[0];
    const newContract = existingContract.clone();
    newContract.contractHourlyRateCategoryPairs = form.hourlyRateCategoriesPairs;

    const newEntry = new WorkpackageSupplierSettingsDtoModel(
      form.projectTypeCode || '',
      typeof form.projectNumber !== 'string' ? form.projectNumber?.code : form.projectNumber.trim(),
      form.activityItemCategoryNumber || null,
      [newContract],
      entryToUpdate.metadata,
    );
    if (isCurrentIndex) {
      this.currentSubject$.next([
        ...this.current.slice(0, index),
        newEntry,
        ...this.current.slice(index + 1),
      ]);
    } else {
      this.addedSubject$.next([
        ...this.added.slice(0, newIndex),
        newEntry,
        ...this.added.slice(newIndex + 1),
      ]);
    }
  }

  /**
   * @description Searches the contract in the AvailableAdditionalSuppliersToAdd based on the
   *  chrcp and adds it to the addedAdditionalSuppliers
   */
  private adjustAddedAdditionalSupplier(
    chrcp: ContractHourlyRateCategoryPairDtoModel,
  ): void {
    // find if already exist in new Settings
    const foundAddedSetting = this.added.find((setting) => setting.contracts[0].contractId === chrcp.contractId);
    if (foundAddedSetting) {
      // update actual setting
      foundAddedSetting.contracts[0].contractHourlyRateCategoryPairs.push(chrcp);
      // trigger a change
      this.added = [...this.added];
    } else {
      // create a new Setting
      // eslint-disable-next-line max-len
      const availableSupplierContract = this.availableContracts.find((contract) => contract.contractId === chrcp.contractId);
      if (!availableSupplierContract) {
        return;
      }

      const newAvailableSupplierContract: ContractDtoModel = availableSupplierContract.clone();
      newAvailableSupplierContract.contractHourlyRateCategoryPairs = [chrcp];

      // eslint-disable-next-line max-len
      const newWpss = new WorkpackageSupplierSettingsDtoModel(
        SameAsType.SameAsSupplier,
        SameAsType.SameAsSupplier,
        null,
        [newAvailableSupplierContract],
        new MetadataModel({
          fields: {
            canEditAdditionalSupplierProjectSettings: true,
          },
        }),
      );
      this.added = [...this.added, newWpss];
    }
  }

  /**
   * @description Searches the contract in the AvailableAdditionalSuppliersToAdd based on the
   * chrcp and removes it from the contractHourlyRateCategoryPairs
   * If the contractHourlyRateCategoryPairs is empty, the contract is removed from the
   * AvailableAdditionalSuppliersToAdd
  */
  private adjustAvailableAdditionalSupplierToAdd(
    chrcp: ContractHourlyRateCategoryPairDtoModel,
  ): void {
    // eslint-disable-next-line arrow-body-style
    const indexToFilter = this.availableToAddSubject$.value.findIndex((contract) => {
      return contract.contractHourlyRateCategoryPairs
        .some((availableChrcp) => availableChrcp.contractId === chrcp.contractId);
    });
    const filteredContractHourlyRateCategoryPairs = this.availableToAddSubject$.value[indexToFilter]
      .contractHourlyRateCategoryPairs
      .filter(
        (availableChrcp) => availableChrcp.contractHourlyRateCategoryPairId !== chrcp.contractHourlyRateCategoryPairId,
      );

    this.lastAddedSupplierUnit = this.availableToAddSubject$.value[indexToFilter].supplierUnit;
    const newAvailableAdditionalSuppliersToAdd = [...this.availableToAddSubject$.value];
    if (filteredContractHourlyRateCategoryPairs.length === 0) {
      newAvailableAdditionalSuppliersToAdd.splice(indexToFilter, 1);
      this.availableToAddSubject$.next([...newAvailableAdditionalSuppliersToAdd]);
      return;
    }

    newAvailableAdditionalSuppliersToAdd[indexToFilter]
      .contractHourlyRateCategoryPairs = filteredContractHourlyRateCategoryPairs;
    this.availableToAddSubject$.next([...newAvailableAdditionalSuppliersToAdd]);
  }
}
