import { UserPreferencesUpdateRequest } from '../../interfaces/requests/user-preferences-update.request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UserPreferencesUpdateRequestModel {
  constructor(
    readonly timezone: string,
    readonly displayInLocalLanguage: boolean,
  ) {
  }

  static fromJSON(json: UserPreferencesUpdateRequest): UserPreferencesUpdateRequestModel {
    assertRequiredProperties(json, [
      'timezone',
    ]);

    return new UserPreferencesUpdateRequestModel(
      json.timezone,
      json.displayInLocalLanguage,
    );
  }

  toJSON(): UserPreferencesUpdateRequest {
    return {
      timezone: this.timezone,
      displayInLocalLanguage: this.displayInLocalLanguage,
    };
  }
}
