import { ProjectTypeListResponse } from '../../interfaces/responses/project-type-list.response';
import { ProjectTypeDtoModel } from '../dtos/project-type.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectTypeListResponseModel {
  constructor(
    readonly items: readonly ProjectTypeDtoModel[],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: ProjectTypeListResponse): ProjectTypeListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new ProjectTypeListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => ProjectTypeDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): ProjectTypeListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
