import { UnitPlanningColumnTotalDto } from '../../interfaces/dtos/unit-planning-column-total.dto';

export class UnitPlanningColumnTotalDtoModel {
  constructor(
    readonly totalPlanned: number,
    readonly totalRecorded: number,
    readonly totalAvailableToBePlanned: number,
    readonly totalConfirmed: number,
  ) {}

  static fromJSON(json: UnitPlanningColumnTotalDto): UnitPlanningColumnTotalDtoModel {
    return new UnitPlanningColumnTotalDtoModel(
      json.totalPlanned,
      json.totalRecorded,
      json.totalAvailableToBePlanned,
      json.totalConfirmed,
    );
  }

  toJSON(): UnitPlanningColumnTotalDto {
    return {
      totalPlanned: this.totalPlanned,
      totalRecorded: this.totalRecorded,
      totalAvailableToBePlanned: this.totalAvailableToBePlanned,
      totalConfirmed: this.totalConfirmed,
    };
  }

  clone(): UnitPlanningColumnTotalDtoModel {
    return new UnitPlanningColumnTotalDtoModel(
      this.totalPlanned,
      this.totalRecorded,
      this.totalAvailableToBePlanned,
      this.totalConfirmed,
    );
  }
}
