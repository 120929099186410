import { GenericListOptions } from '../generic-list';
import { UsersListColumnKey } from '../../../api/meta-info/users-list.info';
import { UserColumnFilters } from '../../../api/filter-columns';
import { UserListStateModel } from './user-list-state.model';

/**
 * Action types for UserListState
 */
export enum UserListActionTypes {
  SetUserListSelectedColumns = '[User List Page] Set Selected Columns',
  SetUserListOptions = '[User List Page] Set Options',
  ResetUserListOptions = '[User List Page] Reset Options',
  InitUserListSettings = '[User API] Initialize User List Settings',
  SetUserListSettings = '[User API] Set User List Settings',
  ResetUserListSettings = '[User API] Reset User List Settings',
  SaveUserListSettings = '[User API] Save User List Settings',
}

export interface UserListOptions extends GenericListOptions {
  columnFilters: UserColumnFilters;
}

export class SetUserListSelectedColumns {
  static readonly type: string = UserListActionTypes.SetUserListSelectedColumns;

  constructor(public columns: UsersListColumnKey[]) {}
}

export class SetUserListOptions {
  static readonly type: string = UserListActionTypes.SetUserListOptions;

  constructor(public options: UserListOptions) {}
}

export class ResetUserListOptions {
  static readonly type: string = UserListActionTypes.ResetUserListOptions;
}

export class InitUserListSettings {
  static readonly type: string = UserListActionTypes.InitUserListSettings;

  constructor(public settings: UserListStateModel) {}
}

export class SetUserListSettings {
  static readonly type: string = UserListActionTypes.SetUserListSettings;

  constructor(public settings: UserListStateModel) {}
}

export class ResetUserListSettings {
  static readonly type: string = UserListActionTypes.ResetUserListSettings;
}

export class SaveUserListSettings {
  static readonly type: string = UserListActionTypes.SaveUserListSettings;

  constructor(public settings: UserListStateModel) {}
}
