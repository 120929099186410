import { AvailableResourcesResponse } from '../../interfaces/responses/available-resources.response';
import { MetadataModel } from '../metadata.model';
import { AvailableResourceDtoModel } from '../dtos/available-resource.dto.model';
import { Int32IdNameDtoModel } from '../dtos/int32-id-name.dto.model';

export class AvailableResourcesResponseModel {
  constructor(
    readonly items: readonly AvailableResourceDtoModel[] = [],
    readonly availableSkills: readonly Int32IdNameDtoModel[] = [],
    readonly metadata: MetadataModel,
  ) {
    this.items = [...items];
    this.availableSkills = availableSkills.map((skill) => skill.clone());
  }

  static fromJSON(json: AvailableResourcesResponse): AvailableResourcesResponseModel {
    return new AvailableResourcesResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => AvailableResourceDtoModel.fromJSON(item)),
      (Array.isArray(json.availableSkills) ? json.availableSkills : [])
        .map(Int32IdNameDtoModel.fromJSON),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): AvailableResourcesResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      availableSkills: this.availableSkills.map((skill) => skill.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
