import { Selector } from '@ngxs/store';
import { SkillsState } from './skills.state';
import { SkillsStateModel } from './skills-state.model';
import { UnitSkillDtoModel } from '../../../api/models/dtos/unit-skill.dto.model';

export class SkillsSelectors {
  @Selector([SkillsState])
  static recentSkillsUnits(state: SkillsStateModel): readonly UnitSkillDtoModel[] {
    return state.recentUnits;
  }

  /**
   * Get the most recent / latest unit that was viewed.
   */
  @Selector([SkillsState])
  static mostRecentSkillsUnit(state: SkillsStateModel): UnitSkillDtoModel | undefined {
    return state.recentUnits.length > 0 ? state.recentUnits[0] : undefined;
  }
}
