import { GateDto } from '../../interfaces/dtos/gate.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class GateDtoModel {
  constructor(
    readonly gateId: number,
    readonly name: string,
    readonly code: string | null = null,
    readonly description: string | null = null,
  ) {
  }

  static fromJSON(json: GateDto): GateDtoModel {
    assertRequiredProperties(json, [
      'gateId',
      'name',
    ]);

    return new GateDtoModel(
      json.gateId,
      json.name,
      json.code,
      json.description,
    );
  }

  clone(): GateDtoModel {
    return new GateDtoModel(
      this.gateId,
      this.name,
      this.code,
      this.description,
    );
  }

  toJSON(): GateDto {
    return {
      gateId: this.gateId,
      code: this.code,
      name: this.name,
      description: this.description,
    };
  }
}
