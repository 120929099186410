/* eslint-disable max-classes-per-file */
import { SortDirection } from '@angular/material/sort';
import moment from 'moment';
import { UserColumnFilters } from './filter-columns';
import { CustomHttpParams } from './custom-http-params';
import { isEmpty } from '../helpers/utilities';
import { UserAvailableResourcesRequestModel } from './models/requests/user-available-resources.request.model';
import { SkillFilterDto } from './interfaces/dtos/skill-filter.dto';

export class ListQueryOptions {
  /**
   * Derived from pageIndex parameter:
   * API is not zero-index based.
   */
  private readonly pageNumber?: number;

  constructor(
    protected pageIndex?: number,
    protected pageSize?: number,
    protected columnFilters?: Record<string, any>,
    protected sortColumn?: string,
    protected sortDirection?: SortDirection,
  ) {
    // API is not zero-index based.
    this.pageNumber = (pageIndex != null ? pageIndex + 1 : undefined);
    this.sortColumn = (!isEmpty(sortColumn) ? sortColumn : undefined);
    this.sortDirection = (!isEmpty(sortDirection) ? sortDirection : undefined);
  }

  /**
   * Uses a template-builder based approach to generate the query parameters
   */
  toHttpParams(): CustomHttpParams {
    return new CustomHttpParams({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      columnFilters: this.columnFilters,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
    });
  }

  getPageNumber(): number {
    return this.pageNumber || 1;
  }
}

export class UserListQueryOptions extends ListQueryOptions {
  constructor(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: UserColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
    private localOnly?: boolean,
    private validOnly?: boolean,
    public unitId?: number,
    public includeSupplierUsers?: boolean,
    public onlyCoordinators?: boolean,
    public isForWpCreator?: boolean,
  ) {
    super(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);
  }

  toHttpParams(): CustomHttpParams {
    return super.toHttpParams()
      .set('localOnly', this.localOnly)
      .set('unitId', this.unitId)
      .set('includeSupplierUsers', this.includeSupplierUsers)
      .set('onlyCoordinators', this.onlyCoordinators)
      .set('isForWpCreator', this.isForWpCreator);
  }
}

export class UserResourceListQueryOptions extends UserListQueryOptions {
  constructor(
    unitId?: number,
    private projectId?: number,
    private workPackageId?: number,
    private excludedUserIds?: string[],
    private excludedNhrIds?: string[],
    private fullName?: string,
    pageIndex?: number,
    pageSize?: number,
    sortColumn?: string,
    sortDirection?: SortDirection,
    private validFrom?: moment.Moment,
    private validUntil?: moment.Moment,
    private year?: number,
    private week?: number,
    private numberOfWeeks?: number,
    private skills?: SkillFilterDto[],
  ) {
    super(pageIndex, pageSize, undefined, sortColumn, sortDirection, undefined, undefined, unitId);

    this.validFrom = (validFrom ? validFrom.clone() : undefined);
    this.validUntil = (validUntil ? validUntil.clone() : undefined);
  }

  generateRequestModel(): UserAvailableResourcesRequestModel {
    return new UserAvailableResourcesRequestModel(
      this.validFrom,
      this.validUntil,
      this.unitId,
      this.projectId,
      this.workPackageId,
      this.excludedUserIds,
      this.excludedNhrIds,
      this.fullName,
      this.year,
      this.week,
      this.numberOfWeeks,
      super.getPageNumber(),
      this.pageSize,
      this.skills,
    );
  }
}

export class WorkPackageListOptions extends ListQueryOptions {}

export class UnitQuestionsListOptions extends ListQueryOptions {}
