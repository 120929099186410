import { UnitListResponse } from '../../interfaces/responses/unit-list.response';
import { UnitListDtoModel } from '../dtos/unit-list.dto.model';
import { MetadataModel } from '../metadata.model';
import { UnitListResponseMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitListResponseModel {
  constructor(
    readonly items: readonly UnitListDtoModel[],
    readonly metadata: MetadataModel<UnitListResponseMetadata>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: UnitListResponse): UnitListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new UnitListResponseModel(
      json.items.map((item) => UnitListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }
}
