import { ProjectStructurePresetUpdateRequest } from '../../interfaces/requests/project-structure-preset-update.request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectStructurePresetUpdateRequestModel {
  constructor(
    readonly structure: readonly string[],
  ) {
    this.structure = [...structure];
  }

  static fromJSON(json: ProjectStructurePresetUpdateRequest): ProjectStructurePresetUpdateRequestModel {
    assertRequiredProperties(json, [
      'structure',
    ]);

    return new ProjectStructurePresetUpdateRequestModel(
      (Array.isArray(json.structure) ? json.structure : [])
        .map((item) => item),
    );
  }

  toJSON(): ProjectStructurePresetUpdateRequest {
    return {
      structure: [...this.structure],
    };
  }
}
