import { TimeTrackingProjectDto } from '../../interfaces/dtos/time-tracking-project.dto';
import { TimeTrackingWorkPackageDtoModel } from './time-tracking-work-package.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingProjectDtoModel {
  /**
   * Not provided from the API:
   * Used to aggregate the total hours of every work package
   * per day.
   */
  readonly totalHours: number[];

  /**
   * An aggregate of the total planned hours for this project.
   */
  readonly totalPlannedHours: number;

  constructor(
    readonly projectId: number,
    readonly projectNumber: string,
    readonly projectTitle: string,
    readonly workPackages: readonly TimeTrackingWorkPackageDtoModel[],
  ) {
    this.workPackages = workPackages.map((d) => d.clone());

    // Calculates for every day the sum of reported work package hours
    this.totalHours = this.calculateTotalHours();
    this.totalPlannedHours = this.calculateTotalPlannedHours();
  }

  static fromJSON(json: TimeTrackingProjectDto): TimeTrackingProjectDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'projectTitle',
      'workPackages',
    ]);

    return new TimeTrackingProjectDtoModel(
      json.projectId,
      json.projectNumber || '',
      json.projectTitle,
      (Array.isArray(json.workPackages) ? json.workPackages : [])
        .map((item) => TimeTrackingWorkPackageDtoModel.fromJSON(item)),
    );
  }

  /**
   * Sums the hours reported in work packages for a specific week day.
   * @param dayIndex
   */
  calculateTotalHoursOfWeekDay(dayIndex: number): number {
    let sum = 0;

    this.workPackages.forEach((wp) => {
      wp.rows.forEach((position) => {
        const hour = +`${position.days[dayIndex].value}`.replace(',', '.');

        if (!Number.isNaN(hour)) {
          sum += hour;
        }
      });
    });

    this.totalHours[dayIndex] = sum;

    return sum;
  }

  calculateTotalPlannedHours(): number {
    return this.workPackages.reduce((acc, wp) => acc + (wp.rows.length ? (wp.rows[0].totalPlannedHours || 0) : 0), 0);
  }

  toJSON(): TimeTrackingProjectDto {
    return {
      projectId: this.projectId,
      projectNumber: this.projectNumber,
      projectTitle: this.projectTitle,
      workPackages: this.workPackages.map((item) => item.toJSON()),
    };
  }

  clone(): TimeTrackingProjectDtoModel {
    return new TimeTrackingProjectDtoModel(
      this.projectId,
      this.projectNumber,
      this.projectTitle,
      this.workPackages,
    );
  }

  /**
   * Returns a list of summed hours. Every entry in the list
   * represents a day of the week. The hours are summed
   * for every work package.
   */
  private calculateTotalHours(): number[] {
    const sums: number[] = [];

    this.workPackages.forEach((wp) => {
      wp.rows.forEach((position) => {
        position.days.forEach((day, dayIndex) => {
          if (!Number.isNaN(day.value)) {
            // Make sure the sums are initialized for every day
            sums[dayIndex] = (sums[dayIndex] || 0) + day.value;
          }
        });
      });
    });

    return sums;
  }
}
