import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'collapp-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: [
    './form-section.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {
  @Input() label: string = '';

  @Input() actionLabel: string | null = null;

  @Output()
  readonly actionClicked: EventEmitter<boolean> = new EventEmitter();

  @HostBinding() class: string = 'form-section';

  @HostBinding('class.form-section--error')
  @Input()
  hasErrors: boolean = false;

  onActionClick(): void {
    this.actionClicked.emit();
  }
}
