import moment from 'moment';
import { PlanningResourceUnitAssignment } from '../../../modules/user/planning2/planning-resource.interface';
import { UserUnitAssignmentDto, UserUnitAssignmentDtoMetadata } from '../../interfaces/dtos/user-unit-assignment.dto';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { isEmpty } from '../../../helpers/utilities';

export class UserUnitAssignmentDtoModel implements PlanningResourceUnitAssignment {
  readonly canDelete: boolean;

  readonly canEdit: boolean;

  readonly lastPlannedDate: moment.Moment | undefined;

  readonly lastReportedDate: moment.Moment | undefined;

  readonly isExpired: boolean;

  constructor(
    readonly userUnitAssignmentId: number,
    readonly unit: UnitSlimDtoModel,
    readonly validFrom: moment.Moment,
    readonly validUntil: moment.Moment | undefined,
    readonly hourlyRateCategory: string,
    readonly activityTypeDescription: string,
    readonly workingHoursPerWeek: number,
    readonly metadata: MetadataModel<UserUnitAssignmentDtoMetadata> = new MetadataModel(),
  ) {
    this.validFrom = validFrom.clone();
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);

    this.canDelete = !!this.metadata.fields.canDelete;
    this.canEdit = !!this.metadata.fields.canEdit;
    this.lastPlannedDate = (!isEmpty(this.metadata.fields.lastPlannedDate)
      ? moment(this.metadata.fields.lastPlannedDate)
        .parseZone()
      : undefined
    );
    this.lastReportedDate = (!isEmpty(this.metadata.fields.lastReportedDate)
      ? moment(this.metadata.fields.lastReportedDate)
        .parseZone()
      : undefined
    );
    this.isExpired = !!this.metadata.fields.isExpired;
  }

  static fromJSON(json: UserUnitAssignmentDto): UserUnitAssignmentDtoModel {
    assertRequiredProperties(json, [
      'userUnitAssignmentId',
      'unit',
      'hourlyRateCategory',
      'workingHoursPerWeek',
      'validFrom',
    ]);

    return new UserUnitAssignmentDtoModel(
      json.userUnitAssignmentId,
      UnitSlimDtoModel.fromJSON(json.unit),
      moment(json.validFrom)
        .parseZone(),
      (!isEmpty(json.validUntil)
        ? moment(json.validUntil)
          .parseZone()
        : undefined
      ),
      json.hourlyRateCategory,
      json.activityTypeDescription,
      json.workingHoursPerWeek,
      new MetadataModel(json.metadata || {}),
    );
  }

  clone(): UserUnitAssignmentDtoModel {
    return new UserUnitAssignmentDtoModel(
      this.userUnitAssignmentId,
      this.unit.clone(),
      this.validFrom.clone(),
      this.validUntil?.clone(),
      this.hourlyRateCategory,
      this.activityTypeDescription,
      this.workingHoursPerWeek,
      this.metadata.clone(),
    );
  }

  toJSON(): UserUnitAssignmentDto {
    return {
      userUnitAssignmentId: this.userUnitAssignmentId,
      unit: this.unit.toJSON(),
      validFrom: this.validFrom.toJSON(),
      validUntil: (this.validUntil != null ? this.validUntil.toJSON() : null),
      hourlyRateCategory: this.hourlyRateCategory,
      activityTypeDescription: this.activityTypeDescription,
      workingHoursPerWeek: this.workingHoursPerWeek,
      metadata: this.metadata.toJSON(),
    };
  }
}
