import { UserNotificationRoleDto } from '../../interfaces/dtos/user-notification-role.dto';
import { UserNotificationSettingsDtoModel } from './user-notification-settings.dto.model';

export class UserNotificationRoleDtoModel {
  constructor(
    readonly roleName: string | null,
    readonly roleCode: string | null,
    readonly orderBy: number,
    readonly notificationSettings: UserNotificationSettingsDtoModel[],
  ) {
    if (notificationSettings) {
      this.notificationSettings = notificationSettings.map((setting) => setting.clone());
    }
  }

  static fromJSON(json: UserNotificationRoleDto): UserNotificationRoleDtoModel {
    return new UserNotificationRoleDtoModel(
      json.roleName,
      json.roleCode,
      json.orderBy,
      (Array.isArray(json.notificationSettings) ? json.notificationSettings : [])
        .map(UserNotificationSettingsDtoModel.fromJSON),
    );
  }

  toJSON(): UserNotificationRoleDto {
    return {
      roleName: this.roleName,
      roleCode: this.roleCode,
      orderBy: this.orderBy,
      notificationSettings: this.notificationSettings,
    };
  }

  clone(): UserNotificationRoleDtoModel {
    return new UserNotificationRoleDtoModel(
      this.roleName,
      this.roleCode,
      this.orderBy,
      (Array.isArray(this.notificationSettings) ? this.notificationSettings : [])
        .map((setting) => setting.clone()),
    );
  }
}
