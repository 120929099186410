import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { UnitColumnFilters } from '../filter-columns';
import { UnitListDto } from '../interfaces/dtos/unit-list.dto';
import { ReadonlyCompleteMap } from '../../app.types';

export type UnitsListColumnKey = ObjectPropertyNames<UnitListDto>;

// This defines the object that should implement all properties of 'UnitListDto' exactly.
// It's used for linting mostly and discarded later.
const unitsListColumnsInfoObj: ListColumnsInfo<UnitListDto> = {
  unitId: {
    label: 'Unit ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  code: {
    label: 'Unit Code',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  name: {
    label: 'Unit Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  level: {
    label: 'Unit Level',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  parentUnitId: {
    label: 'Parent Unit',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  unitCoordinatorFullName: {
    label: 'Unit Coordinator',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  deputyUnitCoodinators: {
    label: 'Deputy Unit Coordinators',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  isDisabled: {
    label: 'Disabled',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const unitsListColumnsInfo: ReadonlyCompleteMap<
UnitsListColumnKey,
ListColumnInfo
> = new Map([
  ['unitId', unitsListColumnsInfoObj.unitId],
  ['code', unitsListColumnsInfoObj.code],
  ['name', unitsListColumnsInfoObj.name],
  ['level', unitsListColumnsInfoObj.level],
  ['parentUnitId', unitsListColumnsInfoObj.parentUnitId],
  ['unitCoordinatorFullName', unitsListColumnsInfoObj.unitCoordinatorFullName],
  ['deputyUnitCoodinators', unitsListColumnsInfoObj.deputyUnitCoodinators],
  ['isDisabled', unitsListColumnsInfoObj.isDisabled],
]) as ReadonlyCompleteMap<UnitsListColumnKey, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const unitsListColumnsWithFilters: (keyof UnitColumnFilters)[] = Array.from(unitsListColumnsInfo.entries())
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof UnitColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
