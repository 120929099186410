import { QuestionUserNotificationResponse } from '../../interfaces/responses/question-user-notification.response';
import { WorkPackageRole } from '../../../models/work-package-role.enum';
import { UserQuestionNotificationDtoModel } from '../dtos/user-question-notification.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionUserNotificationResponseModel {
  constructor(
    readonly workPackageRole: WorkPackageRole,
    readonly users: readonly UserQuestionNotificationDtoModel[],
  ) {
    this.users = [...users];
  }

  static fromJSON(json: QuestionUserNotificationResponse): QuestionUserNotificationResponseModel {
    assertRequiredProperties(json, [
      'workPackageRole',
    ]);

    return new QuestionUserNotificationResponseModel(
      json.workPackageRole,
      (Array.isArray(json.users)
        ? json.users
          .map((item) => UserQuestionNotificationDtoModel.fromJSON(item))
        : []
      ),
    );
  }
}
