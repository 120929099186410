import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { UserPlanningSidepanelIndexStateModel } from './user-planning-sidepanel-index-state.model';
import { UserPlanningSidepanelIndexState } from './user-planning-sidepanel-index.state';

@Injectable({
  providedIn: 'root',
})
export class UserPlanningSidepanelIndexSelectors {
  @Selector([UserPlanningSidepanelIndexState])
  static index(state: UserPlanningSidepanelIndexStateModel): number {
    return state.index;
  }
}
