import moment from 'moment';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import guid from '../../../helpers/guid';
import { UnitPlanningUserWeekEntryViewSlimDto } from '../../interfaces/dtos/unit-planning-user-week-entry-view-slim.dto';
import { MetadataSlimDtoModel } from './metadata-slim.dto.model';

export class UnitPlanningUserWeekEntryViewSlimDtoModel {
  constructor(
    readonly id: string,
    readonly weekStartDate: moment.Moment,
    readonly week: number,
    readonly year: number,
    readonly plannedHours: number,
    readonly unitBackgroundColorId: number | null,
    readonly metadata: MetadataSlimDtoModel,
    readonly workingDays: string,
    readonly note: string | null,
    readonly noteCreatedBy: string | null,
    readonly noteCreatedOn: moment.Moment | null,
    readonly resourceCapacityUnit: CapacityUnits | null = null,
  ) {
    this.metadata = this.metadata.clone();
  }

  static fromJSON(json: UnitPlanningUserWeekEntryViewSlimDto): UnitPlanningUserWeekEntryViewSlimDtoModel {
    return new UnitPlanningUserWeekEntryViewSlimDtoModel(
      guid(),
      moment(json.weekStartDate).parseZone(),
      json.week,
      json.year,
      json.plannedHours,
      json.unitBackgroundColorId,
      new MetadataSlimDtoModel(json.metadata),
      json.workingDays,
      json.note,
      json.noteCreatedBy,
      json.noteCreatedOn ? moment(json.noteCreatedOn) : null,
      Object.values(CapacityUnits).filter((cu) => cu.toString() === json.resourceCapacityUnit)?.[0],
    );
  }

  clone(): UnitPlanningUserWeekEntryViewSlimDtoModel {
    return new UnitPlanningUserWeekEntryViewSlimDtoModel(
      this.id,
      this.weekStartDate.clone(),
      this.week,
      this.year,
      this.plannedHours,
      this.unitBackgroundColorId,
      this.metadata.clone(),
      this.workingDays,
      this.note,
      this.noteCreatedBy,
      this.noteCreatedOn?.clone() || null,
      this.resourceCapacityUnit,
    );
  }
}
