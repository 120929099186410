import { Injectable } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { CollAppApiService } from './collapp-api.service';
import { UnitColumnFilters } from '../filter-columns';
import { UnitDtoModel } from '../models/dtos/unit.dto.model';
import { UnitSlimDtoModel } from '../models/dtos/unit-slim.dto.model';
import { UnitDto } from '../interfaces/dtos/unit.dto';
import { UnitSlimDto } from '../interfaces/dtos/unit-slim.dto';
import { UnitSelectorListResponseModel } from '../models/responses/unit-selector-list.response.model';
import { UnitListResponse } from '../interfaces/responses/unit-list.response';
import { UnitListResponseModel } from '../models/responses/unit-list.response.model';
import { UnitCreateRequestModel } from '../models/requests/unit-create.request.model';
import { UnitUpdateRequestModel } from '../models/requests/unit-update.request.model';
import { UnitSelectorListResponse } from '../interfaces/responses/unit-selector-list.response';
import { ListQueryOptions } from '../list-query-options';
import { UnitCreateResponse } from '../interfaces/responses/unit-create.response';
import { UnitCreateResponseModel } from '../models/responses/unit-create.response.model';
import { UnitViewResponseModel } from '../models/responses/unit-view.response.model';
import { UnitViewResponse } from '../interfaces/responses/unit-view.response';
import { UnitsViewResponseModel } from '../models/responses/units-view.response.model';
import { UnitsViewResponse } from '../interfaces/responses/units-view.response';
import { UnitSelectFetchType } from '../../components/unit-select';
import { SelectorRequestOptions } from '../../models/selector-request.options';
import { DefectListResponse } from '../interfaces/responses/defect-list.response';
import { TimesheetExportTemplateResponse } from '../interfaces/responses/timesheet-export-template.response';
import { TimesheetExportTemplateResponseModel } from '../models/responses/timesheet-export-template.response.model';
import { DefaultNonProductiveCategoryResponse } from '../interfaces/responses/default-non-productive-category.response';
import { DefaultNonProductiveCategoryResponseModel } from '../models/responses/default-non-productive-category-response.model';
import { UnitCoordinatorResponseModel } from '../models/responses/unit-coordinator-response.model';
import { UserSlimDto } from '../interfaces/dtos/user-slim.dto';
import { UnitSkillDtoModel } from '../models/dtos/unit-skill.dto.model';
import { UnitSkillDto } from '../interfaces/dtos/unit-skill.dto';
import { UnitSlimDtoWithoutCoordinatorDto } from '../interfaces/dtos/unit-slim-without-coordinator.dto';
import { UnitSlimWithoutCoordinatorDtoModel } from '../models/dtos/unit-slim-without-coordinator.dto.model';
import { BackgroundColorResponse } from '../interfaces/responses/background-colors-response';
import { BackgroundColorDtoModel } from '../models/dtos/background-color.dto.model';
import { BackgroundColorUpdateRequestModel } from '../models/requests/background-color-update.request.model';

export interface UnitSelectorRequestOptions extends SelectorRequestOptions {
  unitFetchType: UnitSelectFetchType;
}

export interface UnitSelectorAvailableRequestOptions extends SelectorRequestOptions {
  workPackageId: number;
  projectId: number;
}

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(private apiService: CollAppApiService) { }

  /**
   * Gets a list of all units
   */
  getUnits$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: UnitColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<UnitListResponseModel> {
    const options = new ListQueryOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);

    return this.apiService
      .getUnits$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitListResponse>) => UnitListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of all Coordinators by a given UnitId
   */
  getUnitCoordinators$(unitId: number): Observable<UnitCoordinatorResponseModel> {
    return this.apiService
      .getUnitCoordinators$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserSlimDto[]>) => UnitCoordinatorResponseModel
          .fromJSON({ coordinators: response.body! })),
      );
  }

  /**
   * Gets a list of all Coordinators by a given UnitId
   */
  getUnitCoordinatorsForConfirmingWP$(unitId: number): Observable<UnitCoordinatorResponseModel> {
    return this.apiService
      .getUnitCoordinatorsForConfirmingWP$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserSlimDto[]>) => UnitCoordinatorResponseModel
          .fromJSON({ coordinators: response.body! })),
      );
  }

  /**
   * Gets a single unit dto
   */
  getUnitById$(unitId: number): Observable<UnitDtoModel> {
    return this
      .apiService
      .getUnitByUnitId$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitDto>) => UnitDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a single (slim) unit dto
   */
  getUnitSlimByUnitId$(unitId: number): Observable<UnitSlimDtoModel> {
    return this
      .apiService
      .getUnitSlimByUnitId$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitSlimDto>) => UnitSlimDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets the view response of the units in the administration section
   */
  getUnitsViewResponse$(): Observable<UnitsViewResponseModel> {
    return this
      .apiService
      .getUnitsViewResponse$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitsViewResponse>) => UnitsViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a view response of a specific unit.
   */
  getUnitViewByUnitId$(unitId: number): Observable<UnitViewResponseModel> {
    return this
      .apiService
      .getUnitViewResponseByUnitId$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitViewResponse>) => UnitViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets the units as a tree-like structure.
   * Only units the users has access to are returned (including parents).
   */
  getUnitsForUnitSelector$(
    requestOptions: UnitSelectorRequestOptions,
  ): Observable<UnitSelectorListResponseModel> {
    return this.apiService
      .getUnitSelectors$(requestOptions)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitSelectorListResponse>) => UnitSelectorListResponseModel
          .fromJSON(response.body!)),
      );
  }

  getAvailableUnitsForUnitSelector$(
    requestOptions: UnitSelectorAvailableRequestOptions,
  ): Observable<UnitSelectorListResponseModel> {
    return this.apiService
      .getAvailableUnitSelectors$(requestOptions)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitSelectorListResponse>) => UnitSelectorListResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of defects by a unit.
   * If no unit id is provided, a default list of categories is provided.
   */
  getUnitDefects$(unitId?: number): Observable<string[]> {
    return this.apiService
      .getUnitDefects$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<DefectListResponse>) => [...response.body!.items]),
      );
  }

  /**
   * Gets a list of non productive categories a unit has.
   * If no unit id is provided, a default list of categories is provided.
   */
  getDefaultNonProductiveCategories$(): Observable<DefaultNonProductiveCategoryResponseModel[]> {
    return this.apiService
      .getDefaultNonProductiveCategories$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<DefaultNonProductiveCategoryResponse[]>) => (Array.isArray(response.body)
          ? response.body
          : [])),
        map((categories) => categories.map((item) => DefaultNonProductiveCategoryResponseModel.fromJSON(item))),
      );
  }

  /**
   * Gets a unit's timesheet export template.
   * If no unit id is provided, a default template is provided.
   */
  getTimeSheetExportTemplate$(unitId?: number): Observable<TimesheetExportTemplateResponseModel> {
    return this.apiService
      .getUnitTimesheetExportTemplate$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<TimesheetExportTemplateResponse>) => TimesheetExportTemplateResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new unit.
   */
  postUnit$(unit: UnitCreateRequestModel): Observable<UnitCreateResponseModel> {
    return this.apiService
      .postUnit$(unit.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitCreateResponse>) => UnitCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing unit.
   */
  putUnit$(unit: UnitUpdateRequestModel): Observable<void> {
    return this.apiService
      .putUnit$(unit.unitId, unit.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Disables the given unit
   */
  disableUnit$(unitId: number): Observable<void> {
    return this.apiService
      .disableUnit$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  getSkillUnits$(): Observable<UnitSkillDtoModel[]> {
    return this.apiService
      .getSkillUnits$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitSkillDto[]>) => response.body!.map(UnitSkillDtoModel.fromJSON)),
      );
  }

  getL1Units$(): Observable<UnitSlimWithoutCoordinatorDtoModel[]> {
    return this.apiService
      .getL1Units$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UnitSlimDtoWithoutCoordinatorDto[]>) => response.body!
          .map((unit) => UnitSlimWithoutCoordinatorDtoModel.fromJSON(unit))),
      );
  }

  getColorsByL1Unit$(unitId: number): Observable<BackgroundColorDtoModel[]> {
    return this.apiService
      .getColorsByL1Unit$(unitId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<BackgroundColorResponse[]>) => response.body!
          .map((color) => BackgroundColorDtoModel.fromJSON(color))),
      );
  }

  /**
   * Updates existing background colors
   */
  putColors$(unitId: number, colors: BackgroundColorUpdateRequestModel): Observable<void> {
    return this.apiService
      .putColors$(unitId, colors.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  getHourlyRatesCategories$(userId: string): Observable<string[]> {
    return this.apiService
      .getHourlyRatesCategories$(userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => ((response.body?.items) ? response.body?.items : [])),
      );
  }

  getAllPayrollCompanies$(): Observable<string[]> {
    return this.apiService
      .getAllPayrollCompanies$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => ((response.body) ? response.body : [])),
      );
  }

  getprofitCentersByPayrollCompany$(payrollCompany: string): Observable<string[]> {
    return this.apiService
      .getprofitCentersByPayrollCompany$(payrollCompany)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<any>) => ((response.body) ? response.body : [])),
      );
  }
}
