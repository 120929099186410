import { UserCreateResponse } from '../../interfaces/responses/user-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UserCreateResponseModel {
  constructor(
    readonly userId: string,
  ) {
  }

  static fromJSON(json: UserCreateResponse): UserCreateResponseModel {
    assertRequiredProperties(json, [
      'userId',
    ]);

    return new UserCreateResponseModel(
      json.userId,
    );
  }

  toJSON(): UserCreateResponse {
    return {
      userId: this.userId,
    };
  }
}
