import moment, { Moment } from 'moment';
import { ProjectImportListDto } from '../../interfaces/dtos/project-import-list.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { LevelImportListDtoModel } from './level-import-list.dto.model';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { ProjectImportListDtoMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { ProjectImportListPartialDtoModel } from './project-import-list-partial.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectImportListDtoModel extends ProjectImportListPartialDtoModel {
  constructor(
    readonly sapNumber: string,
    readonly projectNumber: string,
    readonly title: string,
    readonly customerCountryCode: string,
    readonly projectManager: UserSlimDtoModel,
    readonly technicalProjectManager: UserSlimDtoModel | null,
    readonly projectTypeCode: ProjectTypeCode,
    readonly emtOrderFromUnit: string,
    readonly emtOrderFromCountryCode: string,
    readonly structure: readonly LevelImportListDtoModel[],
    readonly metadata: ProjectImportListDtoMetadata,
    readonly projectStatus: string | null,
    readonly projectUserStatus: string | null,
    readonly wbsStatus: string | null,
    readonly wbsUserStatus: string | null,
    readonly getDate: Moment | null,
  ) {
    super(
      sapNumber,
      projectNumber,
      title,
      customerCountryCode,
      projectManager,
      technicalProjectManager,
      projectTypeCode,
      emtOrderFromUnit,
      emtOrderFromCountryCode,
      structure,
      metadata,
      projectStatus,
      projectUserStatus,
      wbsStatus,
      wbsUserStatus,
      getDate,
    );
  }

  static fromJSON(json: ProjectImportListDto): ProjectImportListDtoModel {
    assertRequiredProperties(json, [
      'projectManager',
    ]);

    return new ProjectImportListDtoModel(
      json.sapNumber || '',
      json.projectNumber || '',
      json.title || '',
      json.customerCountryCode || '',
      UserSlimDtoModel.fromJSON(json.projectManager),
      json.technicalProjectManager ? UserSlimDtoModel.fromJSON(json.technicalProjectManager) : null,
      (json.projectTypeCode != null
        ? json.projectTypeCode
        : ProjectTypeCode.ProjectWithWBSPlanning3
      ),
      json.emtOrderFromUnit || '',
      json.emtOrderFromCountryCode || '',
      (Array.isArray(json.structure) ? json.structure : [])
        .map((item) => LevelImportListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}) as any,
      json.projectStatus,
      json.projectUserStatus,
      json.wbsStatus,
      json.wbsUserStatus,
      json.getDate ? moment(json.getDate) : null,
    );
  }

  clone(overrides?: Partial<ProjectImportListDtoModel>): ProjectImportListDtoModel {
    return new ProjectImportListDtoModel(
      (overrides && overrides.sapNumber != null
        ? overrides.sapNumber
        : this.sapNumber
      ),
      (overrides && overrides.projectNumber != null
        ? overrides.projectNumber
        : this.projectNumber
      ),
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.customerCountryCode != null
        ? overrides.customerCountryCode
        : this.customerCountryCode
      ),
      (overrides && overrides.projectManager
        ? overrides.projectManager
        : this.projectManager.clone()
      ),
      (overrides && overrides.technicalProjectManager
        ? overrides.technicalProjectManager
        : this.technicalProjectManager?.clone() || null
      ),
      (overrides && overrides.projectTypeCode != null
        ? overrides.projectTypeCode
        : this.projectTypeCode
      ),
      (overrides && overrides.emtOrderFromUnit != null
        ? overrides.emtOrderFromUnit
        : this.emtOrderFromUnit
      ),
      (overrides && overrides.emtOrderFromCountryCode != null
        ? overrides.emtOrderFromCountryCode
        : this.emtOrderFromCountryCode
      ),
      (overrides && overrides.structure
        ? overrides.structure
        : this.structure.map((item) => item.clone())
      ),
      (overrides && overrides.metadata
        ? overrides.metadata
        : new MetadataModel(this.metadata) as any
      ),
      (overrides && overrides.projectStatus != null
        ? overrides.projectStatus
        : this.projectStatus
      ),
      (overrides && overrides.projectUserStatus != null
        ? overrides.projectUserStatus
        : this.projectUserStatus
      ),
      (overrides && overrides.wbsStatus != null
        ? overrides.wbsStatus
        : this.wbsStatus
      ),
      (overrides && overrides.wbsUserStatus != null
        ? overrides.wbsUserStatus
        : this.wbsUserStatus
      ),
      (overrides && overrides.getDate != null
        ? overrides.getDate
        : this.getDate?.clone() || null
      ),
    );
  }
}
