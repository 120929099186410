import { ActivitiesStateModel } from './activities-state.model';

/**
 * Action types for ActivitiesState
 */
export enum ActivitiesActionTypes {
  InitActivitiesSettings = '[Work Package API] Initialize  Activities Settings',
  SetActivitiesSettings = '[Work Package API] Set  Activities Settings',
  ResetActivitiesSettings = '[Work Package API] Reset  Activities Settings',
  SaveActivitiesSettings = '[Work Package API] Save Work Package Activities Settings',
}

export class InitActivitiesSettings {
  static readonly type: string = ActivitiesActionTypes.InitActivitiesSettings;

  constructor(public settings: ActivitiesStateModel) {}
}

export class SetActivitiesSettings {
  static readonly type: string = ActivitiesActionTypes.SetActivitiesSettings;

  constructor(public settings: ActivitiesStateModel) {}
}

export class ResetActivitiesSettings {
  static readonly type: string = ActivitiesActionTypes.ResetActivitiesSettings;
}

export class SaveActivitiesSettings {
  static readonly type: string = ActivitiesActionTypes.SaveActivitiesSettings;

  constructor(public settings: ActivitiesStateModel) {}
}
