import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { LevelListNestedDtoModel } from '../dtos/level-list-nested.dto.model';
import { ProjectStructureResponse } from '../../interfaces/responses/project-structure.response';

export class ProjectStructureResponseModel {
  constructor(
    readonly items: readonly LevelListNestedDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items]
      .sort((a, b) => (a.sortOrder - b.sortOrder));
  }

  static fromJSON(json: ProjectStructureResponse): ProjectStructureResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    const items = json.items
      .map((item) => LevelListNestedDtoModel.fromJSON(item))
      .sort((a, b) => (a.sortOrder - b.sortOrder));

    return new ProjectStructureResponseModel(
      items,
      new MetadataModel(json.metadata || {}),
    );
  }

  clone(): ProjectStructureResponseModel {
    return new ProjectStructureResponseModel(
      this.items,
      this.metadata,
    );
  }
}
