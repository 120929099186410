import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../api/services/user.service';
import { DashboardWorkPackageViewType } from '../../../models/dashboard-work-package-view-type.enum';
import { WorkPackageCombinedFilterType } from '../../../modules/dashboard/dashboard-work-packages/filter-types';
import { DashboardStateModel } from './dashboard-state.model';
import {
  InitDashboardSettings,
  ResetDashboardSettings,
  SaveDashboardSettings,
  SetDashboardSettings,
  SetDashboardWorkPackageFilter,
  SetDashboardWorkPackagesFilter,
} from './dashboard.actions';

const defaultDashboardState: DashboardStateModel = {
  workPackagesFilter: WorkPackageCombinedFilterType.TO_BE_ISSUED,
  dashboardWorkPackageFilters: [
    DashboardWorkPackageViewType.SuppplierView,
    DashboardWorkPackageViewType.SubmitterView,
    DashboardWorkPackageViewType.SelfAssignedView,
  ],
};

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: defaultDashboardState,
})
@Injectable({
  providedIn: 'root',
})
export class DashboardState {
  constructor(
    private userService: UserService,
  ) {
  }

  @Action(SetDashboardWorkPackagesFilter)
  setWorkPackagesFilter$(
    ctx: StateContext<DashboardStateModel>,
    { filterType }: SetDashboardWorkPackagesFilter,
  ): Observable<void> {
    ctx.patchState({
      workPackagesFilter: filterType,
    });

    // @TODO Should dashboard settings be persisted?
    return of(undefined);
    // return ctx.dispatch(new SaveDashboardSettings(ctx.getState()));
  }

  @Action(SetDashboardWorkPackageFilter)
  setDashboardWorkPackageFilter$(
    ctx: StateContext<DashboardStateModel>,
    { viewType }: SetDashboardWorkPackageFilter,
  ): Observable<void> {
    ctx.patchState({
      dashboardWorkPackageFilters: viewType,
    });

    return ctx.dispatch(new SaveDashboardSettings(ctx.getState()));
  }

  @Action(SetDashboardSettings)
  setDashboardSettings$(
    ctx: StateContext<DashboardStateModel>,
    { settings }: SetDashboardSettings,
  ): Observable<void> {
    ctx.patchState({
      workPackagesFilter: settings.workPackagesFilter,
      dashboardWorkPackageFilters: settings.dashboardWorkPackageFilters,
    });

    return ctx.dispatch(new SaveDashboardSettings(ctx.getState()));
  }

  @Action(ResetDashboardSettings)
  resetDashboardSettings(ctx: StateContext<DashboardStateModel>): void {
    ctx.setState(defaultDashboardState);
  }

  @Action(SaveDashboardSettings)
  saveDashboardSettings$(
    ctx: StateContext<DashboardStateModel>,
    { settings }: SaveDashboardSettings,
  ): Observable<void> {
    return this.userService.saveDashboardSettings$(settings.dashboardWorkPackageFilters);
  }

  @Action(InitDashboardSettings)
  initDashboardSettings(ctx: StateContext<DashboardStateModel>, { settings }: InitDashboardSettings): void {
    ctx.patchState(settings);
  }
}
