import { CollappFilterDropdownListRadioModel } from '../../../models/collapp-filter-dropdown-list-radio.interface';
import { TimecardViewResponse } from '../../interfaces/responses/timecard-view.response';
import { TimeTrackingDayInfoDtoModel } from '../dtos/time-tracking-day-info.dto.model';
import { TimeTrackingRowDtoModel } from '../dtos/time-tracking-row.dto.model';
import { MetadataModel } from '../metadata.model';
import { TimeTrackingProjectDtoModel } from '../dtos/time-tracking-project.dto.model';
import { TimeTrackingStatus } from '../../../models/time-tracking-status.enum';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import moment from 'moment';

export class TimecardViewResponseModel {
  /**
   * Flag which indicates whether the user is privileged to save the timecard.
   */
  readonly canSave: boolean;

  /**
   * Flag which indicates whether the user is privileged to send the timecard to approval.
   */
  readonly canSendToApproval: boolean;

  /**
   * Flag which indicates whether the user is privileged to approve a timecard.
   */
  readonly canApproveTimecard: boolean;

  /**
   * Flag which indicates whether the user is privileged to reject a timecard.
   */
  readonly canRejectTimecard: boolean;

  readonly isSubmitEnabled: boolean;

  readonly isReopenEnabled: boolean;

  readonly isRejectEnabled: boolean;

  readonly canCreateAdHocWorkPackage: boolean;

  readonly canAddNewNpc: boolean;

  /**
   * Flag which indicate a week split on 2 months, tell the name of the active month
   */
  readonly monthSplitActiveMonthName: string | null;

  // eslint-disable-next-line complexity
  constructor(
    readonly status: TimeTrackingStatus,
    readonly submittedOn: moment.Moment | null = null,
    readonly days: readonly TimeTrackingDayInfoDtoModel[],
    readonly nonProductive: readonly TimeTrackingRowDtoModel[],
    readonly unit: UnitSlimDtoModel,
    readonly user: UserSlimDtoModel,
    readonly projects: readonly TimeTrackingProjectDtoModel[] = [],
    readonly salesforceProjects: readonly TimeTrackingProjectDtoModel[] = [],
    readonly metadata: MetadataModel,
    readonly headerFilters: CollappFilterDropdownListRadioModel[],
    readonly totalPlannedHours: number,
    readonly totalReportedHours: number,
  ) {
    this.days = days.map((d) => d.clone());
    this.nonProductive = nonProductive.map((d) => d.clone());
    this.projects = projects.map((d) => d.clone());
    this.salesforceProjects = salesforceProjects.map((d) => d.clone());

    this.canSave = (this.metadata.fields && this.metadata.fields.canSaveTimecard) || false;
    this.canSendToApproval = (this.metadata.fields && this.metadata.fields.canSendTimecardToApproval) || false;
    this.canApproveTimecard = (this.metadata.fields && this.metadata.fields.canApproveTimecard) || false;
    this.canRejectTimecard = (this.metadata.fields && this.metadata.fields.canRejectTimecard) || false;
    this.isSubmitEnabled = (this.metadata.fields && this.metadata.fields.isSubmitEnabled) || false;
    this.isReopenEnabled = (this.metadata.fields && this.metadata.fields.isReopenEnabled) || false;
    this.isRejectEnabled = (this.metadata.fields && this.metadata.fields.isRejectEnabled) || false;
    this.monthSplitActiveMonthName = (this.metadata.fields && this.metadata.fields.monthSplitActiveMonthName) || null;
    this.canCreateAdHocWorkPackage = (this.metadata.fields && this.metadata.fields.canCreateAdHocWorkPackage) || null;
    this.canAddNewNpc = (this.metadata.fields && this.metadata.fields.canAddNewNpc) || null;
  }

  static fromJSON(json: TimecardViewResponse): TimecardViewResponseModel {
    assertRequiredProperties(json, [
      'status',
      'days',
      'nonProductive',
      'unit',
      'user',
    ]);

    return new TimecardViewResponseModel(
      json.status,
      json.submittedOn ? moment(json.submittedOn).parseZone() : null,
      (Array.isArray(json.days)
        ? json.days
          .map((item) => TimeTrackingDayInfoDtoModel.fromJSON(item))
        : []
      ),
      (Array.isArray(json.nonProductive)
        ? json.nonProductive
          .map((item) => TimeTrackingRowDtoModel.fromJSON(item))
        : []
      ),
      UnitSlimDtoModel.fromJSON(json.unit),
      UserSlimDtoModel.fromJSON(json.user),
      (Array.isArray(json.projects)
        ? json.projects
          .map((item) => TimeTrackingProjectDtoModel.fromJSON(item))
        : []
      ),
      (Array.isArray(json.salesforceProjects)
        ? json.salesforceProjects
          .map((item) => TimeTrackingProjectDtoModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata),
      json.headerFilters,
      json.totalPlannedHours,
      json.totalReportedHours,
    );
  }

  clone(): TimecardViewResponseModel {
    return new TimecardViewResponseModel(
      this.status,
      this.submittedOn,
      this.days,
      this.nonProductive,
      this.unit,
      this.user,
      this.projects,
      this.salesforceProjects,
      this.metadata,
      this.headerFilters,
      this.totalPlannedHours,
      this.totalReportedHours,
    );
  }
}
