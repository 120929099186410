import { UserNotificationSettingsDto } from '../../interfaces/dtos/user-notification-settings.dto';

export class UserNotificationSettingsDtoModel {
  constructor(
    readonly userId: string,
    readonly notificationName: string | null,
    readonly hasCustomValues: boolean,
    readonly orderBy: number,
    readonly notificationTypeId: number,
    readonly isEnabled: boolean,
    readonly firstAlertValue: number | null,
    readonly secondAlertValue: number | null,
    readonly isBefore: boolean,
  ) {}

  static fromJSON(json: UserNotificationSettingsDto): UserNotificationSettingsDtoModel {
    return new UserNotificationSettingsDtoModel(
      json.userId,
      json.notificationName,
      json.hasCustomValues,
      json.orderBy,
      json.notificationTypeId,
      json.isEnabled,
      json.firstAlertValue,
      json.secondAlertValue,
      json.isBefore,
    );
  }

  toJSON(): UserNotificationSettingsDto {
    return {
      userId: this.userId,
      notificationName: this.notificationName,
      hasCustomValues: this.hasCustomValues,
      orderBy: this.orderBy,
      notificationTypeId: this.notificationTypeId,
      isEnabled: this.isEnabled,
      firstAlertValue: this.firstAlertValue,
      secondAlertValue: this.secondAlertValue,
      isBefore: this.isBefore,
    };
  }

  clone(): UserNotificationSettingsDtoModel {
    return new UserNotificationSettingsDtoModel(
      this.userId,
      this.notificationName,
      this.hasCustomValues,
      this.orderBy,
      this.notificationTypeId,
      this.isEnabled,
      this.firstAlertValue,
      this.secondAlertValue,
      this.isBefore,
    );
  }
}
