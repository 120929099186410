/**
 * Levels (depth) of units
 */
export enum UnitLevel {
  UNKNOWN = -1,
  Level1 = 0,
  Level2 = 1,
  Level3 = 2,
  Level4 = 3,
  Level5 = 4,
}
