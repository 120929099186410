import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddAdditionalSupplierButtonComponent } from './add-additional-supplier-button.component';
import { SpinnerModule } from '../../spinner';

@NgModule({
  declarations: [
    AddAdditionalSupplierButtonComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
  ],
  exports: [
    AddAdditionalSupplierButtonComponent,
  ],
})
export class AddAdditionalSupplierButtonModule { }
