import { SkillDtoModel } from './skill.dto.model';
import { SkillCategoryWithSkillsDto } from '../../interfaces/dtos/skill-category-with-skills.dto';

export class SkillCategoryWithSkillsDtoModel {
  constructor(
    readonly id: number,
    readonly name: string | null,
    readonly skills: SkillDtoModel[],
  ) {
    this.skills = skills.map((skill) => skill.clone());
  }

  static fromJSON(json: SkillCategoryWithSkillsDto): SkillCategoryWithSkillsDtoModel {
    return new SkillCategoryWithSkillsDtoModel(
      json.id,
      json.name,
      json.skills.map(SkillDtoModel.fromJSON),
    );
  }

  clone(): SkillCategoryWithSkillsDtoModel {
    return new SkillCategoryWithSkillsDtoModel(
      this.id,
      this.name,
      this.skills,
    );
  }
}
