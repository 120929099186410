/* eslint-disable max-classes-per-file */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxsModule } from '@ngxs/store';
import { RouterModule } from '@angular/router';
import { NgSelectMaterialModule } from '../components/ng-select-mat';
import { MaterialModule } from '../material.module';
import { CollAppCountryNamePipe } from './country-name.pipe';
import { CollAppGatePipe } from './gate.pipe';
import { CollAppRolePipe } from './role.pipe';
import { FormActionsComponent } from './form-actions/form-actions.component';
import { FormAttachmentsModule } from '../components/form-attachments';
import { FormSectionModule } from '../components/form-section';
import { CollappButtonModule } from '../components/collapp-button';
import { ProjectTreeStructureModule } from '../components/project-tree-structure';
import { ColumnSelectorComponent } from './column-selector/column-selector.component';
import { ColumnSelectorMenuComponent } from './column-selector/column-selector-menu.component';
import { BasicDialogComponent } from './dialogs/basic-dialog/basic.dialog.component';
import { PeopleSelectModule } from '../components/people-select';
import { PrimaryNavigationComponent } from './components/primary-nav/primary-nav.component';
import { PersonIconModule } from '../components/person-icon';
import { UnitSelectModule } from '../components/unit-select';
import { CollAppBadgeComponent } from './collapp-badge/collapp-badge.component';
import { PersonListItemComponent } from './person-list-item/person-list-item.component';
import { ShowMoreComponent } from './show-more/show-more.component';
import { ToastyModule } from './toasty';
import { CollAppProjectStatusPipe } from './project-status.pipe';
import { CollAppUnitPipe } from './unit.pipe';
import { SpinnerModule } from '../components/spinner';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NotificationDialogComponent } from './dialogs/notification-dialog/notification-dialog.component';
import { PersonModule } from '../components/person';
import { sharedStates } from '../state';
import { DatesRangeModule } from '../components/dates-range';
import { ResponsiblesPipe } from './responsibles.pipe';
import { ResponsiblesTooltipPipe } from './responsibles-tooltip.pipe';
import { CollappDateComponent } from './date/date.component';
import { CollappCommonModule } from '../collapp-common';
import { AlertModule } from './alert/alert.module';
import { AlertComponent } from './alert/alert.component';
import { TranslateModule } from '@ngx-translate/core';

const sharedComponents = [
  CollappDateComponent,
  CollAppCountryNamePipe,
  CollAppProjectStatusPipe,
  CollAppGatePipe,
  CollAppRolePipe,
  CollAppUnitPipe,
  CollAppBadgeComponent,
  // CollAppPreserveWhitespacePipe,
  ColumnSelectorComponent,
  ColumnSelectorMenuComponent,
  BasicDialogComponent,
  FormActionsComponent,
  NotificationDialogComponent,
  PersonListItemComponent,
  PrimaryNavigationComponent,
  ProgressBarComponent,
  ShowMoreComponent,
  ResponsiblesPipe,
  ResponsiblesTooltipPipe,
];

/**
 * The Hidden Root Module Strategy™
 * We declare the SharedModule as usual, but in the forRoot() method, we use a module that
 * will be the SharedModule's singleton module. It will only get instantiated once, and used by the forRoot caller.
 *
 * Then in the lazy view we import SharedModule as usual, and that means that we don't instantiate stores or
 * services twice.
 *
 * It's a rather clean workaround for ModuleWithProviders not supporting "imports".
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    NgxFileDropModule,
    FlexLayoutModule,
    CollappCommonModule,
    CollappButtonModule,
    FormSectionModule,
    ProjectTreeStructureModule,
    UnitSelectModule,
    NgSelectMaterialModule,
    PeopleSelectModule,
    PersonModule,
    PersonIconModule,
    FormAttachmentsModule,
    SpinnerModule,
    DatesRangeModule,
    AlertModule,
  ],
  declarations: [
    ...sharedComponents,
  ],
  exports: [
    CommonModule,
    CollappButtonModule,
    FormSectionModule,
    ProjectTreeStructureModule,
    UnitSelectModule,
    NgSelectMaterialModule,
    PeopleSelectModule,
    PersonModule,
    PersonIconModule,
    FormAttachmentsModule,
    SpinnerModule,
    DatesRangeModule,
    ...sharedComponents,
    AlertComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<RootSharedModule> {
    return {
      ngModule: RootSharedModule, // eslint-disable-line @typescript-eslint/no-use-before-define
    };
  }
}

/**
 * Special module that is only used when calling forRoot()
 */
@NgModule({
  imports: [
    NgxsModule.forFeature([
      ...sharedStates,
    ]),

    // import so that the module calling forRoot() gets hold of all the stuff too.
    SharedModule,
    ToastyModule.forRoot(),
  ],
  providers: [ // Services that should be shared across modules
  ],
  exports: [
    SharedModule,
    ToastyModule,
  ],
})
export class RootSharedModule {
}
