import { UnitDto } from '../../interfaces/dtos/unit.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { ContractDtoModel } from './contract.dto.model';
import { UnitLevel } from '../../../models/unit-level.enum';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitDtoModel {
  constructor(
    readonly unitId: number,
    readonly code: string,
    readonly name: string,
    readonly level: UnitLevel,
    readonly parentUnit: UnitSlimDtoModel | null,
    readonly unitCoordinator: UserSlimDtoModel | null,
    readonly deputyUnitCoordinators: readonly UserSlimDtoModel[] = [],
    readonly submitterContracts: readonly ContractDtoModel[] = [],
    readonly supplierContracts: readonly ContractDtoModel[] = [],
    readonly migratedToReiway: boolean = false,
    readonly transferReportedHoursEnabled: boolean = false,
  ) {
    this.deputyUnitCoordinators = [...deputyUnitCoordinators];
    this.submitterContracts = [...submitterContracts];
    this.supplierContracts = [...supplierContracts];
  }

  static fromJSON(json: UnitDto): UnitDtoModel {
    assertRequiredProperties(json, [
      'unitId',
      'code',
      'name',
      'level',
    ]);

    return new UnitDtoModel(
      json.unitId,
      json.code,
      json.name,
      json.level,
      (json.parentUnit
        ? UnitSlimDtoModel.fromJSON(json.parentUnit)
        : null
      ),
      (json.unitCoordinator
        ? UserSlimDtoModel.fromJSON(json.unitCoordinator)
        : null
      ),
      (Array.isArray(json.deputyUnitCoordinators) ? json.deputyUnitCoordinators : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.submitterContracts) ? json.submitterContracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      (Array.isArray(json.supplierContracts) ? json.supplierContracts : [])
        .map((item) => ContractDtoModel.fromJSON(item)),
      json.migratedToReiway,
      json.transferReportedHoursEnabled,
    );
  }

  clone(): UnitDtoModel {
    return new UnitDtoModel(
      this.unitId,
      this.code,
      this.name,
      this.level,
      (this.parentUnit
        ? this.parentUnit.clone()
        : null
      ),
      (this.unitCoordinator
        ? this.unitCoordinator.clone()
        : null
      ),
      this.deputyUnitCoordinators.map((item) => item.clone()),
      this.submitterContracts.map((item) => item.clone()),
      this.supplierContracts.map((item) => item.clone()),
      this.migratedToReiway,
      this.transferReportedHoursEnabled,
    );
  }

  toJSON(): UnitDto {
    return {
      unitId: this.unitId,
      code: this.code,
      name: this.name,
      level: this.level,
      parentUnit: (this.parentUnit
        ? this.parentUnit.toJSON()
        : null
      ),
      unitCoordinator: (this.unitCoordinator
        ? this.unitCoordinator.toJSON()
        : null
      ),
      deputyUnitCoordinators: this.deputyUnitCoordinators.map((item) => item.toJSON()),
      submitterContracts: this.submitterContracts.map((item) => item.toJSON()),
      supplierContracts: this.supplierContracts.map((item) => item.toJSON()),
      migratedToReiway: this.migratedToReiway,
      transferReportedHoursEnabled: this.transferReportedHoursEnabled,
    };
  }
}
