import { UserPlanningSidepanelStateModel } from './user-planning-sidepanel-state.model';

/**
 * Action types for UserPlanningSidepanel
 */
export enum UserPlanningSidepanelActionTypes {
  InitUserPlanningSidepanel = '[UserPlanningSidepanel API] Initialize Sidepanel isOpen',
  OpenUserPlanningSidepanel = '[UserPlanningSidepanel API] Set UserPlanningSidepanel open',
  CloseUserPlanningSidepanel = '[UserPlanningSidepanel API] Set UserPlanningSidepanel closed',
  ToggleUserPlanningSidepanel = '[UserPlanningSidepanel API] Toggle UserPlanningSidepanel',
}

export class InitUserPlanningSidepanel {
  static readonly type: string = UserPlanningSidepanelActionTypes.InitUserPlanningSidepanel;

  constructor(public isOpen: UserPlanningSidepanelStateModel) {}
}

export class OpenUserPlanningSidepanel {
  static readonly type: string = UserPlanningSidepanelActionTypes.OpenUserPlanningSidepanel;
}

export class CloseUserPlanningSidepanel {
  static readonly type: string = UserPlanningSidepanelActionTypes.CloseUserPlanningSidepanel;
}

export class ToggleUserPlanningSidepanel {
  static readonly type: string = UserPlanningSidepanelActionTypes.ToggleUserPlanningSidepanel;
}
