import { Injectable, inject } from '@angular/core';
import { Observable, filter, firstValueFrom, map } from 'rxjs';
import { CollAppApiService } from '../api/services/collapp-api.service';
import { ActivityTypeDto } from '../api/interfaces/dtos/unit-cost-center-activity-type.dto';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ActivityTypeModel } from '../api/models/dtos/activity-type.dto.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypesService {

  private readonly apiService = inject(CollAppApiService);

  getActivityTypesByCostCenterAndUnitId(unitId: string, costCenter: string): Promise<ActivityTypeModel[]> {
    return firstValueFrom((this.apiService
    .getUnitActivityTypesByCostCenterAndUnitId$(unitId, costCenter)
    .pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((response: HttpResponse<ActivityTypeDto[]>) => response.body!.map(r => ActivityTypeModel.fromJSON(r)),
    ))));
  }

  getSupplierActivityTypes$(supplierId: string): Observable<ActivityTypeModel[]> {
    return (this.apiService
    .getSupplierActivityTypes$(supplierId)
    .pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((response: HttpResponse<ActivityTypeDto[]>) => response.body!.map(r => ActivityTypeModel.fromJSON(r)),
    )));
  }

  getActivityTypesByCostCenter$(costCenter: string): Observable<ActivityTypeModel[]> {
    return (this.apiService
    .getUnitActivityTypesByCostCenter$(costCenter)
    .pipe(
      filter((event) => event.type === HttpEventType.Response),
      map((response: HttpResponse<ActivityTypeDto[]>) => response.body!.map(r => ActivityTypeModel.fromJSON(r)),
    )));
  }
}
