import { escape } from 'html-escaper';
import { BasicNonProductiveCategory } from './basic-non-productive-category.interface';
import { assertRequiredProperties } from '../api/utilities/api.utility';

export interface NonProductiveCategory extends BasicNonProductiveCategory {
  unitCategoryId: number | null;
  defaultTimecardCategoryId: number | null;
}

export class NonProductiveCategoryModel {
  constructor(
    public name: string | null,
    public sapCode: string | null,
    public isEnabled: boolean,
    public hasReportedHours: boolean,
    public unitCategoryId: number | null = null,
    public defaultTimecardCategoryId: number | null = null,
  ) {
  }

  static fromJSON(json: NonProductiveCategory): NonProductiveCategoryModel {
    assertRequiredProperties(json, [
      'isEnabled',
      'hasReportedHours',
    ]);

    return new NonProductiveCategoryModel(
      json.name,
      json.sapCode,
      json.isEnabled,
      json.hasReportedHours,
      json.unitCategoryId,
      json.defaultTimecardCategoryId,
    );
  }

  clone(): NonProductiveCategoryModel {
    return new NonProductiveCategoryModel(
      this.name,
      this.sapCode,
      this.isEnabled,
      this.hasReportedHours,
      this.unitCategoryId,
      this.defaultTimecardCategoryId,
    );
  }

  toJSON(): NonProductiveCategory {
    return {
      name: this.name ? escape(this.name) : null,
      sapCode: this.sapCode !== undefined && this.sapCode !== null ? escape(this.sapCode) : '',
      isEnabled: this.isEnabled,
      hasReportedHours: this.hasReportedHours,
      unitCategoryId: this.unitCategoryId,
      defaultTimecardCategoryId: this.defaultTimecardCategoryId,
    };
  }
}
