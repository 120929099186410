import { UnitPlanningRangeStateModel } from './unit-planning-range-state.model';

/**
 * Action types for UnitPlanningRangeState
 */
export enum UnitPlanningRangeActionTypes {
  InitUnitPlanningRangeSettings = '[Unit Planning API] Initialize Unit Planning Range Settings',
  SetUnitPlanningRangeSettings = '[Unit Planning API] Set Unit Planning Range Settings',
  ResetUnitPlanningRangeSettings = '[Unit Planning API] Reset Unit Planning Range Settings',
  SaveUnitPlanningRangeSettings = '[Unit Planning API] Save Unit Planning Range Settings',
}

export class InitUnitPlanningRangeSettings {
  static readonly type: string = UnitPlanningRangeActionTypes.InitUnitPlanningRangeSettings;

  constructor(public settings: UnitPlanningRangeStateModel) {}
}

export class SetUnitPlanningRangeSettings {
  static readonly type: string = UnitPlanningRangeActionTypes.SetUnitPlanningRangeSettings;

  constructor(public settings: UnitPlanningRangeStateModel) {}
}

export class ResetUnitPlanningRangeSettings {
  static readonly type: string = UnitPlanningRangeActionTypes.ResetUnitPlanningRangeSettings;
}

export class SaveUnitPlanningRangeSettings {
  static readonly type: string = UnitPlanningRangeActionTypes.SaveUnitPlanningRangeSettings;

  constructor(public settings: UnitPlanningRangeStateModel) {}
}
