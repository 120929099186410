import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { CollappCommonModule } from '../../collapp-common';
import { ProjectTreeStructureComponent } from './project-tree-structure.component';
import { LevelChangeIndicatorComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTreeModule,
    CollappCommonModule,
  ],
  exports: [
    ProjectTreeStructureComponent,
    LevelChangeIndicatorComponent,
  ],
  declarations: [
    ProjectTreeStructureComponent,
    LevelChangeIndicatorComponent,
  ],
})
export class ProjectTreeStructureModule {
}
