import moment from 'moment';
import { SuspensionCreateRequest } from '../../interfaces/requests/suspension-create.request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class SuspensionCreateRequestModel {
  constructor(
    readonly workPackageId: number,
    readonly estimatedNewEndDate: moment.Moment,
    readonly commentMessage: string = '',
    readonly sendNotifications: boolean = true,
  ) {
    this.estimatedNewEndDate = estimatedNewEndDate.clone();
  }

  static fromJSON(json: SuspensionCreateRequest): SuspensionCreateRequestModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'estimatedNewEndDate',
    ]);

    return new SuspensionCreateRequestModel(
      json.workPackageId,
      moment(json.estimatedNewEndDate)
        .parseZone(),
      json.commentMessage || '',
      json.sendNotifications,
    );
  }

  toJSON(): SuspensionCreateRequest {
    return {
      workPackageId: this.workPackageId,
      estimatedNewEndDate: this.estimatedNewEndDate.toJSON(),
      commentMessage: this.commentMessage,
      sendNotifications: this.sendNotifications,
    };
  }
}
