import { Component, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'collapp-level-change-indicator',
  templateUrl: './level-change-indicator.component.html',
  styleUrls: ['./level-change-indicator.component.scss'],
})
export class LevelChangeIndicatorComponent {
  get deleted(): boolean { return this._deleted; }

  @Input()
  set deleted(value: boolean) {
    this._deleted = coerceBooleanProperty(value);
  }

  get existing(): boolean { return this._existing; }

  @Input()
  set existing(value: boolean) {
    this._existing = coerceBooleanProperty(value);
  }

  get hasChanges(): boolean { return this._hasChanges; }

  @Input()
  set hasChanges(value: boolean) {
    this._hasChanges = coerceBooleanProperty(value);
  }

  private _deleted: boolean = false;

  private _existing: boolean = false;

  private _hasChanges: boolean = false;
}
