import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { ReadonlyCompleteMap } from '../../app.types';
import { UserSlimDtoModel } from '../models/dtos/user-slim.dto.model';

export type UserResourceListColumnKeys = ObjectPropertyNames<UserSlimDtoModel>;
const userResourceListColumnsInfoObj: ListColumnsInfo<UserSlimDtoModel> = {
  userId: {
    label: 'User Id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  id: {
    label: 'id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  fullName: {
    label: 'Full Name',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  email: {
    label: 'Email',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  photoUrl: {
    label: 'Photo URL',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  validFrom: {
    label: 'Valid From',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  validUntil: {
    label: 'Valid Until',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const userResourceListColumnsInfo: ReadonlyCompleteMap<
UserResourceListColumnKeys,
ListColumnInfo
> = new Map([
  ['userId', userResourceListColumnsInfoObj.userId],
  ['fullName', userResourceListColumnsInfoObj.fullName],
  ['email', userResourceListColumnsInfoObj.email],
  ['photoUrl', userResourceListColumnsInfoObj.photoUrl],
  ['validFrom', userResourceListColumnsInfoObj.validFrom],
  ['validUntil', userResourceListColumnsInfoObj.validUntil],
]) as ReadonlyCompleteMap<UserResourceListColumnKeys, ListColumnInfo>;
