import { ProjectScopeViewResponse } from '../../interfaces/responses/project-scope-view.response';
import { GateDtoModel } from '../dtos/gate.dto.model';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { AttachmentSlimDtoModel } from '../dtos/attachment-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectScopeViewResponseModel {
  readonly canEditProject: boolean;

  readonly canEditProjectType: boolean;

  readonly canArchiveProject: boolean;

  readonly canUnarchiveProject: boolean;

  constructor(
    readonly projectId: number | null,
    readonly scope: string | null,
    readonly sapNumber: string | null,
    readonly projectNumber: string | null,
    readonly title: string,
    readonly attachments: readonly AttachmentSlimDtoModel[] = [],
    readonly projectTypeCode: ProjectTypeCode = ProjectTypeCode.Unknown,
    readonly gate: GateDtoModel | null = null,
    readonly customerCountryCode: string = '',
    readonly placeOfManufacturingCountryCode: string = '',
    readonly emtOrderFromUnit: string | null = null,
    readonly emtOrderFromCountryCode: string = '',
    readonly submitterUnit: UnitSlimDtoModel | null = null,
    readonly projectManager: UserSlimDtoModel | null = null,
    readonly technicalProjectManager: UserSlimDtoModel | null = null,
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.attachments = [...attachments];

    this.canEditProject = this.metadata.fields.canEditProject;
    this.canEditProjectType = this.metadata.fields.canEditProjectType;
    this.canArchiveProject = this.metadata.fields.canArchiveProject;
    this.canUnarchiveProject = this.metadata.fields.canUnarchiveProject;
  }

  static fromJSON(json: ProjectScopeViewResponse): ProjectScopeViewResponseModel {
    assertRequiredProperties(json, [
      // 'projectId',
    ]);

    return new ProjectScopeViewResponseModel(
      (json.projectId != null
        ? json.projectId
        : null
      ),
      (json.scope != null
        ? json.scope
        : null
      ),
      (json.sapNumber != null
        ? json.sapNumber
        : null
      ),
      (json.projectNumber != null
        ? json.projectNumber
        : null
      ),
      (json.title != null
        ? json.title
        : ''
      ),
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
      (json.projectTypeCode != null
        ? json.projectTypeCode
        : ProjectTypeCode.Unknown
      ),
      (json.gate
        ? GateDtoModel.fromJSON(json.gate)
        : null
      ),
      json.customerCountryCode || '',
      json.placeOfManufacturingCountryCode || '',
      (json.emtOrderFromUnit != null
        ? json.emtOrderFromUnit
        : null
      ),
      json.emtOrderFromCountryCode || '',
      (json.submitterUnit
        ? UnitSlimDtoModel.fromJSON(json.submitterUnit)
        : null
      ),
      (json.projectManager
        ? UserSlimDtoModel.fromJSON(json.projectManager)
        : null
      ),
      (json.technicalProjectManager
        ? UserSlimDtoModel.fromJSON(json.technicalProjectManager)
        : null
      ),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): ProjectScopeViewResponse {
    return {
      projectId: this.projectId,
      scope: this.scope,
      sapNumber: this.sapNumber,
      projectNumber: this.projectNumber,
      title: this.title,
      attachments: this.attachments.map((item) => item.toJSON()),
      projectTypeCode: this.projectTypeCode,
      gate: this.gate ? this.gate.toJSON() : null,
      customerCountryCode: this.customerCountryCode,
      placeOfManufacturingCountryCode: this.placeOfManufacturingCountryCode,
      emtOrderFromUnit: this.emtOrderFromUnit,
      emtOrderFromCountryCode: this.emtOrderFromCountryCode,
      submitterUnit: this.submitterUnit ? this.submitterUnit.toJSON() : null,
      projectManager: this.projectManager ? this.projectManager.toJSON() : null,
      technicalProjectManager: this.technicalProjectManager ? this.technicalProjectManager.toJSON() : null,
      metadata: this.metadata.toJSON(),
    };
  }
}
