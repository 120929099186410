import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningSlimDto } from '../../interfaces/dtos/user-planning-slim.dto';

export class UserPlanningSlimDtoModel {
  constructor(
    readonly userId: string,
    readonly fullName: string,
    readonly photoUrl: string | null = null,
  ) {}

  static fromJSON(json: UserPlanningSlimDto): UserPlanningSlimDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'fullName',
    ]);

    return new UserPlanningSlimDtoModel(
      json.userId,
      json.fullName,
      json.photoUrl,
    );
  }

  clone(): UserPlanningSlimDtoModel {
    return new UserPlanningSlimDtoModel(
      this.userId,
      this.fullName,
      this.photoUrl,
    );
  }
}
