import moment from 'moment';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { BasicWorkPackageDetailResponse } from '../../interfaces/responses/basic-work-package-detail.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class BasicWorkPackageDetailResponseModel {
  constructor(
    readonly latestEstimatedStartDate: moment.Moment | null = null,
    readonly latestEstimatedEndDate: moment.Moment | null = null,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly latestPlannedEndDate: moment.Moment | null = null,
    readonly latestEstimatedHours: number | null = null,
    readonly plannedHours: number | null = null,
    readonly reportedHours: number | null = null,
    readonly remainingHours: number | null = null,
    readonly responsibleUsers: readonly UserSlimDtoModel[] = [],
    readonly canAddUserToWorkPackage: boolean = false,
    readonly isUserAlreadyResponsible: boolean = false,
  ) {
    this.latestEstimatedStartDate = latestEstimatedStartDate ? latestEstimatedStartDate.clone() : null;
    this.latestEstimatedEndDate = latestEstimatedEndDate ? latestEstimatedEndDate.clone() : null;
    this.plannedStartDate = plannedStartDate ? plannedStartDate.clone() : null;
    this.latestPlannedEndDate = latestPlannedEndDate ? latestPlannedEndDate.clone() : null;
    this.responsibleUsers = [...responsibleUsers];
  }

  static fromJSON(json: BasicWorkPackageDetailResponse): BasicWorkPackageDetailResponseModel {
    assertRequiredProperties(json, [
      'latestEstimatedStartDate',
      'latestEstimatedEndDate',
      'plannedStartDate',
      'latestPlannedEndDate',
      'latestEstimatedHours',
      'responsibleUsers',
      'plannedHours',
      'reportedHours',
      'remainingHours',
      'canAddUserToWorkPackage',
      'isUserAlreadyResponsible',
    ]);

    return new BasicWorkPackageDetailResponseModel(
      moment(json.latestEstimatedStartDate).parseZone(),
      moment(json.latestEstimatedEndDate).parseZone(),
      moment(json.plannedStartDate).parseZone(),
      moment(json.latestPlannedEndDate).parseZone(),
      json.latestEstimatedHours,
      json.plannedHours,
      json.reportedHours,
      json.remainingHours,
      (Array.isArray(json.responsibleUsers) ? json.responsibleUsers : [])
        .map((item) => UserSlimDtoModel.fromJSON((item))),
      json.canAddUserToWorkPackage,
      json.isUserAlreadyResponsible,
    );
  }
}
