import moment from 'moment';
import { TimeTrackingDayInfoDto } from '../../interfaces/dtos/time-tracking-day-info.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingDayInfoDtoModel {
  /**
   * Not provided via API, used to
   * store the calculated hours
   */
  calculatedHours: number = 0;

  constructor(
    readonly date: moment.Moment,
    readonly isHoliday: boolean,
    readonly holidayDescription: string,
    readonly punchedHours: number | null = null,
    readonly reportedHours: number | null = null,
  ) {
    this.date = date.clone();
  }

  static fromJSON(json: TimeTrackingDayInfoDto): TimeTrackingDayInfoDtoModel {
    assertRequiredProperties(json, [
      'date',
      'isHoliday',
    ]);

    return new TimeTrackingDayInfoDtoModel(
      moment(json.date)
        .parseZone(),
      json.isHoliday,
      json.holidayDescription,
      json.punchedHours,
      json.reportedHours,
    );
  }

  toJSON(): TimeTrackingDayInfoDto {
    return {
      date: this.date.toJSON(),
      isHoliday: this.isHoliday,
      holidayDescription: this.holidayDescription,
      punchedHours: this.punchedHours,
      reportedHours: this.reportedHours,
    };
  }

  clone(): TimeTrackingDayInfoDtoModel {
    // eslint-disable-next-line max-len
    return new TimeTrackingDayInfoDtoModel(this.date, this.isHoliday, this.holidayDescription, this.punchedHours, this.reportedHours);
  }
}
