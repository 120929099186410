import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningTimeTrackingProjectDto } from '../../interfaces/dtos/user-planning-time-tracking-project.dto';
import { UserPlanningTimeTrackingWorkPackageDtoModel } from './user-planning-time-tracking-work-package.dto.model';

export class UserPlanningTimeTrackingProjectDtoModel {
  /**
   * Not provided from the API:
   * Used to aggregate the total hours of every work package
   * per day.
   */
  readonly totalHours: number[];

  constructor(
    readonly projectId: number,
    readonly projectNumber: string,
    readonly projectTitle: string,
    readonly workPackages: readonly UserPlanningTimeTrackingWorkPackageDtoModel[],
    readonly totalPlanned: number | null = null,
    readonly totalRecorded: number | null = null,
    readonly totalAvailableToBePlanned: number | null = null,
    readonly totalConfirmed: number | null = null,
  ) {
    this.workPackages = [...workPackages];

    // Calculates for every day the sum of reported work package hours
    this.totalHours = this.calculateTotalHours();
  }

  static fromJSON(json: UserPlanningTimeTrackingProjectDto): UserPlanningTimeTrackingProjectDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'projectNumber',
      'projectTitle',
      'workPackages',
    ]);

    return new UserPlanningTimeTrackingProjectDtoModel(
      json.projectId,
      json.projectNumber,
      json.projectTitle,
      (Array.isArray(json.workPackages) ? json.workPackages : [])
        .map((item) => UserPlanningTimeTrackingWorkPackageDtoModel.fromJSON(item)),
      json.totalPlanned,
      json.totalRecorded,
      json.totalAvailableToBePlanned,
      json.totalConfirmed,
    );
  }

  clone(): UserPlanningTimeTrackingProjectDtoModel {
    return new UserPlanningTimeTrackingProjectDtoModel(
      this.projectId,
      this.projectNumber,
      this.projectTitle,
      this.workPackages.map((item) => item.clone()),
      this.totalPlanned,
      this.totalRecorded,
      this.totalAvailableToBePlanned,
      this.totalConfirmed,
    );
  }

  toJSON(): UserPlanningTimeTrackingProjectDto {
    return {
      projectId: this.projectId,
      projectNumber: this.projectNumber,
      projectTitle: this.projectTitle,
      workPackages: this.workPackages.map((item) => item.toJSON()),
      totalPlanned: this.totalPlanned,
      totalRecorded: this.totalRecorded,
      totalAvailableToBePlanned: this.totalAvailableToBePlanned,
      totalConfirmed: this.totalConfirmed,
    };
  }

  /**
   * Sums the hours reported in work packages for a specific week day.
   * @param dayIndex
   */
  calculateTotalHoursOfRow(weekIndex: number): number {
    let sum = 0;

    this.workPackages.forEach((wp) => {
      const hours = wp.weeks[weekIndex].plannedHours;

      if (hours) {
        sum += hours;
      }
    });

    this.totalHours[weekIndex] = sum;

    return sum;
  }

  /**
   * Returns a list of summed hours. Every entry in the list
   * represents a day of the week. The hours are summed
   * for every work package.
   */
  private calculateTotalHours(): number[] {
    const sums: number[] = [];

    this.workPackages.forEach((wp) => {
      wp.weeks.forEach((week, weekIndex) => {
        if (week.plannedHours) {
          // Make sure the sums are initialized for every day
          sums[weekIndex] = (sums[weekIndex] || 0) + week.plannedHours;
        }
      });
    });

    return sums;
  }
}
