import { GenericListOptions } from '../generic-list';
import { QuestionsViewListColumnKeys } from '../../../api/meta-info/questions-list.info';
import { QuestionsColumnFilters } from '../../../api/filter-columns';
import { QuestionsListStateModel } from './questions-list-state.model';

/**
 * Action types for QuestionsListState
 */
export enum QuestionsListActionTypes {
  SetQuestionsListSelectedColumns = '[ Questions List Page] Set Selected Columns',
  SetQuestionsListOptions = '[ Questions List Page] Set Options',
  ResetQuestionsListOptions = '[ Questions List Page] Reset Options',
  InitQuestionsListSettings = '[ Questions API] Initialize Questions List Settings',
  SetQuestionsListSettings = '[ Questions API] Set Questions List Settings',
  ResetQuestionsListSettings = '[ Questions API] Reset Questions List Settings',
  SaveQuestionsListSettings = '[ Questions API] Save Questions List Settings',
}

export interface QuestionsListOptions extends GenericListOptions {
  columnFilters: QuestionsColumnFilters;
}

export class SetQuestionsListSelectedColumns {
  static readonly type: string = QuestionsListActionTypes.SetQuestionsListSelectedColumns;

  constructor(public columns: QuestionsViewListColumnKeys[]) {}
}

export class SetQuestionsListOptions {
  static readonly type: string = QuestionsListActionTypes.SetQuestionsListOptions;

  constructor(public options: QuestionsListOptions) {}
}

export class ResetQuestionsListOptions {
  static readonly type: string = QuestionsListActionTypes.ResetQuestionsListOptions;
}

export class InitQuestionsListSettings {
  static readonly type: string = QuestionsListActionTypes.InitQuestionsListSettings;

  constructor(public settings: QuestionsListStateModel) {}
}

export class SetQuestionsListSettings {
  static readonly type: string = QuestionsListActionTypes.SetQuestionsListSettings;

  constructor(public settings: QuestionsListStateModel) {}
}

export class ResetQuestionsListSettings {
  static readonly type: string = QuestionsListActionTypes.ResetQuestionsListSettings;
}

export class SaveQuestionsListSettings {
  static readonly type: string = QuestionsListActionTypes.SaveQuestionsListSettings;

  constructor(public settings: QuestionsListStateModel) {}
}
