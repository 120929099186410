import { Selector } from '@ngxs/store';
import { UserPlanningRangeStateModel } from './user-planning-range-state.model';
import { UserPlanningRangeState } from './user-planning-range.state';

export class UserPlanningRangeSelectors {
  @Selector([UserPlanningRangeState])
  static range(state: UserPlanningRangeStateModel): UserPlanningRangeStateModel['range'] {
    return state.range;
  }
}
