import { Selector } from '@ngxs/store';
import { AttachmentListState } from './attachment-list.state';
import { AttachmentListOptions } from './attachment-list.actions';
import { AttachmentListStateModel } from './attachment-list-state.model';

export class AttachmentListSelectors {
  @Selector([AttachmentListState])
  static selectedColumns(state: AttachmentListStateModel): AttachmentListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([AttachmentListState])
  static options(state: AttachmentListStateModel): AttachmentListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: state.columnFilters,
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
