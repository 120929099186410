import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CollappCommonModule } from '../../collapp-common';
import { UnitSelectComponent } from './unit-select.component';
import { NgSelectMaterialModule } from '../ng-select-mat';
import { AddAdditionalSupplierButtonModule } from '../dialogs/add-additional-supplier-button/add-additional-supplier-button.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CollappCommonModule,
    NgSelectMaterialModule,
    AddAdditionalSupplierButtonModule,
  ],
  exports: [
    UnitSelectComponent,
  ],
  declarations: [
    UnitSelectComponent,
  ],
})
export class UnitSelectModule {
}
