import { TimeTrackingUserDto } from '../../interfaces/dtos/time-tracking-user.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { TimeTrackingStatus, timeTrackingStatusCodeName } from '../../../models/time-tracking-status.enum';
import { TimeTrackingEntryViewDtoModel } from './time-tracking-entry-view.dto.model';
import { TimeTrackingRowModel } from '../../../models/time-tracking-row';
import { assertRequiredProperties } from '../../utilities/api.utility';
import moment from 'moment';

export class TimeTrackingUserDtoModel extends TimeTrackingRowModel {
  readonly statusCodeName: string;

  constructor(
    public days: readonly TimeTrackingEntryViewDtoModel[],
    readonly user: UserSlimDtoModel,
    readonly plannedHours: number,
    readonly status: TimeTrackingStatus,
    readonly submittedOn: moment.Moment | null = null,
  ) {
    super(days);

    this.statusCodeName = timeTrackingStatusCodeName[status];
  }

  static fromJSON(json: TimeTrackingUserDto): TimeTrackingUserDtoModel {
    assertRequiredProperties(json, [
      'days',
      'user',
      'status',
    ]);

    return new TimeTrackingUserDtoModel(
      (Array.isArray(json.days) ? json.days : [])
        .map((item) => TimeTrackingEntryViewDtoModel.fromJSON(item)),
      UserSlimDtoModel.fromJSON(json.user),
      json.plannedHours,
      json.status,
      json.submittedOn ? moment(json.submittedOn).parseZone() : null,
    );
  }

  toJSON(): TimeTrackingUserDto {
    return {
      days: this.days.map((item) => item.toJSON()),
      user: this.user.toJSON(),
      plannedHours: this.plannedHours,
      status: this.status,
      submittedOn: this.submittedOn ? this.submittedOn.toJSON() : null,
    };
  }
}
