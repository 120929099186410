import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { AttachmentListStateModel } from './attachment-list-state.model';
import {
  InitAttachmentListSettings,
  ResetAttachmentListSettings,
  SaveAttachmentListOptions,
  SaveAttachmentListSettings,
  SetAttachmentListSettings,
} from './attachment-list.actions';
import { attachmentsListColumnsInfo } from '../../../api/meta-info/attachments-list.info';
import { UserService } from '../../../api/services/user.service';
import { USER_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultAttachmentListState: AttachmentListStateModel = {
  selectedColumns: Array.from(attachmentsListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: USER_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<AttachmentListStateModel>({
  name: 'attachmentList',
  defaults: defaultAttachmentListState,
})
@Injectable({
  providedIn: 'root',
})
export class AttachmentListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SaveAttachmentListOptions)
  setAttachmentListOptions$(
    ctx: StateContext<AttachmentListStateModel>,
    { options }: SaveAttachmentListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, attachmentsListColumnsInfo));

    return ctx.dispatch(new SaveAttachmentListSettings(ctx.getState()));
  }

  @Action(InitAttachmentListSettings)
  initAttachmentListSettings(
    ctx: StateContext<AttachmentListStateModel>,
    { settings }: InitAttachmentListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, attachmentsListColumnsInfo));
  }

  @Action(SetAttachmentListSettings)
  setAttachmentListSettings(
    ctx: StateContext<AttachmentListStateModel>,
    { settings }: SetAttachmentListSettings,
  ): void {
    const newSettings = {
      ...settings,
      // @TODO AttachmentList has no selectable columns. This here prevents issues with
      // messed up user settings from previous "bad" saves
      selectedColumns: defaultAttachmentListState.selectedColumns,
    };
    ctx.patchState(this.cloneListSettings(newSettings, attachmentsListColumnsInfo));
  }

  @Action(ResetAttachmentListSettings)
  resetAttachmentListSettings(ctx: StateContext<AttachmentListStateModel>): void {
    ctx.setState(defaultAttachmentListState);
  }

  @Action(SaveAttachmentListSettings)
  saveAttachmentListSettings$(
    ctx: StateContext<AttachmentListStateModel>,
    { settings }: SaveAttachmentListSettings,
  ): Observable<void> {
    return this.userService.saveAttachmentSettings$(settings);
  }
}
