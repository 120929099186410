import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserPlanningSidepanelIndexStateModel } from './user-planning-sidepanel-index-state.model';
import { UserService } from '../../../api/services/user.service';
import {
  InitUserPlanningSidepanelIndex, ResetUserPlanningSidepanelIndex, SetUserPlanningSidepanelIndex,
} from './user-planning-sidepanel-index.actions';

const defaultState: UserPlanningSidepanelIndexStateModel = { index: 0 };

@State<UserPlanningSidepanelIndexStateModel>({
  name: 'UserPlanningSidePanelIndex',
  defaults: defaultState,
})
@Injectable({
  providedIn: 'root',
})
export class UserPlanningSidepanelIndexState {
  constructor(
    private userService: UserService,
  ) { }

  @Action(InitUserPlanningSidepanelIndex)
  initUserPlanningSidepanelIndex(
    ctx: StateContext<UserPlanningSidepanelIndexStateModel>,
    { settings }: InitUserPlanningSidepanelIndex,
  ): void {
    ctx.setState(
      { index: settings.index },
    );
  }

  @Action(SetUserPlanningSidepanelIndex)
  setUserPlanningSidepanelIndex$(
    ctx: StateContext<UserPlanningSidepanelIndexStateModel>,
    { settings }: SetUserPlanningSidepanelIndex,
  ): Observable<void> {
    ctx.patchState({ index: settings.index });

    return this.userService.saveUserPlanningSidePanelIndex$(ctx.getState());
  }

  @Action(ResetUserPlanningSidepanelIndex)
  resetUserPlanningSidepanelIndex(ctx: StateContext<UserPlanningSidepanelIndexStateModel>): void {
    ctx.setState(defaultState);
  }
}
