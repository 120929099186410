import { Metadata } from '../interfaces/metadata';
import { ChangedProperty } from '../interfaces/changed-property';
import { PaginationInfoModel } from './pagination-info.model';
import { deepCopy } from '../../helpers/object.utility';
import { PaginationInfo } from '../interfaces/pagination-info';
import { ApiModelNew } from './types';
import { Diff } from '../../app.types';

function fieldReducer<T>(obj: any, key: string, value: any): T {
  return { ...obj, [key]: deepCopy(value) };
}

// export type CollappJsonPrimitive = string | number | boolean | null;
// export interface CollappJsonObject { [prop: string]: CollappJsonPrimitive | CollappJsonArray | CollappJsonObject; }
// export interface CollappJsonArray extends Array<CollappJsonPrimitive | CollappJsonArray | CollappJsonObject> {}
// export type CollappJsonValue = CollappJsonPrimitive | CollappJsonObject | CollappJsonArray;

export class MetadataModel<T extends Metadata = Metadata> implements Metadata, ApiModelNew {
  readonly changedProperties: ChangedProperty[];

  readonly fields: Diff<T['fields'], undefined>;

  readonly paginationInfo: PaginationInfoModel;

  constructor(
    protected metadata: T = {} as any,
  ) {
    this.changedProperties = (Array.isArray(this.metadata.changedProperties)
      ? this.metadata.changedProperties as any as ChangedProperty[]
      : []
    );
    this.fields = Object.entries((this.metadata.fields || {}))
      .reduce((obj, value) => fieldReducer(obj, value[0], value[1]), {} as any);
    this.paginationInfo = new PaginationInfoModel(this.metadata.paginationInfo as PaginationInfo);
  }

  get(path: string): any | undefined {
    const parts = path.split('.');
    let value: any | undefined = this.metadata;
    let haystack: any | undefined;
    let key: string;

    for (let i = 0, l = parts.length; i < l && value !== undefined; i += 1) {
      key = parts[i];
      haystack = value;
      value = undefined;

      if (!key || key.trim() === '' || !haystack || typeof haystack !== 'object') {
        // eslint-disable-next-line no-continue
        continue;
      }

      const keys = Object.keys(haystack);

      for (let j = 0, m = keys.length; j < m; j += 1) {
        if (keys[j] === key) {
          value = haystack[key];
          break;
        }
      }
    }

    return value;
  }

  has(path: string): boolean {
    return (this.get(path) !== undefined);
  }

  clone(overrides?: { metadata: T }): MetadataModel<T> {
    return new MetadataModel<T>(
      (overrides && overrides.metadata
        ? overrides.metadata
        : deepCopy(this.metadata)
      ),
    );
  }

  toJSON(): T {
    return deepCopy<T>(this.metadata);
  }
}
