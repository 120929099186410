import { Component, Input } from '@angular/core';
import {
  animate, state, style, transition, trigger,
} from '@angular/animations';

@Component({
  selector: 'collapp-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: [
    './show-more.component.scss',
  ],
  animations: [
    trigger('collapsedExpanded', [
      state('collapsed', style({
        'max-height': 0,
      })),
      state('expanded', style({
        'max-height': 2000,
      })),
      transition('collapsed => expanded', [
        animate('0.5s'),
      ]),
      transition('expanded => collapsed', [
        animate('0.5s'),
      ]),
    ]),
    trigger('pointDownOrUp', [
      state('down', style({
        transform: 'rotate(0)',
      })),
      state('up', style({
        transform: 'rotate(180deg)',
      })),
      transition('down => up', [
        animate('0.25s'),
      ]),
      transition('up => down', [
        animate('0.25s'),
      ]),
    ]),
  ],
})
export class ShowMoreComponent {
  @Input() expanded: boolean = false;

  toggleState(): void {
    this.expanded = !this.expanded;
  }
}
