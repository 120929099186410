import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { UnitPlanningTotalsStateModel } from './unit-planning-totals-state.model';
import {
  InitUnitPlanningTotalsSettings,
  ResetUnitPlanningTotalsSettings,
  SaveUnitPlanningTotalsSettings,
  SetUnitPlanningTotalsSettings,
} from './unit-planning-totals.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUnitPlanningTotalsState: UnitPlanningTotalsStateModel = {
  selectedTotals: ['total-capacity', 'free-capacity', 'total', 'total-recorded'],
  selectedCapacityUnit: CapacityUnits.HoursWeek,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UnitPlanningTotalsStateModel>({
  name: 'UnitPlanningTotals',
  defaults: defaultUnitPlanningTotalsState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitPlanningTotalsState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitUnitPlanningTotalsSettings)
  initUnitPlanningTotalsSettings(
    ctx: StateContext<UnitPlanningTotalsStateModel>,
    { settings }: InitUnitPlanningTotalsSettings,
  ): void {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
      selectedCapacityUnit: settings.selectedCapacityUnit,
    });
  }

  @Action(SetUnitPlanningTotalsSettings)
  setUnitPlanningTotalsSettings$(
    ctx: StateContext<UnitPlanningTotalsStateModel>,
    { settings }: SetUnitPlanningTotalsSettings,
  ): Observable<void> {
    ctx.patchState({
      selectedTotals: settings.selectedTotals,
      selectedCapacityUnit: settings.selectedCapacityUnit,
    });

    return ctx.dispatch(new SaveUnitPlanningTotalsSettings(ctx.getState()));
  }

  @Action(ResetUnitPlanningTotalsSettings)
  resetUnitPlanningTotalsSettings(ctx: StateContext<UnitPlanningTotalsStateModel>): void {
    ctx.setState(defaultUnitPlanningTotalsState);
  }

  @Action(SaveUnitPlanningTotalsSettings)
  saveUnitPlanningTotalsSettings$(
    ctx: StateContext<UnitPlanningTotalsStateModel>,
    { settings }: SaveUnitPlanningTotalsSettings,
  ): Observable<void> {
    return this.userService.saveUnitPlanningTotalsSettings$(settings);
  }
}
