import moment from 'moment';
import { ResponsibleUserWorkPackageViewDto } from '../../interfaces/dtos/responsible-user-work-package-view.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { Metadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { PlanningEntryDtoModel } from './planning-entry.dto.model';

export class ResponsibleUserWorkPackageViewDtoModel extends UserSlimDtoModel {
  /**
   * A sum of all reported hours
   */
  readonly reportedTotalHours: number;

  constructor(
    userId: string,
    fullName: string,
    email: string,
    photoUrl: string | null,
    validFrom: moment.Moment | null,
    validUntil: moment.Moment | null,
    readonly reportedHours: number,
    readonly reportedWorkHours: number,
    readonly reportedReworkHours: number,
    readonly reportedQaHours: number,
    readonly plannedHours: number,
    readonly plannedHoursNotIncludedInTotal: number,
    readonly totalPlannedHours: number,
    readonly planningEntries: readonly PlanningEntryDtoModel[],
    readonly isCoordinator: boolean,
    readonly wasUserPlannedInNewVersion: boolean,
    readonly metadata: MetadataModel<Metadata>,
  ) {
    super(
      userId,
      fullName,
      email,
      photoUrl,
      validFrom,
      validUntil,
    );

    this.planningEntries = [...planningEntries];
    this.reportedTotalHours = this.reportedWorkHours + this.reportedReworkHours + this.reportedQaHours;
  }

  static fromJSON(json: ResponsibleUserWorkPackageViewDto): ResponsibleUserWorkPackageViewDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'fullName',
      'email',
      'isCoordinator',
    ]);

    return new ResponsibleUserWorkPackageViewDtoModel(
      json.userId,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.reportedHours,
      json.reportedWorkHours,
      json.reportedReworkHours,
      json.reportedQaHours,
      json.plannedHours,
      json.plannedHoursNotIncludedInTotal,
      json.totalPlannedHours,
      (Array.isArray(json.planningEntries)
        ? json.planningEntries
          .map((item) => PlanningEntryDtoModel.fromJSON(item))
        : []
      ),
      json.isCoordinator,
      json.wasUserPlannedInNewVersion,
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): ResponsibleUserWorkPackageViewDto {
    return Object.assign(super.toJSON(), {
      reportedHours: this.reportedHours,
      reportedWorkHours: this.reportedWorkHours,
      reportedReworkHours: this.reportedReworkHours,
      reportedQaHours: this.reportedQaHours,
      plannedHours: this.plannedHours,
      plannedHoursNotIncludedInTotal: this.plannedHoursNotIncludedInTotal,
      totalPlannedHours: this.totalPlannedHours,
      planningEntries: this.planningEntries.map((item) => item.toJSON()),
      isCoordinator: this.isCoordinator,
      wasUserPlannedInNewVersion: this.wasUserPlannedInNewVersion,
      metadata: this.metadata.toJSON(),
    });
  }
}
