import {
  Injectable, Injector, OnDestroy, Optional, ɵstringify as stringify,
} from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { TOAST_DATA, ToastyService } from '../shared/toasty';
import { UserDtoModel } from '../api/models/dtos/user.dto.model';
import { TimeZoneToastyComponent } from '../components/notifications/time-zone-notification.component';
import { CollappDateAdapter, createMissingDateImplError } from '../collapp-core';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService implements OnDestroy {
  private lastUserId: string = '';

  private notificationReceived: boolean = false;

  private dateAdapter: CollappDateAdapter;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    private injector: Injector,
    private toastyService: ToastyService,
    @Optional() dateAdapter?: CollappDateAdapter,
  ) {
    if (!dateAdapter) {
      throw createMissingDateImplError(stringify(TimeZoneService));
    }
    this.dateAdapter = dateAdapter;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleUserChange(user?: UserDtoModel | null): void {
    if (!user || user.userId !== this.lastUserId) {
      this.notificationReceived = false;
    }

    if (user && !this.notificationReceived) {
      this.checkTimeZone(user);
    }

    if (user) {
      // Seconds to minutes
      const userTimeZoneOffset = Math.floor(user.timezoneOffset / 60);
      this.dateAdapter.utcOffset(userTimeZoneOffset);
      this.lastUserId = user.userId;
    } else {
      this.dateAdapter.utcOffset(0);
      this.lastUserId = '';
    }
  }

  checkTimeZone(user: UserDtoModel): void {
    // Seconds to minutes
    const userTimeZoneOffset = Math.floor(user.timezoneOffset / 60);
    const timeZoneOffset = this.getNonDstTimeZoneOffset() * -1;

    if (userTimeZoneOffset !== timeZoneOffset) {
      const timeZoneLabel = this.getTimeZoneOffsetLabel(user.timezone, user.timezoneOffset);

      const injectionTokens = new WeakMap<any, any>([
        [TOAST_DATA, {
          timeZoneLabel,
        }],
      ]);

      const injector = new PortalInjector(this.injector, injectionTokens);
      const toastyPortal = new ComponentPortal(TimeZoneToastyComponent, undefined, injector);

      this.toastyService.info({
        portal: toastyPortal,
        timeout: 0,
        onClick: () => {
          this.notificationReceived = true;
        },
        // onRemove: () => {
        //   this.notificationReceived = true;
        // },
      });
    }
  }

  getTimeZoneOffsetLabel(timeZone: string, offset: number): string {
    const [region, area]: string[] = timeZone.split('/');

    if (region && area) {
      const offsetHours = Math.floor(Math.abs(offset / 3600));
      const offsetMinutes = Math.floor(Math.abs(offset / 60) % 60);

      return `GMT${offset < 0 ? '-' : '+'}${offsetHours >= 10 ? offsetHours : `0${offsetHours}`}:${(offsetMinutes >= 10 ? offsetMinutes : `0${offsetMinutes}`)} ${area}`;
    }

    return '';
  }

  private getNonDstTimeZoneOffset(): number {
    const date = new Date();

    const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

    return Math.max(jan, jul);
  }
}
