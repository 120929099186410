import { RoleListResponse } from '../../interfaces/responses/role-list.response';
import { RoleListDtoModel } from '../dtos/role-list.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class RoleListResponseModel {
  constructor(
    readonly items: readonly RoleListDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: RoleListResponse): RoleListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new RoleListResponseModel(
      json.items.map((item) => RoleListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  toJSON(): RoleListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
