import { WorkPackageCreateResponse } from '../../interfaces/responses/work-package-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageCreateResponseModel {
  constructor(
    readonly workPackageId: number,
  ) {
  }

  static fromJSON(json: WorkPackageCreateResponse): WorkPackageCreateResponseModel {
    assertRequiredProperties(json, [
      'workPackageId',
    ]);

    return new WorkPackageCreateResponseModel(
      json.workPackageId,
    );
  }

  toJSON(): WorkPackageCreateResponse {
    return {
      workPackageId: this.workPackageId,
    };
  }
}
