import { Selector } from '@ngxs/store';
import { SettingsState } from './settings.state';
import { SettingsStateModel } from './settings-state.model';

export class SettingsSelectors {
  @Selector([SettingsState])
  static test(state: SettingsStateModel): string | null {
    return state.test;
  }
}
