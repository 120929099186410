import { Pipe, PipeTransform } from '@angular/core';
import { CapacityUnits } from '../../modules/administration/non-human-resources/capacity-units';

@Pipe({
  name: 'capacityUnitShort',
})
export class CapacityUnitShortPipe implements PipeTransform {
  transform(capacityUnit: CapacityUnits | null, ..._args: unknown[]): string | null {
    switch (capacityUnit) {
      case CapacityUnits.HoursWeek:
        return 'h';
      case CapacityUnits.DaysWeek:
        return 'days';
      default:
        return capacityUnit?.toString() || null;
    }
  }
}
