import { Injectable, Optional, ɵstringify as stringify } from '@angular/core';
import moment from 'moment';
import { CollappDateAdapter } from '../../collapp-core';

@Injectable({
  providedIn: 'root',
})
export class LocalDateHelperService {
  constructor(
    @Optional() private dateAdapter: CollappDateAdapter,
  ) {
    if (!this.dateAdapter) {
      throw new Error(`${stringify(LocalDateHelperService)}: No provider found for MomentDateAdapter.`);
    }
  }

  /**
   * Checks if the passed date is today
   */
  isToday(date: moment.Moment): boolean {
    return date.isSame(this.dateAdapter.today(), 'day');
  }

  /**
   * Makes the best attempts to return a valid moment date object from the given year and week.
   *  - week is clamped to the closest correct value
   *  - the resulting date is 'start of the iso week' (monday at 00:00) in the users' time zone.
   *  - in case everything fails, the resulting date will be the current ISO week date
   */
  getIsoWeekDate(isoWeekYear: number, isoWeek: number): moment.Moment {
    const parsedIsoWeek = isoWeek < 1 ? 1 : isoWeek;

    let date = moment(`${isoWeekYear}-W${parsedIsoWeek}`, moment.HTML5_FMT.WEEK);
    if (!date.isValid()) {
      // Assume the ISO week was invalid. The most common case is that the isoWeek number was too high.
      // Create a date with last ISO week of the given year.
      date = moment(`${isoWeekYear}-01-01`);
      date
        .isoWeek(date.isoWeeksInYear())
        .startOf('isoWeek');

      if (!date.isValid()) {
        // Safety fallback to the current ISO week date.
        date = moment()
          .startOf('isoWeek');
      }
    }

    return this.dateAdapter.adjustDate(date, false);
  }
}
