import { Selector } from '@ngxs/store';
import { WorkPackageCombinedFilterType } from '../../../modules/dashboard/dashboard-work-packages/filter-types';
import { DashboardState } from './dashboard.state';
import { DashboardStateModel } from './dashboard-state.model';
import { DashboardWorkPackageViewType } from '../../../models/dashboard-work-package-view-type.enum';

export class DashboardSelectors {
  @Selector([DashboardState])
  static workPackagesFilter(state: DashboardStateModel): WorkPackageCombinedFilterType {
    return state.workPackagesFilter;
  }

  @Selector([DashboardState])
  static dashboardWorkPackageFilters(state: DashboardStateModel): DashboardWorkPackageViewType[] {
    return state.dashboardWorkPackageFilters;
  }
}
