import moment from 'moment';
import { SkillFilterDto } from '../../interfaces/dtos/skill-filter.dto';

export class UserAvailableResourcesRequestModel {
  constructor(
    private startDate?: moment.Moment,
    private endDate?: moment.Moment,
    private unitId?: number,
    private filteredProjectId?: number,
    private workPackageId?: number,
    private excludedUserIds?: string[],
    private excludedNhrIds?: string[],
    private fullName?: string,
    private year?: number,
    private week?: number,
    private numberOfWeeks?: number,
    private pageNumber?: number,
    private pageSize?: number,
    private skills?: SkillFilterDto[],
  ) {
  }
}
