import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitLevel } from '../../../models/unit-level.enum';
import { UnitPlanningSlimDto } from '../../interfaces/dtos/unit-planning-slim.dto';

export class UnitPlanningSlimDtoModel {
  constructor(
    readonly name: string,
    readonly level: UnitLevel,
    readonly unitId: number,
    readonly parentUnitId: number | null,
  ) {}

  static fromJSON(json: UnitPlanningSlimDto): UnitPlanningSlimDtoModel {
    assertRequiredProperties(json, [
      'unitId',
    ]);

    return new UnitPlanningSlimDtoModel(
      json.name,
      json.level,
      json.unitId,
      json.parentUnitId,
    );
  }

  clone(): UnitPlanningSlimDtoModel {
    return new UnitPlanningSlimDtoModel(
      this.name,
      this.level,
      this.unitId,
      this.parentUnitId,
    );
  }

  toJSON(): UnitPlanningSlimDto {
    return {
      name: this.name,
      level: this.level,
      unitId: this.unitId,
      parentUnitId: this.parentUnitId,
    };
  }
}
