import { Selector } from '@ngxs/store';
import { WorkPackageListState } from './work-package-list.state';
import { WorkPackageListOptions } from './work-package-list.actions';
import { WorkPackageListStateModel } from './work-package-list-state.model';

export class WorkPackageListSelectors {
  @Selector([WorkPackageListState])
  static selectedColumns(state: WorkPackageListStateModel): WorkPackageListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([WorkPackageListState])
  static options(state: WorkPackageListStateModel): WorkPackageListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: state.columnFilters,
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
