import { UserViewResponse } from '../../interfaces/responses/user-view.response';
import { RoleDtoModel } from '../dtos/role.dto.model';
import { UserUnitAssignmentDtoModel } from '../dtos/user-unit-assignment.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UserViewResponseModel {
  readonly fullName: string;

  constructor(
    readonly userId: string,
    readonly activeDirectoryId: string,
    readonly email: string,
    readonly globalEmployeeId: string,
    readonly localEmployeeId: string | null = null,
    readonly firstName: string = '',
    readonly lastName: string = '',
    readonly photoUrl: string | null = null,
    readonly abbreviation: string = '',
    readonly hitachiId: string | null = null,
    readonly payrollCompany: string | null = null,
    readonly costCenter: string | null = null,
    readonly countryCode: string = '',
    readonly city: string = '',
    readonly divisionCode: string | null = null,
    readonly businessUnitCode: string | null = null,
    readonly productGroupCode: string | null = null,
    readonly role: RoleDtoModel | null = null,
    readonly userUnitAssignments: readonly UserUnitAssignmentDtoModel[] = [],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.userUnitAssignments = [...userUnitAssignments];

    this.fullName = `${this.firstName} ${this.lastName}`.trim();
  }

  static fromJSON(json: UserViewResponse): UserViewResponseModel {
    assertRequiredProperties(json, [
      'userId',
      'activeDirectoryId',
      'email',
      'globalEmployeeId',
      'metadata',
    ]);

    return new UserViewResponseModel(
      json.userId,
      json.activeDirectoryId,
      json.email,
      json.globalEmployeeId,
      json.localEmployeeId,
      json.firstName || '',
      json.lastName || '',
      json.photoUrl,
      json.abbreviation || '',
      json.hitachiId,
      json.payrollCompany,
      json.costCenter,
      json.countryCode || '',
      json.city || '',
      json.divisionCode,
      json.businessUnitCode,
      json.productGroupCode,
      (json.role
        ? RoleDtoModel.fromJSON(json.role)
        : null
      ),
      (Array.isArray(json.userUnitAssignments)
        ? json.userUnitAssignments
          .map((item) => UserUnitAssignmentDtoModel.fromJSON(item))
        : []
      ),
      new MetadataModel(json.metadata || {}),
    );
  }

  /**
   * These fields can be edited only by the admin,
   * see: https://yooapps.jira.com/browse/COLLAPP-503
   */
  canEditAdminFields(): boolean {
    return this.canEditUserDetails();
  }

  canEditUserRole(): boolean {
    return this.metadata.fields.canEditUserRole;
  }

  canEditUserCollAppData(): boolean {
    return this.metadata.fields.canEditUserCollAppData;
  }

  canEditUserDetails(): boolean {
    return this.metadata.fields.canEditUserDetails;
  }

  clone(): UserViewResponseModel {
    return new UserViewResponseModel(
      this.userId,
      this.activeDirectoryId,
      this.email,
      this.globalEmployeeId,
      this.localEmployeeId,
      this.firstName,
      this.lastName,
      this.photoUrl,
      this.abbreviation,
      this.hitachiId,
      this.payrollCompany,
      this.costCenter,
      this.countryCode,
      this.city,
      this.divisionCode,
      this.businessUnitCode,
      this.productGroupCode,
      this.role ? this.role.clone() : null,
      this.userUnitAssignments,
      this.metadata,
    );
  }

  toJSON(): UserViewResponse {
    return {
      userId: this.userId,
      activeDirectoryId: this.activeDirectoryId,
      email: this.email,
      globalEmployeeId: this.globalEmployeeId,
      localEmployeeId: this.localEmployeeId,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      photoUrl: this.photoUrl,
      abbreviation: this.abbreviation,
      hitachiId: this.hitachiId,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      countryCode: this.countryCode,
      city: this.city,
      divisionCode: this.divisionCode,
      businessUnitCode: this.businessUnitCode,
      productGroupCode: this.productGroupCode,
      role: this.role ? this.role.toJSON() : null,
      userUnitAssignments: this.userUnitAssignments.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
