import moment from 'moment';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningWeekTimeTrackingEntryViewDto } from '../../interfaces/dtos/user-planning-week-tracking-entry-view.dto';

export class UserPlanningTimeTrackingEntryViewDtoModel {
  readonly isoWeekDateIdentifier: string;

  readonly weekStartDate: moment.Moment;

  /**
   * Derived from the received Meta Data
   * for easier access
   */
  readonly canUpdatePlannedEntry: boolean;

  constructor(
    public week: number,
    public year: number,
    public plannedHours?: number,
    public planningEntryId?: number,
    public unitBackgroundColorId?: number | null,
    public workingDays?: string | null,
    public note?: string | null,
    public noteCreatedBy?: string | null,
    public noteCreatedOn?: moment.Moment | null,
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.isoWeekDateIdentifier = `${year}-W${week}`;
    this.weekStartDate = moment(`${year}-W${week}`, moment.HTML5_FMT.WEEK)
      .startOf('isoWeek').parseZone();
    this.canUpdatePlannedEntry = (this.metadata.fields && this.metadata.fields.canUpdatePlannedEntry) || false;

    if (this.noteCreatedOn) {
      this.noteCreatedOn = moment(this.noteCreatedOn);
    }
  }

  static fromJSON(json: UserPlanningWeekTimeTrackingEntryViewDto): UserPlanningTimeTrackingEntryViewDtoModel {
    assertRequiredProperties(json, [
      'week',
      'year',
    ]);

    return new UserPlanningTimeTrackingEntryViewDtoModel(
      json.week,
      json.year,
      json.plannedHours,
      json.planningEntryId,
      json.unitBackgroundColorId,
      json.workingDays,
      json.note,
      json.noteCreatedBy,
      (json.noteCreatedOn
        ? moment(json.noteCreatedOn)
          .parseZone()
        : null
      ),
      new MetadataModel(json.metadata || {}),
    );
  }

  clone(): UserPlanningTimeTrackingEntryViewDtoModel {
    return new UserPlanningTimeTrackingEntryViewDtoModel(
      this.week,
      this.year,
      this.plannedHours,
      this.planningEntryId,
      this.unitBackgroundColorId,
      this.workingDays,
      this.note,
      this.noteCreatedBy,
      this.noteCreatedOn?.clone(),
      this.metadata.clone(),
    );
  }

  toJSON(): UserPlanningWeekTimeTrackingEntryViewDto {
    return {
      week: this.week,
      year: this.year,
      planningEntryId: this.planningEntryId,
      unitBackgroundColorId: this.unitBackgroundColorId,
      workingDays: this.workingDays,
      plannedHours: this.plannedHours,
      note: this.note,
      noteCreatedBy: this.noteCreatedBy,
      noteCreatedOn: this.noteCreatedOn?.toJSON(),
      metadata: this.metadata.toJSON(),
    };
  }
}
