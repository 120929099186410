import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';

import { NonHumanResourcePlanningSlimDto } from '../../interfaces/dtos/non-human-resource-planning-slim.dto';

export class NonHumanResourcePlanningSlimDtoModel {
  constructor(
    readonly nonHumanResourceId: string,
    readonly name: string | null,
    readonly capacityUnit: CapacityUnits | null,
  ) {}

  static fromJSON(json: NonHumanResourcePlanningSlimDto): NonHumanResourcePlanningSlimDtoModel {
    return new NonHumanResourcePlanningSlimDtoModel(
      json.nonHumanResourceId,
      json.name,
      Object.values(CapacityUnits).filter((capacityUnit) => capacityUnit === json.capacityUnit)?.[0] || null,
    );
  }

  clone(): NonHumanResourcePlanningSlimDtoModel {
    return new NonHumanResourcePlanningSlimDtoModel(
      this.nonHumanResourceId,
      this.name,
      this.capacityUnit,
    );
  }
}
