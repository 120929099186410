import moment from 'moment';
import { QuestionStatus } from '../../../models/question-status.enum';
import { QuestionDto } from '../../interfaces/dtos/question.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { AttachmentSlimDtoModel } from './attachment-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { QuestionMetadata } from '../../interfaces/metadata';
import { WorkPackageRole } from '../../../models/work-package-role.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionDtoModel {
  constructor(
    readonly questionId: number,
    readonly title: string,
    readonly text: string,
    readonly creator: UserSlimDtoModel,
    readonly status: QuestionStatus,
    readonly createdOn: moment.Moment,
    readonly replyCounter: number = 0,
    readonly attachments: readonly AttachmentSlimDtoModel[] = [],
    readonly creatorWorkPackageRole: WorkPackageRole | null = null,
    readonly metadata: MetadataModel<QuestionMetadata> = new MetadataModel(),
  ) {
    this.createdOn = createdOn.clone();
    this.attachments = [...attachments];
  }

  static fromJSON(json: QuestionDto): QuestionDtoModel {
    assertRequiredProperties(json, [
      'questionId',
      'title',
      'text',
      'status',
      'creator',
    ]);

    return new QuestionDtoModel(
      json.questionId,
      json.title,
      json.text,
      UserSlimDtoModel.fromJSON(json.creator),
      json.status,
      moment(json.createdOn)
        .parseZone(),
      json.replyCounter || 0,
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
      json.creatorWorkPackageRole,
      new MetadataModel(json.metadata || {}),
    );
  }

  canReplyToQuestion(): boolean {
    return this.metadata.fields.canReplyToQuestion;
  }

  canConcludeQuestion(): boolean {
    return this.metadata.fields.canConcludeQuestion;
  }

  canUpdateQuestion(): boolean {
    return this.metadata.fields.canUpdateQuestion;
  }

  canReopenQuestion(): boolean {
    return this.metadata.fields.canReopenQuestion;
  }

  toJSON(): QuestionDto {
    return {
      questionId: this.questionId,
      title: this.title,
      text: this.text,
      creator: this.creator.toJSON(),
      status: this.status,
      createdOn: this.createdOn.toJSON(),
      replyCounter: this.replyCounter,
      attachments: this.attachments.map((item) => item.toJSON()),
      creatorWorkPackageRole: this.creatorWorkPackageRole,
      metadata: this.metadata.toJSON(),
    };
  }
}
