import { Provider } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor } from '@azure/msal-angular';

import { ImpersonationInterceptor } from './impersonation-interceptor';
import { AppInsightsInterceptor } from './app-insights-interceptor';

/**
 * Http interceptor providers in outside-in order
 */
export const httpInterceptorProviders: Provider[] = [
  { provide: HTTP_INTERCEPTORS, useClass: ImpersonationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AppInsightsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
];
