import { TimecardRangeStateModel } from './timecard-range-state.model';

/**
 * Action types for TimecardRangeState
 */
export enum TimecardRangeActionTypes {
  InitTimecardRangeSettings = '[Timecard API] Initialize Timecard Range Settings',
  SetTimecardRangeSettings = '[Timecard API] Set Timecard Range Settings',
  ResetTimecardRangeSettings = '[Timecard API] Reset Timecard Range Settings',
  SaveTimecardRangeSettings = '[Timecard API] Save Timecard Range Settings',
}

export class InitTimecardRangeSettings {
  static readonly type: string = TimecardRangeActionTypes.InitTimecardRangeSettings;

  constructor(public settings: TimecardRangeStateModel) {}
}

export class SetTimecardRangeSettings {
  static readonly type: string = TimecardRangeActionTypes.SetTimecardRangeSettings;

  constructor(public settings: TimecardRangeStateModel) {}
}

export class ResetTimecardRangeSettings {
  static readonly type: string = TimecardRangeActionTypes.ResetTimecardRangeSettings;
}

export class SaveTimecardRangeSettings {
  static readonly type: string = TimecardRangeActionTypes.SaveTimecardRangeSettings;

  constructor(public settings: TimecardRangeStateModel) {}
}
