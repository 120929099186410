import {
  Component,
  ContentChild,
  HostBinding,
  Input, OnChanges, OnInit, Optional,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
  ɵstringify as stringify,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { UserPlanningResourceSlimDtoModel } from '../../api/models/dtos/unit-planning-resource-slim.dto.model';
import { BasicUser } from '../../models/user.interface';
import { UserSelectors } from '../../state/user';
import { environment } from '../../../environments/environment';
import { CollappDateAdapter, createMissingDateImplError } from '../../collapp-core';
import { PersonIconOverlayTemplateDirective } from './person-icon-overlay-template.directive';
import { SUPER_ADMIN_ROLE_NAME } from '../../services/access-control.service';
import { NonHumanResourceSlimDto } from '../../api/interfaces/dtos/non-human-resource-slim.dto';
import { AvailableResourceDtoModel } from '../../api/models/dtos/available-resource.dto.model';

const defaultBackgroundColor: string = '#ababab';

/**
 * A few funny "hats" to spice things up ;)
 */
enum HatType {
  NONE = 'none',
  SANTA = 'santa',
  BUNNY = 'bunny',
}

@Component({
  selector: 'person-icon',
  templateUrl: './person-icon.component.html',
  styleUrls: ['./person-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonIconComponent implements OnInit, OnChanges {
  @HostBinding('class.person-icon')
  readonly personIconClass: boolean = true;

  @HostBinding('class.person-icon--small')
  get personIconSmallClass(): boolean {
    return (this.size === 'small');
  }

  @HostBinding('class.person-icon--tiny')
  get personIconTinyClass(): boolean {
    return (this.size === 'tiny');
  }

  @Input()
  user: BasicUser | AvailableResourceDtoModel | null | undefined;

  @Input()
  unknown: boolean = false;

  @Input()
  size: 'tiny' | 'small' | 'normal' = 'normal';

  @Input()
  badge: string | null = null;

  @Input()
  forceIsPerson: boolean = false;

  @ContentChild(PersonIconOverlayTemplateDirective, { read: TemplateRef })
  overlayTemplate?: TemplateRef<any>;

  avatarUnavailable: boolean = false;

  userImageSrc: string = '';

  initials: string = '';

  backgroundColor: string = defaultBackgroundColor;

  hatTypes: typeof HatType = HatType;

  hat: HatType = HatType.NONE;

  isRect: boolean = false;

  private _userBackgroundColor: string = defaultBackgroundColor;

  private dateAdapter: CollappDateAdapter;

  constructor(
  @Optional() dateAdapter: CollappDateAdapter,
    private store: Store,
  ) {
    if (!dateAdapter) {
      throw createMissingDateImplError(stringify(PersonIconComponent));
    }
    this.dateAdapter = dateAdapter;
  }

  ngOnInit(): void {
    this.updateUserData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && !changes.user.isFirstChange()) {
      this.updateUserData();
    }
  }

  onAvatarError(): void {
    this.avatarUnavailable = true;
    this.backgroundColor = this._userBackgroundColor;
    this.hat = HatType.NONE;
  }

  // eslint-disable-next-line complexity, max-lines-per-function
  private updateUserData(): void {
    let userImageSrc = '';
    let initials = '';
    let backgroundColor = defaultBackgroundColor;
    this.isRect = false;

    if (this.user) {
      if ((this.user as BasicUser).photoUrl) {
        userImageSrc = (this.user as BasicUser).photoUrl!;
      }
      if ((this.user as BasicUser).fullName || (this.user as unknown as NonHumanResourceSlimDto).name) {
        // Get a random but static starting point for the backgroundColor calculation
        // based on the UUID of the user
        const colorCalcModifier = parseInt((this.user as BasicUser)?.userId?.split('-')[0].replace('[^d]', ''), 10) || 1;
        let colorHue = 0;

        // Assume the users' name to be words separated by whitespace...
        const parts = `${(this.user as BasicUser).fullName || (this.user as unknown as NonHumanResourceSlimDto).name}`
          .replace(/\s+/, ' ')
          .trim()
          .toUpperCase()
          .split(' ');

        // ...and get the first unicode character of the first word (assumed first name)
        const char1 = parts[0].codePointAt(0)!;
        // ...and get the first unicode character of the last word (assumed last name)
        const char2 = parts[parts.length - 1].codePointAt(0)!;

        if (parts.length >= 2) {
          initials = String.fromCodePoint(char1) + String.fromCodePoint(char2);
          // Calculate a static color from the initials, assuming uppercase ASCII chars (but also works with unicode)
          // Clipped to 360 since hue in hsl is 0 - 359
          colorHue = Math.round(((1 / (26 * 26)) * (26 * (char1 - 65) + (char2 - 65))) * 360 * colorCalcModifier) % 360;
        } else if (parts.length === 1) {
          initials = String.fromCodePoint(char1);
          colorHue = Math.round(((1 / 26) * (char1 - 65)) * 360 * colorCalcModifier) % 360;
        }

        backgroundColor = `hsl(${colorHue}, 85%, 55%)`;
      }

      if ((!(this.user as AvailableResourceDtoModel).isUser
        && !(this.user as BasicUser).userId)
        && !(this.user as unknown as UserPlanningResourceSlimDtoModel).isHuman
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        && !(this.user as any).isUserType) {
        this.isRect = true;
      }

      if (this.forceIsPerson) {
        this.isRect = false;
      }
    }

    this._userBackgroundColor = backgroundColor;
    if (userImageSrc !== '') {
      this.avatarUnavailable = false;
      // Reset the background color to prevent "color halos"
      backgroundColor = 'transparent';
      if (this.userImageSrc !== userImageSrc) {
        // Only update hats if the user image did change
        this.updateHats((this.user as BasicUser));
      }
      this.userImageSrc = userImageSrc;
    } else {
      this.avatarUnavailable = true;
    }
    this.initials = initials;
    this.backgroundColor = backgroundColor;
  }

  // eslint-disable-next-line complexity
  private updateHats(user: BasicUser | AvailableResourceDtoModel | null | undefined = this.user): void {
    // Hats are only for people!!! :rage:
    if (!user) {
      this.hat = HatType.NONE;

      return;
    }

    if (environment.production) {
      // Temporarily disable for production (dev, stage, prod)
      // so that ABB does not get a heart attack
      return;
    }

    const yooUsers = [
      'Andra Szasz',
      'Andrei Patrutiu',
      'Benedikt Funk',
      'Christoph Dörfel',
      'Cédric Madörin',
      'Dimitri Vranken',
      'Dragan Boric',
      'Emanuel Marsicovetere',
      'Florian Wagner',
      'Kelvin Louis',
      'Marcel Grieder',
      'Nathalie Madörin',
      'Sacha Schmid',
      'Yanick Recher',
      'Sébastien Closs',
    ];

    const currentUser = this.store.selectSnapshot(UserSelectors.user)!;
    if (!yooUsers.includes(currentUser.fullName)) {
      return;
    }

    const today = this.dateAdapter.today();

    if (
      (currentUser.role && currentUser.role.name === SUPER_ADMIN_ROLE_NAME)
      && today.month() === 11
      && today.date() <= 25
    ) {
      this.hat = HatType.SANTA;
    } else if (
      Math.random() > 0.8
      && yooUsers.includes(user.fullName || '')
    ) {
      this.hat = HatType.BUNNY;
    } else {
      this.hat = HatType.NONE;
    }
  }
}
