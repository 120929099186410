import { TimeTrackingUnitUsersDto } from '../../interfaces/dtos/time-tracking-unit-users.dto';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { TimeTrackingUserDtoModel } from './time-tracking-user.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingUnitUsersDtoModel {
  constructor(
    readonly unit: UnitSlimDtoModel,
    readonly timeTrackingUsers: readonly TimeTrackingUserDtoModel[],
    readonly isCrossUtilized: boolean,
  ) {
    this.timeTrackingUsers = [...timeTrackingUsers];
  }

  static fromJSON(json: TimeTrackingUnitUsersDto): TimeTrackingUnitUsersDtoModel {
    assertRequiredProperties(json, [
      'unit',
      'timeTrackingUsers',
    ]);

    return new TimeTrackingUnitUsersDtoModel(
      UnitSlimDtoModel.fromJSON(json.unit),
      (Array.isArray(json.timeTrackingUsers) ? json.timeTrackingUsers : [])
        .map((item) => TimeTrackingUserDtoModel.fromJSON(item)),
      json.isCrossUtilized,
    );
  }

  toJSON(): TimeTrackingUnitUsersDto {
    return {
      unit: this.unit.toJSON(),
      timeTrackingUsers: this.timeTrackingUsers.map((item) => item.toJSON()),
      isCrossUtilized: this.isCrossUtilized,
    };
  }
}
