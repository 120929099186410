import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UserResourceListStateModel } from './user-resource-list-state.model';
import {
  InitUserResourceListSettings,
  ResetUserResourceListSettings,
  SaveUserResourceListSettings,
  SetUserResourceListOptions,
  SetUserResourceListSelectedColumns,
  SetUserResourceListSettings,
} from './user-resource-list.actions';
import { UserService } from '../../../api/services/user.service';
import { USER_RESOURCE_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';
import { userResourceListColumnsInfo } from '../../../api/meta-info/user-resources-list.info';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUserResourceListState: UserResourceListStateModel = {
  selectedColumns: Array.from(userResourceListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: USER_RESOURCE_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UserResourceListStateModel>({
  name: 'userResourceList',
  defaults: defaultUserResourceListState,
})
@Injectable({
  providedIn: 'root',
})
export class UserResourceListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetUserResourceListSelectedColumns)
  setUserResourceListSelectedColumns$(
    ctx: StateContext<UserResourceListStateModel>,
    { columns }: SetUserResourceListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, userResourceListColumnsInfo));

    return ctx.dispatch(new SaveUserResourceListSettings(ctx.getState()));
  }

  @Action(SetUserResourceListOptions)
  setUserResourceListOptions$(
    ctx: StateContext<UserResourceListStateModel>,
    { options }: SetUserResourceListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, userResourceListColumnsInfo));

    return ctx.dispatch(new SaveUserResourceListSettings(ctx.getState()));
  }

  @Action(InitUserResourceListSettings)
  initUserResourceListSettings(
    ctx: StateContext<UserResourceListStateModel>,
    { settings }: InitUserResourceListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, userResourceListColumnsInfo));
  }

  @Action(SetUserResourceListSettings)
  setUserResourceListSettings$(
    ctx: StateContext<UserResourceListStateModel>,
    { settings }: SetUserResourceListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, userResourceListColumnsInfo));

    return ctx.dispatch(new SaveUserResourceListSettings(ctx.getState()));
  }

  @Action(ResetUserResourceListSettings)
  resetUserResourceListSettings(ctx: StateContext<UserResourceListStateModel>): void {
    ctx.setState(defaultUserResourceListState);
  }

  @Action(SaveUserResourceListSettings)
  saveUserResourceListSettings$(
    ctx: StateContext<UserResourceListStateModel>,
    { settings }: SaveUserResourceListSettings,
  ): Observable<void> {
    return this.userService.SaveUserResourceListSettings$(settings);
  }
}
