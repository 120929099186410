import { UserPlanningTimeTrackingEntryViewDtoModel } from '../api/models/dtos/user-planning-week-tracking-entry-view.dto.model';

export abstract class UserPlanningTimeTrackingRowModel {
  /**
   * Total hours of the entire row.
   */
  readonly totalHours: number;

  constructor(
    readonly weeks: readonly UserPlanningTimeTrackingEntryViewDtoModel[],
  ) {
    this.weeks = [...weeks];

    this.totalHours = this.weeks
      .reduce((prev, week) => (week.plannedHours == null ? 0 : week.plannedHours) + prev, 0);
  }
}
