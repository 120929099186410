import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserPlanningTimeTrackingEntryViewDtoModel } from './user-planning-week-tracking-entry-view.dto.model';
import { UserPlanningTimeTrackingWorkPackageDto } from '../../interfaces/dtos/user-planning-time-tracking-work-package.dto';

export class UserPlanningTimeTrackingWorkPackageDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly sequenceNumber: number,
    readonly title: string,
    readonly projectNumber: string | null,
    readonly projectTitle: string,
    readonly startWeek: number,
    readonly endWeek: number,
    readonly weeks: UserPlanningTimeTrackingEntryViewDtoModel[],
    readonly isCrossUtilized: boolean = false,
    readonly snapshotWorkPackageTotalHours: number,
    readonly totalPlanned: number | null = null,
    readonly totalRecorded: number | null = null,
    readonly totalAvailableToBePlanned: number | null = null,
    readonly totalConfirmed: number | null = null,
    readonly projectId: number | null = null,
  ) {
    this.weeks = [...weeks];
  }

  static fromJSON(json: UserPlanningTimeTrackingWorkPackageDto): UserPlanningTimeTrackingWorkPackageDtoModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'sequenceNumber',
      'title',
      'startWeek',
      'endWeek',
      'weeks',
    ]);

    return new UserPlanningTimeTrackingWorkPackageDtoModel(
      json.workPackageId,
      json.sequenceNumber,
      json.title,
      json.projectNumber,
      json.projectTitle,
      json.startWeek,
      json.endWeek,
      (Array.isArray(json.weeks) ? json.weeks : [])
        .map((item) => UserPlanningTimeTrackingEntryViewDtoModel.fromJSON(item)),
      json.isCrossUtilized,
      json.snapshotWorkPackageTotalHours,
      json.totalPlanned,
      json.totalRecorded,
      json.totalAvailableToBePlanned,
      json.totalConfirmed,
      json.projectId,
    );
  }

  clone(): UserPlanningTimeTrackingWorkPackageDtoModel {
    return new UserPlanningTimeTrackingWorkPackageDtoModel(
      this.workPackageId,
      this.sequenceNumber,
      this.title,
      this.projectNumber,
      this.projectTitle,
      this.startWeek,
      this.endWeek,
      this.weeks.map((item) => item.clone()),
      this.isCrossUtilized,
      this.snapshotWorkPackageTotalHours,
      this.totalPlanned,
      this.totalRecorded,
      this.totalAvailableToBePlanned,
      this.totalConfirmed,
      this.projectId,
    );
  }

  toJSON(): UserPlanningTimeTrackingWorkPackageDto {
    return {
      workPackageId: this.workPackageId,
      sequenceNumber: this.sequenceNumber,
      title: this.title,
      projectNumber: this.projectNumber,
      projectTitle: this.projectTitle,
      startWeek: this.startWeek,
      endWeek: this.endWeek,
      weeks: this.weeks.map((item) => item.toJSON()),
      isCrossUtilized: this.isCrossUtilized,
      snapshotWorkPackageTotalHours: this.snapshotWorkPackageTotalHours,
      totalPlanned: this.totalPlanned,
      totalRecorded: this.totalRecorded,
      totalAvailableToBePlanned: this.totalAvailableToBePlanned,
      totalConfirmed: this.totalConfirmed,
      projectId: this.projectId,
    };
  }
}
