import { BasicWorkPackageViewListItemResponse } from '../../interfaces/responses/basic-work-package-view-list-item.response';

export class BasicWorkPackageViewListItemResponseModel {
  constructor(
    readonly workPackageId: number | null = null,
    readonly workPackageTitle: string | null = null,
  ) {}

  static fromJSON(json: BasicWorkPackageViewListItemResponse): BasicWorkPackageViewListItemResponseModel {
    return new BasicWorkPackageViewListItemResponseModel(
      json.workPackageId,
      json.workPackageTitle,
    );
  }
}
