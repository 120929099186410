import { Selector } from '@ngxs/store';
import { WorkPackagePlanningTotalsStateModel } from './work-package-planning-totals-state.model';
import { WorkPackagePlanningTotalsState } from './work-package-planning-totals.state';

export class WorkPackagePlanningTotalsSelectors {
  @Selector([WorkPackagePlanningTotalsState])
  static selectedTotals(state: WorkPackagePlanningTotalsStateModel): WorkPackagePlanningTotalsStateModel['selectedTotals'] {
    return state.selectedTotals;
  }
}
