import { ResourceLinkDto } from '../../interfaces/dtos/resource-link.dto';

export class ResourceLinkDtoModel {
  constructor(
    readonly linkCode: string | null,
    readonly url: string | null,
  ) {}

  static fromJSON(json: ResourceLinkDto): ResourceLinkDtoModel {
    return new ResourceLinkDtoModel(
      json.linkCode,
      json.url,
    );
  }

  clone(): ResourceLinkDtoModel {
    return new ResourceLinkDtoModel(
      this.linkCode,
      this.url,
    );
  }
}
