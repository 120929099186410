<div class="edit-unit-dialog">
  <header class="collapp-dialog__header">
    @if (unit) {
      <h2 class="collapp-dialog__title">Edit Unit - {{ name?.value }} ({{ code?.value }})</h2>
    } @else {
      <h2 class="collapp-dialog__title">Create Unit {{ name?.value ? ' - ' + name?.value + ' ': '' }} {{ code?.value ? ' (' + code?.value + ')': '' }}</h2>
    }

    <button type="button" class="collapp-dialog__close" (click)="close()" [disabled]="saving">
      <span class="ic-close"></span>
    </button>
  </header>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <collapp-form-section [label]="'Meta Data'">
        <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
          <mat-form-field floatLabel="always" fxFlex>
            <mat-label>Unit Code</mat-label>
            <input autocomplete="off" matInput required [formControlName]="'code'" />
            <mat-error></mat-error>
          </mat-form-field>
          <mat-form-field floatLabel="always" fxFlex>
            <mat-label>Unit Name</mat-label>
            <input autocomplete="off" matInput required [formControlName]="'name'" />
          </mat-form-field>
          <mat-form-field floatLabel="always" fxFlex>
            <mat-label>Unit Coordinator</mat-label>
            <collapp-people-select [formControlName]="'unitCoordinator'" [required]="true"></collapp-people-select>
          </mat-form-field>
        </div>
        <div class="glob_display-flex">
          <div class="glob_dialog-form-left65 glob_dialog-form-padding-right30 edit-unit-dialog__belongs-to">
            <mat-form-field floatLabel="always" style="width: 100%">
              <mat-label>Belongs to</mat-label>
              <collapp-unit-select [formControlName]="'parentUnit'" [disabledItems]="disabledUnitItems" [fetchType]="fetchTypes.AllL1ToL4Units" [limit]="20" [initFetch]="false"></collapp-unit-select>
              @if (parentUnit.value) {
                <mat-hint> The unit will be on level {{ (parentUnit.value | collAppUnitLevel) + 1 }} </mat-hint>
              }
            </mat-form-field>
          </div>
          <div class="edit-unit-dialog__unit-coordinator">
            <mat-form-field floatLabel="always" style="width: 100%">
              <mat-label>Deputy Unit Coordinator(s)</mat-label>
              <collapp-people-select [formControlName]="'deputyUnitCoordinators'" [multiple]="true"></collapp-people-select>
            </mat-form-field>
          </div>
        </div>
        @if (isLevel3) {
          <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
            <mat-form-field floatLabel="always" fxFlex>
              <mat-label>Payroll Company</mat-label>
              <ng-select
                ngSelectMat
                [items]="payrollCompanies().sort()"
                [formControl]="payroleCompany"
                [required]="true"
                [appendTo]="'body'"
                [loading]="isLoadingPayrollCompanies()"
              >
              </ng-select>
              <mat-error></mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="always" fxFlex>
              <mat-label>Profit Center</mat-label>
              <ng-select
                ngSelectMat
                [items]="profitCenters()"
                [formControl]="profitCenter"
                [required]="true"
                [appendTo]="'body'"
                [loading]="isLoadingProfitCenters()"
              >
              </ng-select>
              <mat-error></mat-error>
            </mat-form-field>
          </div>
        }
      </collapp-form-section>
      @if (isLevel3OrBelow) {
        <collapp-form-section [label]="'Settings'">
          @if (unit?.metadata.fields.displayTimecardApprovalOption) {
            <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
              <mat-slide-toggle [formControl]="directUserSubmissionOfTimecard" class="edit-unit-dialog__etime-toggle"> Direct user submission of Timecard </mat-slide-toggle>
            </div>
          }
          <mat-tab-group class="collapp-dialog__tabs">
            @if (isLevel3) {
              <mat-tab label="Project Auto Import Structure Preset" bodyClass="">
                <div fxLayout="column" fxLayoutGap="30px" fxFlexFill>
                  <div>
                    <div>
                      <mat-form-field floatLabel="always" fxFlex>
                        <mat-label>Hidden Levels:</mat-label>
                        <textarea matInput [required]="true" [formControlName]="'hiddenLevels'" class="edit-unit-dialog__hidden-levels"> </textarea>
                        <mat-hint> List of levels to be hidden from the project auto import structure, separated by semicolon (;) </mat-hint>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </mat-tab>
            }

            @if (isLevel4) {
              <mat-tab label="Contracts">
                <collapp-unit-contract-list-control
                  [parentUnitId]="unit?.parentUnit?.unitId || parentUnit.value?.unitId"
                  [selectedUnitId]="parentUnit.value?.unitId"
                  [unitCoordinatorId]="unitCoordinator?.value?.userId"
                  [submitterUnit]="unit"
                  [formControl]="submitterContracts"
                  (contractRemoved)="onSubmitterContractRemoved($event)"
                ></collapp-unit-contract-list-control>
              </mat-tab>
              <mat-tab label="Cost center" bodyClass="">
                @if (unit?.parentUnit?.unitId || parentUnit.value?.unitId) {
                  <cost-center-list
                    [initialCostCenters]="unit?.unitCostCenters || []"
                    [parentUnitId]="unit?.parentUnit?.unitId || parentUnit.value?.unitId"
                    [costCenterService]="costCenterService"
                  ></cost-center-list>
                  @if (costCenterService.showErrors()) {
                    <mat-error>
                      {{ costCenterService.errors().join(' and ') }}
                    </mat-error>
                  }
                }
              </mat-tab>
            }
            @if (isLevel3OrBelow && unit?.migratedToEtime !== true) {
              <mat-tab label="Non-Productive Categories">
                @if (isLoadingDefaultNonProductiveCategories) {
                  <collapp-loading-spinner></collapp-loading-spinner>
                }
                <collapp-non-productive-categories-control [checkboxRemoveCategoriesMode]="!isFormInEditMode" [formControl]="nonProductiveCategories"></collapp-non-productive-categories-control>
              </mat-tab>
            }
            <mat-tab label="Defect List">
              @if (isLoadingDefectList) {
                <collapp-loading-spinner></collapp-loading-spinner>
              }
              <collapp-unit-defect-list-control [formControl]="defects"></collapp-unit-defect-list-control>
            </mat-tab>
            @if (canSeeTimesheetExportTemplate) {
              <mat-tab label="Timesheet Export Settings">
                <ng-template mat-tab-label>
                  @if (hasTimesheetExportTemplateFormErrors) {
                    <div class="collapp-mat-tab-badge collapp-mat-tab-badge--error" [matTooltip]="'This form contains errors'">
                      <mat-icon>error_outline</mat-icon>
                    </div>
                  }
                  Timesheet Export Settings
                </ng-template>
                @if (isLoadingTimesheetExportTemplate) {
                  <collapp-loading-spinner></collapp-loading-spinner>
                } @else {
                  <collapp-form-section class="edit-unit-dialog__export-settings" [label]="'Timesheet Export Template Settings'">
                    <div fxLayout="row" fxLayoutGap="150px" fxFlexFill>
                      <mat-form-field floatLabel="always" fxFlex>
                        <mat-label>Template Columns:</mat-label>
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [required]="areTimesheetExportTemplateColumnsRequired"
                          [formControlName]="'timesheetExportTemplateColumns'"
                          class="collapp-dialog__textarea"
                        >
                        </textarea>
                        <mat-hint> List of columns to be exported, separated by semicolon (;) </mat-hint>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
                      <mat-form-field floatLabel="always" fxFlex>
                        <mat-label>Highlighted Columns:</mat-label>
                        <textarea matInput cdkTextareaAutosize [formControlName]="'timesheetExportTemplateHighlighting'" class="collapp-dialog__textarea"></textarea>
                        <mat-hint> List of booleans (TRUE or FALSE) defining which columns to highlight, separated by semicolon (;) </mat-hint>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="150px" fxFlexFill>
                      <mat-form-field floatLabel="always" fxFlex>
                        <mat-label>Non Productive Template Columns:</mat-label>
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          [required]="areTimesheetExportNonProductiveTemplateColumnsRequired"
                          [formControlName]="'timesheetExportNonProductiveTemplateColumns'"
                          class="collapp-dialog__textarea"
                        ></textarea>
                        <mat-hint> List of columns for non productive categories to be exported, separated by semicolon (;) </mat-hint>
                      </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="30px" fxFlexFill>
                      <mat-form-field floatLabel="always" fxFlex>
                        <mat-label>Highlighted Non Productive Columns:</mat-label>
                        <textarea matInput cdkTextareaAutosize [formControlName]="'timesheetExportNonProductiveTemplateHighlighting'" class="collapp-dialog__textarea"></textarea>
                        <mat-hint> List of booleans (TRUE or FALSE) defining which columns to highlight, separated by semicolon (;) </mat-hint>
                      </mat-form-field>
                    </div>
                  </collapp-form-section>
                  @if (isLevel4OrBelow) {
                    <collapp-alert class="collapp-dialog__alert" [icon]="'info'" [type]="'info'">
                      Timesheet export templates are not configurable. The configuration is inherited from the level 3 unit.
                    </collapp-alert>
                  }
                }
              </mat-tab>
            }
          </mat-tab-group>
        </collapp-form-section>
      }
    </mat-dialog-content>
    <footer class="collapp-dialog__footer">
      @if (unit) {
        <button type="button" collapp-stroked-button [disabled]="!unit?.canDisableUnit" (click)="disableUnit()">Disable unit</button>
      }
      <span class="flex-spacer"></span>
      <button type="button" collapp-stroked-button [disabled]="saving || unit?.isDisabled" (click)="close()">
        @if (!form.dirty) {
          Close
        }
        @if (form.dirty) {
          Cancel
        }
      </button>
      <button type="submit" collapp-flat-button color="primary" [disabled]="!form.dirty || !isSubmitButtonEnabled || unit?.isDisabled" [loading]="saving">Save</button>
    </footer>
  </form>
</div>
