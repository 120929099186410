import {
  ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import moment from 'moment';

@Component({
  selector: 'dates-range',
  templateUrl: './dates-range.component.html',
  styleUrls: ['./dates-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatesRangeComponent {
  @HostBinding('class.dates-range')
  hostClass: boolean = true;

  @Input()
  startDate: moment.Moment | null = null;

  @Input()
  endDate: moment.Moment | null = null;

  @Input()
  endDateSuffix: string | null = null;

  get hasDateRangeError(): boolean { return this._hasDateRangeError; }

  @Input()
  @HostBinding('class.dates-range--has-date-range-error')
  set hasDateRangeError(value: boolean) {
    this._hasDateRangeError = coerceBooleanProperty(value);
  }

  get hasEndDateError(): boolean { return this._hasEndDateError; }

  @Input()
  @HostBinding('class.dates-range--has-end-date-error')
  set hasEndDateError(value: boolean) {
    this._hasEndDateError = coerceBooleanProperty(value);
  }

  private _hasDateRangeError: boolean = false;

  private _hasEndDateError: boolean = false;
}
