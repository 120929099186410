import {
  ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation,
} from '@angular/core';
import moment from 'moment';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'collapp-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollappDateComponent {
  @HostBinding('class.collapp-date')
  hostClass: boolean = true;

  @HostBinding('class.collapp-date--exceeded')
  get exceededClassName(): boolean {
    return this._exceeded;
  }

  @HostBinding('class.collapp-date--overdue')
  get overdueClassName(): boolean {
    return this._overdue;
  }

  @Input()
  date: moment.Moment | null = null;

  @Input()
  suffix: moment.Moment | null = null;

  @Input()
  set exceeded(originalState: boolean) {
    this._exceeded = coerceBooleanProperty(originalState);
  }

  get exceeded(): boolean {
    return this._exceeded;
  }

  @Input()
  set overdue(originalState: boolean) {
    this._overdue = coerceBooleanProperty(originalState);
  }

  get overdue(): boolean {
    return this._overdue;
  }

  private _exceeded: boolean = false;

  private _overdue: boolean = false;
}
