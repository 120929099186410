import { UserBaseSlimDto } from '../../interfaces/dtos/user-base-slim.dto';

export class UserBaseSlimDtoModel {
  constructor(
    readonly userId: string,
    readonly fullName: string,
    readonly photoUrl: string | null,
  ) {}

  static fromJSON(json: UserBaseSlimDto): UserBaseSlimDtoModel {
    return new UserBaseSlimDtoModel(
      json.userId,
      json.fullName,
      json.photoUrl,
    );
  }

  clone(): UserBaseSlimDtoModel {
    return new UserBaseSlimDtoModel(
      this.userId,
      this.fullName,
      this.photoUrl,
    );
  }

  toJSON(): UserBaseSlimDto {
    return {
      userId: this.userId,
      fullName: this.fullName,
      photoUrl: this.photoUrl,
    };
  }
}
