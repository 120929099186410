import { TimeTrackingWorkPackageDto } from '../../interfaces/dtos/time-tracking-work-package.dto';
import { TimeTrackingRowDtoModel } from './time-tracking-row.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeTrackingWorkPackageDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly sequenceNumber: number,
    readonly title: string,
    readonly projectNumber: string | null,
    readonly projectTitle: string,
    readonly projectId: number,
    readonly rows: TimeTrackingRowDtoModel[],
    readonly hasWarningSign: boolean,
    readonly warningMessage: string | null,
  ) {
    this.rows = rows.map((d) => d.clone());
  }

  static fromJSON(json: TimeTrackingWorkPackageDto): TimeTrackingWorkPackageDtoModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'sequenceNumber',
      'title',
      'rows',
    ]);

    return new TimeTrackingWorkPackageDtoModel(
      json.workPackageId,
      json.sequenceNumber,
      json.title,
      json.projectNumber,
      json.projectTitle,
      json.projectId,
      (Array.isArray(json.rows) ? json.rows : [])
        .map((item) => TimeTrackingRowDtoModel.fromJSON(item)),
      json.hasWarningSign,
      json.warningMessage,
    );
  }

  toJSON(): TimeTrackingWorkPackageDto {
    return {
      workPackageId: this.workPackageId,
      sequenceNumber: this.sequenceNumber,
      title: this.title,
      projectNumber: this.projectNumber,
      projectTitle: this.projectTitle,
      projectId: this.projectId,
      rows: this.rows.map((item) => item.toJSON()),
      hasWarningSign: this.hasWarningSign,
      warningMessage: this.warningMessage,
    };
  }

  clone(): TimeTrackingWorkPackageDtoModel {
    return new TimeTrackingWorkPackageDtoModel(
      this.workPackageId,
      this.sequenceNumber,
      this.title,
      this.projectNumber,
      this.projectTitle,
      this.projectId,
      this.rows,
      this.hasWarningSign,
      this.warningMessage,
    );
  }
}
