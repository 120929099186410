import moment from 'moment';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CapacityDto } from '../../interfaces/dtos/capacity.dto';

export class CapacityDtoModel {
  readonly isoWeekDateIdentifier: string;

  constructor(
    readonly userId: string,
    readonly year: number,
    readonly week: number,
    readonly totalHours: number,
    public plannedHours: number,
    public availableHours: number,
    readonly weekStartDate: moment.Moment | null,
    readonly availability: number,
    public hasHoliday: boolean,
    public holidayDescription: string | null = null,
    public holidayHours: number,
    public totalProductiveHours: number,
    public totalNonProductiveHours: number,
  ) {
    this.isoWeekDateIdentifier = `${year}-W${week}`;
  }

  static fromJSON(json: CapacityDto): CapacityDtoModel {
    assertRequiredProperties(json, [
      'week',
      'totalHours',
      'plannedHours',
      'availableHours',
    ]);

    return new CapacityDtoModel(
      json.userId,
      json.year,
      json.week,
      json.totalHours,
      json.plannedHours,
      json.availableHours,
      (json.weekStartDate
        ? moment(json.weekStartDate)
          .parseZone()
        : null
      ),
      CapacityDtoModel.getAvailability(json.plannedHours, json.availableHours),
      json.hasHoliday,
      json.holidayDescription,
      json.holidayHours,
      json.totalProductiveHours,
      json.totalNonProductiveHours,
    );
  }

  static getAvailability(plannedHours: number, availableHours: number): number {
    return (plannedHours / availableHours) * 100;
  }

  clone(): CapacityDtoModel {
    return new CapacityDtoModel(
      this.userId,
      this.year,
      this.week,
      this.totalHours,
      this.plannedHours,
      this.availableHours,
      this.weekStartDate != null ? this.weekStartDate.clone() : null,
      this.availability,
      this.hasHoliday,
      this.holidayDescription,
      this.holidayHours,
      this.totalProductiveHours,
      this.totalNonProductiveHours,
    );
  }

  toJSON(): CapacityDto {
    return {
      userId: this.userId,
      year: this.year,
      week: this.week,
      totalHours: this.totalHours,
      plannedHours: this.plannedHours,
      availableHours: this.availableHours,
      weekStartDate: (this.weekStartDate != null ? this.weekStartDate.toJSON() : null),
      hasHoliday: this.hasHoliday,
      holidayDescription: this.holidayDescription,
      holidayHours: this.holidayHours,
      totalProductiveHours: this.totalProductiveHours,
      totalNonProductiveHours: this.totalNonProductiveHours,
    };
  }
}
