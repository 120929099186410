<mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl">
  <mat-tree-node [ngClass]="{'project-structure-node': true, 'project-structure-node--is-disabled': node.isDisabled}" *matTreeNodeDef="let node">
    <button type="button" mat-icon-button disabled></button>
    <div class="project-structure-node__title">
      <mat-checkbox [checked]="node.isSelected"
        (change)="projectStructureItemSelectionToggle(node)"
        [labelPosition]="labelPosition"
        [disabled]="disabled || node.isDisabled">
        <span class="project-structure-node__title-text"
          [matTooltip]="getOldValueTooltipFor('title', node)">
          {{ node.codePath }}: {{ node.title }}
        </span>
      </mat-checkbox>
    </div>
    @if (node.responsibleEngineer || node.changes.responsibleEngineer) {
      <div class="project-structure-node__responsible-engineer"
      [matTooltip]="getOldValueTooltipFor('responsibleEngineer', node)">{{node.responsibleEngineer | collAppUser}}</div>
    }
    @if (!node.responsibleEngineer && !node.changes.responsibleEngineer) {
      <div class="project-structure-node__responsible-engineer"
      [matTooltip]="getOldValueTooltipFor('responsibleEngineer', node)">n/a</div>
    }
    @if (node.startDate || node.endDate) {
      <div class="project-structure-node__dates">
        <span class="project-structure-node__start-date"
          [matTooltip]="(getOldValueFor('startDate', node) ? 'Previous value: ' + (getOldValueFor('startDate', node) | toMoment | moment) : null)">{{node.startDate | toMoment | moment}}</span
          >@if (node.endDate) {
          <span class="project-structure-node__dates-separator">-</span
            >
            }@if (node.endDate) {
            <span class="project-structure-node__end-date"
            [matTooltip]="(getOldValueFor('endDate', node) ? 'Previous value: ' + (getOldValueFor('endDate', node) | toMoment | moment) : null)">{{node.endDate | toMoment | moment}}</span>
          }
        </div>
      }
      @if (node.cost != undefined && node.changes.cost) {
        <div class="project-structure-node__cost"
          [matTooltip]="getOldValueTooltipFor('cost', node)">{{ node.cost }}(cost)
        </div>
      }
      @if (node.hours != undefined && node.changes.hours) {
        <div class="project-structure-node__hours"
          [matTooltip]="getOldValueTooltipFor('hours', node)">{{ node.hours }}(hours)
        </div>
      }
      <div class="project-structure-node__change-indicator">
        <collapp-level-change-indicator
        [deleted]="node.isDeleted" [existing]="node.isExisting" [hasChanges]="node.hasChanges"></collapp-level-change-indicator>
      </div>
      <span class="flex-spacer"></span>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div [ngClass]="{'mat-tree-node': true, 'project-structure-node': true, 'project-structure-node--is-disabled': node.isDisabled}">
        <button type="button" mat-icon-button matTreeNodeToggle
          [attr.aria-label]="'toggle ' + node.title">
        <mat-icon class="mat-icon-rtl-mirror" [@indicatorRotate]="getExpandedState(node)">expand_more</mat-icon>
      </button>
      <div class="project-structure-node__title">
        <mat-checkbox [checked]="node.selected"
          [indeterminate]="node.partiallySelected"
          (change)="projectStructureItemSelectionToggle(node)"
          [labelPosition]="labelPosition"
          [disabled]="disabled || node.isDisabled">
          <span class="project-structure-node__title-text">
            {{ node.codePath }}: {{ node.title }}
          </span>
        </mat-checkbox>
      </div>
      <div class="project-structure-node__change-indicator">
        <collapp-level-change-indicator
        [deleted]="node.isDeleted" [existing]="node.isExisting" [hasChanges]="node.hasChanges"></collapp-level-change-indicator>
      </div>
      <span class="flex-spacer"></span>
    </div>
    @if (nestedTreeControl.isExpanded(node)) {
      <div class="project-structure-node__children">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    }
  </mat-nested-tree-node>
</mat-tree>
