import { ProjectViewResponseModel } from '../../api/models/responses/project-view.response.model';
import { ProjectScopeViewResponseModel } from '../../api/models/responses/project-scope-view.response.model';
import { ProjectActivityViewResponseModel } from '../../api/models/responses/project-activity-view.response.model';
import { WorkPackageViewResponseModel } from '../../api/models/responses/work-package-view.response.model';

/**
 * Some user action types
 */
export enum ProjectActionTypes {
  ClearViews = '[Project] Clear Views',
  SetProjectView = '[Project] Set Project View',

  SetProjectActivityView = '[Project] Set Activity View',
  SetProjectScopeView = '[Project] Set Scope View',
  SetWorkPackageView = '[Project] Set Work Package View',
  RefreshAllProjectViews = '[Project] Refresh All Project Views',
  RefreshProjectView = '[Project] Refresh Project View',
  RefreshProjectStructure = '[Project] Refresh Project Structure',
  RefreshWorkPackageView = '[Project] Refresh Work Package View',
}

export class ClearViews {
  static readonly type: string = ProjectActionTypes.ClearViews;
}

export class SetProjectView {
  static readonly type: string = ProjectActionTypes.SetProjectView;

  constructor(public view: ProjectViewResponseModel) {}
}

export class SetProjectScopeView {
  static readonly type: string = ProjectActionTypes.SetProjectScopeView;

  constructor(public view: ProjectScopeViewResponseModel) {}
}

export class SetWorkPackageView {
  static readonly type: string = ProjectActionTypes.SetWorkPackageView;

  constructor(public view: WorkPackageViewResponseModel) {}
}

export class SetProjectActivityView {
  static readonly type: string = ProjectActionTypes.SetProjectActivityView;

  constructor(public view: ProjectActivityViewResponseModel) {}
}

export class RefreshAllProjectViews {
  static readonly type: string = ProjectActionTypes.RefreshAllProjectViews;

  constructor(public projectId: number) {}
}

export class RefreshProjectView {
  static readonly type: string = ProjectActionTypes.RefreshProjectView;

  constructor(public projectId: number) {}
}

export class RefreshProjectStructure {
  static readonly type: string = ProjectActionTypes.RefreshProjectStructure;

  constructor(public projectId: number) {}
}

export class RefreshWorkPackageView {
  static readonly type: string = ProjectActionTypes.RefreshWorkPackageView;

  constructor(public projectId: number, public workPackageId: number) {}
}
