import { UnitPlanningResourceDataDtoModel } from './unit-planning-resource-data.dto.model';
import { UnitPlanningSlimDtoModel } from './unit-planning-slim.dto.model';
import { UnitPlanningUserDtoModel } from './unit-planning-user.dto.model';
import { UnitPlanningDto } from '../../interfaces/dtos/unit-planning.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitPlanningNonHumanResourceDtoModel } from './unit-planning-non-human-resource.dto.model';
import { UnitPlanningWeekTotalDtoModel } from './unit-planning-week-total.dto.model';

export class UnitPlanningDtoModel2 {
  constructor(
    readonly unit: UnitPlanningSlimDtoModel,
    readonly weeksTotal: UnitPlanningWeekTotalDtoModel[] | null,
    readonly totalPlannedHours: number | null,
    readonly resourceDatas: UnitPlanningResourceDataDtoModel[] | null,
  ) {
    if (resourceDatas) {
      this.resourceDatas = [...resourceDatas];
    }
  }

  static fromJSON(json: UnitPlanningDto): UnitPlanningDtoModel2 {
    assertRequiredProperties(json, [
      'unit',
    ]);

    const nonHumanResources = (Array.isArray(json.nonHumanResources) ? json.nonHumanResources : [])
      .map(UnitPlanningNonHumanResourceDtoModel.fromJSON);

    const humanResource = (Array.isArray(json.users) ? json.users : [])
      .map(UnitPlanningUserDtoModel.fromJSON);

    const resourceDataNonHuman = nonHumanResources
      .map(UnitPlanningResourceDataDtoModel.fromUnitPlanningNonHumanResourceDtoModel);

    const resourceDataHuman = humanResource
      .map(UnitPlanningResourceDataDtoModel.fromUnitPlanningUserDtoModel);

    const combinedResourceData = [
      ...resourceDataNonHuman,
      ...resourceDataHuman,
    ];

    return new UnitPlanningDtoModel2(
      UnitPlanningSlimDtoModel.fromJSON(json.unit),
      (Array.isArray(json.weeksTotal) ? json.weeksTotal : [])
        .map(UnitPlanningWeekTotalDtoModel.fromJSON),
      json.totalPlannedHours,
      combinedResourceData,
    );
  }

  clone(): UnitPlanningDtoModel2 {
    return new UnitPlanningDtoModel2(
      this.unit.clone(),
      this.weeksTotal?.map((item) => item.clone()) || null,
      this.totalPlannedHours,
      this.resourceDatas?.map((item) => item.clone()) || null,
    );
  }
}
