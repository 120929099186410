import moment from 'moment';
import { WorkPackageListDto } from '../../interfaces/dtos/work-package-list.dto';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserMinimalModel } from './user-minimal.dto.model';

export class WorkPackageListDtoModel {
  get costToComplete(): number {
    return this.remainingHours === null ? 0 : this.remainingHours;
  }

  // eslint-disable-next-line max-lines-per-function
  constructor(
    readonly workPackageId: number | null = null,
    readonly projectId: number | null = null,
    readonly displayId: string | null = null,
    readonly projectNumber: string | null = null,
    readonly projectTitle: string | null = null,
    readonly levelTitle: string | null = null,
    readonly sequenceNumber: number | null = null,
    readonly status: WorkPackageStatus | null = null,
    readonly title: string | null = null,
    readonly confirmedHours: number = 0,
    readonly openQuestionsCount: number = 0,
    readonly latestConfirmedEndDate: moment.Moment | null = null,
    readonly latestConfirmedHours: number | null = null, // TODO: latestConfirmedHours = 0 per default?
    readonly latestEstimatedStartDate: moment.Moment | null = null,
    readonly latestEstimatedEndDate: moment.Moment | null = null,
    readonly latestEstimatedHours: number = 0,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly latestPlannedEndDate: moment.Moment | null = null,
    readonly plannedHours: number = 0,
    readonly reportedHours: number = 0,
    readonly creator: UserMinimalModel | null = null,
    readonly coordinator: UserMinimalModel | null = null,
    readonly remainingHours: number | null = null,
    readonly levelId?: number | null,
  ) {
    this.latestConfirmedEndDate = (latestConfirmedEndDate != null
      ? latestConfirmedEndDate.clone()
      : latestConfirmedEndDate);
    this.latestEstimatedStartDate = (latestEstimatedStartDate != null
      ? latestEstimatedStartDate.clone()
      : latestEstimatedStartDate);
    this.latestEstimatedEndDate = (latestEstimatedEndDate != null
      ? latestEstimatedEndDate.clone()
      : latestEstimatedEndDate);
    this.plannedStartDate = (plannedStartDate != null ? plannedStartDate.clone() : plannedStartDate);
    this.latestPlannedEndDate = (latestPlannedEndDate != null ? latestPlannedEndDate.clone() : latestPlannedEndDate);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static fromJSON(json: WorkPackageListDto): WorkPackageListDtoModel {
    assertRequiredProperties(json, [
    ]);

    return new WorkPackageListDtoModel(
      json.workPackageId,
      json.projectId,
      json.displayId,
      json.projectNumber,
      json.projectTitle,
      json.levelTitle,
      json.sequenceNumber,
      json.status,
      json.workPackageTitle,
      json.confirmedHours || 0,
      json.openQuestionsCount || 0,
      (json.latestConfirmedEndDate != null
        ? moment(json.latestConfirmedEndDate)
          .parseZone()
        : null
      ),
      json.latestConfirmedHours,
      (json.latestEstimatedStartDate != null
        ? moment(json.latestEstimatedStartDate)
          .parseZone()
        : null
      ),
      (json.latestEstimatedEndDate != null
        ? moment(json.latestEstimatedEndDate)
          .parseZone()
        : null
      ),
      json.latestEstimatedHours || 0,
      (json.plannedStartDate != null
        ? moment(json.plannedStartDate)
          .parseZone()
        : null
      ),
      (json.latestPlannedEndDate != null
        ? moment(json.latestPlannedEndDate)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.reportedHours || 0,
      json.creatorFullName ? new UserMinimalModel(
        json.creatorFullName,
        json.creatorPhotoUrl,
      ) : null,
      json.coordinatorFullName ? new UserMinimalModel(
        json.coordinatorFullName,
        json.coordinatorPhotoUrl,
      ) : null,
      json.remainingHours,
      json.levelId,
    );
  }
}
