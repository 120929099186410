import moment from 'moment';
import { QuestionStatus } from '../../../models/question-status.enum';
import { WorkPackageRole } from '../../../models/work-package-role.enum';
import { QuestionListItemDto } from '../../interfaces/dtos/question-list-item.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionListItemDtoModel {
  constructor(
    readonly projectId: number,
    readonly workPackageId: number,
    readonly questionId: number,
    readonly workPackageDisplayId: string,
    readonly workPackageTitle: string,
    readonly projectTitle: string,
    readonly questionTitle: string,
    readonly numberOfReplies: number = 0,
    readonly creatorFullName: string,
    readonly creatorWorkPackageRole: WorkPackageRole | null = null,
    readonly createdOn: moment.Moment,
    readonly lastRepliedByFullName: string | null = null,
    readonly lastRepliedOn: moment.Moment | null = null,
    readonly status: QuestionStatus,
  ) {
    this.createdOn = createdOn.clone();
    this.lastRepliedOn = (lastRepliedOn != null ? lastRepliedOn.clone() : lastRepliedOn);
  }

  static fromJSON(json: QuestionListItemDto): QuestionListItemDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'workPackageId',
      'questionId',
      'workPackageDisplayId',
      'workPackageTitle',
      'projectTitle',
      'questionTitle',
      'creatorFullName',
      'createdOn',
      'status',
    ]);

    return new QuestionListItemDtoModel(
      json.projectId,
      json.workPackageId,
      json.questionId,
      json.workPackageDisplayId,
      json.workPackageTitle,
      json.projectTitle,
      json.questionTitle,
      json.numberOfReplies,
      json.creatorFullName,
      json.creatorWorkPackageRole,
      moment(json.createdOn)
        .parseZone(),
      json.lastRepliedByFullName,
      (json.lastRepliedOn != null
        ? moment(json.lastRepliedOn)
          .parseZone()
        : null
      ),
      json.status,
    );
  }

  toJSON(): QuestionListItemDto {
    return {
      projectId: this.projectId,
      workPackageId: this.workPackageId,
      questionId: this.questionId,
      workPackageDisplayId: this.workPackageDisplayId,
      workPackageTitle: this.workPackageTitle,
      projectTitle: this.projectTitle,
      questionTitle: this.questionTitle,
      numberOfReplies: this.numberOfReplies,
      creatorFullName: this.creatorFullName,
      creatorWorkPackageRole: this.creatorWorkPackageRole,
      createdOn: this.createdOn.toJSON(),
      lastRepliedByFullName: this.lastRepliedByFullName,
      lastRepliedOn: (this.lastRepliedOn != null ? this.lastRepliedOn.toJSON() : null),
      status: this.status,
    };
  }
}
