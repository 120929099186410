import { WorkpackageSidepanelStateModel } from './work-package-sidepanel-state.model';

/**
 * Action types for WPSidepanel
 */
export enum WorkpackageSidepanelActionTypes {
  InitWorkpackageSidepanel = '[WPSidepanel API] Initialize Sidepanel isOpen',
  OpenWorkpackageSidepanel = '[WPSidepanel API] Set WPSidepanel open',
  CloseWorkpackageSidepanel = '[WPSidepanel API] Set WPSidepanel closed',
  ToggleWorkpackageSidepanel = '[WPSidepanel API] Toggle WPSidepanel',
}

export class InitWorkpackageSidepanel {
  static readonly type: string = WorkpackageSidepanelActionTypes.InitWorkpackageSidepanel;

  constructor(public isOpen: WorkpackageSidepanelStateModel) {}
}

export class OpenWorkpackageSidepanel {
  static readonly type: string = WorkpackageSidepanelActionTypes.OpenWorkpackageSidepanel;
}

export class CloseWorkpackageSidepanel {
  static readonly type: string = WorkpackageSidepanelActionTypes.CloseWorkpackageSidepanel;
}

export class ToggleWorkpackageSidepanel {
  static readonly type: string = WorkpackageSidepanelActionTypes.ToggleWorkpackageSidepanel;
}
