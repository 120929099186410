export interface PermissionRules {
  // eslint-disable-next-line id-blacklist
  any?: string[];
  all?: string[];
}

/**
 * Permissions to access the administration route.
 */
export function getAdministrationPermissions(): PermissionRules {
  return {
    all: ['canAccessAdministration'],
  };
}

/**
 * Permissions to access the reporting route.
 */
export function getReportingPermissions(): PermissionRules {
  return {
    all: ['canAccessTimesheet'],
  };
}

/**
 * Permissions to access the power BI link.
 */
export function getPowerBIPermissions(): PermissionRules {
  return {
    all: ['canAccessPowerBI'],
  };
}

/**
 * Permissions to access the power BI link.
 */
export function getSkillAdministrationPermissions(): PermissionRules {
  return {
    all: ['canAccessSkillAdministration'],
  };
}

/**
 * Permissions to access colors
 */
export function getColorsPermissions(): PermissionRules {
  return {
    all: ['canAccessBackgroundColors'],
  };
}
