import moment from 'moment';
import { UnitPlanningUserWeekTotalDtoModel } from './unit-planning-user-week-total.dto.model';
import { UnitPlanningUserDtoModel } from './unit-planning-user.dto.model';
import { UserPlanningResourceSlimDtoModel } from './unit-planning-resource-slim.dto.model';
import { UnitPlanningWeekRowSlimDtoModel } from './unit-planning-week-row-slim.dto.model';
import { UnitPlanningProjectDtoModel } from './unit-planning-project.dto.model';
import { MetadataSlimDtoModel } from './metadata-slim.dto.model';
import { SlimCapacityPlanningDtoModel } from './planning-capacity-slim.dto.model';
import { UnitPlanningNonHumanResourceDtoModel } from './unit-planning-non-human-resource.dto.model';

export class UnitPlanningResourceDataDtoModel {
  constructor(
    readonly resource: UserPlanningResourceSlimDtoModel,
    readonly projects: UnitPlanningProjectDtoModel[],
    readonly totals: UnitPlanningUserWeekTotalDtoModel[], // mybe not required
    readonly capacities: SlimCapacityPlanningDtoModel[],
    readonly metadata: MetadataSlimDtoModel,
    readonly nonProductiveCategories: UnitPlanningWeekRowSlimDtoModel[],
  ) {
    this.resource = this.resource.clone();

    if (projects) {
      this.projects = this.projects.map((project) => project.clone());
    }

    if (totals) {
      this.totals = this.totals.map((total) => total.clone());
    }

    if (capacities) {
      this.capacities = this.capacities.map((item) => item.clone());
    }
  }

  static fromUnitPlanningNonHumanResourceDtoModel(
    model: UnitPlanningNonHumanResourceDtoModel,
  ): UnitPlanningResourceDataDtoModel {
    const newTotals: UnitPlanningUserWeekTotalDtoModel[] = model.totals
      .map((total) => new UnitPlanningUserWeekTotalDtoModel(
        moment(total.weekStartDate),
        0,
        0,
        total.totalPlannedHours,
        total.workingDays,
      ));

    return new UnitPlanningResourceDataDtoModel(
      UserPlanningResourceSlimDtoModel.fromNonHumanResourcePlanningSlimDtoModel(model.nonHumanResource),
      model.projects,
      newTotals,
      model.capacities,
      model.metadata,
      [],
    );
  }

  static fromUnitPlanningUserDtoModel(
    model: UnitPlanningUserDtoModel,
  ): UnitPlanningResourceDataDtoModel {
    return new UnitPlanningResourceDataDtoModel(
      UserPlanningResourceSlimDtoModel.fromUserPlanningSlimDtoModel(model.user),
      model.projects,
      model.totals,
      model.capacities,
      model.metadata,
      model.nonProductiveCategories,
    );
  }

  clone(): UnitPlanningResourceDataDtoModel {
    return new UnitPlanningResourceDataDtoModel(
      this.resource.clone(),
      this.projects?.map((item) => item.clone()) || null,
      this.totals?.map((item) => item.clone()) || null,
      this.capacities?.map((item) => item.clone()) || null,
      this.metadata.clone(),
      this.nonProductiveCategories?.map((item) => item.clone()) || null,
    );
  }
}
