<person-icon
  [user]="user"
  [badge]="badge"
  [size]="size"
  [matTooltip]="isTooltipVisible ? toolTip : ''"
></person-icon>
<div class="person__info"
  [class.person__info--with-label]="hasLabel"
  [style.minWidth]="collapsible ? collapsibleMinWidthAndUnit : null"
  >
  <div #displayNameEl class="person__name">{{ displayName }} </div>
  @if (hasLabel) {
    <div class="person__label">{{ label }} </div>
  }
</div>
