import { ProjectWorkpackageTotalsDto } from '../../interfaces/dtos/project-workpackage-totals.dto';

export class ProjectWorkpackageTotalsDtoModel {
  constructor(
    readonly totalRecorded: number | null,
    readonly totalAvailableToBePlanned: number | null,
    readonly totalConfirmed: number,
    readonly totalPlanned: number | null,
  ) {}

  static fromJSON(json: ProjectWorkpackageTotalsDto): ProjectWorkpackageTotalsDtoModel {
    return new ProjectWorkpackageTotalsDtoModel(
      json.totalRecorded,
      json.totalAvailableToBePlanned,
      json.totalConfirmed,
      json.totalPlanned,
    );
  }

  clone(): ProjectWorkpackageTotalsDtoModel {
    return new ProjectWorkpackageTotalsDtoModel(
      this.totalRecorded,
      this.totalAvailableToBePlanned,
      this.totalConfirmed,
      this.totalPlanned,
    );
  }
}
