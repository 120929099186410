import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { WorkPackageListStateModel } from './work-package-list-state.model';
import {
  InitWorkPackageListSettings,
  ResetWorkPackageListOptions,
  ResetWorkPackageListSettings,
  SaveWorkPackageListSettings,
  SetWorkPackageListOptions,
  SetWorkPackageListSelectedColumns,
  SetWorkPackageListSettings,
} from './work-package-list.actions';
import { workPackageViewListColumnsInfo } from '../../../api/meta-info/work-packages-list.info';
import { UserService } from '../../../api/services/user.service';
import { WORK_PACKAGE_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultWorkPackageListState: WorkPackageListStateModel = {
  selectedColumns: Array.from(workPackageViewListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: WORK_PACKAGE_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<WorkPackageListStateModel>({
  name: 'workPackageList',
  defaults: defaultWorkPackageListState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackageListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetWorkPackageListSelectedColumns)
  setWorkPackageListSelectedColumns$(
    ctx: StateContext<WorkPackageListStateModel>,
    { columns }: SetWorkPackageListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, workPackageViewListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageListSettings(ctx.getState()));
  }

  @Action(SetWorkPackageListOptions)
  setWorkPackageListOptions$(
    ctx: StateContext<WorkPackageListStateModel>,
    { options }: SetWorkPackageListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, workPackageViewListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageListSettings(ctx.getState()));
  }

  @Action(ResetWorkPackageListOptions)
  resetWorkPackageListOptions$(ctx: StateContext<WorkPackageListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveWorkPackageListSettings(ctx.getState()));
  }

  @Action(InitWorkPackageListSettings)
  initWorkPackageListSettings(
    ctx: StateContext<WorkPackageListStateModel>,
    { settings }: InitWorkPackageListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, workPackageViewListColumnsInfo));
  }

  @Action(SetWorkPackageListSettings)
  setWorkPackageListSettings$(
    ctx: StateContext<WorkPackageListStateModel>,
    { settings }: SetWorkPackageListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, workPackageViewListColumnsInfo));

    return ctx.dispatch(new SaveWorkPackageListSettings(ctx.getState()));
  }

  @Action(ResetWorkPackageListSettings)
  resetWorkPackageListSettings(ctx: StateContext<WorkPackageListStateModel>): void {
    ctx.setState(defaultWorkPackageListState);
  }

  @Action(SaveWorkPackageListSettings)
  saveWorkPackageListSettings$(
    ctx: StateContext<WorkPackageListStateModel>,
    { settings }: SaveWorkPackageListSettings,
  ): Observable<void> {
    return this.userService.saveWorkPackageListSettings$(settings);
  }
}
