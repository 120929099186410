<div class="primary-nav__container">
  @if (user$ | async; as user) {
    <mat-nav-list class="primary-nav__list">
      <div>
        @for (item of items; track navItemTrackByFn($index, item); let first = $first) {
          @if (item.link) {
            <ng-container
            *ngTemplateOutlet="internalLink; context: { item: item, first: first, user: user }"></ng-container>
          }
          @if (item.linkCode) {
            <ng-container
            *ngTemplateOutlet="externalLink; context: { item: item, first: first, user: user }"></ng-container>
          }
        }
        @for (item of adminItems; track navItemTrackByFn($index, item); let first = $first) {
          @if (item.link) {
            <ng-container
            *ngTemplateOutlet="internalLink; context: { item: item, first: first, user: user }"></ng-container>
          }
          @if (item.linkCode) {
            <ng-container
            *ngTemplateOutlet="externalLink; context: { item: item, first: first, user: user }"></ng-container>
          }
        }
      </div>
      <div>
        <ng-container
        *ngTemplateOutlet="internalLink; context: { item: userItems[0], first: false, user: user }"></ng-container>
        <ng-container
        *ngTemplateOutlet="internalLink; context: { item: userItems[1], first: false, user: user }"></ng-container>
      </div>
    </mat-nav-list>
    <div class="primary-nav__user">
      <div class="profile-user" [matMenuTriggerFor]="profileUserMenu" [matMenuTriggerData]="{user: user}"
        [matTooltip]="user | collAppUser" matTooltipPosition="after" [matTooltipShowDelay]="profileUserTooltipDelay"
        [matTooltipDisabled]="open">
        @if (user) {
          <person-icon class="profile-user__picture" [user]="user" size="small">
            @if ((originalUser$ | async)) {
              <ng-template personIconOverlayTmp>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                  class="profile-user__overlay-icon">
                  <path fill="#fff" d="M19 12h-7V3.2L5 6.3V12h7v8.9c3.7-1.1 6.5-4.8 7-8.9z" />
                  <path
                    d="M12 1L3 5v6c0 5.6 3.8 10.7 9 12 5.2-1.3 9-6.5 9-12V5l-9-4zm0 11h7c-.5 4.1-3.3 7.8-7 8.9V12H5V6.3l7-3.1V12z" />
                </svg>
              </ng-template>
            }
          </person-icon>
        }
        <mat-icon class="profile-user__dropdown-arrow">arrow_drop_down</mat-icon>
        <div class="profile-user__name">{{user | collAppUser}}</div>
      </div>
    </div>
  } @else {
    <nav class="skeleton-nav">
      <div class="skeleton-nav__top">
        <ul class="skeleton-nav__items">
          <li class="skeleton__menu-icon"></li>
          <li class="skeleton__menu-icon-sub"></li>
          <li class="skeleton__menu-icon-sub"></li>
          <li class="skeleton__menu-icon-sub"></li>
        </ul>
      </div>
      <div class="skeleton-nav__bottom">
        <div class="skeleton-nav__item-profile"></div>
      </div>
    </nav>
  }
  <div class="primary-nav__shadow"></div>
</div>

<mat-menu #profileUserMenu="matMenu" class="profile-user-menu" [overlapTrigger]="false" xPosition="after">
  <ng-template matMenuContent let-user="user">
    <div class="profile-user-menu__user">
      <div class="profile-user-menu__user-name" [matTooltip]="user | collAppUser" [matTooltipShowDelay]="500">
        {{user | collAppUser}}
      </div>
      @if (user.unit) {
        <div class="profile-user-menu__user-unit" [matTooltip]="user.unit | collAppUnit"
          [matTooltipShowDelay]="500">
          {{user.unit | collAppUnit}}
        </div>
      }
      @if (user.role) {
        <div class="profile-user-menu__user-positions">
          {{user.role | collAppRole}}
        </div>
      }
    </div>
    <mat-divider></mat-divider>
    <a mat-menu-item [routerLink]="['/user/timecard']"
    routerLinkActive="profile-user-menu__item--active">Timecard</a>
    <a type="button" mat-menu-item [routerLink]="['/user/planning']"
    routerLinkActive="profile-user-menu__item--active">{{"common.planning" | translate}}</a>
    <a type="button" mat-menu-item [routerLink]="['/user/settings']"
    routerLinkActive="profile-user-menu__item--active">Calendar & Language</a>
    <a type="button" mat-menu-item [routerLink]="['/user/notifications']"
    routerLinkActive="profile-user-menu__item--active">Notification Settings</a>
    <a type="button" mat-menu-item [routerLink]="['/user/profile']"
    routerLinkActive="profile-user-menu__item--active">Profile</a>
    <mat-divider></mat-divider>
    @if ((originalUser$ | async)) {
      <button type="button" mat-menu-item (click)="logOutSubstituteUser()">Logout as
      {{user | collAppUser}}</button>
    }
    <button type="button" mat-menu-item (click)="logOutUser()">Logout</button>
  </ng-template>
</mat-menu>


<!-- todo: should use routerLinkActive="primary-nav__item--active" as soon as everything is in proper module and routes -->
<ng-template #internalLink let-item="item" let-first="first" let-user="user">
  @if (!item.hidden(user)) {
    <a mat-list-item class="primary-nav__item"
      [class.primary-nav__item--admin]="item.adminClass && first" [routerLink]="item.link"
      [class.primary-nav__item--active]="isAciveLink(item)" [matTooltip]="item.name" matTooltipPosition="after"
      [matTooltipShowDelay]="menuItemTooltipDelay" [matTooltipDisabled]="open">
      <div class="primary-nav__toggle">
        <img [attr.src]="'assets/icon/' + item.icon + '.svg'" class="primary-nav__icon">
      </div>
      <div class="primary-nav__title">{{item.name}}</div>
    </a>
  }
</ng-template>

<ng-template #externalLink let-item="item" let-first="first" let-user="user">
  @if (!item.hidden(user)) {
    <a mat-list-item class="primary-nav__item"
      [class.primary-nav__item--admin]="item.adminClass && first" [href]="getExternalLink(item)" [matTooltip]="item.name"
      matTooltipPosition="after" [matTooltipShowDelay]="menuItemTooltipDelay" [matTooltipDisabled]="open" target="_blank">
      <div class="primary-nav__toggle">
        <img [attr.src]="'assets/icon/' + item.icon + '.svg'" class="primary-nav__icon">
      </div>
      <div class="primary-nav__title">{{item.name}}</div>
    </a>
  }
</ng-template>
