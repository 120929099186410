<person-icon
  [user]="user"
  [badge]="badge"
  [size]="size"
  [matTooltip]="(user | collAppUser) + (tooltipSuffix ? ' (' + tooltipSuffix + ')' : '')"
></person-icon>
<div
  class="person-list-item__name"
  [class.person-list-item__name--large]="nameLength === 'large'"
  [class.person-list-item__name--huge]="nameLength === 'huge'"
  title="{{ user | collAppUser }}"
>
  {{ user | collAppUser }}
</div>
