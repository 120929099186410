import { ProjectRecentListDtoModel } from './project-recent-list.dto.model';
import { ProjectDashboardDto } from '../../interfaces/dtos/project-dashboard.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectDashboardDtoModel extends ProjectRecentListDtoModel {
  constructor(
    projectId: number,
    title: string,
    readonly projectNumber: string,
    readonly projectTypeCode: string,
  ) {
    super(
      projectId,
      title,
    );
  }

  static fromJSON(json: ProjectDashboardDto): ProjectDashboardDtoModel {
    assertRequiredProperties(json, [
      'projectNumber',
      'projectTypeCode',
    ]);

    return new ProjectDashboardDtoModel(
      json.projectId,
      json.title,
      json.projectNumber,
      json.projectTypeCode,
    );
  }

  toJSON(): ProjectDashboardDto {
    return Object.assign(super.toJSON(), {
      projectNumber: this.projectNumber,
      projectTypeCode: this.projectTypeCode,
    });
  }
}
