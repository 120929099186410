import { HttpParams } from '@angular/common/http';
import { JsonObject, JsonValue } from '@angular-devkit/core';
import { CustomHttpUrlEncodingCodec } from './custom-http-url-encoding-codec';

/** Params used to construct an `CustomHttpParams` instance. */
export type CustomHttpParamsObject = Record<string, any>;

/**
 * The operation identifier for `CustomHttpParams.clone`.
 */
const enum Operation {
  APPEND,
  SET,
  DELETE,
}

/**
 * @TODO Check this Angular Bug concerning HttpUrlEncoding : https://github.com/angular/angular/issues/18261
 *
 * An HTTP request/response body that represents serialized parameters,
 * per the MIME type `application/x-www-form-urlencoded`.
 *
 * This class is immutable - all mutation operations return a new instance.
 */
export class CustomHttpParams /* implements HttpParams */ {
  private map: Map<string, string>;

  private encoder: CustomHttpUrlEncodingCodec = new CustomHttpUrlEncodingCodec();

  constructor(params: CustomHttpParamsObject | Map<string, string> = {} as CustomHttpParamsObject) {
    if (params instanceof Map) {
      this.map = new Map(params);
    } else {
      const map = new Map();
      const jsonObject = this.toJsonValue(params) as JsonObject;
      Object
        .keys(jsonObject)
        .forEach((key) => {
          this.parseJsonValueIntoMap(jsonObject[key], key, map);
        });
      this.map = map;
    }
  }

  /**
   * Check whether the body has one or more values for the given parameter name.
   */
  has(param: string): boolean {
    return this.map.has(param);
  }

  /**
   * Get the first value for the given parameter name, or `null` if it's not present.
   */
  get(param: string): string | null {
    const res = this.map.get(param);

    return res != null ? res : null;
  }

  /**
   * Get all the parameter names for this body.
   */
  keys(): string[] {
    return Array.from(this.map ? this.map.keys() : []);
  }

  /**
   * Construct a new body with an appended value for the given parameter name.
   */
  append(param: string, value: any): CustomHttpParams {
    if (value == null) {
      return this.delete(param);
    }

    return this.clone(param, this.toJsonValue(value), Operation.APPEND);
  }

  /**
   * Construct a new body with a new value for the given parameter name.
   */
  set(param: string, value: any): CustomHttpParams {
    if (value == null) {
      return this.delete(param);
    }

    return this.clone(param, this.toJsonValue(value), Operation.SET);
  }

  /**
   * Construct a new body with all values for the given parameter removed.
   */
  delete(param: string): CustomHttpParams {
    return this.clone(param, undefined, Operation.DELETE);
  }

  /**
   * Construct a `HttpParams` object with all the current parameters.
   */
  toHttpParams(): HttpParams {
    return new HttpParams({
      fromObject: this.keys()
        .reduce((obj, key) => ({ ...obj, [key]: this.get(key)! }), {} as Record<string, string>),
      encoder: new CustomHttpUrlEncodingCodec(),
    });
  }

  /**
   * Serialize the body to an encoded string, where key-value pairs (separated by `=`) are
   * separated by `&`s.
   */
  toString(): string {
    return this.keys()
      .map((key) => `${this.encoder.encodeKey(key)}=${this.encoder.encodeValue(this.map.get(key)!)}`)
      .join('&');
  }

  /**
   * Returns a clone of the object according to the operation provided.
   */
  private clone(param: string, value: JsonValue | undefined, operation: Operation): CustomHttpParams {
    const map: Map<string, string> = new Map();
    let paramSet = false;

    if (operation !== Operation.APPEND) {
      this.keys()
        .forEach((key) => {
          // Check if key and param might be the same
          if (key.indexOf(param) === 0) {
            const nextChar = key.substr(param.length, 1);
            const isExactParamMatch = (nextChar === '' || nextChar === '[');
            if (!isExactParamMatch) {
              // If key and param are not an exact match, just add the existing key-value pair
              map.set(key, this.map.get(key)!);
            } else if (operation === Operation.SET && !paramSet) {
              // On the first call, replace existing key with param.
              // Remove all matches on subsequent calls (skipped).
              // e.g. `param` will be set and `param[second]` will be ignored.
              paramSet = true;
              this.parseJsonValueIntoMap(value as JsonValue, param, map);
            }
            // else:
            //   operation == DELETE : just skip setting the param
            //   operation == SET and param was already set : skip to remove obsolete params
          } else {
            // If key and param are not the same, just add the existing key-value pair
            map.set(key, this.map.get(key)!);
          }
        });
    }

    if (
      operation === Operation.APPEND
      || (operation === Operation.SET && !paramSet)
    ) {
      this.parseJsonValueIntoMap(value as JsonValue, param, map);
    }

    return new CustomHttpParams(map);
  }

  /**
   * Converts an object to basic JSON values.
   */
  private toJsonValue(obj: Record<string, any>): JsonValue {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Called recursively to construct HTTP param key-value pairs from nested objects.
   */
  private parseJsonValueIntoMap(obj: JsonValue, prefix: string, httpParamsMap: Map<string, string>): void {
    if (obj != null && typeof obj !== 'function') {
      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i += 1) {
          this.parseJsonValueIntoMap(obj[i], `${prefix}[${i}]`, httpParamsMap);
        }
      } else if (typeof obj !== 'object') {
        // This is primitive value (string, number, boolean)
        httpParamsMap.set(prefix, `${obj}`);
      } else {
        // This is an object
        Object
          .keys(obj)
          .forEach((key) => {
            this.parseJsonValueIntoMap(obj[key], `${prefix}[${key}]`, httpParamsMap);
          });
      }
    }
  }
}
