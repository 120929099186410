import { MetadataModel } from '../metadata.model';
import { AttachmentSlimDtoModel } from '../dtos/attachment-slim.dto.model';
import { AttachmentListResponse } from '../../interfaces/responses/attachment-list.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AttachmentListResponseModel {
  constructor(
    readonly items: readonly AttachmentSlimDtoModel[],
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: AttachmentListResponse): AttachmentListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new AttachmentListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): AttachmentListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
