import moment from 'moment';
import { MilestoneListDto } from '../../interfaces/dtos/milestone-list.dto';
import { MilestoneBaseModel } from '../base/milestone.base.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class MilestoneListDtoModel extends MilestoneBaseModel {
  constructor(
    readonly milestoneId: number,
    title: string,
    dueDate: moment.Moment,
    isCompleted: boolean,
    readonly isOverdue: boolean,
    sortOrder: number,
  ) {
    super(
      title,
      dueDate,
      isCompleted,
      sortOrder,
    );
  }

  static fromJSON(json: MilestoneListDto): MilestoneListDtoModel {
    assertRequiredProperties(json, [
      'milestoneId',
      'title',
      'dueDate',
      'isCompleted',
      'isOverdue',
      'sortOrder',
    ]);

    return new MilestoneListDtoModel(
      json.milestoneId,
      json.title,
      moment(json.dueDate)
        .parseZone(),
      json.isCompleted,
      json.isOverdue,
      json.sortOrder,
    );
  }

  clone(overrides?: Partial<MilestoneListDtoModel>): MilestoneListDtoModel {
    return new MilestoneListDtoModel(
      (overrides && overrides.milestoneId != null
        ? overrides.milestoneId
        : this.milestoneId
      ),
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.dueDate != null
        ? overrides.dueDate
        : this.dueDate
      ),
      (overrides && overrides.isCompleted != null
        ? overrides.isCompleted
        : this.isCompleted
      ),
      (overrides && overrides.isOverdue != null
        ? overrides.isOverdue
        : this.isOverdue
      ),
      (overrides && overrides.sortOrder != null
        ? overrides.sortOrder
        : this.sortOrder
      ),
    );
  }

  toJSON(): MilestoneListDto {
    return {
      milestoneId: this.milestoneId,
      title: this.title,
      dueDate: this.dueDate.toJSON(),
      isCompleted: this.isCompleted,
      isOverdue: this.isOverdue,
      sortOrder: this.sortOrder,
    };
  }
}
