import { DashboardWorkPackageViewType } from '../../../models/dashboard-work-package-view-type.enum';
import { WorkPackageCombinedFilterType } from '../../../modules/dashboard/dashboard-work-packages/filter-types';
import { DashboardStateModel } from './dashboard-state.model';

/**
 * Action types for DashboardState
 */
export enum DashboardActionTypes {
  SetDashboardWorkPackagesFilter = '[Dashboard Page] Set Work Packages Filter',
  SetDashboardWorkPackageFilter = '[Dashboard Page] Set Dashboard Work Package Filter',
  SetDashboardSettings = '[User API] Set Dashboard Settings',
  ResetDashboardSettings = '[User API] Reset Dashboard Settings',
  SaveDashboardSettings = '[User API] Save Dashboard Settings',
  InitDashboardSettings = '[User API] Init Dashboard Settings',
}

export class SetDashboardWorkPackagesFilter {
  static readonly type: string = DashboardActionTypes.SetDashboardWorkPackagesFilter;

  constructor(public filterType: WorkPackageCombinedFilterType) {}
}

export class SetDashboardWorkPackageFilter {
  static readonly type: string = DashboardActionTypes.SetDashboardWorkPackageFilter;

  constructor(public viewType: DashboardWorkPackageViewType[]) {}
}

export class SetDashboardSettings {
  static readonly type: string = DashboardActionTypes.SetDashboardSettings;

  constructor(public settings: DashboardStateModel) {}
}

export class ResetDashboardSettings {
  static readonly type: string = DashboardActionTypes.ResetDashboardSettings;
}

export class SaveDashboardSettings {
  static readonly type: string = DashboardActionTypes.SaveDashboardSettings;

  constructor(public settings: DashboardStateModel) {}
}

export class InitDashboardSettings {
  static readonly type: string = DashboardActionTypes.InitDashboardSettings;

  constructor(public settings: DashboardStateModel) {}
}
