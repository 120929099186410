import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CollappCommonModule } from '../../collapp-common';
import { SpinnerModule } from '../spinner';

import { FormAttachmentsComponent } from './form-attachments.component';
import { OutdateAttachmentDialogComponent } from './components/outdate-attachment-dialog';
import { CollappButtonModule } from '../collapp-button';
import { FormSectionModule } from '../form-section';
import { AlertModule } from '../../shared/alert/alert.module';
import { NgSelectMaterialModule } from '../ng-select-mat';
import { StateBadgeComponent } from './components/state-badge';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    NgxFileDropModule,
    CollappCommonModule,
    SpinnerModule,
    MatDialogModule,
    CollappButtonModule,
    FormSectionModule,
    FlexLayoutModule,
    AlertModule,
    NgSelectMaterialModule,
  ],
  exports: [
    FormAttachmentsComponent,
    StateBadgeComponent,
    OutdateAttachmentDialogComponent,
  ],
  declarations: [
    FormAttachmentsComponent,
    StateBadgeComponent,
    OutdateAttachmentDialogComponent,
  ],
})
export class FormAttachmentsModule {
}
