<span class="mat-button-wrapper">
  <ng-content></ng-content>
  @if (loading) {
    <span class="button-spinner-wrapper">
      <mat-spinner [diameter]="16"></mat-spinner>
    </span>
  }
</span>
<!-- TODO MIGRATION -->
<div matRipple class="mat-button-ripple" [matRippleDisabled]="disableRipple"
></div>
<div matRipple class="mat-button-ripple" [matRippleDisabled]="disableRipple"
><!--  [matRippleTrigger]="_getHostElement()" [class.mat-button-ripple-round]="isIconButton" [matRippleCentered]="isIconButton" --></div>
<div class="mat-button-focus-overlay"></div>
