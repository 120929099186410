import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BasicUser } from '../models/user.interface';
import {
  NotificationDialogComponent,
  NotificationDialogData,
} from '../shared/dialogs/notification-dialog/notification-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class CollappDialogService {
  constructor(
    private dialog: MatDialog,
  ) { }

  hasOpenDialogs(): boolean {
    const { openDialogs } = this.dialog;

    return (openDialogs != null && openDialogs.length > 0);
  }

  showNotificationDialog(
    title?: string,
    message?: string,
    recipients?: BasicUser[],
    isCommentRequired?: boolean,
    showSendNotifications?: boolean,
  ): MatDialogRef<NotificationDialogComponent> {
    const parsedTitle = (title != null) ? title : 'Notification';
    const parsedMessage = (message != null) ? message : 'A notification will be sent';
    const parsedRecipients = (recipients != null) ? recipients : [];
    const parsedIsCommentRequired = (isCommentRequired != null) ? isCommentRequired : false;
    const parsedShowSendNotifications = (showSendNotifications != null) ? showSendNotifications : false;

    const dialogRef = this.dialog.open<NotificationDialogComponent, NotificationDialogData>(
      NotificationDialogComponent,
      {
        disableClose: true,
        closeOnNavigation: false,
        autoFocus: false,
        panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        data: {
          title: parsedTitle,
          message: parsedMessage,
          recipients: parsedRecipients,
          isCommentRequired: parsedIsCommentRequired,
          showSendNotifications: parsedShowSendNotifications,
        },
      },
    );

    return dialogRef;
  }
}
