import { ReplyCreateResponse } from '../../interfaces/responses/reply-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ReplyCreateResponseModel {
  constructor(
    readonly replyId: number,
  ) {
  }

  static fromJSON(json: ReplyCreateResponse): ReplyCreateResponseModel {
    assertRequiredProperties(json, [
      'replyId',
    ]);

    return new ReplyCreateResponseModel(
      json.replyId,
    );
  }
}
