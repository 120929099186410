import { UnitUpdateRequest } from '../../interfaces/requests/unit-update.request';
import { UnitCreateRequestModel } from './unit-create.request.model';
import { ContractSaveRequestModel } from './contract-save.request.model';
import { NonProductiveCategoryModel } from '../../../models/non-productive-category.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CostCenerSaveRequest } from '../../interfaces/requests/cost-center-save.request';

export class UnitUpdateRequestModel extends UnitCreateRequestModel {
  constructor(
    readonly unitId: number,
    code: string,
    name: string,
    unitCoordinatorId: string,
    parentUnitId: number | null,
    defects: readonly string[],
    deputyUnitCoordinatorIds: readonly string[],
    submitterContracts: readonly ContractSaveRequestModel[],
    nonProductiveCategories: readonly NonProductiveCategoryModel[],
    timesheetExportTemplateColumns: string,
    timesheetExportTemplateHighlighting: string,
    timesheetExportNonProductiveTemplateColumns: string,
    timesheetExportNonProductiveTemplateHighlighting: string,
    readonly removedSubmitterContractIds: readonly number[],
    directUserSubmissionOfTimecard: boolean,
    readonly costCenters: CostCenerSaveRequest[],
    readonly removedCostCenterIds: number[],
    readonly removedActivityTypeIds: number[],
    hiddenLevels: string,
    payrollCompany: string,
    profitCenter: string,
  ) {
    super(
      code,
      name,
      unitCoordinatorId,
      parentUnitId,
      defects,
      deputyUnitCoordinatorIds,
      submitterContracts,
      nonProductiveCategories,
      timesheetExportTemplateColumns,
      timesheetExportTemplateHighlighting,
      timesheetExportNonProductiveTemplateColumns,
      timesheetExportNonProductiveTemplateHighlighting,
      directUserSubmissionOfTimecard,
      costCenters,
      hiddenLevels,
      payrollCompany,
      profitCenter,
    );

    this.removedSubmitterContractIds = [...removedSubmitterContractIds];
    this.removedCostCenterIds = [...removedCostCenterIds];
    this.removedActivityTypeIds = [...removedActivityTypeIds];
  }

  static fromJSON(json: UnitUpdateRequest): UnitUpdateRequestModel {
    assertRequiredProperties(json, [
      'unitId',
      'code',
      'name',
      'unitCoordinatorId',
    ]);

    return new UnitUpdateRequestModel(
      json.unitId,
      json.code,
      json.name,
      json.unitCoordinatorId,
      json.parentUnitId || null,
      json.defects || [],
      json.deputyUnitCoordinatorIds || [],
      (Array.isArray(json.submitterContracts) ? json.submitterContracts : [])
        .map((item) => ContractSaveRequestModel.fromJSON(item)),
      (Array.isArray(json.nonProductiveCategories) ? json.nonProductiveCategories : [])
        .map((item) => NonProductiveCategoryModel.fromJSON(item)),
      json.timesheetExportTemplateColumns || '',
      json.timesheetExportTemplateHighlighting || '',
      json.timesheetExportNonProductiveTemplateColumns || '',
      json.timesheetExportNonProductiveTemplateHighlighting || '',
      json.removedSubmitterContractIds || [],
      json.directUserSubmissionOfTimecard,
      // TODO: check
      (Array.isArray(json.costCenters) ? json.costCenters : [])
        .map((item) => item),
      json.removedCostCenterIds || [],
      json.removedActivityTypeIds || [],
      json.hiddenLevels || '',
      json.payrollCompany || '',
      json.profitCenter || '',
    );
  }

  clone(): UnitUpdateRequestModel {
    return new UnitUpdateRequestModel(
      this.unitId,
      this.code,
      this.name,
      this.unitCoordinatorId,
      this.parentUnitId,
      this.defects,
      this.deputyUnitCoordinatorIds,
      this.submitterContracts,
      this.nonProductiveCategories,
      this.timesheetExportTemplateColumns,
      this.timesheetExportTemplateHighlighting,
      this.timesheetExportNonProductiveTemplateColumns,
      this.timesheetExportNonProductiveTemplateHighlighting,
      this.removedSubmitterContractIds,
      this.directUserSubmissionOfTimecard,
      this.costCenters,
      this.removedCostCenterIds,
      this.removedActivityTypeIds,
      this.hiddenLevels,
      this.payrollCompany,
      this.profitCenter,
    );
  }

  toJSON(): UnitUpdateRequest {
    return Object.assign(super.toJSON(), {
      unitId: this.unitId,
      removedSubmitterContractIds: [...this.removedSubmitterContractIds],
      costCenters: this.costCenters.map((item) => item),
      removedCostCenterIds: this.removedCostCenterIds,
      removedActivityTypeIds: this.removedActivityTypeIds,
    });
  }
}
