/**
 * Possible conclusions of a closed work package
 */
export enum WorkPackageConclusion {
  Completed = 0,
  Unresolved = 1,
}

export const workPackageConclusions: { [key in WorkPackageConclusion]: string } = Object.freeze({
  [WorkPackageConclusion.Completed]: 'Completed',
  [WorkPackageConclusion.Unresolved]: 'Unresolved',
});
