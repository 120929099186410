<div class="show-more show-more">
  <div class="show-more__body" [@collapsedExpanded]="expanded ? 'expanded' : 'collapsed'">
    <ng-content></ng-content>
  </div>
  <div class="show-more__action">
    <button mat-icon-button (click)="toggleState()">
      <mat-icon [@pointDownOrUp]="expanded ? 'up' : 'down'">keyboard_arrow_down</mat-icon>
    </button>
  </div>
</div>
