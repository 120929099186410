import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { AttachmentColumnFilters } from '../filter-columns';
import { AttachmentSlimDto } from '../interfaces/dtos/attachment-slim.dto';
import { ReadonlyCompleteMap } from '../../app.types';

export type AttachmentsListColumnKey = ObjectPropertyNames<AttachmentSlimDto>;

// This defines the object that should implement all properties of 'AttachmentSlimDto' exactly.
// It's used for linting mostly and discarded later.
const attachmentsListColumnsInfoObj: ListColumnsInfo<AttachmentSlimDto> = {
  attachmentId: {
    label: 'Attachment ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  projectId: {
    label: 'Project ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  workPackageId: {
    label: 'Work Package ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  questionId: {
    label: 'Question ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  documentType: {
    label: 'Document Type',
    selected: false,
    selectable: false,
    sortable: true,
    filterable: false,
  },
  fileName: {
    label: 'Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: false,
  },
  fileType: {
    label: 'Type',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: false,
  },
  fileSize: {
    label: 'Size',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  uploader: {
    label: 'Uploader',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: false,
  },
  uploadedOn: {
    label: 'Uploaded',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: false,
  },
  outdater: {
    label: 'Outdater',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  outdatedOn: {
    label: 'Outdated',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  outdatedComment: {
    label: 'Outdated Comment',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  metadata: {
    label: 'Metadata',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  url: {
    label: 'Url',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  validUntil: {
    label: 'Valid until',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const attachmentsListColumnsInfo: ReadonlyCompleteMap<AttachmentsListColumnKey, ListColumnInfo> = new Map([
  ['documentType', attachmentsListColumnsInfoObj.documentType],
  ['fileName', attachmentsListColumnsInfoObj.fileName],
  ['fileType', attachmentsListColumnsInfoObj.fileType],
  ['fileSize', attachmentsListColumnsInfoObj.fileSize],
  ['uploader', attachmentsListColumnsInfoObj.uploader],
  ['uploadedOn', attachmentsListColumnsInfoObj.uploadedOn],
  ['outdater', attachmentsListColumnsInfoObj.outdater],
  ['outdatedOn', attachmentsListColumnsInfoObj.outdatedOn],
]) as ReadonlyCompleteMap<AttachmentsListColumnKey, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const attachmentsListColumnsWithFilters: (keyof AttachmentColumnFilters)[] = Array.from(
  attachmentsListColumnsInfo.entries(),
)
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof AttachmentColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
