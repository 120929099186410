import moment from 'moment';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { WorkPackageViewListItemResponse } from '../../interfaces/responses/work-package-view-list-item.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageViewListItemResponseModel {
  // eslint-disable-next-line max-lines-per-function
  constructor(
    readonly workPackageId: number | null = null,
    readonly projectId: number | null = null,
    readonly displayId: string | null = null,
    readonly projectNumber: string | null = null,
    readonly projectTitle: string | null = null,
    readonly levelTitle: string | null = null,
    readonly sequenceNumber: number | null = null,
    readonly status: WorkPackageStatus = WorkPackageStatus.Draft,
    readonly workPackageTitle: string | null = null,
    readonly scope: string | null = null,
    readonly estimatedStartDate: moment.Moment | null = null,
    readonly estimatedEndDate: moment.Moment | null = null,
    readonly estimatedHours: number = 0,
    readonly confirmedStartDate: moment.Moment | null = null,
    readonly confirmedEndDate: moment.Moment | null = null,
    readonly confirmedHours: number = 0,
    readonly openQuestionsCount: number = 0,
    readonly latestConfirmedEndDate: moment.Moment | null = null,
    readonly latestConfirmedHours: number | null = null, // TODO: latestConfirmedHours = 0 per default?
    readonly latestEstimatedStartDate: moment.Moment | null = null,
    readonly latestEstimatedEndDate: moment.Moment | null = null,
    readonly latestEstimatedHours: number = 0,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly latestPlannedEndDate: moment.Moment | null = null,
    readonly plannedHours: number = 0,
    readonly reportedHours: number = 0,
    readonly supplierUnit: string | null = null,
    readonly supplierUnitCoordinatorFullName: string | null = null,
    readonly submitterUnit: string | null = null,
    readonly submitterUnitCoordinatorFullName: string | null = null,
    readonly submitterHourlyRateCategory: string | null = null,
    readonly supplierHourlyRateCategory: string | null = null,
    readonly isFlagged: boolean = false,
    readonly creatorFullName: string | null = null,
    readonly responsibles: string | null = null,
    readonly coordinator: string | null = null,
    readonly submitterProjectTypeCode: string | null = null,
    readonly supplierProjectTypeCode: string | null = null,
    readonly supplierProjectNumberANDActivityItemCategoryNumber: string | null = null,
    readonly submitterProjectNumberANDActivityItemCategoryNumber: string | null = null,
    readonly pmFullName: string | null = null,
    readonly technicalPMFullName: string | null = null,
    readonly additionalSupplierProjectTypeCodes: string | null = null,
    readonly additionalSupplierProjectNumberANDActivityItemCategoryNumbers: string | null = null,
    readonly additionalSupplierUnitNames: string | null = null,
    readonly additionalSupplierHourlyRateCategory: string | null = null,
    readonly contractName: string | null = null,
  ) {
    this.estimatedStartDate = (estimatedStartDate != null ? estimatedStartDate.clone() : estimatedStartDate);
    this.estimatedEndDate = (estimatedEndDate != null ? estimatedEndDate.clone() : estimatedEndDate);
    this.confirmedStartDate = (confirmedStartDate != null ? confirmedStartDate.clone() : confirmedStartDate);
    this.confirmedEndDate = (confirmedEndDate != null ? confirmedEndDate.clone() : confirmedEndDate);
    this.latestConfirmedEndDate = (latestConfirmedEndDate != null
      ? latestConfirmedEndDate.clone()
      : latestConfirmedEndDate);
    this.latestEstimatedStartDate = (latestEstimatedStartDate != null
      ? latestEstimatedStartDate.clone()
      : latestEstimatedStartDate);
    this.latestEstimatedEndDate = (latestEstimatedEndDate != null
      ? latestEstimatedEndDate.clone()
      : latestEstimatedEndDate);
    this.plannedStartDate = (plannedStartDate != null ? plannedStartDate.clone() : plannedStartDate);
    this.latestPlannedEndDate = (latestPlannedEndDate != null ? latestPlannedEndDate.clone() : latestPlannedEndDate);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static fromJSON(json: WorkPackageViewListItemResponse): WorkPackageViewListItemResponseModel {
    assertRequiredProperties(json, [
    ]);

    return new WorkPackageViewListItemResponseModel(
      json.workPackageId,
      json.projectId,
      json.displayId,
      json.projectNumber,
      json.projectTitle,
      json.levelTitle,
      json.sequenceNumber,
      json.status || WorkPackageStatus.Draft,
      json.workPackageTitle,
      json.scope,
      (json.estimatedStartDate != null
        ? moment(json.estimatedStartDate)
          .parseZone()
        : null
      ),
      (json.estimatedEndDate != null
        ? moment(json.estimatedEndDate)
          .parseZone()
        : null
      ),
      json.estimatedHours || 0,
      (json.confirmedStartDate != null
        ? moment(json.confirmedStartDate)
          .parseZone()
        : null
      ),
      (json.confirmedEndDate != null
        ? moment(json.confirmedEndDate)
          .parseZone()
        : null
      ),
      json.confirmedHours || 0,
      json.openQuestionsCount || 0,
      (json.latestConfirmedEndDate != null
        ? moment(json.latestConfirmedEndDate)
          .parseZone()
        : null
      ),
      json.latestConfirmedHours,
      (json.latestEstimatedStartDate != null
        ? moment(json.latestEstimatedStartDate)
          .parseZone()
        : null
      ),
      (json.latestEstimatedEndDate != null
        ? moment(json.latestEstimatedEndDate)
          .parseZone()
        : null
      ),
      json.latestEstimatedHours || 0,
      (json.plannedStartDate != null
        ? moment(json.plannedStartDate)
          .parseZone()
        : null
      ),
      (json.latestPlannedEndDate != null
        ? moment(json.latestPlannedEndDate)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.reportedHours || 0,
      json.supplierUnit,
      json.supplierUnitCoordinatorFullName,
      json.submitterUnit,
      json.submitterUnitCoordinatorFullName,
      json.submitterHourlyRateCategory,
      json.supplierHourlyRateCategory,
      json.isFlagged || false,
      json.creatorFullName,
      json.responsibles,
      json.coordinator,
      json.submitterProjectTypeCode,
      json.supplierProjectTypeCode,
      json.supplierProjectNumberANDActivityItemCategoryNumber,
      json.submitterProjectNumberANDActivityItemCategoryNumber,
      json.pmFullName,
      json.technicalPMFullName,
      json.additionalSupplierProjectTypeCodes,
      json.additionalSupplierProjectNumberANDActivityItemCategoryNumbers,
      json.additionalSupplierUnitNames,
      json.additionalSupplierHourlyRateCategory,
      json.contractName
    );
  }
}
