import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { UnitPlanningRangeStateModel } from './unit-planning-range-state.model';
import {
  InitUnitPlanningRangeSettings,
  ResetUnitPlanningRangeSettings,
  SaveUnitPlanningRangeSettings,
  SetUnitPlanningRangeSettings,
} from './unit-planning-range.actions';
import { UserService } from '../../../api/services/user.service';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultUnitPlanningRangeState: UnitPlanningRangeStateModel = {
  range: null,
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<UnitPlanningRangeStateModel>({
  name: 'UnitPlanningRange',
  defaults: defaultUnitPlanningRangeState,
})
@Injectable({
  providedIn: 'root',
})
export class UnitPlanningRangeState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(InitUnitPlanningRangeSettings)
  initUnitPlanningRangeSettings(
    ctx: StateContext<UnitPlanningRangeStateModel>,
    { settings }: InitUnitPlanningRangeSettings,
  ): void {
    ctx.patchState({
      range: settings.range,
    });
  }

  @Action(SetUnitPlanningRangeSettings)
  setUnitPlanningRangeSettings$(
    ctx: StateContext<UnitPlanningRangeStateModel>,
    { settings }: SetUnitPlanningRangeSettings,
  ): Observable<void> {
    ctx.patchState({
      range: settings.range,
    });

    return ctx.dispatch(new SaveUnitPlanningRangeSettings(ctx.getState()));
  }

  @Action(ResetUnitPlanningRangeSettings)
  resetUnitPlanningRangeSettings(ctx: StateContext<UnitPlanningRangeStateModel>): void {
    ctx.setState(defaultUnitPlanningRangeState);
  }

  @Action(SaveUnitPlanningRangeSettings)
  saveUnitPlanningRangeSettings$(
    ctx: StateContext<UnitPlanningRangeStateModel>,
    { settings }: SaveUnitPlanningRangeSettings,
  ): Observable<void> {
    return this.userService.saveUnitPlanningRangeSettings$(settings);
  }
}
