import { UnitSkillDtoModel } from '../../../api/models/dtos/unit-skill.dto.model';

/**
 * Store actions for manipulating skills user settings.
 */
export enum SkillsActionTypes {
  InitSkillsSettings = '[API] Init Skills Settings State',
  AddSkillsRecentUnit = '[Skills] Add Skills Recent Unit',
}

/**
 * In the backend only the cross utilization flag and a list of recent units are stored.
 * The week and year is not stored in the backend.
 */
export class InitSkillsSettings {
  static readonly type: string = SkillsActionTypes.InitSkillsSettings;

  constructor(
    readonly recentUnits: readonly UnitSkillDtoModel[],
  ) {}
}

export class AddSkillsRecentUnit {
  static readonly type: string = SkillsActionTypes.AddSkillsRecentUnit;

  constructor(
    public unit: UnitSkillDtoModel,
  ) {}
}
