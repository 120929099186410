import { BaseDataState } from './base-data';
import { UserState } from './user';
import { ProjectState } from './project';
import {
  AttachmentListState,
  DashboardState,
  ProjectListState,
  QuestionsListState,
  SettingsState,
  TimecardsState,
  TimesheetState,
  UnitListState,
  UserListState,
  WorkPackageListState,
  WorkPackageQuestionListState,
  UserResourceListState,
  NonHumanResourceListState,
  NonHumanResourcePlanningListState,
  WorkPackageState,
  SkillsState,
  ActivitiesState,
  UserPlanningTotalsState,
  UnitPlanningTotalsState,
  UserPlanningRangeState,
  UnitPlanningRangeState,
  TimesheetRangeState,
  TimesheetTotalsState,
  WorkPackagePlanningTotalsState,
  WorkPackagePlanningRangeState,
  TimecardRangeState,
  WorkpackageSidepanelState,
  UserPlanningSidepanelState,
  UnitPlanningSidepanelIndexState,
  UserPlanningSidepanelIndexState,
  WorkPackageSidepanelIndexState,
} from './settings';
import { UnitPlanningSidepanelState } from './settings/unit-planning-sidepanel';
import { ProjectPlanningRangeState } from './settings/project-planning-range/project-planning-range.state';
import { ProjectPlanningTotalsState } from './settings/project-planning-totals/project-planning-totals.state';
import { UnitPlanningState } from './settings/unit-planning/unit-planning.state';

export * from './base-data';
export * from './user';
export * from './project';
export * from './settings';

export const sharedStates = [
  AttachmentListState,
  BaseDataState,
  UserState,
  ProjectState,
  SettingsState,
  DashboardState,
  ProjectListState,
  TimecardsState,
  TimesheetState,
  UnitPlanningState,
  UnitListState,
  NonHumanResourceListState,
  UserListState,
  WorkPackageListState,
  WorkPackageQuestionListState,
  WorkPackageState,
  QuestionsListState,
  UserResourceListState,
  NonHumanResourcePlanningListState,
  SkillsState,
  ActivitiesState,
  UserPlanningTotalsState,
  UnitPlanningTotalsState,
  ProjectPlanningTotalsState,
  UserPlanningRangeState,
  UnitPlanningRangeState,
  ProjectPlanningRangeState,
  TimesheetRangeState,
  TimesheetTotalsState,
  WorkPackagePlanningTotalsState,
  WorkPackagePlanningRangeState,
  TimecardRangeState,
  WorkpackageSidepanelState,
  UserPlanningSidepanelState,
  UnitPlanningSidepanelState,
  UnitPlanningSidepanelIndexState,
  UserPlanningSidepanelIndexState,
  WorkPackageSidepanelIndexState,
];
