import { QuestionListResponse } from '../../interfaces/responses/question-list.response';
import { QuestionDtoModel } from '../dtos/question.dto.model';
import { QuestionListResponseMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionListResponseModel {
  constructor(
    readonly items: readonly QuestionDtoModel[],
    readonly metadata: MetadataModel<QuestionListResponseMetadata> = new MetadataModel(),
  ) {
    this.items = [...items];
  }

  static fromJSON(json: QuestionListResponse): QuestionListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new QuestionListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => QuestionDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): QuestionListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
