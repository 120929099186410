import moment from 'moment';
import { ApiModelNew } from '../types';
import { QuestionDashboardDto } from '../../interfaces/dtos/question-dashboard.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { WorkPackageRole } from '../../../models/work-package-role.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionDashboardDtoModel implements ApiModelNew {
  constructor(
    readonly questionId: number,
    readonly title: string,
    readonly text: string,
    readonly createdOn: moment.Moment,
    readonly workPackageId: number,
    readonly workPackageTitle: string,
    readonly projectId: number,
    readonly projectName: string,
    readonly questionCreator: UserSlimDtoModel,
    readonly questionCreatorWorkPackageRole: WorkPackageRole | null = null,
  ) {
    this.createdOn = createdOn.clone();
  }

  static fromJSON(json: QuestionDashboardDto): QuestionDashboardDtoModel {
    assertRequiredProperties(json, [
      'questionId',
      'title',
      'text',
      'createdOn',
      'workPackageId',
      'workPackageTitle',
      'projectId',
      'projectName',
      'questionCreator',
    ]);

    return new QuestionDashboardDtoModel(
      json.questionId,
      json.title,
      json.text,
      moment(json.createdOn)
        .parseZone(),
      json.workPackageId,
      json.workPackageTitle,
      json.projectId,
      json.projectName,
      UserSlimDtoModel.fromJSON(json.questionCreator),
      json.questionCreatorWorkPackageRole,
    );
  }

  toJSON(): QuestionDashboardDto {
    return {
      questionId: this.questionId,
      title: this.title,
      text: this.text,
      createdOn: this.createdOn.toJSON(),
      workPackageId: this.workPackageId,
      workPackageTitle: this.workPackageTitle,
      projectId: this.projectId,
      projectName: this.projectName,
      questionCreator: this.questionCreator.toJSON(),
      questionCreatorWorkPackageRole: (this.questionCreatorWorkPackageRole
        ? this.questionCreatorWorkPackageRole
        : null),
    };
  }

  clone(overrides?: Partial<QuestionDashboardDtoModel>): QuestionDashboardDtoModel {
    const clone = QuestionDashboardDtoModel.fromJSON(this.toJSON());

    if (overrides) {
      Object.assign(clone, overrides);
    }

    return clone;
  }
}
