import moment from 'moment';
import { PlanningResource } from '../../../modules/user/planning2/planning-resource.interface';
import { UserListDto } from '../../interfaces/dtos/user-list.dto';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { RoleDtoModel } from './role.dto.model';
import { MetadataModel } from '../metadata.model';
import { UserListDtoMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { UserUnitAssignmentDtoModel } from './user-unit-assignment.dto.model';

export class UserListDtoModel implements PlanningResource {
  readonly fullName: string;

  constructor(
    readonly userId: string,
    readonly email: string,
    readonly photoUrl: string | null,
    readonly hitachiId: string | null,
    readonly globalEmployeeId: string | null,
    readonly localEmployeeId: string | null,
    readonly firstName: string | null,
    readonly lastName: string | null,
    readonly abbreviation: string | null,
    readonly payrollCompany: string | null,
    readonly costCenter: string | null,
    readonly countryCode: string | null,
    readonly city: string | null,
    readonly divisionCode: string | null,
    readonly productGroupCode: string | null,
    readonly unit: UnitSlimDtoModel | null,
    readonly unitCoordinator: UserSlimDtoModel | null,
    readonly validFrom: moment.Moment | null,
    readonly validUntil: moment.Moment | null,
    readonly businessUnitCode: string | null,
    readonly hourlyRateCategory: string | null,
    readonly workingHoursPerWeek: number | null,
    readonly role: RoleDtoModel | null,
    readonly metadata: UserListDtoMetadata,
    readonly unitAssignments: UserUnitAssignmentDtoModel[] | null = null,
  ) {
    if (this.firstName && this.lastName) {
      this.fullName = `${this.firstName} ${this.lastName}`.trim();
    } else {
      this.fullName = '';
    }

    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);

    if (unitAssignments) {
      this.unitAssignments = [...unitAssignments];
    }
  }

  get name(): string {
    return this.fullName;
  }

  get id(): string {
    return this.userId;
  }

  get capacityUnit(): string {
    return 'h';
  }

  get isHumanResource(): boolean {
    return true;
  }

  static fromJSON(json: UserListDto): UserListDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'email',
    ]);

    return new UserListDtoModel(
      json.userId,
      json.email,
      json.photoUrl,
      json.hitachiId,
      json.globalEmployeeId,
      json.localEmployeeId,
      json.firstName,
      json.lastName,
      json.abbreviation,
      json.payrollCompany,
      json.costCenter,
      json.countryCode,
      json.city,
      json.divisionCode,
      json.productGroupCode,
      (json.unit
        ? UnitSlimDtoModel.fromJSON(json.unit)
        : null
      ),
      (json.unitCoordinator
        ? UserSlimDtoModel.fromJSON(json.unitCoordinator)
        : null
      ),
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.businessUnitCode,
      json.hourlyRateCategory,
      json.workingHoursPerWeek,
      (json.role
        ? RoleDtoModel.fromJSON(json.role)
        : null
      ),
      new MetadataModel(json.metadata || {}) as UserListDtoMetadata,
      (json.unitAssignments
        ? json.unitAssignments.map((assignment) => UserUnitAssignmentDtoModel.fromJSON(assignment))
        : null
      ),
    );
  }

  clone(): UserListDtoModel {
    return new UserListDtoModel(
      this.userId,
      this.email,
      this.photoUrl,
      this.hitachiId,
      this.globalEmployeeId,
      this.localEmployeeId,
      this.firstName,
      this.lastName,
      this.abbreviation,
      this.payrollCompany,
      this.costCenter,
      this.countryCode,
      this.city,
      this.divisionCode,
      this.productGroupCode,
      this.unit ? this.unit.clone() : null,
      this.unitCoordinator ? this.unitCoordinator.clone() : null,
      this.validFrom ? this.validFrom.clone() : null,
      this.validUntil ? this.validUntil.clone() : null,
      this.businessUnitCode,
      this.hourlyRateCategory,
      this.workingHoursPerWeek,
      this.role ? this.role.clone() : null,
      this.metadata,
      this.unitAssignments ? this.unitAssignments.map((assignment) => assignment.clone()) : null,
    );
  }

  toJSON(): UserListDto {
    return {
      userId: this.userId,
      email: this.email,
      photoUrl: this.photoUrl,
      hitachiId: this.hitachiId,
      globalEmployeeId: this.globalEmployeeId,
      localEmployeeId: this.localEmployeeId,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      abbreviation: this.abbreviation,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      countryCode: this.countryCode,
      city: this.city,
      divisionCode: this.divisionCode,
      productGroupCode: this.productGroupCode,
      unit: this.unit ? this.unit.toJSON() : null,
      unitCoordinator: this.unitCoordinator ? this.unitCoordinator.toJSON() : null,
      validFrom: this.validFrom ? this.validFrom.toJSON() : null,
      validUntil: this.validUntil ? this.validUntil.toJSON() : null,
      businessUnitCode: this.businessUnitCode,
      hourlyRateCategory: this.hourlyRateCategory,
      workingHoursPerWeek: this.workingHoursPerWeek,
      role: this.role ? this.role.toJSON() : null,
      metadata: this.metadata,
      unitAssignments: this.unitAssignments ? this.unitAssignments.map((assignment) => assignment.toJSON()) : null,
    };
  }
}
