import { RatingDto } from '../../interfaces/dtos/rating.dto';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class RatingDtoModel implements ApiModelNew {
  constructor(
    readonly ratingId: number,
    readonly stars: number,
    readonly title: string,
    readonly description: string,
  ) {
  }

  static fromJSON(json: RatingDto): RatingDtoModel {
    assertRequiredProperties(json, [
      'ratingId',
      'stars',
      'title',
      'description',
    ]);

    return new RatingDtoModel(
      json.ratingId,
      json.stars,
      json.title,
      json.description,
    );
  }

  clone(overrides?: Partial<RatingDtoModel>): RatingDtoModel {
    return new RatingDtoModel(
      (overrides && overrides.ratingId != null
        ? overrides.ratingId
        : this.ratingId
      ),
      (overrides && overrides.stars != null
        ? overrides.stars
        : this.stars
      ),
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.description != null
        ? overrides.description
        : this.description
      ),
    );
  }

  toJSON(): RatingDto {
    return {
      ratingId: this.ratingId,
      stars: this.stars,
      title: this.title,
      description: this.description,
    };
  }
}
