import { SkillRatingValueDtoModel } from './skill-rating-value.dto.model';
import { SkillRatingSkillDto } from '../../interfaces/dtos/skill-rating-skill.dto';

export class SkillRatingSkillDtoModel {
  constructor(
    readonly ratings: SkillRatingValueDtoModel[],
    readonly id: number,
    readonly name: string | null,
  ) {
    this.ratings = ratings.map((rating) => rating.clone());
  }

  static fromJSON(json: SkillRatingSkillDto): SkillRatingSkillDtoModel {
    return new SkillRatingSkillDtoModel(
      json.ratings.map(SkillRatingValueDtoModel.fromJSON),
      json.id,
      json.name,
    );
  }

  clone(): SkillRatingSkillDtoModel {
    return new SkillRatingSkillDtoModel(
      this.ratings.map((rating) => rating.clone()),
      this.id,
      this.name,
    );
  }

  toJSON(): SkillRatingSkillDto {
    return {
      ratings: this.ratings.map((rating) => rating.toJSON()),
      id: this.id,
      name: this.name,
    };
  }
}
