import { QuestionCreateResponse } from '../../interfaces/responses/question-create.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class QuestionCreateResponseModel {
  constructor(
    readonly questionId: number,
  ) {
  }

  static fromJSON(json: QuestionCreateResponse): QuestionCreateResponseModel {
    assertRequiredProperties(json, [
      'questionId',
    ]);

    return new QuestionCreateResponseModel(
      json.questionId,
    );
  }

  toJSON(): QuestionCreateResponse {
    return {
      questionId: this.questionId,
    };
  }
}
