import { createSelector, Selector } from '@ngxs/store';
import { CollappFilterDropdownListRadioSetting } from '../../models/collapp-filter-dropdown-list-radio.interface';
import { ProjectPlanningFiltersSettingsModel } from '../../models/project-planning-filter-settings.interface';
import { UserState } from './user.state';
import { UserStateModel } from './user-state.model';
import { UserDtoModel } from '../../api/models/dtos/user.dto.model';
import { UserPermissions } from '../../api/user-permissions';
import { ProjectRecentListDtoModel } from '../../api/models/dtos/project-recent-list.dto.model';
import { TimecardSettings } from '../../models/time-tracking-settings';
import { ProjectStructureFiltersSettingsModel } from '../../models/project-structure-filter-settings.interface';

export class UserSelectors {
  /**
   * Checks a specific permission of the user
   * @param permission
   */
  static permission(permission: string): (state: UserStateModel) => boolean {
    return createSelector([UserState], (state: UserStateModel) => (state.permissions[permission] === true));
  }

  @Selector([UserState])
  static user(state: UserStateModel): UserDtoModel | null {
    return state.user;
  }

  @Selector([UserState])
  static userTimeZone(state: UserStateModel): string | null {
    return (state.user ? state.user.timezone : null);
  }

  /**
   * @deprecated Use single permissions directly!
   * @param state
   */
  @Selector([UserState])
  static permissions(state: UserStateModel): UserPermissions {
    return { ...state.permissions };
  }

  @Selector([UserState])
  static recentProjects(state: UserStateModel): ProjectRecentListDtoModel[] {
    return state.recentProjects;
  }

  @Selector([UserState])
  static recentTimecard(state: UserStateModel): TimecardSettings | null {
    return state.recentTimecard;
  }

  @Selector([UserState])
  static originalUser(state: UserStateModel): UserDtoModel | null {
    return state.originalUser;
  }

  @Selector([UserState])
  static projectStructureFilterSettings(state: UserStateModel): ProjectStructureFiltersSettingsModel | null {
    return <ProjectStructureFiltersSettingsModel>state.user?.settings.projectsStrucureHeaderFilters;
  }

  @Selector([UserState])
  static timecardHeaderFilterSettings(state: UserStateModel): CollappFilterDropdownListRadioSetting[] | null {
    return <CollappFilterDropdownListRadioSetting[]>state.user?.settings.timecardHeaderfilters;
  }

  @Selector([UserState])
  static projectPlaningFilterSettings(state: UserStateModel): ProjectPlanningFiltersSettingsModel | null {
    return <ProjectPlanningFiltersSettingsModel>state.user?.settings.projectPlanningHeaderFilters;
  }

  @Selector([UserState])
  static projectPlanningCompactFilter(state: UserStateModel): boolean {
    return state.user?.settings.projectPlanningCompactFilter || false;
  }
}
