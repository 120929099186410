import moment from 'moment';
import { LevelImportListDto } from '../../interfaces/dtos/level-import-list.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { LevelImportListDtoMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { MilestoneImportDtoModel } from './milestone-import.dto.model';
import { ApiModelNew } from '../types';
import { isEmpty } from '../../../helpers/utilities';

export class LevelImportListDtoModel implements ApiModelNew {
  constructor(
    readonly levelId: number | undefined,
    readonly codePath: string = '',
    readonly levelNumber: number,
    readonly title: string = '',
    readonly responsibleEngineer: UserSlimDtoModel | undefined,
    readonly projectNumberForSubmitterSettings: string | undefined,
    readonly activityItemCategoryNumberForSubmitterSettings: string | undefined,
    readonly startDate: moment.Moment | undefined,
    readonly endDate: moment.Moment | undefined,
    readonly hours: number = 0,
    readonly hourlyRate: number = 0,
    readonly cost: number | undefined,
    readonly costToComplete: number | undefined,
    readonly sortOrder: number | undefined,
    readonly isHidden: boolean = false,
    readonly isExisting: boolean = false,
    readonly isSelected: boolean = false,
    readonly isDeletedInSap: boolean = false,
    readonly milestones: readonly MilestoneImportDtoModel[],
    readonly children: readonly LevelImportListDtoModel[],
    readonly metadata: MetadataModel<LevelImportListDtoMetadata>,
    readonly projectStatus: string | null = '',
    readonly projectUserStatus: string | null = '',
    readonly wbsStatus: string | null = '',
    readonly wbsUserStatus: string | null = '',
  ) {
    this.startDate = (startDate != null ? startDate.clone() : startDate);
    this.endDate = (endDate != null ? endDate.clone() : endDate);
    this.milestones = [...milestones];
    this.children = [...children];

    // This is a quick fix to sanitize the data coming from the backend.
    // Levels can't be de-selected if they have children which are selected.
    if (this.children.length > 0) {
      this.isSelected = this.children.some((child) => child.isSelected);
    }
  }

  static fromJSON(json: LevelImportListDto): LevelImportListDtoModel {
    return new LevelImportListDtoModel(
      (!isEmpty(json.levelId)
        ? json.levelId
        : undefined
      ),
      (json.codePath ?? undefined),
      (json.levelNumber ?? 0),
      json.title,
      (json.responsibleEngineer
        ? UserSlimDtoModel.fromJSON(json.responsibleEngineer)
        : undefined
      ),
      json.projectNumberForSubmitterSettings,
      json.activityItemCategoryNumberForSubmitterSettings,
      (json.startDate
        ? moment(json.startDate)
          .parseZone()
        : undefined
      ),
      (json.endDate
        ? moment(json.endDate)
          .parseZone()
        : undefined
      ),
      json.hours,
      (json.hourlyRate
        ? json.hourlyRate
        : undefined
      ),
      (json.cost
        ? json.cost
        : undefined
      ),
      (json.costToComplete
        ? json.costToComplete
        : undefined
      ),
      (json.sortOrder
        ? json.sortOrder
        : undefined
      ),
      json.isHidden,
      json.isExisting,
      json.isSelected,
      json.isDeletedInSap,
      (Array.isArray(json.milestones) ? json.milestones : [])
        .map((item) => MilestoneImportDtoModel.fromJSON(item)),
      (Array.isArray(json.children) ? json.children : [])
        .map((item) => LevelImportListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
      json.projectStatus,
      json.projectUserStatus,
      json.wbsStatus,
      json.wbsUserStatus,
    );
  }

  static fromSimilarObject(model: LevelImportListDtoModel): LevelImportListDtoModel {
    return new LevelImportListDtoModel(
      (!isEmpty(model.levelId)
        ? model.levelId
        : undefined
      ),
      (model.codePath ?? undefined),
      (model.levelNumber ?? 0),
      (!isEmpty(model.title)
        ? model.title
        : undefined
      ),
      (!isEmpty(model.responsibleEngineer)
        ? model.responsibleEngineer.clone()
        : undefined
      ),
      (!isEmpty(model.projectNumberForSubmitterSettings)
        ? model.projectNumberForSubmitterSettings
        : undefined
      ),
      (!isEmpty(model.activityItemCategoryNumberForSubmitterSettings)
        ? model.activityItemCategoryNumberForSubmitterSettings
        : undefined
      ),
      (!isEmpty(model.startDate)
        ? model.startDate.clone()
        : undefined
      ),
      (!isEmpty(model.endDate)
        ? model.endDate.clone()
        : undefined
      ),
      (!isEmpty(model.hours)
        ? model.hours
        : undefined
      ),
      (!isEmpty(model.hourlyRate)
        ? model.hourlyRate
        : undefined
      ),
      (!isEmpty(model.cost)
        ? model.cost
        : undefined
      ),
      (!isEmpty(model.costToComplete)
        ? model.costToComplete
        : undefined
      ),
      (!isEmpty(model.sortOrder)
        ? model.sortOrder
        : undefined
      ),
      (!isEmpty(model.isHidden)
        ? model.isHidden
        : undefined
      ),
      (!isEmpty(model.isExisting)
        ? model.isExisting
        : undefined
      ),
      (!isEmpty(model.isSelected)
        ? model.isSelected
        : undefined
      ),
      (!isEmpty(model.isDeletedInSap)
        ? model.isDeletedInSap
        : undefined
      ),
      (Array.isArray(model.milestones)
        ? model.milestones
          .map((item) => item.clone())
        : []
      ),
      (Array.isArray(model.children)
        ? model.children
          .map((item) => item.clone())
        : []
      ),
      model.metadata.clone(),
      (!isEmpty(model.projectStatus)
        ? model.projectStatus
        : null
      ),
      (!isEmpty(model.projectUserStatus)
        ? model.projectUserStatus
        : null
      ),
      (!isEmpty(model.wbsStatus)
        ? model.wbsStatus
        : null
      ),
      (!isEmpty(model.wbsUserStatus)
        ? model.wbsUserStatus
        : null
      ),
    );
  }

  clone(overrides?: Partial<LevelImportListDtoModel>): LevelImportListDtoModel {
    return new LevelImportListDtoModel(
      (overrides && overrides.levelId !== undefined
        ? overrides.levelId
        : this.levelId
      ),
      (overrides?.codePath ?? this.codePath),
      (overrides && overrides.levelNumber !== undefined
        ? overrides.levelNumber
        : this.levelNumber
      ),
      (overrides && overrides.title !== undefined
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.responsibleEngineer !== undefined
        ? overrides.responsibleEngineer
        : this.responsibleEngineer
      ),
      (overrides && overrides.projectNumberForSubmitterSettings !== undefined
        ? overrides.projectNumberForSubmitterSettings
        : this.projectNumberForSubmitterSettings
      ),
      (overrides && overrides.activityItemCategoryNumberForSubmitterSettings !== undefined
        ? overrides.activityItemCategoryNumberForSubmitterSettings
        : this.activityItemCategoryNumberForSubmitterSettings
      ),
      (overrides && overrides.startDate !== undefined
        ? overrides.startDate
        : this.startDate
      ),
      (overrides && overrides.endDate !== undefined
        ? overrides.endDate
        : this.endDate
      ),
      (overrides && overrides.hours !== undefined
        ? overrides.hours
        : this.hours
      ),
      (overrides && overrides.hourlyRate !== undefined
        ? overrides.hourlyRate
        : this.hourlyRate
      ),
      (overrides && overrides.cost !== undefined
        ? overrides.cost
        : this.cost
      ),
      (overrides && overrides.costToComplete !== undefined
        ? overrides.costToComplete
        : this.costToComplete
      ),
      (overrides && overrides.sortOrder !== undefined
        ? overrides.sortOrder
        : this.sortOrder
      ),
      (overrides && overrides.isHidden !== undefined
        ? overrides.isHidden
        : this.isHidden
      ),
      (overrides && overrides.isExisting !== undefined
        ? overrides.isExisting
        : this.isExisting
      ),
      (overrides && overrides.isSelected !== undefined
        ? overrides.isSelected
        : this.isSelected
      ),
      (overrides && overrides.isDeletedInSap !== undefined
        ? overrides.isDeletedInSap
        : this.isDeletedInSap
      ),
      (overrides && overrides.milestones !== undefined
        ? overrides.milestones
        : this.milestones
      ),
      (overrides && overrides.children !== undefined
        ? overrides.children
        : this.children
      ),
      (overrides && overrides.metadata !== undefined
        ? overrides.metadata
        : this.metadata
      ),
      (overrides && overrides.projectStatus !== undefined
        ? overrides.projectStatus
        : this.projectStatus
      ),
      (overrides && overrides.projectUserStatus !== undefined
        ? overrides.projectUserStatus
        : this.projectUserStatus
      ),
      (overrides && overrides.wbsStatus !== undefined
        ? overrides.wbsStatus
        : this.wbsStatus
      ),
      (overrides && overrides.wbsUserStatus !== undefined
        ? overrides.wbsUserStatus
        : this.wbsUserStatus
      ),
    );
  }

  toJSON(): LevelImportListDto {
    return {
      levelId: this.levelId,
      codePath: this.codePath,
      levelNumber: this.levelNumber,
      title: this.title,
      responsibleEngineer: (this.responsibleEngineer ? this.responsibleEngineer.toJSON() : null),
      projectNumberForSubmitterSettings: this.projectNumberForSubmitterSettings,
      activityItemCategoryNumberForSubmitterSettings: this.activityItemCategoryNumberForSubmitterSettings,
      startDate: (this.startDate ? this.startDate.toJSON() : null),
      endDate: (this.endDate ? this.endDate.toJSON() : null),
      hours: this.hours,
      hourlyRate: this.hourlyRate,
      cost: this.cost,
      costToComplete: this.costToComplete,
      isHidden: this.isHidden,
      isExisting: this.isExisting,
      isSelected: this.isSelected,
      isDeletedInSap: this.isDeletedInSap,
      milestones: this.milestones.map((item) => item.toJSON()),
      children: this.children.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
      projectStatus: this.projectStatus,
      projectUserStatus: this.projectUserStatus,
      wbsStatus: this.wbsStatus,
      wbsUserStatus: this.wbsUserStatus,
    };
  }
}
