import { UnitSlimDto } from '../../interfaces/dtos/unit-slim.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { UnitLevel } from '../../../models/unit-level.enum';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitSlimDtoModel implements ApiModelNew {
  constructor(
    readonly unitId: number,
    readonly code: string,
    readonly name: string,
    readonly level: UnitLevel,
    readonly unitCoordinator: UserSlimDtoModel | null = null,
    readonly importPunchedHoursFromEtime: boolean,
    readonly directUserSubmissionOfTimecard: boolean,
    readonly migratedToEtime: boolean,
    readonly migratedToReiwa: boolean,
  ) {
  }

  static fromJSON(json: UnitSlimDto): UnitSlimDtoModel {
    assertRequiredProperties(json, [
      'unitId',
      'code',
      'name',
      'level',
    ]);

    return new UnitSlimDtoModel(
      json.unitId,
      json.code,
      json.name,
      json.level,
      (json.unitCoordinator ? UserSlimDtoModel.fromJSON(json.unitCoordinator) : null),
      json.importPunchedHoursFromEtime,
      json.directUserSubmissionOfTimecard,
      json.migratedToEtime,
      json.migratedToReiwa,
    );
  }

  static fromSimilarModel(model: Partial<UnitSlimDtoModel>): UnitSlimDtoModel {
    assertRequiredProperties(model, [
      'unitId',
      'code',
      'name',
      'level',
      'importPunchedHoursFromEtime',
      'directUserSubmissionOfTimecard',
      'migratedToEtime',
      'migratedToReiwa',
    ]);

    return new UnitSlimDtoModel(
      model.unitId,
      model.code,
      model.name,
      model.level,
      (model.unitCoordinator ? model.unitCoordinator.clone() : null),
      model.importPunchedHoursFromEtime,
      model.directUserSubmissionOfTimecard,
      model.migratedToEtime,
      model.migratedToReiwa,
    );
  }

  clone(): UnitSlimDtoModel {
    return new UnitSlimDtoModel(
      this.unitId,
      this.code,
      this.name,
      this.level,
      this.unitCoordinator,
      this.importPunchedHoursFromEtime,
      this.directUserSubmissionOfTimecard,
      this.migratedToEtime,
      this.migratedToReiwa,
    );
  }

  toJSON(): UnitSlimDto {
    return {
      unitId: this.unitId,
      code: this.code,
      name: this.name,
      level: this.level,
      unitCoordinator: (this.unitCoordinator ? this.unitCoordinator.toJSON() : null),
      importPunchedHoursFromEtime: this.importPunchedHoursFromEtime,
      directUserSubmissionOfTimecard: this.directUserSubmissionOfTimecard,
      migratedToEtime: this.migratedToEtime,
      migratedToReiwa: this.migratedToReiwa,
    };
  }
}
