import { UnitCoordinatorResponse } from '../../interfaces/responses/unit-coordinator.response';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';

export class UnitCoordinatorResponseModel {
  constructor(
    readonly coordinators: readonly UserSlimDtoModel[],
  ) {
    this.coordinators = [...coordinators];
  }

  static fromJSON(json: UnitCoordinatorResponse): UnitCoordinatorResponseModel {
    return new UnitCoordinatorResponseModel(
      (Array.isArray(json.coordinators) ? json.coordinators : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
    );
  }
}
