import {
  ChangeDetectionStrategy,
  Component, HostBinding, Input, ViewEncapsulation,
} from '@angular/core';

/**
 * CSS Class Prefix
 */
const ALERT_CLASS = 'collapp-alert';

@Component({
  selector: 'collapp-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input()
  type?: string;

  @Input()
  icon?: string;

  /**
   * Classes set from outside/context.
   */
  @Input()
  class?: string;

  @HostBinding('class')
  get className(): string {
    let className = ALERT_CLASS;

    if (this.type != null) {
      className = `${className} ${ALERT_CLASS}--${this.type}`;
    }

    if (this.class != null) {
      className = `${className} ${this.class}`;
    }

    return className;
  }
}
