import { Pipe, PipeTransform } from '@angular/core';
import { getFileExtensionFromFilename } from '../../helpers/file-type.utility';

@Pipe({
  name: 'fileExtension',
})
export class FileExtensionPipe implements PipeTransform {
  transform(value: string): string {
    return getFileExtensionFromFilename(value);
  }
}
