import { Selector } from '@ngxs/store';
import { NonHumanResourcePlanningListStateModel } from './non-human-resource-planning-list-state.model';
import { NonHumanResourcePlanningListOptions } from './non-human-resource-planning-list.actions';
import { NonHumanResourcePlanningListState } from './non-human-resource-planning-list.state';

export class NonHumanResourcePlanningListSelectors {
  @Selector([NonHumanResourcePlanningListState])
  static selectedColumns(state: NonHumanResourcePlanningListStateModel): NonHumanResourcePlanningListStateModel['selectedColumns'] {
    return state.selectedColumns;
  }

  @Selector([NonHumanResourcePlanningListState])
  static options(state: NonHumanResourcePlanningListStateModel): NonHumanResourcePlanningListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: state.columnFilters,
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
