import { SkillCategoryWithSkillsDtoModel } from '../dtos/skill-category-with-skills.dto.model';
import { SkillsResponse } from '../../interfaces/responses/skills.response';
import { UnitSkillDtoModel } from '../dtos/unit-skill.dto.model';

export class SkillsResponseModel {
  constructor(
    readonly categories: SkillCategoryWithSkillsDtoModel[],
    readonly unit: UnitSkillDtoModel | null,
  ) {
    this.categories = categories.map((category) => category.clone());
  }

  static fromJSON(json: SkillsResponse): SkillsResponseModel {
    return new SkillsResponseModel(
      json.categories.map(SkillCategoryWithSkillsDtoModel.fromJSON),
      json.unit ? UnitSkillDtoModel.fromJSON(json.unit) : null,
    );
  }
}
