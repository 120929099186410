import { assertRequiredProperties } from '../../utilities/api.utility';
import { ActivityTypeDto } from '../../interfaces/dtos/unit-cost-center-activity-type.dto';

export class ActivityTypeModel implements ActivityTypeDto {
  constructor(
    readonly activityTypeCode: string,
    readonly activityTypeDescription: string,
  ) {}

  get name(): string {
    return this.activityTypeCode;
  }

  get description(): string {
    return this.activityTypeDescription;
  }

  static fromJSON(json: ActivityTypeDto): ActivityTypeModel {
    assertRequiredProperties(json, [
        'activityTypeCode',
    ]);

    return new ActivityTypeModel(
      json.activityTypeCode,
      json.activityTypeDescription,
    );
  }

  toJSON(): ActivityTypeDto {
    return {
      activityTypeCode: this.activityTypeCode,
      activityTypeDescription: this.activityTypeDescription,
    };
  }
}
