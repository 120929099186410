/**
 * A list of filter combinations for the dashboard work packages.
 */
export enum WorkPackageCombinedFilterType {
  TO_BE_ISSUED = 1,
  IN_PROGRESS = 2,
  AWAITING_ACTION = 3,
  TO_BE_CLOSED = 4,
  TO_BE_CONFIRMED_IN_MY_UNIT = 5,
}
