import { UserSlimDtoModel } from './user-slim.dto.model';
import { ContractDto } from '../../interfaces/dtos/contract.dto';
import { ContractHourlyRateCategoryPairDtoModel } from './contract-hourly-rate-category-pair.dto.model';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { WorkPackageViewResponseModel } from '../responses/work-package-view.response.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CostObjectDtoModel } from './costObject.dto.model';

export class ContractDtoModel {
  readonly supplierSubmitterPairLabel: string;

  constructor(
    readonly contractId: number | null = null,
    readonly submitterUnit: UnitSlimDtoModel | null = null,
    readonly supplierUnit: UnitSlimDtoModel | null = null,
    readonly model: string | null = null,
    readonly costObject: string | null | CostObjectDtoModel = null,
    readonly categoryActivityItem: string | null = null,
    public contractHourlyRateCategoryPairs: ContractHourlyRateCategoryPairDtoModel[] = [],
    readonly submitterEmployeeNumber: string | null = null,
    readonly costCenter: string | null = null,
    readonly usersNotificationSentTo: UserSlimDtoModel[] = [],
  ) {
    this.contractHourlyRateCategoryPairs = [...contractHourlyRateCategoryPairs];
    this.usersNotificationSentTo = [...usersNotificationSentTo];

    this.supplierSubmitterPairLabel = `${this.supplierUnit ? this.supplierUnit.name : 'unknown'} (${this.submitterUnit ? this.submitterUnit.name : 'unknown'})`;
  }

  static fromJSON(json: ContractDto): ContractDtoModel {
    assertRequiredProperties(json, [
      // 'contractId',
    ]);

    return new ContractDtoModel(
      json.contractId,
      (json.submitterUnit
        ? UnitSlimDtoModel.fromJSON(json.submitterUnit)
        : null
      ),
      (json.supplierUnit
        ? UnitSlimDtoModel.fromJSON(json.supplierUnit)
        : null
      ),
      json.model,
      json.costObject,
      json.categoryActivityItem,
      (Array.isArray(json.contractHourlyRateCategoryPairs) ? json.contractHourlyRateCategoryPairs : [])
        .map((item) => ContractHourlyRateCategoryPairDtoModel.fromJSON(item)),
      json.submitterEmployeeNumber,
      json.submitterCostCenter,
      (Array.isArray(json.usersNotificationSentTo) ? json.usersNotificationSentTo : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
    );
  }

  /**
   * Looks if the properties contractSubmitterUnit and supplierUnit of a
   * work package match with the provided contract.
   *
   * @param contract
   * @param workPackage
   */
  static matchesContract(contract: ContractDtoModel, workPackage: WorkPackageViewResponseModel): boolean {
    if (workPackage.contractSubmitterUnit == null || workPackage.contractSupplierUnit == null) {
      return false;
    }

    if (contract.submitterUnit == null || contract.submitterUnit.unitId !== workPackage.contractSubmitterUnit.unitId) {
      return false;
    }

    if (contract.supplierUnit == null || contract.supplierUnit.unitId !== workPackage.contractSupplierUnit.unitId) {
      return false;
    }

    return true;
  }

  clone(): ContractDtoModel {
    return new ContractDtoModel(
      this.contractId,
      this.submitterUnit ? this.submitterUnit.clone() : null,
      this.supplierUnit ? this.supplierUnit.clone() : null,
      this.model,
      this.costObject,
      this.categoryActivityItem,
      this.contractHourlyRateCategoryPairs.map((item) => item.clone()),
      this.submitterEmployeeNumber,
      this.costCenter,
      this.usersNotificationSentTo,
    );
  }

  toJSON(): ContractDto {
    if (this.costObject instanceof CostObjectDtoModel) {
      throw new Error('CostObjectDtoModel is not supported in toJSON');
    }

    return {
      contractId: this.contractId,
      submitterUnit: (this.submitterUnit
        ? this.submitterUnit.toJSON()
        : null
      ),
      supplierUnit: (this.supplierUnit
        ? this.supplierUnit.toJSON()
        : null
      ),
      model: this.model,
      costObject: this.costObject,
      categoryActivityItem: this.categoryActivityItem,
      contractHourlyRateCategoryPairs: this.contractHourlyRateCategoryPairs.map((item) => item.toJSON()),
      submitterEmployeeNumber: this.submitterEmployeeNumber,
      submitterCostCenter: this.costCenter,
      usersNotificationSentTo: this.usersNotificationSentTo.map((item) => item.toJSON()),
    };
  }
}
