import moment from 'moment';
import { WorkPackageViewResponse } from '../../interfaces/responses/work-package-view.response';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { MetadataModel } from '../metadata.model';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { ScopeChangeDtoModel } from '../dtos/scope-change.dto.model';
import { AttachmentSlimDtoModel } from '../dtos/attachment-slim.dto.model';
import { WorkPackageViewResponseMetadata } from '../../interfaces/metadata';
import { ResponsibleUserWorkPackageViewDtoModel } from '../dtos/responsible-user-work-package-view-dto.model';
import { SuspensionDtoModel } from '../dtos/suspension.dto.model';
import { DocumentType } from '../../../models/document-type.enum';
import { FeedbackDtoModel } from '../dtos/feedback.dto.model';
import { KpiDtoModel } from '../dtos/kpi.dto.model';
import { WorkPackageConclusion } from '../../../models/work-package-conclusion.enum';
import { WorkPackageReworkDtoModel } from '../dtos/work-package-rework.dto.model';
import { WorkPackageLogicHelper } from '../../../helpers/work-package-logic-helper';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { LevelSlimDtoModel } from '../dtos/level-slim.dto.model';
import { ResponsibleResourceDtoModel } from '../dtos/responsible-resource-dto.model';
import { ProjectSlimResponseModel } from './project-slim.response.model';
import { ContractHourlyRateCategoryPairDtoModel } from '../dtos/contract-hourly-rate-category-pair.dto.model';

// TODO: I am not a fan of all those accessors since they force angular to check more
export class WorkPackageViewResponseModel {
  // eslint-disable-next-line max-lines-per-function
  constructor(
    readonly additionalSuppplierHourlyRateCategories: ContractHourlyRateCategoryPairDtoModel[] = [],
    readonly workPackageId: number,
    readonly sequenceNumber: number,
    readonly projectId: number,
    readonly project: ProjectSlimResponseModel,
    readonly level: LevelSlimDtoModel | null,
    readonly title: string,
    readonly estimatedStartDate: moment.Moment,
    readonly estimatedEndDate: moment.Moment,
    readonly estimatedHours: number = 0,
    readonly latestEstimatedStartDate: moment.Moment,
    readonly latestEstimatedEndDate: moment.Moment,
    readonly latestEstimatedHours: number = 0,
    readonly confirmedStartDate: moment.Moment | undefined,
    readonly confirmedEndDate: moment.Moment | null = null,
    // TODO: `confirmedHours` is nullable in the API but we use '0' as default in the FE
    readonly confirmedHours: number = 0,
    readonly latestConfirmedEndDate: moment.Moment | null = null,
    // TODO: `latestConfirmedHours` is nullable in the API but we use '0' as default in the FE
    readonly latestConfirmedHours: number = 0,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly latestPlannedEndDate: moment.Moment | null = null,
    readonly plannedEndDate: moment.Moment | null = null,
    // TODO: `plannedHours` is nullable in the API but we use '0' as default in the FE
    readonly plannedHours: number = 0,
    readonly costToComplete: number = 0,
    readonly responsibleUsers: readonly ResponsibleUserWorkPackageViewDtoModel[] = [],
    readonly plannedNonHumanResources: readonly ResponsibleResourceDtoModel[] = [],
    readonly createdBy: UserSlimDtoModel | null = null,
    readonly submitterUnit: UnitSlimDtoModel | null = null,
    readonly submitterUnitCoordinator: UserSlimDtoModel | null = null,
    readonly submitterUnitDeputyUnitCoordinators: readonly UserSlimDtoModel[] = [],
    readonly contractSubmitterUnit: UnitSlimDtoModel | null = null,
    readonly supplierUnit: UnitSlimDtoModel | null = null,
    readonly contractSupplierUnit: UnitSlimDtoModel | null = null,
    readonly contractId: number | null = null,
    readonly supplierUnitCoordinator: UserSlimDtoModel | null = null,
    readonly supplierUnitDeputyUnitCoordinators: readonly UserSlimDtoModel[] = [],
    readonly submitterProjectTypeCode: string | null = null,
    readonly submitterProjectNumber: string | null = null,
    readonly submitterHourlyRateCategories: string[] | null = null,
    readonly submitterActivityItemCategoryNumber: string | null = null,
    readonly supplierProjectTypeCode: string | null = null,
    readonly supplierProjectNumber: string | null = null,
    readonly supplierHourlyRateCategories: string[] | null = null,
    readonly supplierActivityItemCategoryNumber: string | null = null,
    readonly scope: string | null = null,
    readonly status: WorkPackageStatus = WorkPackageStatus.Draft,
    readonly timezone: string,
    readonly timezoneOffset: number,
    readonly conclusion: WorkPackageConclusion | null = null,
    readonly openQuestionsCount: number = 0,
    readonly hasEverBeenInForecastedStatus: boolean = false,
    readonly hasEverBeenInIssuedStatus: boolean = false,
    readonly isSelfAssigned: boolean = false,
    readonly kpis: KpiDtoModel | null,
    readonly submitterFeedbacks: readonly FeedbackDtoModel[] = [],
    readonly supplierFeedbacks: readonly FeedbackDtoModel[] = [],
    readonly attachments: readonly AttachmentSlimDtoModel[] = [],
    readonly scopeChanges: readonly ScopeChangeDtoModel[] = [],
    readonly reworks: readonly WorkPackageReworkDtoModel[] = [],
    readonly suspensions: readonly SuspensionDtoModel[] = [],
    readonly hourlyRateCategories: ContractHourlyRateCategoryPairDtoModel[] = [],
    readonly metadata: MetadataModel<WorkPackageViewResponseMetadata> = new MetadataModel(),
    // TODO: `plannedHoursNotIncludedInTotal` is nullable in the API but we use '0' as default in the FE
    readonly plannedHoursNotIncludedInTotal: number = 0,
  ) {
    this.estimatedStartDate = (
      estimatedStartDate != null
        ? estimatedStartDate.clone()
        : estimatedStartDate
    );
    this.estimatedEndDate = (
      estimatedEndDate != null
        ? estimatedEndDate.clone()
        : estimatedEndDate
    );
    this.latestEstimatedStartDate = (
      latestEstimatedStartDate != null
        ? latestEstimatedStartDate.clone()
        : latestEstimatedStartDate
    );
    this.latestEstimatedEndDate = (
      latestEstimatedEndDate != null
        ? latestEstimatedEndDate.clone()
        : latestEstimatedEndDate
    );
    this.confirmedStartDate = (
      confirmedStartDate != null
        ? confirmedStartDate.clone()
        : confirmedStartDate
    );
    this.confirmedEndDate = (
      confirmedEndDate != null
        ? confirmedEndDate.clone()
        : confirmedEndDate
    );
    this.latestConfirmedEndDate = (
      latestConfirmedEndDate != null
        ? latestConfirmedEndDate.clone()
        : latestConfirmedEndDate
    );
    this.plannedStartDate = (
      plannedStartDate != null
        ? plannedStartDate.clone()
        : plannedStartDate
    );
    this.latestPlannedEndDate = (
      latestPlannedEndDate != null
        ? latestPlannedEndDate.clone()
        : latestPlannedEndDate
    );
    this.plannedEndDate = (
      plannedEndDate != null
        ? plannedEndDate.clone()
        : plannedEndDate
    );

    this.responsibleUsers = [...responsibleUsers];
    this.plannedNonHumanResources = [...plannedNonHumanResources];
    this.submitterUnitDeputyUnitCoordinators = [...submitterUnitDeputyUnitCoordinators];
    this.supplierUnitDeputyUnitCoordinators = [...supplierUnitDeputyUnitCoordinators];
    this.submitterFeedbacks = [...submitterFeedbacks];
    this.supplierFeedbacks = [...supplierFeedbacks];
    this.attachments = [...attachments];

    this.scopeChanges = [...scopeChanges]
      // The ScopeChanges should already be sorted in descending order - just making sure that they really are.
      .sort((a, b) => b.createdOn.diff(a.createdOn));

    this.reworks = [...reworks]
      // The Rework entries should already be sorted in descending order - just making sure that they really are.
      .sort((a, b) => b.createdOn.diff(a.createdOn));

    this.suspensions = [...suspensions]
      // The Suspension should already be sorted in descending order - just making sure that they really are.
      .sort((a, b) => b.createdOn.diff(a.createdOn));
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static fromJSON(json: WorkPackageViewResponse): WorkPackageViewResponseModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'sequenceNumber',
      'projectId',
      // 'level',
      'title',
      'estimatedStartDate',
      'estimatedEndDate',
      'estimatedHours',
      'latestEstimatedStartDate',
      'latestEstimatedEndDate',
      'latestEstimatedHours',

      'costToComplete',
      'status',
      'timezone',
      'timezoneOffset',
      'hourlyRateCategories',
      // 'kpis',
    ]);

    return new WorkPackageViewResponseModel(
      (Array.isArray(json.additionalSuppplierHourlyRateCategories) ? json.additionalSuppplierHourlyRateCategories : [])
        .map((item) => ContractHourlyRateCategoryPairDtoModel.fromJSON(item)),
      json.workPackageId,
      json.sequenceNumber,
      json.projectId,
      ProjectSlimResponseModel.fromJSON(json.project),
      (json.level != null
        ? LevelSlimDtoModel.fromJSON(json.level)
        : null
      ),
      json.title,
      moment(json.estimatedStartDate)
        .parseZone(),
      moment(json.estimatedEndDate)
        .parseZone(),
      json.estimatedHours,
      moment(json.latestEstimatedStartDate)
        .parseZone(),
      moment(json.latestEstimatedEndDate)
        .parseZone(),
      json.latestEstimatedHours,
      (json.confirmedStartDate != null
        ? moment(json.confirmedStartDate)
          .parseZone()
        : undefined
      ),
      (json.confirmedEndDate != null
        ? moment(json.confirmedEndDate)
          .parseZone()
        : null
      ),
      json.confirmedHours || 0,
      (json.latestConfirmedEndDate != null
        ? moment(json.latestConfirmedEndDate)
          .parseZone()
        : null
      ),
      json.latestConfirmedHours || 0,
      (json.plannedStartDate != null
        ? moment(json.plannedStartDate)
          .parseZone()
        : null
      ),
      (json.latestPlannedEndDate != null
        ? moment(json.latestPlannedEndDate)
          .parseZone()
        : null
      ),
      (json.plannedEndDate != null
        ? moment(json.plannedEndDate)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.costToComplete,
      (Array.isArray(json.responsibleUsers) ? json.responsibleUsers : [])
        .map((item) => ResponsibleUserWorkPackageViewDtoModel.fromJSON(item)),
      (Array.isArray(json.plannedNonHumanResources) ? json.plannedNonHumanResources : [])
        .map((item) => ResponsibleResourceDtoModel.fromJSON(item)),
      (json.createdBy != null
        ? UserSlimDtoModel.fromJSON(json.createdBy)
        : null
      ),
      (json.submitterUnit != null
        ? UnitSlimDtoModel.fromJSON(json.submitterUnit)
        : null
      ),
      (json.submitterUnitCoordinator != null
        ? UserSlimDtoModel.fromJSON(json.submitterUnitCoordinator)
        : null
      ),
      (Array.isArray(json.submitterUnitDeputyUnitCoordinators) ? json.submitterUnitDeputyUnitCoordinators : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
      (json.contractSubmitterUnit != null
        ? UnitSlimDtoModel.fromJSON(json.contractSubmitterUnit)
        : null
      ),
      (json.supplierUnit != null
        ? UnitSlimDtoModel.fromJSON(json.supplierUnit)
        : null
      ),
      (json.contractSupplierUnit != null
        ? UnitSlimDtoModel.fromJSON(json.contractSupplierUnit)
        : null
      ),
      json.contractId,
      (json.supplierUnitCoordinator != null
        ? UserSlimDtoModel.fromJSON(json.supplierUnitCoordinator)
        : null
      ),
      (Array.isArray(json.supplierUnitDeputyUnitCoordinators) ? json.supplierUnitDeputyUnitCoordinators : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
      json.submitterProjectTypeCode,
      json.submitterProjectNumber,
      // json.submitterHourlyRateCategory,
      (Array.isArray(json.submitterHourlyRateCategories) ? json.submitterHourlyRateCategories : []),
      json.submitterActivityItemCategoryNumber,
      json.supplierProjectTypeCode,
      json.supplierProjectNumber,
      // json.supplierHourlyRateCategories,
      (Array.isArray(json.supplierHourlyRateCategories) ? json.supplierHourlyRateCategories : []),
      json.supplierActivityItemCategoryNumber,
      json.scope,
      json.status,
      json.timezone,
      json.timezoneOffset,
      json.conclusion,
      json.openQuestionsCount,
      json.hasEverBeenInForecastedStatus,
      json.hasEverBeenInIssuedStatus,
      json.isSelfAssigned,
      (json.kpis != null
        ? KpiDtoModel.fromJSON(json.kpis)
        : null
      ),
      (Array.isArray(json.submitterFeedbacks) ? json.submitterFeedbacks : [])
        .map((item) => FeedbackDtoModel.fromJSON(item)),
      (Array.isArray(json.supplierFeedbacks) ? json.supplierFeedbacks : [])
        .map((item) => FeedbackDtoModel.fromJSON(item)),
      (Array.isArray(json.attachments) ? json.attachments : [])
        .map((item) => AttachmentSlimDtoModel.fromJSON(item)),
      (Array.isArray(json.scopeChanges) ? json.scopeChanges : [])
        .map((item) => ScopeChangeDtoModel.fromJSON(item)),
      (Array.isArray(json.reworks) ? json.reworks : [])
        .map((item) => WorkPackageReworkDtoModel.fromJSON(item)),
      (Array.isArray(json.suspensions) ? json.suspensions : [])
        .map((item) => SuspensionDtoModel.fromJSON(item)),
      (Array.isArray(json.hourlyRateCategories) ? json.hourlyRateCategories : [])
        .map((item) => ContractHourlyRateCategoryPairDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}),
      json.plannedHoursNotIncludedInTotal || 0,
    );
  }

  static getLevelPath(levelId?: number, structure: readonly LevelSlimDtoModel[] = []): LevelSlimDtoModel[] {
    if (!levelId || structure.length === 0) {
      return [];
    }

    const structureLen = structure.length;
    let levelPath: LevelSlimDtoModel[] = [];
    let i = 0;

    // Look for work package in level and receive the path
    while (levelPath.length === 0 && i < structureLen) {
      levelPath = structure[i].getLevelPath(levelId);
      i += 1;
    }

    return levelPath;
  }

  /**
   * Gets the attachment that were uploaded when the work package was created/edited.
   */
  getOriginalAttachments(includeOutdatedAttachments: boolean): AttachmentSlimDtoModel[] {
    return this.attachments.filter((attachment) => attachment.documentType === DocumentType.WorkPackage
      && (
        includeOutdatedAttachments || attachment.outdatedOn == null
      ));
  }

  /**
   * Gets the attachment that were uploaded in scope changes.
   */
  getScopeChangeAttachments(includeOutdatedAttachments: boolean): AttachmentSlimDtoModel[] {
    const attachments: AttachmentSlimDtoModel[] = [];

    this.scopeChanges.forEach((scope) => {
      const relevantAttachments = scope.attachments
        .filter((attachment) => attachment.documentType === DocumentType.ScopeChange
          && (
            includeOutdatedAttachments || attachment.outdatedOn == null
          ));

      attachments.push(...relevantAttachments);
    });

    return attachments;
  }

  /**
   * Checks if work package is allowed to be edited.
   */
  canEdit(): boolean {
    return this.metadata.fields && this.metadata.fields.canEdit;
  }

  /**
   * Checks if a Creator is allowed to be edited.
   */
  canUpdateCreator(): boolean {
    return this.metadata.fields && this.metadata.fields.canUpdateCreator;
  }

  /**
   * Submitter Unit coordinator (and deputies), they can edit the WP at any state (from DRAFT to READY FOR APPROVAL) to:
   * - change the WP creator to another person
   * - upload new documents to the Scope
   */
  canEditThroughLifeCycle(): boolean {
    return this.metadata.fields && this.metadata.fields.canEditThroughLifeCycle;
  }

  /**
   * Checks if work package is allowed to be duplicated.
   */
  canDuplicate(): boolean {
    return this.metadata.fields && this.metadata.fields.canDuplicate;
  }

  canMoveWorkPackage(): boolean {
    return this.metadata.fields && this.metadata.fields.canMoveWorkPackage;
  }

  canDelete(): boolean {
    return this.metadata.fields && this.metadata.fields.canDelete;
  }

  canAddAdditionalSupplier(): boolean {
    return this.metadata.fields?.canAddAdditionalSupplier;
  }

  isDeleteEnabled(): boolean {
    return this.metadata.fields && this.metadata.fields.isDeleteEnabled;
  }

  canSendToDraft(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToDraft;
  }

  canSelfAssign(): boolean {
    return this.metadata.fields && this.metadata.fields.canSelfAssign;
  }

  canSendToForecasted(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToForecasted;
  }

  canSendToIssued(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToIssued;
  }

  canSendToQualityAssurance(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToQualityAssurance;
  }

  canShowSendToConfirmed(): boolean {
    return this.metadata.fields && this.metadata.fields.showSendToConfirmed;
  }

  canSendToConfirmed(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToConfirmed;
  }

  canSendToReadyToResumeWork(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToReadyToResumeWork;
  }

  canResumeWork(): boolean {
    return this.metadata.fields && this.metadata.fields.canResumeWork;
  }

  canSendToInProgress(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToInProgress;
  }

  canSendToReadyForApproval(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToReadyForApproval;
  }

  canApproveScopeChange(): boolean {
    return this.metadata.fields && this.metadata.fields.canApproveScopeChange;
  }

  canDeclineScopeChange(): boolean {
    return this.metadata.fields && this.metadata.fields.canDeclineScopeChange;
  }

  canSendToOnHold(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToOnHold;
  }

  canSendToClose(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToClose;
  }

  canSendToScopeChange(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToScopeChange;
  }

  canEditScopeChange(): boolean {
    return this.metadata.fields && this.metadata.fields.canEditScopeChange;
  }

  canCancelScopeChange(): boolean {
    return this.metadata.fields && this.metadata.fields.canCancelScopeChange;
  }

  canSendToReadyForScopeChangeApproval(): boolean {
    return this.metadata.fields && this.metadata.fields.canSendToReadyForScopeChangeApproval;
  }

  canEditSubmitterSettings(): boolean {
    return this.metadata.fields && this.metadata.fields.canEditSubmitterSettings;
  }

  canEditSupplierSettings(): boolean {
    return this.metadata.fields && this.metadata.fields.canEditSupplierSettings;
  }

  canEditResourcePlanning(): boolean {
    return this.metadata.fields && this.metadata.fields.canEditResourcePlanning;
  }

  canUploadOutputDocuments(): boolean {
    return this.metadata.fields && this.metadata.fields.canUploadOutputDocuments;
  }

  canReadSummaryTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadSummaryTab;
  }

  canReadAndEditPlanningTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadAndEditPlanningTab;
  }

  // can read and not write (does not check rights)
  isReadOnlyMode(): boolean {
    return !this.canReadAndEditPlanningTab() && this.canReadPlanningTab();
  }

  canReadPlanningTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadPlanningTab;
  }

  canReadDocumentsTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadDocumentsTab;
  }

  canReadActivityTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadActivityTab;
  }

  canOutdateDocuments(): boolean {
    // return this.metadata.fields && this.metadata.fields.canEditResourcePlanning;
    return true;
  }

  canCreateFeedback(): boolean {
    return this.metadata.fields && this.metadata.fields.canCreateFeedback;
  }

  canCreateSupplierFeedback(): boolean {
    return this.metadata.fields && this.metadata.fields.canCreateSupplierFeedback;
  }

  shouldOpenCloseDialog(): boolean {
    return this.metadata.fields?.shouldOpenCloseDialog;
  }

  /**
   * Whether the user can view the questions tab.
   */
  canReadQuestionsTab(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadQuestionsTab;
  }

  canCreateQuestion(): boolean {
    return this.metadata.fields && this.metadata.fields.canCreateQuestion;
  }

  canReadPlanningInSidebar(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadPlanningInSidebar;
  }

  canReadFeedbackInSidebar(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadFeedbackInSidebar;
  }

  canReadKpisInSidebar(): boolean {
    return this.metadata.fields && this.metadata.fields.canReadKpisInSidebar;
  }

  hasReachedForecasted(including: boolean = true): boolean {
    return including ? this.status >= WorkPackageStatus.Forecasted : this.status > WorkPackageStatus.Forecasted;
  }

  hasReachedIsIssued(): boolean {
    return this.status >= WorkPackageStatus.Issued;
  }

  hasReachedConfirmed(): boolean {
    return this.status >= WorkPackageStatus.Confirmed;
  }

  hasReachedInProgress(): boolean {
    return this.status >= WorkPackageStatus.InProgress;
  }

  hasReachedReadyForApproval(): boolean {
    return this.status >= WorkPackageStatus.ReadyForApproval;
  }

  isInReadyForScopeChangeApproval(): boolean {
    return this.status === WorkPackageStatus.ReadyForScopeChangeApproval;
  }

  isInQualityAssurance(): boolean {
    return this.status === WorkPackageStatus.InQualityAssurance;
  }

  isInForecasted(): boolean {
    return this.status === WorkPackageStatus.Forecasted;
  }

  isInIssued(): boolean {
    return this.status === WorkPackageStatus.Issued;
  }

  isInDraft(): boolean {
    return this.status === WorkPackageStatus.Draft;
  }

  isInConfirmed(): boolean {
    return this.status === WorkPackageStatus.Confirmed;
  }

  isInProgress(): boolean {
    return this.status === WorkPackageStatus.InProgress;
  }

  isInReadyToResumeWork(): boolean {
    return this.status === WorkPackageStatus.ReadyToResumeWork;
  }

  isInOnHold(): boolean {
    return this.status === WorkPackageStatus.OnHold;
  }

  isInClosed(): boolean {
    return this.status === WorkPackageStatus.Closed;
  }

  isAllowedScopeChangeInReadyForApproval(): boolean {
    return this.metadata.fields && this.metadata.fields.allowedScopeChangeInReadyForApproval;
  }

  /**
   * Looks recursively if the work package exists in the project. If the work package was found,
   * the path of levels is returned as an array: [l1, l2, l3, ...]
   *
   * @param levelId
   */
  getLevelPath(levelId: number): LevelSlimDtoModel[] {
    return WorkPackageViewResponseModel.getLevelPath(levelId, this.project.structure);
  }

  /**
   * True if one of the states are
   */
  isInScopeChangeStates(): boolean {
    return this.status === WorkPackageStatus.ScopeChange
      || this.status === WorkPackageStatus.ReadyForScopeChangeApproval;
  }

  /**
   * A schedule is before confirmed, if it is in one of
   * the following states:
   * Draft, Forecasted, Issued
   */
  isBeforeConfirmed(): boolean {
    const states = [
      WorkPackageStatus.Draft,
      WorkPackageStatus.Forecasted,
      WorkPackageStatus.Issued,
    ];

    return states.indexOf(this.status) > -1;
  }

  /**
   * A schedule is newly proposed if it is in one of
   * the following states:
   * ReadyForScopeChangeApproval, ScopeChange, ReadyToResumeWork
   */
  showNewlyProposedSchedule(): boolean {
    const states = [
      WorkPackageStatus.ReadyForScopeChangeApproval,
      WorkPackageStatus.ScopeChange,
      WorkPackageStatus.ReadyToResumeWork,
    ];

    return states.indexOf(this.status) > -1;
  }

  isInReadyForApproval(): boolean {
    return this.status === WorkPackageStatus.ReadyForApproval;
  }

  /**
   * Looks for the work package coordinator in the planned resources.
   */
  getWorkPackageCoordinator(): ResponsibleUserWorkPackageViewDtoModel | undefined {
    if (this.responsibleUsers.length === 0) {
      return undefined;
    }

    return this.responsibleUsers.find((responsibleUser) => responsibleUser.isCoordinator);
  }

  /**
   * Get the newest ScopeChange back
   */
  getLatestSuspension(): SuspensionDtoModel | null {
    if (this.suspensions.length === 0) {
      return null;
    }

    return this.suspensions[0];
  }

  /**
   * Get the newest ScopeChange back
   */
  getLatestScopeChange(): ScopeChangeDtoModel | null {
    if (this.scopeChanges.length === 0) {
      return null;
    }

    return this.scopeChanges[0];
  }

  /**
   * Get the sum of confirmed ours from the WP and all scope changes
   */
  getSumOfConfirmedHours(): number {
    let { confirmedHours } = this;

    this.scopeChanges.forEach((scopeChange) => {
      if (scopeChange.confirmedAdditionalHours != null) {
        confirmedHours += scopeChange.confirmedAdditionalHours;
      }
    });

    return confirmedHours;
  }

  /**
   * Get the sum of confirmed additional hours, which are the difference of the planned hours and the sum
   * of all confirmed additional hours for all scope changes.
   */
  getSumOfConfirmedAdditionalHours(): number {
    return this.plannedHours - this.getSumOfConfirmedHours();
  }

  /**
   * Getter is Planned End Date exceeded
   */
  get isPlannedEndDateExceeded(): boolean {
    return WorkPackageLogicHelper.isPlannedEndDateExceeded(this);
  }

  /**
   * Getter is Planned Hours exceeded
   */
  get arePlannedHoursExceeded(): boolean {
    return WorkPackageLogicHelper.arePlannedHoursExceeded(this);
  }

  get isPlannedEndDateOverdue(): boolean {
    return WorkPackageLogicHelper.isPlannedEndDateOverdue(this);
  }

  /**
   * Merges all names of the responsibles to one string.
   */
  getWorkPackageResponsibleNames(concatenator: string = ', '): string {
    if (this.responsibleUsers.length === 0) {
      return '';
    }

    return this.responsibleUsers
      .map((responsibleUser) => responsibleUser.fullName)
      .join(concatenator);
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  toJSON(): WorkPackageViewResponse {
    return {
      additionalSuppplierHourlyRateCategories: this.additionalSuppplierHourlyRateCategories
        .map((item) => item.toJSON()),
      workPackageId: this.workPackageId,
      sequenceNumber: this.sequenceNumber,
      projectId: this.projectId,
      project: this.project.toJSON(),
      level: (this.level ? this.level.toJSON() : null),
      title: this.title,
      estimatedStartDate: this.estimatedStartDate.toJSON(),
      estimatedEndDate: this.estimatedEndDate.toJSON(),
      estimatedHours: this.estimatedHours,
      latestEstimatedStartDate: this.latestEstimatedStartDate.toJSON(),
      latestEstimatedEndDate: this.latestEstimatedEndDate.toJSON(),
      latestEstimatedHours: this.latestEstimatedHours,
      confirmedStartDate: (this.confirmedStartDate != null ? this.confirmedStartDate.toJSON() : null),
      confirmedEndDate: (this.confirmedEndDate != null ? this.confirmedEndDate.toJSON() : null),
      confirmedHours: this.confirmedHours,
      latestConfirmedEndDate: (this.latestConfirmedEndDate != null ? this.latestConfirmedEndDate.toJSON() : null),
      latestConfirmedHours: this.latestConfirmedHours,
      plannedStartDate: (this.plannedStartDate != null ? this.plannedStartDate.toJSON() : null),
      latestPlannedEndDate: (this.latestPlannedEndDate != null ? this.latestPlannedEndDate.toJSON() : null),
      plannedEndDate: (this.plannedEndDate != null ? this.plannedEndDate.toJSON() : null),
      plannedHours: this.plannedHours,
      costToComplete: this.costToComplete,
      responsibleUsers: this.responsibleUsers.map((item) => item.toJSON()),
      plannedNonHumanResources: this.plannedNonHumanResources.map((item) => item.toJSON()),
      createdBy: (this.createdBy != null ? this.createdBy.toJSON() : null),
      submitterUnit: (this.submitterUnit != null ? this.submitterUnit.toJSON() : null),
      submitterUnitCoordinator: (this.submitterUnitCoordinator != null ? this.submitterUnitCoordinator.toJSON() : null),
      submitterUnitDeputyUnitCoordinators: this.submitterUnitDeputyUnitCoordinators.map((item) => item.toJSON()),
      contractSubmitterUnit: (this.contractSubmitterUnit != null ? this.contractSubmitterUnit.toJSON() : null),
      supplierUnit: (this.supplierUnit != null ? this.supplierUnit.toJSON() : null),
      contractSupplierUnit: (this.contractSupplierUnit != null ? this.contractSupplierUnit.toJSON() : null),
      contractId: this.contractId,
      supplierUnitCoordinator: (this.supplierUnitCoordinator != null ? this.supplierUnitCoordinator.toJSON() : null),
      supplierUnitDeputyUnitCoordinators: this.supplierUnitDeputyUnitCoordinators.map((item) => item.toJSON()),
      submitterProjectTypeCode: this.submitterProjectTypeCode,
      submitterProjectNumber: this.submitterProjectNumber,
      submitterHourlyRateCategories: this.submitterHourlyRateCategories,
      submitterActivityItemCategoryNumber: this.submitterActivityItemCategoryNumber,
      supplierProjectTypeCode: this.supplierProjectTypeCode,
      supplierProjectNumber: this.supplierProjectNumber,
      supplierHourlyRateCategories: this.supplierHourlyRateCategories,
      supplierActivityItemCategoryNumber: this.supplierActivityItemCategoryNumber,
      scope: this.scope,
      status: this.status,
      timezone: this.timezone,
      timezoneOffset: this.timezoneOffset,
      conclusion: this.conclusion,
      openQuestionsCount: this.openQuestionsCount,
      hasEverBeenInForecastedStatus: this.hasEverBeenInForecastedStatus,
      hasEverBeenInIssuedStatus: this.hasEverBeenInIssuedStatus,
      isSelfAssigned: this.isSelfAssigned,
      kpis: this.kpis,
      submitterFeedbacks: this.submitterFeedbacks.map((item) => item.toJSON()),
      supplierFeedbacks: this.supplierFeedbacks.map((item) => item.toJSON()),
      attachments: this.attachments.map((item) => item.toJSON()),
      scopeChanges: this.scopeChanges.map((item) => item.toJSON()),
      reworks: this.reworks.map((item) => item.toJSON()),
      suspensions: this.suspensions.map((item) => item.toJSON()),
      hourlyRateCategories: this.hourlyRateCategories.map((item) => item.toJSON()),
      metadata: this.metadata,
      plannedHoursNotIncludedInTotal: this.plannedHoursNotIncludedInTotal,
    };
  }
}
