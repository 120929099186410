import {
  NgModule, Optional, SkipSelf, ɵstringify as stringify,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CollappMomentDateAdapter } from './moment-date-adapter';
import { COLLAPP_MAT_MOMENT_DATE_FORMATS } from './moment-date-formats';
import { CollappDateAdapter } from '../collapp-core';

export * from './moment-date-adapter';
export * from './moment-date-formats';

@NgModule({
  imports: [
    MatMomentDateModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: COLLAPP_MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: CollappMomentDateAdapter,
      useClass: CollappMomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: CollappDateAdapter,
      useExisting: CollappMomentDateAdapter,
      // deps: [MAT_DATE_LOCALE],
    },
    {
      provide: DateAdapter,
      useExisting: CollappMomentDateAdapter,
      // deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class CollappMomentDateModule {
  constructor(
  @Optional() @SkipSelf() parentModule?: CollappMomentDateModule,
  ) {
    if (parentModule) {
      throw new Error(`${stringify(CollappMomentDateModule)} is already loaded. Import it in the AppModule only.`);
    }
  }
}
