<div class="dates-range__dates"
  [class.work-package-node__dates--warning]="hasDateRangeError"
  [matTooltip]="((startDate | moment) || '') + ' - ' + ((endDate | moment) || '')"
  [matTooltipShowDelay]="500"
  >
  <div class="dates-range__start-date">{{ startDate | moment }}</div>
  <div class="dates-range__dates-separator">-</div>
  <div class="dates-range__end-date-wrap"
    [class.work-package-node__dates--error]="hasEndDateError">
    <div class="dates-range__end-date">{{ endDate | moment }}</div>
    @if (endDateSuffix) {
      <div class="dates-range__end-date-suffix">({{ endDateSuffix }})</div>
    }
  </div>
</div>
