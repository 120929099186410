import { Pipe, PipeTransform, ɵstringify as stringify } from '@angular/core';
import { isEmpty } from '../../helpers/utilities';
import { MAX_FRACTION_DIGITS } from '../../shared/constants';

/**
 * Transforms a string into a number (if needed).
 */
function strToNumber(value: number | string): number {
  // Convert strings to numbers
  if (typeof value === 'string' && !Number.isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }
  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }

  return value;
}

@Pipe({
  name: 'decimal',
})
export class DecimalPipe implements PipeTransform {
  transform(value: any, locale?: string, decimalDigits?: number): string|null {
    if (isEmpty(value)) {
      return null;
    }

    try {
      const num = strToNumber(value);
      const maxFactionDigits = (decimalDigits != null ? strToNumber(decimalDigits) : MAX_FRACTION_DIGITS);

      const formatted = num.toFixed(maxFactionDigits);

      return formatted.replace(/\.00$/, '').replace(/\.(\d)0$/, '.$1');
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${value}' for pipe '${stringify(DecimalPipe)}'`);
    }
  }
}
