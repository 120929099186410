import {
  Inject, Injectable, OnDestroy, Optional, ɵstringify as stringify,
} from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateAdapterOptions } from '@angular/material-moment-adapter';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';
import { CollappDateAdapter } from '../collapp-core';

/**
 * We do not expose the MomentDateAdapter configuration but set it here as an
 * application default.
 */
const MAT_MOMENT_DATE_ADAPTER_OPTIONS: MatMomentDateAdapterOptions = {
  strict: true,
  useUtc: true,
};

/** Adapts Moment.js Dates for use with Angular Material. */
@Injectable({
  providedIn: 'root',
})
export class CollappMomentDateAdapter extends CollappDateAdapter implements OnDestroy {
  private static instanceCounter: number = 0;

  /** A stream that emits when the UTC offset changes. */
  readonly utcOffsetChanges$: Observable<number>;

  /**
   * UTC offset in minutes.
   */
  protected _utcOffset: number = 0;

  // eslint-disable-next-line rxjs/no-exposed-subjects
  protected _utcOffsetChanges$: Subject<number> = new Subject();

  // eslint-disable-next-line no-plusplus
  private instanceNumber: number = CollappMomentDateAdapter.instanceCounter++;

  constructor(
  @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
  ) {
    super(dateLocale, MAT_MOMENT_DATE_ADAPTER_OPTIONS);

    /* eslint-disable no-console */

    if (this.instanceNumber > 0) {
      console.warn(`More than one instance of ${stringify(CollappMomentDateAdapter)} was created.`);
    }

    /* eslint-enable no-console */

    this.utcOffsetChanges$ = this._utcOffsetChanges$.asObservable();
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    this._utcOffsetChanges$.complete();
  }

  // region MomentDateAdapter overrides

  /** @inheritdoc */
  getFirstDayOfWeek(): number {
    /**
     * Ensures that the week starts on monday.
     * This is a Collapp default.
     */
    return 1;
    // return this._localeData.firstDayOfWeek;
  }

  /** @inheritdoc */
  createDate(year: number, month: number, date: number): moment.Moment {
    return this.adjustDate(super.createDate(year, month, date));
  }

  /** @inheritdoc */
  today(): moment.Moment {
    return this.adjustDate(super.today());
  }

  /** @inheritdoc */
  parse(value: any, parseFormat: string | string[]): moment.Moment | null {
    return this.adjustDate(super.parse(value, parseFormat));
  }

  /** @inheritdoc */
  toIso8601(date: moment.Moment): string {
    return this.clone(date)
      .toISOString(true);
  }

  /**
   * Returns the given value if given a valid Moment or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid Moments and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): moment.Moment | null {
    return this.adjustDate(super.deserialize(value));
  }

  // endregion

  // region Custom MomentDateAdapter additions

  adjustDate(date: moment.Moment, keepLocalTime?: boolean): moment.Moment;

  adjustDate(date: moment.Moment | null, keepLocalTime?: boolean): moment.Moment | null;

  /**
   * Adjusts a date to the UTC offset currently set on this date adapter.
   *
   * @param [keepLocalTime=true] - Passing true will keep the same local time, but at the expense of choosing
   * a different point in Universal Time.
   */
  adjustDate(date: moment.Moment | null, keepLocalTime: boolean = true): moment.Moment | null {
    if (date != null) {
      return date
        .clone()
        // `setLocale()` is called in super.constructor() which in turn calls `createDate()` which leads to a call
        // here where `_utcOffset` is still `undefined`.
        .utcOffset(this._utcOffset || 0, keepLocalTime);
    }

    return date;
  }

  /**
   * Get the UTC offset in minutes.
   */
  utcOffset(): number;

  /**
   * Setting the UTC offset by supplying minutes.
   * See the official MomentJS documentation for `moment().utcOffset(Number|String);` - this
   * method behaves the same way.
   */
  utcOffset(value: number | string): void;

  utcOffset(value?: number | string): number | undefined {
    if (value == null) {
      return this._utcOffset;
    }

    const date = moment()
      .utcOffset(value);
    if (!date.isValid()) {
      throw new Error(`Invalid UTC offset "${value}" given.`);
    }
    const previousUtcOffset = this._utcOffset;

    const utcOffset = date.utcOffset();
    this._utcOffset = utcOffset;

    if (utcOffset !== previousUtcOffset) {
      this._utcOffsetChanges$.next(utcOffset);
    }

    return undefined;
  }

  // endregion
}
