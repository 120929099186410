import moment from 'moment';
import { GateDtoModel } from '../api/models/dtos/gate.dto.model';
import { UserDtoModel } from '../api/models/dtos/user.dto.model';
import { UserListDtoModel } from '../api/models/dtos/user-list.dto.model';
import { UserSlimDtoModel } from '../api/models/dtos/user-slim.dto.model';
import { RoleDtoModel } from '../api/models/dtos/role.dto.model';
import { LevelSlimDtoModel } from '../api/models/dtos/level-slim.dto.model';
import { ProjectTypeDtoModel } from '../api/models/dtos/project-type.dto.model';
import { ContractDtoModel } from '../api/models/dtos/contract.dto.model';
import { Unit } from '../models/unit.interface';
import { ProjectViewResponseModel } from '../api/models/responses/project-view.response.model';
import { CountryDtoModel } from '../api/models/dtos/country.dto.model';
import { ContractHourlyRateCategoryPairDtoModel } from '../api/models/dtos/contract-hourly-rate-category-pair.dto.model';
import { BasicUser } from '../models/user.interface';
import { UnitPlanningResourceDtoModel } from '../api/models/dtos/unit-planning-resource.dto.model';
import { TimeTrackingRowDtoModel } from '../api/models/dtos/time-tracking-row.dto.model';
import { CostObjectDtoModel } from '../api/models/dtos/costObject.dto.model';

export class CompareWithUtility {
  static compareByHourlyRateCategoryPair(
    item: ContractHourlyRateCategoryPairDtoModel,
    selected: ContractHourlyRateCategoryPairDtoModel,
  ): boolean {
    return item.contractHourlyRateCategoryPairId === selected.contractHourlyRateCategoryPairId;
  }

  static compareDatesAsc(a: moment.Moment, b: moment.Moment): number {
    return a.diff(b);
  }

  static compareDatesDesc(a: moment.Moment, b: moment.Moment): number {
    return b.diff(a);
  }

  static compareCreatedOnAsc(a: { createdOn: moment.Moment }, b: { createdOn: moment.Moment }): number {
    return CompareWithUtility.compareDatesAsc(a.createdOn, b.createdOn);
  }

  static compareCreatedOnDesc(a: { createdOn: moment.Moment }, b: { createdOn: moment.Moment }): number {
    return CompareWithUtility.compareDatesDesc(a.createdOn, b.createdOn);
  }

  static compareUploadedOnDesc(a: { uploadedOn: moment.Moment }, b: { uploadedOn: moment.Moment }): number {
    return CompareWithUtility.compareDatesDesc(a.uploadedOn, b.uploadedOn);
  }

  static gatesCompare(o1: GateDtoModel, o2: GateDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.gateId === o2.gateId);
  }

  static countriesCompare(o1: CountryDtoModel, o2: CountryDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.countryCode === o2.countryCode);
  }

  /**
   * Compares an option (DTO) with a selected country code value.
   * @param listOption
   * @param selectedValue - country code
   */
  static compareCountryByCode(listOption: CountryDtoModel, selectedValue: string): boolean {
    if (!listOption || !selectedValue) {
      return false;
    }

    return (listOption.countryCode === selectedValue);
  }

  static levelsCompare(o1: LevelSlimDtoModel, o2: LevelSlimDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.levelId === o2.levelId);
  }

  static compareConclusionValues(o1: any, o2: any): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return o1.id === o2.id;
  }

  static projectsCompare(p1: ProjectViewResponseModel, p2: ProjectViewResponseModel): boolean {
    if (!p1 || !p2) {
      return false;
    }

    return (p1.projectId === p2.projectId);
  }

  static projectTypesCompare(o1: ProjectTypeDtoModel, o2: ProjectTypeDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.projectTypeCode === o2.projectTypeCode);
  }

  /**
   * Compares an option (DTO) with a selected project type code
   * @param listOption
   * @param selectedValue
   */
  static compareProjectTypeByCode(listOption: ProjectTypeDtoModel, selectedValue: string): boolean {
    if (!listOption || !selectedValue) {
      return false;
    }

    return (listOption.projectTypeCode === selectedValue);
  }

  static compareTimecardCategoryByCode(tc1: TimeTrackingRowDtoModel, tc2: TimeTrackingRowDtoModel): boolean {
    if (!tc1 || !tc2) {
      return false;
    }

    return (tc1.timecardCategoryCode === tc2.timecardCategoryCode);
  }

  static rolesCompare(o1: RoleDtoModel, o2: RoleDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.roleId === o2.roleId);
  }

  static unitsCompare(o1: Unit, o2: Unit): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.unitId === o2.unitId);
  }

  static contractsCompare(c1: ContractDtoModel, c2: ContractDtoModel): boolean {
    if (!c1 || !c2) {
      return false;
    }

    return (c1.contractId === c2.contractId);
  }

  static usersCompare(
    o1: UserDtoModel | UserListDtoModel | UserSlimDtoModel,
    o2: UserDtoModel | UserListDtoModel | UserSlimDtoModel,
  ): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.userId === o2.userId);
  }

  static planningResourceCompare(o1: UnitPlanningResourceDtoModel, o2: UnitPlanningResourceDtoModel): boolean {
    if (!o1 || !o2) {
      return false;
    }

    return (o1.planningResourceId === o2.planningResourceId);
  }

  /**
   * Compares an option (DTO) with a selected supplier hourly rate category
   * @param listOption
   * @param selectedValue
   */
  static compareBySupplierHourlyRateCategory(
    listOption: ContractHourlyRateCategoryPairDtoModel,
    selectedValue: string,
  ): boolean {
    if (!listOption || !selectedValue) {
      return false;
    }

    return (listOption.supplierHourlyRateCategory === selectedValue);
  }

  /**
   * Compares an option (DTO) with a selected submitter hourly rate category
   * @param listOption
   * @param selectedValue
   */
  static compareBySubmitterHourlyRateCategory(
    listOption: ContractHourlyRateCategoryPairDtoModel,
    selectedValue: string,
  ): boolean {
    if (!listOption || !selectedValue) {
      return false;
    }

    return (listOption.submitterHourlyRateCategory === selectedValue);
  }

  static sortUserAsc(o1: BasicUser, o2: BasicUser): number {
    return o1.fullName.localeCompare(o2.fullName);
  }

  static sortUserDesc(o1: BasicUser, o2: BasicUser): number {
    return o2.fullName.localeCompare(o1.fullName);
  }
}
