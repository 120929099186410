import { AvailalbeProjectListResponse } from '../../interfaces/responses/available-project-list.response';
import { AvailableProjectDtoModel } from '../dtos/available-project.dto.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AvailableProjectListResponseModel {
  constructor(
    readonly items: readonly AvailableProjectDtoModel[],
  ) {
    this.items = [...items];
  }

  static fromJSON(json: AvailalbeProjectListResponse): AvailableProjectListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new AvailableProjectListResponseModel(
      (Array.isArray(json.items) ? json.items : [])
        .map((item) => AvailableProjectDtoModel.fromJSON(item)),
    );
  }

  clone(): AvailableProjectListResponseModel {
    return new AvailableProjectListResponseModel(
      this.items.map((item) => item.clone()),
    );
  }

  toJSON(): AvailalbeProjectListResponse {
    return {
      items: this.items.map((item) => item.toJSON()),
    };
  }
}
