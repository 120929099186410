import moment from 'moment';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { CapacityDtoModel } from './capacity.dto.model';
import { AvailableResourceDto } from '../../interfaces/dtos/available-resource.dto';

export class AvailableResourceDtoModel implements ApiModelNew {
  constructor(
    readonly resourceId: string,
    readonly isUser: boolean,
    readonly fullName: string | null,
    readonly email: string | null,
    readonly photoUrl: string | null = null,
    readonly validFrom: moment.Moment | null = null,
    readonly validUntil: moment.Moment | null = null,
    readonly capacities: CapacityDtoModel[] = [],
    readonly canAddToResponsibles: boolean = false,
  ) {
    this.capacities = [...capacities];
  }

  static fromJSON(json: AvailableResourceDto): AvailableResourceDtoModel {
    assertRequiredProperties(json, [
      'resourceId',
    ]);

    return new AvailableResourceDtoModel(
      json.resourceId,
      json.isUser,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      (Array.isArray(json.capacities)
        ? json.capacities
          .map((item) => CapacityDtoModel.fromJSON(item))
        : []
      ),
      json.canAddToResponsibles,
    );
  }

  toJSON(): AvailableResourceDto {
    return {
      resourceId: this.resourceId,
      isUser: this.isUser,
      fullName: this.fullName,
      email: this.email,
      photoUrl: this.photoUrl,
      validFrom: this.validFrom ? this.validFrom.toJSON() : null,
      validUntil: this.validUntil ? this.validUntil.toJSON() : null,
      capacities: this.capacities.map((item) => item.toJSON()),
      canAddToResponsibles: this.canAddToResponsibles,
    };
  }

  clone(): AvailableResourceDtoModel {
    return new AvailableResourceDtoModel(
      this.resourceId,
      this.isUser,
      this.fullName,
      this.email,
      this.photoUrl,
      this.validFrom?.clone() || null,
      this.validUntil?.clone() || null,
      this.capacities,
      this.canAddToResponsibles,
    );
  }

  isValidUntil(endDate: moment.Moment): boolean {
    if (this.validUntil != null) {
      return this.validUntil.isSameOrAfter(endDate, 'days');
    }

    return true;
  }

  isValidFrom(startDate: moment.Moment): boolean {
    if (this.validFrom != null) {
      return this.validFrom.isSameOrBefore(startDate, 'days');
    }

    return false;
  }
}
