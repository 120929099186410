import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { ReadonlyCompleteMap } from '../../app.types';
import { NonHumanResourceSlimDtoModel } from '../models/dtos/non-human-resource-slim.dto.model';

export type NonHumanResourcePlanningListColumnKeys = ObjectPropertyNames<NonHumanResourceSlimDtoModel>;
const nonHumanResourcePlanningListColumnsInfoObj: ListColumnsInfo<NonHumanResourceSlimDtoModel> = {
  nonHumanResourceId: {
    label: 'Equipment, Infrastructure, Tools Id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  name: {
    label: 'Name',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  validFrom: {
    label: 'Valid From',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  validUntil: {
    label: 'Valid Until',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const nonHumanResourcePlanningListColumnsInfo: ReadonlyCompleteMap<
NonHumanResourcePlanningListColumnKeys,
ListColumnInfo
> = new Map([
  ['nonHumanResourceId', nonHumanResourcePlanningListColumnsInfoObj.nonHumanResourceId],
  ['name', nonHumanResourcePlanningListColumnsInfoObj.name],
  ['validFrom', nonHumanResourcePlanningListColumnsInfoObj.validFrom],
  ['validUntil', nonHumanResourcePlanningListColumnsInfoObj.validUntil],
]) as ReadonlyCompleteMap<NonHumanResourcePlanningListColumnKeys, ListColumnInfo>;
