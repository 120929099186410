import { assertRequiredProperties } from '../../utilities/api.utility';
import { UnitCostCenterDto } from '../../interfaces/dtos/unit-cost-center.dto';
import { UnitCostCenterActivityTypeModel } from './unit-cost-center-activity-type.dto.model';
import { CostCenerSaveRequest } from '../../interfaces/requests/cost-center-save.request';

export class UnitCostCenterModel {
  constructor(
    readonly unitCostCenterId: string | null,
    readonly costCenter: string,
    readonly description: string,
    readonly isDefault: boolean,
    readonly unitCostCenterActivityTypes: readonly UnitCostCenterActivityTypeModel[],
  ) {
    this.unitCostCenterActivityTypes = [...unitCostCenterActivityTypes];
  }

  // in some old cases it is possible to have the same cost center with different unitCostCenterId
  // we also need the name since new cost centers have no unitCostCenterId (in case it is a new cost center we know the name will be unique)
  get uid(): string {
    return `${this.unitCostCenterId}${this.costCenter}`;
  }

  get name(): string {
    return this.costCenter;
  }

  static fromJSON(json: UnitCostCenterDto): UnitCostCenterModel {
    assertRequiredProperties(json, []);

    return new UnitCostCenterModel(
      json.unitCostCenterId,
      json.costCenter,
      json.description,
      json.isDefault,
      json.unitCostCenterActivityTypes.map((item) => UnitCostCenterActivityTypeModel.fromJSON(item)),
    );
  }

  toJSON(): UnitCostCenterDto {
    return {
      unitCostCenterId: this.unitCostCenterId,
      costCenter: this.costCenter,
      description: this.description,
      isDefault: this.isDefault,
      unitCostCenterActivityTypes: this.unitCostCenterActivityTypes.map((item) => item.toJSON()),
    };
  }

  toCostCenerSaveRequestJSON(): CostCenerSaveRequest {
    return {
      costCenterId: Number(this.unitCostCenterId),
      description: this.description,
      name: this.costCenter,
      isDefault: this.isDefault,
      unitCostCenterActivityTypes: this.unitCostCenterActivityTypes.map((item) => item.toJSON()),
    };
  }
}
