<div class="form-section__header">
  <h2 class="form-section__title">{{ label }}</h2>
  <div class="form-section__divider"></div>
  @if (actionLabel) {
    <button
      collapp-stroked-button
      type="button"
      class="form-section__action"
      (click)="onActionClick()">
      {{ actionLabel }}
    </button>
  }
</div>
<div class="form-section__body">
  <ng-content></ng-content>
</div>
