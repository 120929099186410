import { ActivityFieldDto } from '../../interfaces/dtos/activity-field.dto';
import { ActivityFieldType } from '../../../models/activity-field-type.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ActivityFieldDtoModel {
  constructor(
    readonly type: ActivityFieldType,
    readonly name: string,
    readonly newValue: string,
    readonly oldValue: string = '',
  ) {
  }

  static fromJSON(json: ActivityFieldDto): ActivityFieldDtoModel {
    assertRequiredProperties(json, [
      'type',
      'name',
    ]);

    return new ActivityFieldDtoModel(
      json.type,
      json.name,
      json.newValue || '',
      json.oldValue || '',
    );
  }

  toJSON(): ActivityFieldDto {
    return {
      name: this.name,
      oldValue: this.oldValue,
      newValue: this.newValue,
      type: this.type,
    };
  }
}
