import moment from 'moment';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceSlimDto } from '../../interfaces/dtos/non-human-resource-slim.dto';

export class NonHumanResourceSlimDtoModel implements ApiModelNew {
  constructor(
    readonly nonHumanResourceId: string,
    readonly name: string,
    readonly validFrom: moment.Moment | null = null,
    readonly validUntil: moment.Moment | null = null,
  ) {
    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);
  }

  static fromJSON(json: NonHumanResourceSlimDto): NonHumanResourceSlimDtoModel {
    assertRequiredProperties(json, [
      'nonHumanResourceId',
      'name',
    ]);

    return new NonHumanResourceSlimDtoModel(
      json.nonHumanResourceId,
      json.name,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
    );
  }

  /**
   * If no value is given the optional field is evaluated as not valid.
   */
  isValidFrom(startDate: moment.Moment): boolean {
    if (this.validFrom != null) {
      return this.validFrom.isSameOrBefore(startDate) || startDate < moment();
    }

    return false;
  }

  /**
   * If no value is given the optional field is evaluated as valid.
   */
  isValidUntil(endDate: moment.Moment): boolean {
    if (this.validUntil != null) {
      return this.validUntil.isSameOrAfter(endDate);
    }

    return true;
  }

  isValidUntilInPast(checkDate: moment.Moment = moment()): boolean {
    return this.validUntil != null && this.validUntil.isBefore(checkDate);
  }

  toJSON(): NonHumanResourceSlimDto {
    return {
      nonHumanResourceId: this.nonHumanResourceId,
      name: this.name,
      validFrom: (this.validFrom ? this.validFrom.toJSON() : null),
      validUntil: (this.validUntil ? this.validUntil.toJSON() : null),
    };
  }

  clone(overrides?: Partial<NonHumanResourceSlimDtoModel>): NonHumanResourceSlimDtoModel {
    return new NonHumanResourceSlimDtoModel(
      overrides && overrides.nonHumanResourceId != null
        ? overrides.nonHumanResourceId
        : this.nonHumanResourceId,
      overrides && overrides.name != null
        ? overrides.name
        : this.name,
      overrides && overrides.validFrom !== undefined
        ? overrides.validFrom
        : this.validFrom?.clone() || null,
      overrides && overrides.validUntil !== undefined
        ? overrides.validUntil
        : this.validUntil?.clone() || null,
    );
  }
}
