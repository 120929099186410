import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { WorkPackageViewResponseModel } from '../api/models/responses/work-package-view.response.model';
import { ProjectService } from '../api/services/project.service';
import { SetWorkPackageView } from '../state';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class WorkPackageViewResolver  {
  constructor(
    private projectService: ProjectService,
    private store: Store,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<WorkPackageViewResponseModel> {
    const projectId = route.paramMap.get('id');
    const workPackageId = route.paramMap.get('wpId');

    if (workPackageId == null) {
      throw new Error('Route param \'wpId\' cannot be null');
    }

    if (projectId == null) {
      throw new Error('Resolved project doesn\'t contain an ID.');
    }

    return this.projectService
      .getWorkPackageView$(+projectId, +workPackageId)
      .pipe(
        switchMap((result) => this.store.dispatch(new SetWorkPackageView(result))
          .pipe(
            mapTo(result),
          )),
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Error loading work package view');

          return throwError(error);
        }),
      );
  }
}
