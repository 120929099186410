import { ApiModelNew } from '../types';
import { PublicHolidaysDto } from '../../interfaces/responses/public-holidays.dto';

export class PublicHolidayDtoModel implements ApiModelNew {
  constructor(
    public hasHoliday: boolean,
    public holidayDescription: string | null = null,
    public holidayHours: number,
  ) {
  }

  static fromJSON(json: PublicHolidaysDto): PublicHolidayDtoModel {
    return new PublicHolidayDtoModel(
      json.hasHoliday,
      json.holidayDescription,
      json.holidayHours,
    );
  }

  clone(): PublicHolidayDtoModel {
    return new PublicHolidayDtoModel(
      this.hasHoliday,
      this.holidayDescription,
      this.holidayHours,
    );
  }

  toJSON(): PublicHolidaysDto {
    return {
      hasHoliday: this.hasHoliday,
      holidayDescription: this.holidayDescription,
      holidayHours: this.holidayHours,
    };
  }
}
