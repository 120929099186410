import { UnitPlanningSidepanelIndexStateModel } from './unit-planning-sidepanel-index-state.model';

/**
 * Action types for UnitPlanningSidepanelIndex
 */
export enum UnitPlanningSidepanelIndexActionTypes {
  InitUnitPlanningSidepanelIndex = '[UnitPlanningSidepanelIndex API] Initialize Sidepanel',
  SetUnitPlanningSidepanelIndex = '[UnitPlanningSidepanelIndex API] Set UnitPlanningSidepanelIndex',
  ResetUnitPlanningSidepanelIndex = '[UnitPlanningSidepanelIndex API] Reset UnitPlanningSidepanelIndex',
}

export class InitUnitPlanningSidepanelIndex {
  static readonly type: string = UnitPlanningSidepanelIndexActionTypes.InitUnitPlanningSidepanelIndex;

  constructor(public settings: UnitPlanningSidepanelIndexStateModel) {}
}

export class ResetUnitPlanningSidepanelIndex {
  static readonly type: string = UnitPlanningSidepanelIndexActionTypes.ResetUnitPlanningSidepanelIndex;
}

export class SetUnitPlanningSidepanelIndex {
  static readonly type: string = UnitPlanningSidepanelIndexActionTypes.SetUnitPlanningSidepanelIndex;

  constructor(public settings: UnitPlanningSidepanelIndexStateModel) {}
}
