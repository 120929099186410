import { GenericListOptions } from '../generic-list';
import { UnitsListColumnKey } from '../../../api/meta-info/units-list.info';
import { UnitColumnFilters } from '../../../api/filter-columns';
import { UnitListStateModel } from './unit-list-state.model';

/**
 * Action types for UnitListState
 */
export enum UnitListActionTypes {
  SetUnitListSelectedColumns = '[Unit List Page] Set Selected Columns',
  SetUnitListOptions = '[Unit List Page] Set Options',
  ResetUnitListOptions = '[Unit List Page] Reset Options',
  InitUnitListSettings = '[User API] Initialize Unit List Settings',
  SetUnitListSettings = '[User API] Set Unit List Settings',
  ResetUnitListSettings = '[User API] Reset Unit List Settings',
  SaveUnitListSettings = '[User API] Save Unit List Settings',
}

export interface UnitListOptions extends GenericListOptions {
  columnFilters: UnitColumnFilters;
}

export class SetUnitListSelectedColumns {
  static readonly type: string = UnitListActionTypes.SetUnitListSelectedColumns;

  constructor(public columns: UnitsListColumnKey[]) {}
}

export class SetUnitListOptions {
  static readonly type: string = UnitListActionTypes.SetUnitListOptions;

  constructor(public options: UnitListOptions) {}
}

export class ResetUnitListOptions {
  static readonly type: string = UnitListActionTypes.ResetUnitListOptions;
}

export class InitUnitListSettings {
  static readonly type: string = UnitListActionTypes.InitUnitListSettings;

  constructor(public settings: UnitListStateModel) {}
}

export class SetUnitListSettings {
  static readonly type: string = UnitListActionTypes.SetUnitListSettings;

  constructor(public settings: UnitListStateModel) {}
}

export class ResetUnitListSettings {
  static readonly type: string = UnitListActionTypes.ResetUnitListSettings;
}

export class SaveUnitListSettings {
  static readonly type: string = UnitListActionTypes.SaveUnitListSettings;

  constructor(public settings: UnitListStateModel) {}
}
