import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { TimecardsStateModel } from './timecards-state.model';
import {
  AddExpandedProjectId,
  RemoveExpandedProjectId,
  SetNonProductiveCategoriesListExpandedState,
  SetProjectsListExpandedState,
} from './timecards.actions';

const defaultTimecardsState: TimecardsStateModel = {
  nonProductiveCategoriesListExpanded: false,
  projectsListExpanded: true,
  collapsedProjectsIds: [],
};

@State<TimecardsStateModel>({
  name: 'timecards',
  defaults: defaultTimecardsState,
})
@Injectable({
  providedIn: 'root',
})
export class TimecardsState {
  @Action(SetNonProductiveCategoriesListExpandedState)
  setNonProductiveCategoriesListExpandedState(
    ctx: StateContext<TimecardsStateModel>,

    { expanded }: SetNonProductiveCategoriesListExpandedState,
  ): void {
    ctx.patchState({
      nonProductiveCategoriesListExpanded: expanded,
    });
  }

  @Action(SetProjectsListExpandedState)
  setProjectsListExpandedState(
    ctx: StateContext<TimecardsStateModel>,
    { expanded }: SetProjectsListExpandedState,
  ): void {
    ctx.patchState({
      projectsListExpanded: expanded,
    });
  }

  @Action(AddExpandedProjectId)
  addExpandedProjectsId(ctx: StateContext<TimecardsStateModel>, { projectId }: AddExpandedProjectId): void {
    const { collapsedProjectsIds } = ctx.getState();
    const set = new Set(collapsedProjectsIds);

    set.add(projectId);

    ctx.patchState({
      collapsedProjectsIds: Array.from(set),
    });
  }

  @Action(RemoveExpandedProjectId)
  removeExpandedProjectsId(
    ctx: StateContext<TimecardsStateModel>,
    { projectId }: RemoveExpandedProjectId,
  ): void {
    const { collapsedProjectsIds } = ctx.getState();

    ctx.patchState({
      collapsedProjectsIds: collapsedProjectsIds.filter((id) => id !== projectId),
    });
  }
}
