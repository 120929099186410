import moment from 'moment';
import { UserSlimDto } from '../../interfaces/dtos/user-slim.dto';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { BasicUser } from '../../../models/user.interface';

export class UserSlimDtoModel implements BasicUser, ApiModelNew {
  constructor(
    readonly userId: string,
    readonly fullName: string,
    readonly email: string,
    readonly photoUrl: string | null = null,
    readonly validFrom: moment.Moment | null = null,
    readonly validUntil: moment.Moment | null = null,
  ) {
    this.validFrom = (validFrom != null ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil != null ? validUntil.clone() : validUntil);
  }

  get id(): string {
    return this.userId;
  }

  static fromJSON(json: UserSlimDto): UserSlimDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'fullName',
      'email',
    ]);

    return new UserSlimDtoModel(
      json.userId,
      json.fullName,
      json.email,
      json.photoUrl,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
    );
  }

  /**
   * If no value is given the optional field is evaluated as not valid.
   */
  isValidFrom(startDate: moment.Moment): boolean {
    if (this.validFrom != null) {
      return this.validFrom.isSameOrBefore(startDate.clone().utcOffset(0, true), 'day');
    }

    return false;
  }

  /**
   * If no value is given the optional field is evaluated as valid.
   */
  isValidUntil(endDate: moment.Moment): boolean {
    if (this.validUntil != null) {
      return this.validUntil.isSameOrAfter(endDate);
    }

    return true;
  }

  isValidUntilInPast(checkDate: moment.Moment = moment()): boolean {
    return this.validUntil != null && this.validUntil.isBefore(checkDate);
  }

  toJSON(): UserSlimDto {
    return {
      userId: this.userId,
      fullName: this.fullName,
      email: this.email,
      photoUrl: this.photoUrl,
      validFrom: (this.validFrom ? this.validFrom.toJSON() : null),
      validUntil: (this.validUntil ? this.validUntil.toJSON() : null),
    };
  }

  clone(overrides?: Partial<UserSlimDtoModel>): UserSlimDtoModel {
    return new UserSlimDtoModel(
      overrides && overrides.userId != null
        ? overrides.userId
        : this.userId,
      overrides && overrides.fullName != null
        ? overrides.fullName
        : this.fullName,
      overrides && overrides.email != null
        ? overrides.email
        : this.email,
      overrides && overrides.photoUrl !== undefined
        ? overrides.photoUrl
        : this.photoUrl,
      overrides && overrides.validFrom !== undefined
        ? overrides.validFrom
        : this.validFrom?.clone() || null,
      overrides && overrides.validUntil !== undefined
        ? overrides.validUntil
        : this.validUntil?.clone() || null,
    );
  }
}
