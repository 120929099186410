import { ProjectStatus } from '../../../models/project-status.enum';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { ProjectListViewDto } from '../../interfaces/dtos/project-list-view';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectListViewDtoModel {
  constructor(
    readonly projectId: number,
    readonly sapNumber: string | null = null,
    readonly projectNumber: string = '',
    readonly title: string = '',
    readonly scope: string = '',
    readonly projectManagerFullName: string | null = null,
    readonly technicalProjectManagerFullName: string | null = null,
    readonly submitterUnitName: string | null = null,
    readonly gateName: string | null = null,
    readonly customerCountryCode: string | null = null,
    readonly placeOfManufacturingCountryCode: string | null = null,
    readonly emtOrderFromUnitName: string = '',
    readonly emtOrderFromCountryCode: string = '',
    readonly status: ProjectStatus | null = null,
    readonly projectTypeCode: ProjectTypeCode | null = null,
    readonly payrollCompany: string | null = null,
    readonly reportedHours: number = 0,
    readonly percentDone: number = 0,
    readonly projectUserStatus: string | null = null,
  ) {
  }

  static fromJSON(json: ProjectListViewDto): ProjectListViewDtoModel {
    assertRequiredProperties(json, [
      // 'projectId',
    ]);

    return new ProjectListViewDtoModel(
      json.projectId,
      json.sapNumber,
      json.projectNumber,
      json.title,
      json.scope,
      json.projectManagerFullName,
      json.technicalProjectManagerFullName,
      json.submitterUnitName,
      json.gateName,
      json.customerCountryCode,
      json.placeOfManufacturingCountryCode,
      json.emtOrderFromUnitName || '',
      json.emtOrderFromCountryCode || '',
      (json.status != null ? json.status : null),
      (json.projectTypeCode != null ? json.projectTypeCode : null),
      json.payrollCompany,
      json.reportedHours || 0,
      json.percentDone || 0,
      json.projectUserStatus,
    );
  }

  toJSON(): ProjectListViewDto {
    return {
      projectId: this.projectId,
      sapNumber: this.sapNumber,
      projectNumber: this.projectNumber,
      title: this.title,
      scope: this.scope,
      projectManagerFullName: this.projectManagerFullName,
      technicalProjectManagerFullName: this.technicalProjectManagerFullName,
      submitterUnitName: this.submitterUnitName,
      gateName: this.gateName,
      customerCountryCode: this.customerCountryCode,
      placeOfManufacturingCountryCode: this.placeOfManufacturingCountryCode,
      emtOrderFromUnitName: this.emtOrderFromUnitName,
      emtOrderFromCountryCode: this.emtOrderFromCountryCode,
      status: this.status,
      projectTypeCode: this.projectTypeCode,
      payrollCompany: this.payrollCompany,
      reportedHours: this.reportedHours,
      percentDone: this.percentDone,
      projectUserStatus: this.projectUserStatus,
    };
  }
}
