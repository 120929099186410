import { UserSettingRequest } from '../../interfaces/requests/user-setting.request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UserSettingRequestModel {
  constructor(
    readonly key: string,
    readonly value: string | boolean,
  ) {
    if (key === '') {
      throw new Error('The property \'key\' can not be an empty string.');
    }
  }

  static fromJSON(json: UserSettingRequest): UserSettingRequestModel {
    assertRequiredProperties(json, [
      'key',
      'value',
    ]);

    return new UserSettingRequestModel(
      json.key,
      json.value,
    );
  }

  toJSON(): UserSettingRequest {
    return {
      key: this.key,
      value: this.value,
    };
  }
}
