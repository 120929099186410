<ng-select
  #unitSelect
  ngSelectMat
  class="collapp-unit-select__select"
  [formControl]="unitControl"
  [items]="(filteredUnits$ | async) || []"
  [trackByFn]="unitTrackByFn"
  [loading]="isLoading"
  [typeahead]="typeahead$"
  [bindLabel]="'name'"
  [compareWith]="unitsCompare"
  [placeholder]="placeholder"
  [notFoundText]="'No units found'"
  [multiple]="multiple"
  [clearable]="clearable"
  [required]="required"
  [appendTo]="'body'"
  [groupBy]="'group'"
  [clearSearchOnAdd]="clearSearchOnAdd"
  [closeOnSelect]="closeOnSelect"
  [searchFn]="isSkillUnitSelector ? searchUnits : undefined"
  (blur)="onBlur()"
  >
  <ng-template ng-optgroup-tmp let-item="item">
    {{ item.group }}
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <div class="ng-value-label">
      <ng-option-text
        [tooltip]="isSkillUnitSelector ? item.name : item.name + ' (Level ' + (item | collAppUnitLevel) + ')'"
        >
        {{ item.name }} @if (!isSkillUnitSelector) {
        <span class="collapp-unit-select__level-indicator">(Level {{ item | collAppUnitLevel }})</span>
      }
    </ng-option-text>
  </div>
</ng-template>
<ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
  @if (!showSummary || !multiple) {
    @for (item of items; track unitOptionTrackByFn($index, item)) {
      <div class="ng-value">
        <div class="ng-value-label">
          <ng-option-text [tooltip]="isSkillUnitSelector ? item.name : item.name + ' (Level ' + (item | collAppUnitLevel) + ')'">
            {{ item.name }} @if (!isSkillUnitSelector) {
            <span class="collapp-unit-select__level-indicator">(Level {{ item | collAppUnitLevel }})</span>
          }
        </ng-option-text>
      </div>
      <div class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</div>
    </div>
  }
}
@if (showSummary && multiple) {
  <ng-option-text [tooltip]="summary">
    {{ summary }}
  </ng-option-text>
}
</ng-template>
<ng-template ng-option-tmp let-item="item">
  <ng-option-text [tooltip]="isSkillUnitSelector ? item.name : item.name + ' (Level ' + (item | collAppUnitLevel) + ')'">
    {{ item.name }} @if (!isSkillUnitSelector) {
    <span class="collapp-unit-select__level-indicator">(Level {{ item | collAppUnitLevel }})</span>
  }
</ng-option-text>
</ng-template>
@if (!isSkillUnitSelector) {
  <ng-template ng-footer-tmp>
    @if (canAddSupplierUnit) {
      <add-additional-supplier-button
        [contractSupplierUnitId]="contractSupplierUnitId"
        [canAdd]="canAddSupplierUnit"
        [workPackageId]="workPackageId"
        [projectId]="projectId"
        [unitControl]="unitControl"
        [existingUnits]="units"
        (click)="unitSelect.close()"
      ></add-additional-supplier-button>
    }
    @if (listInitialized) {
      <div class="collapp-unit-select__footer-container">
        @if (numberOfResults <= numberOfMaxTotalResults) {
          Displaying {{numberOfResults}} of {{numberOfMaxTotalResults}} available units
        } @else {
          Displaying {{numberOfResults}} filtered units
        }
      </div>
    }
  </ng-template>
}
</ng-select>
