import { Pipe, PipeTransform } from '@angular/core';
import { Unit } from '../models/unit.interface';

@Pipe({
  name: 'collAppUnit',
})
export class CollAppUnitPipe implements PipeTransform {
  transform(value?: Unit | Unit[] | null): any {
    if (value == null) {
      return '';
    }

    if (Array.isArray(value)) {
      return value
        .map((unit: Unit) => unit.name)
        .join(', ');
    }

    return value.name;
  }
}
