<ng-container>
  <header class="collapp-dialog__header">
    <h2 class="collapp-dialog__title">Mark attachment as outdated</h2>
    <button type="button" [disabled]="savingForm" class="collapp-dialog__close" (click)="cancel()">
      <span class="ic-close"></span>
    </button>
  </header>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <div fxLayout="row" fxLayoutGap="30px" fxFlexFill class="form-section__row">
        <mat-form-field floatLabel="always" fxFlex>
          <mat-label>Name</mat-label>
          <input matInput autocomplete="off" [formControl]="fileName" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="30px" fxFlexFill class="form-section__row">
        <mat-form-field floatLabel="always" fxFlex>
          <mat-label>Reason for marking attachment as outdated</mat-label>
          <input matInput autocomplete="off" [maxlength]="50" [formControl]="outdatedComment" />
          <mat-hint align="end">
            {{outdatedComment.value?.length || 0}} / 50
          </mat-hint>
          <mat-error>Field is <strong>required</strong>.</mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="30px" fxFlexFill class="form-section__row">
        <collapp-alert [icon]="'warning_amber'" [type]="'warning'">This action will be saved immediately and can not be undone.</collapp-alert>
      </div>
    </mat-dialog-content>
    <footer class="collapp-dialog__footer">
      <span class="flex-spacer"></span>
      <button
        type="button"
        collapp-stroked-button
        (click)="cancel()"
        [disabled]="savingForm"
        [loading]="savingForm">Cancel</button>
      <button
        type="submit"
        collapp-flat-button
        color="primary"
        [disabled]="savingForm"
        [loading]="savingForm">Save</button>
    </footer>
  </form>
</ng-container>
