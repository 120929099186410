import moment, { Moment } from 'moment';
import { ProjectImportListDto } from '../../interfaces/dtos/project-import-list.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { LevelImportListDtoModel } from './level-import-list.dto.model';
import { ProjectTypeCode } from '../../../models/project-type.enum';
import { ProjectImportListDtoMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectImportListPartialDtoModel implements ApiModelNew {
  constructor(
    readonly sapNumber: string,
    readonly projectNumber: string,
    readonly title: string,
    readonly customerCountryCode: string,
    readonly projectManager: UserSlimDtoModel | null,
    readonly technicalProjectManager: UserSlimDtoModel | null,
    readonly projectTypeCode: ProjectTypeCode,
    readonly emtOrderFromUnit: string,
    readonly emtOrderFromCountryCode: string,
    readonly structure: readonly LevelImportListDtoModel[],
    readonly metadata: ProjectImportListDtoMetadata,
    readonly projectStatus: string | null,
    readonly projectUserStatus: string | null,
    readonly wbsStatus: string | null,
    readonly wbsUserStatus: string | null,
    readonly getDate: Moment | null,
  ) {
    this.structure = [...structure];
  }

  // eslint-disable-next-line complexity
  static fromJSON(json: ProjectImportListDto): ProjectImportListPartialDtoModel {
    assertRequiredProperties(json, [
    ]);

    return new ProjectImportListPartialDtoModel(
      json.sapNumber || '',
      json.projectNumber || '',
      json.title || '',
      json.customerCountryCode || '',
      (json.projectManager
        ? UserSlimDtoModel.fromJSON(json.projectManager)
        : null
      ),
      (json.technicalProjectManager
        ? UserSlimDtoModel.fromJSON(json.technicalProjectManager)
        : null
      ),
      (json.projectTypeCode != null
        ? json.projectTypeCode
        : ProjectTypeCode.ProjectWithWBSPlanning1
      ),
      json.emtOrderFromUnit || '',
      json.emtOrderFromCountryCode || '',
      (Array.isArray(json.structure) ? json.structure : [])
        .map((item) => LevelImportListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {}) as any,
      json.projectStatus,
      json.projectUserStatus,
      json.wbsStatus,
      json.wbsUserStatus,
      (json.getDate ? moment(json.getDate) : null),
    );
  }

  clone(overrides?: Partial<ProjectImportListPartialDtoModel>): ProjectImportListPartialDtoModel {
    // const cls = Object.getPrototypeOf(this).constructor;

    return new ProjectImportListPartialDtoModel(
      (overrides && overrides.sapNumber != null
        ? overrides.sapNumber
        : this.sapNumber
      ),
      (overrides && overrides.projectNumber != null
        ? overrides.projectNumber
        : this.projectNumber
      ),
      (overrides && overrides.title != null
        ? overrides.title
        : this.title
      ),
      (overrides && overrides.customerCountryCode != null
        ? overrides.customerCountryCode
        : this.customerCountryCode
      ),
      (overrides && overrides.projectManager !== undefined
        ? overrides.projectManager
        : this.projectManager?.clone() || null
      ),
      (overrides && overrides.technicalProjectManager !== undefined
        ? overrides.technicalProjectManager
        : this.technicalProjectManager?.clone() || null
      ),
      (overrides && overrides.projectTypeCode != null
        ? overrides.projectTypeCode
        : this.projectTypeCode
      ),
      (overrides && overrides.emtOrderFromUnit != null
        ? overrides.emtOrderFromUnit
        : this.emtOrderFromUnit
      ),
      (overrides && overrides.emtOrderFromCountryCode != null
        ? overrides.emtOrderFromCountryCode
        : this.emtOrderFromCountryCode
      ),
      (overrides && overrides.structure
        ? overrides.structure
        : this.structure.map((item) => item.clone())
      ),
      (overrides && overrides.metadata
        ? overrides.metadata
        : new MetadataModel(this.metadata) as any
      ),
      (overrides && overrides.projectStatus != null
        ? overrides.projectStatus
        : this.projectStatus
      ),
      (overrides && overrides.projectUserStatus != null
        ? overrides.projectUserStatus
        : this.projectUserStatus
      ),
      (overrides && overrides.wbsStatus != null
        ? overrides.wbsStatus
        : this.wbsStatus
      ),
      (overrides && overrides.wbsUserStatus != null
        ? overrides.wbsUserStatus
        : this.wbsUserStatus
      ),
      (overrides && overrides.getDate !== undefined
        ? overrides.getDate?.clone() || null
        : this.getDate
      ),
    );
  }

  toJSON(): ProjectImportListDto {
    return {
      sapNumber: this.sapNumber,
      projectNumber: this.projectNumber,
      title: this.title,
      customerCountryCode: this.customerCountryCode,
      projectManager: (this.projectManager ? this.projectManager.toJSON() : null),
      technicalProjectManager: (this.technicalProjectManager ? this.technicalProjectManager.toJSON() : null),
      projectTypeCode: this.projectTypeCode,
      emtOrderFromUnit: this.emtOrderFromUnit,
      emtOrderFromCountryCode: this.emtOrderFromCountryCode,
      structure: this.structure.map((item) => item.toJSON()),
      metadata: { ...this.metadata },
      projectStatus: this.projectStatus,
      projectUserStatus: this.projectUserStatus,
      wbsStatus: this.wbsStatus,
      wbsUserStatus: this.wbsUserStatus,
      getDate: (this.getDate ? this.getDate.toJSON() : null),
    };
  }
}
