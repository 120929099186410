import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  AddWorkPackageRecentSupplierUnit,
  InitWorkPackageSettings,
} from './work-package.actions';
import { UserService } from '../../../api/services/user.service';
import { WorkPackageStateModel } from './work-package-state.model';

const MAX_NUMBER_OF_RECENT_UNITS = 4;

const defaultWorkPackageState: WorkPackageStateModel = {
  recentSupplierUnits: [],
};

@State<WorkPackageStateModel>({
  name: 'workPackage',
  defaults: defaultWorkPackageState,
})
@Injectable({
  providedIn: 'root',
})
export class WorkPackageState {
  constructor(
    private userService: UserService,
  ) {
  }

  @Action(InitWorkPackageSettings)
  initWorkPackageSettingsState(
    ctx: StateContext<WorkPackageStateModel>,
    { recentSupplierUnits }: InitWorkPackageSettings,
  ): void {
    ctx.patchState({
      recentSupplierUnits,
    });
  }

  @Action(AddWorkPackageRecentSupplierUnit)
  addWorkPackageRecentUnit(
    { patchState, getState }: StateContext<WorkPackageStateModel>,
    { unit }: AddWorkPackageRecentSupplierUnit,
  ): void {
    const { recentSupplierUnits } = getState();

    // Check if given unit already exists in queue
    const existingUnitIndex = recentSupplierUnits.findIndex((element) => (element.unitId === unit.unitId));

    // Create a new array of units with the most recent in front.
    const updatedRecentUnits = [unit, ...recentSupplierUnits];

    if (existingUnitIndex !== -1) {
      // Remove duplicates
      updatedRecentUnits.splice(existingUnitIndex + 1, 1);
    }

    // Limit queue to a given maximum number of entries.
    updatedRecentUnits.length = Math.min(updatedRecentUnits.length, MAX_NUMBER_OF_RECENT_UNITS);

    patchState({
      recentSupplierUnits: updatedRecentUnits,
    });
  }
}
