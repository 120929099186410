import { PaginationInfo } from '../interfaces/pagination-info';

export class PaginationInfoModel implements PaginationInfo {
  readonly pageNumber: number;

  readonly pageSize: number;

  readonly numberOfTotalResults: number;

  constructor(paginationInfo?: PaginationInfo) {
    this.pageNumber = ((paginationInfo && paginationInfo.pageNumber) || 1);
    this.pageSize = ((paginationInfo && paginationInfo.pageSize) || 0);
    this.numberOfTotalResults = ((paginationInfo && paginationInfo.numberOfTotalResults) || 0);
  }
}
