import { Selector } from '@ngxs/store';
import { UnitListState } from './unit-list.state';
import { UnitListOptions } from './unit-list.actions';
import { UnitListStateModel } from './unit-list-state.model';

export class UnitListSelectors {
  @Selector([UnitListState])
  static selectedColumns(state: UnitListStateModel): UnitListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([UnitListState])
  static options(state: UnitListStateModel): UnitListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: { ...state.columnFilters },
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
