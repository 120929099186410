import { ApiModelNew } from '../types';
import {
  DashboardViewResponse,
  DashboardViewResponseMetadata,
} from '../../interfaces/responses/dashboard-view.response';
import { UserSlimDtoModel } from '../dtos/user-slim.dto.model';
import { MetadataModel } from '../metadata.model';

export class DashboardViewResponseModel implements ApiModelNew {
  constructor(
    readonly users: readonly UserSlimDtoModel[],
    readonly metadata: MetadataModel<DashboardViewResponseMetadata> = new MetadataModel(),
  ) {
    this.users = [...users];
  }

  static fromJSON(json: DashboardViewResponse): DashboardViewResponseModel {
    return new DashboardViewResponseModel(
      (Array.isArray(json.users) ? json.users : [])
        .map((item) => UserSlimDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }

  clone(overrides?: Partial<DashboardViewResponseModel>): DashboardViewResponseModel {
    return new DashboardViewResponseModel(
      (overrides && overrides.users
        ? overrides.users
        : this.users.map((item) => item.clone())
      ),
      (overrides && overrides.metadata
        ? overrides.metadata
        : this.metadata.clone()
      ),
    );
  }

  toJSON(): DashboardViewResponse {
    return {
      users: this.users.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
    };
  }
}
