import moment from 'moment';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { TimeTrackingEntryViewDto } from '../../interfaces/dtos/time-tracking-entry-view.dto';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import guid from '../../../helpers/guid';
import { NoteInterfaceWithResourceData } from '../../interfaces/note.interface';

export class TimeTrackingEntryViewDtoModel {
  /**
   * Derived from the received Meta Data
   * for easier access
   */
  readonly canUpdateTimecardEntry: boolean;

  readonly isTimecardEntryDisabled: boolean;

  constructor(
    public id: string,
    readonly date: moment.Moment,
    public value: number,
    readonly metadata: MetadataModel = new MetadataModel(),
    public note: string | null,
    public notes: NoteInterfaceWithResourceData[] | null,
    readonly noteCreatedBy: string | null,
    readonly noteCreatedOn: moment.Moment | null,
    readonly resourceCapacityUnit: CapacityUnits | null = null,
  ) {
    this.date = date.clone();
    this.noteCreatedOn = noteCreatedOn?.clone() || null;

    this.canUpdateTimecardEntry = (this.metadata.fields && this.metadata.fields.canUpdateTimecardEntry) || false;
    this.isTimecardEntryDisabled = (this.metadata.fields && this.metadata.fields.isTimecardEntryDisabled) || false;
  }

  static fromJSON(json: TimeTrackingEntryViewDto): TimeTrackingEntryViewDtoModel {
    assertRequiredProperties(json, [
      'date',
      'value',
    ]);

    return new TimeTrackingEntryViewDtoModel(
      guid(),
      moment(json.date)
        .parseZone(),
      json.value,
      new MetadataModel(json.metadata || {}),
      json.note,
      json.notes,
      // MOCK:
      // [
      //   {
      //     createdBy: 'seb',
      //     note: 'text1',
      //     createdOn: '2023-09-25',
      //     workpackageTitle: 'wptitle',
      //   },
      //   {
      //     createdBy: 'seb',
      //     note: 'text2',
      //     createdOn: '2023-09-25',
      //     workpackageTitle: 'wptitle',
      //   },
      // ],
      json.noteCreatedBy,
      json.noteCreatedOn ? moment(json.noteCreatedOn).parseZone() : null,
      Object.values(CapacityUnits).filter((cu) => cu.toString() === json.resourceCapacityUnit)?.[0],
    );
  }

  toJSON(): TimeTrackingEntryViewDto {
    return {
      date: this.date.toJSON(),
      value: this.value,
      metadata: this.metadata.toJSON(),
      note: this.note,
      notes: this.notes,
      noteCreatedBy: this.noteCreatedBy,
      noteCreatedOn: this.noteCreatedOn ? this.noteCreatedOn.toJSON() : null,
      resourceCapacityUnit: this.resourceCapacityUnit?.toString() || '',
    };
  }

  clone(): TimeTrackingEntryViewDtoModel {
    return new TimeTrackingEntryViewDtoModel(
      this.id,
      this.date,
      this.value,
      this.metadata,
      this.note,
      this.notes,
      this.noteCreatedBy,
      this.noteCreatedOn,
      this.resourceCapacityUnit,
    );
  }
}
