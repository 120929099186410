import { GenericListOptions } from './generic-list-options';
import { GenericListStateModel } from './generic-list-state.model';
import { ListColumnInfo, ObjectStringKeys } from '../../../api/meta-info/base';
import { ReadonlyCompleteMap } from '../../../app.types';

export abstract class GenericListState {
  /**
   * Clones the selected columns so that the resulting object can be used
   * directly in the stores' `patchState()` method.
   */
  protected cloneSelectedColumns<T>(
    selectedColumns: ObjectStringKeys<T>[] | null | undefined,
    viewListInfo: ReadonlyCompleteMap<ObjectStringKeys<T>, ListColumnInfo>,
  ): { selectedColumns: ObjectStringKeys<T>[] } {
    const sanitizedSelectedColumns = (selectedColumns ? [...selectedColumns] : [])
      .filter((columnKey) => {
        const colInfo = viewListInfo.get(columnKey);

        return (colInfo && colInfo.selectable) || false;
      });

    return {
      selectedColumns: sanitizedSelectedColumns,
    };
  }

  /**
   * Clones the list options so that the resulting object can be used
   * directly in the stores' `patchState()` method.
   */
  protected cloneListOptions(
    options: GenericListOptions,
    viewListInfo: ReadonlyCompleteMap<string, ListColumnInfo>,
  ): GenericListOptions {
    const {
      pageIndex,
      pageSize,
      columnFilters,
      sortColumn,
      sortDirection,
    } = options;

    const sanitizedColumnFilters: Record<string, any> = {};

    if (columnFilters) {
      Object.keys(columnFilters).forEach((columnKey) => {
        const colInfo = viewListInfo.get(columnKey);
        if (
          colInfo
          && colInfo.selectable
          && colInfo.filterable
        ) {
          sanitizedColumnFilters[columnKey] = columnFilters[columnKey];
        }
      });
    }

    const sanitizedSortColumn = (
      sortColumn
      && viewListInfo.has(sortColumn)
      && viewListInfo.get(sortColumn).sortable
        ? sortColumn
        : ''
    );

    return {
      pageIndex: (pageIndex > 0 ? pageIndex : 0),
      pageSize: (pageSize > 0 ? pageSize : 10),
      columnFilters: sanitizedColumnFilters,
      sortColumn: sanitizedSortColumn,
      sortDirection,
    };
  }

  /**
   * Clones the list settings so that the resulting object can be used
   * directly in the stores' `patchState()` method.
   */
  protected cloneListSettings(
    settings: GenericListStateModel,
    viewListInfo: ReadonlyCompleteMap<string, ListColumnInfo>,
  ): GenericListStateModel {
    const {
      selectedColumns,
      pageIndex,
      pageSize,
      columnFilters,
      sortColumn,
      sortDirection,
    } = settings;

    const sanitizedSelectedColumns = (selectedColumns ? [...selectedColumns] : [])
      .filter((columnKey) => {
        const colInfo = viewListInfo.get(columnKey);

        return (colInfo && colInfo.selectable) || false;
      });

    const sanitizedColumnFilters: Record<string, any> = {};
    if (columnFilters) {
      Object.keys(columnFilters).forEach((columnKey) => {
        if (
          sanitizedSelectedColumns.includes(columnKey)
          && viewListInfo.get(columnKey).filterable
        ) {
          sanitizedColumnFilters[columnKey] = columnFilters[columnKey];
        }
      });
    }

    const sanitizedSortColumn = (
      sortColumn
      && sanitizedSelectedColumns.includes(sortColumn)
      && viewListInfo.get(sortColumn).sortable
        ? sortColumn
        : ''
    );

    return {
      selectedColumns: sanitizedSelectedColumns,
      pageIndex: (pageIndex > 0 ? pageIndex : 0),
      pageSize: (pageSize > 0 ? pageSize : 10),
      columnFilters: sanitizedColumnFilters,
      sortColumn: sanitizedSortColumn,
      sortDirection,
    };
  }
}
