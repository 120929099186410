import moment from 'moment';
import {
  COLLAPP_DATE_FORMAT,
  COLLAPP_DATE_TIME_FORMAT,
  COLLAPP_LONG_MONTH_YEAR_FORMAT,
  COLLAPP_SHORT_DAY_MONTH_FORMAT,
  COLLAPP_SHORT_MONTH_YEAR_FORMAT,
  COLLAPP_SHORT_WEEK_DAY_FORMAT,
} from '../../collapp-core/date';
import { isEmpty } from '../../helpers/utilities';

export type DateFormatPatterns =
    'date'
    | 'datetime'
    | 'shortMonthDate'
    | 'longMonthDate'
    | 'shortDayMonth'
    | 'shortWeekDay';
const DATE_DEFAULT_FORMAT = 'date';

/**
 * Formats a given date according to the format.
 *
 * Possible predefined values for the format are
 *  - date: Year, Month and Day
 *  - datetime: Year, Month and Day as well as Hours and Minutes
 *  - shortMonthDate: Month name (short) and Year
 *  - longMonthDate: Month name (long) and Year
 *  - shortDayMonth: Month as number, Day as number
 *  - shortWeekDay: 3-letter week day name
 *
 * @param [utcOffset] - Optional: Format date as if having the given offset from UTC in minutes
 * @param [keepLocalTime=false] - Passing true will keep the same local time, but at the expense of choosing
 * a different point in Universal Time.
 */
export function formatDate(
  date: moment.Moment,
  format?: DateFormatPatterns | string,
  utcOffset?: number | null,
  keepLocalTime?: boolean,
): string {
  if (!date.isValid()) {
    throw new Error('Given date is not valid.');
  }

  let newFormat = format;

  if (isEmpty(newFormat)) {
    newFormat = DATE_DEFAULT_FORMAT;
  }

  switch (newFormat) {
    case 'date':
      newFormat = COLLAPP_DATE_FORMAT;
      break;
    case 'datetime':
      newFormat = COLLAPP_DATE_TIME_FORMAT;
      break;
    case 'shortMonthDate':
      newFormat = COLLAPP_SHORT_MONTH_YEAR_FORMAT;
      break;
    case 'longMonthDate':
      newFormat = COLLAPP_LONG_MONTH_YEAR_FORMAT;
      break;
    case 'shortDayMonth':
      newFormat = COLLAPP_SHORT_DAY_MONTH_FORMAT;
      break;
    case 'shortWeekDay':
      newFormat = COLLAPP_SHORT_WEEK_DAY_FORMAT;
      break;
    default:
      break;
  }

  let newDate = date;

  if (utcOffset != null) {
    newDate = newDate
      .clone()
      .utcOffset(utcOffset, keepLocalTime);
  }

  return newDate.format(newFormat);
}
