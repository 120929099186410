import { DefaultNonProductiveCategoryResponse } from '../../interfaces/responses/default-non-productive-category.response';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class DefaultNonProductiveCategoryResponseModel {
  constructor(
    readonly name: string,
    readonly sapCode: string,
    readonly defaultTimecardCategoryId: number,
    readonly isEnabled: boolean = true,
    readonly hasReportedHours: boolean = false,
  ) {
  }

  static fromJSON(json: DefaultNonProductiveCategoryResponse): DefaultNonProductiveCategoryResponseModel {
    assertRequiredProperties(json, [
      'name',
      'defaultTimecardCategoryId',
    ]);

    return new DefaultNonProductiveCategoryResponseModel(
      json.name,
      json.sapCode || '',
      json.defaultTimecardCategoryId,
      json.isEnabled,
      json.hasReportedHours,
    );
  }

  toJSON(): DefaultNonProductiveCategoryResponse {
    return {
      name: this.name,
      sapCode: this.sapCode,
      defaultTimecardCategoryId: this.defaultTimecardCategoryId,
      isEnabled: this.isEnabled,
      hasReportedHours: this.hasReportedHours,
    };
  }
}
