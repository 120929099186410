import { AvailableProjectDto } from '../../interfaces/dtos/available-project.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AvailableProjectDtoModel {
  constructor(
    readonly projectId: number,
    readonly title: string,
    readonly projectNumber: string = '',
  ) {}

  static fromJSON(json: AvailableProjectDto): AvailableProjectDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'title',
      'projectNumber',
    ]);

    return new AvailableProjectDtoModel(
      json.projectId,
      json.title,
      json.projectNumber ?? undefined,
    );
  }

  clone(): AvailableProjectDtoModel {
    return new AvailableProjectDtoModel(
      this.projectId,
      this.title,
      this.projectNumber,
    );
  }

  toJSON(): AvailableProjectDto {
    return {
      projectId: this.projectId,
      title: this.title,
      projectNumber: this.projectNumber,
    };
  }
}
