import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { UnitPlanningSidepanelStateModel } from './unit-planning-sidepanel-state.model';
import { UnitPlanningSidepanelState } from './unit-planning-sidepanel.state';

@Injectable({
  providedIn: 'root',
})
export class UnitPlanningSidepanelSelectors {
  @Selector([UnitPlanningSidepanelState])
  static isOpen(state: UnitPlanningSidepanelStateModel): boolean {
    return state.isOpen;
  }
}
