import moment from 'moment';
import { ActivityDto } from '../../interfaces/dtos/activity.dto';
import { QuestionStatus } from '../../../models/question-status.enum';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { ActivityFieldDtoModel } from './activity-field.dto.model';
import { ActivityActionType } from '../../../models/activity-action-type.enum';
import { ActivityType } from '../../../models/activity-type.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ActivityDtoModel {
  constructor(
    readonly activityId: number,
    readonly projectId: number,
    readonly createdOn: moment.Moment,
    readonly changingUser: string,
    readonly workPackageId: number | null,
    readonly questionId: number | null,
    readonly workPackageDisplayId: string = '',
    readonly action: ActivityActionType,
    readonly actionText: string,
    readonly workPackageTitle: string = '',
    readonly formerWorkPackageStatus: WorkPackageStatus | null,
    readonly formerQuestionStatus: QuestionStatus | null,
    readonly workPackageStatus: WorkPackageStatus | null,
    readonly questionStatus: QuestionStatus | null,
    readonly comment: string = '',
    readonly activityType: ActivityType,
    readonly activityNotifiedUsers: readonly string[] = [],
    readonly activityFields: readonly ActivityFieldDtoModel[] = [],
    readonly notificationsSent: boolean | null = null,
  ) {
    this.createdOn = createdOn.clone();
    this.activityNotifiedUsers = [...activityNotifiedUsers];
    this.activityFields = [...activityFields];
  }

  static fromJSON(json: ActivityDto): ActivityDtoModel {
    assertRequiredProperties(json, [
      'activityId',
      'projectId',
      'createdOn',
      'changingUser',
    ]);

    return new ActivityDtoModel(
      json.activityId,
      json.projectId,
      moment(json.createdOn)
        .parseZone(),
      json.changingUser,
      json.workPackageId,
      json.questionId,
      json.workPackageDisplayId || '',
      json.action,
      json.actionText,
      json.workPackageTitle || '',
      json.formerWorkPackageStatus,
      json.formerQuestionStatus,
      json.workPackageStatus,
      json.questionStatus,
      json.comment || '',
      json.activityType,
      [...json.activityNotifiedUsers],
      (Array.isArray(json.activityFields) ? json.activityFields : [])
        .map((item) => ActivityFieldDtoModel.fromJSON(item)),
      json.notificationsSent,
    );
  }
}
