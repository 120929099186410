import { UnitSlimDtoWithoutCoordinatorDto } from '../../interfaces/dtos/unit-slim-without-coordinator.dto';
import { UnitLevel } from '../../../models/unit-level.enum';
import { ApiModelNew } from '../types';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitSlimWithoutCoordinatorDtoModel implements ApiModelNew {
  constructor(
    readonly unitId: number,
    readonly code: string,
    readonly name: string,
    readonly level: UnitLevel,
    readonly importPunchedHoursFromEtime: boolean,
    readonly directUserSubmissionOfTimecard: boolean,
  ) {}

  static fromJSON(json: UnitSlimDtoWithoutCoordinatorDto): UnitSlimWithoutCoordinatorDtoModel {
    assertRequiredProperties(json, [
      'unitId',
      'code',
      'name',
      'level',
    ]);

    return new UnitSlimWithoutCoordinatorDtoModel(
      json.unitId,
      json.code,
      json.name,
      json.level,
      json.importPunchedHoursFromEtime,
      json.directUserSubmissionOfTimecard,
    );
  }

  static fromSimilarModel(
    model: Partial<UnitSlimWithoutCoordinatorDtoModel>,
  ): UnitSlimWithoutCoordinatorDtoModel {
    assertRequiredProperties(model, [
      'unitId',
      'code',
      'name',
      'level',
      'importPunchedHoursFromEtime',
      'directUserSubmissionOfTimecard',
    ]);

    return new UnitSlimWithoutCoordinatorDtoModel(
      model.unitId,
      model.code,
      model.name,
      model.level,
      model.importPunchedHoursFromEtime,
      model.directUserSubmissionOfTimecard,
    );
  }

  clone(): UnitSlimWithoutCoordinatorDtoModel {
    return new UnitSlimWithoutCoordinatorDtoModel(
      this.unitId,
      this.code,
      this.name,
      this.level,
      this.importPunchedHoursFromEtime,
      this.directUserSubmissionOfTimecard,
    );
  }

  toJSON(): UnitSlimDtoWithoutCoordinatorDto {
    return {
      unitId: this.unitId,
      code: this.code,
      name: this.name,
      level: this.level,
      importPunchedHoursFromEtime: this.importPunchedHoursFromEtime,
      directUserSubmissionOfTimecard: this.directUserSubmissionOfTimecard,
    };
  }
}
