import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { catchError, mapTo, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { ProjectService } from '../api/services/project.service';
import { ProjectScopeViewResponseModel } from '../api/models/responses/project-scope-view.response.model';
import { SetProjectScopeView } from '../state';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectScopeResolver  {
  constructor(
    private projectService: ProjectService,
    private store: Store,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ProjectScopeViewResponseModel> {
    const { parent } = route;

    if (!parent || !parent.data || !parent.data.project) {
      throw new Error('Resolver is missing a project to resolve.');
    }

    const { project } = parent.data;

    if (project.projectId == null) {
      throw new Error('Resolved project doesn\'t contain an ID.');
    }

    return this.projectService
      .getProjectScopeView$(project.projectId)
      .pipe(
        switchMap((result) => this.store.dispatch(new SetProjectScopeView(result))
          .pipe(
            mapTo(result),
          )),
        catchError((error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'Error loading project scope');

          return throwError(error);
        }),
      );
  }
}
