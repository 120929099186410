import { Selector } from '@ngxs/store';
import { ProjectListState } from './project-list.state';
import { ProjectListOptions } from './project-list.actions';
import { ProjectListStateModel } from './project-list-state.model';

export class ProjectListSelectors {
  @Selector([ProjectListState])
  static selectedColumns(state: ProjectListStateModel): ProjectListStateModel['selectedColumns'] {
    return [...state.selectedColumns];
  }

  @Selector([ProjectListState])
  static options(state: ProjectListStateModel): ProjectListOptions {
    return {
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      columnFilters: { ...state.columnFilters },
      sortColumn: state.sortColumn,
      sortDirection: state.sortDirection,
    };
  }
}
