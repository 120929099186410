/**
 * Action types for TimecardsState
 */
export enum TimecardsActionTypes {
  SetNonProductiveCategoriesListExpandedState = '[Timecards Page] Set Non Productive Categories List Expanded State',
  SetProjectsListExpandedState = '[Timecards Page] Set Projects List Expanded State',
  AddExpandedProjectId = '[Timecards Page] Add Expanded Project Id',
  RemoveExpandedProjectId = '[Timecards Page] Remove Expanded Project Id',
}

export class SetNonProductiveCategoriesListExpandedState {
  static readonly type: string = TimecardsActionTypes.SetNonProductiveCategoriesListExpandedState;

  constructor(readonly expanded: boolean) {}
}

export class SetProjectsListExpandedState {
  static readonly type: string = TimecardsActionTypes.SetProjectsListExpandedState;

  constructor(readonly expanded: boolean) {}
}

export class AddExpandedProjectId {
  static readonly type: string = TimecardsActionTypes.AddExpandedProjectId;

  constructor(readonly projectId: number) {}
}

export class RemoveExpandedProjectId {
  static readonly type: string = TimecardsActionTypes.RemoveExpandedProjectId;

  constructor(readonly projectId: number) {}
}
