import { ProjectRecentListDto } from '../../interfaces/dtos/project-recent-list.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ProjectRecentListDtoModel {
  constructor(
    readonly projectId: number,
    readonly title: string,
  ) {
  }

  static fromJSON(json: ProjectRecentListDto): ProjectRecentListDtoModel {
    assertRequiredProperties(json, [
      'projectId',
      'title',
    ]);

    return new ProjectRecentListDtoModel(
      json.projectId,
      json.title,
    );
  }

  toJSON(): ProjectRecentListDto {
    return {
      projectId: this.projectId,
      title: this.title,
    };
  }
}
