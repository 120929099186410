import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { UserColumnFilters } from '../filter-columns';
import { UserListDto } from '../interfaces/dtos/user-list.dto';
import { ReadonlyCompleteMap } from '../../app.types';

export type UsersListColumnKey = ObjectPropertyNames<UserListDto>;
type UserListWithoutFullname = Omit<UserListDto, 'fullName'>;

// This defines the object that should implement all properties of 'UserListDto' exactly.
// It's used for linting mostly and discarded later.
const usersListColumnsInfoObj: ListColumnsInfo<UserListWithoutFullname> = {
  userId: {
    label: 'User ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  hitachiId: {
    label: 'Hitachi ID',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  globalEmployeeId: {
    label: 'Glob. Emp. ID',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  localEmployeeId: {
    label: 'Loc. Emp. ID',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  firstName: {
    label: 'First Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  lastName: {
    label: 'Last Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  email: {
    label: 'Email',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  abbreviation: {
    label: 'Abbrev.',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  payrollCompany: {
    label: 'Payroll Company',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  costCenter: {
    label: 'Cost Center',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  countryCode: {
    label: 'Country',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  city: {
    label: 'City',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  divisionCode: {
    label: 'Division',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  businessUnitCode: {
    label: 'Business Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  productGroupCode: {
    label: 'Local Group Unit',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  unit: {
    label: 'Unit',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  unitCoordinator: {
    label: 'Unit Coord.',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  validFrom: {
    label: 'Valid From',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  validUntil: {
    label: 'Valid Until',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  hourlyRateCategory: {
    label: 'common.hrc',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  workingHoursPerWeek: {
    label: 'Work. Hours Per Week',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  photoUrl: {
    label: 'Profile Photo',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  role: {
    label: 'Role',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  metadata: {
    label: 'Metadata',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  unitAssignments: {
    label: 'Unit Assignments',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const usersListColumnsInfo: ReadonlyCompleteMap<
UsersListColumnKey,
ListColumnInfo
> = new Map([
  ['userId', usersListColumnsInfoObj.userId],
  ['hitachiId', usersListColumnsInfoObj.hitachiId],
  ['globalEmployeeId', usersListColumnsInfoObj.globalEmployeeId],
  ['localEmployeeId', usersListColumnsInfoObj.localEmployeeId],
  ['firstName', usersListColumnsInfoObj.firstName],
  ['lastName', usersListColumnsInfoObj.lastName],
  ['countryCode', usersListColumnsInfoObj.countryCode],
  ['city', usersListColumnsInfoObj.city],
  ['hourlyRateCategory', usersListColumnsInfoObj.hourlyRateCategory],
  ['workingHoursPerWeek', usersListColumnsInfoObj.workingHoursPerWeek],
  ['email', usersListColumnsInfoObj.email],
  ['abbreviation', usersListColumnsInfoObj.abbreviation],
  ['costCenter', usersListColumnsInfoObj.costCenter],
  ['payrollCompany', usersListColumnsInfoObj.payrollCompany],
  ['divisionCode', usersListColumnsInfoObj.divisionCode],
  ['businessUnitCode', usersListColumnsInfoObj.businessUnitCode],
  ['productGroupCode', usersListColumnsInfoObj.productGroupCode],
  ['validFrom', usersListColumnsInfoObj.validFrom],
  ['validUntil', usersListColumnsInfoObj.validUntil],
  ['photoUrl', usersListColumnsInfoObj.photoUrl],
  ['unit', usersListColumnsInfoObj.unit],
  ['unitCoordinator', usersListColumnsInfoObj.unitCoordinator],
  ['role', usersListColumnsInfoObj.role],
  ['metadata', usersListColumnsInfoObj.metadata],
  ['unitAssignments', usersListColumnsInfoObj.unitAssignments],
]) as ReadonlyCompleteMap<UsersListColumnKey, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const usersListColumnsWithFilters: (keyof UserColumnFilters)[] = Array.from(usersListColumnsInfo.entries())
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof UserColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
