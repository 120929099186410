import { Injectable } from '@angular/core';
import {
  EMPTY, Observable, throwError,
} from 'rxjs';
import moment from 'moment';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';
import {
  filter, map, mapTo, switchMap,
} from 'rxjs/operators';
import { escape } from 'html-escaper';
import { WpMinimalSettingsInterface } from '../../models/wp-minimal-settings.interface';
import { BasicWorkPackageDetailResponseModel } from '../models/responses/basic-work-package-detail.response.model';
import { BasicWorkPackageDetailResponse } from '../interfaces/responses/basic-work-package-detail.response';
import { BasicWorkPackageViewListResponseModel } from '../models/responses/basic-work-package-view-list.response.model';
import { BasicWorkPackageViewListResponse } from '../interfaces/responses/basic-work-package-view-list.response';
import { BasicWorkpackagesRequestOptions } from '../../models/basic-workpackages-request.options';
import { AvailableProjectDtoModel } from '../models/dtos/available-project.dto.model';
import { AvailableProjectListResponseModel } from '../models/responses/available-project-list.response.model';
import { AvailalbeProjectListResponse } from '../interfaces/responses/available-project-list.response';
import { AvailableRequestRequestOptions } from '../../models/available-projects-request.options';
import { LevelInfoResponse } from '../interfaces/responses/level-info.response';
import {
  ProjectColumnFilters,
  QuestionsColumnFilters,
  WorkPackageColumnFilters,
  WorkPackageQuestionColumnFilters,
} from '../filter-columns';
import { ProjectUpdateRequestModel } from '../models/requests/project-update.request.model';
import { ProjectCreateRequestModel } from '../models/requests/project-create.request.model';
import { WorkPackageUpdateStatusRequestModel } from '../models/requests/work-package-update-status.request.model';
import { ProjectStructurePresetUpdateRequestModel } from '../models/requests/project-structure-preset-update.request.model';
import { WorkPackageListResponseModel } from '../models/responses/work-package-list.response.model';
import { WorkPackageCreateRequestModel } from '../models/requests/work-package-create.request.model';
import { WorkPackageUpdateRequestModel } from '../models/requests/work-package-update.request.model';
import { ProjectImportListResponse } from '../interfaces/responses/project-import-list.response';
import { LevelPlanningUpdateRequestModel } from '../models/requests/level-planning-update.request.model';
import { ProjectListResponse } from '../interfaces/responses/project-list.response';
import { ProjectListResponseModel } from '../models/responses/project-list.response.model';
import { ProjectImportListDtoModel } from '../models/dtos/project-import-list.dto.model';
import { ProjectImportListPartialDtoModel } from '../models/dtos/project-import-list-partial.dto.model';
import { WorkPackageViewResponse } from '../interfaces/responses/work-package-view.response';
import { WorkPackageViewResponseModel } from '../models/responses/work-package-view.response.model';
import { ProjectViewResponse } from '../interfaces/responses/project-view.response';
import { ProjectViewResponseModel } from '../models/responses/project-view.response.model';
import { ProjectActivityViewResponse } from '../interfaces/responses/project-activity-view.response';
import { ProjectActivityViewResponseModel } from '../models/responses/project-activity-view.response.model';
import { LevelSaveRequestModel } from '../models/requests/level-save.request.model';
import { ProjectScopeViewResponseModel } from '../models/responses/project-scope-view.response.model';
import { ProjectScopeViewResponse } from '../interfaces/responses/project-scope-view.response';
import { ProjectCreateResponseModel } from '../models/responses/project-create.response.model';
import { ProjectCreateResponse } from '../interfaces/responses/project-create.response';
import { WorkPackageCreateResponseModel } from '../models/responses/work-package-create.response.model';
import { WorkPackageCreateResponse } from '../interfaces/responses/work-package-create.response';
import { ProjectImportListDto } from '../interfaces/dtos/project-import-list.dto';
import { LevelListResponse } from '../interfaces/responses/level-list.response';
import { LevelListResponseModel } from '../models/responses/level-list.response.model';
import { LevelCreateRequestModel } from '../models/requests/level-create.request.model';
import { WorkPackageSupplierSettingsUpdateRequestModel } from '../models/requests/work-package-supplier-settings-update.request.model';
import { WorkPackageSubmitterSettingsUpdateRequestModel } from '../models/requests/work-package-submitter-settings-update.request.model';
import { LevelCreateResponseModel } from '../models/responses/level-create.response.model';
import { LevelCreateResponse } from '../interfaces/responses/level-create.response';
import { WorkPackageStatus, WorkPackageStatusTransition } from '../../models/work-package-status.enum';
import { WorkPackageResourcePlanningViewResponseModel } from '../models/responses/work-package-resource-planning-view.response.model';
import { WorkPackageResourcePlanningViewResponse } from '../interfaces/responses/work-package-resource-planning-view.response';
import { WorkPackageResourcePlanningUpdateRequestModel } from '../models/requests/work-package-resource-planning-update.request.model';
import { ScopeChangeCreateRequestModel } from '../models/requests/scope-change-create.request.model';
import { ScopeChangeUpdateRequestModel } from '../models/requests/scope-change-update.request.model';
import { ProjectUpdateFromSapRequestModel } from '../models/requests/project-update-from-sap.request.model';
import { WorkPackageTransitionInformationResponseModel } from '../models/responses/work-package-transition-information.response.model';
import { WorkPackageTransitionInformationResponse } from '../interfaces/responses/work-package-transition-information.response';
import { WorkPackageConclusion } from '../../models/work-package-conclusion.enum';
import { WorkPackageCloseRequestModel } from '../models/requests/work-package-close.request.model';
import { SuspensionCreateRequestModel } from '../models/requests/suspension-create.request.model';
import { WorkPackageStatusForm } from '../../modules/projects/dialogs/work-package-status-change/work-package-status-change-dialog.component';
import { WorkPackageUploadOutputDocumentsRequestModel } from '../models/requests/work-package-upload-output-documents.request.model';
import { QuestionListResponseModel } from '../models/responses/question-list-response.model';
import { FileDownloadResponse } from '../interfaces/responses/file-download.response';
import { FileDownloadResponseModel } from '../models/responses/file-download.response.model';
import { QuestionListResponse } from '../interfaces/responses/question-list.response';
import { QuestionCreateRequestModel } from '../models/requests/question-create.request.model';
import { QuestionUpdateRequestModel } from '../models/requests/question-update.request.model';
import { QuestionCreateResponseModel } from '../models/responses/question-create.response.model';
import { QuestionCreateResponse } from '../interfaces/responses/question-create.response';
import { ReplyCreateRequestModel } from '../models/requests/reply-create.request.model';
import { ReplyCreateResponse } from '../interfaces/responses/reply-create.response';
import { ReplyCreateResponseModel } from '../models/responses/reply-create.response.model';
import { ConclusionCreateRequestModel } from '../models/requests/conclusion-create.request.model';
import { ConclusionCreateResponse } from '../interfaces/responses/conclusion-create.response';
import { ConclusionCreateResponseModel } from '../models/responses/conclusion-create.response.model';
import { ReopenCreateResponse } from '../interfaces/responses/reopen-create.response';
import { ReopenCreateResponseModel } from '../models/responses/reopen-create.response.model';
import { ReopenCreateRequestModel } from '../models/requests/reopen-create.request.model';
import { ActivityFetchType } from '../../models/activity-fetch-type.enum';
import { WorkPackageDuplicateRequest } from '../interfaces/requests/work-package-duplicate.request';
import { SelectorRequestOptions } from '../../models/selector-request.options';
import { ProjectSelectorDtoModel } from '../models/dtos/project-selector.dto.model';
import { ProjectSelectorListResponse } from '../interfaces/responses/project-selector-list.response';
import WorkPackageReworkCreateRequestModel from '../models/requests/work-package-rework-create.request.model';
import { WorkPackageViewListResponse } from '../interfaces/responses/work-package-view-list.response';
import { WorkPackageViewListResponseModel } from '../models/responses/work-package-view-list.response.model';
import { AttachmentListResponseModel } from '../models/responses/attachment-list.response.model';
import { AttachmentListResponse } from '../interfaces/responses/attachment-list.response';
import { DocumentType } from '../../models/document-type.enum';
import { LevelSwapRequestModel } from '../models/requests/level-swap.request';
import { ReplyListResponse } from '../interfaces/responses/reply-list.response';
import { ReplyListResponseModel } from '../models/responses/reply-list.response.model';
import { QuestionUserNotificationListResponseModel } from '../models/responses/question-user-notification-list.response.model';
import { QuestionUserNotificationListResponse } from '../interfaces/responses/question-user-notification-list.response';
import { QuestionsViewListResponseModel } from '../models/responses/questions-view-list.response.model';
import { QuestionsViewListResponse } from '../interfaces/responses/questions-view-list.response';
import { ListQueryOptions, UnitQuestionsListOptions, WorkPackageListOptions } from '../list-query-options';
import { AttachmentOutdateRequestModel } from '../models/requests/attachment-outdate-request.model';
import { AttachmentOutdateResponse } from '../interfaces/responses/attachment-outdate.response';
import { AttachmentOutdateResponseModel } from '../models/responses/attachment-outdate.response.model';
import { WorkPackageListResponse } from '../interfaces/responses/work-package-list.response';
import { ProjectSelectorListResponseModel } from '../models/responses/project-selector-list.response.model';
import { QuestionAction } from '../../models/question-action.enum';
import FeedbackCreateRequestModel from '../models/requests/feedback-create.request.model';
import { isEmpty } from '../../helpers/utilities';
import { ResponsibleUserDtoModel } from '../models/dtos/responsible-user-dto.model';
import { ResponsibleUserDto } from '../interfaces/dtos/responsible-user.dto';
import { ProjectStructureResponse } from '../interfaces/responses/project-structure.response';
import { ProjectStructureResponseModel } from '../models/responses/project-structure.response.model';
import { ResponsibleResourceDto } from '../interfaces/dtos/responsible-resource.dto';
import { ResponsibleResourceDtoModel } from '../models/dtos/responsible-resource-dto.model';
import { CollAppApiService } from './collapp-api.service';
import { OldPlannedResourcesRequestModel } from '../models/requests/old-planned-resources.request.model';
import { UserSlimDtoModel } from '../models/dtos/user-slim.dto.model';
import { UserSlimDto } from '../interfaces/dtos/user-slim.dto';
import { LevelInfoResponseModel } from '../models/responses/level-info.response.model';
import { WorkPackageMoveRequest } from '../interfaces/requests/work-package-move.request';
import { ProjectStructureFiltersSettingsModel } from '../../models/project-structure-filter-settings.interface';
import { AdhocWorkpackageRequestModel } from '../models/requests/adhoc-workpackage.request.model';
import { ContractDtoModel } from '../models/dtos/contract.dto.model';
import { ContractDto } from '../interfaces/dtos/contract.dto';
import { WorkpackageSupplierSettingsDtoModel } from '../models/dtos/workpackage-supplier-setting.dto.model';
import { WorkpackageSupplierSettingsDto } from '../interfaces/dtos/workpackageSupplierSettings.dto';
import { AdditionalSuppliersUpdateRequest } from '../interfaces/requests/work-package-additional-suppliers-update.request';
import { ProjectPlanningViewRequestModel } from '../models/requests/project-planning-view.request.model';
import { ProjectPlanningViewResponseModel } from '../models/responses/project-planning-view.response.model';
import { ProjectPlanningViewResponse } from '../interfaces/responses/project-planning-view.response';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private apiService: CollAppApiService,
  ) {}

  private static checkWorkPackagePathParameters(projectId: number, workPackageId: number): void {
    if (projectId == null) {
      throw new Error('No Project ID provided.');
    }

    if (workPackageId == null) {
      throw new Error('No Work Package ID provided.');
    }
  }

  /**
   *
   * @param workPackageId
   * @param commentMessage
   * @param supplierUnitId
   */
  private static createStatusUpdateRequestModel(
    workPackageId: number,
    commentMessage?: string,
    supplierUnitId?: number,
    sendNotifications?: boolean,
  ): WorkPackageUpdateStatusRequestModel {
    return new WorkPackageUpdateStatusRequestModel(
      workPackageId,
      commentMessage ? escape(commentMessage) : undefined,
      [],
      supplierUnitId,
      sendNotifications,
    );
  }

  /**
   * Gets an attachments URL including the SAS token
   *
   * @param {number} attachmentId
   * @return {Observable<FileDownloadResponseModel>}
   */
  getDownloadUrl$(attachmentId: string): Observable<FileDownloadResponseModel> {
    return this.apiService
      .getDownloadUrl$(attachmentId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => FileDownloadResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Marks an attachment as outdated
   */
  patchOutdateFile$(
    attachmentId: string,
    request: AttachmentOutdateRequestModel,
  ): Observable<AttachmentOutdateResponseModel> {
    return this.apiService
      .patchOutdateFile$(attachmentId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<AttachmentOutdateResponse>) => AttachmentOutdateResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing project
   *
   * @param {ProjectUpdateRequestModel} request
   * @return {Observable<void>}
   */
  putProject$(request: ProjectUpdateRequestModel): Observable<void> {
    return this.apiService
      .putProjectByProjectId$(request.projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Archive an existing project
   *
   * @return {Observable<void>}
   */
  archiveProject$(projectId: number): Observable<void> {
    return this.apiService
      .archiveProject$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Archive an existing project
   *
   * @return {Observable<void>}
   */
  unarchiveProject$(projectId: number): Observable<void> {
    return this.apiService
      .unarchiveProject$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Exports an existing project
   *
   * @return {Observable<void>}
   */
  exportProject$(projectId: number): Observable<FileDownloadResponseModel> {
    return this.apiService
      .exportProject$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => FileDownloadResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing project from SAP
   *
   * @param {ProjectUpdateFromSapRequestModel} request
   * @return {Observable<void>}
   */
  patchProject$(request: ProjectUpdateFromSapRequestModel): Observable<void> {
    return this.apiService
      .patchProjectByProjectId$(request.projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets a list of open workPackages by project id
   *
   * @param {number} projectId
   * @return {Observable<string[]>}
   */
  getOpenWorkPackageTitles$(projectId: number): Observable<string[]> {
    return this.apiService
      .getOpenWorkPackageTitles$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<string[]>) => response.body!),
      );
  }

  /**
   * Gets a ProjectViewResponse by project id
   * NOTE: "General" will fall away, once the GET endpoint for receiving a single project
   * is not used any more.
   *
   * @param {number} projectId
   * @return {Observable<ProjectViewResponseModel>}
   */
  getProjectView$(projectId: number): Observable<ProjectViewResponseModel> {
    return this.apiService
      .getProjectView$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectViewResponse>) => ProjectViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a ProjectScopeViewResponse by project id
   *
   * @param {number} projectId
   * @return {Observable<ProjectScopeViewResponseModel>}
   */
  getProjectScopeView$(projectId: number): Observable<ProjectScopeViewResponseModel> {
    return this.apiService
      .getProjectScopeView$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectScopeViewResponse>) => ProjectScopeViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a ProjectActivityView by project id and the filtering of the Paginator
   *
   * @param {number} projectId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {ActivityFetchType} [activityFetchType]
   * @return {Observable<ProjectActivityViewResponseModel>}
   */
  getProjectActivityView$(
    projectId: number,
    pageNumber?: number,
    pageSize?: number,
    activityFetchType?: ActivityFetchType,
  ): Observable<ProjectActivityViewResponseModel> {
    return this.apiService
      .getProjectActivityView$(projectId, pageNumber, pageSize, activityFetchType)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectActivityViewResponse>) => ProjectActivityViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a WorkPackageActivityView by project Id and workPackage Id and the filtering of the Paginator
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} pageNumber
   * @param {number} pageSize
   * @param {ActivityFetchType} [activityFetchType]
   * @return {Observable<ProjectActivityViewResponseModel>}
   */
  getWorkPackageActivityView$(
    projectId: number,
    workPackageId: number,
    pageNumber?: number,
    pageSize?: number,
    activityFetchType?: ActivityFetchType,
  ): Observable<ProjectActivityViewResponseModel> {
    return this.apiService
      .getWorkPackageActivityView$(projectId, workPackageId, pageNumber, pageSize, activityFetchType)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectActivityViewResponse>) => ProjectActivityViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  getCurrentAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
  ): Observable<WorkpackageSupplierSettingsDtoModel[]> {
    return this.apiService
      .getCurrentAdditionalSuppliers$(projectId, workPackageId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkpackageSupplierSettingsDto[]>) => response.body!.map(
          (supplier) => WorkpackageSupplierSettingsDtoModel.fromJSON(supplier),
        )),
      );
  }

  getAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
    contractSupplierUnitId: number,
    excludedPairIds: number[] = [],
  ): Observable<ContractDtoModel[]> {
    const excludedString = excludedPairIds.join(';');
    return this.apiService
      .getAdditionalSuppliers$(projectId, workPackageId, contractSupplierUnitId, excludedString)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ContractDto[]>) => response.body!.map((supplier) => ContractDtoModel
          .fromJSON(supplier))),
      );
  }

  /**
   * Get the informations of an Workpackage transition
   */
  getWorkPackageTransitionInformation$(
    projectId: number,
    workPackageId: number,
    to: WorkPackageStatus,
  ): Observable<WorkPackageTransitionInformationResponseModel> {
    return this.apiService
      .getWorkPackageTransitionInformation$(projectId, workPackageId, to)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        // eslint-disable-next-line max-len
        map((response: HttpResponse<WorkPackageTransitionInformationResponse>) => WorkPackageTransitionInformationResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Get the latest project import file's name for a given projectId
   */
  getLatestImportFileForManuallyUpdate$(projectId: number): Observable<string> {
    return this.apiService
      .getLatestImportFileForManuallyUpdate$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<string>) => response.body!),
      );
  }

  /**
   * Get the latest project import file's name for creating a new project
   */
  getLatestImportFileForCreateNewProject$(): Observable<string> {
    return this.apiService
      .getLatestImportFileForCreateNewProject$()
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<string>) => response.body!),
      );
  }

  /**
   * Returns a FileDownloadResponsModel where the download link is impeded.
   *
   * @return {Observable<FileDownloadResponseModel>}
   */
  getProjectExportList$(options: WorkPackageListOptions): Observable<FileDownloadResponseModel> {
    return this.apiService
      .getProjectExportList$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<FileDownloadResponse>) => FileDownloadResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Imports a project file and returns a list of projects including their structure
   *
   * @param {File} file
   * @param {string} [sapNumber]
   * @return {Observable<ProjectImportListPartialDtoModel[]>}
   */
  postProjectsImport$(file: File, sapNumber?: string): Observable<ProjectImportListPartialDtoModel[]> {
    return this.apiService
      .postProjectsImport$(file, sapNumber)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectImportListResponse>) => response.body!
          .items
          .map((project) => ProjectImportListPartialDtoModel.fromJSON(project))),
      );
  }

  /**
   * Imports a project file and returns the project specified by SapNumber in the request
   *
   * @param {File} file
   * @param {string} sapNumber
   * @return {Observable<ProjectImportListDtoModel>}
   */
  postProjectsImportSingleProject$(
    file: File,
    sapNumber: string,
    projectId: number,
  ): Observable<ProjectImportListDtoModel> {
    return this.apiService
      .postProjectsParseImportFile$(file, sapNumber, projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectImportListDto>) => ProjectImportListDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets the Users which have a related Role like WP-Creator, WP-Unit-Coordinator etc.
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   *
   * @return {Observable<QuestionUserNotificationListResponseModel>}
   */
  getQuestionUserNotificationRecipients$(
    projectId: number,
    workPackageId: number,
    questionId: number | null,
    action: QuestionAction,
  ): Observable<QuestionUserNotificationListResponseModel> {
    return this.apiService.getQuestionUserNotificationRecipients$(projectId, workPackageId, questionId, action)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<QuestionUserNotificationListResponse>) => QuestionUserNotificationListResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new conclusion for a question thread in a given work package.
   */
  postWorkPackageQuestionConclude$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    conclude: ConclusionCreateRequestModel,
  ): Observable<ConclusionCreateResponseModel> {
    return this.apiService
      .postWorkPackageQuestionConclude$(projectId, workPackageId, questionId, conclude.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ConclusionCreateResponse>) => ConclusionCreateResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new reopening question for a question thread in a given work package.
   */
  postWorkPackageQuestionReopen$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    reopen: ReopenCreateRequestModel,
  ): Observable<ReopenCreateResponseModel> {
    return this.apiService
      .postWorkPackageQuestionReopen$(projectId, workPackageId, questionId, reopen.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ReopenCreateResponse>) => ReopenCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   *
   * @param projectId
   * @param workPackageId
   * @param questionId
   * @return {Observable<ReplyListResponseModel>}
   */
  getQuestionReplies$(
    projectId: number,
    workPackageId: number,
    questionId: number,
  ): Observable<ReplyListResponseModel> {
    return this.apiService
      .getQuestionReplies$(projectId, workPackageId, questionId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ReplyListResponse>) => ReplyListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new reply to a question thread of a given work package.
   */
  postWorkPackageQuestionReply$(
    projectId: number,
    workPackageId: number,
    questionId: number,
    reply: ReplyCreateRequestModel,
  ): Observable<ReplyCreateResponseModel> {
    return this.apiService
      .postWorkPackageQuestionReply$(projectId, workPackageId, questionId, reply.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ReplyCreateResponse>) => ReplyCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new Question
   */
  postWorkPackageQuestion$(
    projectId: number,
    workPackageId: number,
    request: QuestionCreateRequestModel,
  ): Observable<QuestionCreateResponseModel> {
    return this.apiService
      .postWorkPackageQuestion$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<QuestionCreateResponse>) => QuestionCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing Question
   */
  putWorkPackageQuestion$(
    projectId: number,
    workPackageId: number,
    request: QuestionUpdateRequestModel,
  ): Observable<void> {
    return this.apiService
      .putWorkPackageQuestion$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Creates a new project
   */
  postProject$(request: ProjectCreateRequestModel): Observable<ProjectCreateResponseModel> {
    return this.apiService
      .postProject$(request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectCreateResponse>) => ProjectCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Saves the project structure preset for the current user
   */
  putProjectStructurePreset$(structure: readonly LevelSaveRequestModel[]): Observable<void> {
    const codeMap: string[] = [];

    const populateCodeMapByStructure = (levels: readonly LevelSaveRequestModel[]): void => {
      levels.forEach((level) => {
        if (level.isSelected) {
          if (level.children && level.children.length > 0) {
            codeMap.push(level.codePath!);
            populateCodeMapByStructure(level.children);
          } else {
            codeMap.push(level.codePath!);
          }
        }
      });
    };

    populateCodeMapByStructure(structure);

    const requestModel = new ProjectStructurePresetUpdateRequestModel(codeMap);

    return this.apiService
      .putProjectStructurePreset$(requestModel.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        switchMap(() => EMPTY),
      );
  }

  /**
   * Gets planning of a specific project
   */
  getProjectPlanning$(
    projectId: number,
    request: ProjectPlanningViewRequestModel,
  ): Observable<ProjectPlanningViewResponseModel> {
    return this.apiService
      .getProjectPlanning$(projectId, request)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectPlanningViewResponse>) => ProjectPlanningViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new level
   */
  postProjectLevel$(projectId: number, request: LevelCreateRequestModel): Observable<LevelCreateResponseModel> {
    return this.apiService
      .postProjectLevel$(projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<LevelCreateResponse>) => LevelCreateResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Updates a specific project level
   */
  updateProjectLevel$(
    projectId: number,
    levelId: number,
    request: LevelPlanningUpdateRequestModel,
  ): Observable<void> {
    return this.apiService
      .updateProjectLevel$(projectId, levelId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Swaps the to provided levels' order
   */
  swapProjectLevels$(projectId: number, request: LevelSwapRequestModel): Observable<void> {
    return this.apiService
      .swapProjectLevels$(projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Deletes a specific project level and its children, if any
   */
  deleteProjectLevel$(projectId: number, levelId: number): Observable<void> {
    return this.apiService
      .deleteProjectLevel$(projectId, levelId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets a flat list of levels for the current project and, if given, the current parent level
   */
  getProjectLevels$(projectId: number, parentLevelId?: number): Observable<LevelListResponseModel> {
    return this.apiService
      .getProjectLevels$(projectId, parentLevelId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<LevelListResponse>) => LevelListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a flat list of levels for the current project and, if given, the current parent level
   */
  getLevelInformation$(levelId?: number): Observable<LevelInfoResponseModel> {
    return this.apiService
      .getLevelInformation$(levelId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<LevelInfoResponse>) => LevelInfoResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a nested list of levels for the current project
   */
  getProjectStructure$(projectId: number): Observable<ProjectStructureResponseModel> {
    return this.apiService
      .getProjectStructure$(projectId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectStructureResponse>) => ProjectStructureResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a page of a list of filtered projects
   *
   * @param {number} [pageIndex]
   * @param {number} [pageSize]
   * @param {ProjectColumnFilters} [columnFilters]
   * @param {string} [sortColumn]
   * @param {SortDirection} [sortDirection]
   * @return {Observable<ProjectListResponseModel>}
   */
  getProjects$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: ProjectColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<ProjectListResponseModel> {
    const options = new ListQueryOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);

    return this.apiService
      .getProjects$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectListResponse>) => ProjectListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a page of a list of filtered work packages
   */
  getWorkPackages$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: WorkPackageColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<WorkPackageViewListResponseModel> {
    const options = new WorkPackageListOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);

    return this.apiService
      .getWorkPackages$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageViewListResponse>) => WorkPackageViewListResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Assigns the given work package to the given user
   */
  assignWorkPackageToUser$(projectId: number, workpackageId: number, userId: string): Observable<void> {
    return this.apiService
      .assignWorkPackageToUser$(projectId, workpackageId, userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets a limited list of projects a unit coordinator has access to.
   * Is slimmer than getProjectSelectors() as it only returns the minimal data.
   */
  getProjectsForUnitCoordinator$(
    requestOptions: AvailableRequestRequestOptions,
  ): Observable<readonly AvailableProjectDtoModel[]> {
    return this.apiService
      .getProjectsForUnitCoordinator$(requestOptions)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<AvailalbeProjectListResponse>) => AvailableProjectListResponseModel
          .fromJSON(response.body!)),
        map((response) => response.items),
      );
  }

  /**
   * Gets a page of a list of filtered work packages
  */
  getBasicWorkPackages$(
    requestOptions: BasicWorkpackagesRequestOptions,
  ): Observable<BasicWorkPackageViewListResponseModel> {
    return this.apiService
      .getBasicWorkPackages$(requestOptions)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<BasicWorkPackageViewListResponse>) => BasicWorkPackageViewListResponseModel
          .fromJSON(response.body!)),
      );
  }

  getBasicWorkPackageDetail$(
    projectId: number,
    workPackageId: number,
    userId: string,
  ): Observable<BasicWorkPackageDetailResponseModel> {
    return this.apiService
      .getBasicWorkPackageDetail$(projectId, workPackageId, userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<BasicWorkPackageDetailResponse>) => BasicWorkPackageDetailResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of filtered questions of a unit where the current user is related to
   *
   * @param {number} [pageIndex]
   * @param {number} [pageSize]
   * @param {QuestionsColumnFilters} [columnFilters]
   * @param {string} [sortColumn]
   * @param {SortDirection} [sortDirection]
   * @return {Observable<QuestionsViewListResponseModel>}
   */
  getQuestions$(
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: QuestionsColumnFilters,
    sortColumn?: string,
    sortDirection?: SortDirection,
  ): Observable<QuestionsViewListResponseModel> {
    const options = new UnitQuestionsListOptions(pageIndex, pageSize, columnFilters, sortColumn, sortDirection);

    return this.apiService
      .getQuestions$(options)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<QuestionsViewListResponse>) => QuestionsViewListResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of work packages for a given level
   *
   * @param {number} projectId
   * @param {number} levelId
   * @return {Observable<WorkPackageListResponseModel>}
   */
  getProjectLevelWorkpackages$(projectId: number, levelId: number): Observable<WorkPackageListResponseModel> {
    return this.apiService
      .getProjectLevelWorkpackages$(projectId, levelId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageListResponse>) => WorkPackageListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of work packages for a given level
   *
   * @param {number} projectId
   * @return {Observable<WorkPackageListResponseModel>}
   */
  getProjectWorkpackages$(
    projectId: number,
    orderByLastUpdated: boolean = false,
    filters: ProjectStructureFiltersSettingsModel | null = null,
  ): Observable<WorkPackageListResponseModel> {
    return this.apiService
      .getProjectWorkpackages$(projectId, orderByLastUpdated, filters)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageListResponse>) => WorkPackageListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a WorkPackageViewResponseModel by project id and work package id
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @return {Observable<WorkPackageViewResponseModel>}
   */
  getWorkPackageView$(projectId: number, workPackageId: number): Observable<WorkPackageViewResponseModel> {
    return this.apiService
      .getWorkPackageView$(projectId, workPackageId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageViewResponse>) => WorkPackageViewResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Fetches a list of attachments of a specific type from a work package.
   */
  getWorkPackageAttachments$(
    projectId: number,
    workPackageId: number,
    type: DocumentType,
  ): Observable<AttachmentListResponseModel> {
    return this.apiService
      .getWorkPackageAttachments$(projectId, workPackageId, type)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<AttachmentListResponse>) => AttachmentListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets the view to edit the resource planning of a work package.
   */
  getWorkPackageResourcePlanningView$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
  ): Observable<WorkPackageResourcePlanningViewResponseModel> {
    return this.apiService
      .getWorkPackageResourcePlanningView$(projectId, workPackageId, year, week, numberOfWeeks)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        // eslint-disable-next-line max-len
        map((response: HttpResponse<WorkPackageResourcePlanningViewResponse>) => WorkPackageResourcePlanningViewResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Gets the user's capacity and planning information
   */
  getResponsibleUserCapacity$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
    userId: string,
  ): Observable<ResponsibleUserDtoModel> {
    return this.apiService
      .getResponsibleUserCapacity$(projectId, workPackageId, year, week, numberOfWeeks, userId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ResponsibleUserDto>) => ResponsibleUserDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets the resource's capacity and planning information
   */
  getResponsibleResourceCapacity$(
    projectId: number,
    workPackageId: number,
    year: number,
    week: number,
    numberOfWeeks: number,
    nonHumanResourceId: string,
  ): Observable<ResponsibleResourceDtoModel> {
    return this.apiService
      .getResponsibleResourceCapacity$(projectId, workPackageId, year, week, numberOfWeeks, nonHumanResourceId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ResponsibleResourceDto>) => ResponsibleResourceDtoModel.fromJSON(response.body!)),
      );
  }

  /**
   * Gets a list of questions for a given work package.
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {number} [pageIndex]
   * @param {number} [pageSize]
   * @param {WorkPackageQuestionColumnFilters} [columnFilters]
   * @param {number} [questionId]
   * @return {Observable<QuestionListResponseModel>}
   */
  getWorkPackageQuestions$(
    projectId: number,
    workPackageId: number,
    pageIndex?: number,
    pageSize?: number,
    columnFilters?: WorkPackageQuestionColumnFilters,
    questionId?: number,
  ): Observable<QuestionListResponseModel> {
    return this.apiService
      .getWorkPackageQuestions$(projectId, workPackageId, pageIndex, pageSize, columnFilters, questionId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<QuestionListResponse>) => QuestionListResponseModel.fromJSON(response.body!)),
      );
  }

  /**
   * Creates a new Work Package within a project
   */
  postWorkPackage$(
    projectId: number,
    request: WorkPackageCreateRequestModel,
  ): Observable<WorkPackageCreateResponseModel> {
    return this.apiService
      .postWorkPackage$(projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageCreateResponse>) => WorkPackageCreateResponseModel
          .fromJSON(response.body!)),
      );
  }

  /**
   * Updates an existing Work Package within a project
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @param {WorkPackageUpdateRequest} request
   * @returns {Observable<void>}
   */
  putWorkPackage$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUpdateRequestModel,
  ): Observable<void> {
    return this.apiService
      .putWorkPackage$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  putAdditionalSuppliers$(
    projectId: number,
    workPackageId: number,
    request: AdditionalSuppliersUpdateRequest,
  ): Observable<void> {
    return this.apiService
      .updateAdditionalSuppliers$(projectId, workPackageId, request)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates an existing Work Package within a project
   */
  duplicateWorkPackage$(
    originalProjectId: number,
    originalWorkPackageId: number,
    request: WorkPackageDuplicateRequest,
  ): Observable<WorkPackageCreateResponseModel> {
    return this.apiService
      .duplicateWorkPackage$(originalProjectId, originalWorkPackageId, request)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageCreateResponse>) => WorkPackageCreateResponseModel
          .fromJSON(response.body!)),
      );
  }

  moveWorkPackage$(
    originalProjectId: number,
    originalWorkPackageId: number,
    request: WorkPackageMoveRequest,
  ): Observable<void> {
    return this.apiService
      .moveWorkPackage$(originalProjectId, originalWorkPackageId, request)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Deletes a specific Work Package
   *
   * @param {number} projectId
   * @param {number} workPackageId
   * @returns {Observable<void>}
   */
  deleteWorkPackage$(projectId: number, workPackageId: number): Observable<void> {
    return this.apiService
      .deleteWorkPackage$(projectId, workPackageId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Saves the resource planning of a work package.
   */
  saveWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageResourcePlanningUpdateRequestModel,
  ): Observable<void> {
    return this.apiService
      .saveWorkPackageResourcePlanning$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Cancels the resource planning of a work package.
   */
  cancelWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: OldPlannedResourcesRequestModel,
  ): Observable<void> {
    return this.apiService
      .cancelWorkPackageResourcePlanning$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Confirms the resource planning of a work package.
   */
  confirmWorkPackageResourcePlanning$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageResourcePlanningUpdateRequestModel,
  ): Observable<void> {
    return this.apiService
      .confirmWorkPackageResourcePlanning$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Sets the work package to its next state by comparing from and to.
   * @param transition
   * @param workPackageStatusForm
   * @return {Observable<void>}
   */
  // eslint-disable-next-line complexity, max-lines-per-function
  doWorkPackageStatusTransition$(
    transition: WorkPackageStatusTransition,
    workPackageStatusForm: WorkPackageStatusForm,
  ): Observable<void> {
    const {
      projectId, workPackageId, from, to,
    } = transition;
    const {
      estimatedNewEndDate, rating, conclusion, comment, l5Units, sendNotifications,
    } = workPackageStatusForm;

    if (from === WorkPackageStatus.ReadyForScopeChangeApproval && to === WorkPackageStatus.ScopeChange) {
      return this.putWorkPackageStatusToScopeChange$(projectId, workPackageId, comment, sendNotifications);
    } if (from === WorkPackageStatus.InQualityAssurance && to === WorkPackageStatus.ReadyForApproval) {
      return this.putWorkPackageStatusToReadyForApproval$(projectId, workPackageId, comment, sendNotifications);
    } if (from === WorkPackageStatus.ReadyForApproval && to === WorkPackageStatus.Closed) {
      return this.putWorkPackageStatusToClosed$(
        projectId,
        workPackageId,
        rating!.ratingId,
        // TODO: Conclusion is of type string when coming directly from the raw form values.
        (!isEmpty(conclusion) ? parseInt(conclusion as any, 10) : WorkPackageConclusion.Completed),
        comment,
        sendNotifications,
      );
    } if (to === WorkPackageStatus.Forecasted) {
      return this.putWorkPackageStatusToForecasted$(projectId, workPackageId, comment, sendNotifications);
    } if (to === WorkPackageStatus.Issued) {
      return this.putWorkPackageStatusToIssued$(projectId, workPackageId, comment, sendNotifications);
    } if (to === WorkPackageStatus.Draft) {
      return this.putWorkPackageStatusToDraft$(projectId, workPackageId, comment, sendNotifications);
    } if (to === WorkPackageStatus.Confirmed) {
      return this.putWorkPackageStatusToConfirmed$(projectId, workPackageId, comment, l5Units, sendNotifications);
    } if (to === WorkPackageStatus.OnHold) {
      return this.putWorkPackageStatusToOnHold$(projectId, workPackageId, comment, sendNotifications);
    } if (to === WorkPackageStatus.InQualityAssurance) {
      return this.putWorkPackageStatusToQualityAssurance$(projectId, workPackageId, comment, sendNotifications);
    } if (to === WorkPackageStatus.ReadyToResumeWork) {
      return this.putWorkPackageStatusToReadyToResumeWork$(
        projectId,
        workPackageId,
        estimatedNewEndDate!,
        comment,
        sendNotifications,
      );
    } if (to === WorkPackageStatus.ReadyForScopeChangeApproval) {
      return this.putWorkPackageStatusToReadyForScopeChangeApproval$(
        projectId,
        workPackageId,
        comment,
        sendNotifications,
      );
    } if (to === WorkPackageStatus.InProgress && from === WorkPackageStatus.Draft) {
      return this.selfAssignWorkPackage$(projectId, workPackageId, comment);
    } if (to === WorkPackageStatus.InProgress) {
      return this.putWorkPackageStatusToInProgress$(projectId, workPackageId, comment, sendNotifications);
    }

    return throwError(new Error('No transition type found'));
  }

  /**
   * Updates a specific work package status to Draft
   */
  putWorkPackageStatusToDraft$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToDraft$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Issued
   */
  putWorkPackageStatusToIssued$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToIssued$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Forecasted
   */
  putWorkPackageStatusToForecasted$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToForecasted$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Quality Assurance
   */
  putWorkPackageStatusToQualityAssurance$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToQualityAssurance$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific WorkPackage to status inProgress
   */
  putWorkPackageStatusToClosed$(
    projectId: number,
    workPackageId: number,
    ratingId: number,
    conclusion: WorkPackageConclusion,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = new WorkPackageCloseRequestModel(
      workPackageId,
      conclusion,
      ratingId,
      commentMessage,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToClosed$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific WorkPackage to status inProgress
   */
  putWorkPackageStatusToInProgress$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToInProgress$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific WorkPackage to status inProgress
   */
  selfAssignWorkPackage$(projectId: number, workPackageId: number, commentMessage?: string): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(workPackageId, commentMessage, undefined, false);

    return this.apiService
      .selfAssignWorkPackage$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Confirmed
   */
  putWorkPackageStatusToConfirmed$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    supplierUnitId?: number,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      supplierUnitId,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToConfirmed$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Confirmed
   */
  putWorkPackageStatusToOnHold$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToOnHold$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  createOutputDocumentsUploadRequest$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageUploadOutputDocumentsRequestModel,
  ): Observable<void> {
    return this.apiService
      .postOutputDocuments$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Create a rework entry of the given work package
   */
  createWorkPackageRework$(projectId: number, request: WorkPackageReworkCreateRequestModel): Observable<void> {
    return this.apiService
      .postWorkPackageRework$(projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Create a feedback entry of the given work package
   */
  createFeedback$(projectId: number, request: FeedbackCreateRequestModel): Observable<void> {
    return this.apiService
      .postFeedback$(projectId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Scope Change and creates the related scope change
   */
  createWorkPackageScopeChange$(request: ScopeChangeCreateRequestModel): Observable<void> {
    return this.apiService
      .postWorkPackageScopeChange$(request.projectId, request.workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates an existing scope change
   */
  updateWorkPackageScopeChange$(request: ScopeChangeUpdateRequestModel): Observable<void> {
    return this.apiService
      .putWorkPackageScopeChange$(request.projectId, request.workPackageId, request.scopeChangeId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Deletes an existing scope change
   */
  deleteWorkPackageScopeChange$(
    projectId: number,
    workPackageId: number,
    scopeChangeId: number,
  ): Observable<void> {
    return this.apiService
      .deleteWorkPackageScopeChange$(projectId, workPackageId, scopeChangeId)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Updates a specific work package status to Confirmed
   */
  putWorkPackageStatusToReadyToResumeWork$(
    projectId: number,
    workPackageId: number,
    estimatedNewEndDate: moment.Moment,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = new SuspensionCreateRequestModel(
      workPackageId,
      estimatedNewEndDate,
      commentMessage,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToReadyToResumeWork$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  putWorkPackageStatusToReadyForScopeChangeApproval$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToReadyForScopeChangeApproval$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  putWorkPackageSubmitterSettings$(
    projectId: number,
    workPackageId: number,
    data: WpMinimalSettingsInterface,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);

    const request = new WorkPackageSubmitterSettingsUpdateRequestModel(
      workPackageId,
      data.hourlyRateCategoriesPairs,
      data.projectTypeCode,
      // TODO: This is a tmp fix once we change the Submitter Settings dialog in the wp view we will change this
      typeof  data.projectNumber !== 'string' ? data.projectNumber?.code : data.projectNumber.trim(),
      data.activityItemCategoryNumber,
    );

    return this.apiService
      .putWorkPackageSubmitterSettings$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Post the minimal data to create an ad-hoc Workpackage
   * @param requestData
   * @returns
   */
  // eslint-disable-next-line max-len
  postAdhocWorkPackage$(data: WpMinimalSettingsInterface, userId: string, year?: number, weekNumber?: number): Observable<WorkPackageCreateResponse> {
    const request: AdhocWorkpackageRequestModel = {
      submitterProjectTypeCode: <string>data.projectTypeCode,
      submitterProjectNumber: typeof  data.projectNumber !== 'string' ? data.projectNumber?.code : data.projectNumber.trim(),
      hourlyRateCategories: <string[]>data.hourlyRateCategoriesPairs.map((item) => item.submitterHourlyRateCategory),
      submitterActivityItemCategoryNumber: <string>data.activityItemCategoryNumber,
      year,
      weekNumber,
      userId,
    };
    return this.apiService
      .postAdhocWorkPackage$(request)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<WorkPackageCreateResponse>) => response.body as WorkPackageCreateResponse),
      );
  }

  putWorkPackageSupplierSettings$(
    projectId: number,
    workPackageId: number,
    request: WorkPackageSupplierSettingsUpdateRequestModel,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);

    return this.apiService
      .putWorkPackageSupplierSettings$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  putWorkPackageStatusToScopeChange$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToScopeChange$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  putWorkPackageStatusToReadyForApproval$(
    projectId: number,
    workPackageId: number,
    commentMessage?: string,
    sendNotifications?: boolean,
  ): Observable<void> {
    ProjectService.checkWorkPackagePathParameters(projectId, workPackageId);
    const request = ProjectService.createStatusUpdateRequestModel(
      workPackageId,
      commentMessage,
      undefined,
      sendNotifications,
    );

    return this.apiService
      .putWorkPackageStatusToReadyForApproval$(projectId, workPackageId, request.toJSON())
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        mapTo(undefined),
      );
  }

  /**
   * Gets a limited list of projects a user has access to (create privileges).
   */
  getProjectSelectors$(requestOptions: SelectorRequestOptions): Observable<readonly ProjectSelectorDtoModel[]> {
    return this.apiService
      .getProjectSelectors$(requestOptions)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<ProjectSelectorListResponse>) => ProjectSelectorListResponseModel
          .fromJSON(response.body!)),
        map((response) => response.items),
      );
  }

  getWorkPackageFeedbackRecipients$(
    projectId: number,
    workPackgeId: number,
    isSupplierFeedback: boolean,
  ): Observable<UserSlimDtoModel[]> {
    return this.apiService
      .getWorkPackageFeedbackRecipients$(projectId, workPackgeId, isSupplierFeedback)
      .pipe(
        filter((event) => event.type === HttpEventType.Response),
        map((response: HttpResponse<UserSlimDto[]>) => response.body!.map(UserSlimDtoModel.fromJSON)),
      );
  }
}
