import { RoleDto } from '../../interfaces/dtos/role.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class RoleDtoModel {
  constructor(
    readonly roleId: number,
    readonly name: string,
  ) {
  }

  static fromJSON(json: RoleDto): RoleDtoModel {
    assertRequiredProperties(json, [
      'roleId',
      'name',
    ]);

    return new RoleDtoModel(
      json.roleId,
      json.name,
    );
  }

  clone(): RoleDtoModel {
    return new RoleDtoModel(
      this.roleId,
      this.name,
    );
  }

  toJSON(): RoleDto {
    return {
      roleId: this.roleId,
      name: this.name,
    };
  }
}
