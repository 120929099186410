import moment from 'moment';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { MetadataModel } from '../metadata.model';
import { ResponsibleUserMetadata } from '../../interfaces/metadata';
import { NonHumanResourcePlanningEntryDtoModel } from './non-human-resource-planning-entry.dto.model';
import { CapacityDtoModel } from './capacity.dto.model';
import { NonHumanResourceSlimDtoModel } from './non-human-resource-slim.dto.model';
import { ResponsibleResourceDto } from '../../interfaces/dtos/responsible-resource.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class ResponsibleResourceDtoModel extends NonHumanResourceSlimDtoModel {
  readonly canRemoveResponsibleUser: boolean;

  readonly canRemoveResponsibleUserMessage: string | null;

  readonly canEditResponsibleUser: boolean;

  constructor(
    nonHumanResourceId: string,
    name: string,
    validFrom: moment.Moment | null,
    validUntil: moment.Moment | null,
    /** This is the total of all the planningEntries of the user, even those not visible or transmitted */
    public plannedHours: number,
    public reportedHours: number,
    public plannedHoursNotIncludedInTotal: number,
    public totalPlannedHours: number,
    readonly planningEntries: NonHumanResourcePlanningEntryDtoModel[],
    readonly capacities: CapacityDtoModel[],
    readonly metadata: MetadataModel<ResponsibleUserMetadata> = new MetadataModel(),
    readonly capacityUnit: CapacityUnits | null = null,
  ) {
    super(
      nonHumanResourceId,
      name,
      validFrom,
      validUntil,
    );
    this.planningEntries = [...planningEntries];
    this.capacities = [...capacities];
    this.planningEntries = [...planningEntries];
    this.capacityUnit = capacityUnit;

    this.canRemoveResponsibleUser = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canRemoveResponsibleUser) || false;

    this.canRemoveResponsibleUserMessage = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canRemoveResponsibleUserMessage) || null;

    this.canEditResponsibleUser = (this.metadata
      && this.metadata.fields
      && this.metadata.fields.canEditResponsibleUser) || false;
  }

  get isHWCapacityUnit(): boolean {
    return this.capacityUnit === CapacityUnits.HoursWeek;
  }

  getId(): string {
    return this.nonHumanResourceId;
  }

  getName(): string {
    return this.name;
  }

  static fromJSON(json: ResponsibleResourceDto): ResponsibleResourceDtoModel {
    assertRequiredProperties(json, [
      'capacityUnit',
    ]);

    return new ResponsibleResourceDtoModel(
      json.nonHumanResourceId,
      json.name,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      json.reportedHours || 0,
      json.plannedHoursNotIncludedInTotal || 0,
      json.totalPlannedHours || 0,
      (Array.isArray(json.planningEntries) ? json.planningEntries : [])
        .map((item) => NonHumanResourcePlanningEntryDtoModel.fromJSON(item)),
      (Array.isArray(json.capacities) ? json.capacities : [])
        .map((item) => CapacityDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata || {} as any),
      Object.values(CapacityUnits).filter((cu) => cu.toString() === json.capacityUnit)?.[0],
    );
  }

  toJSON(): ResponsibleResourceDto {
    return Object.assign(super.toJSON(), {
      planningEntries: this.planningEntries.map((item) => item.toJSON()),
      plannedHours: this.plannedHours,
      reportedHours: this.reportedHours,
      plannedHoursNotIncludedInTotal: this.plannedHoursNotIncludedInTotal,
      totalPlannedHours: this.totalPlannedHours,
      capacities: this.capacities.map((item) => item.toJSON()),
      metadata: this.metadata.toJSON(),
      capacityUnit: this.capacityUnit,
    });
  }
}
