import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { AccessControlService } from '../services/access-control.service';
import { DEFAULT_RETURN_URL, RETURN_URL_QUERY_PARAM } from '../shared/constants';
import { atou, getRelativeUrlOrDefault } from '../helpers/login.utility';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(
    private accessControlService: AccessControlService,
    private router: Router,
  ) { }

  /**
   * A guard deciding if a route can be activated.
   * If the guard returns `true`, navigation will continue. If the guard returns `false`,
   * navigation will be cancelled. If the guard returns a `UrlTree`, current navigation will
   * be cancelled and a new navigation will be kicked off to the `UrlTree` returned from the
   * guard.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    if (this.accessControlService.authenticated) {
      return this.accessControlService
        .accessControlPending$
        .pipe(
          skipWhile((accessControlPending) => accessControlPending),
          take(1),
          map(() => {
            if (this.accessControlService.access) {
              let foundReturnUrlCandidate = false;
              let returnUrl = DEFAULT_RETURN_URL;

              // Check the URL params for a return URL.
              if (route.queryParamMap.has(RETURN_URL_QUERY_PARAM)) {
                const returnUrlParam = route.queryParamMap.get(RETURN_URL_QUERY_PARAM);
                returnUrl = getRelativeUrlOrDefault(returnUrlParam, DEFAULT_RETURN_URL);
                foundReturnUrlCandidate = true;

              // Check for a base64 encoded payload
              } else {
                const pathFromHere = route.url
                  .map((urlSegment) => urlSegment.path)
                  .filter((path: string) => path !== '')
                  .join('/');

                if (pathFromHere !== '') {
                  const decodedReturnUrl = atou(pathFromHere);
                  returnUrl = getRelativeUrlOrDefault(decodedReturnUrl, DEFAULT_RETURN_URL);
                  foundReturnUrlCandidate = true;
                }
              }

              if (foundReturnUrlCandidate) {
                const parsedUrl = this.router.parseUrl(returnUrl);

                return parsedUrl;
              }
            }

            return true;
          }),
        );
    }

    // @TODO Sanitize route when returnUrl invalid?
    // this.router.navigate(
    //   [basePath],
    //   {
    //     queryParams: {
    //       returnUrl: (returnUrl === '/' ? null : returnUrl),
    //     },
    //     queryParamsHandling: 'merge',
    //     replaceUrl: true,
    //   }
    // );
    // ???? why true here?
    return true;
  }

  /**
   * A guard deciding if a child route can be activated.
   * If the guard returns `true`, navigation will continue. If the guard returns `false`,
   * navigation will be cancelled. If the guard returns a `UrlTree`, current navigation will
   * be cancelled and a new navigation will be kicked off to the `UrlTree` returned from the
   * guard.
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | boolean {
    return this.canActivate(childRoute);
  }

  // @TODO currently unused. may be needed when sanitizing returnUrl param.
  private getRouteBasePath(route: ActivatedRouteSnapshot): string {
    const isPathlessChildRoute = (
      route.routeConfig
      && (
        route.routeConfig.path === ''
        || route.routeConfig.path === '**'
      )
    );

    // The path of the rootline up until this component.
    const basePath = `/${
      (isPathlessChildRoute ? route.parent! : route)
        .pathFromRoot
        .map((activatedRoute) => activatedRoute.url
          .map((urlSegment) => urlSegment.path)
          .join('/'))
        .filter((path: string) => path !== '')
        .join('/')}`;

    return basePath;
  }
}
