import { Injectable } from '@angular/core';
import { Selector } from '@ngxs/store';
import { UserPlanningSidepanelStateModel } from './user-planning-sidepanel-state.model';
import { UserPlanningSidepanelState } from './user-planning-sidepanel.state';

@Injectable({
  providedIn: 'root',
})
export class UserPlanningSidepanelSelectors {
  @Selector([UserPlanningSidepanelState])
  static isOpen(state: UserPlanningSidepanelStateModel): boolean {
    return state.isOpen;
  }
}
