import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ng-option-text',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./ng-option-text.component.html",
})
export class NgOptionTextComponent {
  @Input()
  tooltip: string | null = null;
}
