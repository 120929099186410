/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import moment from 'moment';
import { CapacityUnits } from '../../../modules/administration/non-human-resources/capacity-units';
import { UserDto } from '../../interfaces/dtos/user.dto';
import { UnitSlimDtoModel } from './unit-slim.dto.model';
import { RoleDtoModel } from './role.dto.model';
import { UserSettingsDtoModel } from './user-settings.dto.model';
import { UserPermissions } from '../../user-permissions';
import { MetadataModel } from '../metadata.model';
import { UserSettingsDto } from '../../interfaces/dtos/user-settings.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

const DEFAULT_USER_SETTINGS: UserSettingsDto = {
  recentProjects: [],
  projectsTable: null,
  unitsTable: null,
  nonHumanResourcesTable: null,
  usersTable: null,
  workPackagesTable: null,
  workPackageQuestionsTable: null,
  questionsTable: null,
  userResourceTable: null,
  attachmentList: null,
  timesheetWithCrossUtilizeUsers: null,
  recentTimesheetUnits: [],
  recentSkillUnits: [],
  recentPlanningUnits: [],
  recentUsedSupplierUnits: [],
  nonHumanResourcesPlanningTable: null,
  activities: null,
  dashboardWorkPackageFilters: [],
  userPlanningTotals: null,
  unitPlanningTotals: null,
  userPlanningRange: null,
  projectPlanningRange: null,
  projectPlanningTotals: null,
  unitPlanningRange: null,
  timecardRange: null,
  timesheetRange: null,
  workPackagePlanningRange: null,
  timesheetTotals: null,
  workPackagePlanningTotals: null,
  workPackageSidePanel: null,
  userPlanningSidePanel: null,
  unitPlanningCapacityUnit: CapacityUnits.HoursWeek,
  projectPlanningCompactFilter: false,
};

export class UserDtoModel {
  readonly fullName: string;

  constructor(
    readonly userId: string,
    readonly activeDirectoryId: string,
    readonly email: string,
    readonly globalEmployeeId: string | null = null,
    readonly localEmployeeId: string | null = null,
    readonly firstName: string = '',
    readonly lastName: string = '',
    readonly photoUrl: string | null = null,
    readonly photoLargeUrl: string | null = null,
    readonly abbreviation: string = '',
    readonly payrollCompany: string | null = null,
    readonly costCenter: string | null = null,
    readonly countryCode: string = '',
    readonly city: string = '',
    readonly divisionCode: string | null = null,
    readonly businessUnitCode: string | null = null,
    readonly productGroupCode: string | null = null,
    readonly validFrom: moment.Moment | null,
    readonly validUntil: moment.Moment | null,
    readonly hourlyRateCategory: string | null = null,
    readonly workingHoursPerWeek: number | null = null,
    readonly timezone: string = '',
    readonly timezoneOffset: number = 0,
    readonly accountEnabled: boolean = false,
    readonly unit: UnitSlimDtoModel | null = null,
    readonly role: RoleDtoModel | null = null,
    readonly displayInLocalLanguage: boolean = false,
    readonly permissions: UserPermissions = {},
    readonly settings: UserSettingsDtoModel = UserSettingsDtoModel.fromJSON(DEFAULT_USER_SETTINGS),
    readonly metadata: MetadataModel = new MetadataModel(),
  ) {
    this.validFrom = (validFrom ? validFrom.clone() : validFrom);
    this.validUntil = (validUntil ? validUntil.clone() : validUntil);

    this.fullName = `${this.firstName} ${this.lastName}`.trim();
  }

  /**
   * Checks if the user has the permissions
   * that were passed.
   * @param permissions
   */
  static fromJSON(json: UserDto): UserDtoModel {
    assertRequiredProperties(json, [
      'userId',
      'activeDirectoryId',
      // 'fullName',
      'email',
    ]);

    return new UserDtoModel(
      json.userId,
      json.activeDirectoryId,
      json.email,
      json.globalEmployeeId,
      json.localEmployeeId,
      json.firstName || '',
      json.lastName || '',
      json.photoUrl,
      json.photoLargeUrl,
      json.abbreviation || '',
      json.payrollCompany,
      json.costCenter,
      json.countryCode || '',
      json.city || '',
      json.divisionCode,
      json.businessUnitCode,
      json.productGroupCode,
      (json.validFrom
        ? moment(json.validFrom)
          .parseZone()
        : null
      ),
      (json.validUntil
        ? moment(json.validUntil)
          .parseZone()
        : null
      ),
      json.hourlyRateCategory,
      json.workingHoursPerWeek,
      json.timezone,
      json.timezoneOffset,
      json.accountEnabled || false,
      (json.unit
        ? UnitSlimDtoModel.fromJSON(json.unit)
        : null
      ),
      (json.role
        ? RoleDtoModel.fromJSON(json.role)
        : null
      ),
      json.displayInLocalLanguage || false,
      json.permissions || {},
      UserSettingsDtoModel.fromJSON(json.settings || DEFAULT_USER_SETTINGS),
      new MetadataModel(json.metadata || {}),
    );
  }

  hasPermissions(permissions?: any): boolean {
    if (!permissions) {
      return true;
    }

    const {
      any: anyOf = [],
      all: allOf = [],
    }: {
      any: string[]; // eslint-disable-line id-blacklist
      all: string[];
    } = permissions;

    if (anyOf.length === 0 && allOf.length === 0) {
      return true;
    }

    const userPermissions = (this.permissions || {});

    let hasPermissions = true;

    if (allOf.length > 0) {
      let allOfIndex = allOf.length;
      while (hasPermissions && allOfIndex > 0) {
        allOfIndex -= 1;
        if (!userPermissions[allOf[allOfIndex]]) {
          hasPermissions = false;
        }
      }
    }

    if (hasPermissions && anyOf.length > 0) {
      hasPermissions = false;

      let anyOfIndex = anyOf.length;
      while (!hasPermissions && anyOfIndex > 0) {
        anyOfIndex -= 1;
        if (userPermissions[anyOf[anyOfIndex]]) {
          hasPermissions = true;
        }
      }
    }

    return hasPermissions;
  }

  clone(overrides?: Partial<UserDtoModel>): UserDtoModel {
    const copy = { ...this, ...overrides };

    return new UserDtoModel(
      copy.userId,
      copy.activeDirectoryId,
      copy.email,
      copy.globalEmployeeId,
      copy.localEmployeeId,
      copy.firstName,
      copy.lastName,
      copy.photoUrl,
      copy.photoLargeUrl,
      copy.abbreviation,
      copy.payrollCompany,
      copy.costCenter,
      copy.countryCode,
      copy.city,
      copy.divisionCode,
      copy.businessUnitCode,
      copy.productGroupCode,
      copy.validFrom ? copy.validFrom : null,
      copy.validUntil ? copy.validUntil : null,
      copy.hourlyRateCategory,
      copy.workingHoursPerWeek,
      copy.timezone,
      copy.timezoneOffset,
      copy.accountEnabled,
      copy.unit ? copy.unit.clone() : null,
      copy.role ? copy.role.clone() : null,
      copy.displayInLocalLanguage,
      copy.permissions,
      copy.settings.clone(),
      copy.metadata,
    );
  }

  toJSON(): UserDto {
    return {
      userId: this.userId,
      activeDirectoryId: this.activeDirectoryId,
      email: this.email,
      globalEmployeeId: this.globalEmployeeId,
      localEmployeeId: this.localEmployeeId,
      firstName: this.firstName,
      lastName: this.lastName,
      fullName: this.fullName,
      photoUrl: this.photoUrl,
      photoLargeUrl: this.photoLargeUrl,
      abbreviation: this.abbreviation,
      payrollCompany: this.payrollCompany,
      costCenter: this.costCenter,
      countryCode: this.countryCode,
      city: this.city,
      divisionCode: this.divisionCode,
      businessUnitCode: this.businessUnitCode,
      productGroupCode: this.productGroupCode,
      validFrom: this.validFrom ? this.validFrom.toJSON() : null,
      validUntil: this.validUntil ? this.validUntil.toJSON() : null,
      // FIXME the return value does not correspond to he model value
      hourlyRateCategory: this.hourlyRateCategory || '',
      // FIXME the return value does not correspond to he model value
      workingHoursPerWeek: this.workingHoursPerWeek || 0,
      timezone: this.timezone,
      timezoneOffset: this.timezoneOffset,
      accountEnabled: this.accountEnabled,
      unit: this.unit ? this.unit.toJSON() : null,
      role: this.role ? this.role.toJSON() : null,
      displayInLocalLanguage: this.displayInLocalLanguage,
      permissions: this.permissions,
      settings: this.settings.toJSON(),
      metadata: this.metadata.toJSON(),
    };
  }
}
