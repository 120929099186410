import { TimesheetTotalsStateModel } from './timesheet-totals-state.model';

/**
 * Action types for TimesheetTotalsState
 */
export enum TimesheetTotalsActionTypes {
  InitTimesheetTotalsSettings = '[WP Planning API] Initialize Timesheet Totals Settings',
  SetTimesheetTotalsSettings = '[Timesheet API] Set Timesheet Totals Settings',
  ResetTimesheetTotalsSettings = '[Timesheet API] Reset Timesheet Totals Settings',
  SaveTimesheetTotalsSettings = '[Timesheet API] Save Timesheet Totals Settings',
}

export class InitTimesheetTotalsSettings {
  static readonly type: string = TimesheetTotalsActionTypes.InitTimesheetTotalsSettings;

  constructor(public settings: TimesheetTotalsStateModel) {}
}

export class SetTimesheetTotalsSettings {
  static readonly type: string = TimesheetTotalsActionTypes.SetTimesheetTotalsSettings;

  constructor(public settings: TimesheetTotalsStateModel) {}
}

export class ResetTimesheetTotalsSettings {
  static readonly type: string = TimesheetTotalsActionTypes.ResetTimesheetTotalsSettings;
}

export class SaveTimesheetTotalsSettings {
  static readonly type: string = TimesheetTotalsActionTypes.SaveTimesheetTotalsSettings;

  constructor(public settings: TimesheetTotalsStateModel) {}
}
