import { TimeTrackingEntryViewDtoModel } from '../api/models/dtos/time-tracking-entry-view.dto.model';

export abstract class TimeTrackingRowModel {
  /**
   * Total hours of the entire row / week.
   */
  readonly totalHours: number;

  constructor(
    readonly days: readonly TimeTrackingEntryViewDtoModel[],
  ) {
    this.days = [...days];

    this.totalHours = this.days
      .reduce((prev, day) => day.value + prev, 0);
  }
}
