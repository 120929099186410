import { LevelSlimDtoModel } from '../dtos/level-slim.dto.model';
import { ProjectSlimResponse } from '../../interfaces/responses/project-slim.response';
import { ContractDtoModel } from '../dtos/contract.dto.model';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';

export class ProjectSlimResponseModel {
  constructor(
    readonly projectId: number,
    readonly projectNumber: string | null,
    readonly title: string | null,
    readonly projectTypeCode: string,
    readonly submitterUnit: UnitSlimDtoModel,
    readonly structure: LevelSlimDtoModel[] = [],
    readonly childUnitContracts: readonly ContractDtoModel[] = [],
  ) {
    if (structure) {
      this.structure = structure.map((level) => level.clone());
    }

    if (childUnitContracts) {
      this.childUnitContracts = childUnitContracts.map((contract) => contract.clone());
    }
  }

  static getLevelPath(levelId?: number, structure: readonly LevelSlimDtoModel[] = []): LevelSlimDtoModel[] {
    if (!levelId || structure.length === 0) {
      return [];
    }

    const structureLen = structure.length;
    let levelPath: LevelSlimDtoModel[] = [];
    let i = 0;

    // Look for work package in level and receive the path
    while (levelPath.length === 0 && i < structureLen) {
      levelPath = structure[i].getLevelPath(levelId);
      i += 1;
    }

    return levelPath;
  }

  static fromJSON(json: ProjectSlimResponse): ProjectSlimResponseModel {
    return new ProjectSlimResponseModel(
      json.projectId,
      json.projectNumber,
      json.title,
      json.projectTypeCode,
      UnitSlimDtoModel.fromJSON(json.submitterUnit),
      Array.isArray(json.structure) ? json.structure.map((level) => LevelSlimDtoModel.fromJSON(level)) : [],
      Array.isArray(json.childUnitContracts)
        ? json.childUnitContracts.map((level) => ContractDtoModel.fromJSON(level))
        : [],
    );
  }

  toJSON(): ProjectSlimResponse {
    return {
      projectId: this.projectId,
      projectNumber: this.projectNumber,
      title: this.title,
      projectTypeCode: this.projectTypeCode,
      submitterUnit: this.submitterUnit.toJSON(),
      structure: this.structure.map((level) => level.toJSON()),
      childUnitContracts: this.childUnitContracts.map((level) => level.toJSON()),
    };
  }
}
