import { Observable } from 'rxjs';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ProjectListStateModel } from './project-list-state.model';
import {
  InitProjectListSettings,
  ResetProjectListOptions,
  ResetProjectListSettings,
  SaveProjectListSettings,
  SetProjectListOptions,
  SetProjectListSelectedColumns,
  SetProjectListSettings,
} from './project-list.actions';
import { projectsListColumnsInfo } from '../../../api/meta-info/projects-list.info';
import { UserService } from '../../../api/services/user.service';
import { PROJECT_LIST_DEFAULT_PAGE_SIZE } from '../../../shared/constants';
import { GenericListState } from '../generic-list/generic-list.state';

/* eslint-disable @typescript-eslint/no-unused-vars */

const defaultProjectListState: ProjectListStateModel = {
  selectedColumns: Array.from(projectsListColumnsInfo.entries())
    .filter(([_key, value]) => (value.selectable && value.selected))
    .map(([key, _value]) => key),
  pageIndex: 0,
  pageSize: PROJECT_LIST_DEFAULT_PAGE_SIZE,
  columnFilters: {},
  sortColumn: '',
  sortDirection: '',
};

/* eslint-enable @typescript-eslint/no-unused-vars */

@State<ProjectListStateModel>({
  name: 'projectList',
  defaults: defaultProjectListState,
})
@Injectable({
  providedIn: 'root',
})
export class ProjectListState extends GenericListState {
  constructor(
    private userService: UserService,
  ) {
    super();
  }

  @Action(SetProjectListSelectedColumns)
  setProjectListSelectedColumns$(
    ctx: StateContext<ProjectListStateModel>,
    { columns }: SetProjectListSelectedColumns,
  ): Observable<void> {
    ctx.patchState(this.cloneSelectedColumns(columns, projectsListColumnsInfo));

    return ctx.dispatch(new SaveProjectListSettings(ctx.getState()));
  }

  @Action(SetProjectListOptions)
  setProjectListOptions$(
    ctx: StateContext<ProjectListStateModel>,
    { options }: SetProjectListOptions,
  ): Observable<void> {
    ctx.patchState(this.cloneListOptions(options, projectsListColumnsInfo));

    return ctx.dispatch(new SaveProjectListSettings(ctx.getState()));
  }

  @Action(ResetProjectListOptions)
  resetProjectListOptions$(ctx: StateContext<ProjectListStateModel>): Observable<void> {
    ctx.patchState({
      columnFilters: {},
    });

    return ctx.dispatch(new SaveProjectListSettings(ctx.getState()));
  }

  @Action(InitProjectListSettings)
  initProjectListSettings(
    ctx: StateContext<ProjectListStateModel>,
    { settings }: InitProjectListSettings,
  ): void {
    ctx.patchState(this.cloneListSettings(settings, projectsListColumnsInfo));
  }

  @Action(SetProjectListSettings)
  setProjectListSettings$(
    ctx: StateContext<ProjectListStateModel>,
    { settings }: SetProjectListSettings,
  ): Observable<void> {
    ctx.patchState(this.cloneListSettings(settings, projectsListColumnsInfo));

    return ctx.dispatch(new SaveProjectListSettings(ctx.getState()));
  }

  @Action(ResetProjectListSettings)
  resetProjectListSettings(ctx: StateContext<ProjectListStateModel>): void {
    ctx.setState(defaultProjectListState);
  }

  @Action(SaveProjectListSettings)
  saveProjectListSettings$(
    ctx: StateContext<ProjectListStateModel>,
    { settings }: SaveProjectListSettings,
  ): Observable<void> {
    return this.userService.saveProjectListSettings$(settings);
  }
}
