import { UnitsViewResponse } from '../../interfaces/responses/units-view.response';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitsViewResponseModel {
  readonly canCreateUnit: boolean;

  constructor(
    readonly metadata: MetadataModel,
  ) {
    this.canCreateUnit = (this.metadata.fields && this.metadata.fields.canCreateUnit) || false;
  }

  static fromJSON(json: UnitsViewResponse): UnitsViewResponseModel {
    assertRequiredProperties(json, [
      'metadata',
    ]);

    return new UnitsViewResponseModel(
      new MetadataModel(json.metadata || {}),
    );
  }

  toJSON(): UnitsViewResponse {
    return {
      metadata: this.metadata.toJSON(),
    };
  }
}
