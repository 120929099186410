import moment from 'moment';
import { LevelListNestedDto } from '../../interfaces/dtos/level-list-nested.dto';
import { UserSlimDtoModel } from './user-slim.dto.model';
import { MilestoneListDtoModel } from './milestone-list.dto.model';
import { LevelListDtoModel } from './level-list.dto.model';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class LevelListNestedDtoModel extends LevelListDtoModel {
  constructor(
    levelId: number,
    title: string,
    projectNumberForSubmitterSettings: string,
    activityItemCategoryNumberForSubmitterSettings: string,
    sortOrder: number,
    isHidden: boolean,
    isDeletedInSap: boolean,
    codePath: string,
    startDate: moment.Moment | null,
    endDate: moment.Moment | null,
    reportedHours: number,
    hasWarningSign: boolean | null,
    warningMessage: string | null,
    hours: number,
    availableHours: number,
    hourlyRate: number,
    remainingHours: number | null,
    cost: number | null,
    costToComplete: number | null,
    responsibleEngineer: UserSlimDtoModel | null,
    readonly children: readonly LevelListNestedDtoModel[],
    hasWorkPackages: boolean,
    milestones: readonly MilestoneListDtoModel[],
    metadata: MetadataModel = new MetadataModel(),
  ) {
    super(
      levelId,
      title,
      projectNumberForSubmitterSettings,
      activityItemCategoryNumberForSubmitterSettings,
      sortOrder,
      isHidden,
      isDeletedInSap,
      codePath,
      startDate,
      endDate,
      reportedHours,
      hours,
      availableHours,
      hourlyRate,
      remainingHours,
      cost,
      costToComplete,
      responsibleEngineer,
      children.length > 0,
      hasWorkPackages,
      milestones,
      metadata,
      hasWarningSign,
      warningMessage,
    );

    this.children = [...children];
  }

  static fromJSON(json: LevelListNestedDto): LevelListNestedDtoModel {
    assertRequiredProperties(json, [
      'levelId',
      'title',
      'sortOrder',
      'isHidden',
      'isDeletedInSap',
    ]);

    const children = (Array.isArray(json.children)
      ? json.children.map((item) => LevelListNestedDtoModel.fromJSON(item))
      : []
    );

    return new LevelListNestedDtoModel(
      json.levelId,
      json.title,
      json.projectNumberForSubmitterSettings,
      json.activityItemCategoryNumberForSubmitterSettings,
      json.sortOrder,
      json.isHidden,
      json.isDeletedInSap,
      (json.codePath ?? undefined),
      json.startDate != null
        ? moment(json.startDate)
          .parseZone()
        : null,
      json.endDate != null
        ? moment(json.endDate)
          .parseZone()
        : null,
      json.reportedHours || LevelListNestedDtoModel.sumReportedHoursFromChildren(children),
      json.hasWarningSign,
      json.warningMessage,
      json.hours || LevelListNestedDtoModel.sumHoursFromChildren(children),
      json.availableHours || LevelListNestedDtoModel.sumAvailableHoursFromChildren(children),
      json.hourlyRate || 0,
      json.remainingHours,
      json.cost,
      json.costToComplete,
      (json.responsibleEngineer
        ? UserSlimDtoModel.fromJSON(json.responsibleEngineer)
        : null
      ),
      children,
      json.hasWorkPackages,
      (Array.isArray(json.milestones)
        ? json.milestones.map((item) => MilestoneListDtoModel.fromJSON(item))
          .sort((a, b) => (a.sortOrder - b.sortOrder))
        : []
      ),
      new MetadataModel(json.metadata || {}),
    );
  }

  // The backend sends us 0 for hours, for the higher levels.
  private static sumHoursFromChildren(children: readonly LevelListNestedDtoModel[]): number {
    return children.reduce((sum, child) => sum + child.hours, 0);
  }

  // The backend sends us 0 for reportedHours, for the higher levels.
  private static sumReportedHoursFromChildren(children: readonly LevelListNestedDtoModel[]): number {
    return children.reduce((sum, child) => sum + child.reportedHours, 0);
  }

  // The backend sends us 0 for availableHours, for the higher levels.
  private static sumAvailableHoursFromChildren(children: readonly LevelListNestedDtoModel[]): number {
    return children.reduce((sum, child) => sum + child.availableHours, 0);
  }
}
