import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from './user-state.model';
import { UserStateActions } from './user.actions';
import { ProjectRecentListDtoModel } from '../../api/models/dtos/project-recent-list.dto.model';
import { InitProjectListSettings, ResetProjectListSettings } from '../settings/project-list';
import { InitUnitListSettings, ResetUnitListSettings } from '../settings/unit-list';
import { InitUserListSettings, ResetUserListSettings } from '../settings/user-list';
import { InitWorkPackageListSettings, ResetWorkPackageListSettings } from '../settings/work-package-list';
import {
  InitWorkPackageQuestionListSettings,
  ResetWorkPackageQuestionListSettings,
} from '../settings/work-package-question-list';
import { InitQuestionsListSettings, ResetQuestionsListSettings } from '../settings/questions-list';
import { InitUserResourceListSettings, ResetUserResourceListSettings } from '../settings/user-resource-list';
import { InitAttachmentListSettings, ResetAttachmentListSettings } from '../settings/attachment-list';
import { InitUserTimesheetSettings } from '../settings/timesheet';
import { InitNonHumanResourceListSettings, ResetNonHumanResourceListSettings } from '../settings/non-human-resource-list/non-human-resource-list.actions';
import { InitNonHumanResourcePlanningListSettings, ResetNonHumanResourcePlanningListSettings } from '../settings/non-human-resource-planning-list';
import { InitWorkPackageSettings } from '../settings/work-package';
import { InitWorkpackageSidepanel } from '../settings/work-package-sidepanel/work-package-sidepanel.actions';
import { InitSkillsSettings } from '../settings/skills';
import { InitActivitiesSettings, ResetActivitiesSettings } from '../settings/activities';
import { InitDashboardSettings } from '../settings/dashboard';
import { WorkPackageCombinedFilterType } from '../../modules/dashboard/dashboard-work-packages/filter-types';
import { InitUserPlanningTotalsSettings, ResetUserPlanningTotalsSettings } from '../settings/user-planning-totals';
import {
  InitTimecardRangeSettings,
  InitTimesheetRangeSettings,
  InitTimesheetTotalsSettings,
  InitUnitPlanningRangeSettings,
  InitUnitPlanningSidepanel,
  InitUnitPlanningTotalsSettings,
  InitWorkPackagePlanningRangeSettings,
  InitWorkPackagePlanningTotalsSettings,
  ResetTimecardRangeSettings,
  ResetTimesheetRangeSettings,
  ResetTimesheetTotalsSettings,
  ResetUnitPlanningRangeSettings,
  ResetUnitPlanningTotalsSettings,
  ResetWorkPackagePlanningRangeSettings,
  ResetWorkPackagePlanningTotalsSettings,
  InitUnitPlanningSidepanelIndex,
  InitUserPlanningSidepanelIndex,
  InitWorkPackageSidepanelIndex,
} from '../settings';

import { InitUserPlanningRangeSettings, ResetUserPlanningRangeSettings } from '../settings/user-planning-range/user-planning-range.actions';
import { InitUserPlanningSidepanel } from '../settings/user-planning-sidepanel';
import { InitProjectPlanningRangeSettings, ResetProjectPlanningRangeSettings } from '../settings/project-planning-range/project-planning-range.actions';
import { InitProjectPlanningTotalsSettings, ResetProjectPlanningTotalsSettings } from '../settings/project-planning-totals/project-planning-totals.actions';
import { InitUnitPlanningSettings } from '../settings/unit-planning/unit-planning.actions';

const MAX_NUMBER_OF_RECENT_PROJECTS = 4; // Will be 1 less project in frontend

export const defaultUserState: Readonly<UserStateModel> = {
  user: null,
  originalUser: null,
  permissions: {},
  recentProjects: [],
  recentTimecard: null,
};

@State<UserStateModel>({
  name: 'user',
  defaults: { ...defaultUserState },
})
@Injectable({
  providedIn: 'root',
})
export class UserState {
  // eslint-disable-next-line max-lines-per-function
  @Action(UserStateActions.SetLoginUser)
  // eslint-disable-next-line complexity, max-len
  setLoginUser({ patchState, dispatch }: StateContext<UserStateModel>, { user }: UserStateActions.SetLoginUser): Observable<any> {
    patchState({
      user,
      permissions: user.permissions || {},
    });

    let recentProjects = [] as readonly ProjectRecentListDtoModel[];
    if (user.settings && user.settings.recentProjects) {
      recentProjects = user.settings.recentProjects;
    }

    const projectListSettingsAction = (user.settings.projectsTable != null
      ? new InitProjectListSettings(user.settings.projectsTable)
      : new ResetProjectListSettings()
    );

    const unitListSettingsAction = (user.settings.unitsTable != null
      ? new InitUnitListSettings(user.settings.unitsTable)
      : new ResetUnitListSettings()
    );

    const nonHumanResourceListSettingsAction = (user.settings.nonHumanResourcesTable != null
      ? new InitNonHumanResourceListSettings(user.settings.nonHumanResourcesTable)
      : new ResetNonHumanResourceListSettings()
    );

    const userListSettingsAction = (user.settings.usersTable != null
      ? new InitUserListSettings(user.settings.usersTable)
      : new ResetUserListSettings()
    );

    const workPackageListSettingsAction = (user.settings.workPackagesTable != null
      ? new InitWorkPackageListSettings(user.settings.workPackagesTable)
      : new ResetWorkPackageListSettings()
    );

    const activitiesSettingsAction = (user.settings.activities != null
      ? new InitActivitiesSettings(user.settings.activities)
      : new ResetActivitiesSettings()
    );

    const workPackageQuestionListSettingsAction = (user.settings.workPackageQuestionsTable != null
      ? new InitWorkPackageQuestionListSettings(user.settings.workPackageQuestionsTable)
      : new ResetWorkPackageQuestionListSettings()
    );

    const questionsListSettingsAction = (user.settings.questionsTable != null
      ? new InitQuestionsListSettings(user.settings.questionsTable)
      : new ResetQuestionsListSettings()
    );

    const userResourceListSettingsAction = (user.settings.userResourceTable != null
      ? new InitUserResourceListSettings(user.settings.userResourceTable)
      : new ResetUserResourceListSettings()
    );

    const attachmentsListSettingsAction = (user.settings.attachmentList != null
      ? new InitAttachmentListSettings(user.settings.attachmentList)
      : new ResetAttachmentListSettings()
    );

    const nonHumanResourcePlanningListSettingsAction = (user.settings.nonHumanResourcesPlanningTable != null
      ? new InitNonHumanResourcePlanningListSettings(user.settings.nonHumanResourcesPlanningTable)
      : new ResetNonHumanResourcePlanningListSettings()
    );

    const timesheetSettingsAction = new InitUserTimesheetSettings(
      user.settings.recentTimesheetUnits,
      user.settings.timesheetWithCrossUtilizeUsers || false,
    );

    const skillsSettingsAction = new InitSkillsSettings(
      user.settings.recentSkillUnits,
    );

    const unitPlanningSettingsAction = new InitUnitPlanningSettings(
      user.settings.recentPlanningUnits,
    );

    const workPackageSettingsAction = new InitWorkPackageSettings(
      user.settings.recentUsedSupplierUnits,
    );

    const workPackageSidepanelAction = new InitWorkpackageSidepanel({ isOpen: !!user.settings?.workPackageSidePanel });
    const userPlanningSidePanelAction = new InitUserPlanningSidepanel({
      isOpen: !!user.settings?.userPlanningSidePanel,
    });
    const unitPlanningSidePanelAction = new InitUnitPlanningSidepanel({
      isOpen: !!user.settings?.unitPlanningSidePanel,
    });

    const unitPlanningSidePanelIndexAction = new InitUnitPlanningSidepanelIndex({
      index: user.settings?.unitPlanningSidePanelIndex || 0,
    });
    const userPlanningSidePanelIndexAction = new InitUserPlanningSidepanelIndex({
      index: user.settings?.userPlanningSidePanelIndex || 0,
    });
    const workPackagePlanningSidePanelIndexAction = new InitWorkPackageSidepanelIndex({
      index: user.settings?.workPackageSidePanelIndex || 0,
    });

    const dashboardSettingsAction = new InitDashboardSettings({
      workPackagesFilter: WorkPackageCombinedFilterType.TO_BE_ISSUED,
      dashboardWorkPackageFilters: user.settings.dashboardWorkPackageFilters || [],
    });

    const userPlanningTotalsSettingsAction = (user.settings.userPlanningTotals != null
      ? new InitUserPlanningTotalsSettings({ selectedTotals: user.settings.userPlanningTotals })
      : new ResetUserPlanningTotalsSettings()
    );

    const unitPlanningTotalsSettingsAction = (user.settings.unitPlanningTotals != null
      && user.settings.unitPlanningCapacityUnit != null
      ? new InitUnitPlanningTotalsSettings({
        selectedTotals: user.settings.unitPlanningTotals,
        selectedCapacityUnit: user.settings.unitPlanningCapacityUnit,
      })
      : new ResetUnitPlanningTotalsSettings()
    );

    const unitPlanningRangeSettingsAction = (user.settings.unitPlanningRange != null
      ? new InitUnitPlanningRangeSettings({ range: user.settings.unitPlanningRange })
      : new ResetUnitPlanningRangeSettings()
    );

    const projectPlanningTotalsSettingsAction = (
      user.settings.unitPlanningCapacityUnit != null && user.settings.projectPlanningTotals != null
        ? new InitProjectPlanningTotalsSettings({
          selectedTotals: user.settings.projectPlanningTotals,
          selectedCapacityUnit: user.settings.projectPlanningCapacityUnit,
        })
        : new ResetProjectPlanningTotalsSettings()
    );

    const projectPlanningRangeSettingsAction = (user.settings.projectPlanningRange != null
      ? new InitProjectPlanningRangeSettings({ range: user.settings.projectPlanningRange })
      : new ResetProjectPlanningRangeSettings()
    );

    const userPlanningRangeSettingsAction = (user.settings.userPlanningRange != null
      ? new InitUserPlanningRangeSettings({ range: user.settings.userPlanningRange })
      : new ResetUserPlanningRangeSettings()
    );

    const timecardRangeSettingsAction = (user.settings.timecardRange != null
      ? new InitTimecardRangeSettings({ range: user.settings.timecardRange })
      : new ResetTimecardRangeSettings()
    );

    const timesheetRangeSettingsAction = (user.settings.timesheetRange != null
      ? new InitTimesheetRangeSettings({ range: user.settings.timesheetRange })
      : new ResetTimesheetRangeSettings()
    );

    const workPackagePlanningRangeSettingsAction = (user.settings.workPackagePlanningRange != null
      ? new InitWorkPackagePlanningRangeSettings({ range: user.settings.workPackagePlanningRange })
      : new ResetWorkPackagePlanningRangeSettings()
    );

    const workPackagePlanningTotalsSettingsAction = (user.settings.workPackagePlanningTotals != null
      ? new InitWorkPackagePlanningTotalsSettings({ selectedTotals: user.settings.workPackagePlanningTotals })
      : new ResetWorkPackagePlanningTotalsSettings()
    );

    const timesheetTotalsSettingsAction = (user.settings.timesheetTotals != null
      ? new InitTimesheetTotalsSettings({ selectedTotals: user.settings.timesheetTotals })
      : new ResetTimesheetTotalsSettings()
    );

    return dispatch([
      new UserStateActions.SetRecentProjects(recentProjects),
      projectListSettingsAction,
      unitListSettingsAction,
      nonHumanResourceListSettingsAction,
      userListSettingsAction,
      workPackageListSettingsAction,
      workPackageQuestionListSettingsAction,
      questionsListSettingsAction,
      userResourceListSettingsAction,
      attachmentsListSettingsAction,
      nonHumanResourcePlanningListSettingsAction,
      timesheetSettingsAction,
      unitPlanningSettingsAction,
      workPackageSettingsAction,
      skillsSettingsAction,
      activitiesSettingsAction,
      dashboardSettingsAction,
      userPlanningTotalsSettingsAction,
      unitPlanningTotalsSettingsAction,
      unitPlanningRangeSettingsAction,
      userPlanningRangeSettingsAction,
      timecardRangeSettingsAction,
      timesheetRangeSettingsAction,
      workPackagePlanningRangeSettingsAction,
      workPackagePlanningTotalsSettingsAction,
      timesheetTotalsSettingsAction,
      workPackageSidepanelAction,
      userPlanningSidePanelAction,
      unitPlanningSidePanelAction,
      unitPlanningSidePanelIndexAction,
      userPlanningSidePanelIndexAction,
      workPackagePlanningSidePanelIndexAction,
      projectPlanningTotalsSettingsAction,
      projectPlanningRangeSettingsAction,
    ]);
  }

  @Action(UserStateActions.ClearLoginUser)
  clearLoginUser({ patchState }: StateContext<UserStateModel>): void {
    patchState({
      user: null,
      permissions: {},
    });
  }

  @Action(UserStateActions.ClearAllUserData)
  clearAllUserData({ setState }: StateContext<UserStateModel>): void {
    setState({ ...defaultUserState });
  }

  @Action(UserStateActions.SetOriginalUser)
  setOriginalUser({ patchState }: StateContext<UserStateModel>, { user }: UserStateActions.SetLoginUser): void {
    patchState({
      originalUser: user,
    });
  }

  @Action(UserStateActions.ClearOriginalUser)
  clearOriginalUser({ patchState }: StateContext<UserStateModel>): void {
    patchState({
      originalUser: null,
    });
  }

  @Action(UserStateActions.AddRecentProject)
  // eslint-disable-next-line max-len
  addRecentProject({ patchState, getState }: StateContext<UserStateModel>, { project }: UserStateActions.AddRecentProject): void {
    let { recentProjects } = getState();

    // Check if given project already exists in queue
    const existingProjectIndex = recentProjects.findIndex((element) => (element.projectId === project.projectId));

    // Create a new array of projects with the most recent in front.
    recentProjects = [project, ...recentProjects];
    if (existingProjectIndex !== -1) {
      // Remove duplicates
      recentProjects.splice(existingProjectIndex + 1, 1);
    }

    // Limit queue to a given maximum number of entries.
    recentProjects.length = Math.min(recentProjects.length, MAX_NUMBER_OF_RECENT_PROJECTS);

    patchState({
      recentProjects,
    });
  }

  @Action(UserStateActions.SetRecentProjects)
  // eslint-disable-next-line max-len
  setRecentProjects({ patchState }: StateContext<UserStateModel>, { projects }: UserStateActions.SetRecentProjects): void {
    const recentProjects = [...projects];
    recentProjects.length = Math.min(recentProjects.length, MAX_NUMBER_OF_RECENT_PROJECTS);
    patchState({
      recentProjects,
    });
  }

  @Action(UserStateActions.SetRecentTimecard)
  // eslint-disable-next-line max-len
  setRecentTimecard({ patchState }: StateContext<UserStateModel>, { timecard }: UserStateActions.SetRecentTimecard): void {
    patchState({
      recentTimecard: timecard,
    });
  }

  @Action(UserStateActions.UpdateTimecard)
  updateTimecard({ patchState }: StateContext<UserStateModel>): void {
    patchState({});
  }

  /**
   * Update ProjectStructureFilterSettings into UserSettings
   * @param ctx
   * @param action
   */
  @Action(UserStateActions.UpdateProjectStructureFilterSettings)
  // eslint-disable-next-line max-len
  updateSettingsProjectStructureFilters(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateProjectStructureFilterSettings): void {
    const { user } = ctx.getState();
    if (user) {
      user.settings.projectsStrucureHeaderFilters = action.projectStructureHeaderFilterSettings;
      ctx.patchState({ user });
    }
  }

  /**
   * Update ProjectPlanningFilterSettings into UserSettings
   * @param ctx
   * @param action
   */
  @Action(UserStateActions.UpdateProjectPlanningFilterSettings)
  // eslint-disable-next-line max-len
  updateSettingsProjectPlanningFilters(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateProjectPlanningFilterSettings): void {
    const { user } = ctx.getState();
    if (user) {
      user.settings.projectPlanningHeaderFilters = action.projectPlanningHeaderFilterSettings;
      ctx.patchState({ user });
    }
  }

  /**
   * Update timecardHeaderFilterSettings into UserSettings
   * @param ctx
   * @param action
   */
  @Action(UserStateActions.UpdateTimecardHeaderFilterSettings)
  // eslint-disable-next-line max-len
  updateTimecardHeaderFilterSettings(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateTimecardHeaderFilterSettings): void {
    const { user } = ctx.getState();
    if (user) {
      user.settings.timecardHeaderfilters = action.filterSettings;
      ctx.patchState({ user });
    }
  }

  /**
   * UpdateplanningHeaderFilterSettings into UserSettings
   * @param ctx
   * @param action
   */
  @Action(UserStateActions.UpdatePlanningHeaderFilterSettings)
  // eslint-disable-next-line max-len
  updatePlanningHeaderFilterSettings(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdatePlanningHeaderFilterSettings): void {
    const { user } = ctx.getState();
    if (user) {
      user.settings.planningHeaderfilters = action.filterSettings;
      ctx.patchState({ user });
    }
  }

  @Action(UserStateActions.UpdateProjectPlanningCompactFilter)
  // eslint-disable-next-line max-len
  updateProjectPlanningCompactFilter(ctx: StateContext<UserStateModel>, action: UserStateActions.UpdateProjectPlanningCompactFilter): void {
    const { user } = ctx.getState();
    if (user) {
      user.settings.projectPlanningCompactFilter = action.projectPlanningCompactFilter;
      ctx.patchState({ user });
    }
  }

  @Action(UserStateActions.UpdateUserTimeZone)
  updateUserTimeZone(
    { getState, patchState }: StateContext<UserStateModel>,
    { timeZone, offset }: UserStateActions.UpdateUserTimeZone,
  ): void {
    let { user } = getState();

    if (user) {
      user = user.clone({
        fullName: user.fullName,
        timezone: timeZone,
        timezoneOffset: offset,
      });

      patchState({
        user,
      });
    }
  }

  @Action(UserStateActions.UpdateUserLocalLanguageSettings)
  updateUserLocalLanguageSettings(
    { getState, patchState }: StateContext<UserStateModel>,
    { useLocal }: UserStateActions.UpdateUserLocalLanguageSettings,
  ): void {
    let { user } = getState();

    if (user) {
      user = user.clone({
        displayInLocalLanguage: useLocal,
      });

      patchState({
        user,
      });
    }
  }
}
