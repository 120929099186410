import { TimeZoneDto } from '../../interfaces/dtos/time-zone.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class TimeZoneDtoModel {
  constructor(
    readonly timezoneId: string,
    readonly offset: number,
  ) {
  }

  static fromJSON(json: TimeZoneDto): TimeZoneDtoModel {
    assertRequiredProperties(json, [
      'timezoneId',
      'offset',
    ]);

    return new TimeZoneDtoModel(
      json.timezoneId,
      json.offset,
    );
  }

  toJSON(): TimeZoneDto {
    return {
      timezoneId: this.timezoneId,
      offset: this.offset,
    };
  }
}
