import { Selector } from '@ngxs/store';
import { ActivitiesState } from './activities.state';
import { ActivitiesStateModel } from './activities-state.model';

export class ActivitiesSelectors {
  @Selector([ActivitiesState])
  static options(state: ActivitiesStateModel): ActivitiesStateModel {
    return {
      pageSize: state.pageSize,
    };
  }
}
