import { AttachmentOutdateRequest } from '../../interfaces/requests/attachment-outdate-request';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class AttachmentOutdateRequestModel {
  constructor(
    readonly attachmentId: string,
    readonly outdatedComment: string,
  ) {
  }

  static fromJSON(json: AttachmentOutdateRequest): AttachmentOutdateRequestModel {
    assertRequiredProperties(json, [
      'attachmentId',
      'outdatedComment',
    ]);

    return new AttachmentOutdateRequestModel(
      json.attachmentId,
      json.outdatedComment,
    );
  }

  toJSON(): AttachmentOutdateRequest {
    return {
      attachmentId: this.attachmentId,
      outdatedComment: this.outdatedComment,
    };
  }
}
