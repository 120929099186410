import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '../collapp-common';

export type BrowserInfo = Record<string, boolean> & {
  name: string;
  version?: string;
  versionNumber?: number;
  platform: string;
};

@Injectable({
  providedIn: 'root',
})
export class BrowserDetectionService {
  private _isBrowser: boolean;

  private _browser: BrowserInfo;

  // eslint-disable-next-line complexity
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) protected readonly _platformId: object,
    @Inject(WINDOW) protected window: Window,
  ) {
    this._isBrowser = isPlatformBrowser(this._platformId);

    const userAgent = (window && window.navigator && window.navigator.userAgent) || '';
    const matched = this.matchUserAgent(userAgent);
    const browser: any = {};

    if (matched.browser) {
      browser[matched.browser] = true;
      browser.version = matched.version;
      browser.versionNumber = parseInt(matched.versionNumber, 10);
    }
    if (matched.platform) {
      browser[matched.platform] = true;
    }
    if (browser.opr) {
      const opera = 'opera';
      matched.browser = opera;
      browser[opera] = true;
    }
    if (browser.rv) {
      const ie = 'msie';
      matched.browser = ie;
      browser[ie] = true;
    }
    if (browser.edge) {
      const edge = 'edge';
      matched.browser = edge;
      browser[edge] = true;
    }
    if (browser.safari && browser.android) {
      const android = 'android';
      matched.browser = android;
      browser[android] = true;
    }

    browser.name = matched.browser;
    browser.platform = matched.platform;

    this._browser = Object.freeze(browser);
  }

  isBrowser(): boolean {
    return this._isBrowser;
  }

  getInfo(): BrowserInfo {
    return this._browser;
  }

  getName(): string {
    return this._browser.name;
  }

  getPlatform(): string {
    return (this._browser.platform === 'win' ? 'windows' : this._browser.platform);
  }

  getVersion(): string | undefined {
    return this._browser.version;
  }

  getVersionNumber(): number | undefined {
    return this._browser.versionNumber;
  }

  isIE(): boolean {
    return this._browser.msie || false;
  }

  isEdge(): boolean {
    return this._browser.edge || false;
  }

  isIEOrEdge(): boolean {
    return this.isIE() || this.isEdge();
  }

  isMobile(): boolean {
    return (this._browser.android || this._browser.ipad || this._browser.iphone || this._browser['windows phone'] || false);
  }

  isDesktop(): boolean {
    return (this._browser.cros || this._browser.mac || this._browser.linux || this._browser.win || false);
  }

  isWebKit(): boolean {
    return (this._browser.chrome || this._browser.opr || this._browser.safari || false);
  }

  // eslint-disable-next-line complexity
  private matchUserAgent(
    userAgent: string,
  ): { browser: string; version: string; versionNumber: string; platform: string } {
    const ua = userAgent.toLowerCase();

    const match = /(edge)\/([\w.]+)/.exec(ua)
      || /(opr)[/]([\w.]+)/.exec(ua)
      || /(chrome)[/]([\w.]+)/.exec(ua)
      || /(version)(applewebkit)[/]([\w.]+).*(safari)[/]([\w.]+)/.exec(ua)
      || /(webkit)[/]([\w.]+).*(version)[/]([\w.]+).*(safari)[/]([\w.]+)/.exec(ua)
      || /(webkit)[/]([\w.]+)/.exec(ua)
      || /(opera)(?:.*version|)[/]([\w.]+)/.exec(ua)
      || /(msie) ([\w.]+)/.exec(ua)
      || (ua.indexOf('trident') >= 0 && /(rv)(?::| )([\w.]+)/.exec(ua))
      || (ua.indexOf('compatible') < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua))
      || [];

    const platformMatch = /(ipad)/.exec(ua)
      || /(iphone)/.exec(ua)
      || /(android)/.exec(ua)
      || /(windows phone)/.exec(ua)
      || /(win)/.exec(ua)
      || /(mac)/.exec(ua)
      || /(linux)/.exec(ua)
      || /(cros)/.exec(ua)
      || [];

    return {
      browser: match[5] || match[3] || match[1] || '',
      version: match[2] || match[4] || '0',
      versionNumber: match[4] || match[2] || '0',
      platform: platformMatch[0] || '',
    };
  }
}
