import moment from 'moment';
import { UnitPlanningWeekTotalDto } from '../../interfaces/dtos/unit-planning-week-total.dto';

export class UnitPlanningWeekTotalDtoModel {
  constructor(
    readonly weekStartDate: moment.Moment,
    readonly totalHours: number | null,
  ) {
  }

  static fromJSON(json: UnitPlanningWeekTotalDto): UnitPlanningWeekTotalDtoModel {
    return new UnitPlanningWeekTotalDtoModel(
      moment(json.weekStartDate).parseZone(),
      json.totalHours,
    );
  }

  clone(): UnitPlanningWeekTotalDtoModel {
    return new UnitPlanningWeekTotalDtoModel(
      this.weekStartDate,
      this.totalHours,
    );
  }
}
