import { GenericListOptions } from '../generic-list';
import { WorkPackageQuestionColumnFilters } from '../../../api/filter-columns';
import { WorkPackageQuestionListStateModel } from './work-package-question-list-state.model';

/**
 * Action types for WorkPackageQuestionListState
 */
export enum WorkPackageQuestionListActionTypes {
  SetWorkPackageQuestionListSelectedColumns = '[ Work Package Question List Page] Set Selected Columns',
  SetWorkPackageQuestionListOptions = '[ Work Package Question List Page] Set Options',
  InitWorkPackageQuestionListSettings = '[ Work Package Question API] Initialize Work Package Question List Settings',
  SetWorkPackageQuestionListSettings = '[ Work Package Question API] Set Work Package Question List Settings',
  ResetWorkPackageQuestionListSettings = '[ Work Package Question API] Reset Work Package Question List Settings',
  SaveWorkPackageQuestionListSettings = '[ Work Package Question API] Save Work Package Question List Settings',
}

export interface WorkPackageQuestionListOptions extends GenericListOptions {
  columnFilters: WorkPackageQuestionColumnFilters;
}

export class SetWorkPackageQuestionListSelectedColumns {
  static readonly type: string = WorkPackageQuestionListActionTypes.SetWorkPackageQuestionListSelectedColumns;

  constructor(public columns: []) {}
}

export class SetWorkPackageQuestionListOptions {
  static readonly type: string = WorkPackageQuestionListActionTypes.SetWorkPackageQuestionListOptions;

  constructor(public options: WorkPackageQuestionListOptions) {}
}

export class InitWorkPackageQuestionListSettings {
  static readonly type: string = WorkPackageQuestionListActionTypes.InitWorkPackageQuestionListSettings;

  constructor(public settings: WorkPackageQuestionListStateModel) {}
}

export class SetWorkPackageQuestionListSettings {
  static readonly type: string = WorkPackageQuestionListActionTypes.SetWorkPackageQuestionListSettings;

  constructor(public settings: WorkPackageQuestionListStateModel) {}
}

export class ResetWorkPackageQuestionListSettings {
  static readonly type: string = WorkPackageQuestionListActionTypes.ResetWorkPackageQuestionListSettings;
}

export class SaveWorkPackageQuestionListSettings {
  static readonly type: string = WorkPackageQuestionListActionTypes.SaveWorkPackageQuestionListSettings;

  constructor(public settings: WorkPackageQuestionListStateModel) {}
}
