import { ListColumnInfo, ListColumnsInfo, ObjectPropertyNames } from './base';
import { QuestionsColumnFilters } from '../filter-columns';
import { ReadonlyCompleteMap } from '../../app.types';
import { QuestionListItemDtoModel } from '../models/dtos/question-list-item.dto.model';

export type QuestionsViewListColumnKeys = ObjectPropertyNames<QuestionListItemDtoModel>;
const questionsViewListColumnsInfoObj: ListColumnsInfo<QuestionListItemDtoModel> = {
  projectId: {
    label: 'Project Id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  workPackageId: {
    label: 'Work Package ID',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
  workPackageDisplayId: {
    label: 'Work Package ID',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  workPackageTitle: {
    label: 'Work Package Name',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  projectTitle: {
    label: 'Project',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  questionTitle: {
    label: 'Question Title',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  numberOfReplies: {
    label: 'Number of Replies',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  creatorFullName: {
    label: 'Created By',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  creatorWorkPackageRole: {
    label: 'Role of Creator',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  createdOn: {
    label: 'Created On',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  lastRepliedByFullName: {
    label: 'Last Replied By',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  lastRepliedOn: {
    label: 'Last Replied On',
    selected: true,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  status: {
    label: 'Status',
    selected: false,
    selectable: true,
    sortable: true,
    filterable: true,
  },
  questionId: {
    label: 'Question Id',
    selected: false,
    selectable: false,
    sortable: false,
    filterable: false,
  },
};

// Defines the sorting of columns!!!
// The Map is not really readonly - we just pretend that it is :)
export const questionsViewListColumnsInfo: ReadonlyCompleteMap<
QuestionsViewListColumnKeys,
ListColumnInfo
> = new Map([
  ['questionId', questionsViewListColumnsInfoObj.questionId],
  ['workPackageDisplayId', questionsViewListColumnsInfoObj.workPackageDisplayId],
  ['workPackageTitle', questionsViewListColumnsInfoObj.workPackageTitle],
  ['projectTitle', questionsViewListColumnsInfoObj.projectTitle],
  ['questionTitle', questionsViewListColumnsInfoObj.questionTitle],
  ['numberOfReplies', questionsViewListColumnsInfoObj.numberOfReplies],
  ['creatorFullName', questionsViewListColumnsInfoObj.creatorFullName],
  ['creatorWorkPackageRole', questionsViewListColumnsInfoObj.creatorWorkPackageRole],
  ['createdOn', questionsViewListColumnsInfoObj.createdOn],
  ['lastRepliedByFullName', questionsViewListColumnsInfoObj.lastRepliedByFullName],
  ['lastRepliedOn', questionsViewListColumnsInfoObj.lastRepliedOn],
  ['status', questionsViewListColumnsInfoObj.status],
]) as ReadonlyCompleteMap<QuestionsViewListColumnKeys, ListColumnInfo>;

/* eslint-disable @typescript-eslint/no-unused-vars */

export const questionsViewListColumnsWithFilters: (keyof QuestionsColumnFilters)[] = Array.from(
  questionsViewListColumnsInfo.entries(),
)
  .filter(([_key, value]) => value.filterable)
  .map(([key, _value]) => key as keyof QuestionsColumnFilters);

/* eslint-enable @typescript-eslint/no-unused-vars */
