import { GenericListOptions } from '../generic-list';
import { NonHumanResourcePlanningColumnFilters } from '../../../api/filter-columns';
import { NonHumanResourcePlanningListStateModel } from './non-human-resource-planning-list-state.model';

/**
 * Action types for NonHumanResourcePlanningListState
 */
export enum NonHumanResourcePlanningListActionTypes {
  SetNonHumanResourcePlanningListSelectedColumns = '[Resource Planning NHR List Page] Set Selected Columns',
  SetNonHumanResourcePlanningListOptions = '[Resource Planning NHR List Page] Set Options',
  InitNonHumanResourcePlanningListSettings = '[Resource Planning NHR API] Initialize Resource Planning List Settings',
  SetNonHumanResourcePlanningListSettings = '[Resource Planning NHR API] Set Resource Planning List Settings',
  ResetNonHumanResourcePlanningListSettings = '[Resource Planning NHR API] Reset Resource Planning List Settings',
  SaveNonHumanResourcePlanningListSettings = '[Resource Planning NHR API] Save Resource Planning List Settings',
}

export interface NonHumanResourcePlanningListOptions extends GenericListOptions {
  columnFilters: NonHumanResourcePlanningColumnFilters;
}

export class SetNonHumanResourcePlanningListSelectedColumns {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .SetNonHumanResourcePlanningListSelectedColumns;

  constructor(public columns: []) {}
}

export class SetNonHumanResourcePlanningListOptions {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .SetNonHumanResourcePlanningListOptions;

  constructor(public options: NonHumanResourcePlanningListOptions) {}
}

export class InitNonHumanResourcePlanningListSettings {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .InitNonHumanResourcePlanningListSettings;

  constructor(public settings: NonHumanResourcePlanningListStateModel) {}
}

export class SetNonHumanResourcePlanningListSettings {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .SetNonHumanResourcePlanningListSettings;

  constructor(public settings: NonHumanResourcePlanningListStateModel) {}
}

export class ResetNonHumanResourcePlanningListSettings {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .ResetNonHumanResourcePlanningListSettings;
}

export class SaveNonHumanResourcePlanningListSettings {
  static readonly type: string = NonHumanResourcePlanningListActionTypes
    .SaveNonHumanResourcePlanningListSettings;

  constructor(public settings: NonHumanResourcePlanningListStateModel) {}
}
