import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { NgOptionTextComponent } from './ng-option-text.component';
import { NgSelectFormFieldControlDirective } from './ng-select.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  exports: [
    NgSelectModule,
    NgOptionTextComponent,
    NgSelectFormFieldControlDirective,
  ],
  declarations: [
    NgOptionTextComponent,
    NgSelectFormFieldControlDirective,
  ],
})
export class NgSelectMaterialModule {
}
