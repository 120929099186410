import moment from 'moment';
import { LevelImportListDtoMetadata, LevelImportListDtoMetadataFields } from '../../api/interfaces/metadata';
import { ChangedProperty } from '../../api/interfaces/changed-property';
import { decapitalize } from '../../helpers/string.utility';
import { LevelImportListDtoModel } from '../../api/models/dtos/level-import-list.dto.model';
import { UserSlimDtoModel } from '../../api/models/dtos/user-slim.dto.model';
import { MilestoneImportDtoModel } from '../../api/models/dtos/milestone-import.dto.model';
import { MetadataModel } from '../../api/models/metadata.model';

export type BasicChanges<T> = {
  [key in keyof T]?: ChangedProperty;
};

/** Level node with additional state information */
export class LevelModelTreeNode extends LevelImportListDtoModel {
  isSelected: boolean = false;

  readonly children: LevelModelTreeNode[];

  readonly parent: LevelModelTreeNode | null;

  readonly nestingLevel: number;

  readonly expandable: boolean;

  readonly hasChanges: boolean;

  readonly changes: BasicChanges<LevelImportListDtoModel>;

  readonly isDeleted: boolean;

  readonly isDisabled: boolean;

  get selected(): boolean {
    return this.isSelected;
  }

  set selected(value: boolean) {
    this.isSelected = value;
  }

  partiallySelected: boolean = false;

  // eslint-disable-next-line max-lines-per-function
  constructor(
    levelId: number | undefined,
    parent: LevelModelTreeNode | null,
    nestingLevel: number,
    codePath: string,
    levelNumber: number,
    title: string,
    responsibleEngineer: UserSlimDtoModel | undefined,
    projectNumberForSubmitterSettings: string | undefined,
    activityItemCategoryNumberForSubmitterSettings: string | undefined,
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined,
    hours: number,
    hourlyRate: number | undefined,
    cost: number | undefined,
    costToComplete: number | undefined,
    isHidden: boolean,
    isExisting: boolean,
    isSelected: boolean,
    isDeletedInSap: boolean,
    milestones: readonly MilestoneImportDtoModel[],
    children: readonly LevelImportListDtoModel[],
    metadata: MetadataModel<LevelImportListDtoMetadata>,
  ) {
    super(
      levelId,
      codePath,
      levelNumber,
      title,
      responsibleEngineer,
      projectNumberForSubmitterSettings,
      activityItemCategoryNumberForSubmitterSettings,
      startDate,
      endDate,
      hours,
      hourlyRate,
      cost,
      costToComplete,
      undefined,
      isHidden,
      isExisting,
      isSelected,
      isDeletedInSap,
      milestones,
      // Do not map children yet. Will be assigned later.
      [], // children,
      metadata,
    );

    this.parent = parent;
    this.nestingLevel = nestingLevel;

    this.children = (Array.isArray(children) ? children : [])
      .map((item) => LevelModelTreeNode.fromSimilarObject(item, this, nestingLevel + 1));

    this.expandable = (this.children.length > 0);

    const fields: LevelImportListDtoMetadataFields = this.metadata.fields || {};
    const changedProperties: ChangedProperty[] = this.metadata.changedProperties || [];

    this.hasChanges = (changedProperties.length > 0);
    this.changes = (changedProperties || [])
      .reduce((obj, value) => ({ ...obj, [decapitalize(value.propertyName)]: value }), Object.create(null));

    this.isSelected = isSelected;
    this.isDeleted = isDeletedInSap || false;
    this.isDisabled = (fields.isDisabled === true);

    this.updateSelectedStates();
  }

  static fromSimilarObject(
    model: LevelImportListDtoModel,
    parent?: LevelModelTreeNode,
    nestingLevel: number = 0,
  ): LevelModelTreeNode {
    return new LevelModelTreeNode(
      model.levelId,
      parent || null,
      nestingLevel,
      model.codePath,
      model.levelNumber,
      model.title,
      (model.responsibleEngineer
        ? model.responsibleEngineer.clone()
        : undefined
      ),
      model.projectNumberForSubmitterSettings,
      model.activityItemCategoryNumberForSubmitterSettings,
      (model.startDate
        ? model.startDate.clone()
        : undefined
      ),
      (model.endDate
        ? model.endDate.clone()
        : undefined
      ),
      model.hours,
      model.hourlyRate,
      model.cost,
      model.costToComplete,
      model.isHidden,
      model.isExisting,
      model.isSelected,
      model.isDeletedInSap,
      (Array.isArray(model.milestones) ? model.milestones : [])
        .map((item) => item.clone()),
      (Array.isArray(model.children) ? model.children : [])
        .map((item) => item.clone()),
      model.metadata.clone(),
    );
  }

  updateSelectedStates(): void {
    if (this.children.length > 0) {
      const someChildrenSelected = this.children.some((child) => child.isSelected);
      const someChildrenPartiallySelected = this.children.some((child) => child.partiallySelected);
      const allChildrenDeleted = !this.children.some((child) => !child.isDeleted);

      this.selected = someChildrenSelected || (allChildrenDeleted && !this.isDeletedInSap && !this.isHidden);

      this.partiallySelected = (
        someChildrenPartiallySelected
        || (
          someChildrenSelected
          && !this.children.every((child) => child.isSelected) // and not all children selected
        )
      );
    } else {
      this.partiallySelected = false;
    }
  }
}
