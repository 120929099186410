import { MatFormFieldControl } from '@angular/material/form-field';
import {
  Directive,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import {
  UntypedFormControl, FormGroupDirective, NgControl, NgForm,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogContent } from '@angular/material/dialog';

export class NgSelectErrorStateMatcher {
  constructor(private ngSelect: NgSelectFormFieldControlDirective) {}

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (!control) {
      return this.ngSelect.required && this.ngSelect.empty;
    }

    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}

@Directive({
  selector: 'ng-select[ngSelectMat]',
  providers: [{ provide: MatFormFieldControl, useExisting: NgSelectFormFieldControlDirective }],
})
export class NgSelectFormFieldControlDirective implements MatFormFieldControl<any>, OnInit, OnDestroy, DoCheck {
  static nextId: number = 0;

  @HostBinding()
  @Input()
  id: string = `ng-select-${NgSelectFormFieldControlDirective.nextId++}`; // eslint-disable-line no-plusplus

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get required(): boolean { return this._required; }

  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get disabled(): boolean { return this._disabled; }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get value(): any {
    return this._value;
  }

  @Input()
  set value(v: any) {
    this._value = v;
    this.stateChanges.next();
  }

  @Input()
  errorStateMatcher!: ErrorStateMatcher;

  get empty(): boolean {
    return (this._value === undefined || this._value === null)
      || (this.host.multiple && this._value.length === 0);
  }

  errorState: boolean = false;

  // eslint-disable-next-line rxjs/no-exposed-subjects
  stateChanges: Subject<void> = new Subject();

  focused: boolean = false;

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || !!(this.host.searchTerm && this.host.searchTerm.length);
  }

  private _value: any;

  private _disabled: boolean = false;

  private _required: boolean = false;

  private _placeholder: string = '';

  private destroyed$: Subject<void> = new Subject();

  private _defaultErrorStateMatcher: ErrorStateMatcher = new NgSelectErrorStateMatcher(this);

  constructor(
    private host: NgSelectComponent,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    @Optional() private matDialogContent: MatDialogContent,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.host.focusEvent
      .asObservable()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.focused = true;
        this.stateChanges.next();
      });

    this.host.blurEvent
      .asObservable()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.focused = false;
        this.stateChanges.next();
      });

    this.host.openEvent
      .asObservable()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        // Is necessary because the open event is fired,
        // before the drop down panel is rendered.
        setTimeout(() => {
          this.setFontSizeToDropDown();
        }, 0);
      });
  }

  // eslint-disable-next-line max-lines-per-function
  ngOnInit(): void {
    // Prevent scrolling in mat-dialog-content when ng-select dropdown is open
    if (this.matDialogContent) {
      // @TODO Check this piece of code on every update to Angular and ng-select!
      // (prevent scrolling in mat-dialog-content)
      const matDialogContentElement: HTMLDivElement | null = this.element.nativeElement.closest('.mat-dialog-content');
      if (matDialogContentElement) {
        this.host
          .openEvent
          .pipe(
            takeUntil(this.destroyed$),
          )
          .subscribe(() => {
            this.renderer.setStyle(matDialogContentElement, 'overflow', 'hidden');
          });
        this.host
          .closeEvent
          .pipe(
            takeUntil(this.destroyed$),
          )
          .subscribe(() => {
            this.renderer.removeStyle(matDialogContentElement, 'overflow');
          });
      }
    }

    if (this.ngControl) {
      this._value = this.ngControl.value;
      this._disabled = this.ngControl.disabled !== null ? this.ngControl.disabled : true;

      this.ngControl.statusChanges
        ?.pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe((s) => {
          const disabled = s === 'DISABLED';
          if (disabled !== this._disabled) {
            this._disabled = disabled;
            this.stateChanges.next();
          }
        });

      this.ngControl.valueChanges
        ?.pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe((v) => {
          this._value = v;
          this.stateChanges.next();
        });
    } else {
      this.host.changeEvent
        .asObservable()
        .pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe((v) => {
          this._value = v;
          this.stateChanges.next();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngDoCheck(): void {
    // We need to re-evaluate this on every change detection cycle, because there are some
    // error triggers that we can't subscribe to (e.g. parent form submissions). This means
    // that whatever logic is in here has to be super lean or we risk destroying the performance.
    this.updateErrorState();
  }

  updateErrorState(): void {
    const oldState = this.errorState;
    const parent = this._parentFormGroup || this._parentForm;
    const matcher = this.errorStateMatcher || this._defaultErrorStateMatcher;
    const control = this.ngControl ? this.ngControl.control as UntypedFormControl : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]): void {
    if (ids) {
      this.describedBy = ids.join(' ');
    }
  }

  onContainerClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('mat-form-field-infix')) {
      this.host.focus();
      this.host.open();
    }
  }

  /**
   * Ensures that font size is not lost, if dropdown panel is
   * appended outside of the ng-select e.g. body.
   */
  private setFontSizeToDropDown(): void {
    const { element } = this.host;
    const computedStyles = window.getComputedStyle(element);
    const { fontSize } = computedStyles;
    const panel = this.host.dropdownPanel;

    if (panel) {
      const domElem = panel.contentElementRef.nativeElement;
      domElem.style.fontSize = fontSize;
    }
  }
}
