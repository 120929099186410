import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgSelectMaterialModule } from '../ng-select-mat';
import { PersonModule } from '../person';

import { PeopleSelectComponent } from './people-select.component';

// Requires the importing module to also import ReactiveFormsModule
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgSelectMaterialModule,
    PersonModule,
  ],
  exports: [
    PeopleSelectComponent,
  ],
  declarations: [
    PeopleSelectComponent,
  ],
})
export class PeopleSelectModule { }
