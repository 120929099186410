import { WorkPackageCloseRequest } from '../../interfaces/requests/work-package-close.request';
import { WorkPackageConclusion } from '../../../models/work-package-conclusion.enum';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class WorkPackageCloseRequestModel {
  constructor(
    readonly workPackageId: number,
    readonly conclusion: WorkPackageConclusion,
    readonly ratingId: number,
    readonly commentMessage: string | null = null,
    readonly sendNotifications: boolean = true,
  ) {
  }

  static fromJSON(json: WorkPackageCloseRequest): WorkPackageCloseRequestModel {
    assertRequiredProperties(json, [
      'workPackageId',
      'conclusion',
      'ratingId',
      // 'commentMessage',
    ]);

    return new WorkPackageCloseRequestModel(
      json.workPackageId,
      json.conclusion,
      json.ratingId,
      json.commentMessage,
      json.sendNotifications,
    );
  }

  toJSON(): WorkPackageCloseRequest {
    return {
      workPackageId: this.workPackageId,
      conclusion: this.conclusion,
      ratingId: this.ratingId,
      commentMessage: this.commentMessage,
      sendNotifications: this.sendNotifications,
    };
  }
}
