import { Selector } from '@ngxs/store';
import { TimecardsState } from './timecards.state';
import { TimecardsStateModel } from './timecards-state.model';

export class TimecardsSelectors {
  @Selector([TimecardsState])
  static nonProductiveCategoriesListExpanded(state: TimecardsStateModel): boolean {
    return state.nonProductiveCategoriesListExpanded;
  }

  @Selector([TimecardsState])
  static projectsListExpanded(state: TimecardsStateModel): boolean {
    return state.projectsListExpanded;
  }

  @Selector([TimecardsState])
  static collapsedProjectsIds(state: TimecardsStateModel): number[] {
    return [...state.collapsedProjectsIds];
  }
}
