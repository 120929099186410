export interface PathInfo {
  dirname: string;
  basename: string;
  filename: string;
  extension: string;
}

/**
 * Returns information about a file path or file name.
 *
 * @param path
 */
export function pathInfo(path: string): PathInfo {
  const pathParts = `${path}`.split('/');
  const filename = pathParts.pop() || '';
  const dirname = pathParts.join('/');

  const extIndex = filename.lastIndexOf('.');
  const extension = (extIndex !== -1 ? filename.substr(extIndex + 1) : '');
  const basename = (extIndex !== -1 ? filename.substr(0, extIndex) : filename);

  return {
    dirname,
    basename,
    filename,
    extension,
  };
}
