import moment from 'moment';
import { WorkPackageResourcePlanningViewResponse } from '../../interfaces/responses/work-package-resource-planning-view.response';
import { ResponsibleUserDtoModel } from '../dtos/responsible-user-dto.model';
import { Pair } from '../../../helpers/data-structure.utility';
import { UnitSlimDtoModel } from '../dtos/unit-slim.dto.model';
import { WorkPackageResourcePlanningResponseMetadata } from '../../interfaces/metadata';
import { MetadataModel } from '../metadata.model';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { WorkPackageStatus } from '../../../models/work-package-status.enum';
import { ResponsibleResourceDtoModel } from '../dtos/responsible-resource-dto.model';
import { UnitBackgroundColorDtoModel } from '../dtos/unit-background-color.dto.model';
import { PublicHolidayDtoModel } from '../dtos/public-holiday.dto.model';

export class WorkPackageResourcePlanningViewResponseModel {
  readonly canAddResources: boolean;

  readonly canEditResourcePlanningDates: boolean;

  readonly canSetWorkPackageCoordinator: boolean;

  readonly canViewUserPlanning: boolean;

  // eslint-disable-next-line complexity, max-lines-per-function
  constructor(
    readonly status: WorkPackageStatus,
    readonly estimatedStartDate: moment.Moment,
    readonly estimatedEndDate: moment.Moment,
    readonly estimatedHours: number = 0,
    readonly confirmedStartDate: moment.Moment | null = null,
    readonly confirmedEndDate: moment.Moment | null = null,
    readonly confirmedHours: number | null = null,
    readonly plannedStartDate: moment.Moment | null = null,
    readonly plannedEndDate: moment.Moment | null = null,
    readonly plannedHours: number = 0,
    readonly earliestPlannedDate: moment.Moment | null = null,
    readonly earliestPlannedDateAfterViewRange: moment.Moment | null = null,
    readonly latestPlannedDate: moment.Moment | null = null,
    readonly latestPlannedDateBeforeViewRange: moment.Moment | null = null,
    readonly hoursThreshold: number = Number.MAX_SAFE_INTEGER,
    readonly totalAvailableToBePlanned: number = Number.MAX_SAFE_INTEGER,
    readonly estimatedNewEndDate: moment.Moment | null = null,
    readonly supplierUnit: UnitSlimDtoModel | null = null,
    readonly responsibleUsers: readonly ResponsibleUserDtoModel[] = [],
    readonly plannedNonHumanResources: readonly ResponsibleResourceDtoModel[] = [],
    readonly resourcesProjects: readonly Pair<number, string>[] = [],
    readonly unitBackgroundColors: UnitBackgroundColorDtoModel[] = [],
    readonly timezone: string | null = null,
    readonly metadata: MetadataModel<WorkPackageResourcePlanningResponseMetadata> = new MetadataModel(),
    readonly publicHolidays: PublicHolidayDtoModel[] = [],
    readonly latestConfirmedHours: number = 0,
  ) {
    this.estimatedStartDate = (estimatedStartDate != null ? estimatedStartDate.clone() : estimatedStartDate);
    this.estimatedEndDate = (estimatedEndDate != null ? estimatedEndDate.clone() : estimatedEndDate);
    this.confirmedStartDate = (confirmedStartDate != null ? confirmedStartDate.clone() : confirmedStartDate);
    this.confirmedEndDate = (confirmedEndDate != null ? confirmedEndDate.clone() : confirmedEndDate);
    this.plannedStartDate = (plannedStartDate != null ? plannedStartDate.clone() : plannedStartDate);
    this.plannedEndDate = (plannedEndDate != null ? plannedEndDate.clone() : plannedEndDate);
    this.earliestPlannedDate = (earliestPlannedDate != null ? earliestPlannedDate.clone() : earliestPlannedDate);
    this.earliestPlannedDateAfterViewRange = (earliestPlannedDateAfterViewRange != null
      ? earliestPlannedDateAfterViewRange.clone()
      : earliestPlannedDateAfterViewRange);
    this.latestPlannedDate = (latestPlannedDate != null ? latestPlannedDate.clone() : latestPlannedDate);
    this.latestPlannedDateBeforeViewRange = (latestPlannedDateBeforeViewRange != null
      ? latestPlannedDateBeforeViewRange.clone()
      : latestPlannedDateBeforeViewRange);
    this.estimatedNewEndDate = (estimatedNewEndDate != null ? estimatedNewEndDate.clone() : estimatedNewEndDate);
    this.responsibleUsers = [...responsibleUsers];
    this.plannedNonHumanResources = [...plannedNonHumanResources];
    this.resourcesProjects = [...resourcesProjects];
    this.unitBackgroundColors = unitBackgroundColors.map((item) => item.clone());

    this.canAddResources = (this.metadata.fields && this.metadata.fields.canAddResources) || false;
    this.canEditResourcePlanningDates = (this.metadata.fields
      && this.metadata.fields.canEditResourcePlanningDates) || false;
    this.canSetWorkPackageCoordinator = (this.metadata.fields
      && this.metadata.fields.canSetWorkPackageCoordinator) || false;
    this.canViewUserPlanning = (this.metadata.fields && this.metadata.fields.canViewUserPlanning) || false;
  }

  // eslint-disable-next-line max-lines-per-function, complexity
  static fromJSON(json: WorkPackageResourcePlanningViewResponse): WorkPackageResourcePlanningViewResponseModel {
    assertRequiredProperties(json, [
      'status',
      'estimatedStartDate',
      'estimatedEndDate',
      'estimatedHours',
      // Required in FE
      'supplierUnit',
    ]);

    return new WorkPackageResourcePlanningViewResponseModel(
      json.status,
      moment(json.estimatedStartDate)
        .parseZone(),
      moment(json.estimatedEndDate)
        .parseZone(),
      json.estimatedHours || 0,
      (json.confirmedStartDate
        ? moment(json.confirmedStartDate)
          .parseZone()
        : null
      ),
      (json.confirmedEndDate
        ? moment(json.confirmedEndDate)
          .parseZone()
        : null
      ),
      json.confirmedHours,
      (json.plannedStartDate
        ? moment(json.plannedStartDate)
          .parseZone()
        : null
      ),
      (json.plannedEndDate
        ? moment(json.plannedEndDate)
          .parseZone()
        : null
      ),
      json.plannedHours || 0,
      (json.earliestPlannedDate
        ? moment(json.earliestPlannedDate)
          .parseZone()
        : null
      ),
      (json.earliestPlannedDateAfterViewRange
        ? moment(json.earliestPlannedDateAfterViewRange)
          .parseZone()
        : null
      ),
      (json.latestPlannedDate
        ? moment(json.latestPlannedDate)
          .parseZone()
        : null
      ),
      (json.latestPlannedDateBeforeViewRange
        ? moment(json.latestPlannedDateBeforeViewRange)
          .parseZone()
        : null
      ),
      (json.hoursThreshold != null
        ? json.hoursThreshold
        : Number.MAX_SAFE_INTEGER
      ),
      (json.totalAvailableToBePlanned != null
        ? json.totalAvailableToBePlanned
        : 0
      ),
      (json.estimatedNewEndDate
        ? moment(json.estimatedNewEndDate)
          .parseZone()
        : null
      ),
      (json.supplierUnit
        ? UnitSlimDtoModel.fromJSON(json.supplierUnit)
        : null
      ),
      (Array.isArray(json.responsibleUsers) ? json.responsibleUsers : [])
        .map((item) => ResponsibleUserDtoModel.fromJSON(item)),
      (Array.isArray(json.plannedNonHumanResources) ? json.plannedNonHumanResources : [])
        .map((item) => ResponsibleResourceDtoModel.fromJSON(item)),
      Object.entries(json.resourcesProjects || {})
        .map(([key, value]) => new Pair(parseInt(key, 10), value)),
      (Array.isArray(json.unitBackgroundColors) ? json.unitBackgroundColors : [])
        .map((item) => UnitBackgroundColorDtoModel.fromJSON(item)),
      json.timezone,
      new MetadataModel(json.metadata || {}),
      (Array.isArray(json.publicHolidays) ? json.publicHolidays : [])
        .map((item) => PublicHolidayDtoModel.fromJSON(item)),
      json.latestConfirmedHours || 0,
    );
  }

  // eslint-disable-next-line complexity
  toJSON(): WorkPackageResourcePlanningViewResponse {
    return {
      status: this.status,
      estimatedStartDate: this.estimatedStartDate.toJSON(),
      estimatedEndDate: this.estimatedEndDate.toJSON(),
      estimatedHours: this.estimatedHours,
      confirmedStartDate: (this.confirmedStartDate != null ? this.confirmedStartDate.toJSON() : null),
      confirmedEndDate: (this.confirmedEndDate != null ? this.confirmedEndDate.toJSON() : null),
      confirmedHours: this.confirmedHours,
      plannedStartDate: (this.plannedStartDate != null ? this.plannedStartDate.toJSON() : null),
      plannedEndDate: (this.plannedEndDate != null ? this.plannedEndDate.toJSON() : null),
      plannedHours: this.plannedHours,
      earliestPlannedDate: (this.earliestPlannedDate != null ? this.earliestPlannedDate.toJSON() : null),
      earliestPlannedDateAfterViewRange: (this.earliestPlannedDateAfterViewRange != null
        ? this.earliestPlannedDateAfterViewRange.toJSON()
        : null),
      latestPlannedDate: (this.latestPlannedDate != null ? this.latestPlannedDate.toJSON() : null),
      latestPlannedDateBeforeViewRange: (this.latestPlannedDateBeforeViewRange != null
        ? this.latestPlannedDateBeforeViewRange.toJSON()
        : null),
      hoursThreshold: this.hoursThreshold,
      totalAvailableToBePlanned: this.totalAvailableToBePlanned,
      estimatedNewEndDate: (this.estimatedNewEndDate != null ? this.estimatedNewEndDate.toJSON() : null),
      supplierUnit: (this.supplierUnit != null ? this.supplierUnit.toJSON() : null),
      responsibleUsers: this.responsibleUsers.map((item) => item.toJSON()),
      plannedNonHumanResources: this.plannedNonHumanResources.map((item) => item.toJSON()),
      resourcesProjects: this.resourcesProjects
        .reduce((dictionary, item) => ({
          ...dictionary,
          [item.first]: item.second,
        }), {} as Record<number, string>),
      unitBackgroundColors: this.unitBackgroundColors.map((item) => item.toJSON()),
      timezone: this.timezone,
      metadata: this.metadata,
      publicHolidays: this.publicHolidays,
      latestConfirmedHours: this.latestConfirmedHours,
    };
  }
}
