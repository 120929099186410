import { UnitBackgroundColorDto } from '../../interfaces/dtos/unit-background-color.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitBackgroundColorDtoModel {
  constructor(
    readonly unitBackgroundColorId: number,
    readonly unitId: number,
    readonly backgroundColor: string,
    readonly name: string | null,
  ) {}

  static fromJSON(json: UnitBackgroundColorDto): UnitBackgroundColorDtoModel {
    assertRequiredProperties(json, [
      'unitBackgroundColorId',
      'unitId',
      'backgroundColor',
    ]);

    return new UnitBackgroundColorDtoModel(
      json.unitBackgroundColorId,
      json.unitId,
      json.backgroundColor,
      json.name,
    );
  }

  toJSON(): UnitBackgroundColorDto {
    return {
      unitBackgroundColorId: this.unitBackgroundColorId,
      unitId: this.unitId,
      backgroundColor: this.backgroundColor,
      name: this.name,
    };
  }

  clone(): UnitBackgroundColorDtoModel {
    return new UnitBackgroundColorDtoModel(
      this.unitBackgroundColorId,
      this.unitId,
      this.backgroundColor,
      this.name,
    );
  }
}
