import { Diff } from '../../../app.types';
import { deepCopy } from '../../../helpers/object.utility';
import { MetadataSlimDto } from '../../interfaces/dtos/metadata-slim.dto';

function fieldReducer<T>(obj: any, key: string, value: any): T {
  return { ...obj, [key]: deepCopy(value) };
}

export class MetadataSlimDtoModel<T extends MetadataSlimDto = MetadataSlimDto> implements MetadataSlimDto {
  readonly fields: Diff<T['fields'], undefined>;

  constructor(
    protected metadata: T = {} as any,
  ) {
    this.fields = Object.entries((this.metadata.fields || {}))
      .reduce((obj, value) => fieldReducer(obj, value[0], value[1]), {} as any);
  }

  static fromJSON(json: MetadataSlimDto): MetadataSlimDtoModel {
    return new MetadataSlimDtoModel(json);
  }

  get(path: string): any | undefined {
    const parts = path.split('.');
    let value: any | undefined = this.metadata;
    let haystack: any | undefined;
    let key: string;

    for (let i = 0, l = parts.length; i < l && value !== undefined; i += 1) {
      key = parts[i];
      haystack = value;
      value = undefined;

      if (!key || key.trim() === '' || !haystack || typeof haystack !== 'object') {
        // eslint-disable-next-line no-continue
        continue;
      }

      const keys = Object.keys(haystack);

      for (let j = 0, m = keys.length; j < m; j += 1) {
        if (keys[j] === key) {
          value = haystack[key];
          break;
        }
      }
    }

    return value;
  }

  has(path: string): boolean {
    return (this.get(path) !== undefined);
  }

  clone(overrides?: { metadata: T }): MetadataSlimDtoModel<T> {
    return new MetadataSlimDtoModel<T>(
      (overrides && overrides.metadata
        ? overrides.metadata
        : deepCopy(this.metadata)
      ),
    );
  }

  toJSON(): T {
    return deepCopy<T>(this.metadata);
  }
}
