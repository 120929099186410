import { WorkPackagePlanningTotalsStateModel } from './work-package-planning-totals-state.model';

/**
 * Action types for WorkPackagePlanningTotalsState
 */
export enum WorkPackagePlanningTotalsActionTypes {
  InitWorkPackagePlanningTotalsSettings = '[WP Planning API] Initialize WP Planning Totals Settings',
  SetWorkPackagePlanningTotalsSettings = '[WP Planning API] Set WP Planning Totals Settings',
  ResetWorkPackagePlanningTotalsSettings = '[WP Planning API] Reset WP Planning Totals Settings',
  SaveWorkPackagePlanningTotalsSettings = '[WP Planning API] Save WP Planning Totals Settings',
}

export class InitWorkPackagePlanningTotalsSettings {
  static readonly type: string = WorkPackagePlanningTotalsActionTypes.InitWorkPackagePlanningTotalsSettings;

  constructor(public settings: WorkPackagePlanningTotalsStateModel) {}
}

export class SetWorkPackagePlanningTotalsSettings {
  static readonly type: string = WorkPackagePlanningTotalsActionTypes.SetWorkPackagePlanningTotalsSettings;

  constructor(public settings: WorkPackagePlanningTotalsStateModel) {}
}

export class ResetWorkPackagePlanningTotalsSettings {
  static readonly type: string = WorkPackagePlanningTotalsActionTypes.ResetWorkPackagePlanningTotalsSettings;
}

export class SaveWorkPackagePlanningTotalsSettings {
  static readonly type: string = WorkPackagePlanningTotalsActionTypes.SaveWorkPackagePlanningTotalsSettings;

  constructor(public settings: WorkPackagePlanningTotalsStateModel) {}
}
