import moment from 'moment';
import { UnitCategoryDto } from '../../interfaces/dtos/unit-category.dto';
import { assertRequiredProperties } from '../../utilities/api.utility';

export class UnitCategoryDtoModel {
  constructor(
    readonly unitCategoryId: number,
    readonly defaultTimecardCategoryId: number | null,
    readonly name: string | null,
    readonly sapCode: string | null,
    readonly isNonProductive: boolean,
    readonly isWork: boolean,
    readonly isRework: boolean,
    readonly isQualityAssurance: boolean,
    readonly isEnabled: boolean,
    readonly hasReportedHours: boolean,
    readonly unitName: string | null,
    readonly assignmentValidFrom: moment.Moment,
    readonly assignmentValidUntil: moment.Moment | null,
  ) {}

  static fromJSON(json: UnitCategoryDto): UnitCategoryDtoModel {
    assertRequiredProperties(json, [
      'unitCategoryId',
      'assignmentValidFrom',
    ]);

    return new UnitCategoryDtoModel(
      json.unitCategoryId,
      json.defaultTimecardCategoryId,
      json.name,
      json.sapCode,
      json.isNonProductive,
      json.isWork,
      json.isRework,
      json.isQualityAssurance,
      json.isEnabled,
      json.hasReportedHours,
      json.unitName,
      moment(json.assignmentValidFrom)
        .parseZone(),
      (json.assignmentValidUntil
        ? moment(json.assignmentValidUntil)
          .parseZone()
        : null
      ),
    );
  }

  clone(): UnitCategoryDtoModel {
    return new UnitCategoryDtoModel(
      this.unitCategoryId,
      this.defaultTimecardCategoryId,
      this.name,
      this.sapCode,
      this.isNonProductive,
      this.isWork,
      this.isRework,
      this.isQualityAssurance,
      this.isEnabled,
      this.hasReportedHours,
      this.unitName,
      this.assignmentValidFrom,
      this.assignmentValidUntil,
    );
  }
}
