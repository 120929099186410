import { ProjectTypeCode } from '../../../models/project-type.enum';
import { WorkpackageSupplierSettingsUpdateDto } from '../../interfaces/dtos/workpackageSupplierSettingsUpdate.dto';
import { ContractHourlyRateCategoryPairDtoModel } from './contract-hourly-rate-category-pair.dto.model';
import { WorkpackageSupplierSettingsDtoModel } from './workpackage-supplier-setting.dto.model';

export class WorkpackageSupplierSettingsUpdateDtoModel {
  constructor(
    readonly workPackageId: number,
    readonly additionalSupplierProjectTypeCode: string = ProjectTypeCode.SameAsSubmitter,
    readonly additionalSupplierProjectNumber: string | null = null,
    readonly additionalSupplierActivityItemCategoryNumber: string | number | null = null,
    public supplierHourlyRateCategories: ContractHourlyRateCategoryPairDtoModel[] = [],
  ) {
    this.supplierHourlyRateCategories = [...supplierHourlyRateCategories];
  }

  static fromWorkpackageSupplierSettingsDtoModel(
    workpackageSupplierSettingsDtoModel: WorkpackageSupplierSettingsDtoModel,
    workPackageId: number,
  ): WorkpackageSupplierSettingsUpdateDtoModel {
    return new WorkpackageSupplierSettingsUpdateDtoModel(
      workPackageId,
      workpackageSupplierSettingsDtoModel.additionalSupplierProjectTypeCode,
      workpackageSupplierSettingsDtoModel.additionalSupplierProjectNumber,
      workpackageSupplierSettingsDtoModel.additionalSupplierActivityItemCategoryNumber,
      workpackageSupplierSettingsDtoModel.contracts
        .map((contract) => contract.contractHourlyRateCategoryPairs)
        .reduce((acc, val) => acc.concat(val), []),
    );
  }

  static fromJSON(json: WorkpackageSupplierSettingsUpdateDto): WorkpackageSupplierSettingsUpdateDtoModel {
    // assertRequiredProperties(json, [
    //   'additionalSupplierProjectTypeCode',
    // ]);

    return new WorkpackageSupplierSettingsUpdateDtoModel(
      json.workPackageId,
      json.supplierProjectTypeCode,
      json.supplierProjectNumber,
      json.supplierActivityItemCategoryNumber,
      (Array.isArray(json.supplierHourlyRateCategories) ? json.supplierHourlyRateCategories : [])
        .map((item) => ContractHourlyRateCategoryPairDtoModel.fromJSON(item)),
    );
  }

  clone(): WorkpackageSupplierSettingsUpdateDtoModel {
    return new WorkpackageSupplierSettingsUpdateDtoModel(
      this.workPackageId,
      this.additionalSupplierProjectTypeCode,
      this.additionalSupplierProjectNumber,
      this.additionalSupplierActivityItemCategoryNumber,
      this.supplierHourlyRateCategories.map((item) => item.clone()),
    );
  }

  toJSON(): WorkpackageSupplierSettingsUpdateDto {
    return {
      workPackageId: this.workPackageId,
      supplierProjectTypeCode: this.additionalSupplierProjectTypeCode,
      supplierProjectNumber: this.additionalSupplierProjectNumber,
      supplierActivityItemCategoryNumber: this.additionalSupplierActivityItemCategoryNumber,
      supplierHourlyRateCategories: this.supplierHourlyRateCategories.map((item) => item.toJSON()),
    };
  }
}
