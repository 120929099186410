import { assertRequiredProperties } from '../../utilities/api.utility';
import { CostCenterDto } from '../../interfaces/dtos/cost-center.dto';

export class CostCenterModel {
  constructor(
    readonly costCenter: string,
    readonly costCenterDescription: string,
  ) {}

  static fromJSON(json: CostCenterDto): CostCenterModel {
    assertRequiredProperties(json, [
      'costCenter',
    ]);

    return new CostCenterModel(
      json.costCenter,
      json.costCenterDescription,
    );
  }

  get description(): string {
    return this.costCenterDescription;
  }

  toJSON(): CostCenterDto {
    return {
      costCenter: this.costCenter,
      costCenterDescription: this.costCenterDescription,
    };
  }
}
