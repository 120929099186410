export enum CapacityUnits {
  HoursWeek = 'h/week',
  DaysWeek = 'days/week',
  M2 = 'm2',
  Pcs = 'pcs',
}

export function isHoursPerWeek(unit: CapacityUnits | string): boolean {
  return unit.toString() === CapacityUnits.HoursWeek.toString();
}
