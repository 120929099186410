import { UnitPlanningWeekRowSlimDto } from '../../interfaces/dtos/unit-planning-week-row-slim.dto';
import { UnitPlanningColumnTotalDtoModel } from './unit-planning-column-total.dto.model';
import { UnitPlanningUserWeekEntryViewSlimDtoModel } from './unit-planning-user-week-entry-view-slim.dto.model';

export class UnitPlanningWeekRowSlimDtoModel {
  constructor(
    readonly unitCategoryId: number | null,
    readonly categoryName: string | null,
    readonly sapCode: string | null,
    readonly isVisible: boolean,
    readonly unitName: string | null,
    readonly weeks: UnitPlanningUserWeekEntryViewSlimDtoModel[],
    readonly totalPlanned: number | null,
    readonly totalRecorded: number | null,
    readonly totals: UnitPlanningColumnTotalDtoModel | null,
  ) {
    this.weeks = weeks.map((week) => week.clone());
  }

  static fromJSON(json: UnitPlanningWeekRowSlimDto): UnitPlanningWeekRowSlimDtoModel {
    return new UnitPlanningWeekRowSlimDtoModel(
      json.unitCategoryId,
      json.categoryName,
      json.sapCode,
      json.isVisible,
      json.unitName,
      json.weeks.map(UnitPlanningUserWeekEntryViewSlimDtoModel.fromJSON),
      json.totalPlanned,
      json.totalRecorded,
      json.totals ? UnitPlanningColumnTotalDtoModel.fromJSON(json.totals) : null,
    );
  }

  clone(): UnitPlanningWeekRowSlimDtoModel {
    return new UnitPlanningWeekRowSlimDtoModel(
      this.unitCategoryId,
      this.categoryName,
      this.sapCode,
      this.isVisible,
      this.unitName,
      this.weeks.map((week) => week.clone()),
      this.totalPlanned,
      this.totalRecorded,
      this.totals ? this.totals.clone() : null,
    );
  }
}
