import { MetadataModel } from '../metadata.model';
import { NonHumanResourceListResponseMetadata } from '../../interfaces/metadata';
import { assertRequiredProperties } from '../../utilities/api.utility';
import { NonHumanResourceListDtoModel } from '../dtos/non-human-resource-list.dto.model';
import { NonHumanResourceListResponse } from '../../interfaces/responses/non-human-resource-list.response';

export class NonHumanResourceListResponseModel {
  constructor(
    readonly items: readonly NonHumanResourceListDtoModel[],
    readonly metadata: MetadataModel<NonHumanResourceListResponseMetadata>,
  ) {
    this.items = [...items];
  }

  static fromJSON(json: NonHumanResourceListResponse): NonHumanResourceListResponseModel {
    assertRequiredProperties(json, [
      'items',
    ]);

    return new NonHumanResourceListResponseModel(
      json.items.map((item) => NonHumanResourceListDtoModel.fromJSON(item)),
      new MetadataModel(json.metadata),
    );
  }
}
