import { Injectable } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Observable, Subject } from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export abstract class CollappDateAdapter extends MomentDateAdapter {
  /** A stream that emits when the UTC offset changes. */
  abstract readonly utcOffsetChanges$: Observable<number>;

  protected abstract _utcOffsetChanges$: Subject<number>;

  abstract adjustDate(date: moment.Moment, keepLocalTime?: boolean): moment.Moment;

  abstract adjustDate(date: moment.Moment | null, keepLocalTime?: boolean): moment.Moment | null;

  /**
   * Adjusts a date to the UTC offset currently set on this date adapter.
   *
   * @param [keepLocalTime=false] - Passing true will keep the same local time, but at the expense of
   * choosing a different point in Universal Time.
   */
  abstract adjustDate(date: moment.Moment | null, keepLocalTime?: boolean): moment.Moment | null;

  /**
   * Get the UTC offset in minutes.
   */
  abstract utcOffset(): number;

  /**
   * Setting the UTC offset by supplying minutes.
   * See the official MomentJS documentation for `moment().utcOffset(Number|String);` - this
   * method behaves the same way.
   */
  abstract utcOffset(value: number | string): void;

  abstract utcOffset(value?: number | string): number | void;
}
